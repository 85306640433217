var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Cross = function (_a) {
    var isWhite = _a.isWhite;
    return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 4L11.8237 4.00509C10.2489 4.09634 9 5.40232 9 7V9H7C5.34315 9 4 10.3431 4 12V16L4.00509 16.1763C4.09634 17.7511 5.40232 19 7 19H9V21C9 22.6569 10.3431 24 12 24H16L16.1763 23.9949C17.7511 23.9037 19 22.5977 19 21V19H21C22.6569 19 24 17.6569 24 16V12L23.9949 11.8237C23.9037 10.2489 22.5977 9 21 9H19V7C19 5.34315 17.6569 4 16 4H12ZM12 6H16C16.5523 6 17 6.44772 17 7V10C17 10.5523 17.4477 11 18 11H21C21.5523 11 22 11.4477 22 12V16C22 16.5523 21.5523 17 21 17H18C17.4477 17 17 17.4477 17 18V21C17 21.5523 16.5523 22 16 22H12C11.4477 22 11 21.5523 11 21V18C11 17.4477 10.5523 17 10 17H7C6.44772 17 6 16.5523 6 16V12C6 11.4477 6.44772 11 7 11H10C10.5523 11 11 10.5523 11 10V7C11 6.44772 11.4477 6 12 6Z", fill: isWhite ? 'white' : 'black' }) })));
};
export default Cross;
