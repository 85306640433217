var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { t } from 'i18next';
import { Switcher } from '@core/components/formComponents/switchers/radioSwitcher';
import { TextMessage } from '@core/components/surveyMessages/textMessage';
import { Slot } from '@core/pages/forms/slot';
import { getChildren, getContent, hasTag } from '@core/shared/utils/surveyUtils';
import styles from './index.module.sass';
export var Question = function (_a) {
    var self = _a.self, children = _a.children, form = _a.form, stepId = _a.stepId, onButtonClick = _a.onButtonClick, mediaUploadIsNotCanceled = _a.mediaUploadIsNotCanceled, prefilledOCRFieldsId = _a.prefilledOCRFieldsId, readDocuments = _a.readDocuments, handleSetReadDocuments = _a.handleSetReadDocuments, documentsIsRead = _a.documentsIsRead, uploadButton = _a.uploadButton;
    var _b = useMemo(function () {
        var currentChildren = getChildren({
            id: self._id,
            data: children,
            variations: ['slot', 'info', 'content', 'select_option'],
        });
        var questionContent = getContent({ id: self._id, data: children });
        return { currentChildren: currentChildren, questionContent: questionContent };
    }, [self._id, children]), currentChildren = _b.currentChildren, questionContent = _b.questionContent;
    var isSwitcher = self.subvariation === 'switcher';
    var isMediaQuestion = currentChildren.filter(function (child) { return child.variation === 'slot' && child.subvariation === 'media'; }).length;
    var isMultiMedia = isMediaQuestion > 1;
    if (mediaUploadIsNotCanceled) {
        return (_jsx("div", __assign({ className: clsx(styles.question, styles.questionChat) }, { children: _jsx(TextMessage, { className: styles.questionChatTextMessage, label: t('label_please_cancel_upload'), type: "income" }) })));
    }
    var slots = currentChildren.filter(function (child) { return child.variation === 'slot' || child.variation === 'info'; });
    var allSlotsHidden = (hasTag(self, 'hideOnEmpty') && slots.every(function (slot) { return !form.getValues(slot._id); })) ||
        slots.every(function (slot) { return hasTag(slot, 'hide'); });
    if (allSlotsHidden) {
        return null;
    }
    return (_jsxs("div", __assign({ className: clsx(styles.question, styles.questionChat, isMediaQuestion && styles.questionChatMedia) }, { children: [questionContent && (_jsx(TextMessage, { className: styles.questionChatTextMessage, label: questionContent.title, type: "income" })), isSwitcher && (_jsx(Switcher, __assign({ stepId: stepId, className: clsx(styles.slotSwitcher, styles.slotSwitcherChat), form: form, type: 'chat' }, { children: currentChildren }))), !isSwitcher &&
                currentChildren.map(function (child) {
                    if (child.variation === 'slot' || child.variation === 'info') {
                        return (_jsx("div", __assign({ className: clsx(styles.slot, styles.slotChat, child.subvariation === 'media' && styles.slotChatMedia) }, { children: _jsx(Slot, __assign({ questionId: self._id, prefilledOCRFieldsId: prefilledOCRFieldsId, form: form, self: child, onButtonClick: onButtonClick, isMultiMedia: isMultiMedia, handleSetReadDocuments: handleSetReadDocuments, readDocuments: readDocuments, documentsIsRead: documentsIsRead, uploadButton: uploadButton, multiQuestion: self.subvariation === 'multi', allSlots: currentChildren }, { children: currentChildren })) }), child._id));
                    }
                    return null;
                }), form.formState.errors[self._id] && (_jsx("p", __assign({ className: styles.questionError }, { children: form.formState.errors[self._id].message })))] })));
};
