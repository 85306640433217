var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { BaseCard } from '@core/components/baseCard';
import { Avatar } from '@root/../core/components/avatar';
import useWindowDimensions from '@root/../core/shared/utils/widthHook';
import styles from './index.module.sass';
export var FamilyAccessCard = function (_a) {
    var classNames = _a.classNames;
    var nav = useNavigate();
    var isMobile = useWindowDimensions().isMobile;
    var _b = useSelector(function (state) { return state.profile; }), self = _b.self, family = _b.family;
    var handleOnClick = function () {
        nav(routePaths.MANAGE_PLAN);
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsxs(BaseCard, __assign({ handleOnClick: handleOnClick, classNames: clsx(styles.card, classNames) }, { children: [!isMobile && _jsx("div", __assign({ className: styles.title }, { children: t('label_family_access') })), _jsxs("div", __assign({ className: clsx(styles.icons, !(family === null || family === void 0 ? void 0 : family.length) && styles.iconsOne) }, { children: [_jsx(Avatar, { patient: self, firstName: self === null || self === void 0 ? void 0 : self.firstName, lastName: self === null || self === void 0 ? void 0 : self.lastName, showName: false, needLoadAvatar: true, avatarSize: "medium", classNameImage: styles.iconImage, className: styles.icon }), (family === null || family === void 0 ? void 0 : family.length) > 0 &&
                                [family[0], family[1]].map(function (e) { return (_jsx(Avatar, { patient: e, firstName: e === null || e === void 0 ? void 0 : e.name.given[0], lastName: e === null || e === void 0 ? void 0 : e.name.family, showName: false, needLoadAvatar: true, avatarSize: "small", classNameImage: styles.iconImage, className: styles.icon }, e === null || e === void 0 ? void 0 : e._id)); })] }))] })), isMobile && _jsx("div", __assign({ className: styles.titleMobile }, { children: t('label_family_access') }))] })));
};
