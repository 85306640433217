var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Apple = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M22.3932 8.18242C22.2578 8.29043 19.868 9.67537 19.868 12.7549C19.868 16.3168 22.909 17.577 23 17.6082C22.986 17.685 22.5169 19.3339 21.3966 21.0141C20.3977 22.4927 19.3545 23.9688 17.7675 23.9688C16.1804 23.9688 15.772 23.0207 13.9399 23.0207C12.1545 23.0207 11.5197 24 10.0681 24C8.61639 24 7.60349 22.6319 6.43889 20.9517C5.08992 18.9787 4 15.9136 4 13.0045C4 8.33843 6.95001 5.86379 9.85334 5.86379C11.396 5.86379 12.682 6.90549 13.6505 6.90549C14.5724 6.90549 16.0101 5.80138 17.7651 5.80138C18.4303 5.80138 20.8202 5.86379 22.3932 8.18242ZM16.9319 3.82598C17.6578 2.94029 18.1712 1.71137 18.1712 0.482448C18.1712 0.312031 18.1572 0.139214 18.1269 0C16.946 0.0456046 15.541 0.808881 14.6938 1.81938C14.0286 2.59706 13.4078 3.82598 13.4078 5.07171C13.4078 5.25893 13.4382 5.44614 13.4522 5.50615C13.5268 5.52055 13.6482 5.53735 13.7696 5.53735C14.8291 5.53735 16.1618 4.80768 16.9319 3.82598Z", fill: "#007AFF" }) }))); };
export default Apple;
