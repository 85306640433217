var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useRef } from 'react';
import clsx from 'clsx';
import styles from './index.module.sass';
export var Switcher = forwardRef(function (_a) {
    var on = _a.on, label = _a.label, onClick = _a.onClick, isBig = _a.isBig, styleWrapperOn = _a.styleWrapperOn, styleWrapperOff = _a.styleWrapperOff, styleInnerOn = _a.styleInnerOn, styleInnerOff = _a.styleInnerOff;
    var bgRef = useRef();
    var switchRef = useRef();
    var switchTextRef = useRef();
    useEffect(function () {
        var _a;
        if (bgRef.current && ((_a = switchTextRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== 0) {
            var x = bgRef.current.offsetWidth - switchRef.current.offsetWidth;
            switchRef.current.setAttribute('style', "transform: translate3d(".concat(on ? x : 0, "px,0,0)"));
        }
    });
    var getLabel = function () {
        if (label) {
            return on ? label.on : label.off;
        }
    };
    return (_jsxs("div", __assign({ className: clsx(styles.switcher, !label && styles.switcherSimple, label && styles.switcherLabel, on && styles.switcherOn, styleWrapperOn && on && styleWrapperOn, styleWrapperOff && !on && styleWrapperOff, isBig && styles.switcherSimpleBig), onClick: onClick }, { children: [label && (_jsxs("div", __assign({ className: styles.switcherLabelBg, ref: bgRef }, { children: [_jsx("div", __assign({ className: styles.switcherLabelBgLeft }, { children: label.off })), _jsx("div", __assign({ className: styles.switcherLabelBgRight }, { children: label.on }))] }))), _jsx("div", __assign({ className: clsx(styles.switcherInner, styleInnerOn && on && styleInnerOn, styleInnerOff && !on && styleInnerOff), ref: switchRef }, { children: _jsx("span", __assign({ ref: switchTextRef }, { children: getLabel() })) }))] })));
});
Switcher.displayName = 'Switcher';
export var SwitcherField = function (_a) {
    var label = _a.label, on = _a.on, onChange = _a.onChange, switcherClasses = _a.switcherClasses, labelClasses = _a.labelClasses;
    return (_jsxs("div", __assign({ className: clsx(styles.switcherField, switcherClasses) }, { children: [_jsx("label", __assign({ className: clsx(styles.switcherFieldLabel, labelClasses) }, { children: label })), _jsx(Switcher, { on: on, label: '123', onClick: onChange })] })));
};
