var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { BaseWrapper } from '@core/components/baseWrapper';
import { showModal } from '@core/components/modals';
import { PaymentMethodModal } from '@core/components/modals/paymentMethods';
import { PageTitle } from '@core/components/pageTitle';
import useWindowDimensions from '@core/shared/utils/widthHook';
import { addPaymentMethod, getPaymentMethods, resetAddPaymentStatus, setSelectedPaymentMethod, } from '@core/store/reducers/paymentsSlice';
import { getSubscriptionProducts, setSelectedProducts, } from '@core/store/reducers/subscriptionSlice';
import { Payment } from './views/payment';
import { Plan } from './views/plan';
import { Summary } from './views/summary';
import styles from './index.module.sass';
export var Checkout = function () {
    var dispatch = useDispatch();
    var isDesktop = useWindowDimensions().isDesktop;
    var nav = useNavigate();
    var payments = useSelector(function (s) { return s.payments; });
    var _a = useSelector(function (s) { return s.subscriptions; }), subscriptionProducts = _a.subscriptionProducts, selectedProducts = _a.selectedProducts;
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    var _c = useState(false), isLoaded = _c[0], setLoaded = _c[1];
    var currentModalClose = useRef(null);
    var _d = useState(searchParams.get('id')), selectedPlanId = _d[0], setSelectedPlanId = _d[1];
    var _e = useState(searchParams.get('interval') || 'month'), selectedInterval = _e[0], setSelectedInterval = _e[1];
    var _f = useState(selectedPlanId ? subscriptionProducts.find(function (sub) { return sub._id === selectedPlanId; }) : null), selectedPlan = _f[0], setSelectedPlan = _f[1];
    var _g = useState(!searchParams.get('id')), allPlansShown = _g[0], setAllPlansShown = _g[1];
    var _h = useState(allPlansShown ? subscriptionProducts : selectedProducts), plansToShow = _h[0], setPlansToShow = _h[1];
    var calcTotalSum = function () {
        var _a, _b;
        if (!selectedPlanId) {
            return 0;
        }
        var price = (_a = selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.prices) === null || _a === void 0 ? void 0 : _a.find(function (p) { return p.interval === selectedInterval; });
        var sum = parseFloat((_b = price === null || price === void 0 ? void 0 : price.amount) === null || _b === void 0 ? void 0 : _b.value);
        return sum;
    };
    var _j = useState(calcTotalSum()), totalSum = _j[0], setTotalSum = _j[1];
    useEffect(function () {
        if (!(selectedProducts === null || selectedProducts === void 0 ? void 0 : selectedProducts.length)) {
            nav(routePaths.MANAGE_PLAN);
        }
    }, [selectedProducts === null || selectedProducts === void 0 ? void 0 : selectedProducts.length]);
    useEffect(function () {
        dispatch(getPaymentMethods());
        dispatch(getSubscriptionProducts());
    }, []);
    useEffect(function () {
        searchParams.set('id', selectedPlanId);
        searchParams.set('interval', selectedInterval);
        setSearchParams(searchParams);
        setSelectedPlan(subscriptionProducts.find(function (sub) { return sub._id === selectedPlanId; }));
    }, [selectedPlanId, selectedInterval]);
    useEffect(function () {
        var productId = searchParams.get('id');
        if ((subscriptionProducts === null || subscriptionProducts === void 0 ? void 0 : subscriptionProducts.length) && !(selectedProducts === null || selectedProducts === void 0 ? void 0 : selectedProducts.length) && productId) {
            var product_1 = subscriptionProducts.find(function (p) { return p._id === productId; });
            setSelectedPlan(product_1);
            var newSelectedProducts = subscriptionProducts.filter(function (p) {
                var _a, _b;
                return ((p === null || p === void 0 ? void 0 : p.group) === (product_1 === null || product_1 === void 0 ? void 0 : product_1.group) &&
                    ((_a = p === null || p === void 0 ? void 0 : p.name) === null || _a === void 0 ? void 0 : _a.replace(' Family', '')) === ((_b = product_1 === null || product_1 === void 0 ? void 0 : product_1.name) === null || _b === void 0 ? void 0 : _b.replace(' Family', '')));
            });
            dispatch(setSelectedProducts(newSelectedProducts));
        }
    }, [selectedProducts === null || selectedProducts === void 0 ? void 0 : selectedProducts.length, subscriptionProducts === null || subscriptionProducts === void 0 ? void 0 : subscriptionProducts.length, searchParams.get('id')]);
    useEffect(function () {
        setPlansToShow(allPlansShown ? subscriptionProducts : selectedProducts);
    }, [selectedProducts]);
    useEffect(function () {
        setTotalSum(calcTotalSum());
    }, [selectedPlan, selectedInterval]);
    var handlePaymentCardClick = function (paymentMethod) {
        setLoaded(false);
        dispatch(setSelectedPaymentMethod(paymentMethod));
    };
    var addPayment = function (stripe, card) {
        setLoaded(false);
        dispatch(addPaymentMethod({
            stripe: stripe,
            card: card,
            callback: function () {
                setLoaded(true);
                currentModalClose.current && currentModalClose.current();
            },
        }));
    };
    var handleAddPaymentClick = function () {
        var mode = payments.paymentMethods.length > 0 || payments.addPaymentSuccess ? 'select' : 'add';
        dispatch(resetAddPaymentStatus());
        var closeModal = showModal(PaymentMethodModal, {
            onSubmit: addPayment,
            mode: mode,
        }).closeModal;
        currentModalClose.current = closeModal;
    };
    var handleClickPlan = function (plan, priceInterval) {
        setSelectedPlanId(plan._id);
        setSelectedInterval(priceInterval);
    };
    var handleShowAllPlansClick = function () {
        setPlansToShow(subscriptionProducts);
        setAllPlansShown(true);
    };
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs("div", __assign({ className: styles.content }, { children: [_jsxs(BaseWrapper, __assign({ classNames: styles.checkout }, { children: [_jsx(PageTitle, { text: t('label_checkout'), hasBackButton: true }), _jsx(Plan, { plansToShow: plansToShow, handleClickPlan: handleClickPlan, handleShowAllPlansClick: handleShowAllPlansClick, selectedInterval: selectedInterval, selectedPlanId: selectedPlanId, allPlansShown: allPlansShown }), _jsx(Payment, { handleAddPaymentClick: handleAddPaymentClick, handlePaymentCardClick: handlePaymentCardClick }), !isDesktop && (_jsx(Summary, { selectedInterval: selectedInterval, selectedPlan: selectedPlan, totalSum: totalSum }))] })), isDesktop && (_jsx(BaseWrapper, __assign({ classNames: styles.summaryWrapper }, { children: _jsx(Summary, { selectedInterval: selectedInterval, selectedPlan: selectedPlan, totalSum: totalSum }) })))] })) })));
};
