export function scrollToSmoothly(finalPosition, transition) {
    if (transition === void 0) { transition = 500; }
    var start;
    var currentPosition = window.pageYOffset;
    var tempOffset = currentPosition;
    requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        // if (window.pageYOffset !== tempOffset) {
        //     return
        // }
        tempOffset = Math.floor(((finalPosition - currentPosition) * progress) / transition + currentPosition);
        if (currentPosition < finalPosition) {
            window.scrollTo(0, ((finalPosition - currentPosition) * progress) / transition + currentPosition);
        }
        else {
            window.scrollTo(0, currentPosition - ((currentPosition - finalPosition) * progress) / transition);
        }
        if (progress < transition) {
            requestAnimationFrame(step);
        }
        else {
            window.scrollTo(0, finalPosition);
        }
    });
}
