var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
export var faqsConfig = [
    {
        id: 1,
        question: 'What is QSure’s health assurance plan, and how is it different from traditional health insurance?',
        answer: 'Traditional insurance typically covers unexpected or major medical expenses after they occur, while a health assurance plan emphasizes proactive measures, preventive care and predictable costs. QSure’s assurance plan aims to keep individuals healthy and address issues early. QSure operates on a fixed-price subscription model with fixed benefits.',
        type: 'service',
    },
    {
        id: 2,
        question: 'What specific benefits are included in QSure’s plan?',
        answer: (_jsxs("div", { children: ["QSure\u2019s assurance plan includes health coaching, health risk assessments, DNA screening, biometric screening, telemedicine, nutrition support and more. Each plan offers different levels of services, so you can select the plan that works for you.", ' ', _jsx(Link, __assign({ to: "/services/" }, { children: "Click here" })), " to learn about all the services."] })),
        type: 'service',
    },
    {
        id: 3,
        question: 'How does the subscription-based model work, and what are the associated costs?',
        answer: "Once you select your subscription level, you’ll pay a fixed amount regularly, either on a monthly or annual schedule. The cost of an annual subscription is lower than the monthly subscription. The associated costs depend on the chosen plan, including the level of coverage and specific benefits. You’ll maintain coverage and access the plan's benefits for as long as you remain a member. These costs are predictable, giving you the advantage of knowing your healthcare expenses in advance.",
        type: 'consumers',
    },
    {
        id: 4,
        question: 'Can I customize a QSure plan to fit my specific healthcare needs?',
        answer: (_jsxs("div", { children: ["Yes, you can customize your QSure plan to fit your individual healthcare needs. Each subscription level allows you to select specific benefits and coverage options based on your health requirements.", _jsx("p", { children: "We recommend carefully reviewing plan options to ensure that the chosen plan aligns with your individual healthcare needs and preferences." })] })),
        type: 'consumers',
    },
    {
        id: 5,
        question: 'Can I change or cancel my subscription?',
        answer: (_jsxs("div", { children: ["If you are an individual member, you can change your subscription beginning with any new billing period.", _jsx("p", { children: "If you have an employee plan, you generally cannot change subscription levels until the next open enrollment period unless you experience a qualifying life event, such as:" }), _jsx("p", { children: _jsxs("ul", { children: [_jsx("li", { children: "Marriage status change" }), _jsx("li", { children: "Birth or adoption" }), _jsx("li", { children: "Another plan\u2019s involuntary loss of coverage" }), _jsx("li", { children: "Employment status change" }), _jsx("li", { children: "Aging out of a parent\u2019s plan" })] }) })] })),
        type: 'consumers',
    },
    {
        id: 6,
        question: 'What happens when the monthly membership cannot be charged?',
        answer: (_jsxs("div", { children: ["Membership perks stop as soon as automatic payment does not go through.", _jsx("br", {}), " There will be a predefined number of times the payment method will be attempted to be charged.", _jsx("br", {}), "An email will be sent to the user.", _jsx("br", {}), "The membership will become inactive after that.", _jsx("br", {}), "Once the customer resumes payment, the subscription will be extended for the time it was \u201Cinactive\u201D from the first of the month when the payment was made."] })),
        type: 'consumers',
    },
    {
        id: 7,
        question: 'What happens when the monthly membership cannot be charged?',
        answer: (_jsxs("div", { children: ["Membership perks stop as soon as automatic payment does not go through.", _jsx("br", {}), " There will be a predefined number of times the payment method will be attempted to be charged.", _jsx("br", {}), "An email will be sent to the user.", _jsx("br", {}), "The membership will become inactive after that.", _jsx("br", {}), "Once the customer resumes payment, the subscription will be extended for the time it was \u201Cinactive\u201D from the first of the month when the payment was made."] })),
    },
    {
        id: 8,
        question: 'What happens when my membership is inactive?',
        answer: (_jsxs("div", { children: ["There will be no discounts applied.", _jsx("br", {}), "No free-of-charge services will be offered.", _jsx("br", {}), "Patients can still order the non-membership-specific tests and pay for them as before."] })),
        type: 'consumers',
    },
    {
        id: 9,
        question: 'What preventive care measures are covered by QSure?',
        answer: (_jsxs("div", { children: ["QSure\u2019s preventive care measures vary with each", ' ', _jsx(Link, __assign({ to: '/consumers/' }, { children: "subscription level" })), ". Depending on the level you choose, they include some or all of the following:", _jsxs("ul", __assign({ style: { listStyleType: 'square' } }, { children: [_jsx("li", { children: "Routine check-ups" }), _jsx("li", { children: "Biometric and DNA screenings" }), _jsx("li", { children: "Vaccinations" }), _jsx("li", { children: "Wellness exams" }), _jsx("li", { children: "Health coaching" }), _jsx("li", { children: "Nutrition coaching" }), _jsx("li", { children: "Chronic disease management" }), _jsx("li", { children: "Health education" })] })), _jsx("p", { children: "Be sure to review the specific details of each subscription level to understand the preventive care measures included and how they align with your individual health needs." })] })),
        type: 'service',
    },
    {
        id: 10,
        question: 'Are there any limitations or exclusions to the coverage provided by QSure?',
        answer: (_jsx("div", { children: "QSure\u2019s assurance plans are a supplement to traditional insurance, so some health services are not covered. Examples include: Certain medical treatments or procedures, cosmetic procedures, alternative therapies and some prescription medications. If you have questions, speak to a QSure broker to see what is covered under your specific plan." })),
        type: 'service',
    },
    {
        id: 11,
        question: 'How does early detection of health issues work within the plan, and what screenings or tests are included?',
        answer: (_jsxs("div", { children: ["Regular screenings and lab tests included in your subscription can help you identify potential problems before they become more serious. The specific screenings and tests included vary among plans. Some basic early detection screenings include the following:", _jsxs("ul", __assign({ style: { listStyleType: 'square' } }, { children: [_jsx("li", { children: "Routine check-ups" }), _jsx("li", { children: "Blood pressure monitoring" }), _jsx("li", { children: "Cholesterol screenings" }), _jsx("li", { children: "Cancer screenings" }), _jsx("li", { children: "Blood glucose testing" }), _jsx("li", { children: "Immunizations" }), _jsx("li", { children: "Bone density tests" }), _jsx("li", { children: "Vision and hearing tests" })] }))] })),
        type: 'service',
    },
    {
        id: 12,
        question: 'Are there wellness programs or incentives to encourage a healthy lifestyle?',
        answer: (_jsxs("div", { children: ["Yes! These programs are designed to promote overall well-being:", _jsxs("ul", __assign({ style: { listStyleType: 'square' } }, { children: [_jsx("li", { children: "Health coaching" }), _jsx("li", { children: "Nutritional counseling" }), _jsx("li", { children: "Cholesterol screenings" }), _jsx("li", { children: "Smoking cessation support" }), _jsx("li", { children: "Stress management services" }), _jsx("li", { children: "Weight management programs" })] }))] })),
        type: 'service',
    },
    {
        id: 13,
        question: 'What happens if I need medical care that goes beyond the fixed benefits of the plan?',
        answer: (_jsxs("div", { children: ["If you require medical care beyond the fixed benefits of your QSure plan, you will be responsible for the payment. Common scenarios include out-of-pocket costs, out-of-network providers, copayments, deductibles, limitations or exclusions", _jsxs("p", { children: ["If you believe a service should be covered but was denied,", ' ', _jsx(Link, __assign({ to: '/contact-us/' }, { children: "contact us" })), " to launch the appeals process."] }), _jsxs("p", { children: ["It's important to carefully review your subscription plan documentation, including terms, conditions and coverage limitations, to understand how additional or unexpected medical expenses are handled. If you have ", _jsx(Link, __assign({ to: '/contact-us/' }, { children: "questions or concerns," })), ' ', "reach out to us directly to get clarity on the coverage and potential costs associated with specific medical needs."] })] })),
        type: 'service',
    },
    {
        id: 14,
        question: 'What happens if I want to cancel my health assurance plan?',
        answer: (_jsx("div", { children: "You can cancel to change your subscription before the next billing cycle. If you have an employer plan, you will need to wait for the next open enrollment period." })),
        type: 'consumers',
    },
    {
        id: 15,
        question: 'How often can I review or update my personalized preventive care plan?',
        answer: (_jsxs("div", { children: ["You can cancel to change your subscription before the next billing cycle.", _jsx("p", {})] })),
        type: 'consumers',
    },
    {
        id: 16,
        question: 'How does the plan address emergency situations or unexpected health issues?',
        answer: (_jsxs("div", { children: ["Health assurance plans typically provide some coverage for emergency situations and unexpected health issues. This includes:", ' ', _jsxs("ul", __assign({ style: { listStyleType: 'square' } }, { children: [_jsx("li", { children: "Emergency room (ER) coverage" }), _jsx("li", { children: "Urgent care visits" }), _jsx("li", { children: "Ambulance services" }), _jsx("li", { children: "Hospitalization" }), _jsx("li", { children: "Emergency surgery" })] }))] })),
    },
    {
        id: 17,
        question: 'Is mental health covered under my QSure plan, and what services are available?',
        answer: (_jsx("div", { children: "Yes, coverage for confidential mental and behavioral health telehealth visits are available from QSure. The number of monthly covered visits will vary based on the subscription level you select." })),
        type: 'service',
    },
    {
        id: 18,
        question: 'Can I use my QSure plan for routine check-ups and vaccinations?',
        answer: (_jsx("div", { children: "Yes, preventive care, including routine check-ups and immunizations, is a hallmark of QSure\u2019s health plan. These can be virtual or in-person and depend on the subscription level of your choice." })),
        type: 'service',
    },
    {
        id: 19,
        question: 'Can I lock in my subscription price, or does it increase every year? ',
        answer: (_jsx("div", { children: "Monthly and annual subscription prices can change. We will notify you before the next billing cycle if your subscription price increases." })),
        type: 'consumers',
    },
];
