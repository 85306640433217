var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Pen from '@core/shared/svg/pen';
import styles from './index.module.sass';
var mstp = function (state) { return ({
    surveyId: state.survey.surveyId,
    media: state.survey.media,
}); };
export var ImagesMessage = function (_a) {
    var value = _a.value, disableEdit = _a.disableEdit, nonClickable = _a.nonClickable, handleEdit = _a.handleEdit, handleMediaSlot = _a.handleMediaSlot, editable = _a.editable;
    var media = useSelector(mstp).media;
    var _b = useState(), image = _b[0], setImage = _b[1];
    var _c = useState(false), showImage = _c[0], setShowImage = _c[1];
    useEffect(function () {
        if (!media.length)
            return;
        media.forEach(function (media) {
            if (media.slot_id === value.slot_id) {
                setImage(media.url);
            }
        });
    }, [media, value.slot_id]);
    useEffect(function () {
        handleMediaSlot();
    }, []);
    return (_jsxs("div", __assign({ className: styles.ImagesMessage }, { children: [editable && (_jsx("div", __assign({ className: clsx(styles.ImagesMessageEditable, disableEdit && styles.ImagesMessageEditableDisabled, nonClickable && styles.ImagesMessageEditableNonClickable), onClick: handleEdit }, { children: _jsx(Pen, {}) }))), _jsx("div", { className: styles.bubbleLoader }), _jsx("img", { className: clsx(styles.ImagesMessageImage, showImage && styles.ImagesMessageShowImage), src: image, alt: "", onLoad: function () {
                    setShowImage(true);
                } })] })));
};
