var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './index.module.sass';
export var PatientCardLoader = function () {
    return (_jsx(SkeletonTheme, __assign({ borderRadius: 16, baseColor: "#f0f0f0", highlightColor: "#eaeaea", inline: true }, { children: _jsxs("div", __assign({ className: styles.container }, { children: [_jsx("div", __assign({ className: styles.title }, { children: _jsx(Skeleton, { height: 24, width: 130 }) })), _jsx("div", __assign({ className: styles.details }, { children: _jsx(Skeleton, { height: 14, width: 100 }) })), _jsxs("div", __assign({ className: styles.bottom }, { children: [_jsx("div", __assign({ className: styles.bottomAvatar }, { children: _jsx(Skeleton, { height: 28, width: 28 }) })), _jsx("div", __assign({ className: styles.bottomIcon }, { children: _jsx(Skeleton, { height: 28, width: 28 }) }))] }))] })) })));
};
