var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { Button } from '@components/button';
import { QuestionForm } from '@components/questionForm';
import useWindowDimensions from '@core/shared/utils/widthHook';
import { faqsConfig } from '@root/src/configs/landing/faqs';
import styles from './index.module.sass';
export var PageQuestions = function (_a) {
    var type = _a.type;
    var wideScreen = useWindowDimensions().isDesktop;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: styles.main }, { children: [_jsx("div", __assign({ className: styles.mainTitle }, { children: t('label_landing_services_questions_name') })), _jsx("div", __assign({ className: styles.mainTitleBig }, { children: t("label_landing_services_questions_title") })), _jsxs("div", __assign({ className: styles.wrapper }, { children: [_jsxs("div", __assign({ className: styles.wrapperTitle }, { children: [t('label_landing_services_questions_text1', {
                                    type: type === 'consumers' ? 'Plan' : 'Service',
                                }), wideScreen ? _jsx("br", {}) : ' ', t("label_landing_services_questions_text2"), ' ', _jsx(Link, __assign({ to: routePaths.CONTACTUS }, { children: _jsx("u", { children: t('label_landing_services_questions_link') }) })), ' ', t('label_landing_services_questions_text3')] })), _jsx("div", __assign({ className: styles.wrapperSelects }, { children: faqsConfig
                                .filter(function (el) { return el.type === type; })
                                .map(function (item) { return (_jsx("div", { children: _jsx(QuestionForm, { question: item.question, answer: item.answer }) }, item.id)); }) })), _jsxs("div", __assign({ className: styles.wrapperButtons }, { children: [_jsx("div", __assign({ className: styles.wrapperButtonsItem }, { children: _jsx(Link, __assign({ to: routePaths.SUPPORT }, { children: _jsx(Button, { label: t('label_landing_services_questions_btn1'), white: true, classes: [styles.wrapperButtonsGray] }) })) })), _jsx("div", __assign({ className: styles.wrapperButtonsItem }, { children: _jsx(Link, __assign({ to: routePaths.CONTACTUS }, { children: _jsx(Button, { label: t('label_landing_services_questions_btn2'), white: true, classes: [styles.wrapperButtonsGray] }) })) }))] }))] }))] })) }));
};
