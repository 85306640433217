var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { QSureButton as Button } from '@core/components/buttons/qsureButton';
import { useActiveSubscription } from '@core/shared/hooks/useActiveSubscription';
import { checkSubscriptionDetail } from '@core/shared/utils/subscriptionUtils';
import styles from './index.module.sass';
export var CurrentPlanCard = function (_a) {
    var onClick = _a.onClick, className = _a.className;
    var subscriptionProducts = useSelector(function (s) { return s.subscriptions; }).subscriptionProducts;
    var _b = useSelector(function (state) { return state.profile; }), self = _b.self, family = _b.family, subscriptions = _b.subscriptions;
    var currentPlan = useActiveSubscription();
    var planDetails = useMemo(function () {
        var subProduct = subscriptionProducts.find(function (sub) { return sub._id === (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.subscription_product_id); });
        if (!subProduct)
            return null;
        return checkSubscriptionDetail({ subscription: subProduct, iconWithBorder: true });
    }, [subscriptions, subscriptionProducts]);
    return (_jsxs("div", __assign({ className: clsx(styles.card, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isSilver) && styles.cardSilver, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isGold) && styles.cardGold, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isPlatinum) && styles.cardPlatinum, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isDiamond) && styles.cardDiamond, className) }, { children: [_jsxs("div", __assign({ className: styles.cardTop }, { children: [planDetails === null || planDetails === void 0 ? void 0 : planDetails.icon(), _jsx("div", __assign({ className: styles.cardTopTitle }, { children: planDetails === null || planDetails === void 0 ? void 0 : planDetails.displayName }))] })), _jsxs("div", __assign({ className: styles.cardMiddle }, { children: [_jsx("div", __assign({ className: styles.cardMiddleTitle }, { children: t('documents_detail_member_number') })), _jsx("div", __assign({ className: styles.cardMiddleText }, { children: currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.number }))] })), _jsxs("div", __assign({ className: styles.cardBottom }, { children: [_jsxs("div", __assign({ className: styles.cardBottomInfo }, { children: [_jsx("div", __assign({ className: styles.cardBottomInfoTitle }, { children: "".concat(self.firstName, " ").concat(self.lastName) })), family.length > 0 && (planDetails === null || planDetails === void 0 ? void 0 : planDetails.includesFamily) && (_jsx("div", __assign({ className: styles.cardBottomInfoSubTitle }, { children: t('label_plus_family_members') })))] })), _jsx(Button, { label: t('label_manage'), classes: [styles.cardBottomBtn], handler: onClick })] }))] })));
};
