var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import { t } from 'i18next';
import SurveyHeader from '@core/components/surveyHeader';
import { AnsweredStep } from '@core/pages/forms/answered/answeredStep';
import { FinishSurvey } from '@core/pages/forms/finishSurvey';
import { Step } from '@core/pages/forms/step';
import { SID } from '@core/shared/models/survey';
import { getStep, hasTag } from '@core/shared/utils/surveyUtils';
import styles from './index.module.sass';
export var Chat = function (_a) {
    var title = _a.title, backRoute = _a.backRoute, confirmLogout = _a.confirmLogout, finishCallback = _a.finishCallback, closeModal = _a.closeModal, sid = _a.sid, stickyHeader = _a.stickyHeader, customPrefill = _a.customPrefill;
    var _b = useSelector(function (state) { return state; }).survey, currentStep = _b.currentStep, fullSurvey = _b.fullSurvey, answers = _b.answers, isLoading = _b.isLoading, patient = _b.patient;
    var prevStep = useRef(null);
    var nav = useNavigate();
    var handleClose = function () {
        if (typeof backRoute === 'string') {
            if (backRoute === 'logout') {
                return confirmLogout();
            }
            if (sid === SID.ADD_FAMILY) {
                return closeModal();
            }
            nav(backRoute || '/');
            return;
        }
        if (typeof backRoute === 'function') {
            backRoute();
        }
    };
    var isSubmissionError = fullSurvey.survey.status === 'submission_error';
    var isSurveyCompleted = fullSurvey.survey.status === 'completed';
    var isUploading = fullSurvey.survey.status === 'uploading';
    var isFinishSurvey = isSubmissionError || (isSurveyCompleted && !isLoading);
    var isDisableAnswerEdit = isUploading || isSubmissionError || hasTag(currentStep.step, 'update');
    var getDescription = function () {
        if (sid === SID.ADD_DOCUMENT || sid === SID.PREBOOKING_COLLECTOR || sid === SID.PRESCRIPTION) {
            return __spreadArray(__spreadArray([], patient.name.given, true), [patient.name.family], false).join(' ');
        }
        return '';
    };
    return (_jsxs("div", __assign({ className: clsx([styles.chat, stickyHeader && styles.chatStickyHeader]) }, { children: [_jsx(SurveyHeader, { handleClose: handleClose, progress: 0, title: title, description: getDescription(), stickyHeader: stickyHeader }), _jsxs("div", __assign({ className: clsx([styles.chatContent, stickyHeader && styles.chatContentStickyHeader]) }, { children: [answers.map(function (answer) { return (_jsx(AnsweredStep, { answer: answer, step: getStep(fullSurvey, answer.step_id), disableEdit: isDisableAnswerEdit }, answer.step_id)); }), isFinishSurvey ? (_jsx(FinishSurvey, { label: isSubmissionError
                            ? t('error_submission_survey')
                            : t('error_finish_chat') })) : (_jsx(Step, __assign({ customPrefill: customPrefill, finishCallback: finishCallback, self: currentStep.step, answer: currentStep.answer, confirmLogout: confirmLogout, setPrevStep: function (prevStepArg) {
                            prevStep.current = prevStepArg;
                        }, prevStep: prevStep === null || prevStep === void 0 ? void 0 : prevStep.current }, { children: currentStep.children }), currentStep.step._id))] }))] })));
};
