var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var SupportMini = function () { return (_jsxs("svg", __assign({ width: "60", height: "60", viewBox: "0 0 60 60", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "30", cy: "30", r: "30", fill: "#FFCC37" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.5 34.5C16.5 32.0147 18.5147 30 21 30C23.4853 30 25.5 32.0147 25.5 34.5V37.5C25.5 39.9853 23.4853 42 21 42C18.5147 42 16.5 39.9853 16.5 37.5V34.5ZM21 33C20.1716 33 19.5 33.6716 19.5 34.5V37.5C19.5 38.3284 20.1716 39 21 39C21.8284 39 22.5 38.3284 22.5 37.5V34.5C22.5 33.6716 21.8284 33 21 33Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M34.5 34.5C34.5 32.0147 36.5147 30 39 30C41.4853 30 43.5 32.0147 43.5 34.5V37.5C43.5 39.9853 41.4853 42 39 42C36.5147 42 34.5 39.9853 34.5 37.5V34.5ZM39 33C38.1716 33 37.5 33.6716 37.5 34.5V37.5C37.5 38.3284 38.1716 39 39 39C39.8284 39 40.5 38.3284 40.5 37.5V34.5C40.5 33.6716 39.8284 33 39 33Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M30 19.5C24.201 19.5 19.5 24.201 19.5 30V34.5C19.5 35.3284 18.8284 36 18 36C17.1716 36 16.5 35.3284 16.5 34.5V30C16.5 22.5442 22.5442 16.5 30 16.5C37.4558 16.5 43.5 22.5442 43.5 30V34.5C43.5 35.3284 42.8284 36 42 36C41.1716 36 40.5 35.3284 40.5 34.5V30C40.5 24.201 35.799 19.5 30 19.5Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M39 39C39.8284 39 40.5 39.6716 40.5 40.5C40.5 42.6169 38.8229 44.1296 37.0348 45.0236C35.1569 45.9626 32.6677 46.5 30 46.5C29.1716 46.5 28.5 45.8284 28.5 45C28.5 44.1716 29.1716 43.5 30 43.5C32.3029 43.5 34.3136 43.0301 35.6931 42.3403C37.1624 41.6057 37.5 40.8684 37.5 40.5C37.5 39.6716 38.1716 39 39 39Z", fill: "black" })] }))); };
export default SupportMini;
