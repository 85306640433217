import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { PageLoader } from '@core/components/loader/PageLoader';
import { logOut } from '@core/store/reducers/authSlice';
export var Logout = function (_a) {
    var isQsure = _a.isQsure;
    var dispatch = useDispatch();
    var _b = useParams(), phone = _b.phone, email = _b.email;
    useEffect(function () {
        if (phone) {
            dispatch(logOut("/phone/".concat(phone)));
            return;
        }
        if (email) {
            dispatch(logOut("/email/".concat(email)));
            return;
        }
        dispatch(logOut());
    }, []);
    return _jsx(PageLoader, { isQsure: isQsure });
};
