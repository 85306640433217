var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import CheckMark from '@core/shared/svg/checkMark';
import Minus from '@core/shared/svg/minus';
import Plus from '@core/shared/svg/plus';
import styles from './index.module.sass';
export var Checkbox = function (_a) {
    var preLabel = _a.preLabel, label = _a.label, description = _a.description, value = _a.value, onChange = _a.onChange, checked = _a.checked, price = _a.price, subvariation = _a.subvariation, _b = _a.changeType, changeType = _b === void 0 ? 'single' : _b, _c = _a.changeTypeSign, changeTypeSign = _c === void 0 ? 'plus' : _c, className = _a.className, checkClassName = _a.checkClassName, labelClassName = _a.labelClassName, _d = _a.inactive, inactive = _d === void 0 ? false : _d;
    return (_jsxs("div", __assign({ className: clsx(styles.chatAnswer, className), onClick: function () { return onChange(label, value, subvariation); } }, { children: [_jsx("div", __assign({ className: clsx([
                    styles.chatAnswerCheckbox,
                    checked && styles.chatAnswerCheckboxChecked,
                    checkClassName,
                ]) }, { children: checked ? (_jsx(CheckMark, {})) : (changeType === 'multi' && (changeTypeSign === 'plus' ? _jsx(Plus, {}) : _jsx(Minus, {}))) })), _jsxs("p", __assign({ className: clsx([
                    styles.chatAnswerLabel,
                    changeType === 'multi' && styles.chatAnswerLabelMulti,
                    inactive && styles.inactive,
                    labelClassName,
                ]) }, { children: [preLabel && _jsx("span", { children: preLabel }), label, description && _jsx("span", __assign({ className: styles.chatAnswerDescription }, { children: description }))] })), price && _jsx("span", { children: price })] })));
};
