import { PersonFlag } from '../models/person';
export var getMissingContactType = function (accounts) {
    if (!accounts.length)
        return;
    if (accounts.every(function (account) { return account.type !== 'email'; })) {
        return 'email';
    }
    if (accounts.every(function (account) { return account.type !== 'phone'; })) {
        return 'phone';
    }
};
export var checkIsProfileLocked = function (person) {
    var _a;
    return (_a = person.flags) === null || _a === void 0 ? void 0 : _a.includes(PersonFlag.locked);
};
export var formProfileSelectData = function (person, items, personKey) {
    return Object.entries(items).map(function (_a) {
        var _b;
        var key = _a[0], value = _a[1];
        return ({
            id: key,
            name: value,
            isSelect: !!((_b = person[personKey]) === null || _b === void 0 ? void 0 : _b.includes(key)),
        });
    });
};
export var checkIsHolder = function (patient) {
    if (!patient)
        return false;
    return 'firstName' in patient;
};
