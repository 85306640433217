var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { maxBy } from 'lodash';
import { PaymentCard } from '@core/components/paymentCard';
import { PlusItem } from '@core/components/plusItem';
import { Title } from '@core/components/title';
import { setSelectedPaymentMethod } from '@core/store/reducers/paymentsSlice';
import styles from './index.module.sass';
export var Payment = function (_a) {
    var _b;
    var handlePaymentCardClick = _a.handlePaymentCardClick, handleAddPaymentClick = _a.handleAddPaymentClick;
    var dispatch = useDispatch();
    var payments = useSelector(function (s) { return s.payments; });
    useEffect(function () {
        var _a, _b;
        if (((_a = payments === null || payments === void 0 ? void 0 : payments.paymentMethods) === null || _a === void 0 ? void 0 : _a.length) && !(payments === null || payments === void 0 ? void 0 : payments.selectedPaymentMethod)) {
            var lastUsedPaymentMethods = (_b = payments.paymentMethods) === null || _b === void 0 ? void 0 : _b.filter(function (p) { return p === null || p === void 0 ? void 0 : p.last_used; });
            if (!(lastUsedPaymentMethods === null || lastUsedPaymentMethods === void 0 ? void 0 : lastUsedPaymentMethods.length)) {
                return;
            }
            var latestPaymentMethod = maxBy(lastUsedPaymentMethods, function (o) { return new Date(o.last_used); });
            dispatch(setSelectedPaymentMethod(latestPaymentMethod));
        }
    }, [payments === null || payments === void 0 ? void 0 : payments.selectedPaymentMethod, (_b = payments === null || payments === void 0 ? void 0 : payments.paymentMethods) === null || _b === void 0 ? void 0 : _b.length]);
    return (_jsxs("div", __assign({ className: styles.payment }, { children: [_jsx(Title, { title: t('label_payment'), adaptiveTitleTop: false, titleClass: styles.paymentTitle }), _jsx("div", __assign({ className: styles.paymentSubTitle }, { children: t('label_choose_payment_method') })), _jsxs("div", __assign({ className: styles.paymentItems }, { children: [payments.paymentMethods.map(function (method) {
                        var _a;
                        var isActive = ((_a = payments.selectedPaymentMethod) === null || _a === void 0 ? void 0 : _a._id) === (method === null || method === void 0 ? void 0 : method._id);
                        return (_jsx(PaymentCard, { method: method, isActive: isActive, onClick: function () { return handlePaymentCardClick(method); }, className: styles.paymentCard, black: true }, method.stripe_id));
                    }), _jsx(PlusItem, { title: t('label_add_payment_method'), onClick: handleAddPaymentClick, classNameTitle: styles.paymentAddTitle })] }))] })));
};
