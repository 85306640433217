var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Risks = function () { return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "29", height: "28", viewBox: "0 0 29 28", fill: "none" }, { children: _jsx("path", { d: "M25.9839 14.9999C25.9872 16.8409 25.5264 18.6474 24.6512 20.255C24.3872 20.7399 23.7615 20.8609 23.3025 20.554C22.8446 20.2478 22.7257 19.6308 22.9776 19.1409C23.5659 17.997 23.9039 16.7366 23.9641 15.4439C24.0399 13.8185 23.6735 12.2029 22.904 10.7691C22.1346 9.33528 20.9907 8.13693 19.5943 7.30154C18.1979 6.46615 16.6011 6.02499 14.9739 6.02499C13.3467 6.02499 11.7499 6.46615 10.3535 7.30154C8.95703 8.13693 7.81322 9.33528 7.04374 10.7691C6.27426 12.2029 5.9079 13.8185 5.98364 15.4439C6.0441 16.7412 6.38437 18.0061 6.9766 19.1534C7.22612 19.6367 7.1109 20.2456 6.66041 20.5505C6.20482 20.8589 5.58095 20.7391 5.31832 20.2558C4.66055 19.0451 4.23464 17.7189 4.06594 16.3455C3.85659 14.6411 4.04917 12.9114 4.62827 11.2948C5.20737 9.67819 6.1569 8.21963 7.40085 7.03586C8.64481 5.85209 10.1486 4.97599 11.7919 4.4777C13.4352 3.97941 15.1724 3.87276 16.8643 4.16631C18.5562 4.45985 20.1559 5.14542 21.5353 6.16814C22.9147 7.19087 24.0355 8.52233 24.808 10.0559C25.5805 11.5896 25.9832 13.2827 25.9839 14.9999ZM18.1439 10.5299L13.9239 14.7499C13.8192 14.8396 13.7342 14.9499 13.6742 15.074C13.6141 15.198 13.5804 15.3332 13.5751 15.4709C13.5698 15.6086 13.593 15.7459 13.6433 15.8742C13.6935 16.0026 13.7698 16.1191 13.8672 16.2166C13.9647 16.314 14.0812 16.3903 14.2096 16.4406C14.3379 16.4908 14.4752 16.514 14.6129 16.5087C14.7507 16.5034 14.8858 16.4697 15.0099 16.4096C15.1339 16.3496 15.2442 16.2646 15.3339 16.1599L19.5539 11.9399C19.7177 11.7486 19.8033 11.5025 19.7936 11.2509C19.7839 10.9992 19.6795 10.7605 19.5015 10.5824C19.3234 10.4043 19.0846 10.2999 18.8329 10.2902C18.5813 10.2805 18.3352 10.3661 18.1439 10.5299Z", fill: "black" }) }))); };
export default Risks;
