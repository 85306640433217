var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var PlatinumPlan = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "22", height: "23", viewBox: "0 0 22 23", fill: "none" }, { children: [_jsx("rect", { x: "11", width: "15", height: "15", transform: "rotate(45 11 0)", fill: "#B5C6D1" }), _jsx("g", __assign({ style: { mixBlendMode: 'overlay' }, opacity: "0.6" }, { children: _jsx("path", { d: "M10.9996 21.2136L0.392988 10.607H10.9996V21.2136Z", fill: "white" }) })), _jsx("path", { d: "M11.0004 0.000253946L21.607 10.6069H11.0004V0.000253946Z", fill: "white", fillOpacity: "0.2" })] }))); };
export default PlatinumPlan;
