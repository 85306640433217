var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var GoldPlanBorder = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "26", height: "26", viewBox: "0 0 26 26", fill: "none" }, { children: [_jsx("rect", { x: "13", y: "2", width: "15", height: "15", transform: "rotate(45 13 2)", fill: "#FFBB33" }), _jsx("rect", { x: "13", y: "0.585786", width: "17", height: "17", transform: "rotate(45 13 0.585786)", stroke: "white", strokeOpacity: "0.9", strokeWidth: "2", strokeLinejoin: "round" }), _jsx("g", __assign({ style: { mixBlendMode: 'overlay' }, opacity: "0.6" }, { children: _jsx("path", { d: "M13.0006 23.2126L2.39396 12.606H13.0006V23.2126Z", fill: "white" }) })), _jsx("path", { d: "M12.9994 2.00025L23.606 12.6069H12.9994V2.00025Z", fill: "white", fillOpacity: "0.2" })] }))); };
export default GoldPlanBorder;
