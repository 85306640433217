var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { routePaths } from '@app/routes/routes';
import { CurrentPlanCard } from '@core/components/currentPlanCard';
import { YourRiskCard } from '@core/components/yourRiskCard';
import { useActiveSubscription } from '@core/shared/hooks/useActiveSubscription';
import useWindowDimensions from '@core/shared/utils/widthHook';
import { NavMobile } from '@layouts/main/components/navMobile';
import styles from './index.module.sass';
export var HeadSection = function () {
    var nav = useNavigate();
    var isMobile = useWindowDimensions().isMobile;
    var self = useSelector(function (state) { return state.profile; }).self;
    var isLoggedIn = Boolean(useSelector(function (state) { return state.profile.id; }));
    var activeSubscription = useActiveSubscription();
    var handleManagePlanClick = function () {
        nav(routePaths.MANAGE_PLAN);
    };
    var handleShowRecommendations = function () {
        nav(routePaths.RISKS);
    };
    var handleStartTest = function () {
        nav(routePaths.HEALTH_RISK_TEST, { state: { redirectPath: routePaths.RISKS } });
    };
    if (!activeSubscription) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("section", __assign({ className: styles.headSection }, { children: [isMobile && _jsx(NavMobile, {}), _jsx(YourRiskCard, { showRecommendations: handleShowRecommendations, startTest: handleStartTest }), isLoggedIn && activeSubscription && (_jsx("div", __assign({ className: styles.headSectionCurrentPlan }, { children: _jsx(CurrentPlanCard, { onClick: handleManagePlanClick }) })))] })));
};
