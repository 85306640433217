import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { config } from '@core/config/config';
export var StoriesRedirect = function () {
    var self = useSelector(function (state) { return state.profile.self; });
    var page = useParams().page;
    useEffect(function () {
        if (self) {
            var url = "".concat(config.href, "/stories/patients/").concat(self === null || self === void 0 ? void 0 : self.id, "/");
            if (page) {
                url = "".concat(config.href, "/stories/patients/").concat(self === null || self === void 0 ? void 0 : self.id, "/").concat(page, "/");
            }
            window.location.replace(url);
        }
    }, [self, page]);
    return null;
};
