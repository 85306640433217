var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import { getContent, hasTag } from '@core/shared/utils/surveyUtils';
import styles from './index.module.sass';
export var ButtonsGroup = function (_a) {
    var buttons = _a.buttons, currentButton = _a.currentButton, onButtonClick = _a.onButtonClick, mediaUploadIsNotCanceled = _a.mediaUploadIsNotCanceled, handleCancelMediaUpload = _a.handleCancelMediaUpload, bigButtonStyle = _a.bigButtonStyle, isReadingDocuments = _a.isReadingDocuments, readingDocumentsLabel = _a.readingDocumentsLabel, documentsIsRead = _a.documentsIsRead;
    var _b = useSelector(function (state) { return state; }), survey = _b.survey, loading = _b.loading;
    var isLoading = loading.isLoading || survey.isLoading;
    return (_jsx(_Fragment, { children: mediaUploadIsNotCanceled ? (_jsx(Button, { disabled: isLoading, loading: isLoading, classes: [styles.buttonChat], primary: true, label: t('btn_cancel_upload'), handler: handleCancelMediaUpload })) : (_jsx(_Fragment, { children: _jsx("div", __assign({ className: styles.buttonsChat }, { children: buttons.map(function (button) {
                    if (hasTag(button, 'hide')) {
                        return null;
                    }
                    var isReadingButton = hasTag(button, 'readCounter') && isReadingDocuments;
                    var isNotRead = isReadingButton && !documentsIsRead();
                    if (hasTag(button, 'hideForCollector') || hasTag(button, 'forceInsurance')) {
                        return null;
                    }
                    // eslint-disable-next-line no-restricted-globals
                    if (button.action === 'skip_line' && hasTag(self, 'update')) {
                        return null;
                    }
                    var content = getContent({
                        id: button._id,
                        data: survey.fullSurvey.content,
                    });
                    var primary = !hasTag(button, 'secondary');
                    return (_jsx(Button, { disabled: isLoading || isNotRead, loading: isLoading && button._id === currentButton, classes: [styles.buttonChat, bigButtonStyle && styles.buttonChatBigStyle], primary: primary, label: isNotRead ? readingDocumentsLabel : content === null || content === void 0 ? void 0 : content.label, handler: function () { return onButtonClick(button); } }, button._id));
                }) })) })) }));
};
