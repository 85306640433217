var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useEffect, } from 'react';
import clsx from 'clsx';
import ArrowBlack from '@core/shared/svg/arrowBlack';
import ArrowLeft from '@core/shared/svg/arrowLeft';
import ArrowWhite from '@core/shared/svg/arrowWhite';
import styles from './index.module.sass';
export var QSureButton = forwardRef(function (_a, ref) {
    var id = _a.id, label = _a.label, disabled = _a.disabled, gray = _a.gray, black = _a.black, icon = _a.icon, leftIcon = _a.leftIcon, handler = _a.handler, classes = _a.classes, withoutArrow = _a.withoutArrow, _b = _a.type, type = _b === void 0 ? 'button' : _b, loading = _a.loading, isEnterHandle = _a.isEnterHandle, children = _a.children, white = _a.white, props = __rest(_a, ["id", "label", "disabled", "gray", "black", "icon", "leftIcon", "handler", "classes", "withoutArrow", "type", "loading", "isEnterHandle", "children", "white"]);
    var handClick = function () {
        handler && handler();
    };
    var handleEnter = function (event) {
        event.key === 'Enter' && handClick();
    };
    useEffect(function () {
        isEnterHandle && window.addEventListener('keypress', handleEnter);
        return function () {
            window.removeEventListener('keypress', handleEnter);
        };
    }, [id]);
    var mainClasses = [
        styles.btn,
        gray && styles.btnGray,
        black && styles.btnBlack,
        white && styles.btnWhite,
        disabled && styles.btnDisabled,
        loading && styles.loading,
    ];
    classes && mainClasses.push.apply(mainClasses, classes);
    return (_jsx(_Fragment, { children: _jsxs("button", __assign({ ref: ref, className: clsx(mainClasses), onClick: handClick, disabled: disabled, type: type }, (type === 'submit' && {
            onSubmit: handClick,
        }), props, { children: [_jsxs("div", __assign({ className: styles.btnInner }, { children: [leftIcon && (_jsx("div", __assign({ className: styles.btnInnerBackButton }, { children: _jsx(ArrowLeft, {}) }))), label, icon, !black && !withoutArrow && _jsx(ArrowBlack, {}), black && !withoutArrow && _jsx(ArrowWhite, {}), children && children] })), loading && (_jsx("div", __assign({ className: styles.loader }, { children: _jsx("div", { className: styles.bubbleLoader }) })))] })) }));
});
QSureButton.displayName = 'Button';
