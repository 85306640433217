var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Plus = function () {
    return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 6C13.4477 6 13 6.44772 13 7V13H7C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H13V21C13 21.5523 13.4477 22 14 22C14.5523 22 15 21.5523 15 21V15H21C21.5523 15 22 14.5523 22 14C22 13.4477 21.5523 13 21 13H15V7C15 6.44772 14.5523 6 14 6Z", fill: "black" }) })));
};
export default Plus;
