var _a, _b;
export var AccessRole;
(function (AccessRole) {
    AccessRole["COLLECTOR"] = "collector";
    AccessRole["PRACTICE_COLLECTOR"] = "practice_collector";
    AccessRole["MOBILE_COLLECTOR"] = "mobile_collector";
    AccessRole["SUPERVISOR"] = "supervisor";
    AccessRole["PRACTICE_SUPERVISOR"] = "practice_supervisor";
    AccessRole["PRACTICE_MANAGER"] = "practice_manager";
    AccessRole["NURSING_HOME_MANAGER"] = "nursing_home_manager";
    AccessRole["ORGANIZATION_MANAGER"] = "organization_manager";
})(AccessRole || (AccessRole = {}));
export var AccessPriority = (_a = {},
    _a[AccessRole.COLLECTOR] = 0,
    _a[AccessRole.MOBILE_COLLECTOR] = 0,
    _a[AccessRole.SUPERVISOR] = 1,
    _a[AccessRole.PRACTICE_COLLECTOR] = 2,
    _a[AccessRole.PRACTICE_SUPERVISOR] = 3,
    _a[AccessRole.NURSING_HOME_MANAGER] = 4,
    _a[AccessRole.ORGANIZATION_MANAGER] = 5,
    _a[AccessRole.PRACTICE_MANAGER] = 6,
    _a);
export var SortPriority = (_b = {},
    _b[AccessRole.COLLECTOR] = 0,
    _b[AccessRole.MOBILE_COLLECTOR] = 0,
    _b[AccessRole.PRACTICE_COLLECTOR] = 0,
    _b[AccessRole.NURSING_HOME_MANAGER] = 1,
    _b[AccessRole.ORGANIZATION_MANAGER] = 2,
    _b[AccessRole.SUPERVISOR] = 3,
    _b[AccessRole.PRACTICE_SUPERVISOR] = 4,
    _b[AccessRole.PRACTICE_MANAGER] = 5,
    _b);
export var AccessStatus;
(function (AccessStatus) {
    AccessStatus["ACTIVE"] = "active";
    AccessStatus["DISABLED"] = "disabled";
})(AccessStatus || (AccessStatus = {}));
