var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import Trash from '@core/shared/svg/trash';
import styles from './index.module.sass';
var DeletedDenied = function () {
    var nav = useNavigate();
    return (_jsxs("div", __assign({ className: styles.deletedSuccessfully }, { children: [_jsx("div", __assign({ className: styles.trashCircle }, { children: _jsx(Trash, {}) })), _jsx("h2", __assign({ className: styles.title }, { children: t('delete_account_denied_title') })), _jsx("p", __assign({ className: styles.description }, { children: t('delete_account_denied_description') })), _jsx(Button, { classes: [styles.button], primary: true, label: t('btn_done'), handler: function () { return nav(-1); } })] })));
};
export default DeletedDenied;
