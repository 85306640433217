var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '@components/button';
import Checked from '@root/src/shared/svg/checked';
import DiamondIcon from '@root/src/shared/svg/diamondIcon';
import GoldIcon from '@root/src/shared/svg/goldIcon';
import PlatinumIcon from '@root/src/shared/svg/platinumIcon';
import SilverIcon from '@root/src/shared/svg/silverIcon';
import Unchecked from '@root/src/shared/svg/unchecked';
import styles from './index.module.sass';
export var PlansTable = function (_a) {
    var data = _a.data, btnHandler = _a.btnHandler;
    var icons = {
        silver: _jsx(SilverIcon, {}),
        gold: _jsx(GoldIcon, {}),
        platinum: _jsx(PlatinumIcon, {}),
        diamond: _jsx(DiamondIcon, {}),
    };
    var refArray = useRef([]);
    var _b = useState([]), size = _b[0], setSize = _b[1];
    var addToRefs = useCallback(function (el, index) {
        if (!el || refArray.current.includes(el))
            return;
        refArray.current.splice(index, 0, el);
    }, []);
    var resizeHandler = function () {
        var result = [];
        data[0].kit.forEach(function (el, index) {
            var _a, _b;
            result.push((_b = (_a = refArray.current) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.clientHeight.toString());
        });
        setSize(result);
    };
    var removeDiscount = function (text) {
        var words = text.split(' ');
        words[words.length - 1] = '';
        return words.join(' ');
    };
    useEffect(function () {
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return function () {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.header }, { children: [_jsx("div", __assign({ className: styles.headerLeft }, { children: "QSure Benefits" })), _jsx("div", __assign({ className: styles.headerRight }, { children: data.map(function (item) { return (_jsxs("div", __assign({ className: styles.headerRightItem }, { children: [_jsxs("div", __assign({ className: styles.headerRightItemText }, { children: [_jsx("div", __assign({ className: styles.headerRightItemIcon }, { children: icons[item.name.split(' ')[1].toLowerCase()] })), _jsx("div", __assign({ className: styles.headerRightItemName }, { children: item.name.split(' ')[1] }))] })), _jsx("div", __assign({ className: styles.headerRightItemBtn }, { children: _jsx(Button, { label: 'Get', classes: [styles.headerRightItemBtnEl], withoutArrow: true, handler: btnHandler }) }))] }), item.id)); }) }))] })), _jsxs("div", __assign({ className: styles.table }, { children: [_jsx("div", __assign({ className: styles.tableLeft }, { children: data[0].kit.map(function (el, index) { return (_jsx("div", __assign({ ref: function (el) { return addToRefs(el, index); }, className: styles.tableLeftItem }, { children: index === 0 ? removeDiscount(el.text) : el.text }), el.id)); }) })), _jsx("div", __assign({ className: styles.tableRight }, { children: data.map(function (item) { return (_jsx("div", __assign({ className: styles.tableRightItem }, { children: item.kit.map(function (el, index) { return (_jsx("div", __assign({ style: { height: size[index] + 'px' }, className: styles.tableRightItemIcon }, { children: el.isPresent ? _jsx(Checked, {}) : _jsx(Unchecked, {}) }), el.id)); }) }), item.id)); }) }))] }))] }));
};
