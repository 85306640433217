var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import { getCroppedImg } from './utils';
import styles from './index.module.sass';
export var ImageCropper = function (_a) {
    var initImage = _a.initImage, onSave = _a.onSave, closeModal = _a.closeModal;
    // const [image, setImage] = useState<any>(initImage)
    var _b = useState(null), croppedArea = _b[0], setCroppedArea = _b[1];
    var _c = useState({ x: 0, y: 0 }), crop = _c[0], setCrop = _c[1];
    var _d = useState(1), zoom = _d[0], setZoom = _d[1];
    var onCropComplete = function (croppedAreaPercentage, croppedAreaPixels) {
        setCroppedArea(croppedAreaPixels);
    };
    var onDownload = function () { return __awaiter(void 0, void 0, void 0, function () {
        var picture;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCroppedImg(initImage, croppedArea)];
                case 1:
                    picture = _a.sent();
                    onSave(picture);
                    closeModal();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx("div", __assign({ className: styles.containerCropper }, { children: initImage && (_jsx("div", __assign({ className: styles.cropper }, { children: _jsx(Cropper, { image: initImage, crop: crop, zoom: zoom, aspect: 1, onCropChange: setCrop, onZoomChange: setZoom, onCropComplete: onCropComplete, showGrid: false, cropShape: "round" }) }))) })), _jsxs("div", __assign({ className: styles.controls }, { children: [_jsxs("div", __assign({ className: styles.slider }, { children: [_jsx("div", __assign({ className: styles.zoomTitle }, { children: t('label_zoom') })), _jsx("input", { type: "range", min: 1, max: 3, step: 0.1, value: zoom, onChange: function (e) { return setZoom(Number(e.target.value)); } })] })), _jsx(Button, __assign({ primary: true, handler: onDownload }, { children: t('btn_save') }))] }))] })));
};
