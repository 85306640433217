var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { t } from 'i18next';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { showModal } from '@core/components/modals';
import { ConfirmationPopup } from '@core/components/modals/confirmation';
import { config } from '@core/config/config';
import bookingService from '@core/services/booking';
import { serviceMap } from '@core/services/map';
import { navigate } from '@core/services/navigator';
import serviceOffices from '@core/services/offices';
import profileService from '@core/services/prfofile';
import surveysService from '@core/services/surveys';
import { SID } from '@core/shared/models/survey';
import { handleOpenSelectPatient } from '@core/shared/utils/bookingUtils';
import { setAlert } from '@core/store/reducers/alertSlice';
import { addPrescriptions, cleanInsuranceVerification, deletePrescriptions, getBlockedProceduresReq, getBlockedProceduresReset, getBlockedProceduresSuccess, getOffice, getPrescriptions, getProcedures, getProceduresByCurrentOffices, handleClickButton, handleSelectSearchedPerson, initBooking, initBookingSuccess, initPrebooking, initPrebookingSuccess, replaceInsurances, searchPatientByMRN, searchPatientByName, setBookingData, setLoading, setOffice, setOfficesList, setPerson, validateInsurance, } from '@core/store/reducers/bookingSlice';
import { setFamily } from '@core/store/reducers/profileSlice';
import { fetchDocumentsByPersonIdAsync } from '@core/store/sagas/docs';
import { handlerErrors } from '@core/store/sagas/errors';
import { getEventsAsync } from '@core/store/sagas/events';
import styles from '@core/components/modals/selectPatient/index.module.sass';
var ERRORS = {
    document_not_found_error: 'search_patient_not_found_error',
    not_found_error: 'search_patient_not_found_error',
};
var showPrebookingModal = function (person_id) {
    var closePreModal = showModal(ConfirmationPopup, {
        title: t('prebooking_please_add_prebooking'),
        primaryButtonLabel: t('btn_continue'),
        primaryButtonHandler: function () {
            closePreModal();
            navigate("/prebooking/".concat(person_id));
        },
        secondaryButtonHandler: function () {
            closePreModal();
        },
        closeConfirmation: function () { return closePreModal(); },
        className: styles.confirmationModal,
        buttonClassName: styles.confirmationModalBtn,
        buttonGroupClassName: styles.confirmationModalBtnGroup,
        isBottomMobile: true,
    }).closeModal;
};
function checkPrebookingIsSubmitted(person_id) {
    var resp;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, call(surveysService.createSurveyId, SID.PREBOOKING, {
                    person_id: person_id,
                })];
            case 1:
                resp = _c.sent();
                return [2 /*return*/, ((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.status) === 'submitted' || ((_b = resp === null || resp === void 0 ? void 0 : resp.data) === null || _b === void 0 ? void 0 : _b.status) === 'completed'];
        }
    });
}
function initPrebookingAsync(_a) {
    var response, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 7]);
                return [4 /*yield*/, call(profileService.getPersonById, payload.person_id)];
            case 1:
                response = _b.sent();
                return [4 /*yield*/, put(initPrebookingSuccess(response.data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 7];
            case 3:
                e_1 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown_try_again')))];
            case 4:
                _b.sent();
                return [4 /*yield*/, navigate('/')];
            case 5:
                _b.sent();
                return [4 /*yield*/, handleOpenSelectPatient(navigate)];
            case 6:
                _b.sent();
                throw e_1;
            case 7: return [2 /*return*/];
        }
    });
}
function initBookingAsync(_a) {
    var personResp, prebookingIsSubmitted, order, existingOrder, newOrder, e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 12, , 16]);
                return [4 /*yield*/, call(profileService.getPersonById, payload.person_id)];
            case 1:
                personResp = _b.sent();
                return [4 /*yield*/, call(checkPrebookingIsSubmitted, payload.person_id)];
            case 2:
                prebookingIsSubmitted = _b.sent();
                if (!!prebookingIsSubmitted) return [3 /*break*/, 5];
                return [4 /*yield*/, navigate('/')];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(showPrebookingModal, payload.person_id)];
            case 4:
                _b.sent();
                return [2 /*return*/];
            case 5:
                order = void 0;
                if (!payload.order_id) return [3 /*break*/, 7];
                return [4 /*yield*/, call(bookingService.getEventById, payload.order_id)];
            case 6:
                existingOrder = (_b.sent()).data;
                order = existingOrder;
                return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, call(bookingService.createDraft, {
                    patient_person_id: payload.person_id,
                    practice_id: config.practiceId,
                })];
            case 8:
                newOrder = (_b.sent()).data;
                order = newOrder;
                _b.label = 9;
            case 9: return [4 /*yield*/, call(fetchDocumentsByPersonIdAsync, { payload: { person_id: payload.person_id } })];
            case 10:
                _b.sent();
                return [4 /*yield*/, put(initBookingSuccess({
                        activeOrder: order,
                        person: personResp.data,
                    }))];
            case 11:
                _b.sent();
                return [3 /*break*/, 16];
            case 12:
                e_2 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown_try_again')))];
            case 13:
                _b.sent();
                return [4 /*yield*/, navigate('/')];
            case 14:
                _b.sent();
                return [4 /*yield*/, handleOpenSelectPatient(navigate)];
            case 15:
                _b.sent();
                throw e_2;
            case 16: return [2 /*return*/];
        }
    });
}
function addInsuranceSaga(_a) {
    var _b, activeOrder, person_id, answeredSectionsId, e_3;
    var insurance = _a.insurance;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 7, , 9]);
                return [4 /*yield*/, select(function (state) { return state; })];
            case 1:
                _b = (_c.sent()).booking, activeOrder = _b.activeOrder, person_id = _b.patient._id, answeredSectionsId = _b.answeredSectionsId;
                return [4 /*yield*/, call(bookingService.addInsurance, {
                        order_id: (activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder._id) || '',
                        data: { insurance_id: insurance._id, person_id: person_id },
                    })];
            case 2:
                _c.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'selectedInsurance', value: insurance }))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(setBookingData({
                        key: 'answeredSectionsId',
                        value: __spreadArray(__spreadArray([], answeredSectionsId, true), ['confirmInsurance'], false),
                    }))];
            case 4:
                _c.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'currentSection', value: 'selectProceduresGroup' }))];
            case 5:
                _c.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'addInsuranceReqStat', value: null }))];
            case 6:
                _c.sent();
                return [3 /*break*/, 9];
            case 7:
                e_3 = _c.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'addInsuranceReqStat', value: null }))];
            case 8:
                _c.sent();
                throw e_3;
            case 9: return [2 /*return*/];
        }
    });
}
function initCovidProcedures() {
    var answeredSectionsId, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state; })];
            case 1:
                answeredSectionsId = (_a.sent()).booking.answeredSectionsId;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(getProceduresAsync)];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(getBlockedProceduresAsync, {})];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'initCovidProceduresReqStat', value: null }))];
            case 5:
                _a.sent();
                return [4 /*yield*/, put(setBookingData({
                        key: 'answeredSectionsId',
                        value: __spreadArray(__spreadArray([], answeredSectionsId, true), ['selectProceduresGroup'], false),
                    }))];
            case 6:
                _a.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'currentSection', value: 'selectReasons' }))];
            case 7:
                _a.sent();
                return [3 /*break*/, 10];
            case 8:
                e_4 = _a.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'initCovidProceduresReqStat', value: null }))];
            case 9:
                _a.sent();
                throw e_4;
            case 10: return [2 /*return*/];
        }
    });
}
function initOtherProcedures() {
    var _a, answeredSectionsId, activeOrder, patient, data, e_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 9]);
                return [4 /*yield*/, select(function (state) { return state; })];
            case 1:
                _a = (_b.sent()).booking, answeredSectionsId = _a.answeredSectionsId, activeOrder = _a.activeOrder, patient = _a.patient;
                return [4 /*yield*/, call(bookingService.updateOrder, {
                        order_id: activeOrder._id,
                        items: {
                            items: [
                                {
                                    type: 'draft_panel',
                                    person_id: patient._id,
                                },
                            ],
                        },
                    })];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(setBookingData({ key: 'activeOrder', value: data }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'initOtherProceduresReqStat', value: null }))];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({
                        key: 'answeredSectionsId',
                        value: __spreadArray(__spreadArray([], answeredSectionsId, true), ['selectProceduresGroup'], false),
                    }))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'currentSection', value: 'uploadPrescriptionsSelectType' }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7:
                e_5 = _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'uploadPrescriptionsSelectType', value: null }))];
            case 8:
                _b.sent();
                throw e_5;
            case 9: return [2 /*return*/];
        }
    });
}
export function handleClickButtonAsync(_a) {
    var answeredSectionsId, type;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.booking; })];
            case 1:
                answeredSectionsId = (_b.sent()).answeredSectionsId;
                type = payload.type;
                if (!(type === 'confirmInsurance')) return [3 /*break*/, 3];
                return [4 /*yield*/, call(addInsuranceSaga, { insurance: payload.data.selectedInsurance })];
            case 2:
                _b.sent();
                return [2 /*return*/];
            case 3:
                if (!(type === 'selectProceduresGroup')) return [3 /*break*/, 7];
                if (!(payload.data.group === 'covid')) return [3 /*break*/, 5];
                return [4 /*yield*/, call(initCovidProcedures)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                if (!(payload.data.group === 'other')) return [3 /*break*/, 7];
                return [4 /*yield*/, call(initOtherProcedures)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7:
                if (!(type === 'uploadPrescriptions')) return [3 /*break*/, 9];
                return [4 /*yield*/, put(addPrescriptions())];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9:
                if (!(type === 'uploadPrescriptionsSkip')) return [3 /*break*/, 12];
                return [4 /*yield*/, put(setBookingData({
                        key: 'answeredSectionsId',
                        value: __spreadArray(__spreadArray([], answeredSectionsId, true), ['uploadPrescriptions'], false),
                    }))];
            case 10:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'currentSection', value: 'preSelectOffice' }))];
            case 11:
                _b.sent();
                _b.label = 12;
            case 12:
                if (!(type === 'submitOrder')) return [3 /*break*/, 14];
                return [4 /*yield*/, call(submitOrderAsync)];
            case 13:
                _b.sent();
                _b.label = 14;
            case 14:
                if (!(type === 'askDoctor')) return [3 /*break*/, 16];
                return [4 /*yield*/, call(cancelOrderAsync)];
            case 15:
                _b.sent();
                _b.label = 16;
            case 16: return [2 /*return*/];
        }
    });
}
export function getProceduresAsync() {
    var data, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(bookingService.getProcedures)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(setBookingData({ key: 'procedures', value: data }))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_6 = _a.sent();
                put(setAlert(t('error_unknown')));
                throw e_6;
            case 4: return [2 /*return*/];
        }
    });
}
function getPrescriptionsSaga() {
    var activeOrder, newOrder, prescriptions, e_7;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 6, , 9]);
                return [4 /*yield*/, select(function (state) { return state.booking; })];
            case 1:
                activeOrder = (_c.sent()).activeOrder;
                return [4 /*yield*/, call(bookingService.getEventById, activeOrder._id)];
            case 2:
                newOrder = (_c.sent()).data;
                prescriptions = ((_b = (_a = newOrder === null || newOrder === void 0 ? void 0 : newOrder.metadata) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.prescription_records) || [];
                if (!prescriptions) return [3 /*break*/, 5];
                return [4 /*yield*/, put(setBookingData({ key: 'prescriptions', value: prescriptions }))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'getPrescriptionsReqStat', value: null }))];
            case 4:
                _c.sent();
                _c.label = 5;
            case 5: return [3 /*break*/, 9];
            case 6:
                e_7 = _c.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 7:
                _c.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'getPrescriptionsReqStat', value: null }))];
            case 8:
                _c.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function addPrescriptionsSaga() {
    var _a, prescriptions, activeOrder, patient, answeredSectionsId, promises, _loop_1, _i, prescriptions_1, prescription, error_1;
    var _this = this;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.booking; })];
            case 1:
                _a = _b.sent(), prescriptions = _a.prescriptions, activeOrder = _a.activeOrder, patient = _a.patient, answeredSectionsId = _a.answeredSectionsId;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 12, , 16]);
                promises = [];
                _loop_1 = function (prescription) {
                    var _c, _d, _e, _f;
                    return __generator(this, function (_g) {
                        switch (_g.label) {
                            case 0:
                                if (!(prescription.confirmed !== undefined && prescription.confirmed === false)) return [3 /*break*/, 2];
                                _d = (_c = promises).push;
                                return [4 /*yield*/, call(bookingService.deletePrescription, activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder._id, prescription === null || prescription === void 0 ? void 0 : prescription.record_id)];
                            case 1:
                                _d.apply(_c, [_g.sent()]);
                                return [2 /*return*/, "continue"];
                            case 2:
                                if (prescription.confirmed) {
                                    return [2 /*return*/, "continue"];
                                }
                                _f = (_e = promises).push;
                                return [4 /*yield*/, call(function () {
                                        // eslint-disable-next-line no-async-promise-executor
                                        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                                            var data, formData, key, result, e_8;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        _a.trys.push([0, 6, , 7]);
                                                        return [4 /*yield*/, bookingService.startUploadPrescription((activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder._id) || '', patient._id)];
                                                    case 1:
                                                        data = (_a.sent()).data;
                                                        formData = new FormData();
                                                        for (key in data.fields) {
                                                            formData.append(key, data.fields[key]);
                                                        }
                                                        formData.append('file', prescription.url);
                                                        if (!data.url) return [3 /*break*/, 4];
                                                        return [4 /*yield*/, fetch(data.url, {
                                                                body: formData,
                                                                method: 'POST',
                                                                mode: 'no-cors',
                                                            })];
                                                    case 2:
                                                        _a.sent();
                                                        return [4 /*yield*/, bookingService.confirmUploadPrescription((activeOrder === null || activeOrder === void 0 ? void 0 : activeOrder._id) || '', data.id)];
                                                    case 3:
                                                        result = _a.sent();
                                                        resolve(result);
                                                        return [3 /*break*/, 5];
                                                    case 4: throw {};
                                                    case 5: return [3 /*break*/, 7];
                                                    case 6:
                                                        e_8 = _a.sent();
                                                        reject(e_8);
                                                        return [3 /*break*/, 7];
                                                    case 7: return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                    })];
                            case 3:
                                _f.apply(_e, [_g.sent()]);
                                return [2 /*return*/];
                        }
                    });
                };
                _i = 0, prescriptions_1 = prescriptions;
                _b.label = 3;
            case 3:
                if (!(_i < prescriptions_1.length)) return [3 /*break*/, 6];
                prescription = prescriptions_1[_i];
                return [5 /*yield**/, _loop_1(prescription)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                _i++;
                return [3 /*break*/, 3];
            case 6: return [4 /*yield*/, all(promises)];
            case 7:
                _b.sent();
                return [4 /*yield*/, call(getPrescriptionsSaga)];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'addPrescriptionsReqStat', value: null }))];
            case 9:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({
                        key: 'answeredSectionsId',
                        value: __spreadArray(__spreadArray([], answeredSectionsId, true), ['uploadPrescriptions'], false),
                    }))];
            case 10:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'currentSection', value: 'preSelectOffice' }))];
            case 11:
                _b.sent();
                return [3 /*break*/, 16];
            case 12:
                error_1 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown_try_again')))];
            case 13:
                _b.sent();
                return [4 /*yield*/, call(getPrescriptionsSaga)];
            case 14:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'addPrescriptionsReqStat', value: null }))];
            case 15:
                _b.sent();
                return [3 /*break*/, 16];
            case 16: return [2 /*return*/];
        }
    });
}
function deletePrescriptionsSaga(_a) {
    var _b, activeOrder, prescriptions, e_9;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 6, , 8]);
                if (!payload.record_id) return [3 /*break*/, 5];
                return [4 /*yield*/, select(function (state) { return state.booking; })];
            case 1:
                _b = _c.sent(), activeOrder = _b.activeOrder, prescriptions = _b.prescriptions;
                return [4 /*yield*/, call(bookingService.deletePrescription, activeOrder._id || '', payload.record_id)];
            case 2:
                _c.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'deletePrescriptionsReqStat', value: null }))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(setBookingData({
                        key: 'prescriptions',
                        value: prescriptions.filter(function (prescription) { return (prescription === null || prescription === void 0 ? void 0 : prescription.record_id) !== payload.record_id; }),
                    }))];
            case 4:
                _c.sent();
                _c.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                e_9 = _c.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown_try_again')))];
            case 7:
                _c.sent();
                throw e_9;
            case 8: return [2 /*return*/];
        }
    });
}
export function submitOrderAsync() {
    var _a, office, selectedLabs, reasons, additionalReasons, person_id, activeOrder, items, e_10;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state; })];
            case 1:
                _a = (_b.sent()).booking, office = _a.office, selectedLabs = _a.selectedLabs, reasons = _a.reasons, additionalReasons = _a.additionalReasons, person_id = _a.patient._id, activeOrder = _a.activeOrder;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 12, , 15]);
                if (!!activeOrder.items.some(function (el) { return el.type === 'draft_panel'; })) return [3 /*break*/, 5];
                return [4 /*yield*/, selectedLabs.map(function (el) { return ({
                        type: 'procedure',
                        procedure_id: el._id,
                        person_id: person_id,
                        price: el.price,
                        reasons: reasons
                            .map(function (reason) {
                            if (reason[el._id]) {
                                return __assign({ id: reason[el._id].id }, ((additionalReasons === null || additionalReasons === void 0 ? void 0 : additionalReasons[el._id]) && {
                                    additional_reasons: additionalReasons === null || additionalReasons === void 0 ? void 0 : additionalReasons[el._id].map(function (el) { return el.id; }),
                                }));
                            }
                        })
                            .filter(Boolean),
                        coverage_type: 'insurance',
                    }); })];
            case 3:
                items = _b.sent();
                return [4 /*yield*/, call(bookingService.updateOrderProcedures, {
                        order_id: activeOrder._id,
                        data: __assign({ items: items }, (!!office && { office_id: office.id })),
                    })];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                if (!office) return [3 /*break*/, 7];
                return [4 /*yield*/, call(bookingService.updateOrderProcedures, {
                        order_id: activeOrder._id,
                        data: __assign({ items: [
                                {
                                    type: 'draft_panel',
                                    person_id: person_id,
                                },
                            ] }, (!!office && { office_id: office.id })),
                    })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [4 /*yield*/, call(bookingService.submitOrder, activeOrder._id)];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'submitOrderReqStat', value: 'success' }))];
            case 9:
                _b.sent();
                return [4 /*yield*/, call(getBlockedProceduresAsync, {})
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                ];
            case 10:
                _b.sent();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return [4 /*yield*/, call(getEventsAsync, {
                        payload: { limit: 5, additional: false, excludeStatuses: ['canceled'] },
                    })];
            case 11:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                _b.sent();
                return [3 /*break*/, 15];
            case 12:
                e_10 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown_try_again')))];
            case 13:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'submitOrderReqStat', value: null }))];
            case 14:
                _b.sent();
                throw e_10;
            case 15: return [2 /*return*/];
        }
    });
}
export function cancelOrderAsync() {
    var _a, activeOrder, answeredSectionsId, e_11;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state; })];
            case 1:
                _a = (_b.sent()).booking, activeOrder = _a.activeOrder, answeredSectionsId = _a.answeredSectionsId;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 7, , 10]);
                return [4 /*yield*/, call(bookingService.deleteOrder, { order_id: activeOrder._id })];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'cancelingOrderReqStat', value: 'success' }))];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({
                        key: 'answeredSectionsId',
                        value: __spreadArray(__spreadArray([], answeredSectionsId, true), ['uploadPrescriptionsSelectType'], false),
                    }))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'currentSection', value: 'cancelOrder' }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 10];
            case 7:
                e_11 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown_try_again')))];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'cancelingOrderReqStat', value: null }))];
            case 9:
                _b.sent();
                throw e_11;
            case 10: return [2 /*return*/];
        }
    });
}
export function getOfficeAsync(_a) {
    var data, address, e_12;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4 /*yield*/, put(setBookingData({ key: 'getOfficeReqStat', value: 'loading' }))];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(serviceMap.getOfficeById, payload)];
            case 2:
                data = (_b.sent()).data;
                address = "".concat(data.place.address.state, ", ").concat(data.place.address.city, ", ").concat(data.place.address.line);
                return [4 /*yield*/, put(setOffice({
                        id: data._id,
                        name: data.name,
                        address: address,
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setBookingData({ key: 'getOfficeReqStat', value: 'success' }))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                e_12 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 6:
                _b.sent();
                throw e_12;
            case 7: return [2 /*return*/];
        }
    });
}
//SUPER
export function searchPatientByNameAsync(_a) {
    var values, callback, searchPatientRequestPayload, data, e_13, error, currentError;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                values = payload.values, callback = payload.callback;
                searchPatientRequestPayload = {
                    given: [],
                    birth_date: null,
                    family: null,
                    contact: {
                        type: null,
                        identifier: '',
                        new: false,
                    },
                };
                Object.keys(values).forEach(function (key) {
                    if (key === 'email' || key === 'phone') {
                        searchPatientRequestPayload.contact.type = key;
                        searchPatientRequestPayload.contact.identifier = values[key];
                    }
                    if (key === 'firstName')
                        searchPatientRequestPayload.given[0] = values[key];
                    if (key === 'middleName' && values[key])
                        searchPatientRequestPayload.given[1] = values[key];
                    if (key === 'lastName')
                        searchPatientRequestPayload.family = values[key];
                    if (key === 'birth_date')
                        searchPatientRequestPayload.birth_date = values[key];
                }, []);
                _d.label = 1;
            case 1:
                _d.trys.push([1, 6, , 8]);
                return [4 /*yield*/, call(profileService.searchPerson, searchPatientRequestPayload)];
            case 2:
                data = (_d.sent()).data;
                return [4 /*yield*/, put(setPerson(data))];
            case 3:
                _d.sent();
                if (!callback) return [3 /*break*/, 5];
                return [4 /*yield*/, call(callback)];
            case 4:
                _d.sent();
                _d.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                e_13 = _d.sent();
                error = e_13;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error;
                return [4 /*yield*/, put(setAlert(t(ERRORS[currentError] || 'error_unknown')))];
            case 7:
                _d.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function searchPatientByMRNAsync(_a) {
    var values, callback, data, e_14, error, currentError;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                values = payload.values, callback = payload.callback;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 6, , 8]);
                return [4 /*yield*/, call(profileService.searchPersonByMRN, values)];
            case 2:
                data = (_d.sent()).data;
                return [4 /*yield*/, put(setPerson(data))];
            case 3:
                _d.sent();
                if (!callback) return [3 /*break*/, 5];
                return [4 /*yield*/, call(callback)];
            case 4:
                _d.sent();
                _d.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                e_14 = _d.sent();
                error = e_14;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error;
                return [4 /*yield*/, put(setAlert(t(ERRORS[currentError] || 'error_unknown')))];
            case 7:
                _d.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function handleSelectSearchedPersonAsync(_a) {
    var _id, data, e_15, error, currentError;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state; })];
            case 1:
                _id = (_d.sent()).booking.person._id;
                _d.label = 2;
            case 2:
                _d.trys.push([2, 9, 11, 13]);
                return [4 /*yield*/, put(setLoading(true))];
            case 3:
                _d.sent();
                return [4 /*yield*/, call(profileService.getFamilyByPersonId, _id)];
            case 4:
                data = (_d.sent()).data;
                if (!data.length) return [3 /*break*/, 6];
                // Patient has family
                return [4 /*yield*/, put(setFamily(data))];
            case 5:
                // Patient has family
                _d.sent();
                _d.label = 6;
            case 6:
                if (!(payload === null || payload === void 0 ? void 0 : payload.callback)) return [3 /*break*/, 8];
                return [4 /*yield*/, call(payload.callback)];
            case 7:
                _d.sent();
                _d.label = 8;
            case 8: return [3 /*break*/, 13];
            case 9:
                e_15 = _d.sent();
                error = e_15;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error;
                return [4 /*yield*/, put(setAlert(t(ERRORS[currentError] || 'error_unknown')))];
            case 10:
                _d.sent();
                return [3 /*break*/, 13];
            case 11: return [4 /*yield*/, put(setLoading(false))];
            case 12:
                _d.sent();
                return [7 /*endfinally*/];
            case 13: return [2 /*return*/];
        }
    });
}
export function getProceduresByCurrentOfficesAsync(_a) {
    var offices, proceduresResponse_1, allowedProcedures, e_16, error, currentError;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 7, , 9]);
                return [4 /*yield*/, call(serviceOffices.getOffices)];
            case 1:
                offices = (_d.sent()).data.results;
                return [4 /*yield*/, put(setOfficesList(offices))];
            case 2:
                _d.sent();
                return [4 /*yield*/, call(bookingService.getProcedures)];
            case 3:
                proceduresResponse_1 = _d.sent();
                allowedProcedures = offices.reduce(function (acc, office) {
                    var preparedOfficesProcedures = office.procedures.map(function (procedure) { return procedure.id; });
                    var temp = proceduresResponse_1.data.filter(function (procedure) {
                        return preparedOfficesProcedures.some(function (officesProcedure) { return officesProcedure === procedure._id; }) && !acc.some(function (accEl) { return accEl._id === procedure._id; });
                    });
                    return __spreadArray(__spreadArray([], acc, true), temp, true);
                }, []);
                return [4 /*yield*/, put(setBookingData({ key: 'procedures', value: allowedProcedures }))];
            case 4:
                _d.sent();
                if (!payload.callback) return [3 /*break*/, 6];
                return [4 /*yield*/, call(payload.callback)];
            case 5:
                _d.sent();
                _d.label = 6;
            case 6: return [3 /*break*/, 9];
            case 7:
                e_16 = _d.sent();
                error = e_16;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error;
                return [4 /*yield*/, put(setAlert(t(ERRORS[currentError] || 'error_unknown')))];
            case 8:
                _d.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function getBlockedProceduresAsync(_a) {
    var data, e_17;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(bookingService.getBlockedProcedures, payload === null || payload === void 0 ? void 0 : payload.person_id)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(getBlockedProceduresSuccess(data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                e_17 = _b.sent();
                return [4 /*yield*/, put(getBlockedProceduresReset())];
            case 4:
                _b.sent();
                throw e_17;
            case 5: return [2 /*return*/];
        }
    });
}
export function validateInsuranceAsync(_a) {
    var data, e_18;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(bookingService.validateInsuranceById, payload.personId, payload.insuranceId)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(replaceInsurances(data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                e_18 = _b.sent();
                return [4 /*yield*/, put(cleanInsuranceVerification())];
            case 4:
                _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function bookingWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(initBooking, handlerErrors(initBookingAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(initPrebooking, handlerErrors(initPrebookingAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(getProcedures, handlerErrors(getProceduresAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(getOffice.type, handlerErrors(getOfficeAsync))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(handleClickButton, handlerErrors(handleClickButtonAsync))];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(getPrescriptions, handlerErrors(getPrescriptionsSaga))];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(addPrescriptions, handlerErrors(addPrescriptionsSaga))];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(deletePrescriptions, handlerErrors(deletePrescriptionsSaga))];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(searchPatientByName, handlerErrors(searchPatientByNameAsync))];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(searchPatientByMRN, handlerErrors(searchPatientByMRNAsync))];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeEvery(getProceduresByCurrentOffices, handlerErrors(getProceduresByCurrentOfficesAsync))];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeEvery(getBlockedProceduresReq, handlerErrors(getBlockedProceduresAsync))];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeEvery(handleSelectSearchedPerson, handlerErrors(handleSelectSearchedPersonAsync))];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeEvery(validateInsurance, handlerErrors(validateInsuranceAsync))];
            case 14:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
