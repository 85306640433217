import moment from 'moment';
import * as allMoment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
export var checkTimeZ = function (time) {
    if (!(time === null || time === void 0 ? void 0 : time.length))
        return time;
    return time.slice(-1) === 'Z' ? time : time + 'Z';
};
export var isValidDate = function (date, dateFormat) {
    if (dateFormat === void 0) { dateFormat = ''; }
    if (!date)
        return false;
    if (dateFormat !== '') {
        return moment(date, dateFormat).isValid();
    }
    return moment(date).isValid();
};
export var formatDateIfNotEmpty = function (date, dateFormat, needCheckZ, inputDateFormat) {
    if (needCheckZ === void 0) { needCheckZ = true; }
    if (inputDateFormat === void 0) { inputDateFormat = ''; }
    if (!isValidDate(date))
        return '';
    var result = date;
    if (needCheckZ) {
        result = checkTimeZ(date);
    }
    if (inputDateFormat.length > 0) {
        return moment(result, inputDateFormat).format(dateFormat);
    }
    return moment(result).format(dateFormat);
};
export var getUntilTime = function (to, type) {
    if (!moment(to).isValid()) {
        console.warn('invalid date');
        return "00s";
    }
    if (moment(to) <= moment()) {
        return "00s";
    }
    momentDurationFormatSetup(allMoment);
    var duration = moment.duration(moment(to).diff(moment(), 'milliseconds'));
    if (type === 'onlyHours') {
        return duration.add(1, 'h').format("H [".concat(+duration.hours() > 1 ? 'hours' : 'hour', "]"));
    }
    if (duration.hours() <= 0 && type !== 'hours') {
        return duration.format('mm[m] ss[s]');
    }
    return duration.format('hh[h] mm[m]');
};
