var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import clsx from 'clsx';
import ArrowRight from '@core/shared/svg/arrow-right';
import styles from './index.module.sass';
export var QuestionForm = function (_a) {
    var question = _a.question, answer = _a.answer;
    var _b = useState(false), isShown = _b[0], setShown = _b[1];
    var ref = useRef(null);
    // function useOutsideClicker(ref: any, func: any) {
    //   useEffect(() => {
    //     function handleClickOutside(event: any) {
    //       if (event.target.closest('.preventOutside')) {
    //         return
    //       }
    //       if (event.target.classList.contains(`preventOutside`)) {
    //         return
    //       }
    //       if (ref.current && !ref.current.contains(event.target)) {
    //         func()
    //       }
    //     }
    //     document.addEventListener('mousedown', handleClickOutside)
    //     return () => {
    //       document.removeEventListener('mousedown', handleClickOutside)
    //     }
    //   }, [])
    // }
    //
    // useOutsideClicker(ref, () => {
    //   setShown(false)
    // })
    return (_jsxs("div", __assign({ className: styles.main }, { children: [_jsxs("div", __assign({ ref: ref, className: styles.mainTitle, onClick: function () { return setShown(!isShown); } }, { children: [_jsx("div", __assign({ className: styles.mainTitleText }, { children: question })), _jsx("div", __assign({ className: !isShown ? clsx(styles.arrow, styles.arrowDown) : clsx(styles.arrow, styles.arrowUp) }, { children: _jsx(ArrowRight, {}) }))] })), isShown && (_jsx("div", __assign({ className: clsx(styles.mainDrop, "preventOutside") }, { children: answer || 'Nothing to show' })))] })));
};
