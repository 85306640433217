var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Lock = function () { return (_jsxs("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "14", cy: "17", r: "2", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19 10H9C7.34315 10 6 11.3431 6 13V21C6 22.6569 7.34315 24 9 24H19C20.6569 24 22 22.6569 22 21V13C22 11.3431 20.6569 10 19 10ZM8 13C8 12.4477 8.44772 12 9 12H19C19.5523 12 20 12.4477 20 13V21C20 21.5523 19.5523 22 19 22H9C8.44772 22 8 21.5523 8 21V13Z", fill: "black" }), _jsx("path", { d: "M10 11V9C10 6.79086 11.7909 5 14 5C16.2091 5 18 6.79086 18 9V11H20V9C20 5.68629 17.3137 3 14 3C10.6863 3 8 5.68629 8 9V11H10Z", fill: "black" })] }))); };
export default Lock;
