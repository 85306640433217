var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { t } from 'i18next';
import { Button } from '@components/button';
import { checkSubscriptionDetail } from '@core/shared/utils/subscriptionUtils';
import useWindowDimensions from '@core/shared/utils/widthHook';
import styles from './index.module.sass';
export var PlanCard = function (_a) {
    var subscriptionProduct = _a.subscriptionProduct, onClick = _a.onClick, isActive = _a.isActive, price = _a.price, isLoading = _a.isLoading, currentSubscription = _a.currentSubscription;
    var isMobile = useWindowDimensions().isMobile;
    var subDetail = checkSubscriptionDetail({ subscription: subscriptionProduct });
    var btnLabel = isActive
        ? t('label_your_plan')
        : isMobile
            ? t('label_switch')
            : t('label_switch_to_plan', { plan: t("label_qsure_".concat(subDetail === null || subDetail === void 0 ? void 0 : subDetail.type)) });
    return (_jsxs("div", __assign({ className: styles.card }, { children: [_jsxs("div", __assign({ className: styles.left }, { children: [_jsxs("div", __assign({ className: styles.planTitle }, { children: [subDetail.icon(), "".concat(subscriptionProduct.name, " ").concat(t("label_plan"))] })), _jsxs("div", __assign({ className: styles.planPrice }, { children: [_jsx("div", __assign({ className: styles.planPriceNumber }, { children: "$".concat(price) })), _jsx("div", __assign({ className: styles.planPricePerMonth }, { children: "/ per month" }))] }))] })), _jsx("div", __assign({ className: styles.right }, { children: (isActive || !currentSubscription) && (_jsx(Button, { classes: [styles.btn, isActive && styles.btnDisabled], withoutArrow: true, handler: onClick, label: btnLabel, loading: isLoading, disabled: isActive })) }))] })));
};
