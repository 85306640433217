var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import Arrow from '@core/shared/svg/arrow-right';
import styles from './index.module.sass';
var ButtonLink = function (_a) {
    var handleClick = _a.handleClick, loading = _a.loading, children = _a.children, className = _a.className, wrapperClassName = _a.wrapperClassName, description = _a.description, _b = _a.type, type = _b === void 0 ? 'secondary' : _b, _c = _a.showArrow, showArrow = _c === void 0 ? true : _c;
    var _styles = {
        secondary: styles.buttonLinkSecondary,
        primary: styles.buttonLinkPrimary,
    };
    return (_jsxs("div", __assign({ className: clsx(styles.buttonLink, _styles[type], className), onClick: handleClick }, { children: [_jsx("div", __assign({ className: clsx(styles.buttonLinkName, wrapperClassName) }, { children: children })), _jsxs("div", __assign({ className: clsx(styles.buttonLinkRight) }, { children: [description && _jsx("span", __assign({ className: clsx(styles.buttonLinkDescription) }, { children: description })), loading ? (_jsx("div", { className: clsx(styles.bubbleLoader, styles.buttonLinkLoader) })) : showArrow ? (_jsx(Arrow, {})) : (_jsx(_Fragment, {}))] }))] })));
};
export default ButtonLink;
