var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Button } from '@core/components/buttons/button';
import { Input } from '@core/components/formComponents/input';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe, } from '@stripe/react-stripe-js';
import styles from './index.module.sass';
var ELEMENT_OPTIONS = {
    style: {
        base: {
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '1.25',
            color: '#000000',
            '::placeholder': {
                color: '#d1d1d6',
            },
        },
        invalid: {
            color: '#000000',
        },
    },
};
export var PaymentMethodForm = function (_a) {
    var onSubmit = _a.onSubmit;
    var stripe = useStripe();
    var elements = useElements();
    var _b = useState(null), cardNumber = _b[0], setCardNumber = _b[1];
    var _c = useState(null), cardExp = _c[0], setCardExp = _c[1];
    var _d = useState(null), cardCvc = _d[0], setCardCvc = _d[1];
    var _e = useState(true), isBtnDisabled = _e[0], setBtnDisabled = _e[1];
    var isLoading = useSelector(function (state) { return state.payments; }).isLoading;
    var buttonRef = useRef(null);
    var _f = useForm(), register = _f.register, control = _f.control;
    var checkIsValid = function () {
        return (stripe &&
            cardNumber &&
            cardExp &&
            cardCvc &&
            cardNumber.complete &&
            cardExp.complete &&
            cardCvc.complete);
    };
    useEffect(function () {
        var isValid = checkIsValid();
        setBtnDisabled(!isValid);
    }, [stripe, cardNumber, cardExp, cardCvc]);
    useEffect(function () {
        if ((cardNumber === null || cardNumber === void 0 ? void 0 : cardNumber.complete) && !(cardCvc === null || cardCvc === void 0 ? void 0 : cardCvc.complete) && !(cardExp === null || cardExp === void 0 ? void 0 : cardExp.complete)) {
            var cardExpElement = elements.getElement(CardExpiryElement);
            cardExpElement.focus();
        }
    }, [cardNumber]);
    useEffect(function () {
        if (cardExp === null || cardExp === void 0 ? void 0 : cardExp.complete) {
            var cardCvcElement = elements.getElement(CardCvcElement);
            cardCvcElement.focus();
        }
    }, [cardExp]);
    var focusCardNumber = function () {
        var cardNumberElement = elements.getElement(CardNumberElement);
        cardNumberElement.focus();
    };
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var cardNumberElement;
        return __generator(this, function (_a) {
            event.preventDefault();
            if (!checkIsValid()) {
                return [2 /*return*/];
            }
            cardNumberElement = elements.getElement(CardNumberElement);
            onSubmit(stripe, cardNumberElement);
            return [2 /*return*/];
        });
    }); };
    var handleCardNumberClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var cardNumberElement;
        return __generator(this, function (_a) {
            cardNumberElement = elements.getElement(CardNumberElement);
            cardNumberElement.focus();
            return [2 /*return*/];
        });
    }); };
    var handleCardExpClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var cardExpElement;
        return __generator(this, function (_a) {
            cardExpElement = elements.getElement(CardExpiryElement);
            cardExpElement.focus();
            return [2 /*return*/];
        });
    }); };
    var handleCardCvcClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var cardCvcElement;
        return __generator(this, function (_a) {
            cardCvcElement = elements.getElement(CardCvcElement);
            cardCvcElement.focus();
            return [2 /*return*/];
        });
    }); };
    return (_jsxs("form", __assign({ className: styles.paymentMethodsForm, onSubmit: handleSubmit }, { children: [_jsx("div", __assign({ onClick: handleCardNumberClick, className: styles.lineFullWidthInput }, { children: _jsx(Input, __assign({ label: t('label_payment_credit_card_number'), placeholder: t('placeholder_card_number'), type: "code", classes: [styles.input, (!cardNumber || (cardNumber === null || cardNumber === void 0 ? void 0 : cardNumber.empty)) && styles.inputEmpty], labelClassName: cardNumber && !cardNumber.empty ? styles.inputLabel : '', control: control, register: register, error: cardNumber === null || cardNumber === void 0 ? void 0 : cardNumber.error, hasLabel: cardNumber && !(cardNumber === null || cardNumber === void 0 ? void 0 : cardNumber.empty) }, { children: _jsx(CardNumberElement, { onReady: focusCardNumber, onChange: setCardNumber, options: __assign(__assign({}, ELEMENT_OPTIONS), { placeholder: t('placeholder_card_number') }) }) })) })), _jsxs("div", __assign({ className: styles.line }, { children: [_jsx("div", __assign({ onClick: handleCardExpClick }, { children: _jsx(Input, __assign({ label: t('label_payment_expiration_date'), placeholder: t('placeholder_expiration_date'), type: "code", classes: [
                                styles.input,
                                (cardCvc === null || cardCvc === void 0 ? void 0 : cardCvc.error) && styles.inputWithNearError,
                                (!cardExp || (cardExp === null || cardExp === void 0 ? void 0 : cardExp.empty)) && styles.inputEmpty,
                            ], labelClassName: cardExp && !cardExp.empty ? styles.inputLabel : '', control: control, register: register, error: cardExp === null || cardExp === void 0 ? void 0 : cardExp.error, hasLabel: cardExp && !(cardExp === null || cardExp === void 0 ? void 0 : cardExp.empty) }, { children: _jsx(CardExpiryElement, { onChange: setCardExp, options: __assign(__assign({}, ELEMENT_OPTIONS), { placeholder: t('label_payment_expiration_date') }) }) })) })), _jsx("div", __assign({ onClick: handleCardCvcClick }, { children: _jsx(Input, __assign({ label: t('label_payment_security_code'), placeholder: t('placeholder_security_code'), type: "code", classes: [
                                styles.input,
                                (cardExp === null || cardExp === void 0 ? void 0 : cardExp.error) && styles.inputWithNearError,
                                (!cardCvc || (cardCvc === null || cardCvc === void 0 ? void 0 : cardCvc.empty)) && styles.inputEmpty,
                            ], labelClassName: cardCvc && !cardCvc.empty ? styles.inputLabel : '', control: control, register: register, error: cardCvc === null || cardCvc === void 0 ? void 0 : cardCvc.error, hasLabel: cardCvc && !(cardCvc === null || cardCvc === void 0 ? void 0 : cardCvc.empty) }, { children: _jsx(CardCvcElement, { onChange: setCardCvc, options: __assign(__assign({}, ELEMENT_OPTIONS), { placeholder: t('placeholder_security_code') }) }) })) }))] })), _jsx(Button, { type: "submit", label: t('btn_add'), loading: isLoading, classes: [styles.btn], isEnterHandle: true, disabled: isBtnDisabled, primary: true, ref: buttonRef })] })));
};
