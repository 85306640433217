var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Phone = function () { return (_jsxs("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19 3H9C7.34315 3 6 4.34315 6 6V22C6 23.6569 7.34315 25 9 25H19C20.6569 25 22 23.6569 22 22V6C22 4.34315 20.6569 3 19 3ZM8 6C8 5.44772 8.44772 5 9 5H19C19.5523 5 20 5.44772 20 6V22C20 22.5523 19.5523 23 19 23H9C8.44772 23 8 22.5523 8 22V6Z", fill: "black" }), _jsx("circle", { cx: "14", cy: "21", r: "1", fill: "black" })] }))); };
export default Phone;
