var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    loader: true,
    user: null,
    accessItems: [],
};
export var sessionSlice = createSlice({
    name: 'session',
    initialState: initialState,
    reducers: {
        setLoader: function (state, action) {
            state.loader = action.payload;
        },
        setUser: function (state, action) {
            state.user = action.payload;
        },
        checkSession: function (_state, _action) { },
        setAccessItems: function (state, action) {
            state.accessItems = action.payload;
        },
        startSession: function (_state, _action) { },
        switchRole: function (_state, _action) { },
        clearSession: function () { return initialState; },
    },
});
export var clearSession = (_a = sessionSlice.actions, _a.clearSession), setUser = _a.setUser, setAccessItems = _a.setAccessItems, checkSession = _a.checkSession, startSession = _a.startSession, switchRole = _a.switchRole;
export default sessionSlice.reducer;
