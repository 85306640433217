var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Checked = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "36", height: "36", viewBox: "0 0 36 36", fill: "none" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6ZM3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.5607 13.9393C24.1464 14.5251 24.1464 15.4749 23.5607 16.0607L17.5607 22.0607C16.9749 22.6464 16.0251 22.6464 15.4393 22.0607L12.4393 19.0607C11.8536 18.4749 11.8536 17.5251 12.4393 16.9393C13.0251 16.3536 13.9749 16.3536 14.5607 16.9393L16.5 18.8787L21.4393 13.9393C22.0251 13.3536 22.9749 13.3536 23.5607 13.9393Z", fill: "black" })] }))); };
export default Checked;
