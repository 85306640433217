var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from '../index.module.sass';
export var AvatarLoader = function () {
    return (_jsxs("div", __assign({ className: styles.avatar }, { children: [_jsx(Skeleton, { borderRadius: 50, height: 100, width: 100, baseColor: '#f6f6f6', highlightColor: '#eaeaea', containerClassName: styles.avatarImage }), _jsx(Skeleton, { borderRadius: 16, height: 30, width: 100, baseColor: '#f6f6f6', highlightColor: '#eaeaea', containerClassName: styles.avatarEdit })] })));
};
