var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import { mergeToDeletedAccountRequest } from '@core/store/reducers/authSlice';
import styles from './index.module.sass';
export var ReactivateAccount = function (_a) {
    var closeModal = _a.closeModal, callback = _a.callback;
    var dispatch = useDispatch();
    var mergeToDeletedAccountRegStat = useSelector(function (state) { return state.auth; }).mergeToDeletedAccountRegStat;
    var handleReactivate = function () {
        dispatch(mergeToDeletedAccountRequest({
            agreed: true,
            callback: function () {
                callback && callback();
                closeModal();
            },
        }));
    };
    var handleCreateNew = function () {
        dispatch(mergeToDeletedAccountRequest({
            agreed: false,
            callback: function () {
                callback && callback();
                closeModal();
            },
        }));
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx("h3", __assign({ className: styles.title }, { children: t('merge_to_deleted_title') })), _jsx("p", __assign({ className: styles.text }, { children: t('merge_to_deleted_description') })), _jsxs("div", __assign({ className: styles.buttons }, { children: [_jsx(Button, { disabled: mergeToDeletedAccountRegStat === 'loading', primary: true, handler: handleReactivate, label: t('btn_reactivate_account') }), _jsx(Button, { disabled: mergeToDeletedAccountRegStat === 'loading', handler: handleCreateNew, label: t('btn_create_new') })] }))] })));
};
