var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import CheckMark from '@core/shared/svg/checkMark';
import Plus from '@core/shared/svg/plus';
import { formatNumber } from '@core/shared/utils/numberUtils';
import styles from './index.module.sass';
export var PlusItem = function (_a) {
    var title = _a.title, onClick = _a.onClick, price = _a.price, className = _a.className, isActive = _a.isActive, classNameTitle = _a.classNameTitle;
    return (_jsxs("div", __assign({ className: clsx(styles.container, !!className && className, isActive && styles.containerActive), onClick: onClick }, { children: [_jsx("div", __assign({ className: clsx(styles.title, classNameTitle) }, { children: title })), _jsxs("div", __assign({ className: styles.bottom }, { children: [price && _jsx("div", __assign({ className: styles.bottomPrice }, { children: "$".concat(formatNumber(price, 0)) })), _jsx("div", __assign({ className: styles.bottomIcon }, { children: isActive ? _jsx(CheckMark, {}) : _jsx(Plus, {}) }))] }))] })));
};
