var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    verification_id: '',
    created: '',
    last_sent_time: '',
    expiration_timeout: 300,
    resend_timeout: 60,
    timer: 0,
    timerType: null,
};
export var registrationSlice = createSlice({
    name: 'verification',
    initialState: initialState,
    reducers: {
        verificationRequest: function (_state, _action) { },
        addCodeInfo: function (state, action) {
            state.resend_timeout = action.payload.resend_timeout;
            state.created = action.payload.created;
            state.verification_id = action.payload.verification_id;
            state.last_sent_time = action.payload.last_sent_time;
            state.expiration_timeout = action.payload.expiration_timeout;
        },
        setTimer: function (state, action) {
            state.timer = action.payload;
        },
        setTimerType: function (state, action) {
            state.timerType = action.payload;
        },
        resetCodeInfo: function () { return initialState; },
    },
});
export var verificationRequest = (_a = registrationSlice.actions, _a.verificationRequest), addCodeInfo = _a.addCodeInfo, resetCodeInfo = _a.resetCodeInfo, setTimer = _a.setTimer, setTimerType = _a.setTimerType;
export default registrationSlice.reducer;
