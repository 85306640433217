var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { PlanCard } from '@core/components/planCard';
import { Title } from '@core/components/title';
import SubscriptionIcon from '@core/shared/svg/qsure/subscription';
import styles from './index.module.sass';
export var Plan = function (_a) {
    var plansToShow = _a.plansToShow, handleClickPlan = _a.handleClickPlan, handleShowAllPlansClick = _a.handleShowAllPlansClick, selectedInterval = _a.selectedInterval, selectedPlanId = _a.selectedPlanId, allPlansShown = _a.allPlansShown;
    var subscriptionProducts = useSelector(function (s) { return s.subscriptions; }).subscriptionProducts;
    var calcDiscount = function (plan, price) {
        if (price.interval === 'month') {
            return null;
        }
        var monthPrice = plan.prices.find(function (p) { return p.interval === 'month'; });
        var pricePerMonth = parseFloat(price.amount.value) / 12;
        var percent = ((parseFloat(monthPrice.amount.value) - pricePerMonth) /
            parseFloat(monthPrice.amount.value)) *
            100;
        return {
            pricePerMonth: pricePerMonth,
            percent: percent,
        };
    };
    var plansCount = useMemo(function () {
        return subscriptionProducts.reduce(function (acc, el) {
            var _a;
            return acc + ((_a = el === null || el === void 0 ? void 0 : el.prices) === null || _a === void 0 ? void 0 : _a.length);
        }, 0);
    }, [subscriptionProducts.length]);
    return (_jsxs("div", __assign({ className: styles.plan }, { children: [_jsx(Title, { title: t('label_plan'), adaptiveTitleTop: false, titleClass: styles.planTitle }), _jsx("div", __assign({ className: styles.planSubTitle }, { children: t('label_choose_plan') })), _jsxs("div", __assign({ className: styles.planItems }, { children: [plansToShow.map(function (plan) {
                        return plan.prices.map(function (price) {
                            var key = "".concat(plan === null || plan === void 0 ? void 0 : plan._id, "-").concat(price === null || price === void 0 ? void 0 : price.interval);
                            var isActive = key === "".concat(selectedPlanId, "-").concat(selectedInterval);
                            var discount = calcDiscount(plan, price);
                            return (_jsx(PlanCard, { plan: plan, price: price, isActive: isActive, discount: discount, onClick: function () { return handleClickPlan(plan, price.interval); } }, key));
                        });
                    }), !allPlansShown && (_jsxs("div", __assign({ className: styles.planAll, onClick: handleShowAllPlansClick }, { children: [_jsxs("div", __assign({ className: styles.planAllTop }, { children: [_jsx("div", __assign({ className: styles.planAllTopTitle }, { children: t('label_all_plans') })), _jsx("div", __assign({ className: styles.planAllTopSubTitle }, { children: "".concat(plansCount, " ").concat(t('label_plans')) }))] })), _jsxs("div", __assign({ className: styles.planAllBottom }, { children: [_jsx("div", {}), _jsx(SubscriptionIcon, {})] }))] })))] }))] })));
};
