var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { BaseWrapper } from '@core/components/baseWrapper';
import { PatientEditModal } from '@core/components/modals/editPatient';
import { PageTitle } from '@core/components/pageTitle';
import { checkIsProfileLocked } from '@core/shared/utils/profileUtils';
import { removeError } from '@core/store/reducers/errorsSlice';
import { getInfo, resetSelectedPerson, setSelectedPerson, statusesReset, updateInfo, } from '@core/store/reducers/profileSlice';
import { EditProfileLoader } from './loader';
export var Profile = function () {
    var dispatch = useDispatch();
    var nav = useNavigate();
    var _a = useSelector(function (state) { return state.profile; }), self = _a.self, selectedPerson = _a.selectedPerson;
    var _b = useState(false), isLoaded = _b[0], setLoaded = _b[1];
    useEffect(function () {
        dispatch(getInfo(function () {
            setLoaded(true);
        }));
        return function () {
            dispatch(statusesReset());
            dispatch(removeError('updatePatient'));
            dispatch(resetSelectedPerson());
        };
    }, []);
    useEffect(function () {
        if (self && !selectedPerson) {
            dispatch(setSelectedPerson(self));
        }
    }, [self, selectedPerson]);
    var updateCallback = function () {
        dispatch(getInfo(function () {
            setLoaded(true);
        }));
    };
    var handleUpdate = function (data) {
        setLoaded(false);
        dispatch(updateInfo({
            person: data,
            callback: updateCallback,
        }));
    };
    var handleDelete = function () {
        nav(routePaths.DELETE_ACCOUNT);
    };
    var profileLocked = useMemo(function () { return checkIsProfileLocked(self); }, [self.flags]);
    if (isLoaded) {
        return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInnerMid: true }, { children: [_jsx(PageTitle, { text: t('label_profile'), hasBackButton: true }), _jsx(PatientEditModal, { patientId: self.id, isProfileLocked: profileLocked, canDelete: true, onUpdate: handleUpdate, onDelete: handleDelete, deleteBtnText: t('btn_account_delete'), appType: "qsure", showDeleteAccountBtn: false })] })) })));
    }
    return _jsx(EditProfileLoader, {});
};
