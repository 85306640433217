var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Family = function () { return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "42", height: "42", viewBox: "0 0 42 42", fill: "none" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M37.5 32.25C37.5 33.0784 36.8284 33.75 36 33.75C35.1716 33.75 34.5 33.0784 34.5 32.25C34.5084 28.4287 32.1028 25.0188 28.5 23.745L27.78 23.535L27.6 23.445C26.7897 23.2434 26.2104 22.5302 26.1791 21.6958C26.1478 20.8613 26.6721 20.1068 27.465 19.845H27.555C29.5198 18.9554 30.5771 16.7969 30.0756 14.6992C29.5741 12.6015 27.6547 11.1546 25.5 11.25C24.6716 11.25 24 10.5784 24 9.75C24 8.92157 24.6716 8.25 25.5 8.25C28.611 8.24983 31.3992 10.1703 32.5079 13.077C33.6167 15.9838 32.8158 19.2732 30.495 21.345C34.7611 23.298 37.4977 27.5581 37.5 32.25ZM28.5 32.2499C28.5 33.0783 27.8284 33.7499 27 33.7499C26.1716 33.7499 25.5 33.0783 25.5 32.2499C25.5 27.2793 21.4706 23.2499 16.5 23.2499C11.5294 23.2499 7.5 27.2793 7.5 32.2499C7.5 33.0783 6.82843 33.7499 6 33.7499C5.17157 33.7499 4.5 33.0783 4.5 32.2499C4.50235 27.558 7.23892 23.2979 11.505 21.3449C9.18446 19.2731 8.38379 15.9838 9.49251 13.0772C10.6012 10.1706 13.3892 8.25024 16.5 8.25024C19.6108 8.25024 22.3988 10.1706 23.5075 13.0772C24.6162 15.9838 23.8155 19.2731 21.495 21.3449C25.7611 23.2979 28.4977 27.558 28.5 32.2499ZM21 15.75C21 13.2647 18.9853 11.25 16.5 11.25C14.0147 11.25 12 13.2647 12 15.75C12 18.2353 14.0147 20.25 16.5 20.25C18.9853 20.25 21 18.2353 21 15.75Z", fill: "white" }) }))); };
export default Family;
