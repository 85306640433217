var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Home = function () { return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "28", height: "28", viewBox: "0 0 28 28", fill: "none" }, { children: _jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "29", height: "28", viewBox: "0 0 29 28", fill: "none" }, { children: [_jsx("path", { d: "M21.9851 10.7098L16.6521 6.56177C15.6893 5.81274 14.3409 5.81274 13.3781 6.56177L8.04414 10.7098C7.39454 11.2149 7.01475 11.9919 7.01514 12.8148V20.0148C7.01514 21.1193 7.91057 22.0148 9.01514 22.0148H21.0151C22.1197 22.0148 23.0151 21.1193 23.0151 20.0148V12.8148C23.0151 11.9918 22.6351 11.2148 21.9851 10.7098", stroke: "white", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M18.9839 16.0001C16.7739 17.3331 13.1919 17.3331 10.9839 16.0001", stroke: "white", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })) }))); };
export default Home;
