var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { t } from 'i18next';
import { BaseCard } from '@core/components/baseCard';
import styles from './index.module.sass';
export var TestingSpecials = function (_a) {
    var classNames = _a.classNames, onClick = _a.onClick;
    var handleOnClick = function () {
        if (onClick) {
            onClick();
            return;
        }
    };
    return (_jsx(BaseCard, __assign({ handleOnClick: handleOnClick, classNames: clsx(styles.card, classNames) }, { children: _jsxs("div", __assign({ className: styles.left }, { children: [_jsx("div", __assign({ className: styles.leftTitle }, { children: t('label_testing_specials') })), _jsx("div", __assign({ className: styles.leftIcons }, { children: _jsx("div", __assign({ className: styles.leftDescr }, { children: t('label_testing_specials_descr') })) }))] })) })));
};
