import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import moment, { isMoment } from 'moment';
import { showModal } from '@core/components/modals';
import { ModalDatePicker } from '@core/components/modals/datePicker';
import { DATE_FORMAT } from '@core/shared/constants/dateFormat';
import { calculateFromToday, clearHours } from '@core/shared/utils/dateUtils';
import { Calendar } from './calendar';
import { DateInput } from './dateInput';
var SHOWED_FORMAT = DATE_FORMAT.slash;
var DEFAULT_FORMAT = DATE_FORMAT.dash;
var DEFAULT_START_RANGE = moment().set({ year: moment().year() - 100 });
var DEFAULT_END_RANGE = moment().set({ year: moment().year() + 100 });
export var DatePicker = function (_a) {
    var locked = _a.locked, className = _a.className, label = _a.label, placeholder = _a.placeholder, handleChange = _a.handleChange, defaultValue = _a.defaultValue, startValue = _a.startValue, //'2022-04-03' or another
    range = _a.range, //{{startDate: '2010-08-07', endDate: '2015-08-07'}}
    relativeRange = _a.relativeRange, // relativeRange={{startDate: { offset: -3,unit: 'years'}, endDate: {offset: +3,unit: 'days' } }}
    blockedDays = _a.blockedDays, //{['2022-04-03','2022-04-06', ...or another]}
    blockedWeekDays = _a.blockedWeekDays, // blockedWeekDays={['Saturday', 'sunday', ...or another]}
    _b = _a.markBlockedDates, // blockedWeekDays={['Saturday', 'sunday', ...or another]}
    markBlockedDates = _b === void 0 ? true : _b, firstWeekDay = _a.firstWeekDay, //'monday' or another
    outputFormat = _a.outputFormat, // 'YYYY-MM-DD' or another
    showedFormat = _a.showedFormat, //'MM.DD.YYYY' or another
    error = _a.error, disabled = _a.disabled, bigStyle = _a.bigStyle, _c = _a.typeOfModal, typeOfModal = _c === void 0 ? 'form' : _c, _d = _a.needAddMask, needAddMask = _d === void 0 ? true : _d, _e = _a.showLockIcon, showLockIcon = _e === void 0 ? locked : _e;
    var innerOutputFormat = useRef(outputFormat || DEFAULT_FORMAT);
    var innerShowedFormat = useRef(showedFormat || SHOWED_FORMAT);
    var innerRange = useRef({
        startDate: DEFAULT_START_RANGE,
        endDate: DEFAULT_END_RANGE,
    });
    var _f = useState([]), innerBlockedDays = _f[0], setInnerBlockedDays = _f[1];
    var _g = useState(defaultValue || ''), originalValue = _g[0], setOriginalValue = _g[1];
    useEffect(function () {
        var startDate = ((relativeRange === null || relativeRange === void 0 ? void 0 : relativeRange.startDate) && calculateFromToday(relativeRange === null || relativeRange === void 0 ? void 0 : relativeRange.startDate)) ||
            ((range === null || range === void 0 ? void 0 : range.startDate) && clearHours(moment(range.startDate, innerOutputFormat.current))) ||
            DEFAULT_START_RANGE;
        var endDate = ((relativeRange === null || relativeRange === void 0 ? void 0 : relativeRange.endDate) && calculateFromToday(relativeRange === null || relativeRange === void 0 ? void 0 : relativeRange.endDate)) ||
            ((range === null || range === void 0 ? void 0 : range.startDate) && clearHours(moment(range.endDate, innerOutputFormat.current))) ||
            DEFAULT_END_RANGE;
        innerRange.current = { startDate: startDate, endDate: endDate };
    }, [range === null || range === void 0 ? void 0 : range.endDate, range === null || range === void 0 ? void 0 : range.startDate, relativeRange === null || relativeRange === void 0 ? void 0 : relativeRange.endDate, relativeRange === null || relativeRange === void 0 ? void 0 : relativeRange.startDate]);
    useEffect(function () {
        if (startValue) {
            var prepareStartValue = moment(startValue);
            setOriginalValue(prepareStartValue.format(innerOutputFormat.current));
        }
        if (!!blockedDays && blockedDays.length) {
            setInnerBlockedDays(blockedDays.map(function (day) { return moment(day); }));
        }
        // eslint-disable-next-line
    }, [startValue]);
    var handleChangeDay = function (date) {
        var originalValue = date.format(innerOutputFormat.current);
        setOriginalValue(originalValue);
        handleChange(originalValue);
    };
    var handleChangeInput = function (value) {
        if (isMoment(value)) {
            var originalValue_1 = value.format(innerOutputFormat.current);
            setOriginalValue(originalValue_1);
            handleChange(originalValue_1);
        }
        else if (!value) {
            setOriginalValue('');
            handleChange('');
        }
    };
    return (_jsx(DateInput, { bigStyle: bigStyle, disabled: disabled, locked: locked, showLockIcon: showLockIcon, placeholder: placeholder, range: innerRange.current, relativeRange: relativeRange, showedFormat: innerShowedFormat.current, defaultFormat: innerOutputFormat.current, originalValue: originalValue, label: label, onChange: handleChangeInput, blockedDays: innerBlockedDays, blockedWeekDays: blockedWeekDays, className: className, error: error, onIconClick: function () {
            if (disabled || locked) {
                return;
            }
            typeOfModal === 'calendar'
                ? showModal(Calendar, {
                    placeholder: placeholder,
                    label: label,
                    handleChangeDay: handleChangeDay,
                    markBlockedDates: markBlockedDates,
                    innerBlockedDays: innerBlockedDays,
                    blockedWeekDays: blockedWeekDays,
                    originalValue: originalValue,
                    innerRange: innerRange,
                    firstWeekDay: firstWeekDay,
                    defaultFormat: innerOutputFormat.current,
                })
                : showModal(ModalDatePicker, {
                    originalValue: originalValue,
                    handleChangeDate: handleChangeDay,
                    title: label,
                    range: innerRange.current,
                });
        }, needAddMask: needAddMask }));
};
