var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import Trash from '@core/shared/svg/trash';
import { checkCanDelete, formatCardDate, formatCardName, getIconByCardBrand } from './utils';
import { Checkbox } from '../formComponents/checkbox';
import styles from './index.module.sass';
export var PaymentMethodCard = function (_a) {
    var method = _a.method, onDelete = _a.onDelete, onClick = _a.onClick, className = _a.className, isActive = _a.isActive, _b = _a.showCheckbox, showCheckbox = _b === void 0 ? false : _b;
    if (!method)
        return null;
    var handleDeleteClick = function (event) {
        event.stopPropagation();
        onDelete();
    };
    return (_jsxs("div", __assign({ className: clsx(styles.card, className), onClick: onClick }, { children: [_jsxs("div", __assign({ className: styles.cardDetail }, { children: [showCheckbox && (_jsx(Checkbox, { label: '', value: true, onChange: onClick, checked: isActive, className: styles.check })), _jsx("div", __assign({ className: styles.cardDetailImage }, { children: getIconByCardBrand(method.type, 'big') })), _jsxs("div", __assign({ className: styles.cardDetailInfo }, { children: [_jsx("div", __assign({ className: styles.cardDetailInfoTitle }, { children: formatCardName(method === null || method === void 0 ? void 0 : method.type, method === null || method === void 0 ? void 0 : method.last_four_digits) })), (method === null || method === void 0 ? void 0 : method.exp_month) && (method === null || method === void 0 ? void 0 : method.exp_year) && (_jsx("div", __assign({ className: styles.cardDetailInfoDate }, { children: formatCardDate(method === null || method === void 0 ? void 0 : method.exp_month, method === null || method === void 0 ? void 0 : method.exp_year) })))] }))] })), onDelete && checkCanDelete(method.type) && (_jsxs("div", __assign({ className: styles.cardDelete }, { children: [_jsx("div", { className: styles.cardDeleteDivider }), _jsx("div", __assign({ className: styles.cardDeleteBtn, onClick: handleDeleteClick }, { children: _jsx(Trash, {}) }))] })))] })));
};
