var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Search = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", __assign({ fill: "#000", fillRule: "nonzero", opacity: ".25" }, { children: [_jsx("path", { d: "M13.5 22a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17zm0-2a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13z" }), _jsx("path", { d: "m18.762 20.176 3.056 3.056a1 1 0 0 0 1.414-1.414l-3.056-3.056a1 1 0 0 0-1.414 1.414z" })] })) }))); };
export default Search;
