var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PASSWORD_REGEX } from '@core/shared/regex';
// inputs
var code = {
    type: 'input',
    label: 'code',
    input: {
        type: 'code',
        placeholder: 'placeholder_code',
        inputmode: 'numeric',
        autocomplete: 'one-time-code',
        autofocus: true,
        validation: {
            required: { value: true, message: 'error_required' },
            maxLength: { value: 5, message: 'error_auth_code_max_length' },
        },
    },
};
var password = {
    type: 'input',
    label: 'password',
    actions: [
        {
            name: 'setPosition',
            params: {},
        },
    ],
    input: {
        type: 'password',
        placeholder: 'placeholder_new_password',
        autofocus: true,
        validation: {
            required: { value: true, message: 'error_required' },
            pattern: { value: PASSWORD_REGEX, message: 'error_auth_password_requirements' },
        },
    },
};
// components
var codeText = {
    label: 'codeText',
    type: 'text',
    text: 'label_code',
};
var callText = {
    label: 'codeText',
    type: 'text',
    text: 'label_call',
};
var timer = {
    label: 'timer',
    type: 'timer',
};
export var passFlow = {
    initial: {
        components: [
            {
                type: 'text',
                label: 'initText',
                text: 'p_update_pass_init',
            },
            password,
            {
                type: 'button',
                label: 'btn_next',
                loading: 'profile/updatePasswordReq',
                isEnterHandle: true,
                button: {
                    type: 'submit',
                    primary: true,
                },
                actions: [
                    {
                        name: 'sendPassword',
                        field: 'password',
                        params: {
                            nextPosition: 'code',
                        },
                    },
                ],
            },
        ],
    },
    code: {
        components: [
            {
                type: 'text',
                label: 'initText',
                text: 'p_update_pass_init',
            },
            password,
            codeText,
            code,
            {
                type: 'button',
                label: 'btn_update_password',
                loading: 'auth/verificationRequest',
                isEnterHandle: true,
                button: {
                    type: 'submit',
                    primary: true,
                },
                actions: [
                    {
                        name: 'sendCode',
                        field: 'code',
                        params: {},
                    },
                ],
            },
            __assign(__assign({}, __assign(__assign({}, timer), { timerFor: 'sms' })), { actions: [
                    {
                        name: 'sendPassword',
                        params: {},
                    },
                ] }),
            {
                type: 'changeMethod',
                preLabel: 'label_pre_call_method',
                label: 'label_call_method',
                onlyForPhoneAccounts: true,
                loading: 'profile/updatePasswordReq',
                actions: [
                    {
                        name: 'sendPassword',
                        field: 'password',
                        params: {
                            nextPosition: 'callCode',
                            verification: 'call',
                        },
                    },
                    {
                        name: 'resetField',
                        params: {
                            field: 'code',
                        },
                    },
                ],
            },
        ],
    },
    callCode: {
        components: [
            {
                type: 'text',
                label: 'initText',
                text: 'p_update_pass_init',
            },
            password,
            callText,
            code,
            {
                type: 'button',
                label: 'btn_update_password',
                loading: 'auth/verificationRequest',
                isEnterHandle: true,
                button: {
                    type: 'submit',
                    primary: true,
                },
                actions: [
                    {
                        name: 'sendCode',
                        field: 'code',
                        params: {},
                    },
                ],
            },
            __assign(__assign({}, __assign(__assign({}, timer), { timerFor: 'call' })), { actions: [
                    {
                        name: 'sendPassword',
                        params: {
                            verification: 'call',
                        },
                    },
                ] }),
            {
                type: 'changeMethod',
                preLabel: 'label_pre_sms_method',
                label: 'label_sms_method',
                loading: 'profile/updatePasswordReq',
                onlyForPhoneAccounts: true,
                actions: [
                    {
                        name: 'sendPassword',
                        field: 'password',
                        params: {
                            nextPosition: 'code',
                        },
                    },
                    {
                        name: 'resetField',
                        params: {
                            field: 'code',
                        },
                    },
                ],
            },
        ],
    },
    done: {
        components: [
            {
                type: 'text',
                label: 'doneText',
                text: 'p_update_pass_done',
            },
            {
                type: 'button',
                label: 'btn_done',
                loading: 'auth/verificationRequest',
                isEnterHandle: true,
                button: {
                    type: 'submit',
                    primary: true,
                },
                actions: [
                    {
                        name: 'exit',
                        params: {},
                    },
                ],
            },
        ],
    },
};
