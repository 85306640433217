var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { Button } from '@core/components/buttons/button';
import { Input } from '@core/components/formComponents/input';
import { Title } from '@core/components/title';
import ArrowRight from '@core/shared/svg/arrow-right';
import styles from './index.module.sass';
export var ModalForm = function (_a) {
    var _b = _a.closeModal, closeModal = _b === void 0 ? function () { } : _b, title = _a.title, isHaveInsurance = _a.isHaveInsurance;
    var _c = useForm(), register = _c.register, unregister = _c.unregister, errors = _c.formState.errors, control = _c.control, setValue = _c.setValue, clearErrors = _c.clearErrors;
    useEffect(function () {
        setValue('provider', 'Absolute Total Care');
        setValue('policyholder', 'Selt');
        setValue('currentlyActive', 'Yes');
    }, []);
    var renderTextInput = function (label, displayLabel, placeholder, disable, hasLabel, validation) {
        if (validation === void 0) { validation = {
            required: true,
            minLength: 2,
            onChange: function () {
                clearErrors(label);
            },
        }; }
        return (_jsx(Input, { hasLabel: hasLabel, type: "text", disable: disable, label: label, displayLabel: displayLabel, classes: [styles.ModalFormInput], control: control, register: register, placeholder: placeholder, clearErrors: clearErrors, unregister: unregister, error: errors[label], setEmpty: function () {
                setValue(label, '');
            }, validation: validation }));
    };
    return (_jsx("div", __assign({ className: styles.ModalForm }, { children: _jsxs("div", __assign({ className: styles.container }, { children: [_jsxs("div", __assign({ className: styles.ModalFormHeader }, { children: [_jsx("div", __assign({ className: styles.ModalFormHeaderBack, onClick: closeModal }, { children: _jsx(ArrowRight, {}) })), _jsx(Title, { classes: [styles.title], title: title, exLink: false })] })), _jsxs("div", __assign({ className: styles.ModalFormFields }, { children: [isHaveInsurance ? (_jsxs(_Fragment, { children: [renderTextInput('provider', 'Insurance Provider', '', true, true), renderTextInput('rxBin', 'Rx Bin', 'XXXXXX', false, true), renderTextInput('groupNumber', 'Group number (optional)', 'XXXXXXX', false, true), renderTextInput('policyholder', 'Policyholder', '', false, true)] })) : (_jsxs(_Fragment, { children: [renderTextInput('provider', 'Insurance Provider', '', true, true), renderTextInput('phoneNumber', 'Insurance phone number', '', false, true), renderTextInput('claimsAddress', 'Insurance claims address', '', false, true), renderTextInput('policyNumberId', 'ID or Policy Number', '', false, true), renderTextInput('groupNumber', 'Group number (optional)', '', false, true), renderTextInput('name', 'Name of insured', '', false, true), renderTextInput('date', 'MM/DD/YYYY', '', false, true), renderTextInput('policyholder', 'Policyholder', '', true, true), renderTextInput('currentlyActive', 'Whether coverage is currently active', '', true, true)] })), _jsx(Button, { handler: function () {
                                closeModal();
                            }, classes: [clsx(styles.ModalFormBtn, styles.ModalFormBtnLarge)], primary: true, label: 'Next' })] }))] })) })));
};
