var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './index.module.sass';
export var PaymentMethodsLoader = function () {
    var count = 2;
    return (_jsx("div", __assign({ className: styles.container }, { children: _jsxs(SkeletonTheme, __assign({ borderRadius: 16, baseColor: "#f6f6f6", highlightColor: "#f0f0f0" }, { children: [_jsx("div", __assign({ className: styles.paymentElementsLoader }, { children: __spreadArray([], Array(count), true).map(function (e) { return (_jsx(Skeleton, { height: 62, className: styles.paymentCard }, e)); }) })), _jsx(Skeleton, { height: 48, className: styles.contactsElement })] })) })));
};
