var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '@core/components/loader/PageLoader';
import { showModal } from '@core/components/modals';
import { Chat } from '@core/pages/forms/chat';
import { ConfirmLogout } from '@core/pages/forms/modals/confirmLogout';
import surveyService from '@core/services/surveys';
import { SID } from '@core/shared/models/survey';
import { fetchDocumentsByPersonId } from '@core/store/reducers/docsSlice';
import { getFamily } from '@core/store/reducers/profileSlice';
import { clearSurvey, initSurvey, setFinishCallback } from '@core/store/reducers/surveySlice';
export var createForms = function (_a) {
    var sid = _a.sid, title = _a.title, backRoute = _a.backRoute, closeModal = _a.closeModal, callback = _a.callback, params = _a.params, stickyHeader = _a.stickyHeader, customPrefill = _a.customPrefill;
    return (_jsx(CreateForms, { customPrefill: customPrefill, stickyHeader: stickyHeader, sid: sid, title: title, backRoute: backRoute, closeModal: closeModal, params: params, callback: callback }));
};
export var CreateForms = function (_a) {
    var sid = _a.sid, title = _a.title, closeModal = _a.closeModal, _b = _a.backRoute, backRoute = _b === void 0 ? '/' : _b, callback = _a.callback, params = _a.params, stickyHeader = _a.stickyHeader, customPrefill = _a.customPrefill;
    var dispatch = useDispatch();
    var _c = useSelector(function (state) { return state; }).survey, fullSurvey = _c.fullSurvey, currentStep = _c.currentStep, isLoading = _c.isLoading, patient = _c.patient;
    var nav = useNavigate();
    var location = useLocation();
    var handleBack = function (state) {
        if (typeof backRoute === 'string') {
            return nav(backRoute, __assign({}, (state && { state: state })));
        }
        if (typeof backRoute === 'function') {
            backRoute();
        }
    };
    var confirmLogout = function () {
        showModal(ConfirmLogout, {
            isBottomMobile: true,
            showCloseIcon: false,
        });
    };
    var finishCallback = useCallback(function (data) {
        if (sid === SID.ADD_DOCUMENT) {
            dispatch(fetchDocumentsByPersonId({ person_id: patient === null || patient === void 0 ? void 0 : patient._id }));
            handleBack(patient);
        }
        if (sid === SID.PREBOOKING) {
            callback && callback(data);
        }
        if (sid === SID.ADD_FAMILY) {
            closeModal({ showConfirm: false });
            return dispatch(getFamily());
        }
        if (sid === SID.PRESCRIPTION) {
            callback && callback(data);
        }
        if (sid === SID.NEW_USER ||
            sid === SID.PREBOOKING_COLLECTOR ||
            sid === SID.ADD_FAMILY_BY_SUPERVISOR ||
            sid === SID.ONBOARDING ||
            sid === SID.HEALTH_RISK) {
            callback && callback(data);
        }
    }, [patient]);
    useEffect(function () {
        dispatch(setFinishCallback(finishCallback));
    }, [finishCallback]);
    useEffect(function () {
        if (fullSurvey.survey.status === 'submitted') {
            finishCallback();
        }
    }, [fullSurvey.survey.status]);
    useEffect(function () {
        var _a;
        if (sid === SID.ADD_DOCUMENT && !(params === null || params === void 0 ? void 0 : params.person_id) && !((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.person_id)) {
            handleBack();
        }
        if (sid === SID.PRESCRIPTION) {
            dispatch(initSurvey({
                sid: sid,
                params: params,
            }));
        }
        if (sid === SID.ADD_DOCUMENT) {
            dispatch(initSurvey({
                sid: sid,
                params: params || (location === null || location === void 0 ? void 0 : location.state) || null,
            }));
        }
        if (sid === SID.HEALTH_RISK) {
            dispatch(initSurvey({ sid: sid, params: params }));
            return;
        }
        if (sid !== SID.ONBOARDING && sid !== SID.ADD_DOCUMENT) {
            dispatch(initSurvey({ sid: sid }));
        }
        if (sid === SID.ADD_FAMILY_BY_SUPERVISOR) {
            dispatch(initSurvey({
                sid: sid,
                params: params || null,
            }));
        }
        if (sid === SID.PREBOOKING_COLLECTOR) {
            var init = function () { return __awaiter(void 0, void 0, void 0, function () {
                var check, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, surveyService.getInstance(sid, params)];
                        case 1:
                            check = _a.sent();
                            if (check.data.status === 'submitted') {
                                callback && callback();
                            }
                            else {
                                dispatch(initSurvey({
                                    sid: sid,
                                    params: params || null,
                                }));
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            dispatch(initSurvey({
                                sid: sid,
                                params: params || null,
                            }));
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            init().then();
        }
        return function () {
            dispatch(clearSurvey());
        };
    }, [dispatch, sid]);
    var showLoader = !fullSurvey.branches.length ||
        (!Object.keys(currentStep).length &&
            fullSurvey.survey.status !== 'submission_error' &&
            fullSurvey.survey.status !== 'completed');
    if (isLoading || showLoader) {
        return _jsx(PageLoader, {});
    }
    return (_jsx(Chat, { customPrefill: customPrefill, stickyHeader: stickyHeader, closeModal: closeModal, finishCallback: finishCallback, title: title, backRoute: backRoute, confirmLogout: confirmLogout, sid: sid }));
};
