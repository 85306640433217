var _a;
import { AsyncStatus } from '@core/shared/constants/loaderTypes';
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    uploadedPrescription: {
        confirmedMedia: { record_id: '', url: '', confirmed: false },
        prescriptionUploaderReqStat: AsyncStatus.NONE,
        prescriptionRemoverReqStat: AsyncStatus.NONE,
        currentId: '',
    },
};
export var prescriptionSlice = createSlice({
    name: 'prescription',
    initialState: initialState,
    reducers: {
        addPrescription: function (state, _action) {
            state.uploadedPrescription.currentId = '';
            state.uploadedPrescription.prescriptionUploaderReqStat = AsyncStatus.LOADING;
        },
        addPrescriptionSuccess: function (state, action) {
            state.uploadedPrescription.confirmedMedia = action.payload.media;
            state.uploadedPrescription.prescriptionUploaderReqStat = AsyncStatus.SUCCESS;
        },
        addPrescriptionFail: function (state, _action) {
            state.uploadedPrescription.prescriptionUploaderReqStat = AsyncStatus.FAIL;
        },
        removePrescription: function (state, _action) {
            state.uploadedPrescription.prescriptionRemoverReqStat = AsyncStatus.LOADING;
        },
        removePrescriptionSuccess: function (state, _action) {
            state.uploadedPrescription.currentId = '';
            state.uploadedPrescription.prescriptionRemoverReqStat = AsyncStatus.SUCCESS;
        },
        removePrescriptionFail: function (_state, _action) { },
        setCurrentIdPrescription: function (state, action) {
            state.uploadedPrescription.currentId = action.payload.id;
        },
    },
});
export var addPrescription = (_a = prescriptionSlice.actions, _a.addPrescription), addPrescriptionSuccess = _a.addPrescriptionSuccess, addPrescriptionFail = _a.addPrescriptionFail, removePrescription = _a.removePrescription, removePrescriptionSuccess = _a.removePrescriptionSuccess, removePrescriptionFail = _a.removePrescriptionFail, setCurrentIdPrescription = _a.setCurrentIdPrescription;
export default prescriptionSlice.reducer;
