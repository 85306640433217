var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { Clock } from '@core/components/clock';
import { setTimer, setTimerType } from '@core/store/reducers/verificationSlice';
import styles from './index.module.sass';
var mapState = function (state) { return ({
    code: state.code,
    loading: state.loading,
}); };
export var Timer = function (_a) {
    var handle = _a.handle, _b = _a.classes, classes = _b === void 0 ? [] : _b, _c = _a.type, type = _c === void 0 ? 'sms' : _c;
    var dispatch = useDispatch();
    var _d = useSelector(mapState), code = _d.code, loading = _d.loading;
    var _e = useState(false), innerLoading = _e[0], setInnerLoading = _e[1];
    var _f = useState(true), disable = _f[0], setDisable = _f[1];
    var _g = useState(''), mainLabel = _g[0], setMainLabel = _g[1];
    useEffect(function () {
        dispatch(setTimerType(type));
        setInnerLoading(false);
        if (type === 'sms' || type === 'email')
            setMainLabel(t('label_resend_code'));
        if (type === 'call')
            setMainLabel(t('label_recall'));
    }, [type]);
    useEffect(function () {
        resetTimer();
    }, [code.last_sent_time, type]);
    useEffect(function () {
        return function () {
            dispatch(setTimer(0));
            dispatch(setTimerType(null));
        };
    }, []);
    var tick = function () {
        dispatch(setTimer(code.timer - 1000));
    };
    useEffect(function () {
        var timeout = setTimeout(function () {
            if (code.timer > 0) {
                tick();
            }
            if (code.timer <= 1000) {
                setInnerLoading(false);
                setDisable(false);
            }
        }, 1000);
        return function () {
            clearTimeout(timeout);
        };
    }, [code.timer, type]);
    var resetTimer = function () {
        var resendTimeout = code.resend_timeout * 1000;
        setDisable(true);
        dispatch(setTimer(resendTimeout));
    };
    useEffect(function () {
        if (!loading.isLoading && innerLoading) {
            setInnerLoading(false);
        }
    }, [loading.isLoading]);
    return (_jsx("div", { children: _jsxs("button", __assign({ className: clsx.apply(void 0, __spreadArray([styles.timerBtn, disable && styles.timerBtnDisabled], classes, false)), onClick: function () {
                setInnerLoading(true);
                handle();
            }, disabled: disable }, { children: [!innerLoading && mainLabel, _jsx(Clock, { time: code.timer, className: clsx(styles.timerClock, !code.timer && styles.timerClockHidden) }), innerLoading && (_jsx("div", __assign({ className: styles.timerLoader }, { children: _jsx("div", { className: styles.bubbleLoader }) })))] })) }));
};
