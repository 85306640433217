var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { TestingCard } from '@components/testingCard';
import { FluTesting } from '@core/components/seasonTest/fluTesting';
import { config } from '@core/config/config';
import TokenService from '@core/services/tokens';
import { QSURE_REDIRECT_KEY } from '@core/shared/constants/common';
import { changeAppScreenToLocations, changeAppScreenToMyAlarms, changeAppScreenToMyHealsRecords, changeAppScreenToOrderTest, createSeasonTestInApp, isReactNativeWebView, } from '@core/shared/utils/webViewUtils';
import useWindowDimensions from '@core/shared/utils/widthHook';
import { useProceedToLabq } from '@root/src/hooks/useProceedToLabq';
import styles from './index.module.sass';
export var Testing = function () {
    var isMobile = useWindowDimensions().isMobile;
    var profile = useSelector(function (state) { return state.profile; });
    var handleProceedToLabq = useProceedToLabq().handleProceedToLabq;
    var handleSeasonTestClick = function () {
        if (isReactNativeWebView) {
            handleProceedToLabq({
                callback: createSeasonTestInApp,
            });
            return;
        }
        var refreshToken = TokenService.getRefreshToken();
        var url = "".concat(config.href).concat(QSURE_REDIRECT_KEY, "/cart/new/?refresh_token=").concat(refreshToken, "&medical_codes=").concat(config.seasonProceduresCode);
        handleProceedToLabq({ url: url });
    };
    var handleOrderTestClick = function () {
        if (isReactNativeWebView) {
            handleProceedToLabq({
                callback: changeAppScreenToOrderTest,
            });
            return;
        }
        var token = TokenService.getRefreshToken();
        var url = "".concat(config.href).concat(QSURE_REDIRECT_KEY, "/booking/?refresh_token=").concat(token);
        handleProceedToLabq({ url: url });
    };
    var handleHealthRecordsClick = function () {
        var _a;
        if (isReactNativeWebView) {
            handleProceedToLabq({
                callback: changeAppScreenToMyHealsRecords,
            });
            return;
        }
        var token = TokenService.getRefreshToken();
        var url = "".concat(config.href).concat(QSURE_REDIRECT_KEY, "/stories/patients/").concat((_a = profile === null || profile === void 0 ? void 0 : profile.self) === null || _a === void 0 ? void 0 : _a.id, "/?refresh_token=").concat(token);
        handleProceedToLabq({ url: url });
    };
    var handleAlertsClick = function () {
        var _a;
        if (isReactNativeWebView) {
            handleProceedToLabq({
                callback: changeAppScreenToMyAlarms,
            });
            return;
        }
        var token = TokenService.getRefreshToken();
        var url = "".concat(config.href).concat(QSURE_REDIRECT_KEY, "/stories/patients/").concat((_a = profile === null || profile === void 0 ? void 0 : profile.self) === null || _a === void 0 ? void 0 : _a.id, "/alerts/?refresh_token=").concat(token);
        handleProceedToLabq({ url: url });
    };
    var handleLocationsClick = function () {
        if (isReactNativeWebView) {
            handleProceedToLabq({
                callback: changeAppScreenToLocations,
            });
            return;
        }
        var refreshToken = TokenService.getRefreshToken();
        var url = "".concat(config.href).concat(QSURE_REDIRECT_KEY, "/locations/?refresh_token=").concat(refreshToken);
        handleProceedToLabq({ url: url });
    };
    return (_jsxs("div", __assign({ className: styles.testing }, { children: [_jsx("div", __assign({ className: styles.title }, { children: t('label_testing') })), isMobile && (_jsx("div", __assign({ className: styles.seasonTest }, { children: _jsx(FluTesting, { onClick: handleSeasonTestClick, classNames: styles.seasonTestCard, classNameImage: styles.seasonTestImage }) }))), _jsxs("div", __assign({ className: styles.content }, { children: [!isMobile && (_jsx(FluTesting, { onClick: handleSeasonTestClick, classNames: styles.seasonTestCard, classNameImage: styles.seasonTestImage })), _jsx(TestingCard, { type: "order", onClick: handleOrderTestClick }), _jsx(TestingCard, { type: "results", onClick: handleHealthRecordsClick }), _jsx(TestingCard, { type: "alerts", onClick: handleAlertsClick }), _jsx(TestingCard, { type: "location", onClick: handleLocationsClick })] }))] })));
};
