var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import useWindowDimensions from '@core/shared/utils/widthHook';
import styles from './index.module.sass';
export var EmployersBanner = function (_a) {
    var size = _a.size, text = _a.text, title = _a.title, children = _a.children, className = _a.className, titleClassName = _a.titleClassName, textClassName = _a.textClassName;
    var width = useWindowDimensions().width;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: clsx(styles.wrapper, className) }, { children: [_jsxs("div", __assign({ className: styles.wrapperItem }, { children: [title && (_jsx("div", __assign({ className: clsx(styles.wrapperTitle, size === 'big' && styles.wrapperTitleBig, titleClassName) }, { children: title }))), text && (_jsx("div", __assign({ className: clsx(styles.wrapperText, size === 'big' && styles.wrapperTextBig, textClassName) }, { children: text }))), children] })), _jsx("div", __assign({ className: styles.wrapperLogo }, { children: width > 1069 ? (_jsx("img", { srcSet: "".concat(require('@root/src/shared/images/employersLogo/employersLogo@2x.png'), " 2x,\n              ").concat(require('@root/src/shared/images/employersLogo/employersLogo@3x.png'), " 3x"), src: require('@root/src/shared/images/employersLogo/employersLogo.png'), alt: 'logo' })) : (_jsx("img", { srcSet: "".concat(require('@root/src/shared/images/employersLogo/vertical/vertLogo@2x.png'), " 2x, \n            ").concat(require('@root/src/shared/images/employersLogo/vertical/vertLogo@3x.png'), " 3x"), src: require('@root/src/shared/images/employersLogo/vertical/vertLogo.png'), alt: 'logo' })) }))] })) }));
};
