var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import styles from './index.module.sass';
export var CalendarDays = function (_a) {
    var innerValue = _a.innerValue, onChangeDay = _a.onChangeDay, dateFormat = _a.dateFormat, range = _a.range, blockedWeekDays = _a.blockedWeekDays, _b = _a.blockedDays, blockedDays = _b === void 0 ? [] : _b, firstWeekDay = _a.firstWeekDay, markBlockedDates = _a.markBlockedDates;
    var _c = useState([]), dates = _c[0], setDates = _c[1];
    var initialLocale = useRef(moment.locale());
    var calculateDates = function (value) {
        var days = [];
        var startWeekDay = moment(value, 'temp').locale('temp').set('date', 1).weekday();
        var daysInMonth = value.daysInMonth();
        var counter = 1;
        for (var day = 1; day <= daysInMonth; day++) {
            if (counter <= startWeekDay) {
                day = 0;
                days.push(null);
            }
            else {
                var date = moment(value).set('date', day);
                days.push(date);
            }
            counter++;
        }
        return days;
    };
    useEffect(function () {
        if (firstWeekDay) {
            moment.locale('temp', {
                parentLocale: initialLocale.current,
                week: {
                    dow: moment
                        .weekdays()
                        .findIndex(function (week) { return week.toLowerCase() === firstWeekDay.toLowerCase(); }),
                },
            });
        }
        var days = calculateDates(innerValue);
        setDates(days);
        return function () {
            if (firstWeekDay) {
                moment.updateLocale('temp', null);
                // eslint-disable-next-line
                moment.locale(initialLocale.current);
            }
        };
    }, [innerValue]);
    var checkIsSelected = function (day) {
        return (day === null || day === void 0 ? void 0 : day.format(dateFormat)) === (innerValue === null || innerValue === void 0 ? void 0 : innerValue.format(dateFormat));
    };
    var checkIsDisabled = function (day) {
        var rules = [
            (day === null || day === void 0 ? void 0 : day.unix()) > range.endDate.unix(),
            (day === null || day === void 0 ? void 0 : day.unix()) < range.startDate.unix(),
            blockedDays === null || blockedDays === void 0 ? void 0 : blockedDays.some(function (date) { return date.unix() === (day === null || day === void 0 ? void 0 : day.unix()); }),
            blockedWeekDays === null || blockedWeekDays === void 0 ? void 0 : blockedWeekDays.some(function (blockedWeekDay) { var _a; return blockedWeekDay.toLowerCase() === ((_a = moment.weekdays()[day === null || day === void 0 ? void 0 : day.weekday()]) === null || _a === void 0 ? void 0 : _a.toLowerCase()); }),
        ];
        return rules.some(Boolean) && markBlockedDates;
    };
    return (_jsxs("div", __assign({ className: styles.Calendar }, { children: [_jsx("div", __assign({ className: styles.CalendarHeader }, { children: moment.weekdays(true).map(function (weekday) { return (_jsx("span", __assign({ className: styles.CalendarHeaderItem }, { children: weekday.substring(0, 3) }), weekday)); }) })), _jsx("div", __assign({ className: styles.CalendarDays }, { children: dates.map(function (date, index) { return (_jsx("span", __assign({ className: clsx(styles.CalendarDay, checkIsSelected(date) && styles.CalendarDaySelected, !date && styles.CalendarDayEmpty, checkIsDisabled(date) && styles.CalendarDayDisabled), onClick: function () { return onChangeDay(date); } }, { children: date === null || date === void 0 ? void 0 : date.date() }), index)); }) }))] })));
};
