var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { ContactsCards } from '@components/landing/contactsCards';
import { Container } from '@components/landing/container';
import { isReactNativeWebView } from '@core/shared/utils/webViewUtils';
import styles from './index.module.sass';
export var Contacts = function () {
    var location = useLocation();
    useEffect(function () {
        if (location.state === 'toContactUs') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (!location.state) {
            window.scrollTo(0, 0);
        }
    }, []);
    return (_jsx(Container, { children: _jsxs("div", __assign({ className: styles.content }, { children: [_jsxs("div", __assign({ className: styles.title, style: isReactNativeWebView ? { marginTop: '30px' } : null }, { children: [_jsx("div", __assign({ className: styles.titleSmall }, { children: t('label_landing_contacts_name') })), _jsx("div", __assign({ className: styles.titleBig }, { children: t('label_landing_contacts_title') })), _jsx("div", __assign({ className: styles.titleText }, { children: t('label_landing_contacts_text') }))] })), _jsx("div", __assign({ className: styles.cards }, { children: _jsx(ContactsCards, {}) }))] })) }));
};
