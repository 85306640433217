var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DATE_FORMAT } from '@core/shared/constants/dateFormat';
import useMask from '@core/shared/hooks/useMask';
import { DatePickerCalendar } from '@core/shared/svg/datePickerCalendar';
import Lock from '@core/shared/svg/lock';
import { isValidDate } from '@core/shared/utils/timeUtils';
import useWindowDimensions from '@core/shared/utils/widthHook';
import styles from './index.module.sass';
export var DateInput = function (_a) {
    var _b, _c;
    var label = _a.label, placeholder = _a.placeholder, originalValue = _a.originalValue, onChange = _a.onChange, _d = _a.startValue, startValue = _d === void 0 ? '' : _d, onIconClick = _a.onIconClick, showedFormat = _a.showedFormat, defaultFormat = _a.defaultFormat, range = _a.range, relativeRange = _a.relativeRange, blockedDays = _a.blockedDays, blockedWeekDays = _a.blockedWeekDays, className = _a.className, error = _a.error, disabled = _a.disabled, bigStyle = _a.bigStyle, needAddMask = _a.needAddMask, locked = _a.locked, _e = _a.showLockIcon, showLockIcon = _e === void 0 ? locked : _e;
    var mask = useMask(startValue, showedFormat.replace(/[A-Z]/g, '*'), '*', needAddMask);
    var _f = useState(false), dateError = _f[0], setDateError = _f[1];
    var _g = useState(false), isFocused = _g[0], setIsFocused = _g[1];
    var width = useWindowDimensions().width;
    var prevInputValue = useRef();
    useEffect(function () {
        dateError && setDateError(false);
        if (mask.input.value.length === showedFormat.length) {
            var inputValue_1 = moment(mask.input.value, showedFormat);
            var validationRules = [
                !inputValue_1.isValid(),
                inputValue_1.unix() <= range.startDate.unix(),
                inputValue_1.unix() > range.endDate.unix(),
                blockedWeekDays === null || blockedWeekDays === void 0 ? void 0 : blockedWeekDays.some(function (blockedWeekDay) {
                    return blockedWeekDay.toLowerCase() === moment.weekdays()[inputValue_1.weekday()].toLowerCase();
                }),
                blockedDays === null || blockedDays === void 0 ? void 0 : blockedDays.some(function (date) { return date.unix() === inputValue_1.unix(); }),
            ];
            if (validationRules.some(Boolean)) {
                return setDateError(true);
            }
            if (mask.input.value !== prevInputValue.current || !originalValue) {
                prevInputValue.current = mask.input.value;
                return onChange(inputValue_1);
            }
        }
        if (!!originalValue && mask.input.value.length < showedFormat.length) {
            onChange('');
        }
        // eslint-disable-next-line
    }, [mask.input.value, onChange, originalValue, range.endDate, range.startDate, showedFormat]);
    useEffect(function () {
        var dateValue = moment(originalValue, defaultFormat).format(showedFormat);
        var needChange = originalValue.length === showedFormat.length || (!needAddMask && isValidDate(dateValue));
        if (needChange) {
            mask.onChange(dateValue);
        }
        // eslint-disable-next-line
    }, [originalValue, range]);
    var smallScreen = width < 970;
    var isDateError = (error && typeof error === 'string') || dateError;
    var isNotOver18 = ((_b = relativeRange === null || relativeRange === void 0 ? void 0 : relativeRange.startDate) === null || _b === void 0 ? void 0 : _b.offset) === -18 &&
        ((_c = relativeRange === null || relativeRange === void 0 ? void 0 : relativeRange.startDate) === null || _c === void 0 ? void 0 : _c.units) === 'years' &&
        moment(mask.input.value).format(DATE_FORMAT.dashStartByMonth) !== 'Invalid date' &&
        moment().format(DATE_FORMAT.dash) > moment(mask.input.value).format(DATE_FORMAT.dash);
    var bigStyleLabel = (_jsx("label", __assign({ className: clsx([
            styles.DateInputPlaceholder,
            mask.input.value && styles.DateInputPlaceholderHide,
            bigStyle && styles.DateInputPlaceholderBig,
            (mask.input.value || isFocused) && bigStyle && styles.DateInputPlaceholderTop,
        ]) }, { children: placeholder || showedFormat })));
    return (_jsxs("div", __assign({ className: className }, { children: [_jsxs("div", __assign({ className: clsx(styles.DateInputWrapper, className), onClick: function () { return (smallScreen || disabled || locked) && onIconClick(); } }, { children: [label && _jsx("span", __assign({ className: styles.DateInputLabel }, { children: label })), _jsx("label", __assign({ className: styles.DateInputLabelWrapper }, { children: _jsxs("div", __assign({ className: styles.DateInputBlock }, { children: [bigStyle && bigStyleLabel, _jsx("input", __assign({}, (!bigStyle && { placeholder: placeholder || showedFormat }), { disabled: disabled || smallScreen || locked, onFocus: function () { return setIsFocused(true); }, onBlur: function () { return setIsFocused(false); }, className: clsx(styles.DateInput, bigStyle && styles.DateInputBig, (dateError || error) && styles.DateInputError, (disabled || locked) && styles.DateInputDisabled), type: "tel", onChange: mask.onChange }, mask.input)), !locked && (_jsx("div", __assign({ className: clsx(styles.DateInputIcon, 'DatePickerIcon', disabled && styles.DateInputIconDisabled), onClick: function (e) {
                                        e.stopPropagation();
                                        onIconClick();
                                    } }, { children: _jsx(DatePickerCalendar, {}) }))), locked && showLockIcon && (_jsx("div", __assign({ className: clsx(styles.DateInputIcon, 'DatePickerIcon', styles.lock) }, { children: _jsx(Lock, {}) })))] })) }))] })), isDateError && (_jsx("span", __assign({ className: styles.DateInputErrorText }, { children: 
                // {TODO: it need to fix}
                dateError
                    ? isNotOver18
                        ? 'The patient must be under the age of 18 to add to the account. Adult patients must have their own account.'
                        : 'Incorrect date'
                    : error })))] })));
};
