var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import Pen from '@core/shared/svg/pen';
import { replaceContent } from '@core/shared/utils/surveyUtils';
import styles from './index.module.sass';
export var TextMessage = function (_a) {
    var className = _a.className, isWarningMessage = _a.isWarningMessage, _b = _a.type, type = _b === void 0 ? 'income' : _b, _c = _a.handleEdit, handleEdit = _c === void 0 ? function () { } : _c, children = _a.children, editable = _a.editable, stepId = _a.stepId, label = _a.label, icon = _a.icon, topIcon = _a.topIcon, images = _a.images, disableEdit = _a.disableEdit, nonClickable = _a.nonClickable;
    var typeMessage = {
        income: styles.textMessageIncome,
        outcome: styles.textMessageOutcome,
    };
    var textMessageClasses = [
        styles.textMessage,
        typeMessage[type],
        icon && styles.textMessageFlex,
        className,
    ];
    var withoutLabel = !label && !children;
    if (withoutLabel)
        return null;
    return (_jsx(_Fragment, { children: (!!(children === null || children === void 0 ? void 0 : children.length) || label) && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: clsx(textMessageClasses, withoutLabel && styles.textMessageWithoutLabel, isWarningMessage && styles.warning) }, { children: [topIcon && _jsx("div", __assign({ className: styles.topIcon }, { children: topIcon })), replaceContent(label), editable && (_jsx("div", __assign({ className: clsx(styles.textMessageEditable, disableEdit && styles.textMessageEditableDisabled, nonClickable && styles.textMessageEditableNonClickable), onClick: function () { return handleEdit(stepId); } }, { children: _jsx(Pen, {}) }))), icon && _jsx("div", __assign({ className: styles.textMessageIcon }, { children: icon })), children && !!(children === null || children === void 0 ? void 0 : children.length) && children] })), !!(images === null || images === void 0 ? void 0 : images.length) && (_jsx("div", __assign({ className: clsx(styles.textMessageImgWrapper, type === 'outcome' && styles.textMessageImgWrapperOutcome) }, { children: images.map(function (image, index) { return (_jsx("img", { className: styles.textMessageImg, src: image, alt: 'Message content' }, index)); }) })))] })) }));
};
