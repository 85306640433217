var _a;
import { createSlice } from '@reduxjs/toolkit';
export var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus["verified"] = "verified";
    VerificationStatus["invalid"] = "invalid";
    VerificationStatus["not_validated"] = "not_validated";
    VerificationStatus["accepted"] = "accepted";
})(VerificationStatus || (VerificationStatus = {}));
export var InsuranceType;
(function (InsuranceType) {
    InsuranceType["regular"] = "Regular";
    InsuranceType["medicaid"] = "medicaid";
    InsuranceType["medicare"] = "medicare";
})(InsuranceType || (InsuranceType = {}));
export var DocumentTypes;
(function (DocumentTypes) {
    DocumentTypes["insurance"] = "insurance";
    DocumentTypes["military_id"] = "military_id";
    DocumentTypes["driver_license"] = "driver_license";
    DocumentTypes["residence_card"] = "residence_card";
    DocumentTypes["school_id"] = "school_id";
    DocumentTypes["passport"] = "passport";
    DocumentTypes["state_id"] = "state_id";
})(DocumentTypes || (DocumentTypes = {}));
var initialState = {
    isLoading: true,
    documents: [],
    currentPatient: null,
    validateInsurance: null,
};
export var docsSlice = createSlice({
    name: 'docs',
    initialState: initialState,
    reducers: {
        setDocsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        fetchDocuments: function () { },
        fetchDocumentsByPersonId: function (_, __) { },
        reverificationDocument: function (_, __) { },
        setValidateInsurance: function (state, action) {
            state.validateInsurance = action.payload;
        },
        deleteDocument: function (_, __) { },
        setDocuments: function (state, action) {
            state.documents = action.payload;
            state.isLoading = false;
        },
        setCurrentPatient: function (state, action) {
            state.currentPatient = action.payload;
        },
    },
});
export var setDocsLoading = (_a = docsSlice.actions, _a.setDocsLoading), fetchDocuments = _a.fetchDocuments, fetchDocumentsByPersonId = _a.fetchDocumentsByPersonId, reverificationDocument = _a.reverificationDocument, setValidateInsurance = _a.setValidateInsurance, deleteDocument = _a.deleteDocument, setDocuments = _a.setDocuments, setCurrentPatient = _a.setCurrentPatient;
export default docsSlice.reducer;
