var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { t } from 'i18next';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import subscriptionService from '@core/services/subscription';
import { DeclineERRORS, ERRORS } from '@core/shared/utils/subscriptionUtils';
import { setAlert } from '@core/store/reducers/alertSlice';
import { handlerErrors } from '@core/store/sagas/errors';
import { getInfoAllAsync } from './profiles';
import { cancelSubscription, cancelSubscriptionFail, cancelSubscriptionSuccess, createSubscription, createSubscriptionFail, createSubscriptionSuccess, getSubscriptionProducts, getSubscriptionProductsFail, getSubscriptionProductsSuccess, updateSubscription, updateSubscriptionFail, updateSubscriptionSuccess, } from '../reducers/subscriptionSlice';
function getSubscriptionProductsAsync() {
    var data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(subscriptionService.getProducts)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(getSubscriptionProductsSuccess(data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_1 = _a.sent();
                return [4 /*yield*/, put(getSubscriptionProductsFail())];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function createSubscriptionAsync(_a) {
    var data, e_1, error, currentError, declinedCard, currentDeclineCode;
    var _b, _c, _d, _e, _f, _g;
    var _h = _a.payload, user_id = _h.user_id, payment_source_id = _h.payment_source_id, stripe_price_id = _h.stripe_price_id, callback = _h.callback;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _j.trys.push([0, 4, , 7]);
                return [4 /*yield*/, call(subscriptionService.createSubscription, user_id, payment_source_id, stripe_price_id)];
            case 1:
                data = (_j.sent()).data;
                return [4 /*yield*/, put(createSubscriptionSuccess(data))];
            case 2:
                _j.sent();
                return [4 /*yield*/, call(getInfoAllAsync, { payload: { callback: function () { } } })];
            case 3:
                _j.sent();
                if (callback) {
                    callback(data);
                }
                return [3 /*break*/, 7];
            case 4:
                e_1 = _j.sent();
                error = e_1;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.code;
                declinedCard = ((_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.code) == 'card_declined';
                currentDeclineCode = (_g = (_f = error === null || error === void 0 ? void 0 : error.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.decline_code;
                return [4 /*yield*/, put(setAlert(declinedCard
                        ? t(DeclineERRORS[currentDeclineCode])
                        : t(ERRORS[currentError]) || 'error_unknown'))];
            case 5:
                _j.sent();
                return [4 /*yield*/, put(createSubscriptionFail())];
            case 6:
                _j.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function updateSubscriptionAsync(_a) {
    var data, error_2;
    var _b = _a.payload, user_id = _b.user_id, payment_source_id = _b.payment_source_id, subscription_id = _b.subscription_id;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(subscriptionService.updateSubscription, user_id, subscription_id, payment_source_id)];
            case 1:
                data = (_c.sent()).data;
                return [4 /*yield*/, put(updateSubscriptionSuccess(data))];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                error_2 = _c.sent();
                return [4 /*yield*/, put(updateSubscriptionFail())];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function cancelSubscriptionAsync(_a) {
    var data, error_3;
    var _b = _a.payload, user_id = _b.user_id, subscription_id = _b.subscription_id;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(subscriptionService.cancelSubscription, user_id, subscription_id)];
            case 1:
                data = (_c.sent()).data;
                return [4 /*yield*/, put(cancelSubscriptionSuccess(data))];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                error_3 = _c.sent();
                return [4 /*yield*/, put(cancelSubscriptionFail())];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function subscriptionWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getSubscriptionProducts, handlerErrors(getSubscriptionProductsAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(createSubscription, handlerErrors(createSubscriptionAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateSubscription, handlerErrors(updateSubscriptionAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(cancelSubscription, handlerErrors(cancelSubscriptionAsync))];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
