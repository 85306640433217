var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Button } from '@core/components/buttons';
import styles from './index.module.sass';
/*
This is simple confirmation popup according to LabQ design
To be used without additional styling, with props only
If additional styles are required, please use ConfirmationPopup instead
*/
export var SimpleConfirmPopup = function (_a) {
    var title = _a.title, description = _a.description, _b = _a.primaryButtonLabel, primaryButtonLabel = _b === void 0 ? 'Confirm' : _b, primaryButtonHandler = _a.primaryButtonHandler, _c = _a.secondaryButtonLabel, secondaryButtonLabel = _c === void 0 ? 'Cancel' : _c, secondaryButtonHandler = _a.secondaryButtonHandler, _d = _a.thirdButtonLabel, thirdButtonLabel = _d === void 0 ? 'Cancel' : _d, thirdButtonHandler = _a.thirdButtonHandler, _e = _a.isPrimary, isPrimary = _e === void 0 ? true : _e, // if true, primary button is primary
    _f = _a.isDanger, // if true, primary button is primary
    isDanger = _f === void 0 ? false : _f, // if true, primary button is red
    isSecondButtonPrimary = _a.isSecondButtonPrimary, isSecondButtonDanger = _a.isSecondButtonDanger, isThirdButtonPrimary = _a.isThirdButtonPrimary, isThirdButtonDanger = _a.isThirdButtonDanger, isLoading = _a.isLoading, // for button's loader. Works only with callback in modal ('updateStateUnstable')
    _g = _a.isBottomMobile, // for button's loader. Works only with callback in modal ('updateStateUnstable')
    isBottomMobile = _g === void 0 ? false : _g, // appears at the bottom of the screen
    _h = _a.isTextCentered, // appears at the bottom of the screen
    isTextCentered = _h === void 0 ? false : _h, // center all the text inside horizontally
    _j = _a.checkIsLoading, // center all the text inside horizontally
    checkIsLoading = _j === void 0 ? function () { return false; } : _j, isLoading2 = _a.isLoading2, _k = _a.checkIsLoading2, checkIsLoading2 = _k === void 0 ? function () { return false; } : _k, isLoading3 = _a.isLoading3, _l = _a.checkIsLoading3, checkIsLoading3 = _l === void 0 ? function () { return false; } : _l, classNames = _a.classNames;
    return (_jsxs("div", __assign({ className: clsx(styles.modal, !isBottomMobile && styles.modalMobileFullscreen, classNames === null || classNames === void 0 ? void 0 : classNames.modal) }, { children: [title && (_jsx("h3", __assign({ className: clsx(styles.modalTitle, isTextCentered && styles.modalTitleCentered, classNames === null || classNames === void 0 ? void 0 : classNames.title) }, { children: title }))), description && (_jsx("div", __assign({ className: clsx(styles.modalDescription, isTextCentered && styles.modalDescriptionCentered, classNames === null || classNames === void 0 ? void 0 : classNames.description) }, { children: description }))), _jsxs("div", __assign({ className: clsx(styles.modalButtons, !title && !description && styles.modalButtonsWithoutMargin) }, { children: [primaryButtonHandler && (_jsx(Button, { label: primaryButtonLabel, handler: primaryButtonHandler, primary: isPrimary, classes: [isDanger ? styles.modalButtonDanger : null, classNames === null || classNames === void 0 ? void 0 : classNames.btn1], loading: isLoading || checkIsLoading() })), secondaryButtonHandler && (_jsx(Button, { label: secondaryButtonLabel, handler: secondaryButtonHandler, primary: isSecondButtonPrimary, classes: [isSecondButtonDanger ? styles.modalButtonDanger : null, classNames === null || classNames === void 0 ? void 0 : classNames.btn2], loading: isLoading2 || checkIsLoading2() })), thirdButtonHandler && (_jsx(Button, { label: thirdButtonLabel, handler: thirdButtonHandler, primary: isThirdButtonPrimary, classes: [isThirdButtonDanger ? styles.modalButtonDanger : null, classNames === null || classNames === void 0 ? void 0 : classNames.btn3], loading: isLoading3 || checkIsLoading3() }))] }))] })));
};
