var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { t } from 'i18next';
import CheckMark from '@core/shared/svg/checkMark';
import { checkSubscriptionDetail } from '@core/shared/utils/subscriptionUtils';
import styles from './index.module.sass';
export var PlanCard = function (_a) {
    var plan = _a.plan, isActive = _a.isActive, onClick = _a.onClick, price = _a.price, discount = _a.discount;
    var planDetail = checkSubscriptionDetail({ subscription: plan });
    var name = t("label_qsure_".concat(planDetail === null || planDetail === void 0 ? void 0 : planDetail.type));
    if (planDetail.includesFamily) {
        name = 'Family ' + name;
    }
    return (_jsxs("div", __assign({ className: clsx(styles.container, isActive && styles.containerActive), onClick: onClick }, { children: [_jsxs("div", __assign({ className: styles.title }, { children: [planDetail.icon(), name] })), _jsx("div", __assign({ className: clsx(styles.period, isActive && styles.periodActive) }, { children: t("label_billed_".concat(price === null || price === void 0 ? void 0 : price.interval)) })), _jsxs("div", __assign({ className: styles.bottom }, { children: [_jsxs("div", __assign({ className: styles.bottomPrice }, { children: [_jsx("div", __assign({ className: styles.bottomPriceTitle }, { children: "$".concat(price.amount.value) })), discount && (_jsxs("div", __assign({ className: styles.bottomPriceDiscount }, { children: [_jsx("div", __assign({ className: styles.bottomPriceDiscountMonth }, { children: t("label_price_per_month", { price: "$".concat(discount.pricePerMonth.toFixed(2)) }) })), _jsx("div", __assign({ className: styles.bottomPriceDiscountPercent }, { children: "-".concat(discount.percent.toFixed(0), "%") }))] })))] })), isActive && (_jsx("div", __assign({ className: styles.bottomIcon }, { children: _jsx(CheckMark, {}) })))] }))] })));
};
