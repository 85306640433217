var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { BaseCard } from '@core/components/baseCard';
import ArrowRight from '@core/shared/svg/arrow-right2';
import styles from './index.module.sass';
export var FluTesting = function (_a) {
    var nav = _a.nav, classNames = _a.classNames, _b = _a.size, size = _b === void 0 ? 'large' : _b, onClick = _a.onClick, classNameImage = _a.classNameImage;
    var navigator = nav ? nav : useNavigate();
    var handleOnClick = function () {
        if (onClick) {
            onClick();
            return;
        }
        navigator(routePaths.BOOKING);
    };
    return (_jsxs(BaseCard, __assign({ handleOnClick: handleOnClick, classNames: clsx(styles.card, classNames) }, { children: [_jsx("div", { className: styles.overlay }), _jsxs("div", __assign({ className: styles.left }, { children: [_jsx("div", __assign({ className: styles.leftTitle }, { children: t('label_flu_season_testing') })), _jsxs("div", __assign({ className: styles.leftBtn }, { children: [_jsx("div", __assign({ className: styles.leftBtnText }, { children: t('label_order_now') })), _jsx(ArrowRight, {})] }))] })), _jsx("div", __assign({ className: clsx(styles.icon, size === 'small' && styles.iconSmall, classNameImage) }, { children: _jsx("img", { src: require('@core/shared/images/shop/flu_banner.png'), className: styles.iconImage }) }))] })));
};
