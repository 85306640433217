var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { t } from 'i18next';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { all } from 'typed-redux-saga';
import authService from '@core/services/auth';
import { navigate } from '@core/services/navigator';
import profileService from '@core/services/prfofile';
import surveyService from '@core/services/surveys';
import { SID, } from '@core/shared/models/survey';
import { getStep } from '@core/shared/utils/surveyUtils';
import { setAlert } from '@core/store/reducers/alertSlice';
import { setPerson } from '@core/store/reducers/bookingSlice';
import { setStatus } from '@core/store/reducers/profileSlice';
import { completeOnboarding, completeRisksTest, getSurveyFull, handleCancelQuestionEdit, handleCancelUploadMedia, handleDeleteEntity, handleSurveyFinish, handleSurveyNextButtonStep, handleSurveyQuestionEdit, handleSurveyUploadMedia, initSurvey, setDataForCancelQuestionEdit, setFullSurvey, setMedia, setPatient, setSurveyAnswers, setSurveyId, setSurveyLoading, setSurveyNextStep, setSurveySid, setSurveyStatus, setSurveyStep, setTempParams, setValidateInsurance, updateSurveyData, upRetry, } from '@core/store/reducers/surveySlice';
import { handlerErrors } from '@core/store/sagas/errors';
import { getState } from '@store/store';
import { getDataAsync } from './profiles';
var setWaiting = function () {
    return new Promise(function (resolve) {
        var interval = setInterval(function () {
            var pause = getState().survey.pause;
            if (!pause) {
                clearInterval(interval);
                resolve('200');
            }
        }, 500);
    });
};
var replaceSid = function (sid) {
    if (sid === SID.ADD_FAMILY_BY_SUPERVISOR) {
        return SID.ADD_FAMILY;
    }
    return sid;
};
export function initSurveyAsync(_a) {
    var params, isOnboarding, _b, booking, profile, data_1, data;
    var _c, _d, _e, _f;
    var payload = _a.payload;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0: return [4 /*yield*/, payload.sid === SID.ONBOARDING];
            case 1:
                isOnboarding = _g.sent();
                return [4 /*yield*/, select(function (s) { return s; })];
            case 2:
                _b = _g.sent(), booking = _b.booking, profile = _b.profile;
                if (!(((_c = payload === null || payload === void 0 ? void 0 : payload.params) === null || _c === void 0 ? void 0 : _c.person_id) && payload.sid !== SID.ADD_FAMILY_BY_SUPERVISOR)) return [3 /*break*/, 5];
                return [4 /*yield*/, call(profileService.getPersonById, payload.params.person_id)];
            case 3:
                data_1 = (_g.sent()).data;
                return [4 /*yield*/, put(setPatient(data_1))];
            case 4:
                _g.sent();
                _g.label = 5;
            case 5:
                if (payload.sid === SID.ADD_DOCUMENT ||
                    payload.sid === SID.PREBOOKING_COLLECTOR ||
                    payload.sid === SID.ADD_FAMILY_BY_SUPERVISOR) {
                    params = payload.params;
                    if (!params.person_id) {
                        return [2 /*return*/];
                    }
                }
                if (payload.sid === SID.PREBOOKING) {
                    params = { person_id: (_d = booking === null || booking === void 0 ? void 0 : booking.patient) === null || _d === void 0 ? void 0 : _d._id };
                    if (!params.person_id) {
                        return [2 /*return*/, navigate('/')];
                    }
                }
                if (payload.sid === SID.ADD_FAMILY) {
                    params = { person_id: ((_e = profile === null || profile === void 0 ? void 0 : profile.self) === null || _e === void 0 ? void 0 : _e.id) || ((_f = booking === null || booking === void 0 ? void 0 : booking.person) === null || _f === void 0 ? void 0 : _f._id) };
                }
                if (payload.sid === SID.HEALTH_RISK) {
                    params = payload.params;
                }
                if (payload.sid === SID.PRESCRIPTION) {
                    params = { order_id: payload.params.order_id };
                    if (!params.order_id) {
                        return [2 /*return*/];
                    }
                }
                if (!params) return [3 /*break*/, 7];
                return [4 /*yield*/, put(setTempParams(params))];
            case 6:
                _g.sent();
                _g.label = 7;
            case 7: return [4 /*yield*/, call(surveyService.createSurveyId, replaceSid(payload.sid), params && params)];
            case 8:
                data = _g.sent();
                if (!isOnboarding) return [3 /*break*/, 10];
                return [4 /*yield*/, put(setStatus(data.data.status))];
            case 9:
                _g.sent();
                _g.label = 10;
            case 10: return [4 /*yield*/, put(setSurveyId(data.data._id))];
            case 11:
                _g.sent();
                return [4 /*yield*/, put(setSurveySid(payload.sid))];
            case 12:
                _g.sent();
                if (!(payload.sid === SID.PREBOOKING_COLLECTOR)) return [3 /*break*/, 15];
                if (!(data.data.status === 'completed')) return [3 /*break*/, 15];
                return [4 /*yield*/, put(setSurveyLoading(true))];
            case 13:
                _g.sent();
                return [4 /*yield*/, put(handleSurveyFinish())];
            case 14:
                _g.sent();
                return [2 /*return*/];
            case 15: return [4 /*yield*/, put(getSurveyFull())];
            case 16:
                _g.sent();
                return [2 /*return*/];
        }
    });
}
export function getSurveyFullAsync() {
    var _a, surveyId, surveySid, data, allData, branches, content, media, fullSurvey, answers, step;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                _a = (_b.sent()).survey, surveyId = _a.surveyId, surveySid = _a.surveySid;
                return [4 /*yield*/, call(surveyService.getSurveyFull, surveyId, surveySid)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, all([
                        call(surveyService.getSurveyBranches, data.branches),
                        call(surveyService.getSurveyBranches, data.content),
                        call(surveyService.getMedia, surveyId, {}, surveySid),
                    ])];
            case 3:
                allData = _b.sent();
                if (!((!data.survey.next_step_id || data.survey.status === 'completed') &&
                    surveySid === SID.PRESCRIPTION)) return [3 /*break*/, 6];
                //refinish forms
                return [4 /*yield*/, put(setSurveyLoading(true))];
            case 4:
                //refinish forms
                _b.sent();
                return [4 /*yield*/, put(handleSurveyFinish())];
            case 5:
                _b.sent();
                return [2 /*return*/];
            case 6:
                branches = allData[0], content = allData[1], media = allData[2];
                return [4 /*yield*/, {
                        survey: data.survey,
                        branches: branches,
                        content: content,
                    }];
            case 7:
                fullSurvey = _b.sent();
                return [4 /*yield*/, put(setMedia(media.data))];
            case 8:
                _b.sent();
                return [4 /*yield*/, (fullSurvey.branches = fullSurvey.branches.filter(function (branch) { return branch.variation !== 'content'; }))]; //delete extra content
            case 9:
                _b.sent(); //delete extra content
                return [4 /*yield*/, put(setFullSurvey(fullSurvey))];
            case 10:
                _b.sent();
                return [4 /*yield*/, fullSurvey.survey.status === 'uploading'
                        ? fullSurvey.survey.answers.filter(function (answer) { return answer.step_id !== fullSurvey.survey.next_step_id; })
                        : fullSurvey.survey.answers];
            case 11:
                answers = _b.sent();
                return [4 /*yield*/, put(setSurveyAnswers(answers))];
            case 12:
                _b.sent();
                step = getStep(fullSurvey, fullSurvey.survey.next_step_id);
                return [4 /*yield*/, put(setSurveyStep(step))];
            case 13:
                _b.sent();
                return [4 /*yield*/, put(setSurveyLoading(false))];
            case 14:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function handleSurveyUploadMediaAsync(action) {
    var _a, surveyId, surveySid, _b, stepId, values, buttonId, _c, isUpdate, postData, data, _d, promises, surveyData, media, e_1;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                _a = (_e.sent()).survey, surveyId = _a.surveyId, surveySid = _a.surveySid;
                _b = action.payload, stepId = _b.stepId, values = _b.values, buttonId = _b.buttonId, _c = _b.isUpdate, isUpdate = _c === void 0 ? null : _c;
                if (!values.every(function (value) { return typeof value.media === 'string'; })) return [3 /*break*/, 3];
                //if all images not edit
                return [4 /*yield*/, put(handleCancelQuestionEdit())];
            case 2:
                //if all images not edit
                _e.sent();
                return [2 /*return*/];
            case 3:
                postData = values.map(function (value) { return ({ slot_id: value.slot_id }); });
                if (!isUpdate) return [3 /*break*/, 5];
                return [4 /*yield*/, call(surveyService.putSurveyAnswer, surveyId, stepId, postData, buttonId, surveySid)];
            case 4:
                _d = _e.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, call(surveyService.postSurveyAnswer, surveyId, stepId, postData, buttonId, surveySid)];
            case 6:
                _d = _e.sent();
                _e.label = 7;
            case 7:
                data = (_d).data;
                promises = [];
                values.forEach(function (value) {
                    if (typeof value.media === 'string') {
                        //if image not edit
                        return;
                    }
                    promises.push(new Promise(function (resolve) {
                        var formData = new FormData();
                        var mediaUploadInfo = data.media_upload_info.find(function (infoItem) { return infoItem.slot_id === value.slot_id; });
                        var uploadInfo = mediaUploadInfo.upload_info;
                        for (var key in uploadInfo.fields) {
                            formData.append(key, uploadInfo.fields[key]);
                        }
                        formData.append('file', value.media);
                        surveyService.uploadMediaData(surveyId, formData, uploadInfo.url).then(function () {
                            resolve('200');
                        });
                    }));
                });
                _e.label = 8;
            case 8:
                _e.trys.push([8, 15, , 17]);
                return [4 /*yield*/, all(promises)];
            case 9:
                _e.sent();
                return [4 /*yield*/, call(surveyService.confirmUploadMedia, surveyId, surveySid)];
            case 10:
                surveyData = (_e.sent()).data;
                return [4 /*yield*/, call(surveyService.getMedia, surveyId, {}, surveySid)];
            case 11:
                media = (_e.sent()).data;
                return [4 /*yield*/, put(setMedia(media))];
            case 12:
                _e.sent();
                return [4 /*yield*/, put(updateSurveyData(surveyData))];
            case 13:
                _e.sent();
                return [4 /*yield*/, put(setSurveyNextStep(surveyData))];
            case 14:
                _e.sent();
                return [3 /*break*/, 17];
            case 15:
                e_1 = _e.sent();
                return [4 /*yield*/, put(setSurveyStatus({ status: 'uploading' }))];
            case 16:
                _e.sent();
                throw e_1;
            case 17: return [2 /*return*/];
        }
    });
}
export function handleSurveyNextButtonAsync(action) {
    var _a, surveyId, fullSurvey, surveySid, _b, stepId, values, buttonId, _c, isUpdate, isInsurance, data, _d, media, e_2, _e, tempParams, surveySid, _f, values, setError, buttonId, handleCheckAddress, handleCheckFields, surveyType, isInsurance, err;
    var _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
    return __generator(this, function (_11) {
        switch (_11.label) {
            case 0:
                _11.trys.push([0, 24, , 54]);
                return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                _a = (_11.sent()).survey, surveyId = _a.surveyId, fullSurvey = _a.fullSurvey, surveySid = _a.surveySid;
                _b = action.payload, stepId = _b.stepId, values = _b.values, buttonId = _b.buttonId, _c = _b.isUpdate, isUpdate = _c === void 0 ? null : _c, isInsurance = _b.isInsurance;
                if (!(isInsurance === 'validate')) return [3 /*break*/, 3];
                return [4 /*yield*/, put(setValidateInsurance('loading'))];
            case 2:
                _11.sent();
                _11.label = 3;
            case 3:
                if (!(isInsurance === 'creating')) return [3 /*break*/, 5];
                return [4 /*yield*/, put(setValidateInsurance('creating'))];
            case 4:
                _11.sent();
                _11.label = 5;
            case 5:
                if (!(!fullSurvey.survey.next_step_id || fullSurvey.survey.status === 'completed')) return [3 /*break*/, 8];
                //refinish forms
                return [4 /*yield*/, put(setSurveyLoading(true))];
            case 6:
                //refinish forms
                _11.sent();
                return [4 /*yield*/, put(handleSurveyFinish())];
            case 7:
                _11.sent();
                return [2 /*return*/, false];
            case 8:
                if (!isUpdate) return [3 /*break*/, 10];
                return [4 /*yield*/, call(surveyService.putSurveyAnswer, surveyId, stepId, values, buttonId, surveySid)];
            case 9:
                _d = _11.sent();
                return [3 /*break*/, 12];
            case 10: return [4 /*yield*/, call(surveyService.postSurveyAnswer, surveyId, stepId, values, buttonId, surveySid)];
            case 11:
                _d = _11.sent();
                _11.label = 12;
            case 12:
                data = (_d).data;
                if (!isInsurance) return [3 /*break*/, 18];
                if (!(isInsurance === 'validate')) return [3 /*break*/, 14];
                return [4 /*yield*/, put(setValidateInsurance('success'))];
            case 13:
                _11.sent();
                _11.label = 14;
            case 14:
                if (!(isInsurance === 'creating')) return [3 /*break*/, 16];
                return [4 /*yield*/, put(setValidateInsurance('successUpload'))];
            case 15:
                _11.sent();
                _11.label = 16;
            case 16: return [4 /*yield*/, call(setWaiting)];
            case 17:
                _11.sent();
                _11.label = 18;
            case 18: return [4 /*yield*/, put(updateSurveyData(data.survey))];
            case 19:
                _11.sent();
                return [4 /*yield*/, put(setSurveyNextStep(data.survey))];
            case 20:
                _11.sent();
                if (!values.some(function (value) { return Object.keys(value).length === 1 && Object.keys(value)[0] === 'slot_id'; })) return [3 /*break*/, 23];
                return [4 /*yield*/, call(surveyService.getMedia, surveyId, {}, surveySid)];
            case 21:
                media = (_11.sent()).data;
                return [4 /*yield*/, put(setMedia(media))];
            case 22:
                _11.sent();
                _11.label = 23;
            case 23: return [3 /*break*/, 54];
            case 24:
                e_2 = _11.sent();
                return [4 /*yield*/, select(function (s) { return s; })];
            case 25:
                _e = (_11.sent()).survey, tempParams = _e.tempParams, surveySid = _e.surveySid;
                _f = action.payload, values = _f.values, setError = _f.setError, buttonId = _f.buttonId, handleCheckAddress = _f.handleCheckAddress, handleCheckFields = _f.handleCheckFields, surveyType = _f.surveyType, isInsurance = _f.isInsurance;
                err = e_2;
                if (!(((_g = err === null || err === void 0 ? void 0 : err.response) === null || _g === void 0 ? void 0 : _g.status) === 409)) return [3 /*break*/, 27];
                return [4 /*yield*/, put(setAlert(t((_j = (_h = err === null || err === void 0 ? void 0 : err.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.error) || t('error_unknown')))];
            case 26:
                _11.sent();
                return [2 /*return*/];
            case 27:
                if (!(((_l = (_k = err === null || err === void 0 ? void 0 : err.response) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.error) === 'answer_value_validation_error' &&
                    ((_o = (_m = err === null || err === void 0 ? void 0 : err.response) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.detail.find(function (el) { return el.type === 'value_error.tevix_verification_error'; })))) return [3 /*break*/, 31];
                if (!(isInsurance === 'validate')) return [3 /*break*/, 29];
                return [4 /*yield*/, put(setValidateInsurance('failed'))];
            case 28:
                _11.sent();
                _11.label = 29;
            case 29: return [4 /*yield*/, put(upRetry())];
            case 30:
                _11.sent();
                return [2 /*return*/];
            case 31:
                if (!(((_q = (_p = err === null || err === void 0 ? void 0 : err.response) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.error) === 'answer_value_validation_error' &&
                    ((_s = (_r = err === null || err === void 0 ? void 0 : err.response) === null || _r === void 0 ? void 0 : _r.data) === null || _s === void 0 ? void 0 : _s.detail.find(function (el) { return el.type === 'value_error.tevix_accepted_error'; })))) return [3 /*break*/, 35];
                if (!(isInsurance === 'validate')) return [3 /*break*/, 33];
                return [4 /*yield*/, put(setValidateInsurance('acceptedError'))];
            case 32:
                _11.sent();
                _11.label = 33;
            case 33: return [4 /*yield*/, put(upRetry())];
            case 34:
                _11.sent();
                return [2 /*return*/];
            case 35:
                if (!(((_w = (_v = (_u = (_t = err === null || err === void 0 ? void 0 : err.response) === null || _t === void 0 ? void 0 : _t.data) === null || _u === void 0 ? void 0 : _u.detail) === null || _v === void 0 ? void 0 : _v[0]) === null || _w === void 0 ? void 0 : _w.type) === 'value_error.slot_value_check_error')) return [3 /*break*/, 38];
                if (!(isInsurance === 'validate')) return [3 /*break*/, 37];
                return [4 /*yield*/, put(setValidateInsurance(null))];
            case 36:
                _11.sent();
                _11.label = 37;
            case 37:
                handleCheckFields({ values: values, correctData: err.response.data, buttonId: buttonId });
                return [2 /*return*/];
            case 38:
                if (!(surveyType === 'forms')) return [3 /*break*/, 44];
                if (!(((_y = (_x = err === null || err === void 0 ? void 0 : err.response) === null || _x === void 0 ? void 0 : _x.data) === null || _y === void 0 ? void 0 : _y.error) === 'address_not_found_error')) return [3 /*break*/, 40];
                return [4 /*yield*/, put(setAlert(t(err.response.data.error)))];
            case 39:
                _11.sent();
                return [2 /*return*/];
            case 40:
                if (!(((_0 = (_z = err === null || err === void 0 ? void 0 : err.response) === null || _z === void 0 ? void 0 : _z.data) === null || _0 === void 0 ? void 0 : _0.error) === 'invalid_apartment_error')) return [3 /*break*/, 42];
                return [4 /*yield*/, call(handleCheckAddress, { isApartmentError: true })];
            case 41:
                _11.sent();
                return [2 /*return*/];
            case 42:
                if (!(((_4 = (_3 = (_2 = (_1 = err === null || err === void 0 ? void 0 : err.response) === null || _1 === void 0 ? void 0 : _1.data) === null || _2 === void 0 ? void 0 : _2.detail) === null || _3 === void 0 ? void 0 : _3[0]) === null || _4 === void 0 ? void 0 : _4.type) === 'Value_error.invalid_address_slot_error')) return [3 /*break*/, 44];
                return [4 /*yield*/, call(handleCheckAddress, {
                        values: values,
                        correctAddressData: err.response.data,
                        buttonId: buttonId,
                    })];
            case 43:
                _11.sent();
                return [2 /*return*/];
            case 44:
                if (!isInsurance) return [3 /*break*/, 46];
                return [4 /*yield*/, put(setValidateInsurance(null))];
            case 45:
                _11.sent();
                _11.label = 46;
            case 46:
                if (!(((_6 = (_5 = err === null || err === void 0 ? void 0 : err.response) === null || _5 === void 0 ? void 0 : _5.data) === null || _6 === void 0 ? void 0 : _6.error) === 'invalid_next_step_error')) return [3 /*break*/, 49];
                return [4 /*yield*/, put(setSurveyLoading(true))];
            case 47:
                _11.sent();
                return [4 /*yield*/, put(initSurvey(__assign({ sid: surveySid }, (tempParams && { params: tempParams }))))];
            case 48:
                _11.sent();
                throw e_2;
            case 49:
                if (!(((_8 = (_7 = err === null || err === void 0 ? void 0 : err.response) === null || _7 === void 0 ? void 0 : _7.data) === null || _8 === void 0 ? void 0 : _8.error) === 'additional_validation_error' &&
                    typeof ((_10 = (_9 = err === null || err === void 0 ? void 0 : err.response) === null || _9 === void 0 ? void 0 : _9.data) === null || _10 === void 0 ? void 0 : _10.detail) === 'string')) return [3 /*break*/, 51];
                return [4 /*yield*/, put(setAlert(err.response.data.detail))];
            case 50:
                _11.sent();
                _11.label = 51;
            case 51:
                if (!(setError && values.length)) return [3 /*break*/, 53];
                return [4 /*yield*/, call(setFieldError, err, setError, values)];
            case 52:
                _11.sent();
                _11.label = 53;
            case 53: throw e_2;
            case 54: return [2 /*return*/];
        }
    });
}
export function handleSurveyQuestionEditAsync(action) {
    var _a, answers, fullSurvey, currentStep, stepId, stepIndex, currentAnswer, newAnswers, newStep;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                _a = (_b.sent()).survey, answers = _a.answers, fullSurvey = _a.fullSurvey, currentStep = _a.currentStep;
                return [4 /*yield*/, action.payload];
            case 2:
                stepId = (_b.sent()).stepId;
                return [4 /*yield*/, answers.findIndex(function (step) { return step.step_id === stepId; })];
            case 3:
                stepIndex = _b.sent();
                return [4 /*yield*/, answers[stepIndex]];
            case 4:
                currentAnswer = _b.sent();
                return [4 /*yield*/, answers.slice(0, stepIndex)];
            case 5:
                newAnswers = _b.sent();
                return [4 /*yield*/, JSON.parse(JSON.stringify(getStep(fullSurvey, stepId)))];
            case 6:
                newStep = _b.sent();
                return [4 /*yield*/, newStep.step.tags.push('update')];
            case 7:
                _b.sent();
                return [4 /*yield*/, (newStep.answer = currentAnswer)];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(setDataForCancelQuestionEdit({ answers: answers, currentStep: currentStep }))];
            case 9:
                _b.sent();
                return [4 /*yield*/, put(setSurveyAnswers(newAnswers))];
            case 10:
                _b.sent();
                return [4 /*yield*/, put(setSurveyStep(newStep))];
            case 11:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function handleCancelQuestionEditAsync(_) {
    var _a, answers, currentStep;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                _a = (_b.sent()).survey.dataForCancelQuestionEdit, answers = _a.answers, currentStep = _a.currentStep;
                return [4 /*yield*/, put(setDataForCancelQuestionEdit({}))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(setSurveyAnswers(answers))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setSurveyStep(currentStep))];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function handleCancelUploadMediaAsync(_) {
    var _a, surveyId, surveySid, data;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                _a = (_b.sent()).survey, surveyId = _a.surveyId, surveySid = _a.surveySid;
                return [4 /*yield*/, call(surveyService.cancelUploadMedia, surveyId, surveySid)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(updateSurveyData(data))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setSurveyAnswers(data.answers))];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function handleDeleteEntityAsync(action) {
    var surveySid, _a, surveyId, baseStepId, index, data;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                surveySid = (_b.sent()).survey.surveySid;
                _a = action.payload, surveyId = _a.surveyId, baseStepId = _a.baseStepId, index = _a.index;
                return [4 /*yield*/, call(surveyService.deleteEntity, { surveyId: surveyId, baseStepId: baseStepId, index: index }, surveySid)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(updateSurveyData(data))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setSurveyAnswers(data.answers))];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function setSurveyNextStepAsync(action) {
    var fullSurvey, _a, next_step_id, answers, status, nextStep;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                fullSurvey = (_b.sent()).survey.fullSurvey;
                _a = action.payload, next_step_id = _a.next_step_id, answers = _a.answers, status = _a.status;
                if (!(status === 'completed')) return [3 /*break*/, 4];
                return [4 /*yield*/, put(setSurveyLoading(true))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(handleSurveyFinish())];
            case 3:
                _b.sent();
                return [2 /*return*/];
            case 4:
                nextStep = getStep(fullSurvey, next_step_id);
                return [4 /*yield*/, put(setSurveyAnswers(answers))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(setSurveyStep(nextStep))];
            case 6:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function handleSurveyFinishAsync(_) {
    var _a, surveyId, surveySid, finishCallback, response, user, e_3;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                _a = (_e.sent()).survey, surveyId = _a.surveyId, surveySid = _a.surveySid, finishCallback = _a.finishCallback;
                return [4 /*yield*/, put(setSurveyLoading(true))];
            case 2:
                _e.sent();
                _e.label = 3;
            case 3:
                _e.trys.push([3, 15, , 17]);
                return [4 /*yield*/, call(surveyService.completeSurvey, surveyId, surveySid)];
            case 4:
                response = _e.sent();
                if (!(response.data.status === 'submission_error')) return [3 /*break*/, 7];
                return [4 /*yield*/, put(setSurveyLoading(false))];
            case 5:
                _e.sent();
                return [4 /*yield*/, put(updateSurveyData(response.data))];
            case 6:
                _e.sent();
                return [2 /*return*/];
            case 7:
                if (!(surveySid === SID.NEW_USER)) return [3 /*break*/, 12];
                user = ((_d = (_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c['ziphy-qapi']) === null || _d === void 0 ? void 0 : _d.person) || '';
                if (!user) return [3 /*break*/, 9];
                return [4 /*yield*/, put(setPerson(user))];
            case 8:
                _e.sent();
                _e.label = 9;
            case 9:
                if (!finishCallback) return [3 /*break*/, 11];
                return [4 /*yield*/, call(finishCallback, user)];
            case 10:
                _e.sent();
                _e.label = 11;
            case 11: return [2 /*return*/];
            case 12:
                if (!finishCallback) return [3 /*break*/, 14];
                return [4 /*yield*/, call(finishCallback)];
            case 13:
                _e.sent();
                _e.label = 14;
            case 14: return [3 /*break*/, 17];
            case 15:
                e_3 = _e.sent();
                return [4 /*yield*/, put(setSurveyLoading(false))];
            case 16:
                _e.sent();
                return [3 /*break*/, 17];
            case 17: return [2 /*return*/];
        }
    });
}
function completeOnboardingAsync(action) {
    var roles;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(getDataAsync)];
            case 1:
                roles = (_a.sent()).roles;
                return [4 /*yield*/, call(authService.refresh)];
            case 2:
                _a.sent();
                if (!!(roles === null || roles === void 0 ? void 0 : roles.includes('mergeable'))) return [3 /*break*/, 4];
                return [4 /*yield*/, navigate('/account-merge/', {
                        replace: true,
                        state: { redirectPath: action.payload, survey: true },
                    })];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/, false];
        }
    });
}
function completeRisksTestAsync(action) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(getDataAsync)];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(authService.refresh)];
            case 2:
                _a.sent();
                return [4 /*yield*/, navigate(action.payload, {
                        replace: true,
                        state: { redirectPath: action.payload, survey: true },
                    })];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function setFieldError(err, setError, values) {
    var details, i, detailIndex;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                details = ((_b = (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.detail) || [];
                i = 0;
                _c.label = 1;
            case 1:
                if (!(i < details.length)) return [3 /*break*/, 4];
                detailIndex = details[i].loc && details[i].loc.findIndex(function (detail) { return typeof detail === 'number'; });
                if (!(detailIndex !== -1)) return [3 /*break*/, 3];
                return [4 /*yield*/, call(setError, values[details[i]['loc'][detailIndex]]['slot_id'], __assign({ type: 'validation' }, (details[i]['msg'] && {
                        message: t(details[i].type.replace(/\./g, '_')),
                    })))];
            case 2:
                _c.sent();
                _c.label = 3;
            case 3:
                i++;
                return [3 /*break*/, 1];
            case 4: return [2 /*return*/];
        }
    });
}
export function surveyWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(initSurvey, handlerErrors(initSurveyAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(getSurveyFull, handlerErrors(getSurveyFullAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(handleSurveyUploadMedia, handlerErrors(handleSurveyUploadMediaAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(handleSurveyNextButtonStep, handlerErrors(handleSurveyNextButtonAsync))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(handleSurveyQuestionEdit, handlerErrors(handleSurveyQuestionEditAsync))];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(handleCancelQuestionEdit, handlerErrors(handleCancelQuestionEditAsync))];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(handleCancelUploadMedia, handlerErrors(handleCancelUploadMediaAsync))];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(setSurveyNextStep, handlerErrors(setSurveyNextStepAsync))];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(handleDeleteEntity, handlerErrors(handleDeleteEntityAsync))];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(handleSurveyFinish, handlerErrors(handleSurveyFinishAsync))];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeEvery(completeOnboarding, completeOnboardingAsync)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeEvery(completeRisksTest, completeRisksTestAsync)];
            case 12:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
