export var SID;
(function (SID) {
    SID["ONBOARDING"] = "ONBOARDING2";
    SID["ADD_DOCUMENT"] = "ADD-DOCUMENT";
    SID["NEW_USER"] = "NEW-USER";
    SID["PREBOOKING"] = "PREBOOKING";
    SID["PREBOOKING_COLLECTOR"] = "PREBOOKING-COLLECTOR";
    SID["ADD_FAMILY"] = "FAMILY";
    SID["ADD_FAMILY_BY_SUPERVISOR"] = "ADD_FAMILY_BY_SUPERVISOR";
    SID["PRESCRIPTION"] = "PRESCRIPTION";
    SID["HEALTH_RISK"] = "QUESTIONNAIRE-HEALTH-RISK-ASSESSMENT";
})(SID || (SID = {}));
