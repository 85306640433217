var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import { TextMessage } from '@core/components/surveyMessages/textMessage';
import { scrollToSmoothly } from '@core/shared/utils/scrollHelper';
import { handleSurveyFinish } from '@core/store/reducers/surveySlice';
import styles from './index.module.sass';
export var FinishSurvey = function (_a) {
    var label = _a.label;
    var dispatch = useDispatch();
    var handleFinishSurvey = function () {
        dispatch(handleSurveyFinish());
    };
    useEffect(function () {
        setTimeout(function () { return scrollToSmoothly(document.body.scrollHeight, 0); }, 0);
    }, []);
    return (_jsxs("div", __assign({ className: styles.ErrorStep }, { children: [_jsx(TextMessage, { type: "income", label: label }), _jsx(Button, { primary: true, classes: [styles.finishButton], handler: handleFinishSurvey, label: t('Finish') })] })));
};
