var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { t } from 'i18next';
import { call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import geolocationService from '@core/services/geolocation';
import { serviceMap } from '@core/services/map';
import shopService from '@core/services/shop';
import { ProcedureType } from '@core/shared/constants/order';
import { findActiveSubscription } from '@core/shared/utils/subscriptionUtils';
import { handlerErrors } from './errors';
import { setAlert } from '../reducers/alertSlice';
import { getCategories, getCategoriesFail, getCategoriesSuccess, getGeolocation, getGeolocationFail, getGeolocationSuccess, getOfficesNearby, getOfficesNearbyFail, getOfficesNearbySuccess, getProcedureDetail, getProcedureDetailFail, getProcedureDetailSuccess, getProcedures, getProceduresFail, getProceduresSuccess, getSeasonProcedure, getSeasonProcedureFail, getSeasonProcedureSuccess, setGeolocationDenied, } from '../reducers/shopSlice';
function getCategoriesAsync(action) {
    var data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(shopService.getCategories)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(getCategoriesSuccess(data))];
            case 2:
                _a.sent();
                if (!action.payload) return [3 /*break*/, 4];
                return [4 /*yield*/, call(action.payload)];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_1 = _a.sent();
                return [4 /*yield*/, put(getCategoriesFail())];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getProceduresAsync(action) {
    var subscriptions, activeSubscription, params, data, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                return [4 /*yield*/, select(function (state) { return state.profile.self.active_subscriptions; })];
            case 1:
                subscriptions = _a.sent();
                activeSubscription = findActiveSubscription(subscriptions);
                params = __assign(__assign({}, action.payload.data), { procedure_types: [ProcedureType.PANEL] });
                if (activeSubscription) {
                    params.subscription_products = [activeSubscription === null || activeSubscription === void 0 ? void 0 : activeSubscription.subscription_product_id];
                }
                return [4 /*yield*/, call(shopService.getProcedures, params)];
            case 2:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(getProceduresSuccess({
                        data: data,
                        isAdded: action.payload.isAdded,
                    }))];
            case 3:
                _a.sent();
                if (!action.payload.callback) return [3 /*break*/, 5];
                return [4 /*yield*/, call(action.payload.callback, data.results)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_2 = _a.sent();
                return [4 /*yield*/, put(getProceduresFail())];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function getProcedureDetailAsync(action) {
    var data, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(shopService.getProcedureDetail, action.payload.id)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(getProcedureDetailSuccess(data))];
            case 2:
                _a.sent();
                if (!action.payload.callback) return [3 /*break*/, 4];
                return [4 /*yield*/, call(action.payload.callback)];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_3 = _a.sent();
                return [4 /*yield*/, put(getProcedureDetailFail())];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getSeasonProcedureAsync(action) {
    var data, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(shopService.getProcedures, { text_query: action.payload.code })];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(getSeasonProcedureSuccess(data))];
            case 2:
                _a.sent();
                if (!action.payload.callback) return [3 /*break*/, 4];
                return [4 /*yield*/, call(action.payload.callback, data.results)];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_4 = _a.sent();
                return [4 /*yield*/, put(getSeasonProcedureFail())];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getGeolocationAsync() {
    var userLocation, address, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 8, , 11]);
                return [4 /*yield*/, call(geolocationService.getUserLocation)];
            case 1:
                userLocation = _a.sent();
                if (!userLocation.error) return [3 /*break*/, 5];
                return [4 /*yield*/, put(setAlert(userLocation.error))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(setGeolocationDenied(true))];
            case 3:
                _a.sent();
                localStorage.setItem('geolocationDenied', 'true');
                localStorage.removeItem('geolocation');
                return [4 /*yield*/, put(getGeolocationFail())];
            case 4:
                _a.sent();
                return [2 /*return*/];
            case 5: return [4 /*yield*/, call(geolocationService.getAddressByCoords, userLocation.lat, userLocation.lon)];
            case 6:
                address = _a.sent();
                localStorage.setItem('geolocation', JSON.stringify(address));
                return [4 /*yield*/, put(getGeolocationSuccess(address))];
            case 7:
                _a.sent();
                return [3 /*break*/, 11];
            case 8:
                error_5 = _a.sent();
                return [4 /*yield*/, put(setAlert(error_5))];
            case 9:
                _a.sent();
                return [4 /*yield*/, put(getGeolocationFail())];
            case 10:
                _a.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
function getOfficesNearbyAsync(action) {
    var data, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(serviceMap.getOffices, action.payload.lat, action.payload.lng, action.payload.radius)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(getOfficesNearbySuccess(data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _a.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(getOfficesNearbyFail())];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function shopWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(getCategories.type, handlerErrors(getCategoriesAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLeading(getProcedures.type, handlerErrors(getProceduresAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(getProcedureDetail.type, handlerErrors(getProcedureDetailAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(getGeolocation.type, handlerErrors(getGeolocationAsync))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(getOfficesNearby.type, handlerErrors(getOfficesNearbyAsync))];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(getSeasonProcedure.type, handlerErrors(getSeasonProcedureAsync))];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
