var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { t } from 'i18next';
import { countBy } from 'lodash';
import Close from '@core/shared/svg/close';
import styles from './index.module.sass';
export var TextAreaWithSearch = function (_a) {
    var data = _a.data, error = _a.error, onSelect = _a.onSelect, onUnselect = _a.onUnselect, placeholder = _a.placeholder, disabled = _a.disabled, className = _a.className, hasInsideLabel = _a.hasInsideLabel, insideLabel = _a.insideLabel, allowEmpty = _a.allowEmpty, showListOnClick = _a.showListOnClick, _b = _a.emptyText, emptyText = _b === void 0 ? t('label_empty_list') : _b, isSearchableByAll = _a.isSearchableByAll, isSearchableByAliases = _a.isSearchableByAliases, unsupportedItems = _a.unsupportedItems;
    var _c = useForm(), register = _c.register, watch = _c.watch, setFocus = _c.setFocus, setValue = _c.setValue;
    var _d = register('textArea'), onChange = _d.onChange, onBlur = _d.onBlur, name = _d.name, ref = _d.ref;
    var refList = useRef();
    var _e = useState(false), isShowSearch = _e[0], setShowSearch = _e[1];
    var _f = useState(data), localData = _f[0], setLocalData = _f[1];
    var _g = useState(data.filter(function (el) { return !el.isSelect; })), filteredList = _g[0], setFilteredList = _g[1];
    var _h = useState(data.filter(function (el) { return el.isSelect; })), selectedItems = _h[0], setSelectedItems = _h[1];
    var _j = useState(-1), focusedItemIndex = _j[0], setFocusedItemIndex = _j[1];
    var _k = useState(-1), focusedSearchItemIndex = _k[0], setFocusedSearchItemIndex = _k[1];
    var _l = useState(countBy(selectedItems, 'isSelect').true || 0), selectedCount = _l[0], setSelectedCount = _l[1];
    var _m = useState(false), isActive = _m[0], setActive = _m[1];
    useEffect(function () {
        setFilteredList(data.filter(function (el) { return !el.isSelect; }));
        var selected = data.filter(function (el) { return el.isSelect; });
        setSelectedItems(selected);
        setSelectedCount(selected.length);
    }, [data]);
    useEffect(function () {
        setSelectedCount(selectedItems.filter(function (el) { return el.isSelect; }).length);
    }, [selectedItems]);
    useEffect(function () {
        watch(function (value) {
            if (value.textArea) {
                setShowSearch(true);
                setFocusedSearchItemIndex(0);
                setFilteredList(localData.filter(function (el) {
                    var _a;
                    var query = value.textArea.toLowerCase().trim();
                    if (isSearchableByAliases) {
                        return (el.name.toLowerCase().includes(query) ||
                            el.id.toLowerCase().includes(query) ||
                            ((_a = el.aliases) === null || _a === void 0 ? void 0 : _a.some(function (alias) { return alias.toLowerCase().includes(query); })));
                    }
                    if (isSearchableByAll) {
                        return el.name.toLowerCase().includes(query) || el.id.toLowerCase().includes(query);
                    }
                    return el.name.toLowerCase().includes(query);
                }));
            }
            else {
                setShowSearch(false);
            }
        });
    }, [localData]);
    useEffect(function () {
        if (!refList.current)
            return;
        var scrollTop = refList.current.scrollTop;
        if (focusedSearchItemIndex > scrollTop / 40 + 4) {
            refList.current.scrollTo({ top: scrollTop + 40 });
        }
        else if (focusedSearchItemIndex < scrollTop / 40) {
            refList.current.scrollTo({ top: scrollTop - 40 });
        }
    }, [focusedSearchItemIndex, refList]);
    useEffect(function () {
        if (!isActive && isShowSearch) {
            setShowSearch(false);
        }
    }, [isActive]);
    var handleKeyDown = function (event) {
        if (!isShowSearch) {
            switch (event.key) {
                case 'Backspace':
                case 'Delete':
                    if (selectedItems.length) {
                        setFocusedItemIndex(selectedItems.length - 1);
                    }
                    if (focusedItemIndex > -1) {
                        removeItem(event, selectedItems[focusedItemIndex]);
                        if (focusedItemIndex < selectedItems.length - 1) {
                            setFocusedItemIndex(focusedItemIndex);
                        }
                        else {
                            setFocusedItemIndex(-1);
                        }
                    }
                    break;
                case 'ArrowLeft':
                    if (focusedItemIndex === -1) {
                        setFocusedItemIndex(selectedItems.length - 1);
                    }
                    else if (focusedItemIndex !== 0) {
                        setFocusedItemIndex(focusedItemIndex - 1);
                    }
                    break;
                case 'ArrowRight':
                    if (focusedItemIndex === selectedItems.length - 1 || focusedItemIndex === -1) {
                        setFocusedItemIndex(-1);
                    }
                    else {
                        setFocusedItemIndex(focusedItemIndex + 1);
                    }
                    break;
                default:
                    setFocusedItemIndex(-1);
            }
        }
        else {
            switch (event.key) {
                case 'ArrowUp':
                    event.preventDefault();
                    if (focusedSearchItemIndex > 0) {
                        setFocusedSearchItemIndex(focusedSearchItemIndex - 1);
                    }
                    break;
                case 'ArrowDown':
                    event.preventDefault();
                    if (focusedSearchItemIndex < filteredList.length - 1) {
                        setFocusedSearchItemIndex(focusedSearchItemIndex + 1);
                    }
                    break;
                case 'Enter':
                    event.preventDefault();
                    filteredList.length &&
                        selectItem(event, filteredList.find(function (e, i) { return i === focusedSearchItemIndex; }));
                    break;
                case 'Escape':
                case 'Backspace':
                case 'Delete':
                    setShowSearch(false);
                    break;
                default:
                    break;
            }
        }
    };
    var selectItem = function (e, selectedItem) {
        e.stopPropagation();
        onSelect(selectedItem);
        setShowSearch(false);
        var notSelectedItems = localData.filter(function (item) { return item.id !== selectedItem.id; });
        setLocalData(notSelectedItems);
        setFilteredList(notSelectedItems);
        setSelectedItems(function (items) { return __spreadArray(__spreadArray([], items, true), [selectedItem], false); });
        setValue('textArea', '');
    };
    var removeItem = function (e, itemRm) {
        e.stopPropagation();
        onUnselect(itemRm);
        setShowSearch(false);
        setSelectedItems(selectedItems.filter(function (item) { return item.id !== itemRm.id; }));
        setFilteredList(function (prev) { return __spreadArray(__spreadArray([], prev, true), [itemRm], false); });
        setLocalData(function (localData) { return __spreadArray(__spreadArray([], localData, true), [itemRm], false); });
    };
    var handleClickTextArea = function () {
        if (!isShowSearch && showListOnClick) {
            setShowSearch(true);
        }
        setActive(true);
        setFocus('textArea');
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: clsx(styles.textArea, className, (error === null || error === void 0 ? void 0 : error.length) && styles.textAreaInputError, disabled && styles.textAreaDisabled, isActive && styles.textAreaActive), onClick: handleClickTextArea }, { children: [hasInsideLabel && insideLabel && (_jsx("label", __assign({ className: styles.insideLabel }, { children: insideLabel }))), _jsxs("div", __assign({ className: styles.textAreaContent }, { children: [setSelectedItems.length &&
                                selectedItems.map(function (item, index) { return (_jsxs("div", __assign({ className: clsx((unsupportedItems === null || unsupportedItems === void 0 ? void 0 : unsupportedItems.includes(item.id))
                                        ? clsx(styles.textAreaItem, styles.textAreaItemUnsupported)
                                        : styles.textAreaItem, isSearchableByAll && styles.textAreaItemWithPopup, focusedItemIndex === index && styles.textAreaItemIsFocused), "data-code": item.id }, { children: [_jsx("span", __assign({ className: styles.textAreaItemText }, { children: item.name })), (selectedCount > 1 || (selectedCount === 1 && allowEmpty)) && (_jsx("div", __assign({ className: styles.textAreaItemClose, onClick: function (e) { return removeItem(e, item); } }, { children: _jsx(Close, {}) })))] }), item.uniqueKey || item.id)); }), _jsxs("div", __assign({ className: styles.textAreaInputContainer }, { children: [_jsx("input", { onChange: onChange, onBlur: function (e) {
                                            setActive(false);
                                            onBlur(e);
                                        }, onKeyDown: handleKeyDown, name: name, placeholder: !selectedItems.length ? placeholder : undefined, ref: ref, autoComplete: "off", disabled: disabled, className: styles.textAreaInput }), isShowSearch && (_jsx("div", __assign({ className: styles.list, ref: refList }, { children: filteredList.length ? (filteredList.map(function (el, index) { return (_jsx("div", __assign({ className: clsx(styles.listElem, index === focusedSearchItemIndex && styles.listElemActive), onMouseDown: function (e) { return selectItem(e, el); } }, { children: el.name }), el.uniqueKey || el.id)); })) : (_jsx("div", __assign({ className: styles.listEmpty }, { children: emptyText }))) })))] }))] }))] })), !!(error === null || error === void 0 ? void 0 : error.length) && _jsx("div", __assign({ className: styles.error }, { children: error }))] }));
};
