var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { t } from 'i18next';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import paymentService from '@core/services/payment';
import { DeclineERRORS, ERRORS } from '@core/shared/utils/subscriptionUtils';
import { addPaymentMethod, addPaymentMethodSuccess, deletePaymentMethod, deletePaymentMethodSuccess, getPaymentMethods, getPaymentMethodsSuccess, getPaymentMethodsWithoutLoading, resetLoading, } from '@core/store/reducers/paymentsSlice';
import { handlerErrors } from './errors';
import { setAlert } from '../reducers/alertSlice';
export function getPaymentMethodsListAsync(action) {
    var id, data, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 9]);
                return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                id = (_a.sent()).profile.id;
                return [4 /*yield*/, call(paymentService.getPaymentList, id)];
            case 2:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(getPaymentMethodsSuccess(data))];
            case 3:
                _a.sent();
                if (!action.payload) return [3 /*break*/, 5];
                return [4 /*yield*/, call(action.payload)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 9];
            case 6:
                e_1 = _a.sent();
                return [4 /*yield*/, put(setAlert('Something went wrong'))];
            case 7:
                _a.sent();
                return [4 /*yield*/, put(resetLoading())];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function addPaymentMethodAsync(action) {
    var _a, stripe, card, callback, id, res, data, e_2, error, currentError, declinedCard, currentDeclineCode;
    var _b, _c, _d, _e, _f, _g, _h, _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                _k.trys.push([0, 12, , 15]);
                _a = action.payload, stripe = _a.stripe, card = _a.card, callback = _a.callback;
                return [4 /*yield*/, select(function (s) { return s; })
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                ];
            case 1:
                id = (_k.sent()).profile.id;
                return [4 /*yield*/, call(stripe.createToken, card)];
            case 2:
                res = _k.sent();
                if (!!res.token) return [3 /*break*/, 5];
                return [4 /*yield*/, put(setAlert(res.error ? (_b = res.error) === null || _b === void 0 ? void 0 : _b.message : t('error_unknown')))];
            case 3:
                _k.sent();
                return [4 /*yield*/, put(resetLoading())];
            case 4:
                _k.sent();
                return [2 /*return*/];
            case 5: return [4 /*yield*/, call(paymentService.addPaymentMethod, (_c = res.token) === null || _c === void 0 ? void 0 : _c.id, id)];
            case 6:
                data = (_k.sent()).data;
                return [4 /*yield*/, put(addPaymentMethodSuccess(data))];
            case 7:
                _k.sent();
                return [4 /*yield*/, put(setAlert(t('alert_payment_method_added')))];
            case 8:
                _k.sent();
                if (!callback) return [3 /*break*/, 10];
                return [4 /*yield*/, call(callback)];
            case 9:
                _k.sent();
                _k.label = 10;
            case 10: return [4 /*yield*/, put(getPaymentMethodsWithoutLoading())];
            case 11:
                _k.sent();
                return [3 /*break*/, 15];
            case 12:
                e_2 = _k.sent();
                error = e_2;
                currentError = (_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.code;
                declinedCard = ((_g = (_f = error === null || error === void 0 ? void 0 : error.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.code) == 'card_declined';
                currentDeclineCode = (_j = (_h = error === null || error === void 0 ? void 0 : error.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.decline_code;
                return [4 /*yield*/, put(setAlert(declinedCard
                        ? t(DeclineERRORS[currentDeclineCode])
                        : t(ERRORS[currentError]) || 'error_unknown'))];
            case 13:
                _k.sent();
                return [4 /*yield*/, put(resetLoading())];
            case 14:
                _k.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
export function deletePaymentMethodAsync(action) {
    var id, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 8]);
                return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                id = (_a.sent()).profile.id;
                return [4 /*yield*/, call(paymentService.deletePaymentMethod, action.payload, id)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(deletePaymentMethodSuccess())];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(getPaymentMethodsWithoutLoading())];
            case 4:
                _a.sent();
                return [3 /*break*/, 8];
            case 5:
                e_3 = _a.sent();
                return [4 /*yield*/, put(setAlert('Something went wrong'))];
            case 6:
                _a.sent();
                return [4 /*yield*/, put(resetLoading())];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function paymentsWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getPaymentMethods.type, handlerErrors(getPaymentMethodsListAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(getPaymentMethodsWithoutLoading.type, handlerErrors(getPaymentMethodsListAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(addPaymentMethod.type, handlerErrors(addPaymentMethodAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(deletePaymentMethod.type, handlerErrors(deletePaymentMethodAsync))];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
