var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
import moment from 'moment';
import { routePaths } from '@app/routes/routes';
import { Avatar } from '@core/components/avatar';
import { BaseWrapper } from '@core/components/baseWrapper';
import { ButtonLink } from '@core/components/buttons';
import { PageTitle } from '@core/components/pageTitle';
import Lock from '@core/shared/svg/lock';
import LogOut from '@core/shared/svg/logOut';
import Phone from '@core/shared/svg/phone';
import ManagePlanIcon from '@core/shared/svg/qsure/managePlan';
import User from '@core/shared/svg/user';
import { displayGender } from '@core/shared/utils/patientUtils';
import { checkSubscriptionDetail } from '@core/shared/utils/subscriptionUtils';
import { isReactNativeWebView } from '@core/shared/utils/webViewUtils';
import { getSubscriptionProducts } from '@core/store/reducers/subscriptionSlice';
import styles from './index.module.sass';
export var Settings = function () {
    var nav = useNavigate();
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.profile; }), self = _a.self, subscriptions = _a.subscriptions;
    var subscriptionProducts = useSelector(function (s) { return s.subscriptions; }).subscriptionProducts;
    var gender = displayGender(self === null || self === void 0 ? void 0 : self.gender);
    var age = moment().diff(moment(self === null || self === void 0 ? void 0 : self.birthday, 'YYYY-MM-DD'), 'years');
    var subscriptionDetails = useMemo(function () {
        var currentSubscription = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.find(function (sub) { return sub.status === 'active'; });
        var subProduct = subscriptionProducts.find(function (sub) { return sub._id === (currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.subscription_product_id); });
        if (!subProduct)
            return null;
        return checkSubscriptionDetail({ subscription: subProduct });
    }, [subscriptionProducts, subscriptions]);
    useEffect(function () {
        dispatch(getSubscriptionProducts());
    }, [dispatch]);
    var handleBackClick = function () {
        nav(routePaths.HOME);
    };
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInnerMid: true }, { children: [_jsx(PageTitle, { text: t('title_profile_settings'), hasBackButton: true, onClick: handleBackClick }), _jsxs("div", __assign({ className: styles.content }, { children: [_jsxs("div", __assign({ className: styles.profile }, { children: [_jsxs("div", __assign({ className: styles.profileAvatar }, { children: [_jsx(Avatar, { patient: self, firstName: self === null || self === void 0 ? void 0 : self.firstName, lastName: self === null || self === void 0 ? void 0 : self.lastName, showName: false, needLoadAvatar: true, avatarSize: "large", classNameImage: styles.profileAvatarImg }), _jsxs("div", __assign({ className: styles.profileBottom }, { children: [_jsxs("div", __assign({ className: styles.profileBottomName }, { children: [self === null || self === void 0 ? void 0 : self.firstName, " ", self === null || self === void 0 ? void 0 : self.lastName] })), _jsxs("div", __assign({ className: styles.profileBottomInfo }, { children: [gender, ", ", age] })), _jsxs("div", __assign({ className: styles.profileBottomInfo }, { children: [t('label_mrn'), " ", self.mrn] }))] }))] })), _jsx("div", { className: styles.profileBackground })] })), _jsxs("div", __assign({ className: styles.menu }, { children: [_jsxs(ButtonLink, __assign({ className: styles.menuElementBtn, handleClick: function () {
                                        nav(routePaths.PROFILE_EDIT);
                                    }, showArrow: false }, { children: [_jsx(User, {}), t('label_profile')] })), _jsxs(ButtonLink, __assign({ className: styles.menuElementBtn, handleClick: function () {
                                        nav(routePaths.UPDATE_PASSWORD);
                                    }, showArrow: false }, { children: [_jsx(Lock, {}), t('label_password')] })), _jsxs(ButtonLink, __assign({ className: styles.menuElementBtn, handleClick: function () {
                                        nav(routePaths.CONTACTS);
                                    }, showArrow: false }, { children: [_jsx(Phone, {}), t('label_contacts')] })), _jsxs(ButtonLink, __assign({ className: styles.menuElementBtn, handleClick: function () {
                                        nav(routePaths.MANAGE_PLAN);
                                    }, showArrow: false }, { children: [(subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.icon) ? (subscriptionDetails.icon()) : (_jsx("div", __assign({ className: styles.menuElementBtnPlan }, { children: _jsx(ManagePlanIcon, {}) }))), t('label_manage_plan')] })), !isReactNativeWebView && (_jsxs(ButtonLink, __assign({ className: styles.menuElementBtn, handleClick: function () {
                                        nav(routePaths.LOGOUT);
                                    }, showArrow: false }, { children: [_jsx(LogOut, {}), t('btn_logout')] })))] }))] }))] })) })));
};
