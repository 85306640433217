var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isLoading: false,
    isActionLoading: false,
    paymentMethods: [],
    addPaymentSuccess: false,
    deletePaymentSuccess: false,
    selectedPaymentMethod: null,
};
export var paymentsSlice = createSlice({
    name: 'payments',
    initialState: initialState,
    reducers: {
        getPaymentMethods: function (state, _action) {
            state.isLoading = true;
        },
        getPaymentMethodsWithoutLoading: function (state) {
            state.isLoading = false;
        },
        getPaymentMethodsSuccess: function (state, action) {
            state.paymentMethods = action.payload;
            state.isLoading = false;
        },
        addPaymentMethod: function (state, _action) {
            state.isLoading = true;
        },
        addPaymentMethodSuccess: function (state, _action) {
            state.addPaymentSuccess = true;
            state.isLoading = false;
        },
        deletePaymentMethod: function (state, _action) {
            state.isActionLoading = true;
        },
        deletePaymentMethodSuccess: function (state, _action) {
            state.deletePaymentSuccess = true;
            state.isActionLoading = false;
        },
        resetAddPaymentStatus: function (state) {
            state.addPaymentSuccess = initialState.addPaymentSuccess;
        },
        resetDeletePaymentStatus: function (state) {
            state.deletePaymentSuccess = initialState.deletePaymentSuccess;
        },
        resetLoading: function (state) {
            state.isActionLoading = false;
            state.isLoading = false;
        },
        setSelectedPaymentMethod: function (state, action) {
            state.selectedPaymentMethod = action.payload;
        },
        resetSelectedPaymentMethod: function (state) {
            state.selectedPaymentMethod = initialState.selectedPaymentMethod;
        },
    },
});
export var getPaymentMethods = (_a = paymentsSlice.actions, _a.getPaymentMethods), getPaymentMethodsWithoutLoading = _a.getPaymentMethodsWithoutLoading, getPaymentMethodsSuccess = _a.getPaymentMethodsSuccess, addPaymentMethod = _a.addPaymentMethod, addPaymentMethodSuccess = _a.addPaymentMethodSuccess, deletePaymentMethod = _a.deletePaymentMethod, deletePaymentMethodSuccess = _a.deletePaymentMethodSuccess, resetAddPaymentStatus = _a.resetAddPaymentStatus, resetDeletePaymentStatus = _a.resetDeletePaymentStatus, resetLoading = _a.resetLoading, setSelectedPaymentMethod = _a.setSelectedPaymentMethod, resetSelectedPaymentMethod = _a.resetSelectedPaymentMethod;
export default paymentsSlice.reducer;
