var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from 'axios';
import { t } from 'i18next';
import { call, put, putResolve, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { config } from '@core/config/config';
import bookingService from '@core/services/booking';
import Booking from '@core/services/booking';
import cartService from '@core/services/cart';
import profileService from '@core/services/prfofile';
import shopService from '@core/services/shop';
import surveysService from '@core/services/surveys';
import { OrderItemType, PrescriptionTypes, ProcedureType } from '@core/shared/constants/order';
import { SID } from '@core/shared/models/survey';
import { BookingScreens, } from '@core/shared/types/booking-new';
import { findActiveSubscription } from '@core/shared/utils/subscriptionUtils';
import { setAlert } from '@core/store/reducers/alertSlice';
import { handlerErrors } from '@core/store/sagas/errors';
import { addProcedureToDraft, addProcedureToDraftFail, addProcedureToDraftSuccess, changePatientInDraft, changePatientInDraftFail, changePatientInDraftSuccess, changeSubscription, changeSubscriptionFail, changeSubscriptionSuccess, checkPrebookingIsSubmitted, checkPrebookingIsSubmittedFail, checkPrebookingIsSubmittedSuccess, createDraft, createDraftFail, createDraftSuccess, deleteDraft, deleteDraftFail, deleteDraftSuccess, getDraftById, getDraftByIdFail, getDraftByIdSuccess, getDraftLast, getDraftLastFail, getDraftLastSuccess, getFamilyForBooking, getFamilyForBookingFail, getFamilyForBookingSuccess, getPatientInsurances, getPatientInsurancesFail, getPatientInsurancesSuccess, getProcedures, getProceduresFail, getProceduresSuccess, removeDeclinedProcedures, removeDeclinedProceduresFail, removeDeclinedProceduresSuccess, removeProcedureFromDraft, removeProcedureFromDraftSuccess, replaceDraftProcedures, replaceDraftProceduresFail, replaceDraftProceduresSuccess, saveDraft, saveDraftFail, saveDraftSuccess, setBookingReducer, setCurrentScreen, setOrderPaymentMethod, setOrderPaymentMethodFail, setOrderPaymentMethodSuccess, setPreorderModals, submitOrder, submitOrderFail, submitOrderSuccess, } from '../reducers/bookingNewSlice';
function checkPrebookingIsSubmittedAsync(action) {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(surveysService.createSurveyId, SID.PREBOOKING, {
                    person_id: action.payload,
                })];
            case 1:
                data = (_a.sent()).data;
                if (!((data === null || data === void 0 ? void 0 : data.status) === 'submitted' || (data === null || data === void 0 ? void 0 : data.status) === 'completed')) return [3 /*break*/, 3];
                return [4 /*yield*/, put(checkPrebookingIsSubmittedSuccess())];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, put(checkPrebookingIsSubmittedFail())];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function getFamilyForBookingAsync() {
    var familyRes, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(profileService.getFamily)];
            case 1:
                familyRes = (_a.sent()).data;
                return [4 /*yield*/, put(getFamilyForBookingSuccess(familyRes))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_1 = _a.sent();
                return [4 /*yield*/, put(getFamilyForBookingFail())];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function getProceduresAsync() {
    var data, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(shopService.getProcedures, {
                        procedure_types: [ProcedureType.LAB, ProcedureType.RAPID],
                    })];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(getProceduresSuccess(data.results))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(setCurrentScreen(BookingScreens.ReasonForVisiting))];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_1 = _a.sent();
                put(setAlert(t('error_unknown')));
                put(getProceduresFail());
                throw e_1;
            case 5: return [2 /*return*/];
        }
    });
}
function saveDraftAsync(action) {
    var userId, _a, selectedProcedure, selectedAdditionalProcedures, orderDraft, preparedProcedures, error_2, errorMessage;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 8, , 11]);
                return [4 /*yield*/, select(function (state) { return state.profile.self.id; })];
            case 1:
                userId = _c.sent();
                return [4 /*yield*/, select(function (state) { return state.bookingNew; })];
            case 2:
                _a = _c.sent(), selectedProcedure = _a.selectedProcedure, selectedAdditionalProcedures = _a.selectedAdditionalProcedures;
                return [4 /*yield*/, call(bookingService.createDraft, {
                        patient_person_id: userId,
                        practice_id: config.practiceId,
                    })];
            case 3:
                orderDraft = (_c.sent()).data;
                if (!selectedProcedure) return [3 /*break*/, 5];
                preparedProcedures = selectedProcedure._id === 'otherTests'
                    ? [
                        {
                            type: 'draft_panel',
                        },
                    ]
                    : __spreadArray([selectedProcedure], selectedAdditionalProcedures, true).map(function (procedure) {
                        return {
                            type: 'procedure',
                            procedure_id: procedure._id,
                            price: procedure.price,
                            reasons: [],
                        };
                    });
                return [4 /*yield*/, call(bookingService.updateOrderProcedures, {
                        order_id: orderDraft._id,
                        data: {
                            items: preparedProcedures,
                        },
                    })];
            case 4:
                _c.sent();
                _c.label = 5;
            case 5: return [4 /*yield*/, put(saveDraftSuccess())];
            case 6:
                _c.sent();
                return [4 /*yield*/, call(action.payload)];
            case 7:
                _c.sent();
                return [3 /*break*/, 11];
            case 8:
                error_2 = _c.sent();
                errorMessage = axios.isAxiosError(error_2)
                    ? t((_b = error_2.response.data) === null || _b === void 0 ? void 0 : _b.detail)
                    : t('error_unknown');
                return [4 /*yield*/, put(setAlert(errorMessage))];
            case 9:
                _c.sent();
                return [4 /*yield*/, put(saveDraftFail())];
            case 10:
                _c.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
function getDraftByIdAsync(action) {
    var orderRes, patientRes, items, error_3;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 6, , 8]);
                return [4 /*yield*/, call(bookingService.getOrderById, action.payload.id)];
            case 1:
                orderRes = (_b.sent()).data;
                return [4 /*yield*/, call(profileService.getPersonById, orderRes.patient_person_id)];
            case 2:
                patientRes = (_b.sent()).data;
                items = (_a = orderRes === null || orderRes === void 0 ? void 0 : orderRes.items) === null || _a === void 0 ? void 0 : _a.map(function (e) {
                    if ('procedure' in e) {
                        return __assign(__assign({}, e.procedure), { discount_prices: [
                                {
                                    price: e.discount_price,
                                    subscription_product_id: e.subscription_id,
                                },
                            ] });
                    }
                });
                if (!action.payload.callback) return [3 /*break*/, 4];
                return [4 /*yield*/, call(action.payload.callback, items)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [4 /*yield*/, put(getDraftByIdSuccess({ order: orderRes, patient: patientRes }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 8];
            case 6:
                error_3 = _b.sent();
                return [4 /*yield*/, put(getDraftByIdFail())];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function getDraftLastAsync() {
    var orderSearchRes, lastOrder, order, patient, blockedProcedures, patientRes, orderRes, blockedProceduresRes, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 7, , 9]);
                return [4 /*yield*/, call(bookingService.getEvents, 1, 0)];
            case 1:
                orderSearchRes = (_a.sent()).data;
                lastOrder = orderSearchRes.results[0];
                order = {};
                patient = {};
                blockedProcedures = [];
                if (!(lastOrder.status === 'draft')) return [3 /*break*/, 5];
                return [4 /*yield*/, call(profileService.getPersonById, lastOrder.patient_person_id)];
            case 2:
                patientRes = (_a.sent()).data;
                return [4 /*yield*/, call(bookingService.getOrderById, lastOrder._id)];
            case 3:
                orderRes = (_a.sent()).data;
                return [4 /*yield*/, call(bookingService.getBlockedProcedures, lastOrder.patient_person_id)];
            case 4:
                blockedProceduresRes = (_a.sent()).data;
                order = orderRes;
                patient = patientRes;
                blockedProcedures = blockedProceduresRes;
                _a.label = 5;
            case 5: return [4 /*yield*/, put(getDraftLastSuccess({ order: order, patient: patient, blockedProcedures: blockedProcedures }))];
            case 6:
                _a.sent();
                return [3 /*break*/, 9];
            case 7:
                error_4 = _a.sent();
                return [4 /*yield*/, put(getDraftLastFail())];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function createDraftAsync(action) {
    var userId, subscriptions, activeSubscription_1, cartItems, bookingLocalStore, savedData, orderDraft, procedures, preparedProcedures, error_5, errorMessage;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 17, , 20]);
                return [4 /*yield*/, select(function (state) { return state.profile.self.id; })];
            case 1:
                userId = _b.sent();
                return [4 /*yield*/, select(function (state) { return state.profile.self.active_subscriptions; })];
            case 2:
                subscriptions = _b.sent();
                activeSubscription_1 = findActiveSubscription(subscriptions);
                cartItems = cartService.getItems();
                bookingLocalStore = localStorage.getItem('bookingNew');
                if (!bookingLocalStore) return [3 /*break*/, 5];
                savedData = JSON.parse(bookingLocalStore);
                localStorage.removeItem('bookingNew');
                return [4 /*yield*/, putResolve(setBookingReducer(savedData))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(saveDraftAsync, { payload: function () { }, type: saveDraft.type })];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                if (!cartItems.length) return [3 /*break*/, 12];
                return [4 /*yield*/, call(bookingService.createDraft, {
                        patient_person_id: userId,
                        practice_id: config.practiceId,
                    })];
            case 6:
                orderDraft = (_b.sent()).data;
                return [4 /*yield*/, call(shopService.getProcedures, {
                        procedure_ids: cartItems.map(function (e) { return e._id; }),
                        limit: cartItems.length,
                    })];
            case 7:
                procedures = (_b.sent()).data;
                preparedProcedures = procedures.results.map(function (procedure) {
                    if (procedure.type === ProcedureType.PANEL) {
                        return {
                            type: ProcedureType.PANEL,
                            procedure_id: procedure._id,
                            price: procedure.price,
                            reasons: [],
                            selected_components: procedure.required_components,
                            subscription_id: activeSubscription_1 ? activeSubscription_1 === null || activeSubscription_1 === void 0 ? void 0 : activeSubscription_1._id : null,
                        };
                    }
                });
                return [4 /*yield*/, call(bookingService.managePrescriptionInOrder, orderDraft._id, PrescriptionTypes.AUTO)];
            case 8:
                _b.sent();
                return [4 /*yield*/, call(bookingService.updateOrderProcedures, {
                        order_id: orderDraft._id,
                        data: {
                            items: preparedProcedures,
                        },
                    })];
            case 9:
                _b.sent();
                return [4 /*yield*/, call(getFamilyForBookingAsync)];
            case 10:
                _b.sent();
                return [4 /*yield*/, call(cartService.clearCart)];
            case 11:
                _b.sent();
                _b.label = 12;
            case 12: return [4 /*yield*/, call(getDraftLastAsync)];
            case 13:
                _b.sent();
                return [4 /*yield*/, put(createDraftSuccess())];
            case 14:
                _b.sent();
                if (!action.payload) return [3 /*break*/, 16];
                return [4 /*yield*/, call(action.payload)];
            case 15:
                _b.sent();
                _b.label = 16;
            case 16: return [3 /*break*/, 20];
            case 17:
                error_5 = _b.sent();
                errorMessage = axios.isAxiosError(error_5)
                    ? t((_a = error_5.response.data) === null || _a === void 0 ? void 0 : _a.detail)
                    : t('error_unknown');
                return [4 /*yield*/, put(setAlert(errorMessage))];
            case 18:
                _b.sent();
                return [4 /*yield*/, put(createDraftFail())];
            case 19:
                _b.sent();
                return [3 /*break*/, 20];
            case 20: return [2 /*return*/];
        }
    });
}
function addProcedureToDraftAsync(action) {
    var activeOrder, newProcedures, order, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(function (state) { return state.bookingNew.activeOrder; })];
            case 1:
                activeOrder = _a.sent();
                newProcedures = activeOrder.items.map(function (item) {
                    if (item.type === OrderItemType.PANEL) {
                        return {
                            type: item.type,
                            procedure_id: item.procedure._id,
                            price: item.price,
                            reasons: item.reasons,
                            selected_components: item.selected_components,
                            subscription_id: item.subscription_id,
                        };
                    }
                });
                if (action.payload.type === ProcedureType.PANEL &&
                    !activeOrder.items.find(function (e) { return e.type !== OrderItemType.PANEL; })) {
                    newProcedures.push({
                        type: OrderItemType.PANEL,
                        procedure_id: action.payload._id,
                        price: action.payload.price,
                        reasons: action.payload.reasons,
                        selected_components: action.payload.required_components,
                        subscription_id: null,
                    });
                }
                return [4 /*yield*/, call(bookingService.updateOrderProcedures, {
                        order_id: activeOrder._id,
                        data: {
                            items: newProcedures,
                        },
                    })];
            case 2:
                order = (_a.sent()).data;
                return [4 /*yield*/, put(addProcedureToDraftSuccess(order))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_2 = _a.sent();
                return [4 /*yield*/, put(addProcedureToDraftFail())];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function replaceDraftProceduresAsync(action) {
    var activeOrder, newProcedures, order, error_6, errorMessage;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 7]);
                return [4 /*yield*/, select(function (state) { return state.bookingNew.activeOrder; })];
            case 1:
                activeOrder = _b.sent();
                newProcedures = action.payload.map(function (procedure) {
                    if (procedure.type === ProcedureType.PANEL) {
                        return {
                            type: OrderItemType.PANEL,
                            procedure_id: procedure._id,
                            price: procedure.price,
                            reasons: procedure.reasons,
                            selected_components: procedure.required_components,
                            subscription_id: procedure.subscription_id,
                        };
                    }
                });
                return [4 /*yield*/, call(bookingService.updateOrderProcedures, {
                        order_id: activeOrder._id,
                        data: {
                            items: newProcedures,
                        },
                    })];
            case 2:
                order = (_b.sent()).data;
                return [4 /*yield*/, put(replaceDraftProceduresSuccess(order))];
            case 3:
                _b.sent();
                return [3 /*break*/, 7];
            case 4:
                error_6 = _b.sent();
                errorMessage = axios.isAxiosError(error_6)
                    ? t((_a = error_6.response.data) === null || _a === void 0 ? void 0 : _a.detail)
                    : t('error_unknown');
                return [4 /*yield*/, put(setAlert(errorMessage))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(replaceDraftProceduresFail())];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function removeProcedureFromDraftAsync(action) {
    var activeOrder, newProcedures, _i, _a, item, order;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.bookingNew.activeOrder; })];
            case 1:
                activeOrder = _b.sent();
                newProcedures = [];
                for (_i = 0, _a = activeOrder.items; _i < _a.length; _i++) {
                    item = _a[_i];
                    if (item.type === OrderItemType.PANEL && item.procedure._id !== action.payload) {
                        newProcedures.push({
                            type: item.type,
                            procedure_id: item.procedure._id,
                            price: item.price,
                            reasons: item.reasons,
                            selected_components: item.selected_components,
                            subscription_id: item.subscription_id,
                        });
                    }
                }
                return [4 /*yield*/, call(bookingService.updateOrderProcedures, {
                        order_id: activeOrder._id,
                        data: {
                            items: newProcedures,
                        },
                    })];
            case 2:
                order = (_b.sent()).data;
                return [4 /*yield*/, put(removeProcedureFromDraftSuccess(order))];
            case 3:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function changePatientInDraftAsync(action) {
    var activeOrder, orderRes, patientRes, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, select(function (state) { return state.bookingNew.activeOrder; })];
            case 1:
                activeOrder = _a.sent();
                return [4 /*yield*/, call(bookingService.managePatientInOrder, activeOrder._id, action.payload)];
            case 2:
                orderRes = (_a.sent()).data;
                return [4 /*yield*/, call(profileService.getPersonById, action.payload)];
            case 3:
                patientRes = (_a.sent()).data;
                return [4 /*yield*/, put(changePatientInDraftSuccess({ order: orderRes, patient: patientRes }))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_7 = _a.sent();
                return [4 /*yield*/, put(changePatientInDraftFail())];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function changeSubscriptionAsync(action) {
    var activeOrder, newProcedures, order, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(function (state) { return state.bookingNew.activeOrder; })];
            case 1:
                activeOrder = _a.sent();
                newProcedures = activeOrder.items.map(function (item) {
                    if (item.type === OrderItemType.DRAFT_PANEL) {
                        return {
                            type: item.type,
                        };
                    }
                    if (item.procedure._id === action.payload.procedure_id) {
                        return __assign(__assign({ type: item.type, procedure_id: item.procedure._id, price: item.price, reasons: item.reasons }, {
                            selected_components: item.type === OrderItemType.PANEL && item.selected_components,
                        }), { subscription_id: action.payload.subscription_id || null });
                    }
                    return __assign(__assign({ type: item.type, procedure_id: item.procedure._id, price: item.price, reasons: item.reasons }, { selected_components: item.type === OrderItemType.PANEL && item.selected_components }), { subscription_id: item.subscription_id });
                });
                return [4 /*yield*/, call(bookingService.updateOrderProcedures, {
                        order_id: activeOrder._id,
                        data: {
                            items: newProcedures,
                        },
                    })];
            case 2:
                order = (_a.sent()).data;
                return [4 /*yield*/, put(changeSubscriptionSuccess(order))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                e_3 = _a.sent();
                return [4 /*yield*/, put(changeSubscriptionFail())];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function setPreorderModalsAsync(action) {
    var orderRes, allowedMedicalCodes_1, declinedMedicalCodes_1, allowedProcedures, declinedProcedures, notCheckedProcedures, error_8;
    var _a, _b, _c, _d, _e, _f, _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _h.trys.push([0, 17, , 20]);
                return [4 /*yield*/, call(bookingService.getOrderById, action.payload.order_id)];
            case 1:
                orderRes = (_h.sent()).data;
                if (!(orderRes.prescription.type === 'auto_prescription')) return [3 /*break*/, 15];
                allowedMedicalCodes_1 = ((_b = (_a = orderRes === null || orderRes === void 0 ? void 0 : orderRes.prescription) === null || _a === void 0 ? void 0 : _a.result) === null || _b === void 0 ? void 0 : _b.allowed_medical_codes) || [];
                declinedMedicalCodes_1 = ((_d = (_c = orderRes === null || orderRes === void 0 ? void 0 : orderRes.prescription) === null || _c === void 0 ? void 0 : _c.result) === null || _d === void 0 ? void 0 : _d.declined_medical_codes) || [];
                allowedProcedures = (_e = orderRes.items) === null || _e === void 0 ? void 0 : _e.filter(function (el) {
                    var _a;
                    return el.type === OrderItemType.PANEL &&
                        allowedMedicalCodes_1.includes((_a = el === null || el === void 0 ? void 0 : el.procedure) === null || _a === void 0 ? void 0 : _a.medical_code);
                });
                declinedProcedures = (_f = orderRes.items) === null || _f === void 0 ? void 0 : _f.filter(function (el) {
                    var _a;
                    return el.type === OrderItemType.PANEL &&
                        declinedMedicalCodes_1.includes((_a = el === null || el === void 0 ? void 0 : el.procedure) === null || _a === void 0 ? void 0 : _a.medical_code);
                });
                notCheckedProcedures = (_g = orderRes.items) === null || _g === void 0 ? void 0 : _g.filter(function (el) {
                    var _a, _b;
                    return el.type === OrderItemType.PANEL &&
                        !allowedMedicalCodes_1.includes((_a = el === null || el === void 0 ? void 0 : el.procedure) === null || _a === void 0 ? void 0 : _a.medical_code) &&
                        !declinedMedicalCodes_1.includes((_b = el === null || el === void 0 ? void 0 : el.procedure) === null || _b === void 0 ? void 0 : _b.medical_code);
                });
                if (!notCheckedProcedures.length) return [3 /*break*/, 7];
                if (!(allowedProcedures.length || declinedProcedures.length)) return [3 /*break*/, 3];
                return [4 /*yield*/, call(action.payload.handleUpdateAutoPrescription)];
            case 2:
                _h.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, call(action.payload.handleAddAutoPrescription)];
            case 4:
                _h.sent();
                _h.label = 5;
            case 5: return [4 /*yield*/, put(submitOrderFail())];
            case 6:
                _h.sent();
                return [2 /*return*/];
            case 7:
                if (!(!allowedProcedures.length && !notCheckedProcedures.length && declinedProcedures.length)) return [3 /*break*/, 10];
                return [4 /*yield*/, call(action.payload.handleAllProceduresDeclined)];
            case 8:
                _h.sent();
                return [4 /*yield*/, put(submitOrderFail())];
            case 9:
                _h.sent();
                return [2 /*return*/];
            case 10:
                if (!(!notCheckedProcedures.length && allowedProcedures.length && declinedProcedures.length)) return [3 /*break*/, 13];
                return [4 /*yield*/, call(action.payload.handleSomeProceduresDeclined, {
                        allowedProcedures: allowedProcedures,
                        declinedProcedures: declinedProcedures,
                    })];
            case 11:
                _h.sent();
                return [4 /*yield*/, put(submitOrderFail())];
            case 12:
                _h.sent();
                return [2 /*return*/];
            case 13: return [4 /*yield*/, call(action.payload.handleShowPayAttentionModal, orderRes)];
            case 14:
                _h.sent();
                return [2 /*return*/];
            case 15: return [4 /*yield*/, call(submitOrderAsync, {
                    payload: { order_id: action.payload.order_id, callback: action.payload.callback },
                    type: submitOrder.type,
                })];
            case 16:
                _h.sent();
                return [3 /*break*/, 20];
            case 17:
                error_8 = _h.sent();
                return [4 /*yield*/, put(setAlert('Something went wrong'))];
            case 18:
                _h.sent();
                return [4 /*yield*/, put(submitOrderFail())];
            case 19:
                _h.sent();
                return [3 /*break*/, 20];
            case 20: return [2 /*return*/];
        }
    });
}
function submitOrderAsync(action) {
    var e_4, error, currentError;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _g.trys.push([0, 6, , 9]);
                return [4 /*yield*/, call(bookingService.submitOrder, action.payload.order_id)];
            case 1:
                _g.sent();
                return [4 /*yield*/, put(submitOrderSuccess())];
            case 2:
                _g.sent();
                return [4 /*yield*/, put(setAlert('Your order is succesfully created!'))];
            case 3:
                _g.sent();
                if (!action.payload.callback) return [3 /*break*/, 5];
                return [4 /*yield*/, call(action.payload.callback)];
            case 4:
                _g.sent();
                _g.label = 5;
            case 5: return [3 /*break*/, 9];
            case 6:
                e_4 = _g.sent();
                error = e_4;
                currentError = ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.detail) || 'Something went wrong';
                if (((_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.error) === 'payment_source_error' &&
                    ((_f = (_e = error === null || error === void 0 ? void 0 : error.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.code) === 'expired_card') {
                    currentError = t('error_payment_expired');
                }
                return [4 /*yield*/, put(setAlert(currentError))];
            case 7:
                _g.sent();
                return [4 /*yield*/, put(submitOrderFail())];
            case 8:
                _g.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function removeDeclinedProceduresAsync(action) {
    var activeOrder, declinedIds_1, newProcedures, order, error_9;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 6, , 8]);
                return [4 /*yield*/, select(function (state) { return state.bookingNew.activeOrder; })];
            case 1:
                activeOrder = _b.sent();
                declinedIds_1 = (_a = action.payload.declinedProcedures) === null || _a === void 0 ? void 0 : _a.map(function (p) { return (p.type === OrderItemType.PANEL ? p.procedure._id : null); }).filter(function (p) { return !!p; });
                newProcedures = activeOrder.items
                    .filter(function (p) { var _a; return (p === null || p === void 0 ? void 0 : p.type) === OrderItemType.PANEL && !declinedIds_1.includes((_a = p.procedure) === null || _a === void 0 ? void 0 : _a._id); })
                    .map(function (item) {
                    if (item.type === OrderItemType.PANEL) {
                        return {
                            type: item.type,
                            procedure_id: item.procedure._id,
                            price: item.price,
                            reasons: item.reasons,
                            selected_components: item.selected_components,
                            subscription_id: item.subscription_id,
                        };
                    }
                });
                return [4 /*yield*/, call(bookingService.updateOrderProcedures, {
                        order_id: activeOrder._id,
                        data: {
                            items: newProcedures,
                        },
                    })];
            case 2:
                order = (_b.sent()).data;
                if (!action.payload.callback) return [3 /*break*/, 4];
                return [4 /*yield*/, call(action.payload.callback)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [4 /*yield*/, put(removeDeclinedProceduresSuccess(order))];
            case 5:
                _b.sent();
                return [3 /*break*/, 8];
            case 6:
                error_9 = _b.sent();
                return [4 /*yield*/, put(removeDeclinedProceduresFail())];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function changePaymentInDraftAsync(action) {
    var activeOrder, updatedOrder, error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                return [4 /*yield*/, select(function (state) { return state.bookingNew.activeOrder; })];
            case 1:
                activeOrder = _a.sent();
                return [4 /*yield*/, Booking.managePaymentInOrder(activeOrder._id, action.payload.type, action.payload.param)];
            case 2:
                updatedOrder = (_a.sent()).data;
                return [4 /*yield*/, put(setOrderPaymentMethodSuccess(updatedOrder))];
            case 3:
                _a.sent();
                if (!action.payload.callback) return [3 /*break*/, 5];
                return [4 /*yield*/, call(action.payload.callback)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_10 = _a.sent();
                return [4 /*yield*/, put(setOrderPaymentMethodFail())];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function getInsurancesForPatientAsync(action) {
    var insurances, error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(Booking.getPatientInsurances, action.payload)];
            case 1:
                insurances = (_a.sent()).data;
                return [4 /*yield*/, put(getPatientInsurancesSuccess(insurances))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_11 = _a.sent();
                return [4 /*yield*/, put(getPatientInsurancesFail())];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function deleteDraftAsync(action) {
    var activeOrder, error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                return [4 /*yield*/, select(function (state) { return state.bookingNew.activeOrder; })];
            case 1:
                activeOrder = _a.sent();
                return [4 /*yield*/, call(Booking.deleteOrder, { order_id: activeOrder._id })];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(deleteDraftSuccess())];
            case 3:
                _a.sent();
                if (!action.payload) return [3 /*break*/, 5];
                return [4 /*yield*/, call(action.payload)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_12 = _a.sent();
                return [4 /*yield*/, put(deleteDraftFail())];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function bookingNewWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(getFamilyForBooking, handlerErrors(getFamilyForBookingAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(getProcedures, handlerErrors(getProceduresAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(saveDraft, handlerErrors(saveDraftAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(getDraftLast, handlerErrors(getDraftLastAsync))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(getDraftById.type, handlerErrors(getDraftByIdAsync))];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(createDraft.type, handlerErrors(createDraftAsync))];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(changePatientInDraft.type, handlerErrors(changePatientInDraftAsync))];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(changeSubscription.type, handlerErrors(changeSubscriptionAsync))];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(removeProcedureFromDraft.type, handlerErrors(removeProcedureFromDraftAsync))];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(addProcedureToDraft.type, handlerErrors(addProcedureToDraftAsync))];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeEvery(checkPrebookingIsSubmitted.type, handlerErrors(checkPrebookingIsSubmittedAsync))];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeLatest(setOrderPaymentMethod.type, handlerErrors(changePaymentInDraftAsync))];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeEvery(getPatientInsurances.type, handlerErrors(getInsurancesForPatientAsync))];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeEvery(removeDeclinedProcedures.type, handlerErrors(removeDeclinedProceduresAsync))];
            case 14:
                _a.sent();
                return [4 /*yield*/, takeEvery(setPreorderModals.type, handlerErrors(setPreorderModalsAsync))];
            case 15:
                _a.sent();
                return [4 /*yield*/, takeEvery(submitOrder.type, handlerErrors(submitOrderAsync))];
            case 16:
                _a.sent();
                return [4 /*yield*/, takeEvery(replaceDraftProcedures.type, handlerErrors(replaceDraftProceduresAsync))];
            case 17:
                _a.sent();
                return [4 /*yield*/, takeEvery(deleteDraft.type, handlerErrors(deleteDraftAsync))];
            case 18:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
