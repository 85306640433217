var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var HeartBeat = function () { return (_jsxs("svg", __assign({ width: "60", height: "60", viewBox: "0 0 60 60", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "30", cy: "30", r: "30", fill: "#FFCC37" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M28.796 23.4037C27.2474 21.32 24.5343 20.4685 22.0728 21.2938C19.6113 22.1192 17.9598 24.434 17.9804 27.0301C17.987 27.8585 17.3208 28.5354 16.4923 28.542C15.6639 28.5485 14.9871 27.8823 14.9805 27.0539C14.9496 23.1597 17.4269 19.6875 21.1191 18.4495C24.2394 17.4033 27.6291 18.1533 30.0064 20.2971C32.0302 18.4933 34.7922 17.6755 37.526 18.1476C40.8531 18.7222 43.5787 21.1106 44.5852 24.3334C45.5918 27.5562 44.7098 31.0713 42.3011 33.4372C42.269 33.4686 42.236 33.4983 42.202 33.5263L31.0555 44.5658C30.4709 45.1448 29.5291 45.1448 28.9445 44.5658L24.6005 40.2638C24.0118 39.6809 24.0072 38.7312 24.5902 38.1425C25.1731 37.5539 26.1228 37.5493 26.7115 38.1322L29.9999 41.3889L40.1944 31.2923C40.2255 31.2615 40.2576 31.2323 40.2906 31.2048C41.8269 29.6293 42.3797 27.3348 41.7216 25.2277C41.0506 23.0792 39.2336 21.4869 37.0155 21.1039C34.7975 20.7208 32.5516 21.6114 31.1989 23.4105C30.9146 23.7885 30.4687 24.0103 29.9957 24.009C29.5228 24.0077 29.0781 23.7834 28.796 23.4037Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.5 25.5C26.1456 25.5 26.7189 25.9131 26.923 26.5257L28.0811 30H31.5C32.3284 30 33 30.6716 33 31.5C33 32.3284 32.3284 33 31.5 33H27C26.3544 33 25.7811 32.5869 25.577 31.9743L25.5 31.7434L23.923 36.4743C23.7419 37.0178 23.2669 37.4107 22.6991 37.4867C22.1313 37.5628 21.5697 37.3087 21.2519 36.8321L18.6972 33H16.5C15.6716 33 15 32.3284 15 31.5C15 30.6716 15.6716 30 16.5 30H19.5C20.0015 30 20.4699 30.2507 20.7481 30.6679L22.0468 32.6161L24.077 26.5257C24.2811 25.9131 24.8544 25.5 25.5 25.5Z", fill: "black" })] }))); };
export default HeartBeat;
