var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { t } from 'i18next';
import moment from 'moment';
import CheckMark from '@core/shared/svg/checkMark';
import { displayGender, getNameSurname } from '@core/shared/utils/patientUtils';
import { PatientCardLoader } from './loader';
import { Avatar } from '../avatar';
import styles from './index.module.sass';
export var PatientCard = function (_a) {
    var _b;
    var patient = _a.patient, isActive = _a.isActive, onClick = _a.onClick, classNames = _a.classNames, classNameTitle = _a.classNameTitle, classNameDetails = _a.classNameDetails, isHolder = _a.isHolder, isLoading = _a.isLoading;
    if (((_b = patient._id) === null || _b === void 0 ? void 0 : _b.length) > 0 && !isLoading) {
        var name = getNameSurname(patient.name);
        var gender = displayGender(patient.gender);
        var age = moment().diff(moment(patient.birth_date, 'YYYY-MM-DD'), 'years');
        var handleClick_1 = function (patientId) {
            if (onClick) {
                onClick(patientId);
            }
        };
        return (_jsxs("div", __assign({ className: clsx(styles.container, isActive && styles.containerActive, classNames), onClick: function () { return handleClick_1(patient._id); } }, { children: [_jsx("div", __assign({ className: clsx(styles.title, classNameTitle) }, { children: name })), isHolder && (_jsx("div", __assign({ className: clsx(styles.details, classNameDetails) }, { children: t('label_account_holder') }))), !isHolder && (_jsx("div", __assign({ className: clsx(styles.details, classNameDetails) }, { children: "".concat(gender, ", ").concat(age) }))), _jsxs("div", __assign({ className: styles.bottom }, { children: [_jsx(Avatar, { patient: patient, firstName: patient.name.given[0][0], lastName: patient.name.family[0][0], showName: false, needLoadAvatar: true, avatarSize: "small", classNameImage: styles.bottomAvatar }), isActive && (_jsx("div", __assign({ className: styles.bottomIcon }, { children: _jsx(CheckMark, {}) })))] }))] })));
    }
    return _jsx(PatientCardLoader, {});
};
