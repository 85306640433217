var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { analytics } from '@core/analytics/zipanalytics';
import { ConfirmationPopup } from '@core/components/modals/confirmation';
import { Title } from '@core/components/title';
import ArrowLeft from '@core/shared/svg/arrow-left';
import ArrowRight from '@core/shared/svg/arrow-right';
import Close from '@core/shared/svg/close';
import useWindowDimensions from '@core/shared/utils/widthHook';
import { store } from '@store/store';
import styles from './index.module.sass';
var activeConfirm = null;
var closeActiveConfirm = function () {
    if (activeConfirm !== null) {
        activeConfirm.close();
    }
};
function modalWrapper(WrappedComponent) {
    var Component = function (props) {
        var handleCloseModal = props.handleCloseModal, _a = props.type, type = _a === void 0 ? '' : _a, _b = props.closeIconType, closeIconType = _b === void 0 ? 'close' : _b, _c = props.showCloseIcon, showCloseIcon = _c === void 0 ? true : _c, _d = props.showLeftBackArrow, showLeftBackArrow = _d === void 0 ? false : _d, _e = props.showBackDrop, showBackDrop = _e === void 0 ? true : _e, _f = props.closeBackDrop, closeBackDrop = _f === void 0 ? true : _f, _g = props.confirmClose, confirmClose = _g === void 0 ? false : _g, _h = props.confirmCloseData, confirmCloseData = _h === void 0 ? {
            title: '',
            description: '',
            primaryButtonLabel: '',
            secondaryButtonLabel: '',
            additionalButtonLabel: '',
        } : _h, _j = props.isAnimate, isAnimate = _j === void 0 ? true : _j, _k = props.isAnimateRight, isAnimateRight = _k === void 0 ? false : _k, historyBackCallback = props.historyBackCallback, closeCallback = props.closeCallback;
        var width = useWindowDimensions().width;
        var smallScreen = width < 970;
        var _l = useState(confirmClose), showConfirmPopup = _l[0], setShowConfirmPopup = _l[1];
        var _m = useState(null), confirmationPopup = _m[0], setConfirmationPopup = _m[1];
        var bgRef = useRef();
        var modalRef = useRef();
        useEffect(function () {
            setTimeout(animateOpenModal, 0);
        }, []);
        var animateOpenModal = function () {
            var _a, _b;
            if ((_a = bgRef.current) === null || _a === void 0 ? void 0 : _a.style) {
                bgRef.current.style.opacity = '0.52';
            }
            if ((_b = modalRef.current) === null || _b === void 0 ? void 0 : _b.style) {
                if (isAnimateRight && !smallScreen) {
                    modalRef.current.style.right = '30px';
                    return;
                }
                modalRef.current.style.opacity = '1';
                modalRef.current.style.top = '0';
            }
        };
        var animateCloseModal = function (handler) {
            if (!bgRef.current || !modalRef.current) {
                handler && handler();
                return;
            }
            bgRef.current.style.opacity = '0';
            if (isAnimateRight && !smallScreen) {
                modalRef.current.style.right = '-400px';
            }
            else {
                modalRef.current.style.opacity = '0';
                modalRef.current.style.top = props.isBottomMobile && smallScreen ? '400px' : '-400px';
            }
            setTimeout(function () {
                handler();
            }, 400);
        };
        function showConfirmation(arg0) {
            setConfirmationPopup({
                title: arg0.title,
                description: arg0.description,
                primaryButtonHandler: arg0.primaryButtonHandler,
                primaryButtonLabel: arg0.primaryButtonLabel,
                secondaryButtonHandler: arg0.secondaryButtonHandler,
                secondaryButtonLabel: arg0.secondaryButtonLabel,
                additionalButtonHandler: arg0.additionalButtonHandler,
                additionalButtonLabel: arg0.additionalButtonLabel,
            });
        }
        var closeConfirmation = function () {
            setConfirmationPopup(null);
        };
        var closeModal = function (params) {
            if (params === void 0) { params = { showConfirm: true }; }
            if (closeCallback) {
                animateCloseModal(closeCallback);
            }
            if (showConfirmPopup && params.showConfirm) {
                closeActiveConfirm();
                showConfirmation({
                    title: confirmCloseData.title || t('confirmation_popup_label_close_title'),
                    description: confirmCloseData.description || t('confirmation_popup_label_close_description'),
                    primaryButtonHandler: function () {
                        return typeof confirmCloseData.primaryButtonHandler !== 'undefined'
                            ? confirmCloseData.primaryButtonHandler()
                            : animateCloseModal(handleCloseModal);
                    },
                    primaryButtonLabel: confirmCloseData.primaryButtonLabel || t('btn_close'),
                    secondaryButtonHandler: function () {
                        return typeof confirmCloseData.secondaryButtonHandler !== 'undefined'
                            ? confirmCloseData.secondaryButtonHandler()
                            : closeConfirmation();
                    },
                    secondaryButtonLabel: confirmCloseData.secondaryButtonLabel || t('btn_cancel'),
                    additionalButtonHandler: typeof confirmCloseData.additionalButtonHandler !== 'undefined'
                        ? function () { return confirmCloseData.additionalButtonHandler(); }
                        : undefined,
                    additionalButtonLabel: confirmCloseData.additionalButtonLabel || '',
                });
            }
            else {
                animateCloseModal(handleCloseModal);
            }
        };
        useEffect(function () {
            var handleClose = historyBackCallback || closeModal;
            var closeByEsc = function (event) {
                if (event.key === 'Escape') {
                    closeModal();
                }
            };
            window.addEventListener('popstate', handleClose, false);
            window.addEventListener('keydown', closeByEsc, false);
            return function () {
                window.removeEventListener('popstate', handleClose);
                window.removeEventListener('keydown', closeByEsc);
            };
        }, []);
        var renderConfirmation = function () { return (_jsx(ConfirmationPopup, { closeConfirmation: closeConfirmation, title: confirmationPopup.title, description: confirmationPopup.description, primaryButtonHandler: confirmationPopup.primaryButtonHandler, primaryButtonLabel: confirmationPopup.primaryButtonLabel, secondaryButtonHandler: confirmationPopup.secondaryButtonHandler, secondaryButtonLabel: confirmationPopup.secondaryButtonLabel, additionalButtonHandler: confirmationPopup.additionalButtonHandler, additionalButtonLabel: confirmationPopup.additionalButtonLabel })); };
        var modalTypes = {
            fullPage: (_jsxs("div", __assign({ className: clsx([styles.modal, styles.modalFull]) }, { children: [_jsxs("div", __assign({ className: styles.modalHeader }, { children: [showCloseIcon && (_jsx("div", __assign({ className: styles.modalHeaderClose, onClick: function () { return closeModal(); } }, { children: closeIconType === 'back' ? _jsx(ArrowRight, {}) : _jsx(Close, {}) }))), showLeftBackArrow && (_jsx("div", __assign({ className: styles.modalHeaderCloseArrow, onClick: function () { return closeModal(); } }, { children: _jsx(ArrowLeft, {}) }))), _jsx(Title, { classes: [styles.modalTitle], title: props === null || props === void 0 ? void 0 : props.title, exLink: false })] })), _jsxs("div", __assign({ className: clsx(styles.modalContent, styles.modalContentFull), ref: modalRef }, { children: [_jsx(WrappedComponent, __assign({ closeModal: closeModal, showConfirmation: showConfirmation, closeConfirmation: closeConfirmation, setConfirmationClose: function (value) {
                                    setShowConfirmPopup(value);
                                } }, props)), confirmationPopup && renderConfirmation()] }))] }))),
            clean: (_jsxs("div", __assign({ className: clsx([styles.modal, styles.modalClear]) }, { children: [_jsx(WrappedComponent, __assign({ closeModal: closeModal, showConfirmation: showConfirmation, closeConfirmation: closeConfirmation, setConfirmationClose: function (value) {
                            setShowConfirmPopup(value);
                        } }, props)), confirmationPopup && renderConfirmation()] }))),
            default: (_jsxs("div", __assign({ className: clsx([
                    styles.modal,
                    isAnimateRight && !smallScreen && styles.modalIsRight,
                    props.isBottomMobile && styles.modalMobileBottom,
                    props.isBottomModal && styles.modalBottom,
                ]) }, { children: [_jsx("div", { className: clsx(styles.modalBg, showBackDrop && styles.modalBgTransparent, !isAnimate && styles.animateOff), onClick: function () { return (closeBackDrop ? closeModal() : null); }, ref: bgRef }), _jsxs("div", __assign({ className: clsx(styles.modalContent, props.modalContentClassName, isAnimateRight && !smallScreen && styles.modalContentIsRight, props.isBottomMobile && smallScreen && styles.modalContentIsBottomMobile, !isAnimate && styles.animateOff), ref: modalRef }, { children: [showCloseIcon && (_jsx("div", __assign({ className: styles.modalClose, onClick: function () { return closeModal(); } }, { children: _jsx(Close, {}) }))), showLeftBackArrow && (_jsx("div", __assign({ className: clsx(styles.modalClose, styles.modalCloseArrow), onClick: function () { return closeModal(); } }, { children: _jsx(ArrowLeft, {}) }))), _jsx(WrappedComponent, __assign({ closeModal: closeModal, showConfirmation: showConfirmation, closeConfirmation: closeConfirmation, setConfirmationClose: function (value) {
                                    setShowConfirmPopup(value);
                                } }, props)), confirmationPopup && renderConfirmation()] }))] }))),
        };
        return modalTypes[type] || modalTypes.default;
    };
    return Component;
}
export function showConfirm(_a) {
    var _b = _a === void 0 ? {} : _a, parentRef = _b.parentRef, _c = _b.title, title = _c === void 0 ? t('confirmation_popup_label_close_title') : _c, _d = _b.description, description = _d === void 0 ? t('confirmation_popup_label_close_description') : _d, _e = _b.primaryButton, primaryButton = _e === void 0 ? {
        label: t('btn_close'),
        onClick: function () { },
    } : _e, _f = _b.secondaryButton, secondaryButton = _f === void 0 ? {
        label: t('btn_cancel'),
        onClick: function () { },
    } : _f, _g = _b.onClose, onClose = _g === void 0 ? function () { } : _g;
    if (parentRef === null || parentRef === void 0 ? void 0 : parentRef.current) {
        closeActiveConfirm();
        var confirm_1 = document.createElement('div');
        parentRef.current.appendChild(confirm_1);
        confirm_1.style.position = 'absolute';
        confirm_1.style.top = '0';
        confirm_1.style.left = '0';
        confirm_1.style.bottom = '0';
        confirm_1.style.right = '0';
        var closeConfirm_1 = function () {
            ReactDOM.unmountComponentAtNode(confirm_1);
            confirm_1.remove();
            activeConfirm = null;
        };
        ReactDOM.render(_jsx(ConfirmationPopup, { title: title, description: description, primaryButtonLabel: primaryButton.label, primaryButtonHandler: function () {
                closeConfirm_1();
                primaryButton.onClick();
            }, secondaryButtonLabel: secondaryButton.label, secondaryButtonHandler: function () {
                closeConfirm_1();
                secondaryButton.onClick();
            }, closeConfirmation: function () {
                closeConfirm_1();
                onClose();
            } }), confirm_1);
        activeConfirm = {
            close: closeConfirm_1,
        };
    }
    return { confirm: confirm };
}
export function showModal(Component, props) {
    if (props === void 0) { props = {}; }
    var Modal = modalWrapper(Component);
    document.body.style.height = '100vh';
    document.body.style.overflow = 'hidden';
    document.body.style.touchAction = 'none';
    var modalRoot = document.getElementById('modal-root');
    var modal = document.createElement('div');
    modalRoot.appendChild(modal);
    analytics.track('open-modal', { comp: Component === null || Component === void 0 ? void 0 : Component.name });
    var closed = false;
    function closeModal(callback) {
        document.body.style.height = '';
        document.body.style.overflow = '';
        document.body.style.touchAction = '';
        ReactDOM.unmountComponentAtNode(modal);
        modal.remove();
        closed = true;
        callback && callback();
    }
    var updateStateUnstable = function (_a) {
        var key = _a.key, value = _a.value;
        props[key] = value;
        rerender();
    };
    var rerender = function () {
        ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(BrowserRouter, { children: _jsx(Modal, __assign({ handleCloseModal: closeModal }, props)) }) })) }), modal);
    };
    rerender();
    return {
        modal: modal,
        closeModal: closeModal,
        closed: function () { return closed; },
        updateStateUnstable: updateStateUnstable,
    };
}
