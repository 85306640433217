var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from 'redux-saga/effects';
import servicePrescription from '@core/services/prescription';
import { changeDataKeysCase } from '@core/shared/utils/stringUtils';
import { addPrescription, addPrescriptionFail, addPrescriptionSuccess, removePrescription, removePrescriptionFail, removePrescriptionSuccess, setCurrentIdPrescription, } from '@core/store/reducers/prescription';
import { handlerErrors } from './errors';
import { setAlert } from '../reducers/alertSlice';
export function addPrescriptionAsync(_a) {
    var data, typedData, formData, key, e_1, error, currentError;
    var _b, _c;
    var _d = _a.payload, media = _d.media, patientData = _d.patientData, file = _d.file;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 7, , 10]);
                return [4 /*yield*/, call(servicePrescription.uploadPrescription, patientData.orderId, {
                        personId: patientData.patientId,
                    })];
            case 1:
                data = (_e.sent()).data;
                typedData = changeDataKeysCase(data, 'camelCase');
                formData = new FormData();
                // const imageUrl: string = media.url
                for (key in data.fields) {
                    formData.append(key, data.fields[key]);
                }
                formData.append('file', file);
                if (!(typedData.url && typedData.id)) return [3 /*break*/, 6];
                return [4 /*yield*/, put(setCurrentIdPrescription({ id: typedData.id }))];
            case 2:
                _e.sent();
                return [4 /*yield*/, call(servicePrescription.uploadMediaData, formData, typedData.url)];
            case 3:
                _e.sent();
                return [4 /*yield*/, call(servicePrescription.confirmPrescription, patientData.orderId, typedData.id)];
            case 4:
                _e.sent();
                return [4 /*yield*/, put(addPrescriptionSuccess({
                        media: {
                            record_id: typedData.id,
                            url: media.url,
                            confirmed: true,
                        },
                    }))];
            case 5:
                _e.sent();
                _e.label = 6;
            case 6: return [3 /*break*/, 10];
            case 7:
                e_1 = _e.sent();
                error = e_1;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.detail;
                return [4 /*yield*/, put(setAlert(currentError))];
            case 8:
                _e.sent();
                return [4 /*yield*/, put(addPrescriptionFail())];
            case 9:
                _e.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function removePrescriptionAsync(_a) {
    var e_2;
    var _b = _a.payload, recordId = _b.recordId, patientData = _b.patientData, callback = _b.callback;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(servicePrescription.removePrescription, patientData.orderId, recordId)];
            case 1:
                _c.sent();
                return [4 /*yield*/, put(removePrescriptionSuccess())];
            case 2:
                _c.sent();
                if (!callback) return [3 /*break*/, 4];
                return [4 /*yield*/, callback()];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                e_2 = _c.sent();
                return [4 /*yield*/, put(removePrescriptionFail())];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function prescriptionWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(addPrescription, handlerErrors(addPrescriptionAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(removePrescription, handlerErrors(removePrescriptionAsync))];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
