var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Visa = function () { return (_jsxs("svg", __assign({ width: "40", height: "28", viewBox: "0 0 40 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 6C0 2.68629 2.68629 0 6 0H34C37.3137 0 40 2.68629 40 6V22C40 25.3137 37.3137 28 34 28H6C2.68629 28 0 25.3137 0 22L0 6Z", fill: "white" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.2503 18.6832H9.74054L7.85852 11.5032C7.76919 11.1729 7.57952 10.8809 7.30052 10.7433C6.60424 10.3974 5.837 10.1222 5 9.9834V9.70697H9.04304C9.60104 9.70697 10.0195 10.1222 10.0893 10.6045L11.0658 15.7836L13.5743 9.70697H16.0143L12.2503 18.6832ZM17.4094 18.6832H15.0391L16.9909 9.70697H19.3611L17.4094 18.6832ZM22.4277 12.1937C22.4974 11.7102 22.9159 11.4338 23.4042 11.4338C24.1714 11.3644 25.0072 11.5032 25.7047 11.8479L26.1232 9.91524C25.4257 9.63881 24.6584 9.5 23.9622 9.5C21.6617 9.5 19.9877 10.7433 19.9877 12.4689C19.9877 13.7817 21.1734 14.471 22.0104 14.8862C22.9159 15.3003 23.2647 15.5767 23.1949 15.9907C23.1949 16.6118 22.4974 16.8882 21.8012 16.8882C20.9642 16.8882 20.1272 16.6812 19.3611 16.3354L18.9426 18.2692C19.7796 18.6138 20.6852 18.7526 21.5222 18.7526C24.1017 18.8209 25.7047 17.5787 25.7047 15.7143C25.7047 13.3664 22.4277 13.2288 22.4277 12.1937ZM34 18.6832L32.1179 9.70697H30.0964C29.6779 9.70697 29.2594 9.9834 29.1199 10.3974L25.6349 18.6832H28.0749L28.5619 17.3716H31.5599L31.8389 18.6832H34ZM30.4452 12.1244L31.1415 15.5074H29.1897L30.4452 12.1244Z", fill: "#172B85" })] }))); };
export default Visa;
