var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {};
export var errorsSlice = createSlice({
    name: 'errors',
    initialState: initialState,
    reducers: {
        addError: function (state, action) {
            var _a;
            return __assign(__assign({}, state), (_a = {}, _a[action.payload.type] = action.payload.error, _a));
        },
        removeError: function (state, action) {
            var _a;
            return __assign(__assign({}, state), (_a = {}, _a[action.payload] = '', _a));
        },
        resetStoreErrors: function () { return initialState; },
    },
});
export var addError = (_a = errorsSlice.actions, _a.addError), removeError = _a.removeError, resetStoreErrors = _a.resetStoreErrors;
export default errorsSlice.reducer;
