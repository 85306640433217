var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import _ from 'lodash';
import { OrderStatus } from '@core/shared/constants/order';
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    events: null,
    eventsAmount: null,
    detailEvent: null,
    getEventsStatus: null,
    getEventsAdditionalStatus: null,
    getEventsAmountStatus: null,
    getEventsDetailStatus: null,
    orderCancellationStatus: null,
    orderDeleteStatus: null,
    activePopover: null,
};
export var eventsSlice = createSlice({
    name: 'events',
    initialState: initialState,
    reducers: {
        getEvents: function (state, action) {
            action.payload.additional
                ? (state.getEventsAdditionalStatus = 'loading')
                : (state.getEventsStatus = 'loading');
        },
        getEventsSuccess: function (state) {
            state.getEventsStatus = 'success';
            state.getEventsAdditionalStatus = 'success';
        },
        getEventsError: function (state) {
            state.getEventsStatus = null;
        },
        getEventsAmount: function (state, _action) {
            state.getEventsAmountStatus = 'loading';
        },
        getEventsAmountSuccess: function (state, action) {
            state.eventsAmount = action.payload;
            state.getEventsAmountStatus = 'success';
        },
        getEventsAmountError: function (state) {
            state.getEventsAmountStatus = null;
        },
        getEventDetail: function (state, __) {
            state.getEventsDetailStatus = 'loading';
        },
        getEventDetailSuccess: function (state) {
            state.getEventsDetailStatus = 'success';
        },
        getEventDetailError: function (state) {
            state.getEventsDetailStatus = 'error';
        },
        setEvents: function (state, action) {
            state.events = action.payload;
        },
        setEventsAdditional: function (state, action) {
            action.payload.results.map(function (e) {
                state.events.results.push(e);
            });
        },
        setEventDetail: function (state, action) {
            state.detailEvent = action.payload;
        },
        resetEventDetail: function (state) {
            state.getEventsDetailStatus = null;
            state.detailEvent = null;
        },
        // Order cancellation
        cancelOrder: function (state, _action) {
            state.orderCancellationStatus = initialState.orderCancellationStatus;
            state.orderCancellationStatus = 'loading';
        },
        cancelOrderSuccess: function (state, action) {
            var cancelledOrderId = action.payload;
            state.orderCancellationStatus = 'success';
            state.events.results = state.events.results.map(function (e) {
                if (e._id === cancelledOrderId) {
                    var newEvent = _.cloneDeep(e);
                    newEvent.status = OrderStatus.CANCELED;
                    return newEvent;
                }
                return e;
            });
        },
        cancelOrderError: function (state) {
            state.orderCancellationStatus = initialState.orderCancellationStatus;
        },
        resetOrderCancellationStatus: function (state) {
            state.orderCancellationStatus = initialState.orderCancellationStatus;
        },
        // Delete Order
        deleteOrder: function (state, _action) {
            state.orderCancellationStatus = initialState.orderDeleteStatus;
            state.orderDeleteStatus = 'loading';
        },
        deleteOrderSuccess: function (state, action) {
            var deleteOrderId = action.payload;
            state.orderDeleteStatus = 'success';
            state.events.results = state.events.results.filter(function (e) { return e._id !== deleteOrderId; });
        },
        deleteOrderError: function (state) {
            state.orderDeleteStatus = initialState.orderDeleteStatus;
        },
        resetOrderDeleteStatus: function (state) {
            state.orderDeleteStatus = initialState.orderDeleteStatus;
        },
        // Managing event popovers on the list
        setActivePopover: function (state, action) {
            state.activePopover = action.payload;
        },
        resetEventsState: function () { return initialState; },
        resetLoaders: function (state) { return (__assign(__assign({}, state), { getEventsStatus: null, getEventsAdditionalStatus: null, getEventsAmountStatus: null, getEventsDetailStatus: null })); },
    },
});
export var getEvents = (_a = eventsSlice.actions, _a.getEvents), getEventsSuccess = _a.getEventsSuccess, setEvents = _a.setEvents, setEventsAdditional = _a.setEventsAdditional, getEventsError = _a.getEventsError, getEventsAmount = _a.getEventsAmount, getEventsAmountSuccess = _a.getEventsAmountSuccess, getEventsAmountError = _a.getEventsAmountError, getEventDetail = _a.getEventDetail, getEventDetailSuccess = _a.getEventDetailSuccess, getEventDetailError = _a.getEventDetailError, setEventDetail = _a.setEventDetail, resetEventDetail = _a.resetEventDetail, cancelOrder = _a.cancelOrder, cancelOrderSuccess = _a.cancelOrderSuccess, cancelOrderError = _a.cancelOrderError, resetOrderCancellationStatus = _a.resetOrderCancellationStatus, deleteOrder = _a.deleteOrder, deleteOrderSuccess = _a.deleteOrderSuccess, deleteOrderError = _a.deleteOrderError, resetOrderDeleteStatus = _a.resetOrderDeleteStatus, setActivePopover = _a.setActivePopover, resetEventsState = _a.resetEventsState, resetLoaders = _a.resetLoaders;
export default eventsSlice.reducer;
