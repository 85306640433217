var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { Benefits } from '@components/landing/benifits';
import { Container } from '@components/landing/container';
import { Features } from '@components/landing/features';
import { PageQuestions } from '@components/landing/qustions';
// import { PartnersCard } from '@components/partnersCard'
import { Banner } from '@core/components/landing/banner';
// import { partners } from '@root/src/configs/partners'
import styles from './index.module.sass';
export var Services = function () {
    var location = useLocation();
    useEffect(function () {
        if (location.state === 'toServices') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (!location.state) {
            window.scrollTo(0, 0);
        }
    }, []);
    return (_jsxs(Container, { children: [_jsx("div", __assign({ className: styles.banner }, { children: _jsx(Banner, { size: "big", title: t('label_landing_services_banner_title'), text: t('label_landing_services_banner_text') }) })), _jsx(Benefits, {}), _jsx("div", __assign({ className: styles.features }, { children: _jsx(Features, {}) })), _jsx(PageQuestions, { type: 'service' })] }));
};
