var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { analytics } from '@core/analytics/zipanalytics';
import authService from '@core/services/auth';
import { navigate } from '@core/services/navigator';
import sessionService from '@core/services/session';
import TokenService from '@core/services/tokens';
import { addTry, changePostition, checkRequest, checkSuccess, cloneAccount, logInAppleConfirm, logInGoogleConfirm, logInRequest, logOut, mergeToDeletedAccountFail, mergeToDeletedAccountRequest, mergeToDeletedAccountSuccess, resetStore, signUpRequest, verificationPassRequest, } from '@core/store/reducers/authSlice';
import { addError, removeError } from '@core/store/reducers/errorsSlice';
import { resetEventsState } from '@core/store/reducers/eventsSlice';
import { getInfo, profileReset } from '@core/store/reducers/profileSlice';
import { clearSession } from '@core/store/reducers/session';
import { addCodeInfo } from '@core/store/reducers/verificationSlice';
import { handlerErrors, replaceErrors, STOP_MESSAGE, } from '@core/store/sagas/errors';
import { getDataAsync, getInfoAsync } from '@core/store/sagas/profiles';
export function checkUserAsync(_a) {
    var data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, put(removeError(payload.type))];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(authService.checkAccount, payload.type, payload.identifier)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(checkSuccess({ userId: data.user_id, identifier: payload.identifier }))];
            case 3:
                _b.sent();
                if (!payload.isChangePos) return [3 /*break*/, 5];
                return [4 /*yield*/, put(changePostition("".concat(payload.type, "Password")))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [2 /*return*/];
        }
    });
}
function catchUserAsync(_a) {
    var response, replaceMap;
    var e = _a.e, action = _a.action;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                response = e.response;
                if (!(response.status === 404)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(checkSuccess({ userId: '', identifier: action.payload.identifier }))];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(changePostition("".concat(action.payload.type, "RegistrationPassword")))];
            case 2:
                _b.sent();
                return [4 /*yield*/, STOP_MESSAGE];
            case 3:
                _b.sent();
                return [3 /*break*/, 7];
            case 4:
                replaceMap = [
                    {
                        loc: ['body', 'identifier'],
                        payload: {
                            type: 'phone',
                        },
                        map: '*',
                        replace: 'error_phone_incorrect',
                        action: 'addError',
                    },
                    {
                        loc: ['body', 'identifier'],
                        payload: {
                            type: 'email',
                        },
                        map: '*',
                        replace: 'error_email_incorrect',
                        action: 'addError',
                    },
                ];
                return [4 /*yield*/, replaceErrors(replaceMap)];
            case 5:
                _b.sent();
                return [4 /*yield*/, STOP_MESSAGE];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [2 /*return*/];
        }
    });
}
export function signUpAsync(_a) {
    var data, error_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(authService.signUp, payload.type, payload.identifier, payload.password, payload.verification)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(addCodeInfo({
                        verification_id: data.verification_id,
                        created: data.created,
                        expiration_timeout: data.expiration_timeout,
                        last_sent_time: data.last_sent_time,
                        resend_timeout: data.resend_timeout,
                    }))];
            case 2:
                _b.sent();
                if (!payload.nextPosition) return [3 /*break*/, 4];
                return [4 /*yield*/, put(changePostition(payload.nextPosition))];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_1 = _b.sent();
                return [4 /*yield*/, put(addError({
                        type: 'password',
                        error: 'error_auth_password_requirements',
                    }))];
            case 6:
                _b.sent();
                throw error_1;
            case 7: return [2 /*return*/];
        }
    });
}
export function logInAsync(_a) {
    var data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(authService.signIn, payload.type, payload.identifier, payload.verificationType)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(addCodeInfo({
                        verification_id: data.verification_id,
                        created: data.created,
                        last_sent_time: data.last_sent_time,
                        expiration_timeout: data.expiration_timeout,
                        resend_timeout: data.resend_timeout,
                    }))];
            case 2:
                _b.sent();
                if (!payload.nextPosition) return [3 /*break*/, 4];
                return [4 /*yield*/, put(changePostition(payload.nextPosition))];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
export function logInGoogleConfirmAsync(_a) {
    var error_2, e, error_3, e;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(authService.signInGoogleConfirm, payload.state, payload.code)];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 12, , 15]);
                return [4 /*yield*/, call(getDataAsync)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                _b.trys.push([4, 6, , 9]);
                return [4 /*yield*/, call(getInfoAsync)];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6:
                error_2 = _b.sent();
                e = error_2;
                if (!(e.response.status === 404)) return [3 /*break*/, 8];
                return [4 /*yield*/, call(payload.callback, true)];
            case 7:
                _b.sent();
                return [2 /*return*/];
            case 8: return [3 /*break*/, 9];
            case 9:
                if (!payload.callback) return [3 /*break*/, 11];
                return [4 /*yield*/, call(payload.callback, false)];
            case 10:
                _b.sent();
                _b.label = 11;
            case 11: return [3 /*break*/, 15];
            case 12:
                error_3 = _b.sent();
                e = error_3;
                if (!(e.response.status === 404)) return [3 /*break*/, 14];
                return [4 /*yield*/, call(payload.callback, true)];
            case 13:
                _b.sent();
                return [2 /*return*/];
            case 14: return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
export function logInAppleConfirmAsync(_a) {
    var error_4, e, error_5, e;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(authService.signInAppleConfirm, payload.state, payload.code)];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 12, , 15]);
                return [4 /*yield*/, call(getDataAsync)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                _b.trys.push([4, 6, , 9]);
                return [4 /*yield*/, call(getInfoAsync)];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6:
                error_4 = _b.sent();
                e = error_4;
                if (!(e.response.status === 404)) return [3 /*break*/, 8];
                return [4 /*yield*/, call(payload.callback, true)];
            case 7:
                _b.sent();
                return [2 /*return*/];
            case 8: return [3 /*break*/, 9];
            case 9:
                if (!payload.callback) return [3 /*break*/, 11];
                return [4 /*yield*/, call(payload.callback, false)];
            case 10:
                _b.sent();
                _b.label = 11;
            case 11: return [3 /*break*/, 15];
            case 12:
                error_5 = _b.sent();
                e = error_5;
                if (!(e.response.status === 404)) return [3 /*break*/, 14];
                return [4 /*yield*/, call(payload.callback, true)];
            case 13:
                _b.sent();
                return [2 /*return*/];
            case 14: return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
export function logOutAsync(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(process.env.REACT_APP_TYPE === 'supervisor')) return [3 /*break*/, 3];
                return [4 /*yield*/, call(sessionService.stopSession)];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(clearSession())];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [4 /*yield*/, call(authService.signOut)];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(resetStore())];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(profileReset())];
            case 6:
                _b.sent();
                return [4 /*yield*/, put(resetEventsState())];
            case 7:
                _b.sent();
                analytics.endSession();
                return [4 /*yield*/, navigate(payload || '/')];
            case 8:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function checkLogOutAsync(_a) {
    var response, statuses;
    var e = _a.e;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                response = e.response;
                statuses = [401, 403, 404];
                if (!statuses.includes(response.status)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(resetStore())];
            case 1:
                _b.sent();
                TokenService.clearToken();
                return [4 /*yield*/, navigate('/')];
            case 2:
                _b.sent();
                return [4 /*yield*/, STOP_MESSAGE];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
export function passVerificationAsync(action) {
    var _a, type, code, identifier, callback, data, TokenData, error_6, e, error_7, e;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, type = _a.type, code = _a.code, identifier = _a.identifier, callback = _a.callback;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 14, , 21]);
                return [4 /*yield*/, call(authService.signIn, type, identifier, 'password')];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, call(authService.verification, data.verification_id, code)];
            case 3:
                TokenData = (_b.sent()).data;
                return [4 /*yield*/, TokenService.setToken(TokenData)];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(getDataAsync)];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6:
                _b.trys.push([6, 8, , 11]);
                return [4 /*yield*/, call(getInfoAsync, { payload: function () { }, type: getInfo.type })];
            case 7:
                _b.sent();
                return [3 /*break*/, 11];
            case 8:
                error_6 = _b.sent();
                e = error_6;
                if (!(e.response.status === 404)) return [3 /*break*/, 10];
                return [4 /*yield*/, call(callback, true)];
            case 9:
                _b.sent();
                return [2 /*return*/];
            case 10: return [3 /*break*/, 11];
            case 11:
                if (!callback) return [3 /*break*/, 13];
                return [4 /*yield*/, call(callback, false)];
            case 12:
                _b.sent();
                _b.label = 13;
            case 13: return [3 /*break*/, 21];
            case 14:
                error_7 = _b.sent();
                e = error_7;
                if (!(e.response.data.error === 'max_verification_attempts_error')) return [3 /*break*/, 17];
                return [4 /*yield*/, put(addError({ type: 'password', error: 'error_code_tries' }))];
            case 15:
                _b.sent();
                return [4 /*yield*/, put(addTry('code'))];
            case 16:
                _b.sent();
                return [3 /*break*/, 20];
            case 17: return [4 /*yield*/, put(addError({ type: 'password', error: 'error_password_incorrect' }))];
            case 18:
                _b.sent();
                return [4 /*yield*/, put(addTry('code'))];
            case 19:
                _b.sent();
                _b.label = 20;
            case 20: return [3 /*break*/, 21];
            case 21: return [2 /*return*/];
        }
    });
}
function mergeToDeletedAccountSaga(_a) {
    var e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4 /*yield*/, call(authService.mergeToDeletedAccount, { agreed: payload.agreed })];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(mergeToDeletedAccountSuccess())];
            case 2:
                _b.sent();
                return [4 /*yield*/, call(payload.callback)];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                e_1 = _b.sent();
                return [4 /*yield*/, put(mergeToDeletedAccountFail())];
            case 5:
                _b.sent();
                throw e_1;
            case 6: return [2 /*return*/];
        }
    });
}
function cloneAccountAsync(action) {
    var e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                return [4 /*yield*/, call(authService.clone, action.payload.token)];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(getDataAsync)];
            case 2:
                _a.sent();
                return [4 /*yield*/, call(getInfoAsync)];
            case 3:
                _a.sent();
                if (!action.payload.callback) return [3 /*break*/, 5];
                return [4 /*yield*/, call(action.payload.callback, true)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                e_2 = _a.sent();
                return [4 /*yield*/, call(action.payload.callback, false)];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function authWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(checkRequest().type, handlerErrors(checkUserAsync, catchUserAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(signUpRequest().type, handlerErrors(signUpAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(logInRequest().type, handlerErrors(logInAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(verificationPassRequest().type, handlerErrors(passVerificationAsync))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(logInGoogleConfirm().type, handlerErrors(logInGoogleConfirmAsync))];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(logInAppleConfirm().type, handlerErrors(logInAppleConfirmAsync))];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(logInAppleConfirm().type, handlerErrors(logInAppleConfirmAsync))];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLeading(logOut().type, handlerErrors(logOutAsync, checkLogOutAsync))];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeLeading(mergeToDeletedAccountRequest().type, handlerErrors(mergeToDeletedAccountSaga))];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(cloneAccount().type, handlerErrors(cloneAccountAsync))];
            case 10:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
