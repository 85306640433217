var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import ButtonContact from '@core/components/buttons/buttonContacts';
import Mail from '@core/shared/svg/mail';
import Phone from '@core/shared/svg/phone';
import styles from './index.module.sass';
export var ModalContacts = function (_a) {
    var closeModal = _a.closeModal, handle = _a.handle;
    var chooseType = function (type) {
        closeModal();
        handle(type);
    };
    return (_jsxs("div", __assign({ className: styles.contactsModal }, { children: [_jsx("div", __assign({ className: styles.contactsModalTitle }, { children: t('label_add_contact') })), _jsxs("div", __assign({ className: styles.contactsModalSection }, { children: [_jsxs(ButtonContact, __assign({ className: styles.contactsElement, handleClick: function () { return chooseType('email'); }, add: true }, { children: [_jsx(Mail, {}), t('label_email')] })), _jsxs(ButtonContact, __assign({ className: styles.contactsElement, handleClick: function () { return chooseType('phone'); }, add: true }, { children: [_jsx(Phone, {}), t('label_phone')] }))] }))] })));
};
