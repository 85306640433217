var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import moment from 'moment';
import { Button } from '@core/components/buttons';
import { Select } from '@core/components/formComponents/selects';
import { DATE_FORMAT } from '@core/shared/constants/dateFormat';
import { getDaysArray } from '@core/shared/utils/dateUtils';
import styles from './index.module.sass';
export var ModalDatePicker = function (_a) {
    var handleChangeDate = _a.handleChangeDate, title = _a.title, closeModal = _a.closeModal, range = _a.range, originalValue = _a.originalValue;
    var form = useForm();
    var _b = useState([]), years = _b[0], setYears = _b[1];
    var _c = useState([]), days = _c[0], setDays = _c[1];
    var _d = useState(false), errorMsg = _d[0], setErrorMsg = _d[1];
    var _e = useState(moment(originalValue).month() + 1 || NaN), currMonth = _e[0], setCurrMonth = _e[1];
    var _f = useState(moment(originalValue).date() || NaN), currDay = _f[0], setCurrDay = _f[1];
    var _g = useState(moment(originalValue).year() || NaN), currYear = _g[0], setCurrYear = _g[1];
    var _h = useState(0), scrollPosition = _h[0], setScrollPosition = _h[1];
    useEffect(function () {
        var daysOfMonths = getDaysArray(+currYear || 0, +currMonth || 0).map(function (el) {
            return {
                key: el,
                value: el.toString(),
                textValue: el.toString(),
            };
        });
        if (currDay && currMonth && currYear) {
            form.setValue('Month', currMonth);
            form.setValue('Year', currYear);
            form.setValue('Day', currDay);
        }
        setDays(daysOfMonths);
    }, [currYear, currMonth]);
    useEffect(function () {
        var years = [];
        var start = moment(range.startDate).year();
        var end = moment(range.endDate).year();
        for (var year = end; year >= start; year--) {
            years.push(year);
        }
        setYears(years);
    }, [range.startDate, range.endDate]);
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var check, date;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, form.trigger()];
                case 1:
                    check = _a.sent();
                    if (check) {
                        date = moment("".concat(currYear, "-").concat(+currMonth, "-").concat(currDay), DATE_FORMAT.dash);
                        handleChangeDate(date);
                        return [2 /*return*/, closeModal()];
                    }
                    setErrorMsg(true);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (originalValue.length > 0) {
            return setScrollPosition(moment(originalValue).year());
        }
        if (+range.endDate > +moment() && +range.startDate < +moment()) {
            setScrollPosition(moment().year());
        }
        else if (+range.endDate < +moment()) {
            setScrollPosition(range.endDate.year());
        }
        else if (+range.startDate > +moment()) {
            setScrollPosition(range.startDate.year());
        }
    }, [originalValue]);
    return (_jsxs("div", __assign({ className: styles.datePicker }, { children: [_jsx("div", __assign({ className: styles.datePickerTitleWrapper }, { children: _jsx("div", __assign({ className: styles.datePickerTitle }, { children: title || 'Choose date' })) })), _jsxs("div", __assign({ className: styles.datePickerForm }, { children: [_jsx(Controller, { control: form.control, name: 'Month', rules: {
                            required: {
                                value: true,
                                message: '',
                            },
                        }, render: function (_a) {
                            var onChange = _a.field.onChange, error = _a.fieldState.error;
                            return (_jsx(Select, { placeholder: "Month", active: +currMonth, isTopPlaceholder: true, className: clsx(styles.datePickerSelect, styles.datePickerSelectLeft, error && styles.datePickerSelectError), wrapperClassName: styles.datePickerSelectWrap, items: moment.monthsShort().map(function (el, index) {
                                    return {
                                        key: index + 1,
                                        value: el,
                                        textValue: el,
                                    };
                                }), onChange: function (key) {
                                    form.clearErrors();
                                    setErrorMsg(false);
                                    onChange(key);
                                    setCurrMonth(key);
                                } }, "Month"));
                        } }), _jsx(Controller, { control: form.control, name: 'Day', rules: {
                            required: {
                                value: true,
                                message: '',
                            },
                        }, render: function (_a) {
                            var onChange = _a.field.onChange, error = _a.fieldState.error;
                            return (_jsx(Select, { placeholder: "Day", isTopPlaceholder: true, active: +currDay, className: clsx(styles.datePickerSelect, styles.datePickerSelectMid, error && styles.datePickerSelectError), wrapperClassName: styles.datePickerSelectWrap, items: days, onChange: function (key) {
                                    form.clearErrors();
                                    setErrorMsg(false);
                                    onChange(key);
                                    setCurrDay(key);
                                } }, "Day"));
                        } }), _jsx(Controller, { control: form.control, name: 'Year', rules: {
                            required: {
                                value: true,
                                message: '',
                            },
                        }, render: function (_a) {
                            var onChange = _a.field.onChange, error = _a.fieldState.error;
                            return (_jsx(Select, { scrollPosition: scrollPosition, isTopPlaceholder: true, placeholder: "Year", active: +currYear, className: clsx(styles.datePickerSelect, styles.datePickerSelectRight, error && styles.datePickerSelectError), wrapperClassName: styles.datePickerSelectWrap, items: years.map(function (el) {
                                    return {
                                        key: el,
                                        value: el.toString(),
                                        textValue: el.toString(),
                                    };
                                }), onChange: function (key) {
                                    form.clearErrors();
                                    setErrorMsg(false);
                                    onChange(key);
                                    setCurrYear(key);
                                } }, "Year"));
                        } })] })), errorMsg && _jsx("div", __assign({ className: styles.datePickerError }, { children: "Incorrect Date" })), _jsx(Button, { label: "Confirm", classes: [styles.datePickerBtn], primary: true, handler: onClick })] })));
};
