var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { BaseWrapper } from '@core/components/baseWrapper';
import { PageTitle } from '@core/components/pageTitle';
import { StripePaymentMethods } from '@core/components/paymentMethods';
import { resetStoreErrors } from '@core/store/reducers/errorsSlice';
import { setAlert } from '@root/../core/store/reducers/alertSlice';
import { addPaymentMethod } from '@root/../core/store/reducers/paymentsSlice';
import styles from './index.module.sass';
export var AddPaymentMethod = function () {
    var nav = useNavigate();
    var dispatch = useDispatch();
    var addPaymentSuccess = useSelector(function (state) { return state.payments; }).addPaymentSuccess;
    useEffect(function () {
        return function () {
            dispatch(resetStoreErrors());
        };
    }, [dispatch]);
    useEffect(function () {
        if (addPaymentSuccess) {
            nav(routePaths.PAYMENT);
            dispatch(setAlert(t('alert_payment_method_added')));
        }
    }, [addPaymentSuccess]);
    var handleAdd = function (stripe, card) {
        dispatch(addPaymentMethod({ stripe: stripe, card: card }));
    };
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInnerMid: true }, { children: [_jsx(PageTitle, { text: t('label_add_payment_method'), hasBackButton: true, onClick: function () { return nav(routePaths.PAYMENT); } }), _jsx("div", __assign({ className: styles.AddPayment }, { children: _jsx(StripePaymentMethods, { onSubmit: handleAdd }) }))] })) })));
};
