var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { BaseWrapper } from '@core/components/baseWrapper';
import { Button } from '@core/components/buttons';
import { showModal } from '@core/components/modals';
import Trash from '@core/shared/svg/trash';
import { deletePersonSelf, getData, getSelfType } from '@core/store/reducers/profileSlice';
import DeletedDenied from './components/deletedDenied';
import DeletedVerification from './components/deletedVerification';
import styles from './index.module.sass';
export var DeleteAccount = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return ({
        loading: state.loading,
        profile: state.profile,
    }); }), loading = _a.loading, profile = _a.profile;
    useEffect(function () {
        dispatch(getSelfType());
        dispatch(getData());
    }, []);
    var continueDeleteCallback = function () {
        showModal(DeletedVerification);
    };
    var handleContinueDelete = function () {
        dispatch(deletePersonSelf({ callback: continueDeleteCallback, call: false }));
    };
    var isLoading = loading.type === 'profile/deletePersonSelf' || loading.type === 'profile/getSelfType';
    if (isLoading) {
        return (_jsx("div", __assign({ className: styles.deleteAccount }, { children: _jsx("div", __assign({ className: styles.bubbleLoaderWrapper }, { children: _jsx("div", { className: styles.bubbleLoader }) })) })));
    }
    if (profile.self.isStaff) {
        return _jsx(DeletedDenied, {});
    }
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsx(BaseWrapper, __assign({ whiteWrapperInner: true }, { children: _jsxs("div", __assign({ className: styles.deleteAccount }, { children: [_jsx("div", __assign({ className: styles.trashCircle }, { children: _jsx(Trash, {}) })), _jsx("h2", __assign({ className: styles.title }, { children: t('delete_account_title') })), _jsxs("p", __assign({ className: styles.description }, { children: [t('delete_account_description'), _jsxs("a", __assign({ target: "_blank", href: "https://labq.com/privacy-policy/", rel: "noreferrer" }, { children: [' ', "Privacy Policy."] }))] })), _jsxs("p", __assign({ className: styles.description }, { children: [t('delete_acount_include_phone_description'), _jsx("a", __assign({ href: "tel:+17185345227" }, { children: " (718) 534-5227" })), t('delete_acount_include_support_description'), _jsxs("a", __assign({ target: "_blank", href: "http://labq.com/", rel: "noreferrer" }, { children: [' ', "LabQ.com."] }))] })), _jsx(Button, { disabled: isLoading, loading: isLoading, classes: [styles.button], primary: true, label: t('btn_continue'), handler: handleContinueDelete })] })) })) })));
};
