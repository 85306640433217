var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { t } from 'i18next';
import { config } from '@core/config/config';
import Close from '@core/shared/svg/close';
import LogoMini from '@core/shared/svg/logoMini';
import styles from './index.module.sass';
export var OpenAppButton = function (_a) {
    var appName = _a.appName;
    var _b = useState(false), isShowBtn = _b[0], setIsShowBtn = _b[1];
    var openApp = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newUrl, pathname, search;
        return __generator(this, function (_a) {
            newUrl = new URL(window.location.href);
            pathname = newUrl.pathname ? newUrl.pathname : '/';
            search = newUrl.search ? newUrl.search : '';
            window.open("".concat(config.apiService, "/web/open-app").concat(pathname + search), '_blank', 'noreferrer');
            return [2 /*return*/];
        });
    }); };
    var hideButton = function () {
        setIsShowBtn(false);
        var currentTime = new Date().getTime();
        localStorage.setItem('time_closing_open_app_btn', String(currentTime));
    };
    useEffect(function () {
        var timeClosing = Number(localStorage.getItem('time_closing_open_app_btn'));
        var currentTime = new Date().getTime();
        var timeOut = 24 * 60 * 60;
        var timeToShow = (currentTime - timeClosing) / 1000 >= timeOut;
        if (timeToShow) {
            localStorage.removeItem('time_closing_open_app_btn');
            setIsShowBtn(true);
        }
    }, []);
    return (isAndroid || isIOS) && isMobile && isShowBtn ? (_jsxs("div", __assign({ className: styles.openAppButton }, { children: [_jsxs("div", __assign({ className: styles.buttonLogo }, { children: [_jsx("div", __assign({ onClick: hideButton, className: styles.buttonLogoClose }, { children: _jsx(Close, {}) })), _jsx("div", __assign({ className: styles.buttonLogoIcon }, { children: _jsx(LogoMini, {}) })), _jsxs("div", __assign({ className: styles.buttonLogoText }, { children: [_jsx("span", { children: appName }), _jsx("br", {}), t('open_app_btn_text')] }))] })), _jsx("div", __assign({ onClick: openApp, className: styles.button }, { children: t('btn_open_app') }))] }))) : (_jsx(_Fragment, {}));
};
