var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { t } from 'i18next';
import { COUNTRIES } from '@core/shared/phoneMask/';
export function getPhoneMask(code, placeholderSymbol) {
    var _a, _b, _c, _d;
    var currentCountry = COUNTRIES.find(function (country) { return country.iso2.toLowerCase() === code.toLocaleLowerCase(); });
    var dialCode = '+' + currentCountry.phone.dialCode;
    var shouldValidate = false;
    var mask = placeholderSymbol ? t('placeholder_phone') : '... ... ... ...';
    if ((_b = (_a = currentCountry === null || currentCountry === void 0 ? void 0 : currentCountry.phone) === null || _a === void 0 ? void 0 : _a.mask) === null || _b === void 0 ? void 0 : _b.length) {
        mask = placeholderSymbol
            ? (_d = (_c = currentCountry === null || currentCountry === void 0 ? void 0 : currentCountry.phone) === null || _c === void 0 ? void 0 : _c.mask) === null || _d === void 0 ? void 0 : _d.replace(/\./g, placeholderSymbol)
            : currentCountry.phone.mask;
        shouldValidate = true;
    }
    return {
        value: placeholderSymbol ? mask : "".concat(dialCode, "  ").concat(mask),
        shouldValidate: shouldValidate,
    };
}
export var getTheSameDialCodeCountries = function (value) {
    if (!value)
        return [];
    return COUNTRIES.filter(function (country) {
        return value.startsWith(country.phone.dialCode);
    }).reduce(function (acc, elem) {
        var _a, _b;
        var firstDialCode = (_b = (_a = acc[0]) === null || _a === void 0 ? void 0 : _a.phone) === null || _b === void 0 ? void 0 : _b.dialCode;
        var elemDialCode = elem.phone.dialCode;
        if (!firstDialCode || elemDialCode.length > firstDialCode.length) {
            return [elem];
        }
        if (elemDialCode.length < firstDialCode.length) {
            return acc;
        }
        return __spreadArray(__spreadArray([], acc, true), [elem], false);
    }, []);
};
export var getCountryByAreaCode = function (data, value) {
    return data.find(function (country) {
        if (country.phone.areaCodes.length) {
            return country.phone.areaCodes.find(function (areaCode) {
                return value.replace(country.phone.dialCode, '').startsWith(areaCode);
            });
        }
    });
};
