var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './index.module.sass';
export var NotFound = function () {
    return (_jsxs("div", __assign({ className: styles.error }, { children: [_jsx("span", { children: "404" }), _jsx("h1", { children: "Page not found" })] })));
};
