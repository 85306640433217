var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { MainHeaderButton } from '@core/components/buttons/mainHeaderButton';
import { config } from '@core/config/config';
import TokenService from '@core/services/tokens';
import { QSURE_REDIRECT_KEY } from '@core/shared/constants/common';
import Cross from '@core/shared/svg/cross';
import RisksIcon from '@core/shared/svg/qsure/discount2';
import HomeIcon from '@core/shared/svg/qsure/home';
import { changeAppScreenToMyHealsRecords, isReactNativeWebView, } from '@core/shared/utils/webViewUtils';
import styles from './index.module.sass';
export var NavMobile = function () {
    var _a;
    var nav = useNavigate();
    var location = useLocation();
    var self = useSelector(function (state) { return state.profile; }).self;
    var handleMyRecordsClick = function () {
        if (isReactNativeWebView) {
            changeAppScreenToMyHealsRecords();
            return;
        }
        var token = TokenService.getRefreshToken();
        window.location.href = "".concat(config.href).concat(QSURE_REDIRECT_KEY, "/stories/patients/").concat(self === null || self === void 0 ? void 0 : self.id, "/?refresh_token=").concat(token);
    };
    return (_jsxs("div", __assign({ className: styles.nav }, { children: [_jsxs(MainHeaderButton, __assign({ isActive: location.pathname === routePaths.HOME, onClick: function () { return nav(routePaths.HOME); }, className: clsx(styles.navButton, styles.navButtonHome, location.pathname === routePaths.HOME && styles.navButtonDarkActive) }, { children: [_jsx(HomeIcon, {}), t('title_home')] })), ((_a = self === null || self === void 0 ? void 0 : self.active_subscriptions) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsxs(MainHeaderButton, __assign({ isActive: location.pathname === routePaths.RISKS, onClick: function () { return nav(routePaths.RISKS); }, className: clsx(styles.navButton, styles.navButtonRisks, location.pathname === routePaths.RISKS && styles.navButtonDarkActive) }, { children: [_jsx(RisksIcon, {}), t('title_risks')] }))), _jsxs(MainHeaderButton, __assign({ onClick: handleMyRecordsClick, className: clsx(styles.navButton, styles.navButtonRecords) }, { children: [_jsx(Cross, {}), t('label_health_records')] }))] })));
};
