import { jsx as _jsx } from "react/jsx-runtime";
import Cross from '@core/shared/svg/cross';
import Amex from '@core/shared/svg/payments/big/amex';
import ApplePay from '@core/shared/svg/payments/big/applePay';
import GooglePay from '@core/shared/svg/payments/big/googlePay';
import Maestro from '@core/shared/svg/payments/big/maestro';
import MasterCard from '@core/shared/svg/payments/big/masterCard';
import Paypal from '@core/shared/svg/payments/big/paypal';
import Stripe from '@core/shared/svg/payments/big/stripe';
import Visa from '@core/shared/svg/payments/big/visa';
import AmexSmall from '@core/shared/svg/payments/small/amex';
import ApplePaySmall from '@core/shared/svg/payments/small/applePay';
import GooglePaySmall from '@core/shared/svg/payments/small/googlePay';
import MaestroSmall from '@core/shared/svg/payments/small/maestro';
import MasterCardSmall from '@core/shared/svg/payments/small/masterCard';
import PaypalSmall from '@core/shared/svg/payments/small/paypal';
import StripeSmall from '@core/shared/svg/payments/small/stripe';
import VisaSmall from '@core/shared/svg/payments/small/visa';
import { capitalizeFirstLetter } from '@core/shared/utils/stringUtils';
export var formatCardDate = function (month, year) {
    var part1 = month > 9 ? month : "0".concat(month);
    var part2 = year % 100;
    return "".concat(part1, "/").concat(part2);
};
export var formatCardName = function (brand, last4) {
    var name = capitalizeFirstLetter(brand === null || brand === void 0 ? void 0 : brand.toLowerCase());
    return last4 ? "".concat(name, " \u2022 ").concat(last4) : name;
};
export var checkCanDelete = function (paymentType) {
    var notDeletedTypes = ['applePay', 'googlePlay'];
    return !notDeletedTypes.includes(paymentType);
};
export var cardIconsBig = {
    'american express': _jsx(Amex, {}),
    applepay: _jsx(ApplePay, {}),
    googlepay: _jsx(GooglePay, {}),
    maestro: _jsx(Maestro, {}),
    mastercard: _jsx(MasterCard, {}),
    paypal: _jsx(Paypal, {}),
    stripe: _jsx(Stripe, {}),
    visa: _jsx(Visa, {}),
    empty: _jsx(Cross, {}), // TODO: change to some icon
};
export var cardIconsSmall = {
    'american express': _jsx(AmexSmall, {}),
    applepay: _jsx(ApplePaySmall, {}),
    googlepay: _jsx(GooglePaySmall, {}),
    maestro: _jsx(MaestroSmall, {}),
    mastercard: _jsx(MasterCardSmall, {}),
    paypal: _jsx(PaypalSmall, {}),
    stripe: _jsx(StripeSmall, {}),
    visa: _jsx(VisaSmall, {}),
    empty: _jsx(Cross, {}), // TODO: change to some icon
};
export var getIconByCardBrand = function (brand, size) {
    var cardObj = size === 'big' ? cardIconsBig : cardIconsSmall;
    return cardObj[brand.toLowerCase()] || cardObj.empty;
};
