var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { Button } from '@components/button';
import { BaseWrapper } from '@core/components/baseWrapper';
import { PageTitle } from '@core/components/pageTitle';
import { RiskCard } from '@core/components/riskCard';
import { config } from '@core/config/config';
import TokenService from '@core/services/tokens';
import { QSURE_REDIRECT_KEY } from '@core/shared/constants/common';
import { checkRiskLevel, findLatestRecommendation } from '@core/shared/utils/recommendationUtils';
import { createOrderByMedicalCodesInApp, isReactNativeWebView, } from '@core/shared/utils/webViewUtils';
import useWindowDimensions from '@core/shared/utils/widthHook';
import { getRecommendations } from '@core/store/reducers/profileSlice';
import { NavMobile } from '@layouts/main/components/navMobile';
import { useProceedToLabq } from '@root/src/hooks/useProceedToLabq';
import styles from './index.module.sass';
export var Risks = function () {
    var _a, _b, _c, _d;
    var nav = useNavigate();
    var dispatch = useDispatch();
    var _e = useState(true), isLoading = _e[0], setLoading = _e[1];
    var isMobile = useWindowDimensions().isMobile;
    var recommendations = useSelector(function (state) { return state.profile.recommendations; });
    var profile = useSelector(function (state) { return state.profile; });
    var latestRecommendation = findLatestRecommendation(recommendations);
    var risks = recommendations.length > 0 && __spreadArray(__spreadArray([
        (_a = latestRecommendation === null || latestRecommendation === void 0 ? void 0 : latestRecommendation.recommendations) === null || _a === void 0 ? void 0 : _a.bmi
    ], (((_b = latestRecommendation === null || latestRecommendation === void 0 ? void 0 : latestRecommendation.recommendations) === null || _b === void 0 ? void 0 : _b.condition_based) || []), true), (((_c = latestRecommendation === null || latestRecommendation === void 0 ? void 0 : latestRecommendation.recommendations) === null || _c === void 0 ? void 0 : _c.questionnaire) || []), true);
    useEffect(function () {
        var _a;
        if ((_a = profile === null || profile === void 0 ? void 0 : profile.self) === null || _a === void 0 ? void 0 : _a.id) {
            dispatch(getRecommendations({ id: profile.self.id, callback: function () { return setLoading(false); } }));
        }
    }, [(_d = profile === null || profile === void 0 ? void 0 : profile.self) === null || _d === void 0 ? void 0 : _d.id]);
    var handleProceedToLabq = useProceedToLabq().handleProceedToLabq;
    var collectMedicalCodes = function (risk) {
        var _a;
        var medicalCodes = [];
        ((_a = risk === null || risk === void 0 ? void 0 : risk.recommended_tests) === null || _a === void 0 ? void 0 : _a.length) &&
            risk.recommended_tests.forEach(function (test) {
                var _a;
                if ((_a = test.medical_codes) === null || _a === void 0 ? void 0 : _a.length) {
                    test.medical_codes.forEach(function (code) {
                        medicalCodes.push(code);
                    });
                }
            });
        return medicalCodes;
    };
    var handleRiskClick = function (risk) {
        var medicalCodes = collectMedicalCodes(risk);
        if (isReactNativeWebView) {
            handleProceedToLabq({
                callback: function () {
                    createOrderByMedicalCodesInApp(medicalCodes);
                },
            });
            return;
        }
        var token = TokenService.getRefreshToken();
        var url = "".concat(config.href).concat(QSURE_REDIRECT_KEY, "/cart/new/?refresh_token=").concat(token, "&medical_codes=").concat(medicalCodes.join(','));
        handleProceedToLabq({ url: url });
    };
    var handleStartTest = function () {
        nav(routePaths.HEALTH_RISK_TEST, { state: { redirectPath: routePaths.RISKS } });
    };
    var hasRecommendations = recommendations.length > 0;
    var riskLevel = checkRiskLevel(recommendations, 'overall');
    var assessmentDate = hasRecommendations
        ? (latestRecommendation === null || latestRecommendation === void 0 ? void 0 : latestRecommendation.modified) || (latestRecommendation === null || latestRecommendation === void 0 ? void 0 : latestRecommendation.created)
        : '';
    var formatDate = function (date) {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    };
    if (isLoading) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInnerMid: true }, { children: [_jsx(PageTitle, { text: t('title_my_health_risk_assessment'), hasBackButton: true }), _jsxs("div", __assign({ className: styles.content }, { children: [isMobile && _jsx(NavMobile, {}), _jsxs("div", __assign({ className: styles.risksBanner }, { children: [isMobile && (_jsx("div", __assign({ className: styles.risksBannerTitle }, { children: hasRecommendations
                                        ? t('title_health_risk_assessment')
                                        : t('title_understand_health_risk') }))), _jsx("div", __assign({ className: styles.risksBannerImg }, { children: _jsx("img", { srcSet: "".concat(require("@core/shared/images/qsure/risk/".concat(riskLevel, "Risk@2x.png")), " 2x,\n                ").concat(require("@core/shared/images/qsure/risk/".concat(riskLevel, "Risk@3x.png")), " 3x"), src: require("@core/shared/images/qsure/risk/".concat(riskLevel, "Risk@2x.png")), alt: "risk" }) })), _jsx("div", __assign({ className: styles.risksBannerText }, { children: hasRecommendations
                                        ? t('label_health_risk_assessment', { riskLevel: t("label_".concat(riskLevel)) })
                                        : t('label_health_risk_assessment_unknown') })), _jsxs("div", __assign({ className: styles.risksBannerBottom }, { children: [assessmentDate && (_jsxs("div", __assign({ className: styles.risksBannerBottomText }, { children: [_jsx("span", { children: t('label_assessment_completed') }), _jsx("span", { children: formatDate(assessmentDate) })] }))), !hasRecommendations && !!profile.subscriptions.length && (_jsx(Button, { label: t('label_get_assessment'), classes: [styles.risksBannerBottomBtn], handler: handleStartTest }))] }))] })), (risks === null || risks === void 0 ? void 0 : risks.length) > 0 && (_jsxs("div", __assign({ className: styles.risksManagement }, { children: [_jsx("div", __assign({ className: styles.risksManagementTitle }, { children: t('label_health_risks_management') })), risks.map(function (risk) { return (_jsx(RiskCard, { risk: risk, showDescription: true, showButton: collectMedicalCodes(risk).length > 0, onClick: function () { return handleRiskClick(risk); } }, risk.recommendation)); })] })))] }))] })) })));
};
