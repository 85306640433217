var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import { ChatAgreement } from '@core/components/formComponents/chatAgreement';
import { showModal } from '@core/components/modals';
import CheckMark from '@core/shared/svg/checkMark';
import Doc from '@core/shared/svg/doc';
import Warning from '@core/shared/svg/warning';
import styles from './index.module.sass';
export var RulesMessage = function (_a) {
    var labelLink = _a.labelLink, content = _a.content, approved = _a.approved, handleSetReadDocuments = _a.handleSetReadDocuments, selfId = _a.selfId;
    var replaceAttention = function (text) {
        var separator = '[:attention:]';
        var replacedValue = '[attention]';
        var replacedText = text
            .replace(/\[attention]/g, separator + replacedValue)
            .replace(/\[\/attention]/g, separator)
            .split(separator);
        return replacedText
            .map(function (text, index) {
            if (text === '')
                return null;
            if (text.startsWith(replacedValue)) {
                return (_jsxs("div", __assign({ className: styles.attentionMessage }, { children: [_jsx("div", __assign({ className: styles.attentionMessageIcon }, { children: _jsx(Warning, {}) })), _jsx("p", __assign({ className: styles.attentionMessageText }, { children: text.replace(replacedValue, '') }))] }), index));
            }
            else {
                return (_jsx("p", __assign({ className: styles.innerContent }, { children: text }), index));
            }
        })
            .filter(Boolean);
    };
    var handleClick = function () {
        var closeModal = showModal(ChatAgreement, {
            type: 'fullPage',
            closeIconType: 'back',
            title: labelLink || 'Authorization for Disclosure of COVID-19 Test Results',
            children: (_jsxs("div", { children: [_jsx("div", { children: replaceAttention(content) }), handleSetReadDocuments && !approved && (_jsx(Button, { primary: true, classes: [styles.rulesMessageBtn], label: t('btn_agree'), handler: function () {
                            handleSetReadDocuments(selfId);
                            closeModal();
                        } }))] })),
            isOpen: true,
        }).closeModal;
    };
    var showReadDocument = handleSetReadDocuments && !approved;
    return (_jsxs("div", __assign({ className: styles.chatForm, onClick: handleClick }, { children: [_jsx("div", __assign({ className: clsx(styles.chatFormIcon, approved && styles.chatFormIconApproved) }, { children: approved ? _jsx(CheckMark, {}) : _jsx(Doc, {}) })), _jsxs("div", __assign({ className: styles.chatFormContent }, { children: [_jsx("p", __assign({ className: styles.chatFormLabel }, { children: labelLink })), showReadDocument && (_jsx("p", __assign({ className: styles.chatFormReadDocument }, { children: t('label_read_document') })))] }))] })));
};
