var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var DiamondIcon = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "22", height: "22", viewBox: "0 0 22 22", fill: "none" }, { children: [_jsx("rect", { x: "11", y: "0.0012207", width: "15", height: "15", transform: "rotate(45 11 0.0012207)", fill: "#5C5C5C" }), _jsx("g", __assign({ style: { mixBlendMode: 'overlay' }, opacity: "0.2" }, { children: _jsx("path", { d: "M10.9996 21.2148L0.392988 10.6082H10.9996V21.2148Z", fill: "white" }) })), _jsx("g", __assign({ style: { mixBlendMode: 'overlay' }, opacity: "0.2" }, { children: _jsx("path", { d: "M10.9998 21.2145L9.39315 19.6079L10.9998 10.6079V21.2145Z", fill: "white" }) })), _jsx("g", __assign({ style: { mixBlendMode: 'overlay' }, opacity: "0.2" }, { children: _jsx("path", { d: "M1.99983 12.2146L0.393232 10.608H10.9998L1.99983 12.2146Z", fill: "white" }) })), _jsx("path", { d: "M11.0004 0.00147465L21.607 10.6081H11.0004V0.00147465Z", fill: "white", fillOpacity: "0.2" }), _jsx("path", { d: "M20.2829 9.28152L21.6087 10.6073H11.0021L20.2829 9.28152Z", fill: "white", fillOpacity: "0.4" }), _jsx("path", { d: "M11.0015 0.00130862L12.6081 1.60791L11.0015 10.6079V0.00130862Z", fill: "white", fillOpacity: "0.4" })] }))); };
export default DiamondIcon;
