var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    value: '',
};
export var alertSlice = createSlice({
    name: 'alert',
    initialState: initialState,
    reducers: {
        setAlert: function (state, action) {
            state.value = action.payload;
        },
        clearAlert: function (_state, _action) {
            return initialState;
        },
    },
});
export var setAlert = (_a = alertSlice.actions, _a.setAlert), clearAlert = _a.clearAlert;
export default alertSlice.reducer;
