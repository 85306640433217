var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import clsx from 'clsx';
import { getFamily } from '@core/store/reducers/profileSlice';
import { getSubscriptionProducts } from '@core/store/reducers/subscriptionSlice';
import { Footer } from './components/footer';
import { Header } from './components/header';
import styles from './index.module.sass';
export var Landing = function (_a) {
    var AuthSlice = _a.AuthSlice, MainSlice = _a.MainSlice;
    var selfId = useSelector(function (state) { return state.profile; }).id;
    var _b = useState(false), hideScroll = _b[0], setHideScroll = _b[1];
    var isLoggedIn = Boolean(selfId);
    var dispatch = useDispatch();
    useEffect(function () {
        if (isLoggedIn)
            dispatch(getFamily());
        dispatch(getSubscriptionProducts());
    }, [dispatch, selfId]);
    return (_jsx(AuthSlice, { children: _jsx(MainSlice, { children: _jsxs("div", __assign({ className: clsx(styles.wrapper, hideScroll && styles.wrapperHideScroll) }, { children: [_jsx(Header, {}), _jsx(Outlet, { context: {
                            setHideScroll: setHideScroll,
                        } }), _jsx(Footer, {})] })) }) }));
};
