var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Pen = function () { return (_jsxs("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.1332 5.77921L3.76268 13.1497C3.42779 13.4846 3.23966 13.9388 3.23966 14.4124L3.23966 16.7032L5.53045 16.7032C6.00405 16.7032 6.45825 16.5151 6.79314 16.1802L14.1637 8.80967C14.7773 8.19599 14.7773 7.20101 14.1637 6.58733L13.3555 5.77921C12.7419 5.16553 11.7469 5.16553 11.1332 5.77921ZM4.66823 14.4124C4.66823 14.3177 4.70585 14.2269 4.77283 14.1599L12.1434 6.78936C12.1991 6.73357 12.2896 6.73357 12.3454 6.78936L13.1535 7.59748C13.2093 7.65327 13.2093 7.74373 13.1535 7.79952L5.78298 15.17C5.71601 15.237 5.62517 15.2746 5.53045 15.2746H4.66823L4.66823 14.4124Z", fill: "white" }), _jsx("rect", { x: "12.7495", y: "4.16309", width: "1.42857", height: "4.28571", rx: "0.714286", transform: "rotate(-45 12.7495 4.16309)", fill: "white" })] }))); };
export default Pen;
