var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { t } from 'i18next';
import SurveyHeader from '@core/components/surveyHeader';
import { AccountMergeChecking } from '@core/pages/accountsMerge/checking';
import { ConfirmationCode } from '@core/pages/accountsMerge/steps/confirmationCode';
import { ContactsList } from '@core/pages/accountsMerge/steps/contactsList';
import { getFhirAccounts, getSameAccounts, mergeFhirAccount, mergeSameAccount, resetAccounts, resetCurrentAccount, successAddAccount, } from '@core/store/reducers/accountMergeSlice';
import { resetStoreErrors } from '@core/store/reducers/errorsSlice';
import { getInfo } from '@core/store/reducers/profileSlice';
import { verificationRequest } from '@core/store/reducers/verificationSlice';
import styles from './index.module.sass';
export var AccountMerge = function (_a) {
    var backRoute = _a.backRoute;
    var nav = useNavigate();
    var dispatch = useDispatch();
    var state = useLocation().state;
    var _b = useSelector(function (state) { return state; }), accountMerge = _b.accountMerge, code = _b.code;
    var _c = useState(0), currentIndex = _c[0], setCurrentIndex = _c[1];
    var _d = useState('accounts'), type = _d[0], setType = _d[1];
    var _e = useState(null), amount = _e[0], setAmount = _e[1];
    var _f = useState(null), currContact = _f[0], setCurrContact = _f[1];
    useEffect(function () {
        dispatch(getSameAccounts(state === null || state === void 0 ? void 0 : state.survey));
        dispatch(getFhirAccounts(state === null || state === void 0 ? void 0 : state.survey));
        dispatch(successAddAccount(false));
    }, []);
    useEffect(function () {
        if (accountMerge.sameAccounts && accountMerge.fhirAccounts) {
            setAmount(amount + accountMerge.sameAccounts.length + accountMerge.fhirAccounts.length);
        }
    }, [accountMerge]);
    useEffect(function () {
        if ((state === null || state === void 0 ? void 0 : state.survey) && amount !== null && amount === 0) {
            dispatch(getInfo());
            if (state === null || state === void 0 ? void 0 : state.redirectPath) {
                nav(state === null || state === void 0 ? void 0 : state.redirectPath);
                return;
            }
            nav(backRoute || '/');
            return;
        }
    }, [amount]);
    useEffect(function () {
        window.scrollTo(0, document.body.scrollHeight);
    }, [currentIndex]);
    useEffect(function () {
        if (accountMerge.currentAccount.length) {
            setCurrentIndex(function (currentIndex) { return currentIndex + 1; });
        }
    }, [accountMerge.currentAccount]);
    var handleClose = function () {
        dispatch(resetCurrentAccount());
        dispatch(resetStoreErrors());
        dispatch(resetAccounts());
        dispatch(getInfo());
        if (state === null || state === void 0 ? void 0 : state.redirectPath) {
            nav(state === null || state === void 0 ? void 0 : state.redirectPath);
            return;
        }
        nav(backRoute || '/');
    };
    var handleBack = function () {
        dispatch(resetCurrentAccount());
        setCurrentIndex(0);
    };
    var onContactClick = function (contact, listType) {
        setType(listType);
        setCurrContact(contact);
        if (listType === 'accounts') {
            dispatch(mergeSameAccount({
                account: contact,
            }));
        }
        else {
            dispatch(mergeFhirAccount({
                account: contact,
            }));
        }
    };
    var verificationCallback = function () {
        dispatch(successAddAccount(true));
    };
    var onNext = function (val) {
        dispatch(verificationRequest({
            id: code.verification_id,
            code: val,
            callback: verificationCallback,
        }));
        if (val === 'No') {
            dispatch(resetCurrentAccount());
            return handleClose();
        }
    };
    var steps = [
        _jsx(ContactsList, { contacts: {
                accounts: accountMerge.sameAccounts || [],
                fhir: accountMerge.fhirAccounts || [],
            }, onContactClick: onContactClick, handleBack: handleBack, handleClose: handleClose, isServey: state === null || state === void 0 ? void 0 : state.survey }, 0),
        _jsx(ConfirmationCode, { handleClose: handleClose, onNext: onNext, buttonLoading: false, listType: type, contact: currContact }, 1),
    ];
    if (amount === null) {
        return _jsx(AccountMergeChecking, {});
    }
    return (_jsxs("div", __assign({ className: styles.chat }, { children: [_jsx(SurveyHeader, { handleClose: handleClose, progress: currentIndex && (100 / 3) * currentIndex, title: t('account_merge_title') }), _jsx("div", __assign({ className: styles.steps }, { children: steps.map(function (step, index) {
                    if (index <= currentIndex) {
                        return _jsx(React.Fragment, { children: steps[index] }, index);
                    }
                    return null;
                }) }))] })));
};
