var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Unchecked = function () { return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "36", height: "36", viewBox: "0 0 36 36", fill: "none" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6ZM3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18Z", fill: "black", style: { fillOpacity: 0.2 } }) }))); };
export default Unchecked;
