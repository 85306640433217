var _a;
import { AsyncStatus } from '@core/shared/constants/loaderTypes';
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    subscriptionProducts: [],
    getProductsStat: AsyncStatus.NONE,
    createSubscriptionStat: AsyncStatus.NONE,
    updateSubscriptionStat: AsyncStatus.NONE,
    cancelSubscriptionStat: AsyncStatus.NONE,
    selectedProducts: [],
};
export var subscriptionSlice = createSlice({
    name: 'subscriptions',
    initialState: initialState,
    reducers: {
        getSubscriptionProducts: function (state) {
            state.getProductsStat = AsyncStatus.LOADING;
        },
        getSubscriptionProductsSuccess: function (state, action) {
            state.subscriptionProducts = action.payload;
            state.getProductsStat = AsyncStatus.SUCCESS;
        },
        getSubscriptionProductsFail: function (state) {
            state.getProductsStat = AsyncStatus.FAIL;
        },
        createSubscription: function (state, _action) {
            state.createSubscriptionStat = AsyncStatus.LOADING;
        },
        createSubscriptionSuccess: function (state) {
            state.createSubscriptionStat = AsyncStatus.SUCCESS;
        },
        createSubscriptionFail: function (state) {
            state.createSubscriptionStat = AsyncStatus.FAIL;
        },
        updateSubscription: function (state, _action) {
            state.updateSubscriptionStat = AsyncStatus.LOADING;
        },
        updateSubscriptionSuccess: function (state) {
            state.updateSubscriptionStat = AsyncStatus.SUCCESS;
        },
        updateSubscriptionFail: function (state) {
            state.updateSubscriptionStat = AsyncStatus.FAIL;
        },
        cancelSubscription: function (state, _action) {
            state.cancelSubscriptionStat = AsyncStatus.LOADING;
        },
        cancelSubscriptionSuccess: function (state) {
            state.cancelSubscriptionStat = AsyncStatus.SUCCESS;
        },
        cancelSubscriptionFail: function (state) {
            state.cancelSubscriptionStat = AsyncStatus.FAIL;
        },
        setSelectedProducts: function (state, action) {
            state.selectedProducts = action.payload;
        },
        resetSelectedProducts: function (state) {
            state.selectedProducts = initialState.selectedProducts;
        },
    },
});
export var getSubscriptionProducts = (_a = subscriptionSlice.actions, _a.getSubscriptionProducts), getSubscriptionProductsSuccess = _a.getSubscriptionProductsSuccess, getSubscriptionProductsFail = _a.getSubscriptionProductsFail, createSubscription = _a.createSubscription, createSubscriptionSuccess = _a.createSubscriptionSuccess, createSubscriptionFail = _a.createSubscriptionFail, updateSubscription = _a.updateSubscription, updateSubscriptionSuccess = _a.updateSubscriptionSuccess, updateSubscriptionFail = _a.updateSubscriptionFail, cancelSubscription = _a.cancelSubscription, cancelSubscriptionSuccess = _a.cancelSubscriptionSuccess, cancelSubscriptionFail = _a.cancelSubscriptionFail, setSelectedProducts = _a.setSelectedProducts, resetSelectedProducts = _a.resetSelectedProducts;
export default subscriptionSlice.reducer;
