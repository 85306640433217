var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { t } from 'i18next';
import { call, put, takeEvery } from 'redux-saga/effects';
import sessionService from '@core/services/session';
import { AccessRole, SortPriority } from '@core/shared/models/access';
import { checkSession, clearSession, sessionSlice, setAccessItems, setUser, startSession, switchRole, } from '@core/store/reducers/session';
import { handlerErrors } from '@core/store/sagas/errors';
import { setAlert } from '../reducers/alertSlice';
import { logOut } from '../reducers/authSlice';
var getItemsWithAccess = function (payload) {
    var rolesHasAccess = [
        AccessRole.PRACTICE_MANAGER,
        AccessRole.PRACTICE_SUPERVISOR,
        AccessRole.SUPERVISOR,
        AccessRole.ORGANIZATION_MANAGER,
        AccessRole.NURSING_HOME_MANAGER,
    ];
    var itemsWithAccess = payload.filter(function (el) { return rolesHasAccess.includes(el.role) && el.status === 'active'; });
    return itemsWithAccess.length > 0 ? itemsWithAccess : null;
};
var getSortedList = function (payload) {
    return payload.sort(function (a, b) { return SortPriority[b.role] - SortPriority[a.role]; });
};
export function checkSessionAsync(_a) {
    var data, accessItems, data_1, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(sessionService.getSelfAccesses)];
            case 1:
                data = (_b.sent()).data;
                accessItems = getItemsWithAccess(data);
                if (!accessItems) return [3 /*break*/, 3];
                return [4 /*yield*/, put(setAccessItems(getSortedList(accessItems)))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3: return [4 /*yield*/, put(logOut())];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(setAlert(t('session_failed')))];
            case 5:
                _b.sent();
                return [2 /*return*/];
            case 6:
                _b.trys.push([6, 9, , 12]);
                return [4 /*yield*/, call(sessionService.getCurrentSession)];
            case 7:
                data_1 = (_b.sent()).data;
                return [4 /*yield*/, put(setUser(data_1.access))];
            case 8:
                _b.sent();
                return [3 /*break*/, 12];
            case 9:
                e_1 = _b.sent();
                if (payload.onDone) {
                    payload.onDone();
                }
                if (!(accessItems.length == 1 && accessItems[0].role !== AccessRole.NURSING_HOME_MANAGER)) return [3 /*break*/, 11];
                return [4 /*yield*/, call(startSessionAsync, {
                        payload: { user: accessItems[0] },
                        type: sessionSlice.actions.startSession.type,
                    })];
            case 10:
                _b.sent();
                _b.label = 11;
            case 11: return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function startSessionAsync(_a) {
    var sessionData, data, e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(sessionService.startSession, payload.user.practice_id, payload.user.role, payload.nursing_home_id)];
            case 1:
                sessionData = _b.sent();
                if (!sessionData.data.access_token) return [3 /*break*/, 4];
                return [4 /*yield*/, call(sessionService.getCurrentSession)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(setUser(data.access))];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                if (payload.onDone) {
                    payload.onDone();
                }
                return [3 /*break*/, 7];
            case 5:
                e_2 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function switchRoleAsync(_a) {
    var e_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4 /*yield*/, call(sessionService.stopSession)];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(clearSession)];
            case 2:
                _b.sent();
                return [4 /*yield*/, call(startSessionAsync, {
                        payload: {
                            user: payload.user,
                            nursing_home_id: payload.nursing_home_id,
                            onDone: payload.onDone,
                        },
                        type: sessionSlice.actions.startSession.type,
                    })];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                e_3 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function sessionWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(checkSession, handlerErrors(checkSessionAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(startSession, handlerErrors(startSessionAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(switchRole, handlerErrors(switchRoleAsync))];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
