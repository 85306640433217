var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { t } from 'i18next';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import docsService from '@core/services/docs';
import { navigate } from '@core/services/navigator';
import { setAlert } from '@core/store/reducers/alertSlice';
import { deleteDocument, fetchDocuments, fetchDocumentsByPersonId, reverificationDocument, setCurrentPatient, setDocsLoading, setDocuments, setValidateInsurance, } from '@core/store/reducers/docsSlice';
import { handlerErrors } from '@core/store/sagas/errors';
import { addError } from '../reducers/errorsSlice';
export function fetchDocumentsAsync() {
    var data, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, 7, 9]);
                return [4 /*yield*/, put(setDocsLoading(true))];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(docsService.load)];
            case 2:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(setDocuments(data))];
            case 3:
                _a.sent();
                return [3 /*break*/, 9];
            case 4:
                e_1 = _a.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 5:
                _a.sent();
                return [4 /*yield*/, put(setDocuments([]))];
            case 6:
                _a.sent();
                return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, put(setDocsLoading(false))];
            case 8:
                _a.sent();
                return [7 /*endfinally*/];
            case 9: return [2 /*return*/];
        }
    });
}
export function fetchDocumentsByPersonIdAsync(_a) {
    var data, e_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, 14, 16]);
                return [4 /*yield*/, put(setDocsLoading(true))];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(docsService.getExtendedPatientByPersonId, payload.person_id)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(setDocuments(data === null || data === void 0 ? void 0 : data.documents))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setCurrentPatient(data === null || data === void 0 ? void 0 : data.person))];
            case 4:
                _b.sent();
                if (!payload.callback) return [3 /*break*/, 6];
                return [4 /*yield*/, call(payload.callback)];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 16];
            case 7:
                e_2 = _b.sent();
                error = e_2;
                if (!(error.response.status === 404)) return [3 /*break*/, 9];
                return [4 /*yield*/, put(setAlert(t('error_permission')))];
            case 8:
                _b.sent();
                navigate('/');
                return [3 /*break*/, 11];
            case 9: return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 10:
                _b.sent();
                _b.label = 11;
            case 11: return [4 /*yield*/, put(setDocuments([]))];
            case 12:
                _b.sent();
                return [4 /*yield*/, put(addError({
                        type: 'fetchDocumentsByPersonId',
                        error: 'Failed to load data for selected patient',
                    }))];
            case 13:
                _b.sent();
                return [3 /*break*/, 16];
            case 14: return [4 /*yield*/, put(setDocsLoading(false))];
            case 15:
                _b.sent();
                return [7 /*endfinally*/];
            case 16: return [2 /*return*/];
        }
    });
}
export function deleteDocumentAsync(_a) {
    var _b, documents, currentPatient, e_3;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 7, , 9]);
                return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                _b = (_c.sent()).docs, documents = _b.documents, currentPatient = _b.currentPatient;
                return [4 /*yield*/, call(docsService.deleteByPersonId, currentPatient._id, payload.documentId)];
            case 2:
                _c.sent();
                return [4 /*yield*/, put(setDocuments(documents.filter(function (document) { return document._id !== payload.documentId; })))];
            case 3:
                _c.sent();
                if (!payload.callback) return [3 /*break*/, 5];
                return [4 /*yield*/, call(payload.callback)];
            case 4:
                _c.sent();
                _c.label = 5;
            case 5: return [4 /*yield*/, put(setAlert(t('alert_successful_delete')))];
            case 6:
                _c.sent();
                return [3 /*break*/, 9];
            case 7:
                e_3 = _c.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 8:
                _c.sent();
                throw e_3;
            case 9: return [2 /*return*/];
        }
    });
}
export function reverificationDocumentAsync(_a) {
    var documents, documentId, personId, verifiedDocument_1, newDocuments, _b, e_4;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                documents = (_c.sent()).docs.documents;
                documentId = payload.documentId, personId = payload.personId;
                _c.label = 2;
            case 2:
                _c.trys.push([2, 13, , 15]);
                return [4 /*yield*/, put(setValidateInsurance('loading'))];
            case 3:
                _c.sent();
                return [4 /*yield*/, call(docsService.verify, { documentId: documentId, personId: personId })];
            case 4:
                verifiedDocument_1 = (_c.sent()).data;
                newDocuments = documents.map(function (document) {
                    if (document._id === verifiedDocument_1._id) {
                        return verifiedDocument_1;
                    }
                    return document;
                });
                _b = verifiedDocument_1.verification_status;
                switch (_b) {
                    case 'verified': return [3 /*break*/, 5];
                    case 'invalid': return [3 /*break*/, 7];
                    case 'accepted': return [3 /*break*/, 9];
                }
                return [3 /*break*/, 11];
            case 5: return [4 /*yield*/, put(setValidateInsurance('success'))];
            case 6:
                _c.sent();
                return [3 /*break*/, 11];
            case 7: return [4 /*yield*/, put(setValidateInsurance('failed'))];
            case 8:
                _c.sent();
                return [3 /*break*/, 11];
            case 9: return [4 /*yield*/, put(setValidateInsurance('acceptedError'))];
            case 10:
                _c.sent();
                return [3 /*break*/, 11];
            case 11: return [4 /*yield*/, put(setDocuments(newDocuments))];
            case 12:
                _c.sent();
                return [3 /*break*/, 15];
            case 13:
                e_4 = _c.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 14:
                _c.sent();
                setValidateInsurance('acceptedError');
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
export function docsWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(fetchDocuments, handlerErrors(fetchDocumentsAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(fetchDocumentsByPersonId, handlerErrors(fetchDocumentsByPersonIdAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(deleteDocument, handlerErrors(deleteDocumentAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(reverificationDocument, handlerErrors(reverificationDocumentAsync))];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
