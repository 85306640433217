var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { formatCardName, getIconByCardBrand } from '@core/components/paymentMethodCard/utils';
import CheckMark from '@core/shared/svg/checkMark';
import styles from './index.module.sass';
export var PaymentCard = function (_a) {
    var method = _a.method, isActive = _a.isActive, onClick = _a.onClick, black = _a.black, className = _a.className;
    var name = formatCardName(method === null || method === void 0 ? void 0 : method.type);
    var number = "**** ".concat(method === null || method === void 0 ? void 0 : method.last_four_digits);
    var icon = getIconByCardBrand(method.type, 'big');
    return (_jsxs("div", __assign({ className: clsx(styles.container, isActive && styles.containerActive, isActive && black && styles.containerActiveBlack, className), onClick: onClick }, { children: [_jsx("div", __assign({ className: styles.title }, { children: name })), _jsx("div", __assign({ className: styles.details }, { children: number })), _jsxs("div", __assign({ className: styles.bottom }, { children: [_jsx("div", __assign({ className: styles.bottomIcon }, { children: icon })), isActive && (_jsx("div", __assign({ className: styles.bottomIcon }, { children: _jsx(CheckMark, {}) })))] }))] })));
};
