var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Alert from '@core/components/alert';
import { ProgressLine } from '@core/components/progressLine';
import { ElementWrapper } from '@core/layouts/routeWrapper';
import { NavigatorExtension } from '@core/services/navigator';
import '@core/shared/lang/i18n';
import { layouts } from './routes/routes';
import './index.sass';
export var App = function () {
    return (_jsx(BrowserRouter, __assign({ basename: "/" }, { children: _jsxs("div", __assign({ className: "App" }, { children: [_jsx(ProgressLine, {}), _jsx(Alert, {}), _jsx(NavigatorExtension, {}), _jsx(Routes, { children: Object.keys(layouts).map(function (key) {
                        var _a = layouts[key], layout = _a.layout, routes = _a.routes;
                        return (_jsx(Route, __assign({ element: layout }, { children: routes.map(function (route) { return (_jsx(Route, __assign({}, route, { element: _jsx(ElementWrapper, __assign({ route: route }, { children: route.element })) }), route.path)); }) }), key));
                    }) })] })) })));
};
