var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var TubesCircle = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "60", height: "60", viewBox: "0 0 60 60", fill: "none" }, { children: [_jsx("circle", { cx: "30", cy: "30", r: "30", fill: "#FFCC37" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21 19.5V39C21 39.8284 21.6716 40.5 22.5 40.5C23.3284 40.5 24 39.8284 24 39V19.5H21ZM19.5 16.5C18.6716 16.5 18 17.1716 18 18V39C18 41.4853 20.0147 43.5 22.5 43.5C24.9853 43.5 27 41.4853 27 39V18C27 17.1716 26.3284 16.5 25.5 16.5H19.5Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M36 19.5V39C36 39.8284 36.6716 40.5 37.5 40.5C38.3284 40.5 39 39.8284 39 39V19.5H36ZM34.5 16.5C33.6716 16.5 33 17.1716 33 18V39C33 41.4853 35.0147 43.5 37.5 43.5C39.9853 43.5 42 41.4853 42 39V18C42 17.1716 41.3284 16.5 40.5 16.5H34.5Z", fill: "black" }), _jsx("rect", { x: "34.5", y: "22.5", width: "6", height: "3", fill: "black" }), _jsx("path", { d: "M16.5 18C16.5 17.1716 17.1716 16.5 18 16.5H27C27.8284 16.5 28.5 17.1716 28.5 18C28.5 18.8284 27.8284 19.5 27 19.5H18C17.1716 19.5 16.5 18.8284 16.5 18Z", fill: "black" }), _jsx("path", { d: "M31.5 18C31.5 17.1716 32.1716 16.5 33 16.5H42C42.8284 16.5 43.5 17.1716 43.5 18C43.5 18.8284 42.8284 19.5 42 19.5H33C32.1716 19.5 31.5 18.8284 31.5 18Z", fill: "black" }), _jsx("rect", { x: "19.5", y: "30", width: "6", height: "3", fill: "black" })] }))); };
export default TubesCircle;
