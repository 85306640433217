var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { Avatar } from '@core/components/avatar';
import { MainHeaderButton } from '@core/components/buttons/mainHeaderButton';
import { showModal } from '@core/components/modals';
import { LoginModal } from '@core/components/modals/loginModal';
import { config } from '@core/config/config';
import TokenService from '@core/services/tokens';
import { QSURE_REDIRECT_KEY } from '@core/shared/constants/common';
import ArrowLeft from '@core/shared/svg/arrow-left';
import UserIcon from '@core/shared/svg/user';
import { checkSubscriptionDetail } from '@core/shared/utils/subscriptionUtils';
import { changeAppScreenToHome, changeAppScreenToOrderTest, isReactNativeWebView, } from '@core/shared/utils/webViewUtils';
import { useProceedToLabq } from '@root/src/hooks/useProceedToLabq';
import styles from './index.module.sass';
export var HeaderHome = function () {
    var _a = useSelector(function (state) { return state.profile; }), self = _a.self, subscriptions = _a.subscriptions, selfId = _a.id;
    var subscriptionProducts = useSelector(function (s) { return s.subscriptions; }).subscriptionProducts;
    var nav = useNavigate();
    var location = useLocation();
    var isLoggedIn = Boolean(selfId);
    var name = self === null || self === void 0 ? void 0 : self.firstName;
    var isProfilePage = location.pathname === routePaths.SETTINGS || location.pathname === routePaths.PROFILE_EDIT;
    var handleProceedToLabq = useProceedToLabq().handleProceedToLabq;
    var subscriptionDetails = useMemo(function () {
        var currentSubscription = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.find(function (sub) { return sub.status === 'active'; });
        var subProduct = subscriptionProducts.find(function (sub) { return sub._id === (currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.subscription_product_id); });
        if (!subProduct)
            return null;
        return checkSubscriptionDetail({ subscription: subProduct });
    }, [subscriptionProducts, subscriptions]);
    var handleBackClick = function () {
        handleProceedToLabq({
            callback: function () {
                if (isReactNativeWebView) {
                    changeAppScreenToHome();
                }
                else {
                    nav(-1);
                }
            },
        });
    };
    var handleProfileClick = function () {
        nav(routePaths.SETTINGS);
    };
    var handleBookClick = function () {
        if (isReactNativeWebView) {
            changeAppScreenToOrderTest();
            return;
        }
        var refreshToken = TokenService.getRefreshToken();
        var url = "".concat(config.href).concat(QSURE_REDIRECT_KEY, "/booking/?refresh_token=").concat(refreshToken);
        window.location.href = url;
    };
    var handleLoginClick = function () {
        showModal(LoginModal, {
            isModal: true,
            modalContentClassName: styles.loginModalContent,
            nav: nav,
            showCloseIcon: false,
            showLeftBackArrow: true,
            isAnimateRight: false,
        });
    };
    return (_jsxs("div", __assign({ className: styles.header }, { children: [_jsx("button", __assign({ className: styles.buttonBack, onClick: handleBackClick }, { children: _jsx(ArrowLeft, {}) })), isLoggedIn && (_jsxs("div", __assign({ onClick: handleProfileClick, className: clsx(styles.headerProfile, isProfilePage && styles.headerProfileActive) }, { children: [_jsx(Avatar, { patient: self, firstName: self === null || self === void 0 ? void 0 : self.firstName, lastName: self === null || self === void 0 ? void 0 : self.lastName, showName: false, needLoadAvatar: true, avatarSize: "small", classNameImage: styles.headerProfileAvatar }), subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.icon(), _jsx("div", __assign({ className: styles.headerProfileText }, { children: name }))] }))), !isLoggedIn && (_jsxs(MainHeaderButton, __assign({ isActive: false, onClick: handleLoginClick, className: styles.profileButton }, { children: [_jsx(UserIcon, {}), t('btn_login')] })))] })));
};
