var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { BaseWrapper } from '@core/components/baseWrapper';
import { PatientEditModalLoader } from '@core/components/modals/editPatient/loader/loader';
import { PageTitle } from '@core/components/pageTitle';
import 'react-loading-skeleton/dist/skeleton.css';
export var EditProfileLoader = function () {
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInnerMid: true }, { children: [_jsx(PageTitle, { text: t('label_profile'), hasBackButton: true }), _jsx(PatientEditModalLoader, {})] })) })));
};
