var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var User = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.357 14.7678C8.92413 13.6713 8 11.9436 8 10C8 6.68629 10.6863 4 14 4C17.3137 4 20 6.68629 20 10C20 11.9436 19.0759 13.6713 17.643 14.7678C20.7987 16.1664 23 19.3261 23 23C23 23.5523 22.5523 24 22 24C21.4477 24 21 23.5523 21 23C21 19.134 17.866 16 14 16C10.134 16 7 19.134 7 23C7 23.5523 6.55228 24 6 24C5.44772 24 5 23.5523 5 23C5 19.3261 7.20134 16.1664 10.357 14.7678ZM14 14C16.2091 14 18 12.2091 18 10C18 7.79086 16.2091 6 14 6C11.7909 6 10 7.79086 10 10C10 12.2091 11.7909 14 14 14Z", fill: "black" }) }))); };
export default User;
