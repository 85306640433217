var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { Button } from '@components/button';
import { BaseWrapper } from '@core/components/baseWrapper';
import { PageTitle } from '@core/components/pageTitle';
import { getPaymentMethods } from '@core/store/reducers/paymentsSlice';
import { getFamily } from '@core/store/reducers/profileSlice';
import { getSubscriptionProducts } from '@core/store/reducers/subscriptionSlice';
import { PaymentHistory } from '@pages/settings/managePlan/components/paymentHistory';
import { PaymentMethod } from '@pages/settings/managePlan/components/paymentMethod';
import { PlanSettings } from '@pages/settings/managePlan/components/planSettings';
import styles from './index.module.sass';
export var ManagePlan = function () {
    var dispatch = useDispatch();
    var nav = useNavigate();
    var page = useParams().page;
    useEffect(function () {
        dispatch(getFamily());
        dispatch(getSubscriptionProducts());
        dispatch(getPaymentMethods());
    }, [dispatch]);
    var handlePlanClick = function () {
        nav(routePaths.MANAGE_PLAN);
    };
    // const handlePaymentHistoryClick = () => {
    //   nav(routePaths.MANAGE_PLAN_PAGE.replace(':page', 'payment_history'))
    // }
    var handlePaymentMethodsClick = function () {
        nav(routePaths.MANAGE_PLAN_PAGE.replace(':page', 'payment_method'));
    };
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInnerMid: true }, { children: [_jsx(PageTitle, { text: t('label_manage_plan'), hasBackButton: true }), _jsxs("div", __assign({ className: styles.content }, { children: [_jsxs("div", __assign({ className: styles.buttons }, { children: [_jsx(Button, { handler: handlePlanClick, classes: [styles.button, !page && styles.buttonActive], label: t('label_plan_settings'), withoutArrow: true }), _jsx(Button, { handler: handlePaymentMethodsClick, classes: [styles.button, page === 'payment_method' && styles.buttonActive], label: t('label_payment_method'), withoutArrow: true })] })), _jsxs("div", __assign({ className: styles.contentSection }, { children: [!page && _jsx(PlanSettings, {}), page === 'payment_method' && _jsx(PaymentMethod, {}), page === 'payment_history' && _jsx(PaymentHistory, {})] }))] }))] })) })));
};
