var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { Button } from '@core/components/buttons';
import Close from '@core/shared/svg/close';
import styles from './index.module.sass';
var ConfirmValues = function (_a) {
    var title = _a.title, values = _a.values, closeModal = _a.closeModal, handleConfirm = _a.handleConfirm, _b = _a.innerCloseIcon, innerCloseIcon = _b === void 0 ? true : _b;
    var prepareToShow = function (value) {
        if (typeof value === 'string') {
            return value;
        }
        if (typeof value === 'object') {
            return value.name;
        }
    };
    return (_jsxs("div", __assign({ className: styles.confirmValues }, { children: [innerCloseIcon && (_jsx("div", __assign({ className: styles.modalClose, onClick: function () { return closeModal(); } }, { children: _jsx(Close, {}) }))), _jsx("h1", __assign({ className: styles.confirmValuesTitle }, { children: title })), _jsx("div", __assign({ className: styles.confirmValuesList }, { children: values.map(function (item) { return (_jsxs("div", __assign({ className: clsx(styles.confirmValue, item.oldValue && styles.confirmValueWithRepl) }, { children: [_jsx("p", __assign({ className: styles.confirmValueLabel }, { children: item.label })), _jsxs("div", { children: [_jsx("p", __assign({ className: styles.confirmValueValue }, { children: prepareToShow(item.value) })), !!item.oldValue && (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: styles.confirmValueReplaced }, { children: "Replaced automatically" })), _jsxs("p", __assign({ className: styles.confirmValueReplaced }, { children: [prepareToShow(item.oldValue), " ", '>', " ", prepareToShow(item.value)] }))] }))] })] }), item.slot_id)); }) })), _jsxs("div", __assign({ className: styles.confirmValuesButtons }, { children: [_jsx(Button, { primary: true, handler: handleConfirm, label: 'Confirm' }), _jsx(Button, { handler: closeModal, label: 'Change' })] }))] })));
};
export default ConfirmValues;
