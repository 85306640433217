var _a;
import { COUNTRIES } from '@core/shared/phoneMask';
import { getPhoneMask } from '@core/shared/utils/phoneUtils';
import { createSlice } from '@reduxjs/toolkit';
var activeRegion = COUNTRIES.find(function (el) { return el.iso2 === localStorage.getItem('region'); }) ||
    COUNTRIES.find(function (el) { return el.iso2 === 'us'; });
var initialState = {
    activeRegion: {
        dialCode: activeRegion.phone.dialCode,
        img: require("@core/shared/images/flags/".concat(activeRegion.iso2, ".svg")),
        inputMask: getPhoneMask(activeRegion.iso2),
        code: activeRegion.iso2,
    },
    isHeaderHidden: false,
};
export var helpersSlice = createSlice({
    name: 'helpers',
    initialState: initialState,
    reducers: {
        setRegion: function (state, action) {
            localStorage.setItem('region', action.payload.code);
            state.activeRegion = action.payload;
        },
        setHeaderHidden: function (state, action) {
            state.isHeaderHidden = action.payload;
        },
    },
});
export var setRegion = (_a = helpersSlice.actions, _a.setRegion), setHeaderHidden = _a.setHeaderHidden;
export default helpersSlice.reducer;
