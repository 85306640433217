var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from 'redux-saga/effects';
import AccountMergeService from '@core/services/accountMerge';
import { handlerErrors } from './errors';
import { getFhirAccounts, getSameAccounts, mergeFhirAccount, mergeSameAccount, setCurrentAccount, setCurrentAccountType, setFhirAccounts, setSameAccounts, } from '../reducers/accountMergeSlice';
import { setAlert } from '../reducers/alertSlice';
import { addCodeInfo } from '../reducers/verificationSlice';
var during = function (timeout) {
    return new Promise(function (res) {
        setTimeout(function () {
            res('200');
        }, timeout);
    });
};
export function getSameAccountsAsync(_a) {
    var data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!payload) return [3 /*break*/, 2];
                return [4 /*yield*/, call(during, 3000)];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2: return [4 /*yield*/, call(AccountMergeService.getSame)];
            case 3:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(setSameAccounts(data))];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function getFhirAccountsAsync(_a) {
    var data, error_1, exception;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!payload) return [3 /*break*/, 2];
                return [4 /*yield*/, call(during, 3000)];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 5, , 8]);
                return [4 /*yield*/, call(AccountMergeService.getFhir)];
            case 3:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(setFhirAccounts(data))];
            case 4:
                _b.sent();
                return [3 /*break*/, 8];
            case 5:
                error_1 = _b.sent();
                exception = error_1;
                if (!exception.response.status) return [3 /*break*/, 7];
                return [4 /*yield*/, put(setFhirAccounts([]))];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function mergeSameAccountAsync(_a) {
    var data, error_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 8]);
                return [4 /*yield*/, call(AccountMergeService.mergePerson, payload.account.id, payload.account.user_id, payload.verification)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(addCodeInfo({
                        verification_id: data.verification_id,
                        created: data.created,
                        last_sent_time: data.last_sent_time,
                        expiration_timeout: data.expiration_timeout,
                        resend_timeout: data.resend_timeout,
                    }))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(setCurrentAccount(payload.account.identifier))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setCurrentAccountType(payload.account.type))];
            case 4:
                _b.sent();
                if (!payload.callback) return [3 /*break*/, 6];
                return [4 /*yield*/, call(payload.callback)];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 8];
            case 7:
                error_2 = _b.sent();
                setAlert('Something Wrong!');
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function mergeFhirAccountAsync(_a) {
    var data, error_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 8]);
                return [4 /*yield*/, call(AccountMergeService.verifyFhir, payload.account.hash, payload.verification)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(addCodeInfo({
                        verification_id: data.verification_id,
                        created: data.created,
                        last_sent_time: data.last_sent_time,
                        expiration_timeout: data.expiration_timeout,
                        resend_timeout: data.resend_timeout,
                    }))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(setCurrentAccount(payload.account.identifier))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setCurrentAccountType(payload.account.type))];
            case 4:
                _b.sent();
                if (!payload.callback) return [3 /*break*/, 6];
                return [4 /*yield*/, call(payload.callback)];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 8];
            case 7:
                error_3 = _b.sent();
                setAlert('Something Wrong!');
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function accountMergeWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(getSameAccounts().type, handlerErrors(getSameAccountsAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(getFhirAccounts().type, handlerErrors(getFhirAccountsAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(mergeSameAccount().type, handlerErrors(mergeSameAccountAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(mergeFhirAccount().type, handlerErrors(mergeFhirAccountAsync))];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
