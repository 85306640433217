var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { camelCase } from 'lodash';
import { Avatar } from '@core/components/avatar';
import { Button } from '@core/components/buttons/button';
import { DatePicker } from '@core/components/formComponents/datePicker';
import { Input } from '@core/components/formComponents/input';
import { InputFile } from '@core/components/formComponents/InputFile';
import { ItemsPicker } from '@core/components/formComponents/itemsPicker';
import { Select } from '@core/components/formComponents/selects';
import { showModal } from '@core/components/modals';
import { ImageCropper } from '@core/components/modals/imageCropper';
import { SimpleConfirmPopup } from '@core/components/modals/simpleConfirmPopup';
import { LABQ_CONTACTS, QSURE_CONTACTS } from '@core/shared/constants/contactInfo';
import { AsyncStatus } from '@core/shared/constants/loaderTypes';
import { getEthnicOriginTranslation, getGenderIdentityTranslation, getRaceTranslation, getSexualOrientationTranslation, } from '@core/shared/constants/profile';
import useRerender from '@core/shared/hooks/useRerender';
import { getImgSRC } from '@core/shared/utils/imageUtils';
import { checkIsHolder, formProfileSelectData } from '@core/shared/utils/profileUtils';
import { removeError } from '@core/store/reducers/errorsSlice';
import { getFamily, removeAvatar, resetStatus, setSelectedPerson, uploadAvatar, } from '@core/store/reducers/profileSlice';
import { AvatarLoader } from './loader/avatarLoader';
import styles from './index.module.sass';
export var PatientEditModal = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var patientId = _a.patientId, onUpdate = _a.onUpdate, onDelete = _a.onDelete, isProfileLocked = _a.isProfileLocked, canDelete = _a.canDelete, isLoading = _a.isLoading, _m = _a.isLoadingEditUpdateFn, isLoadingEditUpdateFn = _m === void 0 ? function () { return false; } : _m, _o = _a.isLoadingDeleteUpdateFn, isLoadingDeleteUpdateFn = _o === void 0 ? function () { return false; } : _o, _p = _a.titleText, titleText = _p === void 0 ? t('label_edit_patient_modal') : _p, _q = _a.deleteBtnText, deleteBtnText = _q === void 0 ? t('btn_account_delete') : _q, _r = _a.isModal, isModal = _r === void 0 ? false : _r, _s = _a.appType, appType = _s === void 0 ? 'labq' : _s, _t = _a.showDeleteAccountBtn, showDeleteAccountBtn = _t === void 0 ? true : _t;
    var dispatch = useDispatch();
    var contactsData = appType === 'labq' ? LABQ_CONTACTS : QSURE_CONTACTS;
    var SUPPORT_PHONE = contactsData.phone;
    var SUPPORT_DISPLAY_PHONE = contactsData.displayPhone;
    var SUPPORT_SITE = contactsData.site;
    var SUPPORT_DISPLAY_SITE = contactsData.displaySite;
    var backendError = useSelector(function (state) { return state.errors; });
    var _u = useSelector(function (state) { return state.profile; }), selectedPerson = _u.selectedPerson, family = _u.family, statuses = _u.statuses;
    var RACE = getRaceTranslation();
    var GENDER_IDENTITY = getGenderIdentityTranslation();
    var SEXUAL_ORIENTATION = getSexualOrientationTranslation();
    var ETHNIC_ORIGIN = getEthnicOriginTranslation();
    var rerender = useRerender();
    var _v = useState(selectedPerson), patient = _v[0], setPatient = _v[1];
    var _w = useState(''), raceError = _w[0], setRaceError = _w[1];
    var _x = useState(''), ethnicOriginError = _x[0], setEthnicOriginError = _x[1];
    var _y = useState(patient ? formProfileSelectData(patient, RACE, 'race') : []), race = _y[0], setRace = _y[1];
    var _z = useState(patient ? formProfileSelectData(patient, ETHNIC_ORIGIN, 'ethnic_origin') : []), ethnicOrigin = _z[0], setEthnicOrigin = _z[1];
    var _0 = useState(''), mediaUrl = _0[0], setMediaUrl = _0[1];
    var _1 = useState(null), mediaFile = _1[0], setMediaFile = _1[1];
    var _2 = useState(!!((_b = selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.avatars) === null || _b === void 0 ? void 0 : _b.find(function (a) { return a.confirmed; }))), hasAvatar = _2[0], setHasAvatar = _2[1];
    var _3 = useState(false), needUpdatePhoto = _3[0], setNeedUpdatePhoto = _3[1];
    var _4 = useState(false), isAvatarModalOpen = _4[0], setAvatarModalOpen = _4[1];
    var currentModalClose = useRef(null);
    var currentClearFileInput = useRef(null);
    var avatarRef = useRef(null);
    var isHolder = checkIsHolder(patient);
    var defaultValues = {
        firstName: isHolder ? patient === null || patient === void 0 ? void 0 : patient.firstName : (_c = patient === null || patient === void 0 ? void 0 : patient.name) === null || _c === void 0 ? void 0 : _c.given[0],
        lastName: isHolder ? patient === null || patient === void 0 ? void 0 : patient.lastName : (_d = patient === null || patient === void 0 ? void 0 : patient.name) === null || _d === void 0 ? void 0 : _d.family,
        middleName: isHolder ? patient === null || patient === void 0 ? void 0 : patient.middleName : (_e = patient === null || patient === void 0 ? void 0 : patient.name) === null || _e === void 0 ? void 0 : _e.given[1],
        gender: patient === null || patient === void 0 ? void 0 : patient.gender,
        genderIdentity: patient === null || patient === void 0 ? void 0 : patient.gender_identity,
        sexualOrientation: patient === null || patient === void 0 ? void 0 : patient.sexual_orientation,
    };
    var _5 = useForm({
        defaultValues: defaultValues,
    }), register = _5.register, unregister = _5.unregister, errors = _5.formState.errors, control = _5.control, setValue = _5.setValue, getValues = _5.getValues, trigger = _5.trigger, clearErrors = _5.clearErrors, setError = _5.setError;
    useEffect(function () {
        return function () {
            dispatch(setSelectedPerson(null));
            if (avatarRef.current) {
                avatarRef.current.removeEventListener('cancel', handleCancelFileInput);
            }
        };
    }, []);
    useEffect(function () {
        if (!patient && patientId) {
            dispatch(getFamily());
        }
    }, [dispatch, patient, patientId]);
    useEffect(function () {
        if (!selectedPerson && (family === null || family === void 0 ? void 0 : family.length) && patientId) {
            var person = family.find(function (p) { return p._id === patientId; });
            if (person) {
                setPatient(person);
                dispatch(setSelectedPerson(person));
            }
        }
    }, [family, patientId, selectedPerson]);
    useEffect(function () {
        if (backendError === null || backendError === void 0 ? void 0 : backendError.updatePatient) {
            backendError === null || backendError === void 0 ? void 0 : backendError.updatePatient.forEach(function (er) {
                var msg = (er === null || er === void 0 ? void 0 : er.type) === 'type_error.none.not_allowed' ? t('error_required') : er === null || er === void 0 ? void 0 : er.message;
                setError(camelCase(er.field), {
                    type: 'custom',
                    message: msg,
                });
                if (er.field === 'race')
                    setRaceError(msg);
                if (er.field === 'ethnic_origin')
                    setEthnicOriginError(msg);
            });
        }
    }, [backendError]);
    useEffect(function () {
        if (statuses.uploadAvatarStatus === AsyncStatus.SUCCESS) {
            dispatch(resetStatus('uploadAvatarStatus'));
            setHasAvatar(true);
            setAvatarModalOpen(false);
            currentModalClose.current && currentModalClose.current();
            currentClearFileInput.current && currentClearFileInput.current();
        }
        if (statuses.removeAvatarStatus === AsyncStatus.SUCCESS) {
            dispatch(resetStatus('removeAvatarStatus'));
            setHasAvatar(false);
            setMediaFile(null);
            setAvatarModalOpen(false);
            currentModalClose.current && currentModalClose.current();
        }
    }, [statuses.uploadAvatarStatus, statuses.removeAvatarStatus]);
    useEffect(function () {
        var _a;
        var confirmedAvatar = (_a = selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.avatars) === null || _a === void 0 ? void 0 : _a.find(function (a) { return a.confirmed; });
        setHasAvatar(!!confirmedAvatar);
    }, [selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.avatars]);
    useEffect(function () {
        if (!isAvatarModalOpen) {
            currentModalClose.current && currentModalClose.current();
            setNeedUpdatePhoto(false);
        }
    }, [isAvatarModalOpen]);
    var handleCancelFileInput = function () {
        currentClearFileInput.current && currentClearFileInput.current();
        setAvatarModalOpen(false);
        setNeedUpdatePhoto(false);
    };
    useEffect(function () {
        if (avatarRef.current) {
            avatarRef.current.addEventListener('cancel', handleCancelFileInput);
        }
    }, [avatarRef]);
    var handleChangeMultiSelect = function (key, items) {
        var setFn = key === 'race' ? setRace : setEthnicOrigin;
        var prevData = key === 'race' ? race : ethnicOrigin;
        var unknownKey = 'prefer_not_to_say';
        var unknownItem = items.find(function (el) { return el.id === unknownKey; });
        var prevUnknownItem = prevData.find(function (el) { return el.id === unknownKey; });
        if (!prevUnknownItem.isSelect && unknownItem.isSelect) {
            var newData_1 = items.map(function (el) {
                el.isSelect = el.id === unknownKey;
                return el;
            });
            setFn(newData_1);
            return;
        }
        var newData = items.map(function (el) {
            if (el.id === unknownKey) {
                el.isSelect = false;
            }
            return el;
        });
        setFn(newData);
    };
    var handleUpdateProfleData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, isValid, raceIds, ethnicOriginIds, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    values = getValues();
                    return [4 /*yield*/, trigger()];
                case 1:
                    isValid = _a.sent();
                    raceIds = race.filter(function (el) { return el.isSelect; }).map(function (el) { return el.id; });
                    ethnicOriginIds = ethnicOrigin.filter(function (el) { return el.isSelect; }).map(function (el) { return el.id; });
                    if (!isValid)
                        return [2 /*return*/];
                    dispatch(removeError('updatePatient'));
                    data = {
                        firstName: values.firstName,
                        middleName: values.middleName,
                        lastName: values.lastName,
                        birthday: values.datePicker,
                        gender: values.gender,
                        race: raceIds.length > 0 ? raceIds : null,
                        ethnic_origin: ethnicOriginIds.length > 0 ? ethnicOriginIds : null,
                        gender_identity: values.genderIdentity,
                        sexual_orientation: values.sexualOrientation,
                    };
                    onUpdate(data);
                    return [2 /*return*/];
            }
        });
    }); };
    var clearAvatar = function () {
        dispatch(removeAvatar(patientId));
    };
    var handleAvatarClick = function (e) {
        if (!hasAvatar || needUpdatePhoto) {
            avatarRef.current.click();
            return;
        }
        if (isAvatarModalOpen)
            return;
        e === null || e === void 0 ? void 0 : e.preventDefault();
        setAvatarModalOpen(true);
        setNeedUpdatePhoto(true);
        var closeModal = showModal(SimpleConfirmPopup, {
            showCloseIcon: false,
            isTextCentered: true,
            primaryButtonLabel: t('label_upload_photo'),
            primaryButtonHandler: function () {
                setNeedUpdatePhoto(true);
                e.target.click();
                currentModalClose.current && currentModalClose.current();
            },
            isPrimary: false,
            secondaryButtonLabel: t('label_remove_photo'),
            secondaryButtonHandler: function () {
                clearAvatar();
            },
            isLoading2: statuses.removeAvatarStatus === AsyncStatus.LOADING,
            checkIsLoading2: function () {
                return useSelector(function (state) { return state.profile.statuses.removeAvatarStatus; }) ===
                    AsyncStatus.LOADING;
            },
            thirdButtonLabel: t('btn_cancel'),
            thirdButtonHandler: function () {
                setAvatarModalOpen(false);
                currentModalClose.current && currentModalClose.current();
                closeModal();
            },
            isBottomMobile: true,
            classNames: { modal: styles.avatarModal, btn2: styles.avatarBtnRemove },
            closeCallback: function () {
                setAvatarModalOpen(false);
                setNeedUpdatePhoto(false);
            },
        }).closeModal;
        currentModalClose.current = closeModal;
    };
    var handleInputFileClick = function (e, clearFn) {
        var _a, _b;
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        var file = (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        var src = getImgSRC(file);
        setMediaUrl(src);
        currentModalClose.current && currentModalClose.current();
        currentClearFileInput.current = clearFn;
        setNeedUpdatePhoto(false);
        if (!src)
            return;
        showModal(ImageCropper, {
            initImage: src,
            onSave: function (croppedFile) {
                var croppedSrc = getImgSRC(croppedFile);
                dispatch(uploadAvatar({
                    media: croppedSrc,
                    patientId: patientId,
                    file: croppedFile,
                }));
            },
            closeCallback: handleCancelFileInput,
        });
    };
    return (_jsxs("div", __assign({ className: clsx(styles.ProfileEdit, isModal && styles.ProfileEditModal) }, { children: [isModal && _jsx("div", __assign({ className: styles.ProfileEditTitle }, { children: titleText })), statuses.uploadAvatarStatus === AsyncStatus.LOADING ? (_jsx(AvatarLoader, {})) : (_jsxs("div", __assign({ className: styles.avatar, onClick: handleAvatarClick }, { children: [_jsx(InputFile, __assign({ ref: avatarRef, innerTitle: t('label_upload_photo'), fieldClassName: clsx(styles.slotFile), name: 'media', mediaTypes: ['image/jpeg', 'image/png', 'image/tiff'], type: "", title: t('label_edit'), deleteIconClassName: styles.avatarDeleteIcon, handleChange: handleInputFileClick }, { children: _jsx(Avatar, { patient: selectedPerson, firstName: isHolder
                                ? selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.firstName
                                : (_g = (_f = selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.name) === null || _f === void 0 ? void 0 : _f.given) === null || _g === void 0 ? void 0 : _g[0], lastName: isHolder
                                ? selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.lastName
                                : (_j = (_h = selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.name) === null || _h === void 0 ? void 0 : _h.given) === null || _j === void 0 ? void 0 : _j[1], image: getImgSRC(mediaFile), showName: false, needLoadAvatar: true, avatarSize: "large", classNameImage: styles.avatarImage }) })), _jsx("div", __assign({ className: styles.avatarEdit }, { children: hasAvatar ? t('label_change_photo') : t('label_upload_photo') }))] }))), _jsxs("div", __assign({ className: styles.ProfileEditContent }, { children: [isProfileLocked && (_jsx("div", __assign({ className: styles.lockWarning }, { children: _jsxs("p", { children: [t('label_profile_locked') + t('label_profile_support1'), _jsx("a", __assign({ href: "tel:".concat(SUPPORT_PHONE) }, { children: SUPPORT_DISPLAY_PHONE })), t('label_profile_support2'), _jsx("a", __assign({ href: SUPPORT_SITE }, { children: SUPPORT_DISPLAY_SITE })), "."] }) }))), _jsxs("div", __assign({ className: styles.ProfileEditContentRow }, { children: [_jsx(Input, { locked: isProfileLocked, showLockIcon: false, type: "text", label: "firstName", classes: [styles.input], control: control, register: register, clearErrors: clearErrors, error: errors['firstName'], unregister: unregister, placeholder: t('p_reg_first_name'), displayLabel: t('p_reg_first_name'), setEmpty: function () {
                                    setValue('firstName', '');
                                }, validation: {
                                    required: t('error_required'),
                                    minLength: 1,
                                    onChange: function () {
                                        clearErrors('firstName');
                                    },
                                }, required: true, hasLabel: !!getValues('firstName') }), _jsx(Input, { locked: isProfileLocked, showLockIcon: false, type: "text", label: "middleName", displayLabel: t('p_reg_middle_name'), classes: [styles.input], control: control, register: register, clearErrors: clearErrors, error: errors['middleName'], unregister: unregister, placeholder: t('p_reg_middle_name'), setEmpty: function () {
                                    setValue('middleName', '');
                                }, hasLabel: !!getValues('middleName') }), _jsx(Input, { locked: isProfileLocked, showLockIcon: false, type: "text", label: "lastName", displayLabel: t('p_reg_last_name'), classes: [styles.input], control: control, register: register, clearErrors: clearErrors, unregister: unregister, error: errors['lastName'], placeholder: t('p_reg_last_name'), setEmpty: function () {
                                    setValue('lastName', '');
                                }, validation: {
                                    required: t('error_required'),
                                    minLength: 1,
                                    onChange: function () {
                                        clearErrors('lastName');
                                    },
                                }, required: true, hasLabel: !!getValues('lastName') })] })), _jsxs("div", __assign({ className: styles.ProfileEditContentRowDouble }, { children: [_jsx(Controller, { control: control, name: 'datePicker', rules: {
                                    required: {
                                        value: true,
                                        message: t('error_required'),
                                    },
                                }, render: function (_a) {
                                    var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
                                    return (_jsx(DatePicker, { locked: isProfileLocked, showLockIcon: false, startValue: value, className: styles.inputDatePicker, bigStyle: true, placeholder: t('documents_detail_birth_date'), relativeRange: {
                                            startDate: {
                                                offset: -150,
                                                units: 'years',
                                            },
                                            endDate: {
                                                offset: isHolder ? -18 : 18,
                                                units: 'years',
                                            },
                                        }, handleChange: function (value) {
                                            clearErrors('datePicker');
                                            onChange(value);
                                        }, error: error === null || error === void 0 ? void 0 : error.message, defaultValue: isHolder ? patient === null || patient === void 0 ? void 0 : patient.birthday : patient === null || patient === void 0 ? void 0 : patient.birth_date }, 'datePicker'));
                                } }), _jsx("div", __assign({ className: styles.inputSelectWrapper }, { children: _jsx(Select, { hasInsideLabel: true, insideLabel: t('documents_detail_gender'), locked: isProfileLocked, showLockIcon: false, placeholder: t('placeholder_choose_gender'), className: styles.inputSelect, items: [
                                        {
                                            key: 'male',
                                            value: t('documents_detail_male'),
                                        },
                                        {
                                            key: 'female',
                                            value: t('documents_detail_female'),
                                        },
                                        {
                                            key: 'unknown',
                                            value: t('documents_detail_unknown'),
                                        },
                                    ], onChange: function (value) {
                                        setValue('gender', value);
                                        rerender();
                                    }, active: getValues('gender') }) }), "GenderSelect")] })), _jsx("div", __assign({ className: styles.inputSelectWrapper }, { children: _jsx(ItemsPicker, { items: race, setItems: function (items) {
                                setRaceError('');
                                handleChangeMultiSelect('race', items);
                            }, isRequired: true, hasInsideLabel: race.length > 0, insideLabel: t('label_race'), placeholder: t('placeholder_choose_race'), className: styles.inputPicker, textAreaClassName: clsx(styles.inputPickerTextArea, raceError && styles.inputPickerError), itemsError: raceError, showListOnClick: true }) })), _jsx("div", __assign({ className: styles.inputSelectWrapper }, { children: _jsx(ItemsPicker, { isRequired: true, items: ethnicOrigin, setItems: function (items) {
                                setEthnicOriginError('');
                                handleChangeMultiSelect('ethnicOrigin', items);
                            }, hasInsideLabel: ethnicOrigin.length > 0, insideLabel: t('label_ethnic_origin'), placeholder: t('placeholder_choose_ethnic_origin'), className: styles.inputPicker, textAreaClassName: clsx(styles.inputPickerTextArea, ethnicOriginError && styles.inputPickerError), itemsError: ethnicOriginError, showListOnClick: true }) })), _jsxs("div", __assign({ className: styles.ProfileEditContentRowDouble }, { children: [_jsx("div", __assign({ className: styles.inputSelectWrapper }, { children: _jsx(Select, { hasInsideLabel: !!getValues('genderIdentity'), insideLabel: t('label_gender_identity'), placeholder: t('placeholder_choose_gender_identity'), className: styles.inputSelect, items: Object.entries(GENDER_IDENTITY).map(function (_a) {
                                        var key = _a[0], value = _a[1];
                                        return ({
                                            key: key,
                                            value: value.title,
                                            description: value.description,
                                        });
                                    }), onChange: function (value) {
                                        clearErrors('genderIdentity');
                                        setValue('genderIdentity', value);
                                        rerender();
                                    }, active: getValues('genderIdentity'), error: ((_k = errors['genderIdentity']) === null || _k === void 0 ? void 0 : _k.message) || '' }) }), "GenderIdentity"), _jsx("div", __assign({ className: styles.inputSelectWrapper }, { children: _jsx(Select, { hasInsideLabel: !!getValues('sexualOrientation'), insideLabel: t('label_sexual_orientation'), placeholder: t('placeholder_choose_sexual_orientation'), className: styles.inputSelect, items: Object.entries(SEXUAL_ORIENTATION).map(function (_a) {
                                        var key = _a[0], value = _a[1];
                                        return ({
                                            key: key,
                                            value: value.title,
                                            description: value.description,
                                        });
                                    }), onChange: function (value) {
                                        clearErrors('sexualOrientation');
                                        setValue('sexualOrientation', value);
                                        rerender();
                                    }, active: getValues('sexualOrientation'), error: ((_l = errors['sexualOrientation']) === null || _l === void 0 ? void 0 : _l.message) || '' }) }), "SexOrientationSelect")] })), _jsx(Button, { classes: [styles.ProfileEditUpdateBtn], primary: true, label: t('btn_submit'), handler: handleUpdateProfleData, loading: isLoading || isLoadingEditUpdateFn() }), showDeleteAccountBtn && (_jsxs("div", __assign({ className: styles.ProfileDelete }, { children: [_jsx("div", __assign({ className: styles.ProfileDeleteTitle }, { children: t('btn_account_delete') })), (canDelete && !isProfileLocked) || isHolder ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.ProfileDeleteSubtitle }, { children: t('label_profile_delete') })), _jsx(Button, { classes: [styles.ProfileDeleteBtn], deleteAcc: true, label: deleteBtnText, handler: onDelete, loading: isLoadingDeleteUpdateFn() })] })) : (_jsx("div", __assign({ className: styles.ProfileDeleteSubtitle }, { children: t('patient_delete_refuse') + '.' })))] })))] }))] })));
};
