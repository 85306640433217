var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Visa = function () { return (_jsxs("svg", __assign({ width: "23", height: "16", viewBox: "0 0 23 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 2C0 0.895431 0.895431 0 2 0H21C22.1046 0 23 0.895431 23 2V14C23 15.1046 22.1046 16 21 16H2C0.89543 16 0 15.1046 0 14L0 2Z", fill: "white" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.04393 10.6761H5.60081L4.51865 6.57325C4.46728 6.38452 4.35822 6.21767 4.1978 6.13904C3.79744 5.94141 3.35627 5.78414 2.875 5.70482V5.54686H5.19975C5.5206 5.54686 5.76123 5.78414 5.80134 6.05971L6.36282 9.01925L7.80524 5.54686H9.20825L7.04393 10.6761ZM10.0104 10.6761H8.64749L9.76975 5.54686H11.1327L10.0104 10.6761ZM12.8959 6.96785C12.936 6.69159 13.1767 6.53363 13.4574 6.53363C13.8986 6.49397 14.3791 6.57329 14.7802 6.77023L15.0208 5.66587C14.6198 5.50791 14.1786 5.42859 13.7782 5.42859C12.4554 5.42859 11.4929 6.13907 11.4929 7.12512C11.4929 7.87526 12.1747 8.26914 12.656 8.50642C13.1767 8.74302 13.3772 8.90098 13.3371 9.13758C13.3371 9.49248 12.936 9.65044 12.5357 9.65044C12.0544 9.65044 11.5731 9.53214 11.1327 9.33452L10.892 10.4396C11.3733 10.6365 11.894 10.7158 12.3752 10.7158C13.8585 10.7548 14.7802 10.045 14.7802 8.97962C14.7802 7.63798 12.8959 7.55934 12.8959 6.96785ZM19.55 10.6761L18.4678 5.54686H17.3054C17.0648 5.54686 16.8242 5.70482 16.744 5.94141L14.7401 10.6761H16.1431L16.4231 9.92666H18.147L18.3074 10.6761H19.55ZM17.506 6.92823L17.9064 8.86136H16.7841L17.506 6.92823Z", fill: "#172B85" })] }))); };
export default Visa;
