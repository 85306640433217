var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@core/components/buttons';
import Plus from '@core/shared/svg/plus';
import styles from './index.module.sass';
export var AddButton = function (_a) {
    var label = _a.label, className = _a.className, onClick = _a.onClick, popover = _a.popover;
    var _b = useState(false), openPopover = _b[0], setOpenPopover = _b[1];
    var box = useRef();
    useEffect(function () {
        if (popover) {
            var onClick_1 = function (e) { var _a; return ((_a = box.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) || setOpenPopover(false); };
            document.addEventListener('click', onClick_1);
            return function () { return document.removeEventListener('click', onClick_1); };
        }
    }, []);
    return (_jsxs("div", __assign({ ref: box, className: styles.addButtonWrapper }, { children: [_jsxs("div", __assign({ className: styles.box, onClick: function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    if (popover) {
                        return setOpenPopover(!openPopover);
                    }
                    else {
                        onClick();
                    }
                } }, { children: [_jsx(Button, { primary: true, classes: [styles.btn, className], icon: _jsx(Plus, {}), handler: function () { } }), label && _jsx("span", __assign({ className: styles.label }, { children: label }))] })), !!popover && openPopover && (_jsx("div", __assign({ className: styles.popover, onClick: function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpenPopover(false);
                } }, { children: popover })))] })));
};
