var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { SearchInput } from '@core/components/formComponents/admin/inputs';
import { showModal } from '@core/components/modals';
import { Title } from '@core/components/title';
import AddFamily from '@core/pages/forms/forms/addFamily';
import { SELECT_PATIENT_PARAM } from '@core/shared/constants/common';
import { AsyncStatus } from '@core/shared/constants/loaderTypes';
import { getFamily, getFamilyByPersonId } from '@core/store/reducers/profileSlice';
import styles from './index.module.sass';
var mstp = function (s) { return ({
    self: s.booking.person,
    loading: s.profile.isLoading,
    family: s.profile.family,
    statusFamily: s.profile.statuses.getFamilyStatus,
}); };
var Patient = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var onClick = _a.onClick, disabled = _a.disabled, patient = _a.patient, isOwner = _a.isOwner;
    var lastName = ((_b = patient === null || patient === void 0 ? void 0 : patient.name) === null || _b === void 0 ? void 0 : _b.family) || '';
    var firstName = ((_d = (_c = patient === null || patient === void 0 ? void 0 : patient.name) === null || _c === void 0 ? void 0 : _c.given) === null || _d === void 0 ? void 0 : _d[0]) || '';
    var middleName = ((_f = (_e = patient === null || patient === void 0 ? void 0 : patient.name) === null || _e === void 0 ? void 0 : _e.given) === null || _f === void 0 ? void 0 : _f[1]) || '';
    return (_jsxs("div", __assign({ className: clsx(styles.patient, disabled && styles.patientDisabled), onClick: function () {
            onClick(patient);
        } }, { children: [_jsx("div", __assign({ className: styles.patientCircle }, { children: _jsx("span", __assign({ className: styles.patientAbbr }, { children: ((_g = firstName === null || firstName === void 0 ? void 0 : firstName[0]) === null || _g === void 0 ? void 0 : _g.toUpperCase()) + ((_h = lastName === null || lastName === void 0 ? void 0 : lastName[0]) === null || _h === void 0 ? void 0 : _h.toUpperCase()) })) })), _jsxs("div", __assign({ className: styles.patientInformation }, { children: [_jsxs("p", __assign({ className: styles.patientName }, { children: [firstName || '', " ", middleName || '', " ", lastName || ''] })), isOwner && (_jsx("p", __assign({ className: styles.patientRelationship }, { children: t('label_account_holder') })))] }))] })));
};
export var SelectPatient = function (_a) {
    var handleSelectPatient = _a.handleSelectPatient, userId = _a.userId, handleAddFamily = _a.handleAddFamily, closeModal = _a.closeModal;
    var _b = useSelector(mstp), self = _b.self, loading = _b.loading, family = _b.family, statusFamily = _b.statusFamily;
    var dispatch = useDispatch();
    var _c = useState(''), search = _c[0], setSearch = _c[1];
    var _d = useSearchParams(), searchParams = _d[0], setSearchParams = _d[1];
    useEffect(function () {
        if (userId) {
            dispatch(getFamilyByPersonId());
        }
        else {
            dispatch(getFamily());
        }
    }, [dispatch]);
    useEffect(function () {
        if (statusFamily === AsyncStatus.FAIL) {
            closeModal();
        }
    }, [statusFamily]);
    useEffect(function () {
        return function () {
            var param = searchParams.get(SELECT_PATIENT_PARAM);
            if (param === 'true') {
                searchParams.delete(SELECT_PATIENT_PARAM);
                setSearchParams(searchParams);
            }
        };
    }, []);
    var filteredFamily = useMemo(function () {
        if (!(family === null || family === void 0 ? void 0 : family.length))
            return [];
        if (!search)
            return family;
        return family.filter(function (familyItem) {
            var haystack = [familyItem.name.given.join(' '), familyItem.name.family]
                .join(' ')
                .toLowerCase();
            var needle = search.toLowerCase().split(' ');
            for (var _i = 0, needle_1 = needle; _i < needle_1.length; _i++) {
                var item = needle_1[_i];
                if (haystack.indexOf(item) === -1) {
                    return false;
                }
            }
            return true;
        });
    }, [family, search]);
    var onPatientClick = function (patient) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            handleSelectPatient(patient);
            return [2 /*return*/];
        });
    }); };
    var onAddFamily = function () {
        if (handleAddFamily) {
            handleAddFamily();
        }
        else {
            showModal(AddFamily, {
                type: 'clean',
                showCloseIcon: false,
            });
        }
    };
    if (loading) {
        return (_jsx("div", __assign({ className: styles.bubbleLoaderWrapper }, { children: _jsx("div", { className: styles.bubbleLoader }) })));
    }
    return (_jsxs("div", __assign({ className: styles.selectPatient }, { children: [_jsx("div", __assign({ className: styles.selectPatientTitle }, { children: _jsx(Title, { title: t('placeholder_select_patient') }) })), _jsx("div", __assign({ className: styles.selectPatientSearchInputWrapper }, { children: _jsx(SearchInput, { placeholder: t('placeholder_search_patient'), className: styles.selectPatientSearchInput, value: search, onChange: function (e) {
                        setSearch(e.target.value);
                    } }) })), _jsxs("div", __assign({ className: clsx(styles.patient, styles.addPatient), onClick: onAddFamily }, { children: [_jsx("div", { className: clsx(styles.patientCircle, styles.addPatientCircle) }), _jsx("p", __assign({ className: styles.addPatientName }, { children: t('label_add_patient') }))] })), _jsxs("div", __assign({ className: styles.patientPeople }, { children: [_jsx(Patient, { patient: self, isOwner: true, onClick: onPatientClick }), filteredFamily.map(function (familyPerson) { return (_jsx(React.Fragment, { children: _jsx(Patient, { patient: familyPerson, onClick: onPatientClick }) }, familyPerson._id)); })] }))] })));
};
