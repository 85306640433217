var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Mail = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", __assign({ fill: "#007AFF", fillRule: "nonzero" }, { children: [_jsx("path", { d: "M7 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H7zm0-2h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3z" }), _jsx("path", { d: "m4.386 8.79 7.772 6.044a3 3 0 0 0 3.684 0l7.772-6.045-1.228-1.578-7.772 6.045a1 1 0 0 1-1.228 0L5.614 7.21 4.386 8.789z" })] })) }))); };
export default Mail;
