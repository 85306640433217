var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { showModal } from '@core/components/modals';
import { Terms } from '@pages/landing/statics/terms';
import styles from './index.module.sass';
export var Footer = function () {
    var location = useLocation();
    useEffect(function () {
        var terms = location.hash.includes('#terms');
        var modal = null;
        if (terms)
            modal = Terms;
        if (modal !== null) {
            showModal(modal, { type: 'fullPage' });
        }
    }, [location]);
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: styles.footer }, { children: _jsx("div", __assign({ className: styles.container }, { children: _jsxs("div", __assign({ className: styles.footerBottom }, { children: [_jsx("div", __assign({ className: styles.footerBottomText }, { children: "Copyright \u00A9 2024 QSure. All rights reserved." })), _jsx("div", __assign({ className: styles.footerBottomBtn }, { children: _jsx(Link, __assign({ to: '/#terms' }, { children: _jsx("div", __assign({ className: styles.footerBottomBtnItem }, { children: "Terms of Service" })) })) }))] })) })) })) }));
};
