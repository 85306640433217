var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Doc = function () { return (_jsxs("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23 9.64909L18.4785 4H5V24H23V9.64909ZM21 22H7V6H17.5176L21 10.3509V22Z", fill: "black" }), _jsx("rect", { x: "10", y: "12", width: "8", height: "2", rx: "1", fill: "black" }), _jsx("rect", { x: "10", y: "16", width: "5", height: "2", rx: "1", fill: "black" })] }))); };
export default Doc;
