var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { clearAlert } from '@core/store/reducers/alertSlice';
import styles from './index.module.sass';
var Alert = function () {
    var dispatch = useDispatch();
    var alert = useSelector(function (s) { return s.alert; });
    var _a = useState(false), active = _a[0], setActive = _a[1];
    useEffect(function () {
        var _a;
        if ((_a = alert.value) === null || _a === void 0 ? void 0 : _a.length) {
            setActive(true);
            setTimeout(function () {
                setActive(false);
                dispatch(clearAlert());
            }, 3000);
        }
    }, [alert.value]);
    var handleClick = function () { return setActive(false); };
    return (_jsx("div", __assign({ className: clsx([styles.alert, active && styles.alertActive]) }, { children: _jsx("div", __assign({ onClick: handleClick, className: styles.alertText }, { children: alert.value })) })));
};
export default Alert;
