var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import styles from './index.module.sass';
var mapState = function (state) { return ({
    loading: state.loading,
}); };
var timeInterval = 400;
var progressInterval = 2.5;
export var ProgressLine = function () {
    var _a = useState(false), isActive = _a[0], setIsActive = _a[1];
    var _b = useState(false), isError = _b[0], setIsError = _b[1];
    var _c = useState(0), progress = _c[0], setProgress = _c[1];
    var loading = useSelector(mapState).loading;
    var interval = useRef();
    var startProgress = useCallback(function () {
        if (!isActive) {
            setIsActive(true);
            setProgress(progressInterval);
            interval.current = setInterval(function () {
                setProgress(function (prevProgress) { return prevProgress + progressInterval; });
            }, timeInterval);
        }
    }, [isActive]);
    function clearProgress() {
        clearInterval(interval.current);
        setProgress(0);
        setIsActive(false);
        setIsError(false);
    }
    useEffect(function () {
        if (loading.isLoading) {
            startProgress();
        }
    }, [startProgress, loading.isLoading]);
    useEffect(function () {
        if (isActive && !loading.isLoading) {
            setProgress(100);
            setTimeout(clearProgress, timeInterval * 1.8);
        }
        if (isActive && loading.isError) {
            setIsError(true);
        }
    }, [isActive, loading.isLoading, loading.isError]);
    return (_jsx("div", __assign({ className: styles.progress }, { children: _jsx("div", { style: { width: progress + '%' }, className: clsx(styles.progressLine, isActive && styles.progressLineActive, isError && styles.progressLineError) }) })));
};
