var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from 'redux-saga/effects';
import { serviceMap } from '@core/services/map';
import { getMarkerObject, getOfficeObject } from '@core/shared/utils/mapUtils';
import { getDetail, getOfficesByProcedures, getOfficesNearPoint, setDetail, setMarkersOptions, setOffices, } from '@core/store/reducers/mapSlice';
import { handlerErrors } from '@core/store/sagas/errors';
export function getOfficesAsync(_a) {
    var data, sortedOffices, markers;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(serviceMap.getOffices, payload.lat, payload.lng, payload.radius)];
            case 1:
                data = (_b.sent()).data;
                sortedOffices = data.map(function (office) { return getOfficeObject(office); });
                markers = data.map(function (office) { return getMarkerObject(office); });
                return [4 /*yield*/, put(setOffices(sortedOffices))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(setMarkersOptions({
                        options: markers,
                        replace: true,
                    }))];
            case 3:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function getDetailAsync(_a) {
    var data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(serviceMap.getOfficeById, payload)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(setDetail(__assign(__assign({}, getOfficeObject(data)), { position: {
                            lat: data.place.geo_object.coordinates[1],
                            lng: data.place.geo_object.coordinates[0],
                        } })))];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function getOfficesByProceduresAsync(_a) {
    var data, filteredData, sortedOffices, markers;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(serviceMap.getOffices, payload.lat, payload.lng, payload.radius)];
            case 1:
                data = (_b.sent()).data;
                filteredData = [];
                return [4 /*yield*/, (filteredData = data.filter(function (element) {
                        var arrElementProcedures = element.procedures.map(function (element) { return element.id; });
                        return payload.procedures.every(function (procedure) {
                            return arrElementProcedures.includes(procedure);
                        });
                    }))];
            case 2:
                _b.sent();
                sortedOffices = filteredData.map(function (office) { return getOfficeObject(office); });
                markers = filteredData.map(function (office) {
                    return getMarkerObject(office);
                });
                return [4 /*yield*/, put(setOffices(sortedOffices))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setMarkersOptions({
                        options: markers,
                        replace: true,
                    }))];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function mapWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(getOfficesNearPoint().type, handlerErrors(getOfficesAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(getDetail().type, handlerErrors(getDetailAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(getOfficesByProcedures().type, handlerErrors(getOfficesByProceduresAsync))];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
