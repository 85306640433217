var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var MasterCard = function () { return (_jsxs("svg", __assign({ width: "40", height: "28", viewBox: "0 0 40 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 6C0 2.68629 2.68629 0 6 0H34C37.3137 0 40 2.68629 40 6V22C40 25.3137 37.3137 28 34 28H6C2.68629 28 0 25.3137 0 22L0 6Z", fill: "white" }), _jsx("circle", { cx: "14.4", cy: "14", r: "8.4", fill: "#FF3B30" }), _jsx("circle", { cx: "25.6", cy: "14", r: "8.4", fill: "#F5A623" }), _jsx("mask", __assign({ id: "mask0_5781_28393", maskUnits: "userSpaceOnUse", x: "17", y: "5", width: "17", height: "18" }, { children: _jsx("circle", { cx: "25.6", cy: "14", r: "8.4", fill: "white" }) })), _jsx("g", __assign({ mask: "url(#mask0_5781_28393)" }, { children: _jsx("circle", { cx: "14.4", cy: "14", r: "8.4", fill: "#FF3B30", fillOpacity: "0.1" }) }))] }))); };
export default MasterCard;
