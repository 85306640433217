var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import useWindowDimensions from '@core/shared/utils/widthHook';
import styles from './index.module.sass';
export var Title = function (_a) {
    var title = _a.title, description = _a.description, link = _a.link, linkName = _a.linkName, _b = _a.exLink, exLink = _b === void 0 ? true : _b, _c = _a.classes, classes = _c === void 0 ? [] : _c, _d = _a.adaptiveTitleTop, adaptiveTitleTop = _d === void 0 ? true : _d, titleClass = _a.titleClass, descriptionClass = _a.descriptionClass;
    var width = useWindowDimensions().width;
    return (_jsxs("div", __assign({ className: clsx.apply(void 0, __spreadArray([styles.header], classes, false)) }, { children: [_jsxs("div", __assign({ className: clsx(adaptiveTitleTop && description && width < 970 && styles.headerTitleWrapper) }, { children: [_jsx("div", __assign({ className: clsx(styles.headerTitle, description && styles.headerTitleWithDescr, titleClass) }, { children: title })), description && (_jsx("div", __assign({ className: clsx(styles.headerDescription, descriptionClass) }, { children: description })))] })), exLink && link && (_jsx(Link, __assign({ className: styles.headerLink, to: link }, { children: linkName })))] })));
};
