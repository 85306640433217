var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var MedCrossCircle = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "60", height: "60", viewBox: "0 0 60 60", fill: "none" }, { children: [_jsx("circle", { cx: "30", cy: "30", r: "30", fill: "#FFCC37" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27 15L26.7356 15.0076C24.3734 15.1445 22.5 17.1035 22.5 19.5V22.5H19.5C17.0147 22.5 15 24.5147 15 27V33L15.0076 33.2644C15.1445 35.6266 17.1035 37.5 19.5 37.5H22.5V40.5C22.5 42.9853 24.5147 45 27 45H33L33.2644 44.9924C35.6266 44.8555 37.5 42.8965 37.5 40.5V37.5H40.5C42.9853 37.5 45 35.4853 45 33V27L44.9924 26.7356C44.8555 24.3734 42.8965 22.5 40.5 22.5H37.5V19.5C37.5 17.0147 35.4853 15 33 15H27ZM27 18H33C33.8284 18 34.5 18.6716 34.5 19.5V24C34.5 24.8284 35.1716 25.5 36 25.5H40.5C41.3284 25.5 42 26.1716 42 27V33C42 33.8284 41.3284 34.5 40.5 34.5H36C35.1716 34.5 34.5 35.1716 34.5 36V40.5C34.5 41.3284 33.8284 42 33 42H27C26.1716 42 25.5 41.3284 25.5 40.5V36C25.5 35.1716 24.8284 34.5 24 34.5H19.5C18.6716 34.5 18 33.8284 18 33V27C18 26.1716 18.6716 25.5 19.5 25.5H24C24.8284 25.5 25.5 24.8284 25.5 24V19.5C25.5 18.6716 26.1716 18 27 18Z", fill: "black" })] }))); };
export default MedCrossCircle;
