var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import ArrowLeft from '@core/shared/svg/arrow-left';
import styles from './index.module.sass';
export var HeaderMobile = function (_a) {
    var _b;
    var currentRoute = _a.currentRoute;
    var navigate = useNavigate();
    var titles = (_b = {},
        _b[routePaths.SETTINGS] = t('title_profile_settings'),
        _b[routePaths.MANAGE_PLAN] = t('label_manage_plan'),
        _b[routePaths.MANAGE_PLAN_PAGE.replace(':page', 'payment_history')] = t('label_manage_plan'),
        _b[routePaths.MANAGE_PLAN_PAGE.replace(':page', 'payment_method')] = t('label_manage_plan'),
        _b[routePaths.PROFILE_EDIT] = t('label_profile'),
        _b[routePaths.UPDATE_PASSWORD] = t('label_password_update'),
        _b[routePaths.CONTACTS] = t('label_contacts'),
        _b[routePaths.ADD_CONTACT.replace(':type', 'email')] = t('label_add_email'),
        _b[routePaths.ADD_CONTACT.replace(':type', 'phone')] = t('label_add_phone'),
        _b[routePaths.DELETE_CONTACT] = t('label_delete_contact'),
        _b[routePaths.PAYMENT] = t('label_manage_plan'),
        _b[routePaths.ADD_PAYMENT] = t('label_add_payment_method'),
        _b);
    var handleGoBack = function () {
        switch (currentRoute) {
            case routePaths.SETTINGS:
                navigate(routePaths.HOME);
                break;
            case routePaths.MANAGE_PLAN:
            case routePaths.PAYMENT:
            case routePaths.PROFILE_EDIT:
            case routePaths.CONTACTS:
            case routePaths.UPDATE_PASSWORD:
                navigate(routePaths.SETTINGS);
                break;
            case routePaths.ADD_CONTACT.replace(':type', 'email'):
            case routePaths.ADD_CONTACT.replace(':type', 'phone'):
            case routePaths.DELETE_CONTACT:
                navigate(routePaths.CONTACTS);
                break;
            case routePaths.ADD_PAYMENT:
                navigate(routePaths.PAYMENT);
                break;
            default:
                navigate(-1);
        }
    };
    return (_jsxs("div", __assign({ className: styles.header }, { children: [_jsx("button", __assign({ className: styles.headerButton, onClick: handleGoBack }, { children: _jsx(ArrowLeft, {}) })), _jsx("h1", __assign({ className: clsx(styles.headerTitle) }, { children: titles[currentRoute] }))] })));
};
