import { t } from 'i18next';
export var getRaceTranslation = function () { return ({
    american_indian_or_alaska_native: t('race_american_indian_or_alaska'),
    asian: t('race_asian'),
    black_or_african_american: t('race_black_or_african'),
    hispanic: t('race_hispanic'),
    native_hawaiian_or_other_pacific_islander: t('race_native_hawaiian'),
    white: t('race_white'),
    multiracial: t('race_multiracial'),
    other: t('race_other'),
    prefer_not_to_say: t('race_unknown'),
}); };
export var getEthnicOriginTranslation = function () { return ({
    african: t('ethnic_african'),
    african_american: t('ethnic_african_american'),
    asian: t('ethnic_asian'),
    black: t('ethnic_black'),
    chinese: t('ethnic_chinese'),
    east_african: t('ethnic_east_african'),
    east_asian: t('ethnic_east_asian'),
    hispanic_or_latin_american: t('ethnic_hispanic_latin'),
    indian: t('ethnic_indian'),
    middle_eastern: t('ethnic_middle_eastern'),
    native_american_or_inuit: t('ethnic_native_american'),
    pacific_islander: t('ethnic_pacific_islander'),
    south_asian: t('ethnic_south_asian'),
    southeast_asian: t('ethnic_southeast_asian'),
    white_or_caucasian: t('ethnic_white'),
    mixed_ethnicity: t('ethnic_mixed'),
    other: t('ethnic_other'),
    prefer_not_to_say: t('ethnic_unknown'),
}); };
export var getGenderIdentityTranslation = function () { return ({
    cisgender: {
        title: t('gender_identity_cisgender'),
        description: t('gender_identity_description_cisgender'),
    },
    transgender: {
        title: t('gender_identity_transgender'),
        description: t('gender_identity_description_transgender'),
    },
    nonbinary: {
        title: t('gender_identity_nonbinary'),
    },
    not_listed: {
        title: t('gender_identity_not_listed'),
    },
    not_sure: {
        title: t('gender_identity_questioning'),
    },
    prefer_not_to_say: {
        title: t('gender_identity_unknown'),
    },
}); };
export var getSexualOrientationTranslation = function () { return ({
    heterosexual: {
        title: t('sexual_orientation_heterosexual'),
        description: t('sexual_orientation_description_heterosexual'),
    },
    lesbian: {
        title: t('sexual_orientation_lesbian'),
        description: t('sexual_orientation_description_lesbian'),
    },
    gay: {
        title: t('sexual_orientation_gay'),
        description: t('sexual_orientation_description_gay'),
    },
    bisexual: {
        title: t('sexual_orientation_bisexual'),
        description: t('sexual_orientation_description_bisexual'),
    },
    queer: {
        title: t('sexual_orientation_queer'),
        description: t('sexual_orientation_description_queer'),
    },
    questioning: {
        title: t('sexual_orientation_questioning'),
        description: t('sexual_orientation_description_questioning'),
    },
    prefer_not_to_say: {
        title: t('sexual_orientation_unknown'),
    },
}); };
