var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { t } from 'i18next';
import { call, put } from 'redux-saga/effects';
import { setAlert } from '@core/store/reducers/alertSlice';
import { logOut } from '@core/store/reducers/authSlice';
import { addError } from '@core/store/reducers/errorsSlice';
import { addIsLoading, removeIsLoading, setErrorIsLoading } from '@core/store/reducers/loadingSlice';
export var STOP_MESSAGE = {
    type: 'stopMessage',
};
export function replaceErrors(items) {
    return {
        type: 'replaceErrors',
        data: items,
    };
}
function iterateErrors(map, detail, payload, action) {
    var is_equal, key;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (map.loc !== '*') {
                    is_equal = map.loc.filter(function (item) { var _a; return ((_a = detail === null || detail === void 0 ? void 0 : detail.loc) === null || _a === void 0 ? void 0 : _a.indexOf(item)) < 0; }).length === 0;
                    if (!is_equal) {
                        return [2 /*return*/];
                    }
                }
                if (map.payload) {
                    for (key in map.payload) {
                        // TODO: Fix this bad peace of code
                        if (map.loc.length === 3) {
                            if (map.payload[key] !== payload[map.loc[1]][key]) {
                                return [2 /*return*/];
                            }
                        }
                        else {
                            if (map.payload[key] !== payload[key]) {
                                return [2 /*return*/];
                            }
                        }
                    }
                }
                if (Array.isArray(map.map)) {
                    if (map.map.indexOf(detail.type) === -1) {
                        return [2 /*return*/];
                    }
                }
                if (!(map.action === 'addError')) return [3 /*break*/, 3];
                return [4 /*yield*/, put(setErrorIsLoading(action.type))];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(addError({ type: action.payload.type || map.payload.type, error: map.replace }))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                if (!(map.action === 'setAlert')) return [3 /*break*/, 6];
                return [4 /*yield*/, put(setErrorIsLoading(action.type))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(setAlert(t(map.replace)))];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}
export function handlerErrors(sagaFn, intercept, skipLoading) {
    if (skipLoading === void 0) { skipLoading = false; }
    return function (action) {
        var e_1, exception, response, handler, done, result, list, details, payload, _i, details_1, errorItem, _a, list_1, mapItem;
        var _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _f.trys.push([0, 3, 19, 21]);
                    return [4 /*yield*/, action.type !== 'profile/getData' &&
                            action.type !== 'profile/getInfo' &&
                            !skipLoading &&
                            put(addIsLoading(action.type))];
                case 1:
                    _f.sent();
                    return [4 /*yield*/, call(sagaFn, action)];
                case 2:
                    _f.sent();
                    return [3 /*break*/, 21];
                case 3:
                    e_1 = _f.sent();
                    exception = e_1;
                    response = exception.response;
                    if (!response) {
                        return [2 /*return*/];
                    }
                    if (!(typeof intercept === 'function')) return [3 /*break*/, 13];
                    handler = intercept({ e: exception, action: action });
                    done = false;
                    _f.label = 4;
                case 4:
                    if (!!done) return [3 /*break*/, 13];
                    result = handler.next();
                    if (!(result.value === STOP_MESSAGE)) return [3 /*break*/, 5];
                    return [2 /*return*/];
                case 5:
                    if (!(((_b = result.value) === null || _b === void 0 ? void 0 : _b.type) === 'replaceErrors')) return [3 /*break*/, 11];
                    list = result.value.data;
                    details = (_c = response.data) === null || _c === void 0 ? void 0 : _c.detail;
                    payload = action.payload;
                    _i = 0, details_1 = details;
                    _f.label = 6;
                case 6:
                    if (!(_i < details_1.length)) return [3 /*break*/, 11];
                    errorItem = details_1[_i];
                    _a = 0, list_1 = list;
                    _f.label = 7;
                case 7:
                    if (!(_a < list_1.length)) return [3 /*break*/, 10];
                    mapItem = list_1[_a];
                    return [4 /*yield*/, iterateErrors(mapItem, errorItem, payload, action)];
                case 8:
                    _f.sent();
                    _f.label = 9;
                case 9:
                    _a++;
                    return [3 /*break*/, 7];
                case 10:
                    _i++;
                    return [3 /*break*/, 6];
                case 11: return [4 /*yield*/, result.value];
                case 12:
                    _f.sent();
                    done = result.done;
                    return [3 /*break*/, 4];
                case 13:
                    if (!(response.status === 500)) return [3 /*break*/, 15];
                    return [4 /*yield*/, put(setAlert(t('error_unknown')))];
                case 14:
                    _f.sent();
                    _f.label = 15;
                case 15:
                    if (!((response.status === 401 && ((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.error) === 'jwt_error') ||
                        (response.status === 403 && ((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.detail) === 'Not authenticated'))) return [3 /*break*/, 17];
                    return [4 /*yield*/, put(logOut())];
                case 16:
                    _f.sent();
                    return [2 /*return*/];
                case 17: return [4 /*yield*/, put(setErrorIsLoading(action.type))];
                case 18:
                    _f.sent();
                    return [3 /*break*/, 21];
                case 19: return [4 /*yield*/, put(removeIsLoading())];
                case 20:
                    _f.sent();
                    return [7 /*endfinally*/];
                case 21: return [2 /*return*/];
            }
        });
    };
}
