var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var LogoSmall = function () { return (_jsxs("svg", __assign({ width: "90", height: "90", viewBox: "0 0 90 90", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M61.0607 21.207L63.5928 26.3237V44.7225V68.0076C63.5928 69.6962 63.4583 70.8346 62.591 72.1571L65.2467 71.4768C68.0276 70.2129 69.3857 71.4079 71.4011 69.1707C74.8786 65.3112 76.9979 60.2248 77 54.6685V44.7235V34.7821C77 22.965 67.4101 13.2669 55.5895 13C58.6476 15.5756 59.0711 17.1896 61.0607 21.207Z", fill: "#EBA330" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21.7502 62.3702L24.0527 70.624L31.6102 75.3217L33.5976 75.6446C34.0915 75.678 34.5865 75.6991 35.0892 75.6975H36.4059C51.0729 75.6975 52.16 92.8484 67.8144 82.8926C68.1164 82.7024 68.2622 82.3614 68.1909 82.0118C68.12 81.6616 67.8542 81.4066 67.4973 81.3454C56.3057 79.4568 51.3884 62.3702 38.457 62.3702H21.7502Z", fill: "#EB1C25" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M45.0902 13H35.0891C23.204 13 13.4521 22.5331 13.1822 34.288C15.4003 32.6205 17.0103 31.5319 20.9599 29.3821L26.5878 26.3283L45.0902 26.3294L63.5926 26.3283V21.4425C63.5926 17.0138 60.1105 13.3466 55.7373 13.0103C55.5247 13.0041 55.3085 13 55.0924 13H45.0902Z", fill: "#82AF40" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M68.5089 63.1236L54.0002 63.1221C53.65 63.1221 53.3516 63.3201 53.2177 63.643C52.0199 66.5029 63.6889 75.4251 68.1112 72.1554C73.3709 68.2702 76.838 62.1045 76.9999 55.1635C76.7356 59.5788 73.013 63.1221 68.5089 63.1236Z", fill: "#EB1C25" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21.4904 26.3301C17.0329 26.3301 13.3413 28.9464 13.0083 33.2975C13.0047 33.5093 13 33.7196 13 33.9325V43.8739V53.8188C13 65.6354 22.5884 75.3326 34.4105 75.5999C29.968 75.3398 26.4046 71.636 26.4046 67.158V43.8728V26.3301H21.4904Z", fill: "#22B6E3" })] }))); };
export default LogoSmall;
