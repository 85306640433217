var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@core/components/buttons/button';
import { Input } from '@core/components/formComponents/input';
import { showModal } from '@core/components/modals';
import { Title } from '@core/components/title';
import ArrowRight from '@core/shared/svg/arrow-right';
import SearchIcon from '@core/shared/svg/search';
import { ModalForm } from './view/modalForm';
import { SelectItems } from './view/selectItems';
import styles from './index.module.sass';
export var Select = function (_a) {
    var title = _a.title, _b = _a.items, items = _b === void 0 ? [] : _b, closeModal = _a.closeModal, type = _a.type, multi = _a.multi, _c = _a.handleChange, handleChange = _c === void 0 ? function () { } : _c;
    var search = useForm();
    var _d = useState([]), innerItems = _d[0], setInnerItems = _d[1];
    var _e = useState([]), values = _e[0], setValues = _e[1];
    var searchValue = search.watch('search');
    useEffect(function () {
        setInnerItems(items);
    }, [items]);
    var searchedItems = useMemo(function () {
        return innerItems.filter(function (item) { var _a, _b; return (_b = (_a = item === null || item === void 0 ? void 0 : item.label) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.includes(searchValue === null || searchValue === void 0 ? void 0 : searchValue.toLowerCase()); });
    }, [searchValue, innerItems]);
    var handleNoInsurance = function () {
        closeModal();
        showModal(ModalForm, {
            title: 'Insurance Details',
        });
    };
    return (_jsx("div", __assign({ className: styles.Select }, { children: _jsxs("div", __assign({ className: styles.container }, { children: [_jsxs("div", __assign({ className: styles.SelectHeader }, { children: [_jsx("div", __assign({ className: styles.SelectHeaderBack, onClick: closeModal }, { children: _jsx(ArrowRight, {}) })), _jsx(Title, { classes: [styles.title], title: title, exLink: false })] })), _jsx(Input, { classes: [styles.SelectSearch], label: "search", type: "text", icon: _jsx(SearchIcon, {}), setEmpty: function () { return search.setValue('search', ''); }, placeholder: 'Search state', register: search.register, clearErrors: search.clearErrors, control: search.control, unregister: search.unregister }), _jsx("div", __assign({ className: styles.SelectItems }, { children: searchedItems.length ? (_jsx(SelectItems, { items: searchedItems, values: values, setValues: setValues, type: type, multi: multi })) : (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: styles.SelectItemsNoResults }, { children: "No Results found" })), type === 'Insurance' && (_jsx(Button, { handler: handleNoInsurance, label: "Add Insurance Company" }))] })) })), type === 'Insurance' && (_jsxs("div", __assign({ className: styles.SelectButtons }, { children: [_jsx(Button, { handler: function () {
                                closeModal();
                                showModal(ModalForm, {
                                    title: 'Insurance Details',
                                    isHaveInsurance: true,
                                });
                            }, primary: true, label: "Select" }), _jsx(Button, { handler: handleNoInsurance, label: "I have no health insurance" })] }))), type === 'State' && (_jsxs("div", __assign({ className: styles.SelectButtons }, { children: [_jsx(Button, { classes: [styles.SelectButton], disabled: !values.length, handler: function () {
                                handleChange(values);
                                closeModal();
                            }, primary: true, label: "Select" }), _jsx(Button, { classes: [styles.SelectButton], handler: function () { return closeModal(); }, label: "Cancel" })] })))] })) })));
};
