var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import styles from './index.module.sass';
export var CalendarYears = function (_a) {
    var innerValue = _a.innerValue, handleChangeYear = _a.handleChangeYear, range = _a.range;
    var _b = useState([]), years = _b[0], setYears = _b[1];
    var calendarYears = useRef();
    var selectedYear = useRef();
    useEffect(function () {
        if (selectedYear.current && calendarYears.current) {
            var offsetTop = selectedYear.current.offsetTop;
            calendarYears.current.scrollTo({ top: offsetTop - 96 });
        }
    }, [selectedYear.current, calendarYears.current]);
    useEffect(function () {
        var years = [];
        var start = moment(range.startDate).year();
        var end = moment(range.endDate).year();
        for (var year = end; year >= start; year--) {
            years.push(year);
        }
        setYears(years);
    }, [range.startDate, range.endDate]);
    var isSelected = function (year) {
        return innerValue.year() === year;
    };
    return (_jsx("div", __assign({ ref: calendarYears, className: styles.CalendarYears }, { children: years.map(function (year) { return (_jsx("div", __assign({ className: clsx(styles.CalendarYear, isSelected(year) && styles.CalendarYearSelected), ref: isSelected(year) ? selectedYear : null, onClick: function () { return handleChangeYear(year); } }, { children: _jsx("span", __assign({ className: styles.CalendarYearText }, { children: year })) }), year)); }) })));
};
