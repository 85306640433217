var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import Trash from '@core/shared/svg/trash';
import styles from './index.module.sass';
var ButtonContact = function (_a) {
    var handleClick = _a.handleClick, handleDelete = _a.handleDelete, loading = _a.loading, children = _a.children, className = _a.className, _b = _a.add, add = _b === void 0 ? false : _b, 
    // disable = false,
    isDeleting = _a.isDeleting, deleteClassName = _a.deleteClassName;
    return (_jsxs("div", __assign({ className: clsx(styles.buttonContact, className, add && styles.buttonContactAdd), onClick: handleClick }, { children: [_jsx("div", __assign({ className: styles.buttonContactName }, { children: children })), isDeleting &&
                (loading ? (_jsx("div", __assign({ className: clsx(styles.buttonContactDelete, deleteClassName) }, { children: _jsx("div", { className: clsx(styles.bubbleLoader, styles.buttonContactLoader) }) }))) : (_jsx("div", __assign({ className: clsx(styles.buttonContactDelete, deleteClassName), onClick: handleDelete }, { children: _jsx(Trash, {}) }))))] })));
};
export default ButtonContact;
