var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { t } from 'i18next';
import { config } from '@core/config/config';
import TokenService from '@core/services/tokens';
import { QSURE_REDIRECT_KEY } from '@core/shared/constants/common';
import { changeAppScreenToShop, isReactNativeWebView } from '@core/shared/utils/webViewUtils';
import { useProceedToLabq } from '@root/src/hooks/useProceedToLabq';
import { FamilyAccessCard } from './components/famiyAccess';
import { SmallCard } from './components/smallCard';
import { TestingSpecials } from './components/testingSpecials';
import styles from './index.module.sass';
export var Resources = function () {
    var handleProceedToLabq = useProceedToLabq().handleProceedToLabq;
    var handleSeasonTestClick = function () {
        if (isReactNativeWebView) {
            handleProceedToLabq({
                callback: changeAppScreenToShop,
            });
            return;
        }
        var refreshToken = TokenService.getRefreshToken();
        var url = "".concat(config.href).concat(QSURE_REDIRECT_KEY, "/shop/all/?refresh_token=").concat(refreshToken);
        handleProceedToLabq({ url: url });
    };
    var handleCoachClick = function () {
        // TODO
    };
    var handleCheckupClick = function () {
        // TODO
    };
    var handleSupportClick = function () {
        // TODO
    };
    return (_jsxs("div", __assign({ className: styles.testing }, { children: [_jsx("div", __assign({ className: styles.title }, { children: t('label_resources') })), _jsx("div", __assign({ className: styles.description }, { children: t('label_resources_description') })), _jsxs("div", __assign({ className: styles.content }, { children: [_jsx(TestingSpecials, { onClick: handleSeasonTestClick, classNames: styles.seasonTestCard }), _jsx(SmallCard, { type: "coach", onClick: handleCoachClick, classNames: styles.coach }), _jsx(SmallCard, { type: "checkup", onClick: handleCheckupClick, classNames: styles.checkup }), _jsx(SmallCard, { type: "support", onClick: handleSupportClick, classNames: styles.support }), _jsx(FamilyAccessCard, { classNames: styles.family })] }))] })));
};
