var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import ArrowLeft from '@core/shared/svg/arrow-left';
import styles from './index.module.sass';
export var PageTitle = function (_a) {
    var text = _a.text, hasBackButton = _a.hasBackButton, onClick = _a.onClick, className = _a.className;
    var navigate = useNavigate();
    var handleBackClick = function () {
        if (onClick && typeof onClick === 'function') {
            onClick();
        }
        else {
            navigate(-1);
        }
    };
    return (_jsxs("div", __assign({ className: clsx(styles.container, !!className && className) }, { children: [hasBackButton && (_jsx("button", __assign({ className: styles.buttonBack, onClick: handleBackClick }, { children: _jsx(ArrowLeft, {}) }))), _jsx("h1", __assign({ className: styles.title }, { children: text }))] })));
};
