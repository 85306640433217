var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AnsweredQuestion } from '@core/pages/forms/answered/answeredQuestion';
import { hasTag } from '@core/shared/utils/surveyUtils';
import { handleSurveyQuestionEdit } from '@core/store/reducers/surveySlice';
export var AnsweredStep = function (_a) {
    var answer = _a.answer, step = _a.step, disableEdit = _a.disableEdit;
    var dispatch = useDispatch();
    var _b = useMemo(function () {
        var children = step.children.filter(function (child) { return child.parents.indexOf(answer.step_id) !== -1; });
        var questions = children.filter(function (child) {
            return child.parents.indexOf(answer.step_id) === child.parents.length - 1 &&
                child.variation === 'question';
        });
        return { children: children, questions: questions };
    }, [answer.step_id, step.children]), children = _b.children, questions = _b.questions;
    var handleEdit = function () {
        dispatch(handleSurveyQuestionEdit({ stepId: step.step._id }));
    };
    return (_jsx(_Fragment, { children: questions.map(function (question) {
            if (hasTag(question, 'hideInAnswers') || hasTag(question, 'hide')) {
                return null;
            }
            return (_jsx(AnsweredQuestion, __assign({ self: question, answer: answer, handleEdit: handleEdit, disableEdit: disableEdit }, { children: children }), question._id));
        }) }));
};
