var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { showModal } from '@core/components/modals';
import { SimpleConfirmPopup } from '@core/components/modals/simpleConfirmPopup';
import { PatientCard } from '@core/components/patientCard';
import { PlusItem } from '@core/components/plusItem';
import AddFamily from '@core/pages/forms/forms/addFamily';
import { AsyncStatus } from '@core/shared/constants/loaderTypes';
import { getSubscriptions } from '@core/store/reducers/profileSlice';
import { setSelectedProducts } from '@core/store/reducers/subscriptionSlice';
import { CurrentPlanCard } from '../currentPlanCard';
import { PlanCard } from '../planCard';
import styles from './index.module.sass';
export var PlanSettings = function () {
    var nav = useNavigate();
    var dispatch = useDispatch();
    var location = useLocation();
    var self = useSelector(function (s) { return s.booking.person; });
    var _a = useSelector(function (s) { return s.profile; }), family = _a.family, profileSelf = _a.self;
    var _b = useSelector(function (s) { return s.subscriptions; }), subscriptionProducts = _b.subscriptionProducts, createSubscriptionStat = _b.createSubscriptionStat;
    var activeSubscriptions = useMemo(function () {
        return profileSelf === null || profileSelf === void 0 ? void 0 : profileSelf.active_subscriptions.filter(function (s) { return s.status === 'active'; });
    }, [profileSelf === null || profileSelf === void 0 ? void 0 : profileSelf.active_subscriptions]);
    var _c = useState(null), changePlanIdLoading = _c[0], setChangePlanIdLoading = _c[1];
    var allPatients = __spreadArray([], family, true);
    if (self) {
        allPatients.unshift(self);
    }
    var handleCancelPlanClick = function (productId) {
        var closeModal = showModal(SimpleConfirmPopup, {
            title: t('label_cancel_subscription_title'),
            primaryButtonLabel: t('label_cancel_subscription'),
            secondaryButtonLabel: t('label_change_my_mind'),
            isTextCentered: true,
            isBottomMobile: true,
            isDanger: true,
            primaryButtonHandler: function () {
                console.log('cancel plan click', productId);
                closeModal();
            },
            secondaryButtonHandler: function () { return closeModal(); },
        }).closeModal;
        console.log('cancel plan click', productId);
    };
    // const handleComparePlanClick = () => {
    //   nav(routePaths.CONSUMERS, { state: 'toTable' })
    // }
    useEffect(function () {
        if (createSubscriptionStat === AsyncStatus.SUCCESS) {
            setChangePlanIdLoading(null);
            dispatch(getSubscriptions(self.user_id));
        }
        if (createSubscriptionStat === AsyncStatus.FAIL) {
            setChangePlanIdLoading(null);
        }
    }, [createSubscriptionStat, dispatch, self.user_id]);
    useEffect(function () {
        var isAddNewPatient = routePaths.MANAGE_PLAN_NEW_PATIENT === location.pathname;
        if (isAddNewPatient) {
            showModal(AddFamily, {
                type: 'clean',
                showCloseIcon: false,
            });
        }
    }, [location.pathname]);
    var handleChangePlanClick = function (sub) {
        var _a;
        setChangePlanIdLoading(sub._id);
        var selectedProducts = subscriptionProducts.filter(function (product) {
            return (product.group === sub.group &&
                product.name.replace(' Family', '') === sub.name.replace(' Family', ''));
        });
        dispatch(setSelectedProducts(selectedProducts));
        nav("".concat(routePaths.CHECKOUT, "?id=").concat(sub._id, "&interval=").concat((_a = sub === null || sub === void 0 ? void 0 : sub.prices[0]) === null || _a === void 0 ? void 0 : _a.interval));
    };
    var handleAddPatient = function () {
        nav(routePaths.MANAGE_PLAN_NEW_PATIENT);
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [activeSubscriptions.length > 0 &&
                activeSubscriptions.map(function (sub) { return (_jsx(CurrentPlanCard, { currentPlan: sub, onCancelPlan: function () { return handleCancelPlanClick(sub._id); } }, sub._id)); }), _jsxs("div", __assign({ className: styles.familyAccess }, { children: [_jsx("div", __assign({ className: styles.familyAccessTitle }, { children: t('label_family_access') })), _jsxs("div", __assign({ className: styles.familyAccessPatients }, { children: [allPatients.length > 0 &&
                                allPatients.map(function (patient) { return (_jsx(PatientCard, { patient: patient, isActive: false, classNames: styles.familyAccessCard, classNameTitle: styles.familyAccessCardTitle, classNameDetails: styles.familyAccessCardDetails }, patient === null || patient === void 0 ? void 0 : patient._id)); }), _jsx(PlusItem, { title: t('label_add_family_member'), onClick: handleAddPatient, className: styles.familyAccessCard, classNameTitle: styles.familyAccessAddCardTitle })] }))] })), _jsxs("div", __assign({ className: styles.changePlan }, { children: [_jsx("div", __assign({ className: styles.changePlanHead }, { children: _jsx("div", __assign({ className: styles.changePlanHeadTitle }, { children: t('label_change_plan') })) })), _jsx("div", __assign({ className: styles.plans }, { children: subscriptionProducts.map(function (sub) { return (_jsx(PlanCard, { subscriptionProduct: sub, price: sub.prices[0].amount.value, isActive: activeSubscriptions.map(function (s) { return s === null || s === void 0 ? void 0 : s.subscription_product_id; }).includes(sub._id), isLoading: sub._id === changePlanIdLoading, onClick: function () { return handleChangePlanClick(sub); }, currentSubscription: (activeSubscriptions === null || activeSubscriptions === void 0 ? void 0 : activeSubscriptions.length) ? activeSubscriptions[0] : null }, sub._id)); }) }))] }))] })));
};
