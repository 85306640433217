export var capitalizeFirstLetter = function (str) {
    var strArr = str.split('');
    var strArrWithCap = strArr.map(function (e, i) { return (i === 0 ? e.toUpperCase() : e); });
    return strArrWithCap.join('');
};
export var capitalizeSentence = function (str) {
    var strArr = str.split(' ');
    var strArrWithCap = strArr.map(function (e) { return capitalizeFirstLetter(e); });
    return strArrWithCap.join(' ');
};
export var decodePhoneNumber = function (phoneNumber) {
    if (!phoneNumber)
        return '';
    var returnedNumber = '';
    var table = {
        2: 'abc',
        3: 'def',
        4: 'ghi',
        5: 'jkl',
        6: 'mno',
        7: 'pqrs',
        8: 'tuv',
        9: 'wxyz',
    };
    //TODO: it's need to refactoring
    if (!phoneNumber.startsWith('+')) {
        returnedNumber = '+1';
    }
    phoneNumber.split('').forEach(function (symbol) {
        if (symbol !== ' ') {
            if (!isNaN(Number(symbol)) || symbol === '+') {
                return (returnedNumber = returnedNumber + symbol);
            }
            else {
                Object.keys(table).forEach(function (key) {
                    if (table[key].toLowerCase().includes(symbol.toLowerCase())) {
                        return (returnedNumber = returnedNumber + key);
                    }
                });
            }
        }
    });
    return returnedNumber;
};
export var removeParamsFromUrl = function (url) {
    if (!(url === null || url === void 0 ? void 0 : url.length))
        return url;
    return url.split('/:')[0];
};
export var camelToSnakeCase = function (str) {
    return str.replace(/[A-Z]/g, function (letter) { return "_".concat(letter.toLowerCase()); });
};
export var snakeToCamelCase = function (str) {
    return str.replace(/([-_][a-z])/g, function (group) {
        if (str.indexOf(group) === 0) {
            return group.replace('-', '').replace('_', '');
        }
        else {
            return group.toUpperCase().replace('-', '').replace('_', '');
        }
    });
};
var isPlainObject = function (arg) {
    return Object.prototype.toString.call(arg) === '[object Object]';
};
export function changeDataKeysCase(data, type) {
    if (Array.isArray(data)) {
        return data.map(function (item) { return changeDataKeysCase(item, type); });
    }
    if (isPlainObject(data)) {
        return Object.entries(data).reduce(function (acc, _a) {
            var key = _a[0], value = _a[1];
            var newKey = type === 'snakeCase' ? camelToSnakeCase(key) : snakeToCamelCase(key);
            acc[newKey] = changeDataKeysCase(value, type);
            return acc;
        }, {});
    }
    return data;
}
