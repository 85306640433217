var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import Trash from '@core/shared/svg/trash';
import { setAlert } from '@core/store/reducers/alertSlice';
import Plus from '../../../shared/svg/plus';
import { Button } from '../../buttons/button';
import styles from './index.module.sass';
var mstp = function (state) { return ({
    media: state.survey.media,
}); };
export var InputFile = forwardRef(function (_a, ref) {
    var _b, _c;
    var name = _a.name, title = _a.title, children = _a.children, handleChange = _a.handleChange, className = _a.className, type = _a.type, register = _a.register, watch = _a.watch, disabled = _a.disabled, loading = _a.loading, setValue = _a.setValue, startLink = _a.startLink, _d = _a.mediaTypes, mediaTypes = _d === void 0 ? [] : _d, error = _a.error, fieldClassName = _a.fieldClassName, innerTitle = _a.innerTitle, _e = _a.deleteIconStyle, deleteIconStyle = _e === void 0 ? 'cross' : _e, deleteIconClassName = _a.deleteIconClassName;
    var inputFile = useRef();
    var media = useSelector(mstp).media;
    var _f = useState(''), startValue = _f[0], setStartValue = _f[1];
    var dispatch = useDispatch();
    var _g = useState(false), load = _g[0], setLoad = _g[1];
    var _h = useForm(), innerWatch = _h.watch, innerRegister = _h.register, innerSetValue = _h.setValue;
    var reg = register
        ? register(name, { onChange: function (e) { return handleChange(e, handleReset); } })
        : innerRegister(name, { onChange: function (e) { return handleChange(e, handleReset); } });
    var innerFile = watch ? (_b = watch(name)) === null || _b === void 0 ? void 0 : _b[0] : (_c = innerWatch(name)) === null || _c === void 0 ? void 0 : _c[0];
    var handleClick = function (e) {
        e.stopPropagation();
        if (disabled || innerFile || showStartValue)
            return;
        inputFile.current.click();
    };
    useEffect(function () {
        if (!!startLink && typeof startLink === 'string') {
            var value = media.find(function (mediaEl) { return mediaEl.slot_id === startLink; });
            setStartValue(value.url);
        }
        else {
            startValue && setStartValue('');
        }
    }, [startLink]);
    var handleReset = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            e === null || e === void 0 ? void 0 : e.stopPropagation();
            setValue ? setValue(name, '') : innerSetValue(name, '');
            handleChange(null, handleReset);
            return [2 /*return*/];
        });
    }); };
    var isValidFileType = function (event) {
        if (mediaTypes) {
            if (mediaTypes.includes(event.target.files[0].type)) {
                return true;
            }
            else {
                dispatch(setAlert("".concat(t('error_supported_format'))));
                return false;
            }
        }
    };
    //because of watch interface return string we need to use any type :((
    var getImgSRC = function (file) { return URL.createObjectURL(file); };
    var showStartValue = startValue && !innerFile;
    return (_jsxs("div", __assign({ className: clsx(styles.inputFile, className, (disabled || innerFile || showStartValue) && styles.inputFileDisabled) }, { children: [(innerFile || showStartValue) &&
                !disabled &&
                (deleteIconStyle === 'cross' ? (_jsx("div", __assign({ onClick: handleReset, className: clsx(styles.inputFileResetIcon, deleteIconClassName) }, { children: _jsx(Plus, {}) }))) : (_jsx("div", __assign({ onClick: handleReset, className: clsx(styles.inputFileResetIconTrash, deleteIconClassName) }, { children: _jsx(Trash, {}) })))), _jsx("input", __assign({}, reg, { ref: function (e) {
                    inputFile.current = e;
                    reg.ref(e);
                    if (ref)
                        ref.current = e;
                }, onChange: function (event) {
                    isValidFileType(event) && reg.onChange(event);
                }, type: "file", name: name, accept: mediaTypes === null || mediaTypes === void 0 ? void 0 : mediaTypes.join(',') })), _jsxs("label", __assign({ onClick: handleClick }, { children: [type === 'imageField' && (_jsxs(_Fragment, { children: [title && _jsx("span", __assign({ className: styles.inputFilePickerTitle }, { children: title })), _jsxs("div", __assign({ className: clsx(styles.inputFilePickerField, fieldClassName) }, { children: [!load && (showStartValue || innerFile) && (_jsx("div", __assign({ className: styles.bubbleLoaderWrapper }, { children: _jsx("div", { className: styles.bubbleLoader }) }))), showStartValue ? (_jsx("img", { src: startValue, alt: "", onLoad: function () { return setLoad(true); } })) : innerFile ? (_jsx("img", { src: getImgSRC(innerFile), alt: "", onLoad: function () { return setLoad(true); } })) : innerTitle ? (_jsxs("div", __assign({ className: styles.inputFileWithInnerTitle }, { children: [_jsx("div", __assign({ className: styles.innerTitle }, { children: innerTitle })), _jsx(Plus, {})] }))) : (_jsx(Plus, {}))] }))] })), type === 'button' && (_jsx(Button, { loading: loading, disabled: disabled, classes: [styles.registrationBtn], primary: true, label: title })), children, error && _jsx("span", __assign({ className: styles.inputFilePickerError }, { children: error }))] }))] })));
});
InputFile.displayName = 'InputFile';
