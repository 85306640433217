var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import doc from '@core/shared/images/gif/docs.gif';
import upload from '@core/shared/images/gif/upload.gif';
import CheckMark from '@core/shared/svg/checkMark';
import Close from '@core/shared/svg/close';
import { hasTag } from '@core/shared/utils/surveyUtils';
import { clearRetry, setPause, setValidateInsurance } from '@core/store/reducers/surveySlice';
import styles from './index.module.sass';
var Step = function (_a) {
    var text1 = _a.text1, text2 = _a.text2, text3 = _a.text3, img = _a.img, buttons = _a.buttons, extendedClass = _a.extendedClass;
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: clsx(styles.validateInsuranceImg, extendedClass) }, { children: img })), _jsxs("div", __assign({ className: styles.validateInsuranceTexts }, { children: [_jsx("p", __assign({ className: styles.validateInsuranceText }, { children: text1 })), _jsx("p", __assign({ className: styles.validateInsuranceText }, { children: text2 })), text3 && _jsx("p", __assign({ className: styles.validateInsuranceText }, { children: text3 }))] })), buttons && _jsx("div", __assign({ className: styles.validateInsuranceButtons }, { children: buttons }))] }));
};
var ValidateInsurance = function (_a) {
    var closeModal = _a.closeModal, onButtonClick = _a.onButtonClick, buttons = _a.buttons, type = _a.type;
    var _b = useSelector(function (state) { return state; }), _c = _b.survey, validateInsuranceSurvey = _c.validateInsurance, retryCounter = _c.retryCounter, validateInsuranceDocuments = _b.docs.validateInsurance;
    var validateInsurance = useMemo(function () {
        if (type === 'docs') {
            return validateInsuranceDocuments;
        }
        if (type === 'forms') {
            return validateInsuranceSurvey;
        }
    }, [type, validateInsuranceDocuments, validateInsuranceSurvey]);
    var dispatch = useDispatch();
    var _d = useState(0), longWaitPosition = _d[0], setLongWaitPosition = _d[1];
    var interval = useRef();
    var handleClose = function () {
        dispatch(setValidateInsurance(null));
        dispatch(clearRetry());
        closeModal();
    };
    useEffect(function () {
        if (!validateInsurance) {
            handleClose();
        }
    }, [validateInsurance]);
    useEffect(function () {
        interval.current = setInterval(function () {
            setLongWaitPosition(function (prev) {
                if (prev === 2) {
                    clearInterval(interval.current);
                    return prev;
                }
                return prev + 1;
            });
        }, 15000);
        return function () {
            clearInterval(interval.current);
        };
    }, []);
    var renderFailedButtons = function (_a) {
        var showTryAgain = _a.showTryAgain;
        if (type === 'forms') {
            return __spreadArray(__spreadArray([], buttons.map(function (button) {
                var hideOnResubmit = hasTag(button, 'hide_on_resubmit');
                var secondary = hasTag(button, 'secondary') || hideOnResubmit;
                if ((!showTryAgain && hideOnResubmit) ||
                    (hideOnResubmit && retryCounter >= 2) ||
                    hasTag(button, 'hideForCollector')) {
                    return null;
                }
                return (_jsx(Button, { label: hideOnResubmit ? t('btn_try_again') : t('btn_continue_anyway'), primary: !secondary, handler: function () {
                        if (hasTag(button, 'forceInsurance')) {
                            handleClose();
                        }
                        onButtonClick(button, false, hasTag(button, 'forceInsurance'));
                        closeModal();
                    } }, button._id));
            }), true), [
                _jsx(Button, { label: t('btn_change_insurance'), handler: handleClose }, 1),
            ], false);
        }
        if (type === 'docs') {
            return __spreadArray([
                _jsx(Button, { primary: true, label: t('btn_continue_anyway'), handler: handleClose }, 999)
            ], buttons.map(function (button, index) { return (_jsx(Button, { label: button.label, handler: function () {
                    handleClose();
                    button.action();
                } }, index)); }), true);
        }
    };
    var steps = {
        loading: (_jsx(Step, { text1: "\n          ".concat(longWaitPosition === 0 ? t('validate_insurance_text_validating') : '', "\n          ").concat(longWaitPosition === 1 ? t('validate_insurance_text_still_validating') : '', "\n          ").concat(longWaitPosition === 2 ? t('validate_insurance_text_still_validating2') : '', "\n        "), text2: "\n          ".concat(longWaitPosition === 0 ? t('validate_insurance_text_please_be_patient') : '', "\n          ").concat(longWaitPosition === 1 ? t('validate_insurance_text_still_validating3') : '', "\n          ").concat(longWaitPosition === 2 ? t('validate_insurance_text_still_validating4') : '', "\n        "), text3: "\n          ".concat(longWaitPosition === 0 ? t('validate_insurance_text_approximately') : '', "\n          ").concat(longWaitPosition === 1 ? t('validate_insurance_text_approximately2') : '', "\n          ").concat(longWaitPosition === 2 ? t('validate_insurance_text_approximately3') : '', "\n        "), img: _jsx("img", { src: doc, alt: "Doc" }) })),
        creating: (_jsx(Step, { text1: t('validate_insurance_text_uploading'), text2: t('validate_insurance_text_please_be_patient'), img: _jsx("img", { src: upload, alt: "Upload" }) })),
        success: (_jsx(Step, { text1: t('validate_insurance_text_check_complete'), text2: t('validate_insurance_text_thanks_for_patience'), img: _jsx(CheckMark, {}), buttons: [
                _jsx(Button, { label: t('btn_continue'), primary: true, handler: function () {
                        dispatch(setPause(false));
                        closeModal();
                    } }, 1),
            ], extendedClass: styles.validateInsuranceImgSuccess })),
        successUpload: (_jsx(Step, { text1: t('validate_insurance_text_successfully_uploaded'), text2: t('validate_insurance_text_thanks_for_patience'), img: _jsx(CheckMark, {}), buttons: [
                _jsx(Button, { label: t('btn_continue'), primary: true, handler: function () {
                        dispatch(setPause(false));
                        closeModal();
                    } }, 1),
            ], extendedClass: styles.validateInsuranceImgSuccess })),
        failed: (_jsx(Step, { text1: t('validate_insurance_text_filed1'), text2: t('validate_insurance_text_filed_continue'), text3: t('validate_insurance_text_thanks_for_patience'), img: _jsx(Close, {}), buttons: renderFailedButtons({ showTryAgain: false }), extendedClass: styles.validateInsuranceImgError })),
        acceptedError: (_jsx(Step, { text1: t('validate_insurance_text_filed1'), text2: retryCounter < 2
                ? t('validate_insurance_text_filed_continue_retry')
                : t('validate_insurance_text_filed_continue'), text3: t('validate_insurance_text_thanks_for_patience'), img: _jsx(Close, {}), buttons: renderFailedButtons({ showTryAgain: true }), extendedClass: styles.validateInsuranceImgError })),
    };
    return _jsx("div", __assign({ className: styles.validateInsurance }, { children: steps[validateInsurance] }));
};
export default ValidateInsurance;
