var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import styles from './index.module.sass';
var DeletedSuccessfully = function () {
    return (_jsxs("div", __assign({ className: styles.deletedSuccessfully }, { children: [_jsx("h2", __assign({ className: styles.title }, { children: t('delete_account_successfully_title') })), _jsxs("p", __assign({ className: styles.description }, { children: [t('delete_account_description'), _jsxs("a", __assign({ target: "_blank", href: "https://labq.com/privacy-policy/", rel: "noreferrer" }, { children: [' ', "Privacy Policy."] }))] })), _jsxs("p", __assign({ className: styles.description }, { children: [t('delete_acount_include_phone_description'), _jsx("a", __assign({ href: "tel:+17185345227" }, { children: " (718) 534-5227" })), t('delete_acount_include_support_description'), _jsxs("a", __assign({ target: "_blank", href: "http://labq.com/", rel: "noreferrer" }, { children: [' ', "LabQ.com."] }))] })), _jsx(Button, { classes: [styles.button], primary: true, label: t('btn_logout'), handler: function () { return (window.location.href = '/logout'); } })] })));
};
export default DeletedSuccessfully;
