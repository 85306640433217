var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var ArrowRight = function () { return (_jsx("svg", __assign({ className: "arrowIcon", width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.5858 19.5858C10.1953 19.9763 10.1953 20.6095 10.5858 21C10.9763 21.3905 11.6095 21.3905 12 21L18.7071 14.2929L12 7.5858C11.6095 7.19528 10.9763 7.19528 10.5858 7.5858C10.1953 7.97633 10.1953 8.60949 10.5858 9.00001L15.8787 14.2929L10.5858 19.5858Z", fill: "black" }) }))); };
export default ArrowRight;
