var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { HeaderButton } from '@core/components/buttons/headerButton';
import { showModal } from '@core/components/modals';
import { Policy } from '@core/pages/statics/policy';
import { Questions } from '@core/pages/statics/questions';
import { Terms } from '@core/pages/statics/terms';
import Close from '@core/shared/svg/close';
import styles from './index.module.sass';
export var Auth = function (_a) {
    var children = _a.children;
    var location = useLocation();
    var nav = useNavigate();
    useEffect(function () {
        var tos = location.hash.includes('#tos');
        var pp = location.hash.includes('#pp');
        var qa = location.hash.includes('#qa');
        var modal = null;
        if (tos)
            modal = Terms;
        if (pp)
            modal = Policy;
        if (qa)
            modal = Questions;
        if (modal !== null) {
            showModal(modal, { type: 'fullPage' });
        }
    }, [location]);
    var handleBackButtonClick = function () {
        localStorage.removeItem('booking');
        nav(routePaths.LANDING_HOME);
    };
    return (_jsxs("div", __assign({ className: styles.mainLayout }, { children: [_jsx("div", __assign({ className: styles.loginWarning }, { children: t('notification_please_log_in') })), _jsx("div", __assign({ className: styles.buttonWrapper }, { children: _jsx(HeaderButton, { Icon: _jsx(Close, {}), handler: handleBackButtonClick }) })), children ? children : _jsx(Outlet, {})] })));
};
