var TokensService = /** @class */ (function () {
    function TokensService() {
        this.type = process.env.REACT_APP_TYPE;
    }
    TokensService.prototype.setToken = function (tokenObj, isSessionToken) {
        if (isSessionToken === void 0) { isSessionToken = false; }
        var now = new Date();
        localStorage.setItem("access_token_".concat(this.type), tokenObj.access_token);
        localStorage.setItem("refresh_token_".concat(this.type), tokenObj.refresh_token);
        localStorage.setItem("access_token_ttl_".concat(this.type), String(tokenObj.access_token_ttl));
        localStorage.setItem("refresh_token_createTime_".concat(this.type), String(Number(now)));
        if (isSessionToken) {
            localStorage.setItem('isSessionToken', '1');
        }
        else {
            localStorage.removeItem('isSessionToken');
        }
    };
    TokensService.prototype.getAccessToken = function () {
        return localStorage.getItem("access_token_".concat(this.type));
    };
    TokensService.prototype.getRefreshToken = function () {
        return localStorage.getItem("refresh_token_".concat(this.type));
    };
    TokensService.prototype.getTokens = function () {
        return {
            refreshToken: this.getRefreshToken(),
            accessToken: this.getAccessToken(),
        };
    };
    TokensService.prototype.checkAccessToken = function () {
        var ttl = localStorage.getItem("access_token_ttl_".concat(this.type));
        var createTime = localStorage.getItem("refresh_token_createTime_".concat(this.type));
        var now = new Date();
        return (Number(now) - Number(createTime)) / 1000 / 60 < Number(ttl) - 0.15;
    };
    TokensService.prototype.clearToken = function () {
        localStorage.removeItem("access_token_".concat(this.type));
        localStorage.removeItem("refresh_token_".concat(this.type));
        localStorage.removeItem("access_token_ttl_".concat(this.type));
        localStorage.removeItem("refresh_token_createTime_".concat(this.type));
    };
    return TokensService;
}());
var service = new TokensService();
export default service;
