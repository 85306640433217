import { t } from 'i18next';
import GoldPlan from '@core/shared/svg/qsure/goldPlan';
import GoldPlanBorder from '@core/shared/svg/qsure/goldPlanBorder';
import PlatinumPlan from '@core/shared/svg/qsure/platinumPlan';
import DiamondPlan from '@core/shared/svg/qsure/platinumPlan';
import SilverPlan from '@core/shared/svg/qsure/silverPlan';
export var checkSubscriptionDetail = function (data) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var isSilver = (_b = (_a = data === null || data === void 0 ? void 0 : data.subscription) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes('silver');
    var isGold = (_d = (_c = data === null || data === void 0 ? void 0 : data.subscription) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes('gold');
    var isPlatinum = (_f = (_e = data === null || data === void 0 ? void 0 : data.subscription) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes('platinum');
    var isDiamond = (_h = (_g = data === null || data === void 0 ? void 0 : data.subscription) === null || _g === void 0 ? void 0 : _g.name) === null || _h === void 0 ? void 0 : _h.toLowerCase().includes('diamond');
    var type = '';
    var icon = null;
    if (isSilver) {
        type = 'silver';
        icon = SilverPlan;
    }
    if (isGold) {
        type = 'gold';
        icon = (data === null || data === void 0 ? void 0 : data.iconWithBorder) ? GoldPlanBorder : GoldPlan;
    }
    if (isPlatinum) {
        type = 'platinum';
        icon = PlatinumPlan;
    }
    if (isDiamond) {
        type = 'diamond';
        icon = DiamondPlan;
    }
    return {
        id: (_j = data === null || data === void 0 ? void 0 : data.subscription) === null || _j === void 0 ? void 0 : _j._id,
        name: (_k = data === null || data === void 0 ? void 0 : data.subscription) === null || _k === void 0 ? void 0 : _k.name,
        displayName: ((_l = data === null || data === void 0 ? void 0 : data.subscription) === null || _l === void 0 ? void 0 : _l.includes_family)
            ? t("label_qsure_".concat(type, "_family_plan"))
            : t("label_qsure_".concat(type, "_plan")),
        type: type,
        icon: icon,
        isSilver: isSilver,
        isGold: isGold,
        isPlatinum: isPlatinum,
        isDiamond: isDiamond,
        includesFamily: (_m = data.subscription) === null || _m === void 0 ? void 0 : _m.includes_family,
    };
};
export var formatPlanDate = function (planDate) {
    return new Date(planDate).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
    });
};
export var findActiveSubscription = function (subscriptions) {
    if (!(subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.length))
        return null;
    return subscriptions.find(function (s) { return s.status === 'active'; });
};
export var ERRORS = {
    expired_card: 'Your card has expired',
    processing_error: 'An error occurred while processing your card. Try again in a little bit.',
    incorrect_cvc: "Your card's security code is incorrect.",
};
export var DeclineERRORS = {
    generic_decline: 'Your card was declined',
    insufficient_funds: 'Your card has insufficient funds.',
    lost_card: 'Your card was lost',
    stolen_card: 'Your card was stolen',
    card_velocity_exceeded: 'Your card was declined for making repeated attempts too frequently or exceeding its amount limit.',
};
