var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { AsyncStatus } from '@core/shared/constants/loaderTypes';
import { checkIsHolder } from '@core/shared/utils/profileUtils';
import { getAvatar, } from '@core/store/reducers/profileSlice';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './index.module.sass';
export var Avatar = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName, description = _a.description, image = _a.image, _b = _a.direction, direction = _b === void 0 ? 'row' : _b, className = _a.className, classNameImage = _a.classNameImage, patient = _a.patient, _c = _a.needLoadAvatar, needLoadAvatar = _c === void 0 ? false : _c, _d = _a.showName, showName = _d === void 0 ? true : _d, _e = _a.isSubscription, isSubscription = _e === void 0 ? false : _e, _f = _a.avatarSize, avatarSize = _f === void 0 ? 'medium' : _f;
    var dispatch = useDispatch();
    var _g = useSelector(function (state) { return state.profile; }), statuses = _g.statuses, avatars = _g.avatars, avatarLoadingId = _g.avatarLoadingId;
    var _h = useState(image), imgUrl = _h[0], setImgUrl = _h[1];
    var _j = useState(false), isLoading = _j[0], setLoading = _j[1];
    var _k = useState(false), imgLoading = _k[0], setImgLoading = _k[1];
    var patientId = checkIsHolder(patient) ? patient === null || patient === void 0 ? void 0 : patient.id : patient === null || patient === void 0 ? void 0 : patient._id;
    var generateSrcset = function (avatarSizes) {
        var needAvatarArr = avatarSizes.find(function (arr) { return arr.name === avatarSize; });
        if (!needAvatarArr)
            return '';
        return needAvatarArr.sizes
            .map(function (size) {
            return "".concat(size.url, " ").concat(size.scale);
        })
            .join(', ');
    };
    var _l = useState(avatars[patientId] ? generateSrcset(avatars[patientId]) : ''), srcSet = _l[0], setSrcSet = _l[1];
    var skeletonSize = avatarSize === 'small' ? '28px' : avatarSize === 'medium' ? '70px' : '100px';
    var setAvatarCallback = function (data) {
        var _a, _b;
        var avatarData = data.find(function (d) { return d.name === 'large'; });
        if (avatarData && avatarData.sizes) {
            var defaultSrc = ((_a = avatarData.sizes.find(function (size) { return size.scale === 'x1'; })) === null || _a === void 0 ? void 0 : _a.url) || ((_b = avatarData.sizes[0]) === null || _b === void 0 ? void 0 : _b.url);
            setImgUrl(defaultSrc);
            setSrcSet(generateSrcset(avatarData.sizes));
        }
    };
    var loadAvatar = function (forceReload) {
        var _a;
        if (forceReload === void 0) { forceReload = false; }
        var confirmedAvatar = (_a = patient === null || patient === void 0 ? void 0 : patient.avatars) === null || _a === void 0 ? void 0 : _a.find(function (a) { return a.confirmed; });
        if (!confirmedAvatar)
            return;
        dispatch(getAvatar({
            patientId: patientId,
            callback: setAvatarCallback,
            forceReload: forceReload,
        }));
    };
    useEffect(function () {
        setImgUrl(image);
    }, [image]);
    useEffect(function () {
        if (needLoadAvatar && patient) {
            loadAvatar();
        }
    }, [patient, needLoadAvatar, avatars[patientId]]);
    useEffect(function () {
        if (avatars[patientId]) {
            var srcSet_1 = generateSrcset(avatars[patientId]);
            setSrcSet(srcSet_1);
        }
    }, [avatars[patientId]]);
    useEffect(function () {
        if (statuses.uploadAvatarStatus === AsyncStatus.SUCCESS && avatarLoadingId === patientId) {
            loadAvatar(true);
        }
    }, [statuses.uploadAvatarStatus, avatars[patientId]]);
    useEffect(function () {
        if (statuses.removeAvatarStatus === AsyncStatus.SUCCESS && !avatars[patientId]) {
            setImgUrl(null);
        }
    }, [statuses.removeAvatarStatus, avatars[patientId]]);
    useEffect(function () {
        var checkNeedLoading = (avatarLoadingId === patientId &&
            (statuses.getAvatarStatus === AsyncStatus.LOADING ||
                statuses.removeAvatarStatus === AsyncStatus.LOADING)) ||
            imgLoading;
        setLoading(checkNeedLoading);
    }, [statuses.getAvatarStatus, statuses.removeAvatarStatus, imgLoading]);
    useEffect(function () {
        if (imgUrl) {
            setImgLoading(true);
            var img = new Image();
            img.src = imgUrl;
            img.onload = function () { return setImgLoading(false); };
        }
    }, [imgUrl]);
    return (_jsxs("div", __assign({ className: clsx(styles.user, direction === 'column' && styles.userColumn, className) }, { children: [imgUrl &&
                (isLoading ? (_jsx("div", __assign({ className: clsx(styles.avatar, classNameImage, isLoading && styles.avatarLoading) }, { children: _jsx(Skeleton, { borderRadius: 48, height: skeletonSize, width: skeletonSize }) }))) : (_jsx("img", { src: imgUrl, srcSet: srcSet, className: clsx(styles.avatar, classNameImage, isSubscription && styles.avatarSubscription), alt: "".concat(firstName, " ").concat(lastName) }))), !imgUrl && (_jsxs("div", __assign({ className: clsx(styles.avatar, classNameImage) }, { children: [firstName === null || firstName === void 0 ? void 0 : firstName[0], lastName === null || lastName === void 0 ? void 0 : lastName[0]] }))), showName && (_jsxs("div", __assign({ className: clsx(styles.info, description && styles.infoWithDescription) }, { children: [_jsxs("div", __assign({ className: styles.name }, { children: [firstName, " ", lastName] })), description && _jsx("div", __assign({ className: styles.description }, { children: description }))] })))] })));
};
