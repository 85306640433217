var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { BaseWrapper } from '@core/components/baseWrapper';
import { PageTitle } from '@core/components/pageTitle';
import useWindowDimensions from '@core/shared/utils/widthHook';
import { NavMobile } from '@layouts/main/components/navMobile';
import styles from './index.module.sass';
export var Perks = function () {
    var isMobile = useWindowDimensions().isMobile;
    return (_jsx(BaseWrapper, __assign({ classNames: styles.wrapper }, { children: _jsxs(BaseWrapper, __assign({ classNames: styles.wrapperInner }, { children: [_jsx(PageTitle, { text: t('title_perks'), hasBackButton: true }), isMobile && _jsx(NavMobile, {})] })) })));
};
