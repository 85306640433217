var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { Button } from '@components/button';
import { LoginContacts } from '@components/landing/loginContacts';
import { LoginModal } from '@core/components/modals/loginModal';
import useWindowDimensions from '@core/shared/utils/widthHook';
import HeroLogo from '@root/src/shared/svg/heroLogo';
import LoginLogo from '@root/src/shared/svg/loginLogo';
import styles from './index.module.sass';
export var Login = function () {
    var navigate = useNavigate();
    var width = useWindowDimensions().width;
    return (_jsx("div", { children: width < 1100 ? (_jsxs("div", __assign({ className: styles.smallWrapper }, { children: [_jsx("div", __assign({ className: styles.wrapperLeftButtons }, { children: _jsx(Button, { label: t('label_landing_login_btn_back'), gray: true, leftIcon: true, withoutArrow: true, handler: function () { return navigate('/'); } }) })), _jsxs("div", __assign({ className: styles.smallWrapperLogo }, { children: [_jsx(HeroLogo, {}), _jsx("div", { children: t('label_landing_login_banner_title') })] })), _jsx("div", __assign({ className: styles.smallWrapperLogin }, { children: _jsx(LoginModal, { nav: navigate, isQsure: true, noLogo: true }) })), _jsx("div", __assign({ className: styles.smallWrapperCards }, { children: _jsx(LoginContacts, {}) }))] }))) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.wrapper }, { children: [_jsxs("div", __assign({ className: styles.container }, { children: [_jsxs("div", __assign({ className: styles.wrapperLeft }, { children: [_jsx("div", __assign({ className: styles.wrapperLeftButtons }, { children: _jsx(Button, { label: t('label_landing_login_btn_back'), gray: true, leftIcon: true, withoutArrow: true, handler: function () { return navigate('/'); } }) })), _jsxs("div", __assign({ className: styles.wrapperLeftContent }, { children: [_jsx("div", __assign({ className: styles.wrapperLeftTitle }, { children: t('label_landing_login_banner_title') })), _jsx("div", __assign({ className: styles.wrapperLeftText }, { children: t('label_landing_login_banner_text') }))] }))] })), _jsx("div", __assign({ className: styles.wrapperRight }, { children: _jsx("div", __assign({ className: styles.login }, { children: _jsx(LoginModal, { nav: navigate, isQsure: true }) })) }))] })), _jsx("div", __assign({ className: styles.logo }, { children: _jsx(LoginLogo, {}) }))] })), _jsx("div", __assign({ className: styles.container }, { children: _jsx(LoginContacts, {}) }))] })) }));
};
