var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { t } from 'i18next';
import { getFullName } from './bookingUtils';
export var getNameSurname = function (nameData) {
    var _a;
    var surname = nameData === null || nameData === void 0 ? void 0 : nameData.family;
    if (!surname)
        return '';
    var name = ((_a = nameData === null || nameData === void 0 ? void 0 : nameData.given) === null || _a === void 0 ? void 0 : _a.length) ? nameData.given[0] : null;
    return name ? "".concat(name, " ").concat(surname) : surname;
};
export var getNameMiddleSurname = function (nameData) {
    var _a, _b;
    var surname = nameData === null || nameData === void 0 ? void 0 : nameData.family;
    if (!surname)
        return '';
    var names;
    if (((_a = nameData === null || nameData === void 0 ? void 0 : nameData.given) === null || _a === void 0 ? void 0 : _a.length) === 2) {
        names = "".concat(nameData === null || nameData === void 0 ? void 0 : nameData.given[0], " ").concat(nameData === null || nameData === void 0 ? void 0 : nameData.given[1]);
    }
    else if (((_b = nameData === null || nameData === void 0 ? void 0 : nameData.given) === null || _b === void 0 ? void 0 : _b.length) === 1) {
        names = nameData === null || nameData === void 0 ? void 0 : nameData.given[0];
    }
    else {
        names = null;
    }
    return names ? "".concat(names, " ").concat(surname) : surname;
};
export var handleAddFamily = function (userId, navigate, backRoute) {
    navigate('/add-family', { state: { backRoute: backRoute, userId: userId } });
};
// Converts "Name Middle Surname" to "N M Surname"
export var getSurnameWithInitials = function (nameData) {
    var surname = nameData === null || nameData === void 0 ? void 0 : nameData.family;
    if (!surname)
        return '';
    var names = nameData === null || nameData === void 0 ? void 0 : nameData.given.map(function (n) { return n[0]; });
    return __spreadArray(__spreadArray([], names, true), [surname], false).join(' ');
};
export var displayGender = function (gender) {
    switch (gender) {
        case 'male':
            return t('documents_detail_male');
        case 'female':
            return t('documents_detail_female');
        case 'unknown':
            return t('documents_detail_unknown');
    }
};
export var filterPatients = function (patients, searchValue) {
    return patients.filter(function (patient) {
        var patientName = getFullName(patient).toLowerCase();
        var patientId = patient._id.toLowerCase();
        var searchString = searchValue.toLowerCase();
        return patientName.includes(searchString) || patientId.includes(searchString);
    });
};
