var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import AutocompleteFullscreen from '@core/components/formComponents/autocompleteFullscreen';
import { Checkbox } from '@core/components/formComponents/checkbox';
import { DatePicker } from '@core/components/formComponents/datePicker';
import { Draw } from '@core/components/formComponents/draw';
import { Input as ChatInput } from '@core/components/formComponents/input';
import { InputFile } from '@core/components/formComponents/InputFile';
import { PhoneInput } from '@core/components/formComponents/phoneInput';
import { Select } from '@core/components/formComponents/selects';
import { RulesMessage } from '@core/components/surveyMessages/rulesMessage';
import { TextMessage } from '@core/components/surveyMessages/textMessage';
import useRerender from '@core/shared/hooks/useRerender';
import { useStartValue } from '@core/shared/hooks/useStartValue';
import { EMAIL_PATTERN, getChildren, getContent, getCurrentSlot, hasTag, MESSAGE_ICONS, replaceContent, } from '@core/shared/utils/surveyUtils';
import styles from './index.module.sass';
var ALLOWED_SLOT_TAGS = [
    'disabled',
    'optionSlot',
    'phone',
    'email',
    'selectFullscreen',
    'buttonSelect',
    'selectedFirst',
    'shortStateName',
    'withUpload',
    'uppercase',
    'hideOnEmpty',
    'hide',
    'mustRead',
    'hideInAnswers',
    'hideForCollector',
    'useAsIs',
    'warningMessage', // yellow income message
    //mesageicons
];
export var Slot = function (_a) {
    var _b;
    var self = _a.self, children = _a.children, form = _a.form, onButtonClick = _a.onButtonClick, isMultiMedia = _a.isMultiMedia, prefilledOCRFieldsId = _a.prefilledOCRFieldsId, questionId = _a.questionId, readDocuments = _a.readDocuments, handleSetReadDocuments = _a.handleSetReadDocuments, documentsIsRead = _a.documentsIsRead, uploadButton = _a.uploadButton, multiQuestion = _a.multiQuestion, allSlots = _a.allSlots;
    var rerender = useRerender();
    var _c = useSelector(function (state) { return ({
        survey: state.survey.fullSurvey,
        isLoading: state.loading.isLoading,
    }); }), survey = _c.survey, isLoading = _c.isLoading;
    var _d = useState(false), disabledByPrefill = _d[0], setDisabledByPrefill = _d[1];
    var _e = useState(false), hideOnEmpty = _e[0], setHideOnEmpty = _e[1];
    var _f = useState(false), wasFilled = _f[0], setWasFilled = _f[1];
    var tags = useMemo(function () {
        var tags = {
            bigStyle: true,
        };
        ALLOWED_SLOT_TAGS.forEach(function (tag) {
            if (hasTag(self, tag)) {
                tags[tag] = true;
            }
        });
        return tags;
    }, [self]);
    var isDisabled = useMemo(function () { return disabledByPrefill || isLoading || tags.disabled; }, [disabledByPrefill, isLoading, tags.disabled]);
    var _g = useMemo(function () {
        var _a;
        var currentChildren = getChildren({
            id: self._id,
            data: children,
            variations: ['slot', 'info', 'content', 'select_option'],
        });
        var content = getContent({ id: self._id, data: children });
        var isRequired = !(self.required !== null && self.required === false) &&
            ((_a = prefilledOCRFieldsId === null || prefilledOCRFieldsId === void 0 ? void 0 : prefilledOCRFieldsId[self._id]) === null || _a === void 0 ? void 0 : _a.editable) !== 'forbidden';
        return { currentChildren: currentChildren, content: content, isRequired: isRequired };
    }, [self._id, children, (_b = prefilledOCRFieldsId === null || prefilledOCRFieldsId === void 0 ? void 0 : prefilledOCRFieldsId[self._id]) === null || _b === void 0 ? void 0 : _b.editable, self.required]), currentChildren = _g.currentChildren, content = _g.content, isRequired = _g.isRequired;
    var selectOptions = useMemo(function () {
        var selectOptions;
        if (self.variation === 'slot' && self.subvariation === 'select') {
            var options = currentChildren.filter(function (child) { return child.variation !== 'content'; });
            selectOptions = options.map(function (option) {
                return {
                    key: option.value,
                    value: option.value,
                    textValue: getContent({
                        id: option._id,
                        data: survey.content,
                    }).text,
                };
            });
        }
        return { selectOptions: selectOptions };
    }, [currentChildren, self.subvariation, self.variation, survey.content]).selectOptions;
    useEffect(function checkHideOnEmpty() {
        var hideOnEmpty = tags.hideOnEmpty && Object.keys(prefilledOCRFieldsId).indexOf(self._id) === -1;
        setHideOnEmpty(hideOnEmpty);
    }, [self.prefill, prefilledOCRFieldsId]);
    useEffect(function () {
        if (form.getValues(self._id)) {
            !wasFilled && setWasFilled(true);
        }
    }, [form.getValues(self._id)]);
    useEffect(function disabledByPrefill() {
        var prefill = prefilledOCRFieldsId === null || prefilledOCRFieldsId === void 0 ? void 0 : prefilledOCRFieldsId[self._id];
        var isPrefilled = !!prefill && !!prefill.editable;
        if (isPrefilled) {
            switch (prefill.editable) {
                case 'empty_only': {
                    setDisabledByPrefill(!!form.getValues(self._id));
                    break;
                }
                case 'filled_only': {
                    setDisabledByPrefill(!form.getValues(self._id) && !wasFilled);
                    break;
                }
                case 'forbidden': {
                    setDisabledByPrefill(true);
                }
            }
        }
    }, [self.prefill, form.getValues(self._id)]); //eslint-disable-line
    var isText = (self === null || self === void 0 ? void 0 : self.variation) === 'info' && (self === null || self === void 0 ? void 0 : self.subvariation) === 'text';
    var isDocument = (self === null || self === void 0 ? void 0 : self.variation) === 'info' && (self === null || self === void 0 ? void 0 : self.subvariation) === 'document';
    if (hideOnEmpty && !wasFilled)
        return null;
    if (tags.hide)
        return null;
    if (!isDocument && !isText && tags.mustRead && !documentsIsRead())
        return null;
    if (isText) {
        var iconKey = Object.keys(MESSAGE_ICONS).find(function (key) { return hasTag(self, key); });
        return (_jsx(TextMessage, __assign({ topIcon: iconKey && MESSAGE_ICONS[iconKey], isWarningMessage: tags.warningMessage, className: styles.infoMessage, type: 'income' }, { children: content.text })));
    }
    if (isDocument) {
        return (_jsx(RulesMessage, { selfId: self._id, className: styles.chatMessageRules, labelLink: replaceContent(content.title), content: replaceContent(content.text), approved: readDocuments.some(function (docId) { return docId === self._id; }), handleSetReadDocuments: tags.mustRead && handleSetReadDocuments }, self._id));
    }
    var isOption = self.subvariation === 'option';
    if (isOption) {
        return (_jsx(Controller, { control: form.control, name: questionId, rules: {
                required: {
                    value: isRequired,
                    message: t('error_required'),
                },
            }, render: function (_a) {
                var _b = _a.field, onChange = _b.onChange, value = _b.value, _c = _a.fieldState;
                return (_jsx(Checkbox, { className: styles.chatCheckbox, label: content.label, description: content === null || content === void 0 ? void 0 : content.description, value: self._id, changeType: multiQuestion ? 'multi' : 'single', checked: multiQuestion ? value === null || value === void 0 ? void 0 : value.includes(self._id) : value === self._id, onChange: function (_, v) {
                        form.clearErrors(questionId);
                        if (multiQuestion) {
                            var valueIsArray = value instanceof Array;
                            if (!value || !valueIsArray || self.modifier === 'single') {
                                return onChange([v]);
                            }
                            if (value && valueIsArray) {
                                var preparedOldValue = value.filter(function (el) {
                                    var slot = getCurrentSlot({ id: el, data: allSlots });
                                    return (slot === null || slot === void 0 ? void 0 : slot.modifier) !== 'single';
                                });
                                preparedOldValue.includes(v)
                                    ? onChange(preparedOldValue.filter(function (el) { return el !== v; }))
                                    : onChange(__spreadArray(__spreadArray([], preparedOldValue, true), [v], false));
                            }
                            return;
                        }
                        onChange(v);
                    } }));
            } }));
    }
    if (self.subvariation === 'input') {
        var isEmailSlot = tags.email;
        var startValue = useStartValue(form.getValues(self._id));
        if (tags.phone) {
            return (_jsx("div", __assign({ className: styles.chatPhone }, { children: _jsx(PhoneInput, { defValue: startValue, name: self._id, classNameSelect: styles.phoneSelect, className: styles.phone, classNameElements: styles.chatPhoneElements, classNameWrapper: styles.phoneWrapper, setValue: form.setValue, error: form.formState.errors[self._id], register: form.register, clearErrors: form.clearErrors }) })));
        }
        return (_jsx(ChatInput, { withUploadButton: tags.withUpload, uploadButtonAction: function () {
                onButtonClick(uploadButton);
            }, displayLabel: tags.bigStyle && content.label, hasLabel: tags.bigStyle, type: "text", label: self._id, classes: [styles.chatInput, tags.bigStyle && styles.surveyMessageInputBig], control: form.control, register: form.register, placeholder: tags.bigStyle ? '' : content.label, clearErrors: form.clearErrors, unregister: form.unregister, error: form.formState.errors[self._id], setEmpty: function () {
                form.setValue(self._id, '');
            }, validation: {
                required: { value: isRequired, message: 'error_required' },
                pattern: isEmailSlot
                    ? {
                        value: EMAIL_PATTERN,
                        message: t('error_email_incorrect'),
                    }
                    : {
                        value: self.regex ? new RegExp(self.regex) : null,
                        message: t('error_incorrect_value'),
                    },
                onChange: function (event) {
                    var _a, _b;
                    if (tags.uppercase) {
                        event.target.value = (_b = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toUpperCase();
                    }
                    form.clearErrors(self._id);
                },
            }, disable: isDisabled }, self._id));
    }
    if (self.subvariation === 'select') {
        return (_jsx(Controller, { control: form.control, name: self._id, rules: {
                required: {
                    value: isRequired,
                    message: t('error_required'),
                },
            }, render: function (_a) {
                var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
                var props = {
                    fullscreen: tags.selectFullscreen,
                    label: tags.optionSlot ? 'Type:' : content === null || content === void 0 ? void 0 : content.label,
                    title: content === null || content === void 0 ? void 0 : content.label,
                    buttonClassName: styles.buttonSelect,
                    active: value || '',
                    items: selectOptions,
                    shortStateName: tags.shortStateName,
                    onChange: function (value) {
                        form.clearErrors(self._id);
                        onChange(value);
                        rerender();
                    },
                    disabled: isDisabled,
                };
                return (_jsx(Select, __assign({}, props, { placeholder: content === null || content === void 0 ? void 0 : content.label, label: '', isTopPlaceholder: tags.bigStyle, selectListClassName: styles.chatSelectList, wrapperClassName: styles.chatSelectWrapper, className: clsx(styles.chatSelect, tags.bigStyle && styles.chatSelectBig, tags.disabled && styles.chatDisabled), error: error === null || error === void 0 ? void 0 : error.message })));
            } }));
    }
    if (self.subvariation === 'media') {
        return (_jsx(Controller, { control: form.control, name: self._id, rules: {
                required: {
                    value: isRequired,
                    message: t('error_required'),
                },
            }, render: function (_a) {
                var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
                return (_jsx(InputFile, { startLink: value, className: clsx(styles.chatFile), fieldClassName: isMultiMedia && styles.chatFileMulti, title: content.label, name: 'media', mediaTypes: self.media_types, type: "imageField", disabled: isDisabled, handleChange: function (e) {
                        var _a, _b;
                        !e && form.resetField(self._id);
                        var file = (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
                        if (file) {
                            form.clearErrors(self._id);
                            onChange(file);
                            rerender();
                        }
                    }, error: error === null || error === void 0 ? void 0 : error.message }));
            } }));
    }
    if (self.subvariation === 'date') {
        return (_jsx(Controller, { control: form.control, name: self._id, rules: {
                required: {
                    value: isRequired,
                    message: t('error_required'),
                },
            }, render: function (_a) {
                var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
                return (_jsx(DatePicker, { bigStyle: tags.bigStyle, className: clsx(styles.chatDatePicker, styles.chatDatePickerBig), disabled: isDisabled, startValue: value, relativeRange: {
                        startDate: self.range.limit_from,
                        endDate: self.range.limit_to,
                    }, label: !tags.bigStyle && content.label, placeholder: tags.bigStyle && content.label, handleChange: function (value) {
                        form.clearErrors(self._id);
                        onChange(value);
                        rerender();
                    }, error: error === null || error === void 0 ? void 0 : error.message }, self._id));
            } }));
    }
    if (self.subvariation === 'base64') {
        return (_jsx("div", __assign({ className: clsx(styles.chatDraw) }, { children: _jsx(Controller, { control: form.control, name: self._id, rules: {
                    required: {
                        value: isRequired,
                        message: t('error_required'),
                    },
                }, render: function (_a) {
                    var onChange = _a.field.onChange, error = _a.fieldState.error;
                    return (_jsx(Draw, { label: content.label, onChange: function (value) {
                            form.clearErrors(self._id);
                            onChange(value);
                            rerender();
                        }, error: error === null || error === void 0 ? void 0 : error.message }));
                } }) })));
    }
    if (self.subvariation === 'autocomplete') {
        return (_jsx(Controller, { control: form.control, name: self._id, rules: {
                required: {
                    value: isRequired,
                    message: t('error_required'),
                },
            }, render: function (_a) {
                var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
                return (_jsx(AutocompleteFullscreen, { useAsIs: tags.useAsIs, className: styles.chatAutocomplete, slot: self, content: content, value: value, disabled: isDisabled, error: error === null || error === void 0 ? void 0 : error.message, handleChange: function (value) {
                        form.clearErrors(self._id);
                        onChange(value);
                        rerender();
                    } }));
            } }));
    }
    return _jsx("span", {});
};
