var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Amex = function () { return (_jsxs("svg", __assign({ width: "23", height: "16", viewBox: "0 0 23 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 2C0 0.895431 0.895431 0 2 0H21C22.1046 0 23 0.895431 23 2V14C23 15.1046 22.1046 16 21 16H2C0.89543 16 0 15.1046 0 14L0 2Z", fill: "#1F72CD" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.37658 5.71429L2.29999 10.4155H4.78596L5.09414 9.66593H5.79859L6.10678 10.4155H8.84313V9.8434L9.08696 10.4155H10.5024L10.7462 9.83131V10.4155H16.4371L17.1291 9.68539L17.777 10.4155L20.7 10.4215L18.6168 8.07802L20.7 5.71429H17.8224L17.1488 6.4309L16.5212 5.71429H10.3303L9.79869 6.92771L9.2546 5.71429H6.77382V6.26692L6.49785 5.71429H4.37658ZM12.8747 6.38186H16.1427L17.1422 7.48637L18.1739 6.38186H19.1734L17.6548 8.07734L19.1734 9.75329H18.1286L17.1291 8.63594L16.0921 9.75329H12.8747V6.38186ZM13.6818 7.69609V7.08028V7.07969H15.7209L16.6106 8.06454L15.6815 9.05477H13.6818V8.38247H15.4646V7.69609H13.6818ZM4.85765 6.38186H6.06943L7.44683 9.56977V6.38186H8.77429L9.83817 8.66757L10.8187 6.38186H12.1395V9.75528H11.3358L11.3292 7.11189L10.1575 9.75528H9.43859L8.26033 7.11189V9.75528H6.60696L6.29351 8.999H4.60006L4.28726 9.75461H3.4014L4.85765 6.38186ZM4.8891 8.29993L5.44702 6.95269L6.0043 8.29993H4.8891Z", fill: "white" })] }))); };
export default Amex;
