import { jsx as _jsx } from "react/jsx-runtime";
import { NoLayout } from '@core/layouts';
import { AccountMerge } from '@core/pages/accountsMerge';
import { AppleConfirm, GoogleConfirm } from '@core/pages/auth/components';
import { Logout } from '@core/pages/logout';
import { NotFound } from '@core/pages/notFound';
import { Redirect } from '@core/pages/redirect';
import { Landing as LandingLayout } from '@layouts/landing';
import { Main as MainLayout } from '@layouts/main';
import { NoLayoutWithAuth } from '@layouts/noLayoutWithAuth';
import { RequireAuthSlice, RequireMainSlice } from '@layouts/Requirements';
import { Checkout } from '@pages/checkout';
import { Home } from '@pages/home';
import { Contacts as LandingContacts } from '@pages/landing/contacts';
import { Employers } from '@pages/landing/employers';
import { Faqs } from '@pages/landing/faqs';
import { Home as LandingHome } from '@pages/landing/home';
import { Login } from '@pages/landing/login';
import { Services } from '@pages/landing/services';
import { Consumers } from '@pages/landing/сonsumers';
import { Onboarding } from '@pages/onboarding';
import { Perks } from '@pages/perks';
import { Risks } from '@pages/risks';
import { HealthRiskTest } from '@pages/risksTest';
import { Settings } from '@pages/settings';
import { ActivateCode } from '@pages/settings/activateCode';
import { AddPaymentMethod } from '@pages/settings/addPayment';
import { Contacts } from '@pages/settings/contacts';
import { AddContact } from '@pages/settings/contacts/subPages/add';
import { DeleteContact } from '@pages/settings/contacts/subPages/delete';
import { DeleteAccount } from '@pages/settings/deleteAccount';
import { ManagePlan } from '@pages/settings/managePlan';
import { UpdatePassword } from '@pages/settings/password';
import { Profile } from '@pages/settings/profile';
import { StoriesRedirect } from '@pages/storiesRedirect';
export var routePaths;
(function (routePaths) {
    routePaths["HOME"] = "/app/";
    routePaths["RISKS"] = "/app/risks/";
    routePaths["PERKS"] = "/app/perks/";
    routePaths["ONBOARDING"] = "/onboarding/";
    routePaths["ACCOUNT_MERGE"] = "/account-merge/";
    routePaths["HEALTH_RISK_TEST"] = "/health-risk-test/";
    routePaths["SETTINGS"] = "/app/settings/";
    routePaths["MANAGE_PLAN"] = "/app/settings/plan/";
    routePaths["MANAGE_PLAN_PAGE"] = "/app/settings/:page/";
    routePaths["MANAGE_PLAN_NEW_PATIENT"] = "/app/settings/plan/new/";
    routePaths["CHECKOUT"] = "/app/checkout/";
    routePaths["PROFILE_EDIT"] = "/app/settings/profile/";
    routePaths["CONTACTS"] = "/app/settings/contacts/";
    routePaths["ADD_CONTACT"] = "/app/settings/contacts/add/:type/";
    routePaths["DELETE_CONTACT"] = "/app/settings/contacts/delete/";
    routePaths["ACTIVATE_CODE"] = "/app/settings/code/";
    routePaths["PAYMENT"] = "/app/settings/payment_method/";
    routePaths["ADD_PAYMENT"] = "/app/settings/payment_method/add/";
    routePaths["UPDATE_PASSWORD"] = "/app/settings/password/";
    routePaths["DELETE_ACCOUNT"] = "/settings/delete-account/";
    routePaths["REDIRECT"] = "/redirect/*";
    routePaths["LOGOUT"] = "/logout/";
    routePaths["GOOGLE_CONFIRM"] = "/oauth/google/";
    routePaths["APPLE_CONFIRM"] = "/oauth/apple/";
    routePaths["STORIES_REDIRECT"] = "/stories_redirect/";
    routePaths["STORIES_REDIRECT_PAGE"] = "/stories_redirect/:page/";
    routePaths["LANDING_HOME"] = "/";
    routePaths["SERVICES"] = "/services/";
    routePaths["EMPLOYERS"] = "/employers/";
    routePaths["CONSUMERS"] = "/consumers/";
    routePaths["SUPPORT"] = "/support/";
    routePaths["CONTACTUS"] = "/contact-us/";
    routePaths["LOGIN"] = "/login/";
})(routePaths || (routePaths = {}));
export var layouts = {
    //MAIN LAYOUT
    main: {
        layout: _jsx(MainLayout, { AuthSlice: RequireAuthSlice, MainSlice: RequireMainSlice }),
        routes: [
            {
                path: routePaths.HOME,
                element: _jsx(Home, {}),
            },
            {
                path: routePaths.RISKS,
                element: _jsx(Risks, {}),
            },
            {
                path: routePaths.PERKS,
                element: _jsx(Perks, {}),
            },
            {
                path: routePaths.SETTINGS,
                element: _jsx(Settings, {}),
            },
            {
                path: routePaths.MANAGE_PLAN,
                element: _jsx(ManagePlan, {}),
            },
            {
                path: routePaths.MANAGE_PLAN_PAGE,
                element: _jsx(ManagePlan, {}),
            },
            {
                path: routePaths.MANAGE_PLAN_NEW_PATIENT,
                element: _jsx(ManagePlan, {}),
            },
            {
                path: routePaths.PROFILE_EDIT,
                element: _jsx(Profile, {}),
            },
            {
                path: routePaths.CONTACTS,
                element: _jsx(Contacts, {}),
            },
            {
                path: routePaths.ADD_CONTACT,
                element: _jsx(AddContact, {}),
            },
            {
                path: routePaths.DELETE_CONTACT,
                element: _jsx(DeleteContact, {}),
            },
            {
                path: routePaths.DELETE_ACCOUNT,
                element: _jsx(DeleteAccount, {}),
                title: '',
            },
            {
                path: routePaths.UPDATE_PASSWORD,
                element: _jsx(UpdatePassword, {}),
            },
            {
                path: routePaths.ADD_PAYMENT,
                element: _jsx(AddPaymentMethod, {}),
            },
            {
                path: routePaths.ACTIVATE_CODE,
                element: _jsx(ActivateCode, {}),
            },
            {
                path: routePaths.CHECKOUT,
                element: _jsx(Checkout, {}),
            },
            {
                path: routePaths.REDIRECT,
                element: _jsx(Redirect, {}),
            },
            {
                path: routePaths.STORIES_REDIRECT,
                element: _jsx(StoriesRedirect, {}),
                title: '',
            },
            {
                path: routePaths.STORIES_REDIRECT_PAGE,
                element: _jsx(StoriesRedirect, {}),
                title: '',
            },
        ],
    },
    landing: {
        layout: _jsx(LandingLayout, { AuthSlice: RequireAuthSlice, MainSlice: RequireMainSlice }),
        routes: [
            {
                path: routePaths.LANDING_HOME,
                element: _jsx(LandingHome, {}),
            },
            {
                path: routePaths.CONSUMERS,
                element: _jsx(Consumers, {}),
            },
            {
                path: routePaths.SERVICES,
                element: _jsx(Services, {}),
            },
            {
                path: routePaths.SUPPORT,
                element: _jsx(Faqs, {}),
            },
            {
                path: routePaths.CONTACTUS,
                element: _jsx(LandingContacts, {}),
            },
            {
                path: routePaths.EMPLOYERS,
                element: _jsx(Employers, {}),
            },
        ],
    },
    noLayout: {
        layout: _jsx(NoLayout, {}),
        routes: [
            {
                path: routePaths.LOGOUT,
                element: _jsx(Logout, { isQsure: true }),
            },
            {
                path: routePaths.LANDING_HOME,
                element: _jsx(LandingHome, {}),
            },
            {
                path: routePaths.GOOGLE_CONFIRM,
                element: _jsx(GoogleConfirm, { isQsure: true }),
            },
            {
                path: routePaths.APPLE_CONFIRM,
                element: _jsx(AppleConfirm, { isQsure: true }),
            },
            {
                path: routePaths.LOGIN,
                element: _jsx(Login, {}),
            },
            {
                path: '*',
                element: _jsx(NotFound, {}),
            },
        ],
    },
    noLayoutWithAuth: {
        layout: _jsx(NoLayoutWithAuth, { AuthSlice: RequireAuthSlice, MainSlice: RequireMainSlice }),
        routes: [
            {
                path: routePaths.ACCOUNT_MERGE,
                element: _jsx(AccountMerge, {}),
            },
            {
                path: routePaths.ONBOARDING,
                element: _jsx(Onboarding, {}),
            },
            {
                path: routePaths.HEALTH_RISK_TEST,
                element: _jsx(HealthRiskTest, {}),
            },
        ],
    },
};
