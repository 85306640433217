import { t } from 'i18next';
export var ChatConstants;
(function (ChatConstants) {
    ChatConstants[ChatConstants["LIMIT"] = 15] = "LIMIT";
})(ChatConstants || (ChatConstants = {}));
export var PAGE_LIMIT = 10;
export var getColumnNames = function () { return ({
    name: 'Name',
    _id: 'ID',
    created: t('label_created'),
    lastUpd: t('label_last_update'),
    status: t('label_status'),
}); };
export var queryKeys = {
    location: 'location_id',
};
export var messageTypes;
(function (messageTypes) {
    messageTypes["NEW_MESSAGE"] = "new_message";
    messageTypes["MESSAGE_EDIT"] = "message_edited";
    messageTypes["MESSAGE_DELETED"] = "message_deleted";
    messageTypes["MESSAGE_READ"] = "message_read";
    messageTypes["USER_STATUS"] = "user_status";
    messageTypes["USER_ACTION"] = "user_action";
    messageTypes["ERROR"] = "error";
})(messageTypes || (messageTypes = {}));
