import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { routePaths } from '@app/routes/routes';
import { PageLoader } from '@core/components/loader/PageLoader';
import { LoginModal } from '@core/components/modals/loginModal';
import TokenService from '@core/services/tokens';
import { REDIRECT_KEY } from '@core/shared/constants/common';
import { cloneAccount } from '@core/store/reducers/authSlice';
import { getInfoAll } from '@core/store/reducers/profileSlice';
import { Auth as AuthLayout } from '@layouts/auth';
export var RequireMainSlice = function (_a) {
    var children = _a.children;
    var dispatch = useDispatch();
    var _b = useSelector(function (state) { return state.profile; }), id = _b.id, accounts = _b.accounts;
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1];
    useEffect(function () {
        var _a;
        if (!id && !((_a = accounts === null || accounts === void 0 ? void 0 : accounts[0]) === null || _a === void 0 ? void 0 : _a.id)) {
            dispatch(getInfoAll({
                callback: function () {
                    setIsLoading(false);
                },
            }));
        }
        else {
            setIsLoading(false);
        }
    }, [dispatch]);
    if (isLoading) {
        return _jsx(PageLoader, { isQsure: true });
    }
    else {
        return children;
    }
};
export var RequireAuthSlice = function (_a) {
    var children = _a.children;
    var isAuth = !!TokenService.getAccessToken();
    var location = useLocation();
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1];
    var locationPath = location.pathname + (location.pathname.endsWith('/') ? '' : '/');
    var isRedirected = locationPath.startsWith(REDIRECT_KEY);
    var isInvitePath = locationPath.startsWith('/email/') || locationPath.startsWith('/phone/');
    var isHasToken = searchParams.has('refresh_token');
    var isLandingPage = [
        routePaths.LANDING_HOME,
        routePaths.CONSUMERS,
        routePaths.LOGIN,
        routePaths.SERVICES,
        routePaths.EMPLOYERS,
        routePaths.SUPPORT,
        routePaths.CONTACTUS,
    ].includes(locationPath);
    var isAllowedRoute = isLandingPage || isInvitePath;
    useEffect(function () {
        if (!isHasToken) {
            setIsLoading(false);
            return;
        }
        var token = searchParams.get('refresh_token');
        if (isAuth) {
            TokenService.clearToken();
        }
        dispatch(cloneAccount({
            token: token,
            callback: function (isCloned) {
                searchParams.delete('refresh_token');
                setSearchParams(searchParams, { replace: true });
                setIsLoading(false);
                if (isCloned) {
                    isAuth = true;
                }
                return;
            },
        }));
    }, []);
    useEffect(function () {
        var isAfterRedirect = location.state === 'redirect';
        if (!isAllowedRoute && !isRedirected && !isAuth && !isAfterRedirect && !isHasToken) {
            navigate(REDIRECT_KEY + locationPath + location.search, { replace: true });
        }
    }, [location, isAllowedRoute, isRedirected, isAuth, isHasToken]);
    if (isLoading) {
        return _jsx(PageLoader, { isQsure: true });
    }
    if (isAuth || (!isAuth && isLandingPage)) {
        return children;
    }
    return (_jsx(AuthLayout, { children: _jsx(LoginModal, { nav: navigate, isQsure: true }) }));
};
