export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["DRAFT"] = "draft";
    OrderStatus["PENDING"] = "pending";
    OrderStatus["CREATED"] = "created";
    OrderStatus["VERIFIED"] = "verified";
    OrderStatus["FINISHED"] = "finished";
    OrderStatus["CANCELED"] = "canceled";
    OrderStatus["FAILED"] = "failed";
})(OrderStatus || (OrderStatus = {}));
export var OrderType;
(function (OrderType) {
    OrderType["MOBILE"] = "mobile";
    OrderType["IN_OFFICE"] = "in_office";
})(OrderType || (OrderType = {}));
export var OrderMobileStatus;
(function (OrderMobileStatus) {
    OrderMobileStatus["ASSIGNED"] = "assigned";
    OrderMobileStatus["ON_THE_WAY"] = "on_the_way";
    OrderMobileStatus["ARRIVED"] = "arrived";
    OrderMobileStatus["PROCESSED"] = "processed";
})(OrderMobileStatus || (OrderMobileStatus = {}));
export var ExternalProcedureStatus;
(function (ExternalProcedureStatus) {
    ExternalProcedureStatus["ACCESSIONED"] = "accessioned";
    ExternalProcedureStatus["CANCELLED"] = "cancelled";
    ExternalProcedureStatus["CORRECTED"] = "corrected";
    ExternalProcedureStatus["FINAL"] = "final";
    ExternalProcedureStatus["RESULTED"] = "resulted";
    ExternalProcedureStatus["PARTIAL"] = "partial";
})(ExternalProcedureStatus || (ExternalProcedureStatus = {}));
export var ProcedureStatus;
(function (ProcedureStatus) {
    ProcedureStatus["CREATED"] = "created";
    ProcedureStatus["IN_PROGRESS"] = "in_progress";
    ProcedureStatus["FINISHED"] = "finished";
    ProcedureStatus["CANCELED"] = "canceled";
    ProcedureStatus["FAILED"] = "failed";
})(ProcedureStatus || (ProcedureStatus = {}));
export var StepType;
(function (StepType) {
    StepType["SET_SAMPLE_ID"] = "set_sample_id";
    StepType["COLLECT_SAMPLE_RAPID"] = "collect_sample_rapid";
    StepType["COLLECT_SAMPLE_LAB"] = "collect_sample_lab";
    StepType["CHECK_STARTED"] = "check_started";
    StepType["UPLOAD_PHOTO"] = "upload_photo";
    StepType["CONFIRM_UPLOAD_PHOTO"] = "confirm_upload_photo";
    StepType["CHECK_RESULTS"] = "check_results";
})(StepType || (StepType = {}));
export var ProcedureCalculatedStatus;
(function (ProcedureCalculatedStatus) {
    ProcedureCalculatedStatus["CREATED"] = "Created";
    ProcedureCalculatedStatus["COLLECTING"] = "Collecting";
    ProcedureCalculatedStatus["PENDING"] = "Pending";
    ProcedureCalculatedStatus["RESULTING"] = "Resulting";
    ProcedureCalculatedStatus["COMPLETED"] = "Completed";
    ProcedureCalculatedStatus["CORRECTED"] = "Corrected";
    ProcedureCalculatedStatus["CANCELED"] = "Canceled";
    ProcedureCalculatedStatus["FAILED"] = "Failed";
})(ProcedureCalculatedStatus || (ProcedureCalculatedStatus = {}));
export var OrderCalculatedStatus;
(function (OrderCalculatedStatus) {
    OrderCalculatedStatus["DRAFT"] = "draft";
    OrderCalculatedStatus["CREATED"] = "created";
    OrderCalculatedStatus["IN_PROGRESS"] = "in_progress";
    OrderCalculatedStatus["PENDING_RESULTS"] = "pending_results";
    OrderCalculatedStatus["PENDING_RESULTS_WITH_FAILED"] = "pending_results_with_failed";
    OrderCalculatedStatus["FAILED"] = "failed";
    OrderCalculatedStatus["PARTIALLY_COMPLETED"] = "partially_completed";
    OrderCalculatedStatus["PARTIALLY_COMPLETED_WITH_FAILED"] = "partially_completed_with_failed";
    OrderCalculatedStatus["COMPLETED"] = "completed";
    OrderCalculatedStatus["CANCELED"] = "canceled";
    // E-prescription in office statuses
    OrderCalculatedStatus["CREATED_EP_OFFICE"] = "created_ep_office";
    // E-prescription mobile statuses
    OrderCalculatedStatus["CREATED_EP_MOBILE"] = "created_ep_mobile";
    OrderCalculatedStatus["CREATED_EP_MOBILE_ON_THE_WAY"] = "created_ep_mobile_on_the_way";
})(OrderCalculatedStatus || (OrderCalculatedStatus = {}));
export var ProcedureType;
(function (ProcedureType) {
    ProcedureType["LAB"] = "lab";
    ProcedureType["RAPID"] = "rapid";
    ProcedureType["PANEL"] = "panel";
})(ProcedureType || (ProcedureType = {}));
export var Biomaterial;
(function (Biomaterial) {
    Biomaterial["BLOOD"] = "blood";
    Biomaterial["URINE"] = "urine";
    Biomaterial["STOOL"] = "stool";
    Biomaterial["NASAL_SWAB"] = "nasal_swab";
    Biomaterial["MEDIA"] = "media";
    Biomaterial["SWAB"] = "swab";
    Biomaterial["COVID_SWAB"] = "covid_swab";
    Biomaterial["SALIVA"] = "saliva";
})(Biomaterial || (Biomaterial = {}));
export var AppointmentProcedureType;
(function (AppointmentProcedureType) {
    AppointmentProcedureType["PROCEDURE"] = "procedure";
    AppointmentProcedureType["PANEL"] = "panel";
})(AppointmentProcedureType || (AppointmentProcedureType = {}));
export var PaymentTypes;
(function (PaymentTypes) {
    PaymentTypes["INSURANCE"] = "insurance";
    PaymentTypes["OUT_OF_POCKET"] = "out_of_pocket";
})(PaymentTypes || (PaymentTypes = {}));
export var PrescriptionTypes;
(function (PrescriptionTypes) {
    PrescriptionTypes["PAPER"] = "paper_prescription";
    PrescriptionTypes["ELECTRONIC"] = "e_prescription";
    PrescriptionTypes["AUTO"] = "auto_prescription";
})(PrescriptionTypes || (PrescriptionTypes = {}));
export var OrderItemType;
(function (OrderItemType) {
    OrderItemType["PROCEDURE"] = "procedure";
    OrderItemType["PANEL"] = "panel";
    OrderItemType["DRAFT_PANEL"] = "draft_panel";
})(OrderItemType || (OrderItemType = {}));
