var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { REDIRECT_KEY } from '@core/shared/constants/common';
import Logo from '@core/shared/svg/logo';
import { logInGoogleConfirm } from '@core/store/reducers/authSlice';
import styles from './index.module.sass';
export var GoogleConfirm = function (_a) {
    var isQsure = _a.isQsure;
    var searchParams = useSearchParams()[0];
    var dispatch = useDispatch();
    var _b = useLocation(), pathname = _b.pathname, search = _b.search, hash = _b.hash;
    var nav = useNavigate();
    var verificationCallback = function (isOnboarding) {
        var homePath = isQsure ? '/app/' : '/';
        var path = pathname.includes(REDIRECT_KEY) ? "".concat(pathname).concat(search).concat(hash) : homePath;
        isOnboarding ? nav('/onboarding/', { state: { redirectPath: path } }) : nav(path);
    };
    useEffect(function () {
        dispatch(logInGoogleConfirm({
            isQsure: isQsure,
            state: searchParams.get('state'),
            code: searchParams.get('code'),
            callback: verificationCallback,
        }));
    }, []);
    return (_jsx("div", __assign({ className: styles.logo }, { children: _jsx(Logo, {}) })));
};
