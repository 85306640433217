var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Close = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.9497 20.3639L14 15.4142L9.05025 20.3639C8.65922 20.755 8.02707 20.755 7.63604 20.3639C7.24501 19.9729 7.24501 19.3408 7.63604 18.9497L12.5858 14L7.63604 9.05024C7.24572 8.65992 7.24501 8.02705 7.63604 7.63603C8.02707 7.245 8.65993 7.2457 9.05025 7.63603L14 12.5858L18.9497 7.63603C19.3401 7.2457 19.9729 7.245 20.364 7.63603C20.755 8.02705 20.7543 8.65992 20.364 9.05024L15.4142 14L20.364 18.9497C20.755 19.3408 20.755 19.9729 20.364 20.3639C19.9729 20.755 19.3408 20.755 18.9497 20.3639Z", fill: "black" }) }) }))); };
export default Close;
