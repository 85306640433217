import states from '@core/shared/states/states.json';
import MarkerIcon from '@core/shared/svg/map/marker_small.svg';
export var getOfficeObject = function (data) { return ({
    id: data._id,
    name: data.name,
    address: data.place.address,
    contacts: data.contacts,
    tags: data.tags,
    rawSchedule: data.schedule,
    timezone: data.timezone,
}); };
export var getMarkerObject = function (data) { return ({
    officeId: data._id,
    icon: {
        url: MarkerIcon,
    },
    position: {
        lat: data.place.geo_object.coordinates[1],
        lng: data.place.geo_object.coordinates[0],
    },
}); };
export var formatApiData = function (_a, updateCoords) {
    var address_components = _a.address_components, place_id = _a.place_id, geometry = _a.geometry;
    if (updateCoords === void 0) { updateCoords = false; }
    var tmp = {
        zip: '',
        state: '',
        country: '',
        street: '',
        city: '',
        building: '',
        apartment: '',
        floor: '',
        directions: '',
        geocoderId: '',
        coords: {
            lat: 0,
            lng: 0,
        },
    };
    if (address_components) {
        var locality_1 = '', neighborhood_1 = '', sublocality_1 = '', level2_1 = '', establishment_1 = '', building_1 = '', street_1 = '', premise_1 = '', zip_1 = '', country_1 = '', state_1 = '';
        address_components.forEach(function (el) {
            if (el.types.includes('locality') && !locality_1)
                locality_1 = el.short_name;
            if (el.types.includes('sublocality') && !sublocality_1)
                sublocality_1 = el.short_name;
            if (el.types.includes('neighborhood') && !neighborhood_1)
                neighborhood_1 = el.short_name;
            if (el.types.includes('administrative_area_level_2') && !level2_1)
                level2_1 = el.short_name;
            //
            if (el.types.includes('route') && !street_1)
                street_1 = el.long_name;
            if (el.types.includes('establishment') && !establishment_1)
                establishment_1 = el.short_name;
            //
            if (el.types.includes('street_number') && !building_1)
                building_1 = el.short_name;
            if (el.types.includes('premise') && !premise_1)
                premise_1 = el.short_name;
            //
            if (el.types.includes('postal_code') && !zip_1)
                zip_1 = el.short_name;
            if (el.types.includes('country') && !country_1)
                country_1 = el.short_name;
            if (el.types.includes('administrative_area_level_1') && !state_1)
                state_1 = el.short_name;
        });
        tmp.city = locality_1 || sublocality_1 || neighborhood_1 || level2_1;
        tmp.street = street_1 || establishment_1;
        tmp.building = building_1 || premise_1 || (street_1 ? establishment_1 : '');
        tmp.zip = zip_1;
        tmp.country = country_1;
        tmp.state = state_1;
    }
    if (place_id) {
        tmp.geocoderId = place_id;
    }
    if (geometry && updateCoords) {
        tmp.coords = {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
        };
    }
    return tmp;
};
export var debounce = function (func, wait) {
    var timeout;
    return function executedFunction() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var later = function () {
            timeout = null;
            func.apply(void 0, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};
export var getRadius = function (lat, zoom) {
    var metersPerPixel = (Math.cos((lat * Math.PI) / 180) * 2 * Math.PI * 6378137) / (256 * Math.pow(2, zoom));
    return Number(metersPerPixel.toFixed(4)) * 1000;
};
export var decodeGoogleMapsResult = function (mapResult) {
    var addressComponents = mapResult === null || mapResult === void 0 ? void 0 : mapResult.address_components;
    if (!addressComponents) {
        return null;
    }
    var result = {};
    addressComponents === null || addressComponents === void 0 ? void 0 : addressComponents.forEach(function (item) {
        var _a;
        (_a = item === null || item === void 0 ? void 0 : item.types) === null || _a === void 0 ? void 0 : _a.forEach(function (type) {
            var _a, _b;
            switch (type) {
                case 'street_number':
                    result.building = item.long_name;
                    break;
                case 'route':
                    result.street = item.long_name;
                    break;
                case 'locality':
                case 'sublocality':
                case 'sublocality_level_1':
                case 'administrative_area_level_2':
                    result.city = (_a = item.short_name) !== null && _a !== void 0 ? _a : '';
                    break;
                case 'administrative_area_level_1':
                    var stateId = (_b = states.find(function (state) { return state.id === item.short_name; })) === null || _b === void 0 ? void 0 : _b.id;
                    result.state = stateId !== null && stateId !== void 0 ? stateId : '';
                    break;
                case 'country':
                    result.country = item.short_name;
                    break;
                case 'postal_code':
                    result.zip = item.short_name;
                    break;
            }
        });
    });
    return result;
};
export var formatLocationAddress = function (address) {
    if (!address)
        return 'Location';
    return "".concat(address === null || address === void 0 ? void 0 : address.city, ", ").concat((address === null || address === void 0 ? void 0 : address.state) || (address === null || address === void 0 ? void 0 : address.country));
};
