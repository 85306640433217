var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var LogOut = function () { return (_jsxs("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M19 21C19 20.4477 19.4477 20 20 20C20.5523 20 21 20.4477 21 21C21 22.6569 19.6569 24 18 24H9C7.34315 24 6 22.6569 6 21V7C6 5.34315 7.34315 4 9 4H18C19.6569 4 21 5.34315 21 7C21 7.55228 20.5523 8 20 8C19.4477 8 19 7.55228 19 7C19 6.44772 18.5523 6 18 6H9C8.44772 6 8 6.44772 8 7V21C8 21.5523 8.44772 22 9 22H18C18.5523 22 19 21.5523 19 21Z", fill: "black" }), _jsx("rect", { x: "12", y: "13", width: "10", height: "2", rx: "1", fill: "black" }), _jsx("path", { d: "M21.4142 14L19.2929 11.8786C18.9024 11.4881 18.9024 10.855 19.2929 10.4644C19.6834 10.0739 20.3166 10.0739 20.7071 10.4644L23.5355 13.2929C23.9261 13.6834 23.9261 14.3165 23.5355 14.7071L20.7071 17.5355C20.3166 17.926 19.6834 17.926 19.2929 17.5355C18.9024 17.145 18.9024 16.5118 19.2929 16.1213L21.4142 14Z", fill: "black" })] }))); };
export default LogOut;
