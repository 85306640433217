var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { sortBy } from 'lodash';
import useMask from '@core/shared/hooks/useMask';
import CloseIcon from '@core/shared/svg/close';
import { getCountryByAreaCode, getPhoneMask, getTheSameDialCodeCountries, } from '@core/shared/utils/phoneUtils';
import { setRegion } from '@core/store/reducers/helpers';
import { SelectRegion } from './components/selectRegion';
import styles from './index.module.sass';
export var PhoneInput = function (_a) {
    var _b = _a.name, name = _b === void 0 ? 'phone' : _b, error = _a.error, register = _a.register, clearErrors = _a.clearErrors, setValue = _a.setValue, handleAction = _a.handleAction, className = _a.className, classNameSelect = _a.classNameSelect, classNameWrapper = _a.classNameWrapper, classNameElements = _a.classNameElements, isAdmin = _a.isAdmin, isQsure = _a.isQsure, defValue = _a.defValue, isHideClose = _a.isHideClose, autoFocus = _a.autoFocus;
    var activeRegion = useSelector(function (s) { return s.helpers; }).activeRegion;
    var dispatch = useDispatch();
    var _c = useState(false), isOpenSelectRegions = _c[0], setOpenSelectRegions = _c[1];
    var mask = useMask('', activeRegion.inputMask.value, '.', true);
    var inputRef = useRef(null);
    var flagRef = useRef(null);
    var errorRef = useRef(null);
    var fieldRef = useRef(null);
    var animateShowError = function () {
        errorRef.current.classList.remove(styles.fieldErrorHidden);
        fieldRef.current.classList.add(styles.fieldWrong);
    };
    var animateHideError = function () {
        errorRef.current.classList.add(styles.fieldErrorHidden);
        fieldRef.current.classList.remove(styles.fieldWrong);
        setTimeout(clearErrors, 300);
    };
    var changeRegionByValue = function (value, onPaste) {
        var sameDialCodeCountries = getTheSameDialCodeCountries(value);
        var sortedCountries = sortBy(sameDialCodeCountries, 'priority');
        if (sortedCountries.length) {
            var dialCode = void 0, iso2 = void 0;
            if (sortedCountries.length === 1 && sortedCountries[0].iso2 !== activeRegion.code) {
                dialCode = sortedCountries[0].phone.dialCode;
                iso2 = sortedCountries[0].iso2;
            }
            if (onPaste) {
                var firstWithoutAreaCodes = sortedCountries.find(function (country) { return !country.phone.areaCodes.length; }) || sortedCountries[0];
                dialCode = firstWithoutAreaCodes.phone.dialCode;
                iso2 = firstWithoutAreaCodes.iso2;
            }
            else {
                dialCode = sortedCountries[0].phone.dialCode;
                iso2 = sortedCountries[0].iso2;
            }
            var countryByAreaCode = getCountryByAreaCode(sortedCountries, value);
            if (countryByAreaCode) {
                dialCode = countryByAreaCode.phone.dialCode;
                iso2 = countryByAreaCode.iso2;
            }
            if ((!!dialCode && !!iso2) || onPaste) {
                dispatch(setRegion({
                    dialCode: dialCode,
                    img: require("@core/shared/images/flags/".concat(iso2, ".svg")),
                    inputMask: getPhoneMask(iso2),
                    code: iso2,
                }));
            }
            return {
                dialCode: dialCode,
            };
        }
    };
    var handlePaste = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var clipboardData, formattedValue, dialCode;
        return __generator(this, function (_a) {
            e.preventDefault();
            clipboardData = e.clipboardData.getData('text').trim();
            formattedValue = clipboardData.replace(/[^\d;]/g, '');
            if (clipboardData.startsWith('+') && !!formattedValue) {
                dialCode = changeRegionByValue(formattedValue, true).dialCode;
                formattedValue = formattedValue.replace(dialCode, '');
            }
            setChanges(formattedValue);
            return [2 /*return*/];
        });
    }); };
    var handleOpenSelectRegions = function (isOpenLocal) {
        setOpenSelectRegions(isOpenLocal);
    };
    var handleClear = function () {
        setChanges('');
    };
    var handleChange = function (e) {
        var value = e.target.value;
        var valueForRegion = value.replace(/[^\d;]/g, '');
        setChanges(e);
        if (value.startsWith('+')) {
            changeRegionByValue(valueForRegion);
        }
        else {
            changeRegionByValue(activeRegion.code + valueForRegion);
        }
    };
    var handleRegionChanged = function (isRegionChanged) {
        isRegionChanged && animateHideError();
    };
    var setChanges = function (value) {
        animateHideError();
        handleAction && handleAction();
        mask.onChange(value);
    };
    useEffect(function () {
        if (error === null || error === void 0 ? void 0 : error.message) {
            animateShowError();
        }
    }, [error]);
    useEffect(function () {
        setValue(name, mask.input.value);
    }, [mask.input.value]);
    useEffect(function () {
        if (!(defValue === null || defValue === void 0 ? void 0 : defValue.length)) {
            return;
        }
        var formattedValue = defValue.replace(/[^\d;]/g, '');
        if (formattedValue) {
            var dialCode = (changeRegionByValue(formattedValue) || {}).dialCode;
            mask.onChange(formattedValue.replace(dialCode, ''));
        }
    }, [defValue]);
    var fieldClassNames = [
        className,
        styles.field,
        isQsure && styles.fieldQsure,
        styles.regionsField,
        isAdmin && styles.regionsFieldAdm,
    ];
    return (_jsxs("div", __assign({ className: clsx(styles.regions, classNameWrapper), ref: inputRef }, { children: [_jsxs("div", __assign({ className: clsx(fieldClassNames), ref: fieldRef }, { children: [_jsx("div", __assign({ ref: flagRef, className: clsx(styles.fieldSelect, styles.select), onClick: function () { return handleOpenSelectRegions(!isOpenSelectRegions); } }, { children: _jsx("img", { className: styles.selectFlag, src: activeRegion === null || activeRegion === void 0 ? void 0 : activeRegion.img }) })), mask.isEmptyField && (_jsx(_Fragment, { children: _jsxs("p", __assign({ className: styles.fieldPlaceholder }, { children: [_jsx("span", __assign({ className: styles.fieldPlaceholderOffset }, { children: "+".concat(activeRegion.dialCode, " ") })), getPhoneMask(activeRegion.code, '5').value] })) })), _jsx("input", __assign({ type: "tel", className: clsx(styles.fieldInput, isAdmin && styles.fieldInputIsNotBold), onPaste: handlePaste, autoFocus: autoFocus || false }, mask.input, register(name, {
                        minLength: {
                            value: 8,
                            message: 'error_required',
                        },
                        onChange: handleChange,
                    }))), !mask.isEmptyField && !isHideClose && (_jsx("div", __assign({ className: clsx(styles.fieldClear, isAdmin && styles.fieldClearAdm), onClick: handleClear }, { children: _jsx(CloseIcon, {}) })))] })), _jsx("div", __assign({ className: clsx(styles.fieldError, styles.fieldErrorHidden), ref: errorRef }, { children: error && t(String(error.message)) })), _jsx(SelectRegion, { flagElement: flagRef.current, inputElement: inputRef.current, isAdmin: isAdmin, className: classNameSelect, classNameElements: classNameElements, isOpenSelectRegions: isOpenSelectRegions, handleOpenSelectRegions: handleOpenSelectRegions, handleAction: handleAction, handleRegionChanged: handleRegionChanged })] })));
};
