import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { createForms } from '@core/pages/forms';
import { SID } from '@core/shared/models/survey';
import { initPrebooking } from '@core/store/reducers/bookingSlice';
import { completeOnboarding, initSurvey } from '@core/store/reducers/surveySlice';
export var Onboarding = function () {
    var dispatch = useDispatch();
    var state = useLocation().state;
    useEffect(function () {
        dispatch(initSurvey({ sid: SID.ONBOARDING }));
    }, [initPrebooking]);
    return createForms({
        sid: SID.ONBOARDING,
        title: t('label_new_account'),
        backRoute: 'logout',
        callback: function () {
            dispatch(completeOnboarding(state === null || state === void 0 ? void 0 : state.redirectPath));
        },
    });
};
