var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { analytics } from '@core/analytics/zipanalytics';
import { Button } from '@core/components/buttons/button';
import { Input } from '@core/components/formComponents/input';
import { PhoneInput } from '@core/components/formComponents/phoneInput';
import { Timer } from '@core/components/timer';
import { config } from '@core/config/config';
import { REDIRECT_KEY } from '@core/shared/constants/common';
import { EMAIL_REGEX, PASSWORD_REGEX } from '@core/shared/regex';
import Apple from '@core/shared/svg/apple';
import Google from '@core/shared/svg/google';
import Email from '@core/shared/svg/mail';
import Phone from '@core/shared/svg/phone';
import { getPhoneMask } from '@core/shared/utils/phoneUtils';
import { changePostition, checkRequest, logInRequest, signUpRequest, verificationPassRequest, } from '@core/store/reducers/authSlice';
import { addIsLoading, loaderType, removeIsLoading } from '@core/store/reducers/loadingSlice';
import { resetCodeInfo, verificationRequest } from '@core/store/reducers/verificationSlice';
import styles from './index.module.sass';
export var LoginModal = function (_a) {
    var closeModal = _a.closeModal, nav = _a.nav, noLogo = _a.noLogo, _b = _a.isModal, isModal = _b === void 0 ? true : _b, isQsure = _a.isQsure;
    var dispatch = useDispatch();
    var _c = useLocation(), pathname = _c.pathname, search = _c.search, hash = _c.hash;
    var _d = useParams(), phone = _d.phone, email = _d.email;
    var _e = useForm(), register = _e.register, errors = _e.formState.errors, setValue = _e.setValue, clearErrors = _e.clearErrors, control = _e.control, unregister = _e.unregister, setError = _e.setError, trigger = _e.trigger, getValues = _e.getValues;
    var user = useSelector(function (state) { return state.auth; });
    var position = user === null || user === void 0 ? void 0 : user.position;
    var _f = useSelector(function (state) { return state.loading; }), loadingType = _f.type, isLoading = _f.isLoading;
    var errorsStore = useSelector(function (state) { return state.errors; });
    var activeRegion = useSelector(function (state) { return state.helpers; }).activeRegion;
    var codeStore = useSelector(function (state) { return state.code; });
    var self = useSelector(function (state) { return state.profile; }).self;
    var isSurveyOpen = useSelector(function (state) { return state.survey; }).isSurveyOpen;
    var _g = useState(position.includes('phone') ? 'phone' : 'email'), loginType = _g[0], setLoginType = _g[1];
    var _h = useState(loginType === 'phone' ? t('btn_continue_phone') : t('btn_continue_email')), currentStepBtnLabel = _h[0], setCurrentStepBtnLabel = _h[1];
    var _j = useState(t('placeholder_password')), passwordPlaceholder = _j[0], setPasswordPlaceholder = _j[1];
    var _k = useState(false), positionLoading = _k[0], setPositionLoading = _k[1];
    var _l = useState(false), verificationLoading = _l[0], setVerificationLoading = _l[1];
    var _m = useState(false), needCloseModal = _m[0], setNeedCloseModal = _m[1];
    var _o = useState(''), defPhoneValue = _o[0], setDefPhoneValue = _o[1];
    useEffect(function () {
        clearErrors();
        setValue('password', '');
        setValue('code', '');
        if (position.includes('phone')) {
            setLoginType('phone');
        }
        else {
            setLoginType('email');
        }
        if (position === 'emailRegistrationPassword') {
            setCurrentStepBtnLabel(t('btn_create_email'));
            setPasswordPlaceholder(t('placeholder_create_password'));
        }
        if (position === 'phoneRegistrationPassword') {
            setCurrentStepBtnLabel(t('btn_create_phone'));
            setPasswordPlaceholder(t('placeholder_create_password'));
        }
        if (position === 'phoneInitial') {
            setCurrentStepBtnLabel(t('btn_continue_phone'));
            setPasswordPlaceholder(t('placeholder_password'));
        }
        if (position === 'emailInitial') {
            setCurrentStepBtnLabel(t('btn_continue_email'));
            setPasswordPlaceholder(t('placeholder_password'));
        }
    }, [position]);
    useEffect(function () {
        if (phone) {
            var formatPhone = phone.includes('+', 0) ? phone : '+' + phone;
            setDefPhoneValue(phone);
            dispatch(changePostition('phoneInitial'));
            dispatch(checkRequest({ type: 'phone', identifier: formatPhone }));
            dispatch(logInRequest({
                nextPosition: 'phoneCode',
                type: 'phone',
                loadingType: loaderType.LOGIN,
                identifier: formatPhone,
                verificationType: 'sms',
            }));
        }
        if (email) {
            var formatEmail = email.toLowerCase();
            setValue('email', formatEmail);
            dispatch(changePostition('emailInitial'));
            dispatch(checkRequest({ type: 'email', identifier: formatEmail }));
            dispatch(logInRequest({
                nextPosition: 'emailCode',
                type: 'email',
                loadingType: loaderType.LOGIN,
                identifier: formatEmail,
                verificationType: 'email',
            }));
        }
    }, [phone, email]);
    useEffect(function () {
        for (var key in errorsStore) {
            if (Object.prototype.hasOwnProperty.call(errorsStore, key)) {
                var element = errorsStore[key];
                if (element) {
                    setVerificationLoading(false);
                    setError(key, { type: 'pattern', message: element });
                }
            }
        }
    }, [errorsStore, setError]);
    useEffect(function () {
        clearErrors();
        setValue('password', '');
        setValue('code', '');
        return function () {
            clearErrors();
            dispatch(changePostition("phoneInitial"));
        };
    }, []);
    useEffect(function () {
        if (needCloseModal && (self === null || self === void 0 ? void 0 : self.id)) {
            setVerificationLoading(false);
            closeModal && closeModal();
        }
    }, [self, needCloseModal]);
    useEffect(function () {
        if (errors) {
            setVerificationLoading(false);
        }
    }, [errors]);
    useEffect(function () {
        if (isSurveyOpen) {
            closeModal && closeModal();
        }
    }, [isSurveyOpen]);
    var checkAccount = function () {
        var value = getValues(loginType);
        if (loginType === 'phone') {
            if (!value || value === activeRegion.dialCode + '  ')
                return setError(loginType, { type: 'required', message: 'error_required' });
            if (value.trim() === "+".concat(activeRegion.dialCode))
                return setError(loginType, { type: 'pattern', message: 'empty_msg' });
            var clearValue = value.replace(/[^\d;]/g, '');
            if (getPhoneMask(activeRegion.code).shouldValidate) {
                var pattern = '+' + getPhoneMask(activeRegion.code).value.replace(/[^\d;.]/g, '');
                value = '+' + clearValue;
                if (value.length !== pattern.length)
                    return setError(loginType, { type: 'pattern', message: 'error_phone_incorrect' });
            }
            value = '+' + clearValue;
        }
        dispatch(checkRequest({ type: loginType, identifier: value, isChangePos: true }));
    };
    var sendPassword = function () { return __awaiter(void 0, void 0, void 0, function () {
        var password, isOk, verificationCallback;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    password = getValues('password');
                    return [4 /*yield*/, trigger()];
                case 1:
                    isOk = _a.sent();
                    if (!isOk) {
                        return [2 /*return*/];
                    }
                    verificationCallback = function (isOnboarding) {
                        var homePath = isQsure ? '/app/' : '/';
                        var path = pathname.includes(REDIRECT_KEY) ? "".concat(pathname).concat(search).concat(hash) : homePath;
                        isOnboarding ? nav('/onboarding/', { state: { redirectPath: path } }) : nav(path);
                        setNeedCloseModal(true);
                    };
                    dispatch(verificationPassRequest({
                        type: loginType,
                        code: password,
                        identifier: user.identifier,
                        callback: verificationCallback,
                    }));
                    return [2 /*return*/];
            }
        });
    }); };
    var signUp = function (verification) {
        if (verification === void 0) { verification = ''; }
        return __awaiter(void 0, void 0, void 0, function () {
            var isOk, signUpData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, trigger('password')];
                    case 1:
                        isOk = _a.sent();
                        if (!isOk) {
                            return [2 /*return*/];
                        }
                        analytics.track('signup-attempt', {});
                        signUpData = {
                            type: loginType,
                            identifier: user.identifier,
                            password: getValues('password'),
                        };
                        if (verification) {
                            signUpData.verification = verification;
                        }
                        if (!verification) {
                            signUpData.nextPosition = "".concat(loginType, "RegistrationCode");
                        }
                        dispatch(signUpRequest(signUpData));
                        return [2 /*return*/];
                }
            });
        });
    };
    var sendCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var code, verificationCallback;
        return __generator(this, function (_a) {
            code = getValues('code');
            verificationCallback = function (isOnboarding) {
                var homePath = isQsure ? '/app/' : '/';
                var path = pathname.includes(REDIRECT_KEY) ? "".concat(pathname).concat(search).concat(hash) : homePath;
                isOnboarding ? nav('/onboarding/', { state: { redirectPath: path } }) : nav(path);
                setNeedCloseModal(true);
            };
            dispatch(verificationRequest({
                id: codeStore.verification_id,
                code: code,
                callback: verificationCallback,
            }));
            return [2 /*return*/];
        });
    }); };
    var handleChangePhoneMethodClick = function () {
        setPositionLoading(true);
        var type = position === 'phoneRegistrationCall' ? '' : 'call';
        var newPosition = position === 'phoneRegistrationCall' ? 'phoneRegistrationCode' : 'phoneRegistrationCall';
        dispatch(changePostition(newPosition));
        signUp(type);
    };
    var handleChangeCodeToPassword = function () {
        clearErrors();
        setPositionLoading(false);
        dispatch(changePostition("".concat(loginType, "Password")));
    };
    var handleChangePasswordToCode = function () {
        clearErrors();
        setPositionLoading(false);
        var identifier = getValues(loginType);
        if (loginType === 'phone') {
            identifier = identifier.includes('+', 0) ? identifier : '+' + identifier;
        }
        else {
            identifier = identifier.toLowerCase();
        }
        dispatch(logInRequest({
            nextPosition: "".concat(loginType, "Code"),
            type: loginType,
            loadingType: loaderType.LOGIN,
            identifier: identifier,
            verificationType: loginType === 'email' ? 'email' : 'sms',
        }));
        dispatch(changePostition("".concat(loginType, "Code")));
    };
    var handleNextStep = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    clearErrors();
                    _a = position;
                    switch (_a) {
                        case 'emailInitial': return [3 /*break*/, 1];
                        case 'phoneInitial': return [3 /*break*/, 1];
                        case 'emailPassword': return [3 /*break*/, 2];
                        case 'phonePassword': return [3 /*break*/, 2];
                        case 'emailRegistrationPassword': return [3 /*break*/, 3];
                        case 'phoneRegistrationPassword': return [3 /*break*/, 3];
                        case 'emailCode': return [3 /*break*/, 5];
                        case 'phoneCode': return [3 /*break*/, 5];
                        case 'emailRegistrationCode': return [3 /*break*/, 5];
                        case 'phoneRegistrationCode': return [3 /*break*/, 5];
                        case 'phoneRegistrationCall': return [3 /*break*/, 5];
                        case 'callCode': return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 6];
                case 1:
                    dispatch(addIsLoading(loaderType.CHECK_USER));
                    setTimeout(function () {
                        checkAccount();
                    }, 300);
                    dispatch(removeIsLoading());
                    return [3 /*break*/, 6];
                case 2:
                    setVerificationLoading(true);
                    setTimeout(function () {
                        sendPassword();
                    }, 300);
                    return [3 /*break*/, 6];
                case 3: return [4 /*yield*/, signUp()];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 6];
                case 5:
                    setVerificationLoading(true);
                    setTimeout(function () {
                        sendCode();
                    }, 300);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleLoginApple = function () {
        analytics.track('login-attempt', { method: 'apple' });
        var app = isQsure ? config.appQsure : config.app;
        var device = window.navigator.userAgent;
        dispatch(addIsLoading('auth/appleSignIn'));
        window.location.href = "".concat(config.authService, "/auth/login/apple?app=").concat(app, "&device=").concat(device);
        setTimeout(function () {
            dispatch(removeIsLoading());
        }, 3000);
    };
    var handleLoginGoogle = function () {
        analytics.track('login-attempt', { method: 'google' });
        var app = isQsure ? config.appQsure : config.app;
        var device = window.navigator.userAgent;
        dispatch(addIsLoading('auth/googleSignIn'));
        window.location.href = "".concat(config.authService, "/auth/login/google?app=").concat(app, "&device=").concat(device);
        setTimeout(function () {
            dispatch(removeIsLoading());
        }, 3000);
    };
    var handleSetLoginPhone = function () {
        dispatch(changePostition('phoneInitial'));
        setValue('email', '');
        setValue('phone', '');
    };
    var handleSetLoginEmail = function () {
        dispatch(changePostition('emailInitial'));
        setValue('email', '');
        setValue('phone', '');
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter') {
            handleNextStep();
        }
    };
    return (_jsxs("div", __assign({ className: clsx(styles.login, isQsure && styles.loginQsure, isModal && styles.loginModal), style: noLogo && { justifyContent: 'flex-start', minHeight: '100%', padding: 0 }, onKeyDown: handleKeyDown }, { children: [_jsx("div", {}), _jsxs("div", __assign({ className: styles.loginContent, style: noLogo && { width: '100%' } }, { children: [_jsx("div", __assign({ className: clsx(styles.logo, isQsure && styles.logoQsure), style: noLogo && { display: 'none' } }, { children: isQsure ? (_jsx("img", { src: require('@core/shared/images/qsure/logo.png'), alt: "QSure logo" })) : (_jsx("img", { src: require('@core/shared/images/wholeqLogo/logo@3x.png'), srcSet: "".concat(require("@core/shared/images/wholeqLogo/logo@3x.png"), " 2x,\n              ").concat(require("@core/shared/images/wholeqLogo/logo@3x.png"), " 3x"), alt: "WholeQ logo" })) })), _jsxs("div", __assign({ className: styles.controls }, { children: [loginType === 'phone' ? (_jsx(PhoneInput, { autoFocus: true, isQsure: isQsure, defValue: defPhoneValue, setValue: setValue, handleAction: function () {
                                    clearErrors('phone');
                                    if (position !== 'phoneInitial') {
                                        dispatch(changePostition('phoneInitial'));
                                        dispatch(resetCodeInfo());
                                    }
                                }, error: errors.phone, register: register, clearErrors: clearErrors })) : (_jsx(Input, { label: "email", isQsure: isQsure, autofocus: true, placeholder: t('placeholder_your_email'), type: "email", control: control, register: register, clearErrors: clearErrors, unregister: unregister, error: errors.email, setEmpty: function () {
                                    setValue('email', '');
                                    clearErrors('email');
                                    if (position !== 'emailInitial') {
                                        dispatch(changePostition('emailInitial'));
                                        dispatch(resetCodeInfo());
                                    }
                                }, validation: {
                                    required: { value: true, message: 'error_required' },
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: 'error_email_incorrect',
                                    },
                                    onChange: function () {
                                        clearErrors('email');
                                        if (position !== 'emailInitial') {
                                            dispatch(changePostition('emailInitial'));
                                            dispatch(resetCodeInfo());
                                        }
                                    },
                                } })), (position.includes('Code') || position.includes('Call')) && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.codeText }, { children: t('label_code') })), _jsx(Input, { autofocus: true, isQsure: isQsure, label: "code", placeholder: t('placeholder_code'), type: "code", control: control, register: register, clearErrors: clearErrors, unregister: unregister, error: errors.code, setEmpty: function () {
                                            setValue('code', '');
                                        }, validation: {
                                            required: { value: true, message: 'error_required' },
                                            maxLength: { value: 5, message: 'error_auth_code_max_length' },
                                            onChange: function () {
                                                clearErrors('code');
                                            },
                                        } })] })), position.includes('Password') && (_jsx(Input, { autofocus: true, isQsure: isQsure, label: "password", placeholder: passwordPlaceholder, type: "password", control: control, register: register, clearErrors: clearErrors, unregister: unregister, error: errors.password, setEmpty: function () {
                                    setValue('password', '');
                                }, validation: {
                                    required: { value: true, message: 'error_required' },
                                    pattern: {
                                        value: PASSWORD_REGEX,
                                        message: 'error_auth_password_requirements',
                                    },
                                    onChange: function () {
                                        clearErrors('password');
                                    },
                                } })), _jsx(Button, { primary: true, classes: [isQsure && styles.qsurePrimary], handler: handleNextStep, label: currentStepBtnLabel, loading: (isLoading &&
                                    (loadingType === loaderType.CHECK_USER ||
                                        loadingType === loaderType.LOGIN ||
                                        loadingType === loaderType.SIGN_UP ||
                                        loadingType === loaderType.VERIF ||
                                        loadingType === loaderType.VERIF_PASS)) ||
                                    verificationLoading }), position.includes('Code') && _jsx(Timer, { handle: handleChangePasswordToCode }), (position === 'phoneCode' || position === 'emailCode') && (_jsx("div", __assign({ className: styles.changeMethod }, { children: positionLoading ? (_jsx("div", { className: styles.bubbleLoader })) : (_jsx("div", __assign({ className: clsx(styles.changeMethodInner, styles["changeMethodBottom".concat(loginType)]), onClick: handleChangeCodeToPassword }, { children: _jsx("span", __assign({ className: styles.changeMethodBtn }, { children: t('label_password_method') })) }))) }))), (position === 'phoneRegistrationCode' ||
                                position === 'phoneCode' ||
                                position === 'phoneRegistrationCall' ||
                                position === 'callCode') && (_jsx("div", __assign({ className: styles.changeMethod }, { children: positionLoading ? (_jsx("div", { className: styles.bubbleLoader })) : (_jsxs("div", __assign({ className: styles.changeMethodInner }, { children: [_jsxs("span", __assign({ className: styles.changeMethodInnerText }, { children: [position === 'phoneRegistrationCall'
                                                    ? t('label_pre_sms_method')
                                                    : t('label_pre_call_method'), "\u00A0"] })), _jsx("span", __assign({ className: styles.changeMethodBtn, onClick: handleChangePhoneMethodClick }, { children: position === 'phoneRegistrationCall'
                                                ? t('label_sms_method')
                                                : t('label_call_method') }))] }))) }))), (position === 'emailPassword' || position === 'phonePassword') && (_jsx("div", __assign({ className: styles.changeMethod }, { children: positionLoading ? (_jsx("div", { className: styles.bubbleLoader })) : (_jsx("div", __assign({ className: styles.changeMethodInner, onClick: handleChangePasswordToCode }, { children: _jsx("span", __assign({ className: styles.changeMethodBtn }, { children: t('label_code_method') })) }))) }))), !position.includes('Call') && !position.includes('Code') && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.dash }, { children: t('label_dash') })), loginType === 'email' && (_jsx(Button, { classes: [styles.btn, isQsure && styles.qsure], handler: handleSetLoginPhone, label: t('btn_continue_phone'), icon: _jsx(Phone, {}) })), loginType === 'phone' && (_jsx(Button, { classes: [styles.btn, isQsure && styles.qsure], handler: handleSetLoginEmail, label: t('btn_continue_email'), icon: _jsx(Email, {}) })), _jsx(Button, { classes: [isQsure && styles.qsure], handler: handleLoginGoogle, label: t('btn_continue_google'), icon: _jsx(Google, {}), loading: isLoading && loadingType === loaderType.GOOGLE_lOGIN }), _jsx(Button, { classes: [isQsure && styles.qsure], handler: handleLoginApple, label: t('btn_continue_apple'), icon: _jsx(Apple, {}), loading: isLoading && loadingType === loaderType.APPLE_lOGIN })] }))] }))] })), _jsxs("div", __assign({ className: clsx(styles.terms, isQsure && styles.termsQsure), style: noLogo && { marginTop: '40px' } }, { children: [t('label_text_terms'), _jsx("span", __assign({ className: styles.termsLink, onClick: function () { return nav('#tos'); } }, { children: "".concat(t('label_ziphy_terms')) })), t('label_and_terms'), _jsx("span", __assign({ className: styles.termsLink, onClick: function () { return nav('#pp'); } }, { children: "".concat(t('label_policy_terms'), ".") }))] }))] })));
};
