var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    tempParams: null,
    finishCallback: null,
    backRoute: null,
    surveyId: null,
    surveySid: null,
    isLoading: false,
    fullSurvey: {
        branches: [],
        content: [],
        survey: {
            answer_safe: [],
            answers: [],
            created: null,
            instance_id: null,
            modified: null,
            next_step_id: null,
            state_id: null,
            status: null,
            user_id: null,
            version: null,
            _id: null,
        },
    },
    answers: [],
    media: [],
    currentStep: {},
    dataForCancelQuestionEdit: {},
    retryCounter: 0,
    validateInsurance: null,
    pause: false,
    patient: null,
    isSurveyOpen: false,
};
export var surveySlice = createSlice({
    name: 'survey',
    initialState: initialState,
    reducers: {
        initSurvey: function (state, __) {
            state.isSurveyOpen = true;
        },
        getSurveyFull: function () { },
        handleSurveyUploadMedia: function (_, __) { },
        handleSurveyNextButtonStep: function (_, __) { },
        handleSurveyQuestionEdit: function (_, __) { },
        setSurveyNextStep: function (state, __) {
            state.retryCounter = 0;
        },
        handleCancelQuestionEdit: function () { },
        handleCancelUploadMedia: function () { },
        handleSurveyFinish: function () { },
        handleDeleteEntity: function (_, __) { },
        setPatient: function (state, action) {
            state.patient = action.payload;
        },
        upRetry: function (state) {
            var tmp = state.retryCounter;
            state.retryCounter = tmp + 1;
        },
        setRetry: function (state, action) {
            state.retryCounter = action.payload;
        },
        clearRetry: function (state) {
            state.retryCounter = 0;
        },
        completeOnboarding: function (state, _action) {
            state.isSurveyOpen = false;
        },
        completeRisksTest: function (state, _action) {
            state.isSurveyOpen = false;
        },
        setFinishCallback: function (state, action) {
            state.finishCallback = action.payload;
        },
        setSurveyStatus: function (state, action) {
            state.fullSurvey.survey.status = action.payload.status;
        },
        setSurveyLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setSurveyId: function (state, action) {
            state.surveyId = action.payload;
        },
        setSurveySid: function (state, action) {
            state.surveySid = action.payload;
        },
        setSurveyBackRoute: function (state, action) {
            state.backRoute = action.payload;
        },
        setFullSurvey: function (state, action) {
            state.fullSurvey = action.payload;
        },
        updateSurveyData: function (state, action) {
            state.fullSurvey.survey = action.payload;
        },
        setSurveyAnswers: function (state, action) {
            state.answers = action.payload;
        },
        setSurveyStep: function (state, action) {
            state.currentStep = action.payload;
        },
        setDataForCancelQuestionEdit: function (state, action) {
            state.dataForCancelQuestionEdit = action.payload;
        },
        setMedia: function (state, action) {
            var tempMedia = __spreadArray(__spreadArray([], action.payload, true), state.media, true);
            state.media = tempMedia.reduce(function (accum, mediaA) {
                if (!accum.find(function (mediaB) { return mediaB.slot_id === mediaA.slot_id; })) {
                    accum.push(mediaA);
                }
                return accum;
            }, []);
        },
        setValidateInsurance: function (state, action) {
            if (action.payload === 'success' || action.payload === 'successUpload') {
                state.pause = true;
            }
            state.validateInsurance = action.payload;
        },
        setPause: function (state, action) {
            state.pause = action.payload;
        },
        setTempParams: function (state, action) {
            state.tempParams = action.payload;
        },
        clearSurvey: function () { return initialState; },
    },
});
export var initSurvey = (_a = surveySlice.actions, _a.initSurvey), setSurveyLoading = _a.setSurveyLoading, setSurveyStatus = _a.setSurveyStatus, getSurveyFull = _a.getSurveyFull, setSurveyId = _a.setSurveyId, setSurveySid = _a.setSurveySid, setFullSurvey = _a.setFullSurvey, updateSurveyData = _a.updateSurveyData, setSurveyAnswers = _a.setSurveyAnswers, setSurveyStep = _a.setSurveyStep, handleSurveyUploadMedia = _a.handleSurveyUploadMedia, handleSurveyNextButtonStep = _a.handleSurveyNextButtonStep, handleSurveyQuestionEdit = _a.handleSurveyQuestionEdit, handleCancelQuestionEdit = _a.handleCancelQuestionEdit, handleCancelUploadMedia = _a.handleCancelUploadMedia, setDataForCancelQuestionEdit = _a.setDataForCancelQuestionEdit, setSurveyNextStep = _a.setSurveyNextStep, setMedia = _a.setMedia, handleSurveyFinish = _a.handleSurveyFinish, clearSurvey = _a.clearSurvey, setPause = _a.setPause, setValidateInsurance = _a.setValidateInsurance, handleDeleteEntity = _a.handleDeleteEntity, setFinishCallback = _a.setFinishCallback, upRetry = _a.upRetry, setRetry = _a.setRetry, setTempParams = _a.setTempParams, clearRetry = _a.clearRetry, setPatient = _a.setPatient, completeOnboarding = _a.completeOnboarding, completeRisksTest = _a.completeRisksTest;
export default surveySlice.reducer;
