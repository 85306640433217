var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { Button } from '@components/button';
import { Container } from '@components/landing/container';
import { Hero } from '@components/landing/hero';
import { Services } from '@components/landing/services';
import { PlansCards } from '@components/plansCards';
// import { SliderLanding } from '@components/sliderLanding'
import { plansKit } from '@root/src/configs/landing/plansKit';
import styles from './index.module.sass';
export var Home = function () {
    var navigate = useNavigate();
    var cardsRef = useRef(null);
    var subscriptionProducts = useSelector(function (s) { return s.subscriptions.subscriptionProducts; });
    function handleScroll() {
        var _a;
        (_a = cardsRef === null || cardsRef === void 0 ? void 0 : cardsRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
    var handlerPlan = function (data, isAnnyally) {
        var interval = isAnnyally ? 'year' : 'month';
        navigate("/app/checkout/?id=".concat(data._id, "&interval=").concat(interval));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Container, __assign({ classNames: styles.container }, { children: [_jsx(Hero, { handleScroll: handleScroll }), _jsx(Services, {})] })), _jsx("div", __assign({ className: styles.employers }, { children: _jsxs(Container, __assign({ classNames: styles.container }, { children: [_jsx("div", __assign({ className: styles.employersName }, { children: t('label_landing_home_employers_name') })), _jsx("div", __assign({ className: styles.employersTitle }, { children: t('label_landing_home_employers_title') })), _jsx("div", __assign({ className: styles.employersText }, { children: t('label_landing_home_employers_text') })), _jsx(Link, __assign({ to: routePaths.EMPLOYERS, className: styles.employersBtn }, { children: _jsx(Button, { label: t('label_landing_home_employers_btn') }) }))] })) })), _jsxs(Container, { children: [_jsxs("div", __assign({ className: styles.plansCards, ref: cardsRef }, { children: [_jsx("div", __assign({ className: styles.name }, { children: t('label_landing_home_plans_name') })), _jsx("div", __assign({ className: styles.title }, { children: t('label_landing_home_plans_title') })), _jsx("div", __assign({ className: styles.text }, { children: t('label_landing_home_plans_text') })), _jsx(PlansCards, { kits: plansKit, data: subscriptionProducts.filter(function (i) { return !i.includes_family; }), btnHandler: handlerPlan })] })), _jsxs("div", __assign({ className: styles.bottomBtn }, { children: [_jsx("div", __assign({ className: styles.bottomBtnText }, { children: t('label_landing_home_plans_btn_desc') })), _jsx(Button, { label: t('label_landing_home_plans_btn'), gray: true, handler: function () { return navigate(routePaths.CONSUMERS, { state: 'toTable' }); } })] }))] })] }));
};
