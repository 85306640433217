var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var ArrowLeft = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", __assign({ opacity: "0.25" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.4144 19.5858C17.8049 19.9763 17.8049 20.6095 17.4144 21C17.0239 21.3905 16.3907 21.3905 16.0002 21L9.29306 14.2929L16.0002 7.5858C16.3907 7.19528 17.0239 7.19528 17.4144 7.5858C17.8049 7.97633 17.8049 8.60949 17.4144 9.00001L12.1215 14.2929L17.4144 19.5858Z", fill: "black" }) })) }))); };
export default ArrowLeft;
