import { docsWatcher } from '@core/store/sagas/docs';
import { eventsWatcher } from '@core/store/sagas/events';
import { supportWatcher } from '@core/store/sagas/support';
import { accountMergeWatcher } from './accountMerge';
import { authWatcher } from './auth';
import { bookingWatcher } from './booking';
import { bookingNewWatcher } from './bookingNew';
import { doctorVisitWatcher } from './doctorVisit';
import { mapWatcher } from './map';
import { newOrderWatcher } from './new-order';
import { paymentsWatcher } from './payments';
import { prescriptionWatcher } from './prescription';
import { profileWatcher } from './profiles';
import { reportsWatcher } from './reports';
import { sessionWatcher } from './session';
import { shopWatcher } from './shop';
import { subscriptionWatcher } from './subscription';
import { surveyWatcher } from './survey';
import { verificationWatcher } from './verificataion';
export var RootCoreSaga = [
    accountMergeWatcher(),
    authWatcher(),
    profileWatcher(),
    verificationWatcher(),
    mapWatcher(),
    surveyWatcher(),
    newOrderWatcher(),
    supportWatcher(),
    bookingWatcher(),
    sessionWatcher(),
    docsWatcher(),
    eventsWatcher(),
    prescriptionWatcher(),
    reportsWatcher(),
    shopWatcher(),
    paymentsWatcher(),
    bookingNewWatcher(),
    subscriptionWatcher(),
    doctorVisitWatcher(),
];
