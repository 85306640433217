var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Shield = function () { return (_jsxs("svg", __assign({ width: "60", height: "60", viewBox: "0 0 60 60", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "30", cy: "30", r: "30", fill: "#FFCC37" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M28.9426 16.9361C29.5276 16.3546 30.4724 16.3546 31.0574 16.9361C33.6679 19.5306 37.1055 19.7162 41.2725 19.502C41.9825 19.4655 42.6207 19.9325 42.8006 20.6203C44.115 25.6433 43.5635 32.553 41.2566 37.1263C38.9481 41.7028 35.0553 45.0893 30.4196 46.4401C30.1456 46.52 29.8544 46.52 29.5804 46.4401C24.9446 45.0893 21.0518 41.7028 18.7434 37.1263C16.4365 32.553 15.8849 25.6433 17.1993 20.6203C17.3793 19.9325 18.0174 19.4655 18.7275 19.502C22.8944 19.7162 26.3321 19.5306 28.9426 16.9361ZM19.8358 22.5511C19.067 26.5383 19.6273 32.2176 21.4219 35.7752C23.318 39.5342 26.4137 42.2537 30 43.43C33.5863 42.2537 36.682 39.5342 38.5781 35.7752C40.3726 32.2176 40.9329 26.5383 40.1642 22.5511C36.7444 22.6551 33.0731 22.3784 30 19.9946C26.9268 22.3784 23.2556 22.6551 19.8358 22.5511Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.258 31.5C24.258 30.6716 24.9296 30 25.758 30L28.5007 30L28.5007 27.2574C28.5007 26.4289 29.1723 25.7574 30.0007 25.7574C30.8291 25.7574 31.5007 26.4289 31.5007 27.2574L31.5007 30L34.2433 30C35.0717 30 35.7433 30.6716 35.7433 31.5C35.7433 32.3284 35.0717 33 34.2433 33L31.5007 33L31.5007 35.7426C31.5007 36.5711 30.8291 37.2426 30.0007 37.2426C29.1723 37.2426 28.5007 36.5711 28.5007 35.7426L28.5007 33L25.758 33C24.9296 33 24.258 32.3284 24.258 31.5Z", fill: "black" })] }))); };
export default Shield;
