var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    type: '',
    isLoading: false,
    isError: false,
};
export var loaderType;
(function (loaderType) {
    loaderType["CHECK_USER"] = "auth/checkRequest";
    loaderType["LOGIN"] = "auth/logInRequest";
    loaderType["SIGN_UP"] = "auth/signUpRequest";
    loaderType["VERIF_PASS"] = "auth/verificationPassRequest";
    loaderType["GOOGLE_lOGIN"] = "auth/googleSignIn";
    loaderType["APPLE_lOGIN"] = "auth/appleSignIn";
    loaderType["ADD_ACCOUNT"] = "profile/addAccountReq";
    loaderType["GET_DATA"] = "profile/getData";
    loaderType["EDIT_STAFF_DETAILS"] = "staff/editDetails";
    loaderType["EDIT_STAFF_ACCESSES"] = "staff/editAccesses";
    loaderType["DELETE_STAFF_ROLE"] = "staff/deleteRole";
    loaderType["VERIF"] = "verification/verificationRequest";
})(loaderType || (loaderType = {}));
export var loadingSlice = createSlice({
    name: 'loading',
    initialState: initialState,
    reducers: {
        addIsLoading: function (state, action) {
            return (state = {
                type: action.payload,
                isLoading: true,
                isError: false,
            });
        },
        setErrorIsLoading: function (state, action) {
            return (state = {
                type: action.payload,
                isLoading: true,
                isError: true,
            });
        },
        removeIsLoading: function () { return initialState; },
    },
});
export var addIsLoading = (_a = loadingSlice.actions, _a.addIsLoading), removeIsLoading = _a.removeIsLoading, setErrorIsLoading = _a.setErrorIsLoading;
export default loadingSlice.reducer;
