var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    initBookingReqStat: null,
    initPrebookingReqStat: null,
    getOfficeReqStat: null,
    patient: null,
    insurances: [],
    selectedInsurance: null,
    activeOrder: null,
    currentSection: 'confirmInsurance',
    answeredSectionsId: [],
    initCovidProceduresReqStat: null,
    initOtherProceduresReqStat: null,
    addInsuranceReqStat: null,
    getPrescriptionsReqStat: null,
    addPrescriptionsReqStat: null,
    deletePrescriptionsReqStat: null,
    submitOrderReqStat: null,
    cancelingOrderReqStat: null,
    loading: false,
    procedures: null,
    proceduresWithOffices: null,
    selectedLabs: [],
    reasons: [],
    additionalReasons: {},
    person: null,
    officesList: [],
    office: null,
    approvedPatient: false,
    insuranceVerifying: 'none',
    getBlockedProceduresStatus: 'none',
    blockedProcedures: [],
    prescriptions: [],
};
export var bookingSlice = createSlice({
    name: 'patient',
    initialState: initialState,
    reducers: {
        setBookingData: function (state, _a) {
            var _b;
            var payload = _a.payload;
            return __assign(__assign({}, state), (_b = {}, _b[payload.key] = payload.value, _b));
        },
        initBooking: function (state, _) {
            state.initBookingReqStat = 'loading';
        },
        initBookingSuccess: function (state, action) {
            state.patient = action.payload.person;
            state.activeOrder = action.payload.activeOrder;
            state.initBookingReqStat = 'success';
        },
        initPrebooking: function (state, _) {
            state.initPrebookingReqStat = 'loading';
        },
        initPrebookingSuccess: function (state, action) {
            state.patient = action.payload;
            state.initPrebookingReqStat = 'success';
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        setPerson: function (state, action) {
            state.person = action.payload;
        },
        getProcedures: function () { },
        setSelectedLabs: function (state, action) {
            state.selectedLabs = action.payload;
        },
        handleClickButton: function (state, action) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            var _m = action.payload, type = _m.type, data = _m.data;
            if (type === 'setCurrentSection') {
                state.currentSection = action.payload.data;
            }
            if (type === 'confirmInsurance') {
                state.addInsuranceReqStat = 'loading';
            }
            if (type === 'selectProceduresGroup') {
                if (data.group === 'covid') {
                    state.initCovidProceduresReqStat = 'loading';
                }
                if (data.group === 'other') {
                    state.initOtherProceduresReqStat = 'loading';
                }
            }
            if (type === 'uploadPrescriptionsSelectType') {
                if (data.selectType === 'bring') {
                    state.currentSection = 'preSelectOffice';
                }
                if (data.selectType === 'upload') {
                    state.currentSection = 'uploadPrescriptions';
                }
                if (data.selectType === 'askDoctor') {
                    state.currentSection = 'askDoctor';
                }
                state.answeredSectionsId = __spreadArray(__spreadArray([], state.answeredSectionsId, true), ['uploadPrescriptionsSelectType'], false);
            }
            if (type === 'askDoctor') {
                state.cancelingOrderReqStat = 'loading';
            }
            if (type === 'selectReasons') {
                state.selectedLabs = action.payload.data.selectedLabs;
                state.reasons = action.payload.data.selectedReasons;
                state.answeredSectionsId = __spreadArray(__spreadArray([], state.answeredSectionsId, true), [type], false);
                var labKey = (_d = Object.keys((_c = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.selectedReasons) === null || _c === void 0 ? void 0 : _c[0])) === null || _d === void 0 ? void 0 : _d[0];
                if (labKey &&
                    ((_l = (_k = (_j = (_h = (_g = (_f = (_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.selectedReasons) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h[labKey]) === null || _j === void 0 ? void 0 : _j.additional_reasons) === null || _k === void 0 ? void 0 : _k.items) === null || _l === void 0 ? void 0 : _l.length)) {
                    state.currentSection = 'selectAdditionalReasons';
                }
                else {
                    state.currentSection = 'selectLabs';
                }
            }
            if (type === 'selectAdditionalReasons') {
                state.additionalReasons = action.payload.data;
                state.answeredSectionsId = __spreadArray(__spreadArray([], state.answeredSectionsId, true), [type], false);
                state.currentSection = 'selectLabs';
            }
            if (type === 'selectLabs') {
                state.selectedLabs = action.payload.data;
                state.answeredSectionsId = __spreadArray(__spreadArray([], state.answeredSectionsId, true), [type], false);
                state.currentSection = 'uploadPrescriptionsSelectType';
            }
            if (type === 'preSelectOffice') {
                state.office = null;
                state.answeredSectionsId = __spreadArray(__spreadArray([], state.answeredSectionsId, true), [type], false);
                state.currentSection = 'selectOffice';
            }
            if (type === 'preSelectOfficeSkip') {
                state.office = null;
                state.answeredSectionsId = __spreadArray(__spreadArray([], state.answeredSectionsId, true), [type], false);
                state.currentSection = 'submitOrder';
            }
            if (type === 'selectOffice') {
                state.answeredSectionsId = __spreadArray(__spreadArray([], state.answeredSectionsId, true), [type], false);
                state.currentSection = 'submitOrder';
            }
            // saga actions
            if (type === 'submitOrder') {
                state.submitOrderReqStat = 'loading';
            }
        },
        getPrescriptions: function (state) {
            state.getPrescriptionsReqStat = 'loading';
        },
        addPrescriptions: function (state) {
            state.addPrescriptionsReqStat = 'loading';
        },
        deletePrescriptions: function (state, action) {
            if (!action.payload.record_id) {
                state.prescriptions = state.prescriptions.filter(function (prescription) { return prescription.url !== action.payload.url; });
                return;
            }
            state.deletePrescriptionsReqStat = 'loading';
        },
        handleSelectPatient: function (_, __) { },
        setPatient: function (state, action) {
            state.patient = action.payload;
        },
        getOffice: function (_, __) { },
        setOffice: function (state, action) {
            state.office = action.payload;
        },
        clearBookingSlice: function (state, action) {
            var _a;
            if ((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.deletePerson) {
                return initialState;
            }
            else {
                return __assign(__assign({}, initialState), { blockedProcedures: state.blockedProcedures, person: state.person });
            }
        },
        setInsurances: function (state, action) {
            state.insurances = action.payload;
        },
        setSelectedInsurance: function (state, action) {
            state.selectedInsurance = action.payload;
        },
        replaceInsurances: function (state, action) {
            if (state.selectedInsurance._id === action.payload._id) {
                state.selectedInsurance = action.payload;
            }
            state.insurances = state.insurances.map(function (el) {
                if (el._id === action.payload._id) {
                    return action.payload;
                }
                return el;
            });
            state.insuranceVerifying = 'done';
        },
        cleanInsuranceVerification: function (state) {
            state.insuranceVerifying = 'none';
        },
        //SUPER
        getProceduresByCurrentOffices: function (_, __) { },
        getBlockedProceduresReq: function (state, _) {
            state.getBlockedProceduresStatus = 'loading';
        },
        getBlockedProceduresSuccess: function (state, action) {
            state.blockedProcedures = action.payload;
            state.getBlockedProceduresStatus = 'success';
        },
        getBlockedProceduresReset: function (state) {
            state.blockedProcedures = [];
            state.getBlockedProceduresStatus = 'none';
        },
        searchPatientByName: function (_, __) { },
        searchPatientByMRN: function (_, __) { },
        handleSelectSearchedPerson: function (_, __) { },
        clearPatient: function (state) {
            state.patient = null;
            state.approvedPatient = false;
        },
        setOfficesList: function (state, action) {
            state.officesList = action.payload;
        },
        validateInsurance: function (state, __) {
            state.insuranceVerifying = 'loading';
        },
    },
});
export var setBookingData = (_a = bookingSlice.actions, _a.setBookingData), setLoading = _a.setLoading, setPatient = _a.setPatient, initBooking = _a.initBooking, initBookingSuccess = _a.initBookingSuccess, initPrebooking = _a.initPrebooking, initPrebookingSuccess = _a.initPrebookingSuccess, getProcedures = _a.getProcedures, setSelectedLabs = _a.setSelectedLabs, setPerson = _a.setPerson, handleClickButton = _a.handleClickButton, getPrescriptions = _a.getPrescriptions, addPrescriptions = _a.addPrescriptions, deletePrescriptions = _a.deletePrescriptions, getOffice = _a.getOffice, setOffice = _a.setOffice, clearBookingSlice = _a.clearBookingSlice, getProceduresByCurrentOffices = _a.getProceduresByCurrentOffices, getBlockedProceduresReq = _a.getBlockedProceduresReq, getBlockedProceduresSuccess = _a.getBlockedProceduresSuccess, getBlockedProceduresReset = _a.getBlockedProceduresReset, searchPatientByName = _a.searchPatientByName, searchPatientByMRN = _a.searchPatientByMRN, handleSelectSearchedPerson = _a.handleSelectSearchedPerson, setOfficesList = _a.setOfficesList, setInsurances = _a.setInsurances, setSelectedInsurance = _a.setSelectedInsurance, validateInsurance = _a.validateInsurance, replaceInsurances = _a.replaceInsurances, cleanInsuranceVerification = _a.cleanInsuranceVerification;
export default bookingSlice.reducer;
