var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import styles from './index.module.sass';
export var BaseCard = function (_a) {
    var handleOnClick = _a.handleOnClick, children = _a.children, classNames = _a.classNames, disabled = _a.disabled;
    return (_jsx("div", __assign({ className: clsx(styles.container, !!classNames && classNames, disabled && styles.containerDisabled), onClick: function () { return !disabled && handleOnClick(); } }, { children: children })));
};
