import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translationEn from './en.json';
var resources = {
    en: {
        translation: translationEn,
    },
};
i18n.use(initReactI18next).init({
    resources: resources,
    lng: 'en',
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;
