var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import { Input as ChatInput } from '@core/components/formComponents/input';
import { TextMessage } from '@core/components/surveyMessages/textMessage';
import { Timer } from '@core/components/timer';
import { mergeFhirAccount, mergeSameAccount, } from '@core/store/reducers/accountMergeSlice';
import styles from './index.module.sass';
export var ConfirmationCode = function (_a) {
    var onNext = _a.onNext, buttonLoading = _a.buttonLoading, handleClose = _a.handleClose, listType = _a.listType, contact = _a.contact;
    var form = useForm();
    var dispatch = useDispatch();
    var _b = useSelector(function (state) { return state; }), accountMerge = _b.accountMerge, errors = _b.errors;
    var _c = useState('code'), codePosition = _c[0], setCodePosition = _c[1];
    var _d = useState(false), positionLoading = _d[0], setPositionLoading = _d[1];
    var onChangePositionClick = function () {
        var type = codePosition === 'call' ? 'code' : 'call';
        setPositionLoading(true);
        handleResend(type, function () {
            setCodePosition(type);
            setPositionLoading(false);
        });
    };
    var handleConfirm = function () {
        var valid = form.trigger();
        if (valid) {
            var val = form.getValues('code');
            onNext(val, true);
        }
    };
    var handleResend = function (type, callback) {
        if (listType === 'accounts') {
            dispatch(mergeSameAccount(__assign(__assign({ account: contact }, (callback && { callback: callback })), (type === 'call' && { verification: 'call' }))));
        }
        else {
            dispatch(mergeFhirAccount(__assign(__assign({ account: contact }, (callback && { callback: callback })), (type === 'call' && { verification: 'call' }))));
        }
    };
    useEffect(function () {
        for (var key in errors) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
                var element = errors[key];
                if (element)
                    form.setError(key, { type: 'pattern', message: element });
            }
        }
    }, [errors, form.setError]);
    return (_jsxs("div", __assign({ className: clsx(styles.confirmationCode, styles.height) }, { children: [_jsxs(TextMessage, { children: [t('account_merge_confirmation_code'), " ", accountMerge.currentAccount] }), accountMerge.isAddAccount ? (_jsxs(_Fragment, { children: [_jsx(TextMessage, { children: t('account_merge_success') }), _jsx(Button, { classes: [styles.confirmationCodeBtn], label: t('btn_confirm'), primary: true, handler: handleClose })] })) : (_jsxs(_Fragment, { children: [_jsx(ChatInput, { placeholder: t('account_merge_confirmation_code_label'), type: "text", label: 'code', classes: [styles.chatInput], inputmode: "numeric", autocomplete: "one-time-code", autofocus: true, control: form.control, register: form.register, clearErrors: form.clearErrors, unregister: form.unregister, error: form.formState.errors['code'], setEmpty: function () {
                            form.setValue('code', '');
                        }, validation: {
                            required: { value: true, message: 'error_required' },
                            onChange: function () {
                                form.clearErrors('code');
                            },
                        }, disable: buttonLoading }), _jsxs("div", __assign({ className: styles.buttons }, { children: [_jsx(Button, { classes: [styles.confirmationCodeBtn], label: t('btn_confirm'), primary: true, handler: handleConfirm }), _jsx(Timer, { handle: function () {
                                    handleResend(codePosition);
                                }, type: codePosition === 'call' ? 'call' : 'sms' })] })), accountMerge.currentAccountType === 'phone' && listType === 'accounts' && (_jsx("div", __assign({ className: styles.changeMethod }, { children: positionLoading ? (_jsx("div", { className: styles.bubbleLoader })) : (_jsxs("div", __assign({ className: styles.changeMethodInner }, { children: [_jsxs("span", { children: [codePosition === 'call'
                                            ? t('label_pre_sms_method')
                                            : t('label_pre_call_method'), "\u00A0"] }), _jsx("span", __assign({ className: clsx(styles.changeMethodBtn), onClick: onChangePositionClick }, { children: codePosition === 'call'
                                        ? t('label_sms_method')
                                        : t('label_call_method') }))] }))) })))] }))] })));
};
