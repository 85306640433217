var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Trash = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M22 7H19V6C19 4.89543 18.1046 4 17 4H11C9.89543 4 9 4.89543 9 6V7H6C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H7V22C7 22.5523 7.44772 23 8 23H20C20.5523 23 21 22.5523 21 22V9H22C22.5523 9 23 8.55228 23 8C23 7.44772 22.5523 7 22 7ZM11 6H17V7H11V6ZM9 21H19V9H9V21ZM13 12V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM17 18V12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12V18C15 18.5523 15.4477 19 16 19C16.5523 19 17 18.5523 17 18Z", fill: "black" }) }))); };
export default Trash;
