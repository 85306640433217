var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { CheckboxButton } from '@core/components/formComponents/checkboxButton';
import { getContent } from '@core/shared/utils/surveyUtils';
import styles from './index.module.sass';
export var Switcher = function (_a) {
    var stepId = _a.stepId, children = _a.children, form = _a.form, className = _a.className, type = _a.type;
    var _b = useSelector(function (state) { return ({
        survey: state.survey.fullSurvey,
        isLoading: state.loading.isLoading,
    }); }), survey = _b.survey, isLoading = _b.isLoading;
    var prepareForRadio = useCallback(function (children) {
        var preparedData = [];
        children.forEach(function (child) {
            if (child.variation === 'slot' && child.subvariation === 'option') {
                var content = getContent({
                    id: child._id,
                    data: survey.content,
                });
                content &&
                    preparedData.push({
                        value: child._id,
                        label: content.label,
                    });
            }
        });
        return preparedData;
    }, [survey.content]);
    var items = useMemo(function () {
        return prepareForRadio(children);
    }, [children, prepareForRadio]);
    return (_jsxs("div", __assign({ className: clsx(className, styles.radioSwitcher, isLoading && styles.radioSwitcherDisabled, type === 'chat' && styles.radioSwitcherChat) }, { children: [_jsx(Controller, { control: form.control, name: stepId, rules: {
                    required: {
                        value: true,
                        message: t('error_required'),
                    },
                }, render: function (_a) {
                    var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
                    return (_jsxs(_Fragment, { children: [items.map(function (item) { return (_jsx(CheckboxButton, { renderFor: type, className: styles.checkboxButton, label: item.label, values: [value], onChange: function (id) {
                                    onChange(id);
                                    form.clearErrors(stepId);
                                }, id: item.value }, item.value)); }), error && _jsx("span", __assign({ className: styles.errorText }, { children: error.message }))] }));
                } }), _jsx("span", {})] })));
};
