var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { BaseWrapper } from '@core/components/baseWrapper';
import { Button } from '@core/components/buttons/button';
import { Input } from '@core/components/formComponents/input';
import { PhoneInput } from '@core/components/formComponents/phoneInput';
import { PageTitle } from '@core/components/pageTitle';
import { Timer } from '@core/components/timer';
import { EMAIL_REGEX } from '@core/shared/regex';
import { resetStoreErrors } from '@core/store/reducers/errorsSlice';
import { loaderType } from '@core/store/reducers/loadingSlice';
import { addAccountReq } from '@core/store/reducers/profileSlice';
import { resetCodeInfo, verificationRequest } from '@core/store/reducers/verificationSlice';
import styles from './index.module.sass';
var mapState = function (state) { return ({
    profile: state.profile,
    loading: state.loading,
    errorsStore: state.errors,
    storeCode: state.code,
}); };
export var AddContact = function () {
    var _a = useForm(), register = _a.register, errors = _a.formState.errors, setValue = _a.setValue, unregister = _a.unregister, control = _a.control, clearErrors = _a.clearErrors, setError = _a.setError, trigger = _a.trigger, getValues = _a.getValues;
    var params = useParams();
    var nav = useNavigate();
    var dispatch = useDispatch();
    var _b = useSelector(mapState), storeCode = _b.storeCode, loading = _b.loading, errorsStore = _b.errorsStore;
    var _c = useState('initial'), codePosition = _c[0], setCodePosition = _c[1];
    var _d = useState(false), positionLoading = _d[0], setPositionLoading = _d[1];
    var disableChangePosition = false;
    var onChangePositionClick = function () {
        var type = codePosition === 'call' ? 'code' : 'call';
        setPositionLoading(true);
        sendIdentifier(type, function () {
            setPositionLoading(false);
            setCodePosition(type);
        });
    };
    var sendIdentifier = function (type, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var identifier;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger(params.type)];
                case 1:
                    if (!(_a.sent()))
                        return [2 /*return*/];
                    identifier = getValues(params.type);
                    dispatch(addAccountReq(__assign({ type: params.type, identifier: identifier, callback: callback }, (type === 'call' && { verification: 'call' }))));
                    return [2 /*return*/];
            }
        });
    }); };
    var verificationCallback = function () {
        nav(routePaths.CONTACTS);
    };
    var sendCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var code;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger('code')];
                case 1:
                    if (!(_a.sent()))
                        return [2 /*return*/];
                    code = getValues('code');
                    dispatch(verificationRequest({
                        id: storeCode.verification_id,
                        code: code,
                        disableUpdateInfo: true,
                        callback: verificationCallback,
                    }));
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (storeCode.verification_id) {
            setCodePosition('code');
        }
    }, [storeCode.verification_id]);
    useEffect(function () {
        for (var key in errorsStore) {
            if (Object.prototype.hasOwnProperty.call(errorsStore, key)) {
                var element = errorsStore[key];
                if (element)
                    setError(key, { type: 'pattern', message: element });
            }
        }
    }, [errorsStore, setError]);
    useEffect(function () {
        return function () {
            clearErrors();
            dispatch(resetStoreErrors());
        };
    }, [dispatch]); /*eslint-disable-line*/
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInnerMid: true }, { children: [_jsx(PageTitle, { text: t(params.type === 'phone' ? 'label_add_phone' : 'label_add_email'), hasBackButton: true }), _jsxs("div", __assign({ className: styles.AddContact }, { children: [params.type === 'phone' ? (_jsx(PhoneInput, { classNameSelect: styles.AddContactSelect, setValue: setValue, handleAction: function () {
                                clearErrors('phone');
                                if (codePosition !== 'initial') {
                                    clearErrors();
                                    setCodePosition('initial');
                                    setValue('code', '');
                                    dispatch(resetCodeInfo());
                                }
                            }, error: errors.phone, register: register, clearErrors: clearErrors })) : (_jsx(Input, { label: "email", placeholder: t('placeholder_email'), type: "email", control: control, register: register, clearErrors: clearErrors, unregister: unregister, error: errors.email, setEmpty: function () {
                                setValue('email', '');
                                if (codePosition !== 'initial') {
                                    clearErrors();
                                    setCodePosition('initial');
                                    setValue('code', '');
                                    dispatch(resetCodeInfo());
                                }
                            }, validation: {
                                required: { value: true, message: 'error_required' },
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: 'error_email_incorrect',
                                },
                                onChange: function () {
                                    clearErrors('email');
                                    if (codePosition !== 'initial') {
                                        clearErrors();
                                        setCodePosition('initial');
                                        setValue('code', '');
                                        dispatch(resetCodeInfo());
                                    }
                                },
                            } })), codePosition === 'initial' && (_jsx(Button, { label: "Next", handler: sendIdentifier, loading: loading.type === loaderType.ADD_ACCOUNT && loading.isLoading, classes: [styles.AddContactBtn], isEnterHandle: true, primary: true })), (codePosition === 'code' || codePosition === 'call') && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.codeText }, { children: codePosition === 'call' ? t('label_call') : t('label_code') })), _jsx(Input, { label: "code", placeholder: t('placeholder_code'), type: "code", autofocus: true, control: control, register: register, clearErrors: clearErrors, unregister: unregister, error: errors.code, setEmpty: function () {
                                        setValue('code', '');
                                    }, validation: {
                                        required: { value: true, message: 'error_required' },
                                        maxLength: { value: 5, message: 'error_auth_code_max_length' },
                                        onChange: function () {
                                            clearErrors('code');
                                        },
                                    } }), _jsx(Button, { label: "Next", handler: sendCode, loading: loading.type === loaderType.VERIF && loading.isLoading, classes: [styles.AddContactBtn], isEnterHandle: true, primary: true }), _jsx(Timer, { handle: function () { return sendIdentifier(codePosition); }, classes: [styles.AddContactBtn], type: codePosition === 'call' ? 'call' : 'sms' }), params.type === 'phone' && (_jsx("div", __assign({ className: styles.changeMethod }, { children: positionLoading ? (_jsx("div", { className: styles.bubbleLoader })) : (_jsxs("div", __assign({ className: styles.changeMethodInner }, { children: [_jsxs("span", { children: [codePosition === 'call'
                                                        ? t('label_pre_sms_method')
                                                        : t('label_pre_call_method'), "\u00A0"] }), _jsx("span", __assign({ className: clsx(styles.changeMethodBtn, disableChangePosition && styles.changeMethodBtnDisabled), onClick: onChangePositionClick }, { children: codePosition === 'call'
                                                    ? t('label_sms_method')
                                                    : t('label_call_method') }))] }))) })))] }))] }))] })) })));
};
