var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import DriverLicence from '@core/shared/svg/driverLicence';
import { store } from '@store/store';
export var EMAIL_PATTERN = /[a-zA-Z\d._%+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6}$/;
export var MESSAGE_ICONS = {
    iconDriverLicense: _jsx(DriverLicence, {}),
};
export var getStep = function (fullSurvey, stepId) {
    var _a;
    var step = (_a = fullSurvey.branches) === null || _a === void 0 ? void 0 : _a.find(function (branch) { return branch._id === stepId; });
    var children = getChildren({
        data: __spreadArray(__spreadArray([], fullSurvey.branches, true), fullSurvey.content, true),
        id: stepId,
    }).sort(function (a, b) { return a.order - b.order; });
    return { step: step, children: children };
};
export var getCurrentSlot = function (_a) {
    var id = _a.id, data = _a.data;
    return data.find(function (child) { return child._id === id; });
};
export var getOrder = function (_a) {
    var id = _a.id, data = _a.data;
    var slot = getCurrentSlot({ id: id, data: data });
    return slot.order;
};
export var getChildren = function (_a) {
    var id = _a.id, data = _a.data, variations = _a.variations;
    return (variations === null || variations === void 0 ? void 0 : variations.length)
        ? data
            .filter(function (child) {
            return child.parents.indexOf(id) !== -1 && variations.some(function (tag) { return tag === child.variation; });
        })
            .sort(function (a, b) { return a.order - b.order; })
        : data
            .filter(function (child) { return child.parents.indexOf(id) !== -1; })
            .sort(function (a, b) { return a.order - b.order; });
};
export var getContent = function (_a) {
    var id = _a.id, data = _a.data;
    return data.find(function (child) {
        return child.parents.indexOf(id) === child.parents.length - 1 && child.variation === 'content';
    });
};
export var getSelectContentByValue = function (_a) {
    var value = _a.value, data = _a.data;
    var item = data.find(function (child) { return child.value === value && child.variation === 'select_option'; });
    return getContent({ id: item._id, data: data });
};
export var hasTag = function (item, tag) {
    var _a;
    return ((_a = item === null || item === void 0 ? void 0 : item.tags) === null || _a === void 0 ? void 0 : _a.indexOf(tag)) !== -1;
};
export var getValueByPath = function (data, path) {
    if (!data)
        return '';
    var parts = path.split('.');
    var current = data;
    for (var i = 0; i < parts.length; i++) {
        current = current === null || current === void 0 ? void 0 : current[parts === null || parts === void 0 ? void 0 : parts[i]];
        if (!current)
            break;
    }
    if (typeof current === 'string') {
        return current;
    }
    return;
};
export var getPrefilledContent = function (_a) {
    var prefill = _a.prefill, answers = _a.answers, index = _a.index, content = _a.content;
    if (answers.length) {
        var answersByCurrentIndex = answers.filter(function (answer) { return answer.index === index; });
        var generalPath = prefill.slice(1, -1).split('.');
        generalPath.shift();
        var _b = generalPath.slice(0, generalPath.indexOf('$')), searchKey_1 = _b[0], searchValue_1 = _b[1]; //Data for search answer
        var pathToValue = generalPath.slice(generalPath.indexOf('$') + 1).join('.'); //Path for search value
        var isFindBySlot = (searchKey_1 === 'slot_sid' || searchKey_1 === 'slot_id') && !!searchValue_1;
        var isFindByStep = (searchKey_1 === 'step_sid' || searchKey_1 === 'step_id') && !!searchValue_1;
        var calculatedValue_1;
        if (isFindBySlot) {
            answersByCurrentIndex.forEach(function (answer) {
                calculatedValue_1 = answer.values.find(function (value) { return value[searchKey_1] && value[searchKey_1] === searchValue_1; });
            });
        }
        if (isFindByStep) {
            var currentAnswer = answersByCurrentIndex.find(function (answer) { return answer[searchKey_1] && answer[searchKey_1] === searchValue_1; });
            calculatedValue_1 = currentAnswer.values[0];
        }
        if (calculatedValue_1) {
            calculatedValue_1 = __assign({ content: getContent({
                    id: calculatedValue_1.slot_id,
                    data: content,
                }) }, calculatedValue_1);
        }
        return getValueByPath(calculatedValue_1, pathToValue);
    }
};
export var replaceContent = function (str) {
    var _a, _b, _c, _d;
    if (!str)
        return null;
    var state = store.getState();
    if (str.includes('{:personFullName}')) {
        var objectName = ((_b = (_a = state === null || state === void 0 ? void 0 : state.booking) === null || _a === void 0 ? void 0 : _a.patient) === null || _b === void 0 ? void 0 : _b.name) || ((_d = (_c = state === null || state === void 0 ? void 0 : state.survey) === null || _c === void 0 ? void 0 : _c.patient) === null || _d === void 0 ? void 0 : _d.name);
        if (!objectName)
            return str;
        var name = __spreadArray(__spreadArray([], objectName.given, true), [objectName.family], false).join(' ');
        return "".concat(str.replace('{:personFullName}', name));
    }
    return str;
};
export var prefillFields = function (_a) {
    var survey = _a.survey, prefilledSlots = _a.prefilledSlots, callback = _a.callback;
    var allAnswerValues = survey.answers.reduce(function (acc, answer) { return acc.concat(answer.values); }, []);
    prefilledSlots.forEach(function (slot) {
        var isUpperCase = hasTag(slot, 'uppercase');
        var prefillsList = slot.prefills;
        prefillsList.find(function (prefill) {
            var prefillData;
            if (prefill.source_type === 'answer') {
                prefillData = allAnswerValues.find(function (value) {
                    return value.slot_sid === prefill.slot_sid || value.slot_id === prefill.slot_id;
                });
            }
            else if (prefill.source_type === 'survey') {
                prefillData = survey.fullSurvey.survey;
            }
            if (prefillData) {
                var value = prefillData && getValueByPath(prefillData, prefill.path);
                if (value && isUpperCase)
                    value = value.toUpperCase();
                if (value) {
                    callback({ slot: slot, prefill: prefill, value: value });
                    return true;
                }
            }
        });
    });
};
