var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { forwardRef, useRef, useState, } from 'react';
import clsx from 'clsx';
import { t } from 'i18next';
import { ClearButton } from '@core/components/buttons';
import styles from './index.module.sass';
// TO DO: update react to 18 version and use useId hook for generate unique id
var uniqueId = 1;
export var Input = forwardRef(function (_a, ref) {
    var _b = _a.tagType, tagType = _b === void 0 ? 'input' : _b, label = _a.label, className = _a.className, focusClassName = _a.focusClassName, fieldClassName = _a.fieldClassName, leftAddon = _a.leftAddon, rightAddon = _a.rightAddon, _c = _a.clearable, clearable = _c === void 0 ? false : _c, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, form = _a.form, error = _a.error, inputParams = __rest(_a, ["tagType", "label", "className", "focusClassName", "fieldClassName", "leftAddon", "rightAddon", "clearable", "isLoading", "form", "error"]);
    uniqueId++;
    // eslint-disable-next-line prefer-const
    var _e = inputParams.disabled, disabled = _e === void 0 ? false : _e, value = inputParams.value, name = inputParams.name, type = inputParams.type;
    var _f = useState(false), hasTouch = _f[0], setHasTouch = _f[1];
    var _g = useState(false), hasFocus = _g[0], setHasFocus = _g[1];
    var _h = useState(false), isShow = _h[0], setShow = _h[1];
    var wrapperRef = useRef(null);
    var leftAddonRef = useRef(null);
    var rightAddonRef = useRef(null);
    var innerRef = useRef(null);
    var inputRef = ref ? ref : innerRef;
    var setFocus = function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form ? form.setFocus(name) : inputRef.current.focus();
    };
    var setValue = function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form ? form.setValue(name, value) : inputParams.onChange({ target: { value: value } });
    };
    var showPass = function () {
        setShow(!isShow);
    };
    if (form) {
        if (error === undefined) {
            error = form.formState.errors[name];
        }
        if (value === undefined) {
            value = form.getValues(name);
        }
    }
    var onClick = function (e) {
        var refs = [wrapperRef.current, leftAddonRef.current, rightAddonRef.current];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (refs.includes(e.target) && e.target === e.currentTarget) {
            setFocus();
        }
    };
    var onFocus = function (e) {
        setHasTouch(true);
        setHasFocus(true);
        if (inputParams.onFocus) {
            inputParams.onFocus(e);
        }
    };
    var onBlur = function (e) {
        setHasFocus(false);
        if (inputParams.onBlur) {
            inputParams.onBlur(e);
        }
    };
    return (_jsxs("div", __assign({ className: clsx(styles.field, fieldClassName) }, { children: [label && _jsx(Label, __assign({ htmlFor: "input-".concat(uniqueId) }, { children: label })), _jsxs("div", __assign({ ref: wrapperRef, className: clsx(styles.input, tagType === 'textarea' && styles.textarea, hasTouch && styles.inputTouch, hasFocus && styles.inputFocus, hasFocus && focusClassName, disabled && styles.inputDisabled, error && styles.inputError, className), onClick: onClick }, { children: [leftAddon && (_jsx("div", __assign({ ref: leftAddonRef, className: styles.addonLeft, onClick: onClick }, { children: leftAddon }))), tagType === 'input' && (_jsx("input", __assign({ ref: inputRef, id: "input-".concat(uniqueId) }, inputParams, { onFocus: onFocus, onBlur: onBlur, type: isShow ? 'text' : type }))), type === 'password' && (_jsx("div", __assign({ className: styles.inputShow, onClick: showPass }, { children: t(isShow ? 'label_hide' : 'label_show') }))), tagType === 'textarea' && (_jsx(_Fragment, { children: 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        _jsx("textarea", __assign({ ref: inputRef, onFocus: onFocus, onBlur: onBlur, id: "input-".concat(uniqueId) }, inputParams)) })), rightAddon && !clearable && (_jsx("div", __assign({ ref: rightAddonRef, className: styles.addonRight, onClick: onClick }, { children: rightAddon }))), isLoading && (_jsx("div", __assign({ className: styles.loader }, { children: _jsx("div", { className: styles.bubbleLoader }) }))), clearable && (_jsx("div", __assign({ className: clsx(styles.clear, value.length > 0 && styles.clearShow) }, { children: _jsx(ClearButton, { className: styles.clearButton, onClick: function () {
                                setValue('');
                                setFocus();
                            } }) })))] })), 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error && _jsx("div", __assign({ className: styles.error }, { children: t(error.message) }))] })));
});
Input.displayName = 'Input';
export var Label = function (_a) {
    var htmlFor = _a.htmlFor, className = _a.className, children = _a.children;
    return (_jsx("label", __assign({ htmlFor: htmlFor, className: clsx(styles.label, className) }, { children: children })));
};
