var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Google = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", __assign({ fillRule: "nonzero", fill: "none" }, { children: [_jsx("path", { d: "M24 14.447c0-.673-.06-1.311-.162-1.933h-9.62v3.84h5.508c-.247 1.26-.97 2.325-2.044 3.048v2.555h3.287c1.924-1.78 3.031-4.402 3.031-7.51z", fill: "#4285F4" }), _jsx("path", { d: "M14.217 24.434c2.759 0 5.066-.92 6.752-2.477l-3.287-2.555c-.92.613-2.086.988-3.465.988-2.665 0-4.921-1.797-5.73-4.223H5.098v2.63c1.678 3.338 5.126 5.637 9.12 5.637z", fill: "#34A853" }), _jsx("path", { d: "M8.487 16.167a5.929 5.929 0 0 1-.324-1.95c0-.681.12-1.337.324-1.95v-2.63H5.098a10.099 10.099 0 0 0 0 9.16l3.389-2.63z", fill: "#FBBC05" }), _jsx("path", { d: "M14.217 8.044c1.507 0 2.852.52 3.917 1.533l2.912-2.912C19.283 5.013 16.976 4 14.217 4c-3.993 0-7.441 2.299-9.119 5.636l3.389 2.631c.809-2.426 3.065-4.223 5.73-4.223z", fill: "#EA4335" })] })) }))); };
export default Google;
