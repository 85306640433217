var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useRef, useState, } from 'react';
var useMask = function (startValue, mask, keySymbol, needAddMask) {
    if (startValue === void 0) { startValue = ''; }
    if (mask === void 0) { mask = ''; }
    if (keySymbol === void 0) { keySymbol = '*'; }
    if (needAddMask === void 0) { needAddMask = true; }
    var _a = useState(startValue), value = _a[0], setValue = _a[1];
    var _b = useState(startValue), originalValue = _b[0], setOriginalValue = _b[1];
    var _c = useState(true), isEmptyField = _c[0], setIsEmptyField = _c[1];
    var prevMaskPrefix = useRef();
    function setSelection(event, value) {
        if (event.target instanceof HTMLInputElement) {
            event.target.selectionStart = value;
            event.target.selectionEnd = value;
        }
    }
    var checkSelectorOnMaskPrefix = useCallback(function (event) {
        var dialPrefix = getDialPrefix(mask);
        var isDialMask = mask && mask.startsWith('+');
        var target = event.target;
        var selectionStart = target.selectionStart || 0;
        var selectionEnd = target.selectionEnd || 0;
        var isSelectorOnMaskRules = [
            !!isDialMask,
            target.value === dialPrefix ||
                (selectionStart <= dialPrefix.length && selectionStart === selectionEnd),
        ];
        if (isSelectorOnMaskRules.every(Boolean)) {
            if (event.keyCode === 8) {
                event.preventDefault();
                return setSelection(event, target.value.length);
            }
            event.preventDefault();
            setSelection(event, dialPrefix.length);
        }
    }, [mask]);
    var setCorrectSelectorPosition = useCallback(function (event, tmpSelectionStart, initialTargetValue, prevValue, newValue) {
        var eventValue = event.target.value;
        var maskPrefix = getDialPrefix(mask);
        if (!!maskPrefix && tmpSelectionStart < maskPrefix.length) {
            var position = maskPrefix.length;
            tmpSelectionStart += maskPrefix.length;
            while (position < newValue.length) {
                if (eventValue[position].match(/[^\d;]/g)) {
                    position++;
                    tmpSelectionStart++;
                }
                else
                    break;
            }
        }
        if (tmpSelectionStart !== initialTargetValue.length &&
            eventValue.length >= prevValue.length &&
            prevValue !== eventValue) {
            while (tmpSelectionStart < newValue.length) {
                if (newValue[tmpSelectionStart - 1].match(/[^\d;]/g)) {
                    tmpSelectionStart++;
                }
                else
                    break;
            }
            setSelection(event, tmpSelectionStart);
        }
        if (eventValue.length < prevValue.length || prevValue === eventValue) {
            while (tmpSelectionStart > 0) {
                if (newValue[tmpSelectionStart - 1] && newValue[tmpSelectionStart - 1].match(/[^\d;]/g))
                    tmpSelectionStart--;
                else
                    break;
            }
            setSelection(event, tmpSelectionStart);
        }
        checkSelectorOnMaskPrefix(event);
    }, [mask, checkSelectorOnMaskPrefix]);
    function getDialPrefix(mask) {
        var maskDialPrefix = '';
        if (!!mask && mask[0] === '+') {
            maskDialPrefix = mask.substring(0, mask.indexOf('  '));
            return maskDialPrefix + '  ';
        }
        return maskDialPrefix;
    }
    function decodeMask(value) {
        if (value === getDialPrefix(mask))
            return (value = '');
        if (value === prevMaskPrefix.current)
            return (value = '');
        var newValue = value.replace(/[^\d;]/g, '');
        return value.startsWith('+') ? '+' + newValue : newValue;
    }
    var clearMask = useCallback(function (value, mask) {
        if (!mask)
            return value.replace(/[^\d;]/g, '');
        var maskPrefix = getDialPrefix(mask);
        var valuePrefix = getDialPrefix(value);
        var result = value;
        if (value === prevMaskPrefix.current || value === prevMaskPrefix.current)
            return '';
        if (maskPrefix)
            prevMaskPrefix.current = maskPrefix;
        if (value.startsWith(valuePrefix))
            result = value.substring(valuePrefix.length, value.length);
        if (value.startsWith(maskPrefix))
            result = value.substring(maskPrefix.length, value.length);
        if (result[0] === '+' && !!maskPrefix)
            result = result.substring(maskPrefix.length, result.length);
        if (result[0] === '+' && !maskPrefix)
            result = '';
        result = result.replace(/[^\d;]/g, '');
        return result;
    }, []);
    var addMask = useCallback(function (value, mask) {
        if (!mask || !needAddMask)
            return value;
        var prepareValue = clearMask(value, mask).split('');
        var prepareMask, result;
        var maskDialPrefix = getDialPrefix(mask);
        if (maskDialPrefix) {
            prepareMask = mask.substring(maskDialPrefix.length, mask.length).split('');
            result = maskDialPrefix;
        }
        else {
            prepareMask = mask.split('');
            result = '';
        }
        prepareMask.forEach(function (maskItem) {
            if (!prepareValue.length)
                return;
            if (maskItem === keySymbol)
                return (result += prepareValue.shift());
            else
                return (result += maskItem);
        });
        return result;
    }, [keySymbol, clearMask]);
    var onChange = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var tmpSelectionStart, initialTargetValue, prevValue, newValue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(typeof event === 'string')) return [3 /*break*/, 1];
                    setValue(addMask(event, mask));
                    return [3 /*break*/, 3];
                case 1:
                    tmpSelectionStart = event.target.selectionStart || 0;
                    initialTargetValue = event.target.value;
                    prevValue = value;
                    newValue = addMask(initialTargetValue, mask);
                    return [4 /*yield*/, setValue(newValue)];
                case 2:
                    _a.sent();
                    setCorrectSelectorPosition(event, tmpSelectionStart, initialTargetValue, prevValue, newValue);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [setValue, mask, addMask, value, setCorrectSelectorPosition]);
    function onKeyDown(event) {
        var keyCodeForCheckSelector = [37, 38, 36, 8];
        // const allowedKeysRules: boolean[] = [
        //     event.keyCode >= 48 && event.keyCode <= 57,
        //     event.keyCode >= 96 && event.keyCode <= 105,
        //     event.keyCode === 46, event.keyCode === 8,
        //     event.keyCode === 9, event.keyCode === 27,
        //     event.keyCode === 65 && event.ctrlKey,
        //     event.keyCode === 67 && event.ctrlKey,
        //     event.keyCode === 86 && event.ctrlKey,
        //     event.keyCode >= 35 && event.keyCode <= 39,
        // ]
        if (keyCodeForCheckSelector.some(function (key) { return event.keyCode === key; }))
            checkSelectorOnMaskPrefix(event);
        // if (!allowedKeysRules.some(Boolean)) return event.preventDefault()
    }
    useEffect(function () {
        setIsEmptyField(getDialPrefix(mask) === value || !value);
        setOriginalValue(decodeMask(value));
        // eslint-disable-next-line
    }, [value, mask]);
    useEffect(function () {
        if (mask)
            onChange(value);
        else
            onChange(decodeMask(value));
        // eslint-disable-next-line
    }, [mask, onChange, value, isEmptyField]);
    useEffect(function () {
        onChange(startValue);
    }, [startValue]); // eslint-disable-line
    var input = {
        value: value,
        onKeyDown: onKeyDown,
        onClick: checkSelectorOnMaskPrefix,
    };
    return {
        input: input,
        originalValue: originalValue,
        onChange: onChange,
        isEmptyField: isEmptyField,
        mask: mask || '',
    };
};
export default useMask;
