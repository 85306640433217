var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Trash from '@core/shared/svg/trash';
import styles from './index.module.sass';
var CANVAS_HEIGHT = 186;
var CANVAS_WIDTH = 345;
var DRAW_SLOT_ID = 'DRAW_SLOT_ID';
export var Draw = function (_a) {
    var label = _a.label, onChange = _a.onChange, error = _a.error;
    var canvas = useRef(null);
    var _b = useState(false), drawing = _b[0], setDrawing = _b[1];
    var _c = useState(null), position = _c[0], setPosition = _c[1];
    var onDown = useCallback(function (event) {
        var coordinates = getCoordinates(event);
        if (coordinates) {
            setPosition(coordinates);
            setDrawing(true);
        }
    }, []);
    var onClear = function () {
        var context = canvas.current.getContext('2d');
        context.clearRect(0, 0, canvas.current.width, canvas.current.height);
        onChange('');
    };
    function isCanvasBlank(canvas) {
        var blank = document.createElement('canvas');
        blank.width = canvas === null || canvas === void 0 ? void 0 : canvas.width;
        blank.height = canvas === null || canvas === void 0 ? void 0 : canvas.height;
        return (canvas === null || canvas === void 0 ? void 0 : canvas.toDataURL()) == (blank === null || blank === void 0 ? void 0 : blank.toDataURL());
    }
    var onUp = useCallback(function () {
        setDrawing(false);
        setPosition(null);
        if (!isCanvasBlank(canvas.current)) {
            var base64Canvas = canvas.current.toDataURL().split(';base64,')[1];
            onChange(base64Canvas);
        }
    }, []);
    var preventTouchMove = function (event) {
        event.preventDefault();
    };
    useEffect(function () {
        var drawSlot = document.getElementById(DRAW_SLOT_ID);
        drawSlot.addEventListener('touchmove', preventTouchMove, false);
        return function () {
            drawSlot.removeEventListener('touchmove', preventTouchMove, false);
        };
    }, []);
    var getCoordinates = function (event) {
        if (!canvas.current) {
            return null;
        }
        var x = 0;
        var y = 0;
        if (window.TouchEvent && event.nativeEvent instanceof TouchEvent) {
            var rect = event.target.getBoundingClientRect();
            x = event.nativeEvent.touches[0].clientX - rect.left;
            y = event.nativeEvent.touches[0].clientY - rect.top;
        }
        if (event.nativeEvent instanceof MouseEvent) {
            var rect = event.target.getBoundingClientRect();
            x = event.clientX - rect.left;
            y = event.clientY - rect.top;
        }
        return {
            x: x - canvas.current.offsetLeft,
            y: y - canvas.current.offsetTop,
        };
    };
    var onMove = useCallback(function (event) {
        if (drawing) {
            var newPosition = getCoordinates(event);
            if (position && newPosition) {
                drawLine(position, newPosition);
                setPosition(newPosition);
            }
        }
    }, [drawing, position]);
    var drawLine = function (originalPosition, newPosition) {
        if (!canvas.current) {
            return null;
        }
        var context = canvas.current.getContext('2d');
        if (context) {
            context.strokeStyle = '#000000';
            context.lineJoin = 'round';
            context.lineWidth = 3;
            context.beginPath();
            context.moveTo(originalPosition.x, originalPosition.y);
            context.lineTo(newPosition.x, newPosition.y);
            context.closePath();
            context.stroke();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: styles.label }, { children: label })), _jsxs("div", __assign({ className: styles.canvasWrapper, style: { width: CANVAS_WIDTH, height: CANVAS_HEIGHT } }, { children: [!isCanvasBlank(canvas.current) && (_jsx("div", __assign({ className: styles.clear, onClick: onClear }, { children: _jsx(Trash, {}) }))), _jsx("canvas", { id: DRAW_SLOT_ID, className: styles.canv, ref: canvas, onMouseDown: onDown, onTouchStart: onDown, onMouseUp: onUp, onTouchEnd: onUp, onMouseLeave: onUp, onMouseMove: onMove, onTouchMove: onMove, width: CANVAS_WIDTH, height: CANVAS_HEIGHT })] })), error && _jsx("span", __assign({ className: styles.error }, { children: error }))] }));
};
