var CartService = /** @class */ (function () {
    function CartService() {
    }
    CartService.prototype.cartParse = function () {
        return JSON.parse(localStorage.getItem("cart")) || [];
    };
    CartService.prototype.addItem = function (procedure) {
        var cart = this.cartParse();
        cart.push(procedure);
        localStorage.setItem("cart", JSON.stringify(cart));
        window.dispatchEvent(new Event('storage'));
    };
    CartService.prototype.getItemById = function (id) {
        var cart = this.cartParse();
        var item = cart.find(function (item) { return item._id === id; });
        return item;
    };
    CartService.prototype.getItems = function () {
        var cart = this.cartParse();
        return cart;
    };
    CartService.prototype.removeItem = function (id) {
        var cart = this.cartParse();
        var filteredCart = cart.filter(function (e) { return e._id !== id; });
        if (filteredCart.length === 0) {
            localStorage.removeItem("cart");
            window.dispatchEvent(new Event('storage'));
            return;
        }
        localStorage.setItem("cart", JSON.stringify(filteredCart));
        window.dispatchEvent(new Event('storage'));
    };
    CartService.prototype.clearCart = function () {
        localStorage.removeItem("cart");
    };
    return CartService;
}());
var cartService = new CartService();
export default cartService;
