var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { PlansCards } from '@components/plansCards';
import { BaseWrapper } from '@core/components/baseWrapper';
import { useActiveSubscription } from '@core/shared/hooks/useActiveSubscription';
import { getFamily, getRecommendations } from '@core/store/reducers/profileSlice';
import { getSubscriptionProducts, setSelectedProducts, } from '@core/store/reducers/subscriptionSlice';
import { HeadSection } from '@pages/home/components/headSection';
import { Testing } from '@pages/home/components/testing';
import { plansKit } from '@root/src/configs/landing/plansKit';
import { Resources } from './components/resources';
import styles from './index.module.sass';
export var Home = function () {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var self = useSelector(function (state) { return state.profile; }).self;
    var subscriptionProducts = useSelector(function (s) { return s.subscriptions.subscriptionProducts; });
    var activeSubscription = useActiveSubscription();
    useEffect(function () {
        dispatch(getFamily());
        dispatch(getSubscriptionProducts());
    }, [dispatch]);
    useEffect(function () {
        if (self === null || self === void 0 ? void 0 : self.id) {
            dispatch(getRecommendations({
                id: self.id,
                callback: function () {
                    return;
                },
            }));
        }
    }, [self === null || self === void 0 ? void 0 : self.id]);
    var handlePlanClick = function (sub, isAnnually) {
        var selectedProducts = subscriptionProducts.filter(function (product) {
            var _a, _b;
            return ((product === null || product === void 0 ? void 0 : product.group) === (sub === null || sub === void 0 ? void 0 : sub.group) &&
                ((_a = product === null || product === void 0 ? void 0 : product.name) === null || _a === void 0 ? void 0 : _a.replace(' Family', '')) === ((_b = sub === null || sub === void 0 ? void 0 : sub.name) === null || _b === void 0 ? void 0 : _b.replace(' Family', '')));
        });
        dispatch(setSelectedProducts(selectedProducts));
        navigate("".concat(routePaths.CHECKOUT, "?id=").concat(sub === null || sub === void 0 ? void 0 : sub._id, "&interval=").concat(isAnnually ? 'year' : 'month'));
    };
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInner: true }, { children: [!activeSubscription && (_jsxs("div", __assign({ className: styles.plans }, { children: [_jsx("div", __assign({ className: styles.title }, { children: t('label_pick_plan') })), _jsx(PlansCards, { kits: plansKit, data: subscriptionProducts.filter(function (i) { return !i.includes_family; }), btnHandler: handlePlanClick })] }))), _jsx(HeadSection, {}), _jsx(Testing, {}), _jsx(Resources, {})] })) })));
};
