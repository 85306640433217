var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { t } from 'i18next';
import { call, put, takeEvery } from 'redux-saga/effects';
import { routePaths } from '@app/routes/routes';
import { navigate } from '@core/services/navigator';
import serviceSupport from '@core/services/support';
import { ChatConstants } from '@core/shared/constants/support';
import { setAlert } from '@core/store/reducers/alertSlice';
import { createSupport, enterToRoom, exitFromRoom, finishSupportChat, getAdminCurrentFullSupport, getCurrentRoomFull, getOldMessages, getSelf, getSupportOldMessages, getSupportsList, getUnReadMessages, getUserSupport, setCurrentRoomFull, setSupportsAmount, setSupportsList, startSupportChat, stopSupportChat, takeOverRoom, } from '@core/store/reducers/support';
import { handlerErrors } from '@core/store/sagas/errors';
export function getSelfAsync(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(serviceSupport.getSelf)];
            case 1:
                _b.sent();
                if (payload.onDone) {
                    payload.onDone();
                }
                return [2 /*return*/];
        }
    });
}
export function getCurrentRoomFullAsync(_a) {
    var request, unReadMessagesArray, data, fullRoomResponse, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(serviceSupport.getUnReadMessages, payload)];
            case 1:
                request = (_b.sent()).request;
                unReadMessagesArray = JSON.parse(request.response);
                if (unReadMessagesArray.length > ChatConstants.LIMIT) {
                    payload.filter.limit = unReadMessagesArray.length + 1;
                }
                else {
                    payload.filter.limit = ChatConstants.LIMIT;
                }
                return [4 /*yield*/, call(serviceSupport.getCurrentRoomFull, payload)];
            case 2:
                data = (_b.sent()).data;
                fullRoomResponse = data;
                if (payload.onDone) {
                    payload.onDone(fullRoomResponse);
                }
                if (payload.onDoneFirst) {
                    payload.onDoneFirst(unReadMessagesArray);
                }
                return [3 /*break*/, 5];
            case 3:
                e_1 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 4:
                _b.sent();
                throw e_1;
            case 5: return [2 /*return*/];
        }
    });
}
export function getAdminCurrentFullSupportAsync(_a) {
    var data, fullSupportResponse, e_2, error, errorText;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 6]);
                return [4 /*yield*/, call(serviceSupport.getCurrentSupportFull, payload)];
            case 1:
                data = (_b.sent()).data;
                fullSupportResponse = data;
                if (payload.onDone) {
                    payload.onDone(fullSupportResponse);
                }
                return [4 /*yield*/, put(setCurrentRoomFull(fullSupportResponse))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_2 = _b.sent();
                error = e_2;
                errorText = error.response.data.detail;
                if (!error) return [3 /*break*/, 5];
                return [4 /*yield*/, put(setAlert(errorText))];
            case 4:
                _b.sent();
                navigate(routePaths.SUPPORT);
                _b.label = 5;
            case 5: throw e_2;
            case 6: return [2 /*return*/];
        }
    });
}
export function getOldMessagesAsync(_a) {
    var data, oldMessagesArray, e_3;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 4]);
                return [4 /*yield*/, call(serviceSupport.getOldMessages, payload)];
            case 1:
                data = (_b.sent()).data;
                oldMessagesArray = data;
                if (payload.onDone) {
                    payload.onDone(oldMessagesArray);
                }
                return [3 /*break*/, 4];
            case 2:
                e_3 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 3:
                _b.sent();
                throw e_3;
            case 4: return [2 /*return*/];
        }
    });
}
export function getSupportOldMessagesAsync(_a) {
    var data, oldMessagesArray, e_4;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 4]);
                return [4 /*yield*/, call(serviceSupport.getSupportOldMessages, payload)];
            case 1:
                data = (_b.sent()).data;
                oldMessagesArray = data;
                if (payload.onDone) {
                    payload.onDone(oldMessagesArray);
                }
                return [3 /*break*/, 4];
            case 2:
                e_4 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 3:
                _b.sent();
                throw e_4;
            case 4: return [2 /*return*/];
        }
    });
}
export function getUnReadMessagesAsync(_a) {
    var data, unReadMessagesArray, e_5;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 4]);
                return [4 /*yield*/, call(serviceSupport.getUnReadMessages, payload)];
            case 1:
                data = (_b.sent()).data;
                unReadMessagesArray = data;
                if (payload.onDone) {
                    payload.onDone(unReadMessagesArray);
                }
                return [3 /*break*/, 4];
            case 2:
                e_5 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 3:
                _b.sent();
                throw e_5;
            case 4: return [2 /*return*/];
        }
    });
}
var defaultSupportFiltersAction = null;
export function getSupportsListAsync(action) {
    var data, supportsArray, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                defaultSupportFiltersAction = action;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(serviceSupport.getSupportsList, action.payload)];
            case 2:
                data = (_a.sent()).data;
                supportsArray = data === null || data === void 0 ? void 0 : data.results;
                return [4 /*yield*/, put(setSupportsList(supportsArray))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(setSupportsAmount(data === null || data === void 0 ? void 0 : data.total))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_6 = _a.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function createSupportAsync(_a) {
    var data, newSupportResponse, e_7;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 4]);
                return [4 /*yield*/, call(serviceSupport.createSupport, payload)];
            case 1:
                data = (_b.sent()).data;
                newSupportResponse = data;
                if (payload.onDone) {
                    payload.onDone(newSupportResponse);
                }
                return [3 /*break*/, 4];
            case 2:
                e_7 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 3:
                _b.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export function getUserSupportAsync(_a) {
    var data, supportResponse, e_8;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 4]);
                return [4 /*yield*/, call(serviceSupport.getUserSupport, payload)];
            case 1:
                data = (_b.sent()).data;
                supportResponse = data;
                if (payload.onDone) {
                    payload.onDone(supportResponse);
                }
                return [3 /*break*/, 4];
            case 2:
                e_8 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 3:
                _b.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export function startSupportChatAsync(_a) {
    var data, e_9, error, errorText;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 5]);
                return [4 /*yield*/, call(serviceSupport.startSupportChat, payload.support_id)];
            case 1:
                data = (_b.sent()).data;
                if (payload.onDone) {
                    payload.onDone(data);
                }
                return [3 /*break*/, 5];
            case 2:
                e_9 = _b.sent();
                error = e_9;
                errorText = error.response.data.detail;
                if (!error) return [3 /*break*/, 4];
                return [4 /*yield*/, put(setAlert(errorText))];
            case 3:
                _b.sent();
                navigate(routePaths.SUPPORT);
                _b.label = 4;
            case 4: return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function stopSupportChatAsync(_a) {
    var e_10;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, 5, 6]);
                return [4 /*yield*/, call(serviceSupport.stopSupportChat, payload.support_id)];
            case 1:
                _b.sent();
                if (payload.onDone) {
                    payload.onDone();
                }
                return [4 /*yield*/, put(setAlert(t('Successfully')))];
            case 2:
                _b.sent();
                return [3 /*break*/, 6];
            case 3:
                e_10 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 4:
                _b.sent();
                return [3 /*break*/, 6];
            case 5:
                navigate(routePaths.SUPPORT);
                return [7 /*endfinally*/];
            case 6: return [2 /*return*/];
        }
    });
}
export function finishSupportChatAsync(_a) {
    var e_11;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, 5, 6]);
                return [4 /*yield*/, call(serviceSupport.finishSupportChat, payload.support_id, payload.success)];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(setAlert(t('Successfully')))];
            case 2:
                _b.sent();
                if (payload.onDone) {
                    payload.onDone();
                }
                return [3 /*break*/, 6];
            case 3:
                e_11 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 4:
                _b.sent();
                return [3 /*break*/, 6];
            case 5:
                navigate(routePaths.SUPPORT);
                return [7 /*endfinally*/];
            case 6: return [2 /*return*/];
        }
    });
}
export function enterToRoomAsync(_a) {
    var data, e_12;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 4]);
                return [4 /*yield*/, call(serviceSupport.enterToRoom, payload.support_id)];
            case 1:
                data = (_b.sent()).data;
                if (payload.onDone) {
                    payload.onDone(data);
                }
                return [3 /*break*/, 4];
            case 2:
                e_12 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 3:
                _b.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export function exitFromRoomAsync(_a) {
    var e_13;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, call(serviceSupport.exitFromRoom, payload.support_id)];
            case 1:
                _b.sent();
                if (payload.onDone) {
                    payload.onDone();
                }
                return [3 /*break*/, 3];
            case 2:
                e_13 = _b.sent();
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}
export function takeOverRoomAsync(_a) {
    var data, e_14;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 4]);
                return [4 /*yield*/, call(serviceSupport.takeOverRoom, payload.support_id)];
            case 1:
                data = (_b.sent()).data;
                if (payload.onDone) {
                    payload.onDone(data);
                }
                return [3 /*break*/, 4];
            case 2:
                e_14 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 3:
                _b.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export function supportWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(getSelf, handlerErrors(getSelfAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(getCurrentRoomFull, handlerErrors(getCurrentRoomFullAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(getOldMessages, handlerErrors(getOldMessagesAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(getSupportOldMessages, handlerErrors(getSupportOldMessagesAsync))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(getUnReadMessages, handlerErrors(getUnReadMessagesAsync))];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(createSupport, handlerErrors(createSupportAsync))];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(getSupportsList, handlerErrors(getSupportsListAsync))];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(getUserSupport, handlerErrors(getUserSupportAsync))];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(startSupportChat, handlerErrors(startSupportChatAsync))];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(stopSupportChat, handlerErrors(stopSupportChatAsync))];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeEvery(finishSupportChat, handlerErrors(finishSupportChatAsync))];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeEvery(getAdminCurrentFullSupport, handlerErrors(getAdminCurrentFullSupportAsync))];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeEvery(enterToRoom, handlerErrors(enterToRoomAsync))];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeEvery(exitFromRoom, handlerErrors(exitFromRoomAsync))];
            case 14:
                _a.sent();
                return [4 /*yield*/, takeEvery(takeOverRoom, handlerErrors(takeOverRoomAsync))];
            case 15:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
