var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useWatch, } from 'react-hook-form';
import clsx from 'clsx';
import { t } from 'i18next';
import CloseIcon from '@core/shared/svg/close';
import Lock from '@core/shared/svg/lock';
import UploadDocument from '@core/shared/svg/uploadDocument';
import styles from './index.module.sass';
export var Input = function (props) {
    var id = props.id, label = props.label, displayLabel = props.displayLabel, type = props.type, inputRef = props.inputRef, placeholder = props.placeholder, required = props.required, hasLabel = props.hasLabel, _a = props.classes, classes = _a === void 0 ? [] : _a, error = props.error, inputmode = props.inputmode, disable = props.disable, icon = props.icon, register = props.register, unregister = props.unregister, clearErrors = props.clearErrors, setEmpty = props.setEmpty, control = props.control, validation = props.validation, setValue = props.setValue, stylesFor = props.stylesFor, autofocus = props.autofocus, autocomplete = props.autocomplete, withUploadButton = props.withUploadButton, uploadButtonAction = props.uploadButtonAction, locked = props.locked, _b = props.showLockIcon, showLockIcon = _b === void 0 ? locked : _b, children = props.children, labelClassName = props.labelClassName, isQsure = props.isQsure;
    var errorRef = useRef(null);
    var fieldRef = useRef(null);
    var _c = useState(false), isShow = _c[0], setShow = _c[1];
    var watch = useWatch({
        control: control,
        name: label,
    });
    var innerRef = useRef();
    var showPass = function () {
        setShow(!isShow);
    };
    var clearField = function () {
        clearErrors && animateHideError(function () { return clearErrors(label); });
        setEmpty && setEmpty();
        unregister(label);
    };
    useEffect(function () {
        if (error) {
            animateShowError();
        }
        else {
            animateHideError();
        }
    }, [error]);
    var animateShowError = function () {
        errorRef.current.classList.remove(styles.inputErrorHidden);
        fieldRef.current.classList.add(styles.inputFieldWrong);
    };
    var animateHideError = function (handler) {
        errorRef.current.classList.add(styles.inputErrorHidden);
        fieldRef.current.classList.remove(styles.inputFieldWrong);
        setTimeout(handler, 300);
    };
    var reg = register(label, validation);
    var mainClasses = [
        styles.inputField,
        hasLabel && styles.inputFieldWithLabel,
        (disable || locked) && styles.inputFieldDisabled,
        isQsure && styles.inputFieldQsure,
        String(label) === 'search' && styles.inputFieldSearch,
    ];
    return (_jsxs("div", __assign({ className: clsx.apply(void 0, __spreadArray(__spreadArray([styles.input], classes, false), [stylesFor === 'adm' && styles.inputAdm,
            (disable || locked) && styles.inputDisabled], false)), onClick: function () { var _a; return (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.focus(); } }, { children: [_jsxs("div", __assign({ className: clsx(mainClasses, 'wrapper'), ref: fieldRef }, { children: [icon && _jsx("div", __assign({ className: styles.inputIcon }, { children: icon })), _jsxs("div", __assign({ className: clsx(styles.inputBlock, hasLabel && styles.inputBlockWithLabel, labelClassName) }, { children: [hasLabel && (_jsx("div", __assign({ className: clsx(styles.inputLabel, (placeholder || watch) && styles.inputLabelFilled) }, { children: displayLabel || label }))), children ? (children) : (_jsx("input", __assign({ type: isShow ? 'text' : type, placeholder: t(placeholder), autoComplete: autocomplete, inputMode: inputmode, disabled: disable || locked, autoFocus: autofocus }, reg, { onChange: function (e) {
                                    animateHideError(function () {
                                        if (!!id && e.target.value.includes(' ') && type === 'password') {
                                            setValue(id, e.target.value.replace(/ /g, ''));
                                        }
                                        else {
                                            reg.onChange(e);
                                        }
                                    });
                                }, ref: function (e) {
                                    innerRef.current = e;
                                    reg.ref(e);
                                    if (inputRef) {
                                        inputRef.current = e;
                                    }
                                } })))] })), label !== 'code' &&
                        !locked &&
                        !required &&
                        !withUploadButton &&
                        (type === 'password' && watch && watch.length ? (_jsx("div", __assign({ className: styles.inputShow, onClick: showPass }, { children: t(isShow ? 'label_hide' : 'label_show') }))) : (watch &&
                            watch.length && (_jsx("div", __assign({ className: clsx(styles.inputClear, stylesFor === 'adm' && styles.inputClearAdm), onClick: clearField }, { children: _jsx(CloseIcon, {}) }))))), withUploadButton && !locked && (_jsx("div", __assign({ className: clsx(styles.uploadButton, stylesFor === 'adm' && styles.uploadButtonAdm), onClick: function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            uploadButtonAction();
                        } }, { children: _jsx(UploadDocument, {}) }))), locked && showLockIcon && (_jsx("div", __assign({ className: clsx(styles.lock, stylesFor === 'adm' && styles.lock) }, { children: _jsx(Lock, {}) })))] })), _jsx("div", __assign({ className: clsx(styles.inputError, styles.inputErrorHidden), ref: errorRef }, { children: error && t(String(error.message)) }))] })));
};
