var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var SilverPlan = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "22", height: "23", viewBox: "0 0 22 23", fill: "none" }, { children: [_jsx("rect", { x: "10.6072", y: "0.5", width: "15", height: "15", transform: "rotate(45 10.6072 0.5)", fill: "#007AFF" }), _jsx("g", __assign({ style: { mixBlendMode: 'overlay' }, opacity: "0.6" }, { children: _jsx("path", { d: "M10.6068 21.7136L0.000166029 11.107H10.6068V21.7136Z", fill: "white" }) })), _jsx("path", { d: "M10.6076 0.500254L21.2142 11.1069H10.6076V0.500254Z", fill: "white", fillOpacity: "0.1" })] }))); };
export default SilverPlan;
