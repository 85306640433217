var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useRef } from 'react';
import clsx from 'clsx';
import { Input } from '@core/components/formComponents/admin/inputs';
import Search from '@core/shared/svg/search';
import styles from './index.module.sass';
export var SearchInput = forwardRef(function (_a, ref) {
    var _b = _a.hasLeftAddon, hasLeftAddon = _b === void 0 ? true : _b, options = __rest(_a, ["hasLeftAddon"]);
    var value = options.value;
    var innerRef = useRef(null);
    var inputRef = ref ? ref : innerRef;
    var className = clsx(options === null || options === void 0 ? void 0 : options.className, styles.input);
    if (hasLeftAddon) {
        options.leftAddon = (_jsx("div", __assign({ className: styles.search }, { children: _jsx(Search, {}) })));
    }
    return _jsx(Input, __assign({ ref: inputRef, clearable: true }, options, { className: className }));
});
SearchInput.displayName = 'SearchInput';
