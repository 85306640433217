import { maxBy } from 'lodash';
export var findLatestRecommendation = function (recommendations) {
    return maxBy(recommendations, function (o) { return new Date(o.created); });
};
export var checkRiskLevel = function (recommendations, riskLevelSource) {
    var _a;
    if (!(recommendations === null || recommendations === void 0 ? void 0 : recommendations.length)) {
        return 'unknown';
    }
    var latestRecommendation = findLatestRecommendation(recommendations);
    if (!latestRecommendation) {
        return 'unknown';
    }
    return (_a = latestRecommendation === null || latestRecommendation === void 0 ? void 0 : latestRecommendation.recommendations[riskLevelSource]) === null || _a === void 0 ? void 0 : _a.risk_level;
};
