var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { t } from 'i18next';
import { QSureButton as Button } from '@core/components/buttons/qsureButton';
import { Checkbox } from '@core/components/formComponents/checkbox';
import { setToAppAsyncStorage } from '@core/shared/utils/webViewUtils';
import styles from './index.module.sass';
export var LeaveQSure = function (_a) {
    var closeModal = _a.closeModal, hasSubscription = _a.hasSubscription, onContinue = _a.onContinue;
    var _b = useState(false), checkAsk = _b[0], setCheckAsk = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var setDontAskAgain = function () {
        var isDontAskAgain = localStorage.getItem('is_dont_ask_again_proceed_to_labq');
        if (!isDontAskAgain && checkAsk) {
            localStorage.setItem('is_dont_ask_again_proceed_to_labq', String(true));
            setToAppAsyncStorage({ key: 'is_dont_ask_again_proceed_to_labq', value: String(true) });
        }
    };
    var handleClickContinue = function () {
        setLoading(true);
        setDontAskAgain();
        onContinue();
        closeModal();
    };
    var handleClickCancel = function () {
        setDontAskAgain();
        closeModal();
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx("div", __assign({ className: styles.title }, { children: t('label_proceed_to_labq') })), _jsx("div", __assign({ className: styles.description }, { children: hasSubscription ? t('label_leave_qsure') : t('label_leave_qsure_not_member') })), _jsxs("div", __assign({ className: styles.buttons }, { children: [_jsx(Button, { handler: handleClickContinue, label: t('btn_continue'), withoutArrow: true, classes: [styles.btn, styles.btnContinue], loading: loading }), _jsx(Button, { handler: handleClickCancel, label: t('btn_cancel'), withoutArrow: true, white: true, classes: [styles.btn, styles.btnCancel] })] })), _jsx("div", __assign({ className: styles.checkboxWrapper }, { children: _jsx(Checkbox, { inactive: false, className: styles.checkbox, checkClassName: styles.checkboxBg, checked: checkAsk, label: t('label_not_show_anymore'), value: "", onChange: function () { return setCheckAsk(!checkAsk); } }) }))] })));
};
