var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import User from '@core/shared/svg/user';
import styles from './index.module.sass';
export var ContactItem = function (_a) {
    var item = _a.item, onClick = _a.onClick, listType = _a.listType;
    return (_jsxs("div", __assign({ className: styles.contactItem, onClick: function () { return onClick(item, listType); } }, { children: [_jsx("div", __assign({ className: styles.contactItemCircle }, { children: _jsx(User, {}) })), _jsx("span", __assign({ className: styles.contactItemText }, { children: (item === null || item === void 0 ? void 0 : item.identifier) || '' }))] })));
};
