var prod = {
    authService: 'https://auth-api.wholeq.com',
    formsService: 'https://forms-api.wholeq.com',
    chatsService: 'https://chats-api.wholeq.com',
    qCareService: 'https://home.qcare.com',
    componentsService: 'https://components-api.wholeq.com',
    apiService: 'https://core-api.wholeq.com',
    apiPdf: 'https://pdf-reports-api.wholeq.com',
    prescriptionService: 'https://pc-forms.qcare.com',
    practiceId: '61c57d38f7c1db96896aa830',
    href: 'https://wholeq.com',
    hrefQsure: 'https://qsure.com',
    app: 'client-web',
    appQsure: 'qsure-web',
    apiKeys: {
        map: 'AIzaSyBkcaPFOj7mYZUtMoRNdeJJi2Fsow4RhWc',
        stripe: 'pk_live_51O2DS1KmsPF7V93ZGD9OEs93b2dvOyLPC8aFxQdgJGyuPV2BI0Pqp6DriIzzLNycALXctfejSHcvs32zvj3JmFH7005AolWvfp',
    },
    adminMode: false,
    featureFlags: {
        AUTOCOMPLETE_USE_AS_IS: true,
    },
    appStore: 'https://apps.apple.com/us/app/labq/id1610376669',
    googleStore: 'https://play.google.com/store/apps/details?id=com.labq',
    chatWebSocket: 'wss://chats-api.wholeq.com',
    seasonProceduresCode: 'WP',
    editableProceduresCodes: ['LBQC-19'],
    organizations: [
        {
            id: '65e0a93a41d0c9041751b939',
            name: 'Test',
        },
    ],
};
var stage = {
    authService: 'https://auth-api-stage.wholeq.com',
    formsService: 'https://forms-api-stage.wholeq.com',
    chatsService: 'https://chats-api-stage.wholeq.com',
    qCareService: 'https://home-stg.qcare.com',
    componentsService: 'https://components-api-stage.wholeq.com',
    apiService: 'https://core-api-stage.wholeq.com',
    apiPdf: 'https://pdf-reports-api-stage.wholeq.com',
    prescriptionService: 'https://pc-forms-stage.qcare.com',
    practiceId: '61c57d38f7c1db96896aa830',
    href: 'https://stage.wholeq.com',
    hrefQsure: 'https://stage.qsure.com',
    app: 'client-web',
    appQsure: 'qsure-web',
    apiKeys: {
        map: 'AIzaSyBkcaPFOj7mYZUtMoRNdeJJi2Fsow4RhWc',
        stripe: 'pk_test_51LxVJCBWhhdkggpCDhFFQTbKrmOwSXylwSNnb4UAyUZx4GUBG6JXvvUdZZsj9CEzDvV99o0SxCBUDPSxC01opxUe00Iv0k3tzD',
    },
    adminMode: false,
    featureFlags: {
        AUTOCOMPLETE_USE_AS_IS: true,
    },
    appStore: 'https://apps.apple.com/us/app/labq/id1610376669',
    googleStore: 'https://play.google.com/store/apps/details?id=com.labq',
    chatWebSocket: 'wss://chats-api-stage.wholeq.com',
    seasonProceduresCode: 'WP',
    editableProceduresCodes: ['LBQC-19'],
    organizations: [
        {
            id: '65e0a93a41d0c9041751b939',
            name: 'Test',
        },
    ],
};
var dev = {
    authService: 'https://auth-api-dev.wholeq.com',
    formsService: 'https://forms-api-dev.wholeq.com',
    chatsService: 'https://chats-api-dev.wholeq.com',
    qCareService: 'https://home-stg.qcare.com',
    componentsService: 'https://components-api-dev.wholeq.com',
    apiService: 'https://core-api-dev.wholeq.com',
    apiPdf: 'https://pdf-reports-api-dev.wholeq.com',
    prescriptionService: 'https://pc-forms-dev.qcare.com',
    practiceId: '61c57d38f7c1db96896aa830',
    href: 'https://dev.wholeq.com',
    hrefQsure: 'https://dev.qsure.com',
    app: 'client-web',
    appQsure: 'qsure-web',
    apiKeys: {
        map: 'AIzaSyBkcaPFOj7mYZUtMoRNdeJJi2Fsow4RhWc',
        stripe: 'pk_test_51O0SHJBHGh92Ty43LSCSd6nMvj4HqiPDeV4112jTr8uriUScA67c1cHe7IQL3ROay5ccuckXAFG8OlRR0AtIDXMx00ZJdhLBal',
    },
    adminMode: true,
    featureFlags: {
        AUTOCOMPLETE_USE_AS_IS: true,
    },
    appStore: 'https://apps.apple.com/us/app/labq/id1610376669',
    googleStore: 'https://play.google.com/store/apps/details?id=com.labq',
    chatWebSocket: 'wss://chats-api-dev.wholeq.com',
    seasonProceduresCode: 'WP',
    editableProceduresCodes: ['LBQC-19'],
    organizations: [
        {
            id: '65e0a93a41d0c9041751b939',
            name: 'Test',
        },
    ],
};
var getConfig = function () {
    switch (window.location.hostname) {
        case 'app-stg.labq.com':
        case 'stage.wholeq.com':
        case 'stage.qsure.com':
            return stage;
        case 'localhost':
        case 'app-dev.labq.com':
        case 'dev.wholeq.com':
        case 'dev.qsure.com':
            return dev;
    }
    return prod;
};
export var config = getConfig();
