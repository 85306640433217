import moment from 'moment';
export var clearHours = function (instance) {
    return moment(instance).set({ hours: 0, minutes: 0, seconds: 0, millisecond: 0 });
};
export var calculateFromToday = function (_a) {
    var offset = _a.offset, units = _a.units;
    return clearHours(moment()).add(offset, units);
};
export var getDaysArray = function (year, month) {
    var monthIndex = month === 0 ? 0 : month - 1;
    var date = new Date(year, monthIndex, 1);
    var result = [];
    while (date.getMonth() == monthIndex) {
        result.push(date.getDate());
        date.setDate(date.getDate() + 1);
    }
    return result;
};
// If noZeros === true, returns '11AM' instead of '11:00AM'
export var formatTimeToAmPm = function (time, noZeros) {
    if (time === undefined || time === null || time > 1440 || time < 0)
        return '-';
    var hours = Math.floor(Number(time) / 60);
    var minutes = Number(time) % 60;
    var minutesToString = minutes === 0 && noZeros ? '' : ":".concat(minutes.toString().padStart(2, '0'));
    if (hours === 0 || hours === 24) {
        return "12".concat(minutesToString, "AM");
    }
    if (hours < 12) {
        return "".concat(hours).concat(minutesToString, "AM");
    }
    if (hours === 12) {
        return "12".concat(minutesToString, "PM");
    }
    if (hours > 12) {
        return "".concat(hours - 12).concat(minutesToString, "PM");
    }
};
export var formatTimeToMinutes = function (time) {
    var regex = /(AM)|(PM)/i;
    var times = time.replace(regex, '').split(':');
    var hours;
    if (/AM/i.test(time)) {
        hours = times[0] === '12' ? 0 : Number(times[0]);
    }
    else {
        hours = times[0] === '12' ? 12 : Number(times[0]) + 12;
    }
    var minutes = times[1];
    return Number(hours) * 60 + Number(minutes);
};
