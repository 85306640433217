var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { t } from 'i18next';
import { BaseCard } from '@core/components/baseCard';
import styles from './index.module.sass';
var checkDataByCardType = function (type) {
    switch (type) {
        case 'order':
            return {
                title: t('label_order_test'),
                img: require('@core/shared/images/shop/logo.png'),
            };
        case 'location':
            return {
                title: t('label_start_locatiions'),
                img: require('@core/shared/images/shop/location.png'),
            };
        case 'results':
            return {
                title: t('label_health_records'),
                img: require('@core/shared/images/shop/health_records.png'),
            };
        case 'alerts':
            return {
                title: t('label_alarms'),
                img: require('@core/shared/images/shop/biomarkers.png'),
            };
    }
};
export var TestingCard = function (_a) {
    var type = _a.type, onClick = _a.onClick, classNames = _a.classNames, classNamesIcon = _a.classNamesIcon, classNamesTitle = _a.classNamesTitle;
    var cardData = checkDataByCardType(type);
    var handleOnClick = function () {
        onClick && onClick();
    };
    return (_jsxs(BaseCard, __assign({ handleOnClick: handleOnClick, classNames: clsx(styles.card, classNames) }, { children: [_jsx("div", __assign({ className: clsx(styles.title, classNamesTitle) }, { children: cardData.title })), _jsx("div", __assign({ className: clsx(styles.icon, classNamesIcon) }, { children: _jsx("img", { src: cardData.img, alt: cardData.title, className: styles.iconImage }) }))] })));
};
