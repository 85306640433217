var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import CheckMark from '@core/shared/svg/checkMark';
import Plus from '@core/shared/svg/plus';
import styles from './index.module.sass';
export var SelectItems = function (_a) {
    var items = _a.items, values = _a.values, setValues = _a.setValues, className = _a.className, _b = _a.type, type = _b === void 0 ? 'State' : _b, multi = _a.multi;
    var handleClick = function (id) {
        if (multi) {
            values.includes(id)
                ? setValues(function (prev) { return prev.filter(function (el) { return el !== id; }); })
                : setValues(function (prev) { return __spreadArray(__spreadArray([], prev, true), [id], false); });
        }
        else {
            setValues([id]);
        }
    };
    return (_jsx("div", __assign({ className: clsx(styles.SelectItems, className) }, { children: items.map(function (item) {
            var checked = values.includes(item.id);
            return (_jsxs("div", __assign({ onClick: function () { return handleClick(item.id); }, className: clsx(styles.SelectItem, checked && styles.SelectItemChecked, type && styles["SelectItem".concat(type)]) }, { children: [_jsx("span", __assign({ className: styles.SelectItemLabel }, { children: item.label })), _jsx("div", __assign({ className: styles.SelectItemCheckbox }, { children: checked ? (_jsx(CheckMark, {})) : type === 'State' ? (_jsx("div", { className: styles.SelectItemCheckboxEmpty })) : (_jsx(Plus, {})) }))] }), item.id));
        }) })));
};
