var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export var DatePickerCalendar = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 4C8 3.44772 8.44772 3 9 3C9.55228 3 10 3.44772 10 4V5H18V4C18 3.44772 18.4477 3 19 3C19.5523 3 20 3.44772 20 4V5C22.2091 5 24 6.79086 24 9V20C24 22.2091 22.2091 24 20 24H8C5.79086 24 4 22.2091 4 20V9C4 6.79086 5.79086 5 8 5V4ZM18 7V8C18 8.55228 18.4477 9 19 9C19.5523 9 20 8.55228 20 8V7C21.1046 7 22 7.89543 22 9V12H6V9C6 7.89543 6.89543 7 8 7V8C8 8.55228 8.44772 9 9 9C9.55228 9 10 8.55228 10 8V7H18ZM22 14H6L6 20C6 21.1046 6.89543 22 8 22H20C21.1046 22 22 21.1046 22 20V14Z", fill: "black" }) }) }))); };
