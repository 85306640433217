var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var CheckMark = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.70711 13.196C7.31658 12.8055 6.68342 12.8055 6.29289 13.196C5.90237 13.5866 5.90237 14.2197 6.29289 14.6102L12.1732 20.4905L21.9566 10.7071C22.3471 10.3166 22.3471 9.68342 21.9566 9.29289C21.5661 8.90237 20.9329 8.90237 20.5424 9.29289L12.1732 17.6621L7.70711 13.196Z", fill: "white" }) }))); };
export default CheckMark;
