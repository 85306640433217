var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useEffect, } from 'react';
import clsx from 'clsx';
import ArrowRight from '@core/shared/svg/arrow-right2';
import styles from './index.module.sass';
export var Button = forwardRef(function (_a, ref) {
    var id = _a.id, label = _a.label, disabled = _a.disabled, primary = _a.primary, icon = _a.icon, handler = _a.handler, menuEl = _a.menuEl, classes = _a.classes, _b = _a.type, type = _b === void 0 ? 'button' : _b, loading = _a.loading, deleting = _a.deleting, deleteAcc = _a.deleteAcc, link = _a.link, isEnterHandle = _a.isEnterHandle, attentionMarker = _a.attentionMarker, children = _a.children, withArrow = _a.withArrow, props = __rest(_a, ["id", "label", "disabled", "primary", "icon", "handler", "menuEl", "classes", "type", "loading", "deleting", "deleteAcc", "link", "isEnterHandle", "attentionMarker", "children", "withArrow"]);
    var handClick = function () {
        handler && handler();
    };
    var handleEnter = function (event) {
        event.key === 'Enter' && handClick();
    };
    useEffect(function () {
        isEnterHandle && window.addEventListener('keypress', handleEnter);
        return function () {
            window.removeEventListener('keypress', handleEnter);
        };
    }, [id]);
    var mainClasses = [
        styles.btn,
        disabled && styles.btnDisabled,
        primary && styles.btnPrimary,
        link && styles.btnLink,
        menuEl && styles.btnMenuEl,
        deleting && styles.deleting,
        deleteAcc && styles.deleteAcc,
        loading && styles.loading,
    ];
    classes && mainClasses.push.apply(mainClasses, classes);
    return (_jsx(_Fragment, { children: _jsxs("button", __assign({ ref: ref, className: clsx(mainClasses), onClick: handClick, disabled: disabled, type: type }, (type === 'submit' && {
            onSubmit: handClick,
        }), props, { children: [_jsxs("div", __assign({ className: clsx(styles.btnInner, withArrow && styles.btnInnerWithArrow) }, { children: [icon, label, menuEl && _jsx("span", { className: styles.btnArrow }), withArrow && (_jsx("div", __assign({ className: styles.btnArrow }, { children: _jsx(ArrowRight, {}) }))), children && children] })), attentionMarker && _jsx("div", { className: styles.attentionMarker }), loading && (_jsx("div", __assign({ className: styles.loader }, { children: _jsx("div", { className: styles.bubbleLoader }) })))] })) }));
});
Button.displayName = 'Button';
