var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { Button } from '@components/button';
import { HeaderModal } from '@components/modals/headerModal';
import { Avatar } from '@core/components/avatar';
import { MainHeaderButton } from '@core/components/buttons/mainHeaderButton';
import { showModal } from '@core/components/modals';
import Burger from '@core/shared/svg/burger';
import Logo from '@core/shared/svg/qsure/logo';
import { checkSubscriptionDetail } from '@core/shared/utils/subscriptionUtils';
import { changeAppScreenToHome, isReactNativeWebView } from '@core/shared/utils/webViewUtils';
import useWindowDimensions from '@core/shared/utils/widthHook';
import styles from './index.module.sass';
export var Header = function () {
    var _a;
    var navigate = useNavigate();
    var location = useLocation();
    var _b = useSelector(function (state) { return state.profile; }), self = _b.self, subscriptions = _b.subscriptions, selfId = _b.id;
    var subscriptionProducts = useSelector(function (s) { return s.subscriptions; }).subscriptionProducts;
    var isLoggedIn = Boolean(selfId);
    var wideScreen = useWindowDimensions().isDesktop;
    var name = "".concat((_a = self === null || self === void 0 ? void 0 : self.firstName) === null || _a === void 0 ? void 0 : _a[0], ". ").concat(self.lastName);
    var subscriptionDetails = useMemo(function () {
        var currentSubscription = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.find(function (sub) { return sub.status === 'active'; });
        var subProduct = subscriptionProducts.find(function (sub) { return sub._id === (currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.subscription_product_id); });
        if (!subProduct)
            return null;
        return checkSubscriptionDetail({ subscription: subProduct, iconWithBorder: true });
    }, [subscriptionProducts, subscriptions]);
    var modalHandler = function (url) {
        navigate(url);
    };
    function handleBack() {
        if (isReactNativeWebView) {
            changeAppScreenToHome();
            return;
        }
    }
    return (_jsx("div", __assign({ className: styles.container }, { children: _jsx("div", __assign({ className: styles.header }, { children: isReactNativeWebView ? (_jsxs("div", __assign({ className: styles.webViewHeader }, { children: [_jsx("div", __assign({ className: styles.headerMenuLogo, onClick: function () { return navigate(routePaths.LANDING_HOME); } }, { children: _jsx(Logo, {}) })), _jsx(Button, { label: t('label_landing_header_btn_back'), gray: true, leftIcon: true, withoutArrow: true, handler: handleBack })] }))) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.headerMenu }, { children: [_jsx("div", __assign({ className: styles.headerMenuLogo, onClick: function () { return navigate(routePaths.LANDING_HOME); } }, { children: _jsx(Logo, {}) })), wideScreen && (_jsxs("div", __assign({ className: styles.headerMenuNav }, { children: [_jsx(Link, __assign({ to: routePaths.LANDING_HOME }, { children: _jsx("div", __assign({ className: clsx(styles.headerMenuNavItem, location.pathname === routePaths.LANDING_HOME &&
                                                styles.headerMenuNavItemActive) }, { children: t('label_landing_header_btn_home') })) })), _jsx(Link, __assign({ to: routePaths.SERVICES }, { children: _jsx("div", __assign({ className: clsx(styles.headerMenuNavItem, location.pathname === routePaths.SERVICES && styles.headerMenuNavItemActive) }, { children: t('label_landing_header_btn_services') })) })), _jsx(Link, __assign({ to: routePaths.EMPLOYERS }, { children: _jsx("div", __assign({ className: clsx(styles.headerMenuNavItem, location.pathname === routePaths.EMPLOYERS &&
                                                styles.headerMenuNavItemActive) }, { children: t('label_landing_header_btn_employers') })) })), _jsx(Link, __assign({ to: routePaths.CONSUMERS }, { children: _jsx("div", __assign({ className: clsx(styles.headerMenuNavItem, location.pathname === routePaths.CONSUMERS &&
                                                styles.headerMenuNavItemActive) }, { children: t('label_landing_header_btn_consumers') })) })), _jsx(Link, __assign({ to: routePaths.SUPPORT }, { children: _jsx("div", __assign({ className: clsx(styles.headerMenuNavItem, location.pathname === routePaths.SUPPORT && styles.headerMenuNavItemActive) }, { children: t('label_landing_header_btn_support') })) })), _jsx(Link, __assign({ to: routePaths.CONTACTUS }, { children: _jsx("div", __assign({ className: clsx(styles.headerMenuNavItem, location.pathname === routePaths.CONTACTUS &&
                                                styles.headerMenuNavItemActive) }, { children: t('label_landing_header_btn_contact_us') })) }))] })))] })), _jsx("div", __assign({ className: styles.headerButtons }, { children: wideScreen ? (_jsxs(_Fragment, { children: [isLoggedIn ? (_jsxs(MainHeaderButton, __assign({ isActive: false, onClick: function () { return navigate(routePaths.SETTINGS); }, className: styles.headerButtonsItemLogin }, { children: [_jsx(Avatar, { patient: self, firstName: self === null || self === void 0 ? void 0 : self.firstName, lastName: self === null || self === void 0 ? void 0 : self.lastName, showName: false, needLoadAvatar: true, avatarSize: "small", classNameImage: styles.avatarImg }), subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.icon(), _jsx("div", __assign({ className: styles.headerButtonText }, { children: wideScreen && name }))] }))) : (_jsx(Link, __assign({ to: routePaths.LOGIN }, { children: _jsx(Button, { label: t('label_landing_header_btn_login'), classes: [styles.headerButtonsItem], gray: true, withoutArrow: true }) }))), _jsx(Link, __assign({ to: 'tel:+17182478000' }, { children: _jsx(Button, { label: '(718) 247-8000', classes: [styles.headerButtonsItem], gray: true, withoutArrow: true }) })), _jsx(Link, __assign({ to: isLoggedIn ? routePaths.HOME : routePaths.LOGIN }, { children: _jsx(Button, { label: t('label_landing_header_btn_join_now'), classes: [styles.headerButtonsItem] }) }))] })) : (_jsx("div", __assign({ className: styles.headerButtonsBurger, onClick: function () {
                                return showModal(HeaderModal, {
                                    modalHandler: modalHandler,
                                    subscriptionDetails: subscriptionDetails,
                                    isLoggedIn: isLoggedIn,
                                });
                            } }, { children: _jsx(Burger, {}) }))) }))] })) })) })));
};
