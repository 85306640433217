var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import ButtonContact from '@core/components/buttons/buttonContacts';
import { Input } from '@core/components/formComponents/input';
import { showModal } from '@core/components/modals';
import { Timer } from '@core/components/timer';
import { Title } from '@core/components/title';
import TokenService from '@core/services/tokens';
import Mail from '@core/shared/svg/mail';
import Phone from '@core/shared/svg/phone';
import { deletePersonSelf } from '@core/store/reducers/profileSlice';
import { verificationRequest } from '@core/store/reducers/verificationSlice';
import DeletedSuccessfully from '../deletedSuccessfully';
import styles from './index.module.sass';
var mapState = function (state) { return ({
    storeCode: state.code,
    profile: state.profile,
    loading: state.loading,
    user: state,
    errorsStore: state.errors,
}); };
var DeletedVerification = function () {
    var _a = useForm({
        reValidateMode: 'onChange',
    }), register = _a.register, errors = _a.formState.errors, setValue = _a.setValue, unregister = _a.unregister, control = _a.control, clearErrors = _a.clearErrors, setError = _a.setError, getValues = _a.getValues, trigger = _a.trigger;
    var _b = useSelector(mapState), selfDelete = _b.profile.selfDelete, loading = _b.loading, storeCode = _b.storeCode, errorsStore = _b.errorsStore;
    var dispatch = useDispatch();
    var _c = useState('code'), codePosition = _c[0], setCodePosition = _c[1];
    var _d = useState(false), positionLoading = _d[0], setPositionLoading = _d[1];
    var mainContactIsPhone = useMemo(function () {
        return selfDelete.type === 'phone';
    }, []);
    var onChangePositionClick = function () {
        var type = codePosition === 'call' ? 'code' : 'call';
        setPositionLoading(true);
        resend(type, function () {
            setCodePosition(type);
            setPositionLoading(false);
        });
    };
    useEffect(function () {
        for (var key in errorsStore) {
            if (Object.prototype.hasOwnProperty.call(errorsStore, key)) {
                var element = errorsStore[key];
                if (element)
                    setError(key, { type: 'pattern', message: element });
            }
        }
    }, [errorsStore, setError]);
    var disableChangePosition = false;
    var resend = function (type, callback) {
        dispatch(deletePersonSelf({ callback: callback, call: type === 'call' }));
    };
    var successDeletedCallback = function () {
        showModal(DeletedSuccessfully, {
            type: 'fullPage',
            showCloseIcon: false,
        });
        TokenService.clearToken();
    };
    var handleDeleteAccount = function () { return __awaiter(void 0, void 0, void 0, function () {
        var code, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    code = getValues('code');
                    return [4 /*yield*/, trigger('code')];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(verificationRequest({
                            id: storeCode.verification_id,
                            code: code,
                            callback: successDeletedCallback,
                        }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", __assign({ className: styles.deletedVerification }, { children: _jsxs("div", __assign({ className: styles.deletedVerificationElements }, { children: [_jsx(Title, { classes: [styles.deleteTitle], title: t('btn_delete_account') }), _jsxs(ButtonContact, __assign({ disable: true }, { children: [selfDelete.type === 'phone' ? _jsx(Phone, {}) : _jsx(Mail, {}), selfDelete.identifier] })), _jsxs("div", __assign({ className: styles.codeText }, { children: [t('label_code_delete_acc_1'), _jsx("br", {}), t('label_code_delete_acc_2')] })), _jsx(Input, { label: "code", placeholder: t('placeholder_code'), type: "text", control: control, register: register, clearErrors: clearErrors, unregister: unregister, error: errors.code, autofocus: true, inputmode: "numeric", autocomplete: "one-time-code", setEmpty: function () {
                        setValue('code', '');
                    }, validation: {
                        required: { value: true, message: 'error_required' },
                        maxLength: { value: 5, message: 'error_auth_code_max_length' },
                        onChange: function () {
                            clearErrors('code');
                        },
                    } }), _jsx(Button, { label: t('btn_delete_account'), handler: handleDeleteAccount, primary: true, loading: loading.isLoading && loading.type === 'verification/verificationRequest', disabled: loading.isLoading && loading.type === 'verification/verificationRequest', deleting: true, classes: [styles.deletedVerificationBtn], isEnterHandle: true }), _jsx(Timer, { handle: function () { return resend(codePosition); }, classes: [styles.deletedVerificationBtn], type: codePosition === 'call' ? 'call' : 'sms' }), mainContactIsPhone && (_jsx("div", __assign({ className: styles.changeMethod }, { children: positionLoading ? (_jsx("div", { className: styles.bubbleLoader })) : (_jsxs("div", __assign({ className: styles.changeMethodInner }, { children: [_jsxs("span", { children: [codePosition === 'call'
                                        ? t('label_pre_sms_method')
                                        : t('label_pre_call_method'), "\u00A0"] }), _jsx("span", __assign({ className: clsx(styles.changeMethodBtn, disableChangePosition && styles.changeMethodBtnDisabled), onClick: onChangePositionClick }, { children: codePosition === 'call'
                                    ? t('label_sms_method')
                                    : t('label_call_method') }))] }))) })))] })) })));
};
export default DeletedVerification;
