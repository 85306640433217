var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { checkSubscriptionDetail, formatPlanDate } from '@core/shared/utils/subscriptionUtils';
import useWindowDimensions from '@core/shared/utils/widthHook';
import styles from './index.module.sass';
export var CurrentPlanCard = function (_a) {
    var currentPlan = _a.currentPlan, onCancelPlan = _a.onCancelPlan;
    var self = useSelector(function (state) { return state.profile; }).self;
    var subscriptionProducts = useSelector(function (s) { return s.subscriptions; }).subscriptionProducts;
    var isMobile = useWindowDimensions().isMobile;
    var planDetails = useMemo(function () {
        var subProduct = subscriptionProducts.find(function (sub) { return sub._id === (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.subscription_product_id); });
        if (!subProduct)
            return null;
        return checkSubscriptionDetail({ subscription: subProduct, iconWithBorder: true });
    }, [currentPlan, subscriptionProducts]);
    if (isMobile) {
        return (_jsxs("div", __assign({ className: clsx(styles.currentPlanMobile, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isSilver) && styles.imageSilver, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isGold) && styles.imageGold, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isPlatinum) && styles.imagePlatinum, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isDiamond) && styles.imageDiamond) }, { children: [_jsxs("div", __assign({ className: styles.currentPlanMobileTop }, { children: [planDetails === null || planDetails === void 0 ? void 0 : planDetails.icon(), _jsx("div", __assign({ className: styles.currentPlanMobileTopTitle }, { children: "".concat(planDetails === null || planDetails === void 0 ? void 0 : planDetails.name, " ").concat(t("label_plan")) }))] })), _jsxs("div", __assign({ className: styles.currentPlanMobileBottom }, { children: [_jsxs("div", __assign({ className: styles.currentPlanMobileBottomLeft }, { children: [_jsx("div", __assign({ className: styles.currentPlanMobileBottomLeftTitle }, { children: t("label_plan_price_per_".concat(currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.subscription_price.interval), {
                                        price: currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.subscription_price.amount.value,
                                    }) })), _jsx("div", __assign({ className: styles.currentPlanMobileBottomLeftSubTitle }, { children: t('label_plan_next_payment', {
                                        date: formatPlanDate(currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.current_period_end),
                                    }) }))] })), _jsx("div", { className: styles.currentPlanMobileBottomRight })] }))] })));
    }
    return (_jsxs("div", __assign({ className: styles.currentPlan }, { children: [_jsxs("div", __assign({ className: styles.currentPlanLeft }, { children: [_jsxs("div", __assign({ className: clsx(styles.currentPlanLeftImage, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isSilver) && styles.imageSilver, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isGold) && styles.imageGold, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isPlatinum) && styles.imagePlatinum, (planDetails === null || planDetails === void 0 ? void 0 : planDetails.isDiamond) && styles.imageDiamond) }, { children: [_jsx("div", __assign({ className: styles.currentPlanLeftImageTop }, { children: _jsx("img", { src: require('@core/shared/images/qsure/logoGold.png'), alt: "logo" }) })), _jsx("div", __assign({ className: styles.currentPlanLeftImageBottom }, { children: "".concat(self.firstName, " ").concat(self.lastName) }))] })), _jsxs("div", __assign({ className: styles.currentPlanLeftInfo }, { children: [_jsxs("div", __assign({ className: styles.planName }, { children: [planDetails === null || planDetails === void 0 ? void 0 : planDetails.icon(), _jsx("div", __assign({ className: styles.planTitle }, { children: "".concat(planDetails === null || planDetails === void 0 ? void 0 : planDetails.name, " ").concat(t("label_plan")) }))] })), _jsxs("div", __assign({ className: styles.planDetails }, { children: [_jsx("div", __assign({ className: styles.planDetailsPrice }, { children: t("label_plan_price_per_".concat(currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.subscription_price.interval), {
                                            price: currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.subscription_price.amount.value,
                                        }) })), _jsx("div", __assign({ className: styles.planDetailsNextPayment }, { children: t('label_plan_next_payment', {
                                            date: formatPlanDate(currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.current_period_end),
                                        }) }))] }))] }))] })), _jsx("div", { className: styles.currentPlanRight })] })));
};
