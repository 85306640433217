var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import styles from './index.module.sass';
export var HeaderButton = function (_a) {
    var handler = _a.handler, Icon = _a.Icon, nobg = _a.nobg, isArrowButton = _a.isArrowButton;
    return (_jsx("div", __assign({ className: clsx(styles.headerButton, nobg && styles.headerButtonWitchoutBack, isArrowButton && styles.headerButtonArrow), onClick: handler }, { children: Icon })));
};
