import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    patient: null,
    approvedPatient: false,
    tests: null,
};
export var newOrderSlice = createSlice({
    name: 'newOrder',
    initialState: initialState,
    reducers: {
        setTests: function (state, action) {
            state.tests = action.payload.tests;
        },
    },
});
export var setTests = newOrderSlice.actions.setTests;
export default newOrderSlice.reducer;
