var PersonAccessRightType;
(function (PersonAccessRightType) {
    PersonAccessRightType["edit"] = "edit";
    PersonAccessRightType["profile"] = "profile";
    PersonAccessRightType["results"] = "results";
})(PersonAccessRightType || (PersonAccessRightType = {}));
export var PersonFlag;
(function (PersonFlag) {
    PersonFlag["patient"] = "patient";
    PersonFlag["staff"] = "staff";
    PersonFlag["minor"] = "minor";
    PersonFlag["locked"] = "locked";
    PersonFlag["hasOrders"] = "has_orders";
})(PersonFlag || (PersonFlag = {}));
