var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { Input } from '@core/components/formComponents/input';
import { COUNTRIES } from '@core/shared/phoneMask/';
import SearchIcon from '@core/shared/svg/search';
import { getPhoneMask } from '@core/shared/utils/phoneUtils';
import useWindowDimensions from '@core/shared/utils/widthHook';
import { setRegion } from '@core/store/reducers/helpers';
import styles from './index.module.sass';
export var SelectRegion = function (_a) {
    var className = _a.className, isAdmin = _a.isAdmin, classNameElements = _a.classNameElements, isOpenSelectRegions = _a.isOpenSelectRegions, handleOpenSelectRegions = _a.handleOpenSelectRegions, handleAction = _a.handleAction, flagElement = _a.flagElement, inputElement = _a.inputElement, handleRegionChanged = _a.handleRegionChanged;
    var _b = useForm(), watch = _b.watch, setFocus = _b.setFocus, register = _b.register, control = _b.control, setValue = _b.setValue, clearErrors = _b.clearErrors, unregister = _b.unregister;
    var activeRegion = useSelector(function (s) { return s.helpers; }).activeRegion;
    var dispatch = useDispatch();
    var height = useWindowDimensions().height;
    var wrapperRef = useRef(null);
    var _c = useState(COUNTRIES), filteredList = _c[0], setFilteredList = _c[1];
    var sortCountries = function (countries) {
        var _a = countries.reduce(function (accum, element) {
            if (element.priority === undefined) {
                //check only undefined!
                accum[1].push(element);
                return accum;
            }
            if (!accum[0][element.priority]) {
                accum[0][element.priority] = [];
            }
            accum[0][element.priority].push(element);
            return accum;
        }, [[], []]), priority = _a[0], withoutPriority = _a[1];
        var sortByLabel = function (arr) {
            return arr.sort(function (a, b) { return (a.label < b.label ? -1 : 1); });
        };
        var sortedPriority = priority.map(function (country) { return sortByLabel(country); }).flat();
        var sortedWithoutPriority = sortByLabel(withoutPriority);
        return __spreadArray(__spreadArray([], sortedPriority, true), sortedWithoutPriority, true);
    };
    var chooseRegion = function (iso2, dialCode) {
        dispatch(setRegion({
            dialCode: dialCode,
            img: require("@core/shared/images/flags/".concat(iso2, ".svg")),
            inputMask: getPhoneMask(iso2),
            code: iso2,
        }));
        handleRegionChanged(true);
        handleAction && handleAction();
        handleOpenSelectRegions(false);
    };
    var calculateHeight = useCallback(function () {
        if (!inputElement && !(wrapperRef === null || wrapperRef === void 0 ? void 0 : wrapperRef.current)) {
            return;
        }
        var topCoord = inputElement.getBoundingClientRect().y;
        var heightOfList = height - (topCoord + 73);
        if (heightOfList < 200) {
            heightOfList = 200;
            wrapperRef.current.style.top = "".concat(-(heightOfList + 10), "px");
        }
        else {
            wrapperRef.current.style.top = '59px';
        }
        wrapperRef.current.style.width = inputElement.clientWidth + 'px';
        wrapperRef.current.style.height = "".concat(heightOfList, "px");
    }, [inputElement, wrapperRef, height]);
    useEffect(function () {
        if (!wrapperRef && !flagElement) {
            return;
        }
        function handleClickOutside(event) {
            if (!flagElement.contains(event.target) &&
                !wrapperRef.current.contains(event.target)) {
                handleOpenSelectRegions(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return function () { return document.removeEventListener('mousedown', handleClickOutside); };
    }, [wrapperRef, flagElement]);
    useEffect(function () {
        sortCountries(sortCountries(filteredList));
    }, []);
    useEffect(function () {
        if (isOpenSelectRegions) {
            calculateHeight();
            setTimeout(function () { return setFocus('search'); }, 300);
        }
    }, [isOpenSelectRegions]);
    useEffect(function () {
        var watchSearch = watch('search');
        if (watchSearch)
            setFilteredList(COUNTRIES.filter(function (elem) {
                var _a;
                return elem.label.toLowerCase().includes(watchSearch.toLowerCase()) ||
                    ((_a = elem.phone.dialCode) === null || _a === void 0 ? void 0 : _a.startsWith(watchSearch.toLowerCase().replace('+', '')));
            }));
        return function () { return setFilteredList(sortCountries(COUNTRIES)); };
    }, [watch('search')]);
    var wrapperClassNames = clsx([
        styles.list,
        className,
        isAdmin && styles.listAdm,
        isOpenSelectRegions && styles.listOpen,
    ]);
    return (_jsxs("div", __assign({ className: wrapperClassNames, ref: wrapperRef }, { children: [_jsx("div", __assign({ className: clsx(styles.listSearch, styles.search) }, { children: _jsx(Input, { label: "search", type: "text", icon: _jsx(SearchIcon, {}), setEmpty: function () { return setValue('search', ''); }, placeholder: t('placeholder_search_country'), register: register, clearErrors: clearErrors, unregister: unregister, control: control, classes: [isAdmin && styles.searchAdm], stylesFor: 'adm' }) })), _jsxs("div", __assign({ className: clsx(styles.elements, classNameElements) }, { children: [filteredList.map(function (_a) {
                        var label = _a.label, iso2 = _a.iso2, dialCode = _a.phone.dialCode;
                        return (_jsxs("div", __assign({ className: clsx(styles.region, styles.listElement, activeRegion.code === iso2 && styles.regionActive), onClick: function () { return chooseRegion(iso2, dialCode); } }, { children: [_jsx("div", __assign({ className: clsx(styles.regionName, isAdmin && styles.isNotBold) }, { children: label })), _jsx("div", __assign({ className: clsx(styles.regionDialCode, isAdmin && styles.isNotold) }, { children: '+' + dialCode }))] }), iso2));
                    }), !filteredList.length && (_jsx("div", __assign({ className: styles.listEmpty }, { children: t('label_search_empty') })))] }))] })));
};
