var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from 'redux-saga/effects';
import authService from '@core/services/auth';
import TokenService from '@core/services/tokens';
import { setAlert } from '@core/store/reducers/alertSlice';
import { addTry, resetTries } from '@core/store/reducers/authSlice';
import { resetStoreErrors } from '@core/store/reducers/errorsSlice';
import { addError } from '@core/store/reducers/errorsSlice';
import { resetCodeInfo, verificationRequest } from '@core/store/reducers/verificationSlice';
import { handlerErrors } from '@core/store/sagas/errors';
import { getDataAsync, getInfoAsync } from './profiles';
import { getInfo } from '../reducers/profileSlice';
export function verificationAsync(_a) {
    var id, code, disableUpdateInfo, callback, alertMsg, data, error_1, e;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, code = payload.code, disableUpdateInfo = payload.disableUpdateInfo, callback = payload.callback, alertMsg = payload.alertMsg;
                return [4 /*yield*/, put(resetStoreErrors())];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(resetTries())];
            case 2:
                _b.sent();
                return [4 /*yield*/, call(authService.verification, id, code)];
            case 3:
                data = (_b.sent()).data;
                if (!(data === null || data === void 0 ? void 0 : data.access_token)) return [3 /*break*/, 5];
                return [4 /*yield*/, TokenService.setToken(data)];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                if (!!disableUpdateInfo) return [3 /*break*/, 12];
                return [4 /*yield*/, call(getDataAsync)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7:
                _b.trys.push([7, 9, , 12]);
                return [4 /*yield*/, call(getInfoAsync, { payload: function () { }, type: getInfo.type })];
            case 8:
                _b.sent();
                return [3 /*break*/, 12];
            case 9:
                error_1 = _b.sent();
                e = error_1;
                if (!(e.response.status === 404)) return [3 /*break*/, 11];
                return [4 /*yield*/, call(callback, true)];
            case 10:
                _b.sent();
                return [2 /*return*/];
            case 11: return [3 /*break*/, 12];
            case 12:
                if (!callback) return [3 /*break*/, 14];
                return [4 /*yield*/, call(callback, false)];
            case 13:
                _b.sent();
                _b.label = 14;
            case 14:
                if (!alertMsg) return [3 /*break*/, 16];
                return [4 /*yield*/, put(setAlert(alertMsg))];
            case 15:
                _b.sent();
                _b.label = 16;
            case 16: return [4 /*yield*/, put(resetCodeInfo())];
            case 17:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function checkVerificationAsync(_a) {
    var response;
    var e = _a.e;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                response = e.response;
                return [4 /*yield*/, put(addTry('code'))];
            case 1:
                _b.sent();
                if (!(response.data.error === 'max_verification_attempts_error')) return [3 /*break*/, 3];
                return [4 /*yield*/, put(addError({ type: 'code', error: 'error_code_tries' }))];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3:
                if (!(response.data.error === 'verification_not_valid_error')) return [3 /*break*/, 5];
                return [4 /*yield*/, put(addError({ type: 'code', error: 'error_code_timeout' }))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                if (!(response.data.error === 'verification_timeout_error')) return [3 /*break*/, 7];
                return [4 /*yield*/, put(addError({ type: 'code', error: 'error_code_timeout' }))];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7:
                if (!(response.data.error === 'invalid_verification_code_error')) return [3 /*break*/, 9];
                return [4 /*yield*/, put(addError({ type: 'code', error: 'error_code_incorrect' }))];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9: return [2 /*return*/];
        }
    });
}
export function verificationWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(verificationRequest().type, handlerErrors(verificationAsync, checkVerificationAsync, false))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
