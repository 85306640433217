import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { createForms } from '@core/pages/forms';
import { SID } from '@core/shared/models/survey';
import { clearSurvey, completeRisksTest } from '@core/store/reducers/surveySlice';
export var HealthRiskTest = function () {
    var dispatch = useDispatch();
    var state = useLocation().state;
    var self = useSelector(function (state) { return state.profile; }).self;
    useEffect(function () {
        return function () {
            dispatch(clearSurvey());
        };
    }, []);
    return createForms({
        sid: SID.HEALTH_RISK,
        title: t('label_health_risks_management'),
        backRoute: routePaths.HOME,
        params: { person_id: self.id },
        callback: function () {
            dispatch(completeRisksTest(state === null || state === void 0 ? void 0 : state.redirectPath));
        },
    });
};
