var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { t } from 'i18next';
import { Label } from '@core/components/formComponents/admin/inputs';
import { TextAreaWithSearch } from '@core/components/formComponents/textAreaWithSearch';
import styles from './index.module.sass';
export var ItemsPicker = function (_a) {
    var items = _a.items, setItems = _a.setItems, itemsError = _a.itemsError, _b = _a.hasAccessToEdit, hasAccessToEdit = _b === void 0 ? true : _b, _c = _a.isRequired, isRequired = _c === void 0 ? false : _c, label = _a.label, _d = _a.placeholder, placeholder = _d === void 0 ? t('label_add_location') : _d, _e = _a.showAddAllButtons, showAddAllButtons = _e === void 0 ? false : _e, className = _a.className, textAreaClassName = _a.textAreaClassName, insideLabel = _a.insideLabel, hasInsideLabel = _a.hasInsideLabel, _f = _a.allowEmpty, allowEmpty = _f === void 0 ? true : _f, _g = _a.showListOnClick, showListOnClick = _g === void 0 ? true : _g;
    var batchButtons = useMemo(function () {
        var all = items === null || items === void 0 ? void 0 : items.every(function (item) { return item.isSelect === true; });
        var any = items === null || items === void 0 ? void 0 : items.some(function (item) { return item.isSelect === true; });
        return { addAll: !all, clear: any };
    }, [items]);
    var switchAll = function (condition) {
        var newItems = items.map(function (elem) { return (__assign(__assign({}, elem), { isSelect: condition })); });
        setItems(newItems);
    };
    var selectItem = function (searchItem, condition) {
        var current = items.find(function (i) { return i.id === searchItem.id; });
        var reduced = items.reduce(function (acc, item) {
            if (item.id !== searchItem.id) {
                if (item.isSelect === true) {
                    acc.true.push(__assign({}, item));
                }
                else {
                    acc.false.push(__assign({}, item));
                }
            }
            return acc;
        }, { true: [], false: [] });
        setItems(__spreadArray(__spreadArray(__spreadArray([], reduced.true, true), [__assign(__assign({}, current), { isSelect: condition })], false), reduced.false, true));
    };
    return (_jsxs("div", __assign({ className: clsx(styles.itemsPicker, className) }, { children: [_jsxs("div", __assign({ className: styles.itemsInput }, { children: [_jsx(Label, __assign({ className: styles.itemsInputLabel }, { children: label })), hasAccessToEdit && showAddAllButtons && (_jsxs("div", __assign({ className: styles.itemsInputButtons }, { children: [batchButtons.addAll && (_jsx("button", __assign({ onClick: function () { return switchAll(true); } }, { children: t('btn_add_all') }))), batchButtons.clear && (_jsx("button", __assign({ onClick: function () { return switchAll(false); } }, { children: t('btn_clear') })))] })))] })), _jsx("div", __assign({ className: styles.searchArea }, { children: _jsx(TextAreaWithSearch, { data: items, placeholder: placeholder, error: isRequired ? itemsError : '', disabled: !hasAccessToEdit, onSelect: function (item) { return selectItem(item, true); }, onUnselect: function (item) { return selectItem(item, false); }, className: textAreaClassName, hasInsideLabel: hasInsideLabel, insideLabel: insideLabel, allowEmpty: allowEmpty, showListOnClick: showListOnClick }) }))] })));
};
