export var WebViewMessageSource;
(function (WebViewMessageSource) {
    WebViewMessageSource["LabqWebViewGoTo"] = "labqWebViewGoTo";
    WebViewMessageSource["LabqWebViewConsoleLog"] = "labqWebViewConsoleLog";
    WebViewMessageSource["LabqWebViewSetToAsyncStorage"] = "labqWebViewSetToAsyncStorage";
})(WebViewMessageSource || (WebViewMessageSource = {}));
export var WebViewMessageScreens;
(function (WebViewMessageScreens) {
    WebViewMessageScreens["BookAppointment"] = "BookAppointment";
    WebViewMessageScreens["MedicalHistoryScreen"] = "MedicalHistoryScreen";
    WebViewMessageScreens["ViewAllEventsScreen"] = "ViewAllEventsScreen";
    WebViewMessageScreens["HomeScreen"] = "HomeScreen";
    WebViewMessageScreens["OfficesNearbyScreen"] = "OfficesNearbyScreen";
    WebViewMessageScreens["QSureScreen"] = "QSureScreen";
    WebViewMessageScreens["ShopAllTests"] = "ShopAllTests";
})(WebViewMessageScreens || (WebViewMessageScreens = {}));
