var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { Button } from '@components/button';
import { Container } from '@components/landing/container';
import { QuestionForm } from '@components/questionForm';
import { faqsConfig } from '@root/src/configs/landing/faqs';
import styles from './index.module.sass';
export var Faqs = function () {
    var location = useLocation();
    useEffect(function () {
        if (location.state === 'toSupport') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (!location.state) {
            window.scrollTo(0, 0);
        }
    }, []);
    return (_jsxs(Container, { children: [_jsxs("div", __assign({ className: styles.title }, { children: [_jsx("div", __assign({ className: styles.titleSmall }, { children: t('label_landing_faq_name') })), _jsx("div", __assign({ className: styles.titleBig }, { children: t('label_landing_faq_title') })), _jsx("div", __assign({ className: styles.titleText }, { children: t('label_landing_faq_text') }))] })), _jsxs("div", __assign({ className: styles.content }, { children: [faqsConfig.map(function (item) { return (_jsx("div", { children: _jsx(QuestionForm, { question: item.question, answer: item.answer }) }, item.id)); }), _jsx(Link, __assign({ to: routePaths.CONTACTUS }, { children: _jsx(Button, { label: "Contact Us", white: true, classes: [styles.contentButton] }) }))] }))] }));
};
