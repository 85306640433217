var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.sass';
export var UserInfoMessage = function () {
    var _a, _b;
    var profile = useSelector(function (state) { return state; }).profile;
    var _c = useState(''), localFN = _c[0], setLocalFN = _c[1];
    var _d = useState(''), localLN = _d[0], setLocalLN = _d[1];
    var _e = useState(''), localMN = _e[0], setLocalMN = _e[1];
    useEffect(function () {
        if (!profile.self) {
            return;
        }
        setLocalFN(profile.self.firstName);
        setLocalLN(profile.self.lastName);
        setLocalMN(profile.self.middleName);
    }, [profile]);
    var firstAbr = (_a = localFN[0]) === null || _a === void 0 ? void 0 : _a.toUpperCase();
    var lastAbr = (_b = localLN[0]) === null || _b === void 0 ? void 0 : _b.toUpperCase();
    var innerMiddleName = (localFN + localLN + localMN).length > 40 ? '' : localMN;
    return (_jsxs("div", __assign({ className: styles.userInfoMessage }, { children: [_jsx("div", __assign({ className: styles.userPhoto }, { children: firstAbr ? (_jsx("p", __assign({ className: styles.userPhotoAbr }, { children: "".concat(firstAbr).concat(lastAbr) }))) : (_jsx("div", { className: styles.bubbleLoader })) })), _jsxs("div", __assign({ className: styles.userName }, { children: [localFN, " ", innerMiddleName || '', " ", localLN] }))] })));
};
