var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { Button } from '@core/components/buttons';
import Close from '@core/shared/svg/close';
import styles from './index.module.sass';
export var ConfirmationPopup = function (_a) {
    var title = _a.title, description = _a.description, _b = _a.primaryButtonLabel, primaryButtonLabel = _b === void 0 ? 'Confirm' : _b, primaryButtonHandler = _a.primaryButtonHandler, _c = _a.secondaryButtonLabel, secondaryButtonLabel = _c === void 0 ? 'Cancel' : _c, secondaryButtonHandler = _a.secondaryButtonHandler, _d = _a.additionalButtonLabel, additionalButtonLabel = _d === void 0 ? 'Save' : _d, additionalButtonHandler = _a.additionalButtonHandler, closeConfirmation = _a.closeConfirmation, className = _a.className, buttonClassName = _a.buttonClassName, buttonGroupClassName = _a.buttonGroupClassName;
    return (_jsx("div", __assign({ className: clsx(styles.confirmationModalWrapper, className) }, { children: _jsxs("div", __assign({ className: styles.confirmationModal }, { children: [_jsx("div", __assign({ onClick: function () { return closeConfirmation(); }, className: styles.closeIcon }, { children: _jsx(Close, {}) })), _jsx("h3", __assign({ className: styles.confirmationModalTitle }, { children: title })), _jsx("p", __assign({ className: styles.confirmationModalDescription }, { children: description })), _jsxs("div", __assign({ className: clsx(styles.confirmationModalButtonGroup, buttonGroupClassName) }, { children: [primaryButtonHandler && (_jsx(Button, { classes: [styles.confirmationModalButton, buttonClassName], handler: primaryButtonHandler, primary: true, label: primaryButtonLabel })), additionalButtonHandler && (_jsx(Button, { classes: [styles.confirmationModalButton, buttonClassName], handler: additionalButtonHandler, label: additionalButtonLabel })), secondaryButtonHandler && (_jsx(Button, { classes: [styles.confirmationModalButton, buttonClassName], handler: secondaryButtonHandler, label: secondaryButtonLabel }))] }))] })) })));
};
