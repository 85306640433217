var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { AutocompleteFullscreenModal } from '@core/components/formComponents/autocompleteFullscreen/modal';
import { showModal } from '@core/components/modals';
import { config } from '@core/config/config';
import styles from './index.module.sass';
var AutocompleteFullscreen = function (_a) {
    var _b;
    var className = _a.className, slot = _a.slot, content = _a.content, error = _a.error, value = _a.value, disabled = _a.disabled, handleChange = _a.handleChange, _c = _a.useAsIs, useAsIs = _c === void 0 ? false : _c;
    var useAsIsFeature = ((_b = config === null || config === void 0 ? void 0 : config.featureFlags) === null || _b === void 0 ? void 0 : _b.AUTOCOMPLETE_USE_AS_IS) && useAsIs;
    var handleClickAutocomplete = function (e) {
        e.preventDefault();
        showModal(AutocompleteFullscreenModal, {
            handleChange: function (val) {
                handleChange(val);
            },
            useAsIs: useAsIsFeature,
            isBottomModal: true,
            title: content.label,
            autocompleteData: slot.autocomplete_url,
            value: value,
        });
    };
    return (_jsxs("div", __assign({ className: clsx(styles.autocomplete, disabled && styles.disabled, className) }, { children: [_jsxs("div", __assign({ className: styles.autocompleteInputWrapper }, { children: [_jsx("label", __assign({ htmlFor: slot._id, className: clsx(styles.autocompleteLabel, value && styles.autocompleteLabelFocused) }, { children: content.label })), _jsx("div", __assign({ className: styles.autocompleteInputHandler, onClick: handleClickAutocomplete }, { children: _jsx("input", { id: slot._id, className: clsx(styles.autocompleteInput, error && styles.autocompleteInputError, disabled && styles.inputDisabled), value: (value === null || value === void 0 ? void 0 : value.name) || '', onChange: function () { } }) }))] })), error && _jsx("div", __assign({ className: styles.autocompleteError }, { children: error }))] })));
};
export default AutocompleteFullscreen;
