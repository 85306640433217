var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Warning = function () { return (_jsx("svg", __assign({ width: "84", height: "84", viewBox: "0 0 84 84", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M72.7384 55.6629L49.886 14.1131C49.0635 12.6177 47.8328 11.387 46.3373 10.5645C41.9821 8.16905 36.5095 9.75785 34.1141 14.1131L11.2618 55.6629C10.5309 56.9917 10.1477 58.4836 10.1477 60.0002C10.1477 64.9707 14.1771 69.0002 19.1477 69.0002H64.8524C66.369 69.0002 67.8609 68.617 69.1897 67.8861C73.545 65.4907 75.1338 60.0182 72.7384 55.6629ZM43.4458 15.8218C43.9443 16.0959 44.3546 16.5062 44.6287 17.0046L67.4811 58.5544C68.2796 60.0062 67.75 61.8304 66.2982 62.6288C65.8553 62.8724 65.358 63.0002 64.8524 63.0002H19.1477C17.4909 63.0002 16.1477 61.657 16.1477 60.0002C16.1477 59.4947 16.2754 58.9973 16.5191 58.5544L39.3714 17.0046C40.1699 15.5529 41.9941 15.0233 43.4458 15.8218ZM39.0001 30.0002C39.0001 28.3433 40.3432 27.0002 42.0001 27.0002C43.6569 27.0002 45.0001 28.3433 45.0001 30.0002V45.0002C45.0001 46.657 43.6569 48.0002 42.0001 48.0002C40.3432 48.0002 39.0001 46.657 39.0001 45.0002V30.0002ZM42.0001 51.0002C40.3432 51.0002 39.0001 52.3433 39.0001 54.0002C39.0001 55.657 40.3432 57.0002 42.0001 57.0002C43.6569 57.0002 45.0001 55.657 45.0001 54.0002C45.0001 52.3433 43.6569 51.0002 42.0001 51.0002Z", fill: "black" }) }))); };
export default Warning;
