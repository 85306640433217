var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import clsx from 'clsx';
import CheckMark from '@core/shared/svg/checkMark';
import styles from './index.module.sass';
export var CheckboxButton = function (_a) {
    var className = _a.className, prise = _a.prise, label = _a.label, id = _a.id, values = _a.values, _b = _a.onChange, onChange = _b === void 0 ? function () { } : _b, _c = _a.type, type = _c === void 0 ? 'single' : _c, _d = _a.renderFor, renderFor = _d === void 0 ? 'forms' : _d, checked = _a.checked;
    var innerChecked = useMemo(function () {
        return (values === null || values === void 0 ? void 0 : values.includes(id)) || checked;
    }, [values, id, checked]);
    return (_jsxs("div", __assign({ className: clsx(styles.checkboxButton, renderFor === 'chat' && styles.checkboxButtonChat, renderFor === 'forms' && styles.checkboxButtonForms, className), onClick: function () { return onChange(id); } }, { children: [_jsx("span", __assign({ className: clsx(styles.checkboxButtonRadio, type === 'multi' && styles.checkboxButtonRadioMulti, innerChecked && styles.checkboxButtonRadioChecked) }, { children: innerChecked && (_jsx("div", __assign({ className: styles.checkboxButtonRadioCheckMark }, { children: _jsx(CheckMark, {}) }))) })), _jsx("p", __assign({ className: styles.checkboxButtonLabel }, { children: label })), _jsx("p", __assign({ className: styles.checkboxButtonPrice }, { children: prise }))] })));
};
