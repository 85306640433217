var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import moment from 'moment-timezone';
import { config } from '@core/config/config';
import { instance } from '@core/services/axios';
var Booking = /** @class */ (function () {
    function Booking() {
    }
    Booking.prototype.createDraft = function (_a) {
        var patient_person_id = _a.patient_person_id, practice_id = _a.practice_id;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + '/orders/', { patient_person_id: patient_person_id, practice_id: practice_id }, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Booking.prototype.addInsurance = function (_a) {
        var order_id = _a.order_id, data = _a.data;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + "/orders/".concat(order_id, "/insurances"), data, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Booking.prototype.updateOrder = function (_a) {
        var order_id = _a.order_id, items = _a.items;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, instance.put(config.apiService + "/orders/".concat(order_id), items, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Booking.prototype.getProcedures = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.get(config.apiService + '/procedures/', {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.getProceduresByOfficeId = function (office_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.get(config.apiService + "/offices/".concat(office_id, "/procedures"), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.getEvents = function (limit, skip, exclude_statuses, number) {
        return __awaiter(this, void 0, void 0, function () {
            var excludeStatusesStr;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        excludeStatusesStr = '';
                        if (exclude_statuses === null || exclude_statuses === void 0 ? void 0 : exclude_statuses.length) {
                            exclude_statuses.forEach(function (status) {
                                if (!exclude_statuses.length) {
                                    excludeStatusesStr += "exclude_statuses=".concat(status);
                                }
                                else {
                                    excludeStatusesStr += "&exclude_statuses=".concat(status);
                                }
                            });
                        }
                        return [4 /*yield*/, instance.get(config.apiService + "/orders/self?".concat(excludeStatusesStr), {
                                headers: {
                                    isAccessToken: true,
                                },
                                params: __assign(__assign(__assign({}, (limit > -1 && { limit: limit })), (skip > -1 && { skip: skip })), { timezone: moment.tz.guess(), number: number }),
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.getProceduresTotalInfo = function (date) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + "/orders/self/total", { previous_visit: date }, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.getEventById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.get(config.apiService + "/orders/".concat(id), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.getOrderById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.get(config.apiService + "/orders/".concat(id, "/extended"), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.getOrderStepsById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.get(config.apiService + "/orders/".concat(id, "/steps"), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.createBooking = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + '/orders/', payload, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.updateOrderProcedures = function (_a) {
        var order_id = _a.order_id, data = _a.data;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, instance.put(config.apiService + "/orders/".concat(order_id), data, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Booking.prototype.submitOrder = function (order_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + "/orders/".concat(order_id, "/submit"), {}, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Booking.prototype.deleteOrder = function (_a) {
        var order_id = _a.order_id;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, instance.delete(config.apiService + "/orders/".concat(order_id), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Booking.prototype.validateInsuranceById = function (person_id, document_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + "/persons/".concat(person_id, "/insurances/").concat(document_id, "/verify"), {}, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.getBlockedProcedures = function (person_id) {
        return __awaiter(this, void 0, void 0, function () {
            var name;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        name = moment.tz.guess() || 'Etc/UTC';
                        return [4 /*yield*/, instance.get(config.apiService + "/appointments/procedures/blocked", {
                                headers: {
                                    isAccessToken: true,
                                },
                                params: __assign({ timezone: moment.tz.zone(name).name }, (person_id && { person_id: person_id })),
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.deletePrescription = function (order_id, record_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.delete(config.apiService + "/orders/".concat(order_id, "/prescriptions/").concat(record_id), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.startUploadPrescription = function (order_id, person_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + "/orders/".concat(order_id, "/prescriptions"), {
                            person_id: person_id,
                        }, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.confirmUploadPrescription = function (order_id, record_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + "/orders/".concat(order_id, "/prescriptions/").concat(record_id, "/confirm"), {}, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.managePrescriptionInOrder = function (order_id, prescription_type) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.put(config.apiService + "/orders/".concat(order_id, "/prescription"), { prescription_type: prescription_type }, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.managePatientInOrder = function (order_id, person_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + "/orders/".concat(order_id, "/patient"), { person_id: person_id }, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.managePaymentInOrder = function (orderId, type, param) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post(config.apiService + "/orders/".concat(orderId, "/payment"), __assign({ type: type }, (type === 'insurance'
                            ? { insurances: param ? [param] : [] }
                            : { payment_source_id: param })), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Booking.prototype.getPatientInsurances = function (personId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.get(config.apiService + "/persons/".concat(personId, "/insurances"), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return Booking;
}());
var bookingService = new Booking();
export default bookingService;
