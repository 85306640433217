var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ImagesMessage } from '@core/components/surveyMessages/imagesMessage/imagesMessage';
import { TextMessage } from '@core/components/surveyMessages/textMessage';
import MultipleMessageItem from '@core/components/surveyMessages/textMessage/multipleMessageItem';
import DocApproved from '@core/shared/svg/docApproved';
import { getChildren, getContent, getCurrentSlot, getSelectContentByValue, hasTag, MESSAGE_ICONS, } from '@core/shared/utils/surveyUtils';
var mapStateToProps = function (_a) {
    var loading = _a.loading;
    return ({ loading: loading });
};
export var AnsweredQuestion = function (_a) {
    var _b;
    var self = _a.self, children = _a.children, answer = _a.answer, handleEdit = _a.handleEdit, disableEdit = _a.disableEdit;
    var loading = useSelector(mapStateToProps).loading;
    var _c = useState(false), isMedia = _c[0], setIsMedia = _c[1];
    var handleMediaSlot = function () { return setIsMedia(true); };
    var info = useMemo(function () {
        if (self.subvariation === 'info') {
            return getChildren({
                id: self._id,
                data: children,
                variations: ['info', 'content'],
            });
        }
    }, [children, self._id, self.subvariation]);
    if (self.subvariation === 'info') {
        var documents = info.filter(function (infoItem) { return infoItem.subvariation === 'document'; });
        var infoQuestions = info.filter(function (infoItem) { return infoItem.subvariation === 'info_question'; });
        if (documents.length) {
            return (_jsxs(_Fragment, { children: [_jsx(TextMessage, __assign({ type: "outcome" }, { children: documents.map(function (doc) {
                            var content = getContent({ id: doc._id, data: info });
                            return (_jsx(MultipleMessageItem, { nativeIconStyle: true, icon: _jsx(DocApproved, {}), label: content === null || content === void 0 ? void 0 : content.title }, doc._id));
                        }) })), !!infoQuestions.length &&
                        infoQuestions.map(function (q) { return _jsx(TextMessage, { label: q.title, type: "income" }, q._id); })] }));
        }
        var infoElements_1 = [];
        info.map(function (item) {
            var warning = hasTag(item, 'warningMessage');
            var iconKey = Object.keys(MESSAGE_ICONS).find(function (key) { return hasTag(item, key); });
            if (item.subvariation === 'info_question') {
                infoElements_1.push(_jsx(TextMessage, { topIcon: iconKey && MESSAGE_ICONS[iconKey], isWarningMessage: warning, label: item.title, type: "income" }, item._id));
            }
            if (item.variation === 'info') {
                var content = getContent({ id: item._id, data: info });
                if (content.subvariation === 'text_info') {
                    infoElements_1.push(_jsx(TextMessage, { topIcon: iconKey && MESSAGE_ICONS[iconKey], isWarningMessage: warning, label: content.text, type: "income" }, item._id + content._id));
                }
            }
            if (item.subvariation === 'text') {
                if (!!answer && !!answer.values && !answer.values.length && answer.button_id) {
                    var content = getContent({ id: answer.button_id, data: children });
                    content &&
                        infoElements_1.push(_jsx(TextMessage, { topIcon: iconKey && MESSAGE_ICONS[iconKey], isWarningMessage: warning, editable: true, label: content.label, handleEdit: handleEdit, type: "outcome" }, item._id + content._id));
                }
            }
        });
        return _jsx(_Fragment, { children: infoElements_1 });
    }
    var questionContent = getContent({ id: self._id, data: children });
    return (_jsxs("div", { children: [!!questionContent && _jsx(TextMessage, { label: questionContent.title, type: "income" }), !answer.values.length && !!answer.button_id && (_jsx(TextMessage, { editable: true, disableEdit: disableEdit, nonClickable: loading.isLoading, handleEdit: handleEdit, label: (_b = getContent({
                    id: answer.button_id,
                    data: children,
                })) === null || _b === void 0 ? void 0 : _b.label, type: "outcome" })), self.subvariation === 'switcher' &&
                answer.values.map(function (value) { return (_jsx(TextMessage, { editable: true, disableEdit: disableEdit, nonClickable: loading.isLoading, handleEdit: handleEdit, label: getContent({
                        id: value.slot_id,
                        data: children,
                    }).label, type: "outcome" }, value.slot_id)); }), (self.subvariation === 'multi' || self.subvariation === 'single') && (_jsx(TextMessage, __assign({ type: "outcome", editable: true, disableEdit: disableEdit, nonClickable: loading.isLoading, handleEdit: handleEdit }, { children: answer.values
                    .map(function (value, index) {
                    var _a;
                    var content = getContent({
                        id: value.slot_id,
                        data: children,
                    });
                    if (!content) {
                        return _jsx("div", {}, index);
                    }
                    if (content.subvariation === 'autocomplete_slot') {
                        return (_jsxs("p", { children: [content.label, ": ", (_a = value === null || value === void 0 ? void 0 : value.value) === null || _a === void 0 ? void 0 : _a.name] }, index));
                    }
                    if (content.subvariation === 'input_slot') {
                        return (_jsxs("p", { children: [content.label, ": ", value.value] }, index));
                    }
                    if (content.subvariation === 'date_slot') {
                        return (_jsxs("p", { children: [content.label, ": ", moment(value.value).format('MM-DD-YYYY')] }, index));
                    }
                    if (content.subvariation === 'select_slot') {
                        var slotContent = getSelectContentByValue({
                            value: value.value,
                            data: children,
                        });
                        return (_jsxs("p", { children: [content.label, ": ", slotContent.text] }, index));
                    }
                    if (content.subvariation === 'option_slot') {
                        return _jsx("p", { children: content.label }, index);
                    }
                    return null;
                })
                    .filter(Boolean) }))), _jsx("div", __assign({ style: isMedia ? { display: 'flex', justifyContent: 'flex-end' } : {} }, { children: answer.values
                    .map(function (item) {
                    return __assign(__assign({}, item), { order: getCurrentSlot({
                            id: item.slot_id,
                            data: children,
                        }).order });
                })
                    .sort(function (a, b) {
                    return a.order - b.order;
                })
                    .map(function (value, index) {
                    var content = getContent({
                        id: value.slot_id,
                        data: children,
                    });
                    if ((content === null || content === void 0 ? void 0 : content.subvariation) === 'media_slot') {
                        return (_jsx(ImagesMessage, { value: value, disableEdit: disableEdit, nonClickable: loading.isLoading, handleEdit: handleEdit, handleMediaSlot: handleMediaSlot, editable: index === 0 }, content._id));
                    }
                    return null;
                }) }))] }));
};
