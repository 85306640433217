var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { config } from '@core/config/config';
import { instance } from '@core/services/axios';
import { SID } from '@core/shared/models/survey';
var Surveys = /** @class */ (function () {
    function Surveys() {
    }
    Surveys.prototype.getInstance = function (instance_sid, payload, sid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/instance/".concat(instance_sid), __assign({}, payload), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Surveys.prototype.getSurveyById = function (id, sid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.get((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/".concat(id), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Surveys.prototype.createSurveyId = function (type, params) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post((type === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            '/surveys/sid', __assign({ instance_sid: type }, (params && { params: params })), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    Surveys.prototype.getSurveyFull = function (id, sid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.get((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/".concat(id, "/full-url"), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Surveys.prototype.postSurveyAnswer = function (id, step_id, values, button_id, sid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/".concat(id, "/answers/"), __assign({ step_id: step_id, values: values }, (button_id && { button_id: button_id })), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Surveys.prototype.putSurveyAnswer = function (id, step_id, values, button_id, sid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.patch((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/".concat(id, "/answers/"), __assign({ step_id: step_id, values: values }, (button_id && { button_id: button_id })), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Surveys.prototype.uploadMediaData = function (surveyId, formData, url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(url, {
                            method: 'POST',
                            body: formData,
                            mode: 'no-cors',
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Surveys.prototype.confirmUploadMedia = function (surveyId, sid) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/".concat(surveyId, "/answers/confirm-upload"), {}, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    Surveys.prototype.cancelUploadMedia = function (surveyId, sid) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/".concat(surveyId, "/answers/cancel-upload"), {}, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    Surveys.prototype.getMedia = function (surveyId, params, sid) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.get((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/".concat(surveyId, "/media"), __assign({ headers: {
                                isAccessToken: true,
                            } }, (params && { params: params })))];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    Surveys.prototype.deleteEntity = function (_a, sid) {
        var surveyId = _a.surveyId, index = _a.index, baseStepId = _a.baseStepId;
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, instance.delete((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/".concat(surveyId, "/answers/delete-entity/").concat(baseStepId, "/").concat(index), {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1:
                        res = _b.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    Surveys.prototype.deleteSurvey = function (instance_sid) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.delete((instance_sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            '/surveys/instance/' +
                            instance_sid, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    Surveys.prototype.completeSurvey = function (surveyId, sid) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, instance.post((sid === SID.PRESCRIPTION ? config.prescriptionService : config.formsService) +
                            "/surveys/".concat(surveyId, "/finish"), {}, {
                            headers: {
                                isAccessToken: true,
                            },
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res];
                }
            });
        });
    };
    Surveys.prototype.getSurveyBranches = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return Surveys;
}());
var service = new Surveys();
export default service;
