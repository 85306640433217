var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { t } from 'i18next';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { analytics } from '@core/analytics/zipanalytics';
import { showModal } from '@core/components/modals';
import { ReactivateAccount } from '@core/components/modals/reactivateAccount';
import authService from '@core/services/auth';
import componentService from '@core/services/componentsService';
import { navigate } from '@core/services/navigator';
import profileService from '@core/services/prfofile';
import sessionService from '@core/services/session';
import subscriptionService from '@core/services/subscription';
import { checkIsProfileLocked } from '@core/shared/utils/profileUtils';
import { changeDataKeysCase } from '@core/shared/utils/stringUtils';
import { setAlert } from '@core/store/reducers/alertSlice';
import { setPerson } from '@core/store/reducers/bookingSlice';
import { addError } from '@core/store/reducers/errorsSlice';
import { addAccountReq, deleteAccountReq, deletePatient, deletePatientError, deletePatientSuccess, deletePersonSelf, getAlertsCount, getAlertsCountFail, getAlertsCountSuccess, getAvatar, getAvatarFail, getAvatarSuccess, getData, getFamily, getFamilyByPersonId, getFamilyWithoutLoader, getInfo, getInfoAll, getRecommendations, getRecommendationsFail, getRecommendationsSuccess, getSelfType, getSubscriptionFail, getSubscriptions, getSubscriptionsSuccess, removeAvatar, removeAvatarFail, removeAvatarSuccess, setDeleteId, setDeletePerson, setDeleteType, setFamily, setFamilyFailed, setInfo, setLoading, setPassPosition, setSelfType, statusesReset, updateData, updateInfo, updateInfoError, updateInfoSuccess, updatePasswordReq, updatePatient, updatePatientError, updatePatientSuccess, uploadAvatar, uploadAvatarFail, uploadAvatarSuccess, } from '@core/store/reducers/profileSlice';
import { addCodeInfo } from '@core/store/reducers/verificationSlice';
import { handlerErrors, replaceErrors, STOP_MESSAGE, } from '@core/store/sagas/errors';
export function getInfoAllAsync(_a) {
    var e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, 4, 7]);
                return [4 /*yield*/, call(getDataAsync)];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(getInfoAsync)];
            case 2:
                _b.sent();
                return [3 /*break*/, 7];
            case 3:
                e_1 = _b.sent();
                return [3 /*break*/, 7];
            case 4: return [4 /*yield*/, call(payload.callback)];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(statusesReset())];
            case 6:
                _b.sent();
                return [7 /*endfinally*/];
            case 7: return [2 /*return*/];
        }
    });
}
export function getDataAsync() {
    var data_1, users, e_2;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 6, , 7]);
                return [4 /*yield*/, call(authService.getMe)];
            case 1:
                data_1 = (_b.sent()).data;
                if (!((_a = data_1 === null || data_1 === void 0 ? void 0 : data_1.roles) === null || _a === void 0 ? void 0 : _a.includes('mergeable'))) return [3 /*break*/, 4];
                return [4 /*yield*/, navigate('/')];
            case 2:
                _b.sent();
                return [4 /*yield*/, showModal(ReactivateAccount, {
                        showCloseIcon: false,
                        closeBackDrop: false,
                        isBottomMobile: true,
                        callback: function () {
                            navigate('/account-merge/', { replace: true, state: { survey: true } });
                            return false;
                        },
                    })];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                users = data_1.accounts.map(function (account) {
                    if (data_1.account_id === account.id) {
                        return __assign(__assign({}, account), { deleting: false });
                    }
                    return __assign(__assign({}, account), { deleting: true });
                });
                return [4 /*yield*/, put(updateData({ accounts: users, roles: data_1.roles }))];
            case 5:
                _b.sent();
                return [2 /*return*/, data_1];
            case 6:
                e_2 = _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function addAccountAsync(_a) {
    var data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(authService.makeAccount, payload.type, payload.identifier, payload.verification)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(addCodeInfo({
                        verification_id: data.verification_id,
                        created: data.created,
                        last_sent_time: data.last_sent_time,
                        expiration_timeout: data.expiration_timeout,
                        resend_timeout: data.resend_timeout,
                    }))];
            case 2:
                _b.sent();
                if (!payload.callback) return [3 /*break*/, 4];
                return [4 /*yield*/, call(payload.callback)];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
export function checkAddAccountAsync(_a) {
    var response, replaceMap, replaceMap;
    var e = _a.e;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                response = e.response;
                if (!(response.status === 409)) return [3 /*break*/, 3];
                replaceMap = [
                    {
                        loc: ['body', 'identifier'],
                        payload: {
                            type: 'email',
                        },
                        map: '*',
                        replace: 'error_email_already_exist',
                        action: 'addError',
                    },
                    {
                        loc: ['body', 'identifier'],
                        payload: {
                            type: 'phone',
                        },
                        map: '*',
                        replace: 'error_phone_already_exist',
                        action: 'addError',
                    },
                ];
                return [4 /*yield*/, replaceErrors(replaceMap)];
            case 1:
                _b.sent();
                return [4 /*yield*/, STOP_MESSAGE];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3:
                if (!(response.status === 422)) return [3 /*break*/, 6];
                replaceMap = [
                    {
                        loc: ['body', 'identifier'],
                        payload: {
                            type: 'phone',
                        },
                        map: ['value_error'],
                        replace: 'error_phone_incorrect',
                        action: 'addError',
                    },
                    {
                        loc: ['body', 'identifier'],
                        payload: {
                            type: 'email',
                        },
                        map: ['value_error.emailsyntax', 'value_error.emailundeliverable'],
                        replace: 'error_email_incorrect',
                        action: 'addError',
                    },
                ];
                return [4 /*yield*/, replaceErrors(replaceMap)];
            case 4:
                _b.sent();
                return [4 /*yield*/, STOP_MESSAGE];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}
export function deleteAccountAsync(_a) {
    var params, data;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                params = __assign({}, (payload.verification && { verification: payload.verification }));
                return [4 /*yield*/, call(authService.deleteAccount, payload.id, params)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(addCodeInfo({
                        verification_id: data.verification_id,
                        created: data.created,
                        last_sent_time: data.last_sent_time,
                        expiration_timeout: data.expiration_timeout,
                        resend_timeout: data.resend_timeout,
                    }))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(setDeleteType(payload.type))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(setDeleteId(payload.id))];
            case 4:
                _b.sent();
                if (!payload.callback) return [3 /*break*/, 6];
                return [4 /*yield*/, call(payload.callback)];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}
export function updatePasswordAsync(_a) {
    var data;
    var _b, _c, _d, _e;
    var payload = _a.payload;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, authService.updatePassword(payload.newPass, (_b = payload === null || payload === void 0 ? void 0 : payload.params) === null || _b === void 0 ? void 0 : _b.verification)];
            case 1:
                data = (_f.sent()).data;
                return [4 /*yield*/, put(addCodeInfo({
                        verification_id: data.verification_id,
                        created: data.created,
                        last_sent_time: data.last_sent_time,
                        expiration_timeout: data.expiration_timeout,
                        resend_timeout: data.resend_timeout,
                    }))];
            case 2:
                _f.sent();
                if (!((_c = payload === null || payload === void 0 ? void 0 : payload.params) === null || _c === void 0 ? void 0 : _c.callback)) return [3 /*break*/, 4];
                return [4 /*yield*/, call((_d = payload === null || payload === void 0 ? void 0 : payload.params) === null || _d === void 0 ? void 0 : _d.callback)];
            case 3:
                _f.sent();
                _f.label = 4;
            case 4:
                if (!((_e = payload === null || payload === void 0 ? void 0 : payload.params) === null || _e === void 0 ? void 0 : _e.nextPosition)) return [3 /*break*/, 9];
                return [4 /*yield*/, put(setPassPosition(payload.params.nextPosition))];
            case 5:
                _f.sent();
                if (!(payload.params.nextPosition === 'code')) return [3 /*break*/, 7];
                return [4 /*yield*/, put(setAlert('We sent you a temporary code.'))];
            case 6:
                _f.sent();
                return [3 /*break*/, 9];
            case 7:
                if (!(payload.params.nextPosition === 'callCode')) return [3 /*break*/, 9];
                return [4 /*yield*/, put(setAlert(t('label_call')))];
            case 8:
                _f.sent();
                _f.label = 9;
            case 9: return [2 /*return*/];
        }
    });
}
export function checkUpdaatePasswordAsync(_a) {
    var error, errorType, _b;
    var _c, _d, _e;
    var e = _a.e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                error = e;
                errorType = (_e = (_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.detail) === null || _e === void 0 ? void 0 : _e.type;
                if (!errorType) return [3 /*break*/, 6];
                _b = errorType;
                switch (_b) {
                    case 'value_error.identicalpassword': return [3 /*break*/, 1];
                }
                return [3 /*break*/, 3];
            case 1: return [4 /*yield*/, put(addError({ type: 'password', error: 'error_password_identical' }))];
            case 2:
                _f.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, put(addError({ type: 'password', error: 'error_auth_password_requirements' }))];
            case 4:
                _f.sent();
                _f.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6: return [4 /*yield*/, put(addError({ type: 'password', error: 'error_auth_password_requirements' }))];
            case 7:
                _f.sent();
                _f.label = 8;
            case 8: return [4 /*yield*/, put(setAlert('Something wrong.'))];
            case 9:
                _f.sent();
                return [2 /*return*/];
        }
    });
}
export function getInfoAsync(action) {
    var data;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, call(profileService.getSelf)];
            case 1:
                data = (_c.sent()).data;
                return [4 /*yield*/, put(setPerson(data))];
            case 2:
                _c.sent();
                return [4 /*yield*/, call(getSelfTypeAsync)];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(getSubscriptions(data.user_id))];
            case 4:
                _c.sent();
                return [4 /*yield*/, put(getRecommendations({ id: data._id, callback: function () { } }))];
            case 5:
                _c.sent();
                return [4 /*yield*/, put(getAlertsCount())];
            case 6:
                _c.sent();
                return [4 /*yield*/, put(setInfo({
                        id: data.user_id,
                        person: {
                            id: data._id,
                            firstName: data.name.given[0],
                            lastName: data.name.family,
                            middleName: ((_b = (_a = data === null || data === void 0 ? void 0 : data.name) === null || _a === void 0 ? void 0 : _a.given) === null || _b === void 0 ? void 0 : _b[1]) || '',
                            birthday: data.birth_date,
                            gender: data.gender,
                            mrn: data.mrn,
                            roles: data.roles,
                            flags: data.flags,
                            gender_identity: data.gender_identity,
                            sexual_orientation: data.sexual_orientation,
                            race: data.race,
                            ethnic_origin: data.ethnic_origin,
                            avatars: data.avatars,
                            active_subscriptions: data.active_subscriptions,
                        },
                        documents: data.documents,
                    }))];
            case 7:
                _c.sent();
                analytics.startSession(data.user_id, {
                    id: data._id,
                    role: Array.isArray(data.roles) ? data.roles[0] : 'na',
                });
                if (!(action === null || action === void 0 ? void 0 : action.payload)) return [3 /*break*/, 9];
                return [4 /*yield*/, call(action.payload)];
            case 8:
                _c.sent();
                _c.label = 9;
            case 9: return [2 /*return*/];
        }
    });
}
export function updateInfoAsync(_a) {
    var self, isProfileLocked, _b, firstName, middleName, lastName, birthday, gender, race, sexual_orientation, ethnic_origin, gender_identity, dataForSending, e_3, detail, errors;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 7, , 15]);
                return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                self = (_c.sent()).profile.self;
                isProfileLocked = checkIsProfileLocked(self);
                _b = payload.person, firstName = _b.firstName, middleName = _b.middleName, lastName = _b.lastName, birthday = _b.birthday, gender = _b.gender, race = _b.race, sexual_orientation = _b.sexual_orientation, ethnic_origin = _b.ethnic_origin, gender_identity = _b.gender_identity;
                dataForSending = {
                    ethnic_origin: ethnic_origin,
                    race: race,
                    sexual_orientation: sexual_orientation,
                    gender_identity: gender_identity,
                };
                if (!isProfileLocked) {
                    dataForSending.name = {
                        family: lastName,
                        given: middleName ? [firstName, middleName] : [firstName],
                    };
                    dataForSending.birth_date = birthday;
                    dataForSending.gender = gender;
                }
                return [4 /*yield*/, call(profileService.updateSelf, dataForSending)];
            case 2:
                _c.sent();
                if (!payload.callback) return [3 /*break*/, 4];
                return [4 /*yield*/, call(payload.callback)];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4: return [4 /*yield*/, put(updateInfoSuccess())];
            case 5:
                _c.sent();
                return [4 /*yield*/, put(setAlert('Profile Updated'))];
            case 6:
                _c.sent();
                return [3 /*break*/, 15];
            case 7:
                e_3 = _c.sent();
                if (!axios.isAxiosError(e_3)) return [3 /*break*/, 10];
                detail = e_3.response.data.detail;
                errors = detail.map(function (d) { return ({
                    field: d === null || d === void 0 ? void 0 : d.loc[1],
                    type: d === null || d === void 0 ? void 0 : d.type,
                    message: d === null || d === void 0 ? void 0 : d.msg,
                }); });
                if (!(errors.length > 0)) return [3 /*break*/, 9];
                return [4 /*yield*/, put(addError({ type: 'updatePatient', error: errors }))];
            case 8:
                _c.sent();
                _c.label = 9;
            case 9: return [3 /*break*/, 12];
            case 10: return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 11:
                _c.sent();
                _c.label = 12;
            case 12: return [4 /*yield*/, put(updateInfoError())];
            case 13:
                _c.sent();
                return [4 /*yield*/, put(setLoading(false))];
            case 14:
                _c.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
export function getFamilyAsync(action) {
    var family, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, 8, 10]);
                return [4 /*yield*/, put(setLoading(true))];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(profileService.getFamily)];
            case 2:
                family = (_a.sent()).data;
                return [4 /*yield*/, put(setFamily(family))];
            case 3:
                _a.sent();
                if (!action.payload) return [3 /*break*/, 5];
                return [4 /*yield*/, call(action.payload)];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 10];
            case 6:
                e_4 = _a.sent();
                return [4 /*yield*/, put(setFamilyFailed())];
            case 7:
                _a.sent();
                throw e_4;
            case 8:
                put(setAlert(t('error_unknown')));
                return [4 /*yield*/, put(setLoading(false))];
            case 9:
                _a.sent();
                return [7 /*endfinally*/];
            case 10: return [2 /*return*/];
        }
    });
}
export function getFamilyByPersonIdAsync() {
    var _id, family, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(function (s) { return s; })];
            case 1:
                _id = (_a.sent()).booking.person._id;
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, 8, 10]);
                return [4 /*yield*/, put(setLoading(true))];
            case 3:
                _a.sent();
                return [4 /*yield*/, call(profileService.getFamilyByPersonId, _id)];
            case 4:
                family = (_a.sent()).data;
                return [4 /*yield*/, put(setFamily(family))];
            case 5:
                _a.sent();
                return [3 /*break*/, 10];
            case 6:
                e_5 = _a.sent();
                return [4 /*yield*/, put(setFamilyFailed())];
            case 7:
                _a.sent();
                throw e_5;
            case 8:
                put(setAlert(t('error_unknown')));
                return [4 /*yield*/, put(setLoading(false))];
            case 9:
                _a.sent();
                return [7 /*endfinally*/];
            case 10: return [2 /*return*/];
        }
    });
}
export function deletePersonSelfAsync(_a) {
    var data_2, accounts, currentAccount, code, e_6;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 8, , 10]);
                return [4 /*yield*/, call(authService.getMe)];
            case 1:
                data_2 = (_b.sent()).data;
                return [4 /*yield*/, select(function (s) { return s; })];
            case 2:
                accounts = (_b.sent()).profile.accounts;
                currentAccount = accounts.find(function (e) { return e.id === data_2.account_id; });
                return [4 /*yield*/, put(setDeletePerson({
                        type: currentAccount.type,
                        identifier: currentAccount.identifier,
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(authService.deleteSelf, 'self', payload.call)];
            case 4:
                code = _b.sent();
                return [4 /*yield*/, put(addCodeInfo({
                        verification_id: code.data.verification_id,
                        created: code.data.created,
                        last_sent_time: code.data.last_sent_time,
                        expiration_timeout: code.data.expiration_timeout,
                        resend_timeout: code.data.resend_timeout,
                    }))];
            case 5:
                _b.sent();
                if (!payload.callback) return [3 /*break*/, 7];
                return [4 /*yield*/, call(payload.callback)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                e_6 = _b.sent();
                return [4 /*yield*/, put(setAlert(t('error_deleting_account')))];
            case 9:
                _b.sent();
                throw e_6;
            case 10: return [2 /*return*/];
        }
    });
}
export function getSelfTypeAsync() {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(sessionService.getSelfAccesses)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(setSelfType(!!(data === null || data === void 0 ? void 0 : data.length)))];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function deletePatientAsync(_a) {
    var e_7, errorText, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, 10, 12]);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return [4 /*yield*/, call(profileService.deletePatient, payload)];
            case 1:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                _b.sent();
                return [4 /*yield*/, put(deletePatientSuccess())];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(getFamilyWithoutLoader())];
            case 3:
                _b.sent();
                return [3 /*break*/, 12];
            case 4:
                e_7 = _b.sent();
                return [4 /*yield*/, put(deletePatientError())];
            case 5:
                _b.sent();
                if (!axios.isAxiosError(e_7)) return [3 /*break*/, 7];
                errorText = t('error_unknown');
                error = e_7.response.data.error;
                if (error === 'person_deletion_error') {
                    errorText = t('error_person_deletion');
                }
                return [4 /*yield*/, put(setAlert(errorText))];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9: return [3 /*break*/, 12];
            case 10: return [4 /*yield*/, put(setLoading(false))];
            case 11:
                _b.sent();
                return [7 /*endfinally*/];
            case 12: return [2 /*return*/];
        }
    });
}
export function updatePatientAsync(_a) {
    var isSupervisorApp, self, isProfileLocked, _b, id, gender_identity, ethnic_origin, race, sexual_orientation, birthday, gender, lastName, middleName, firstName, dataForSending, anyMiddleName, e_8, detail, errors;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                isSupervisorApp = !!payload.isSupervisorApp === true;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 10, 17, 19]);
                return [4 /*yield*/, select(function (s) { return s; })];
            case 2:
                self = (_c.sent()).profile.self;
                isProfileLocked = isSupervisorApp ? null : checkIsProfileLocked(payload.person);
                _b = payload.person, id = _b.id, gender_identity = _b.gender_identity, ethnic_origin = _b.ethnic_origin, race = _b.race, sexual_orientation = _b.sexual_orientation, birthday = _b.birthday, gender = _b.gender, lastName = _b.lastName, middleName = _b.middleName, firstName = _b.firstName;
                dataForSending = void 0;
                dataForSending = {
                    ethnic_origin: ethnic_origin,
                    race: race,
                    sexual_orientation: sexual_orientation,
                    gender_identity: gender_identity,
                };
                if (!isProfileLocked) {
                    dataForSending.name = {
                        family: lastName,
                        given: middleName ? [firstName, middleName] : [firstName],
                    };
                    dataForSending.birth_date = birthday;
                    dataForSending.gender = gender;
                }
                if (isSupervisorApp) {
                    anyMiddleName = middleName || self.middleName;
                    dataForSending = {
                        name: {
                            family: lastName,
                            given: anyMiddleName ? [firstName, anyMiddleName] : [firstName],
                        },
                        // existing fields from profile:
                        birth_date: self.birthday,
                        gender: self.gender,
                        race: self.race,
                        gender_identity: self.gender_identity,
                        ethnic_origin: self.ethnic_origin,
                        sexual_orientation: self.sexual_orientation,
                    };
                }
                return [4 /*yield*/, call(profileService.updatePatient, id, dataForSending)];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(updatePatientSuccess())];
            case 4:
                _c.sent();
                if (!isSupervisorApp) return [3 /*break*/, 6];
                return [4 /*yield*/, put(getInfo())];
            case 5:
                _c.sent();
                return [3 /*break*/, 8];
            case 6: return [4 /*yield*/, put(getFamilyWithoutLoader())];
            case 7:
                _c.sent();
                _c.label = 8;
            case 8: return [4 /*yield*/, put(setAlert('Profile Updated'))];
            case 9:
                _c.sent();
                return [3 /*break*/, 19];
            case 10:
                e_8 = _c.sent();
                return [4 /*yield*/, put(updatePatientError())];
            case 11:
                _c.sent();
                if (!axios.isAxiosError(e_8)) return [3 /*break*/, 14];
                detail = e_8.response.data.detail;
                errors = detail.map(function (d) { return ({
                    field: d === null || d === void 0 ? void 0 : d.loc[1],
                    type: d === null || d === void 0 ? void 0 : d.type,
                    message: d === null || d === void 0 ? void 0 : d.msg,
                }); });
                if (!(errors.length > 0)) return [3 /*break*/, 13];
                return [4 /*yield*/, put(addError({ type: 'updatePatient', error: errors }))];
            case 12:
                _c.sent();
                _c.label = 13;
            case 13: return [3 /*break*/, 16];
            case 14: return [4 /*yield*/, put(setAlert(t('error_unknown')))];
            case 15:
                _c.sent();
                _c.label = 16;
            case 16: return [3 /*break*/, 19];
            case 17: return [4 /*yield*/, put(setLoading(false))];
            case 18:
                _c.sent();
                return [7 /*endfinally*/];
            case 19: return [2 /*return*/];
        }
    });
}
export function getAvatarAsync(_a) {
    var avatars, data, e_9, error, currentError;
    var _b, _c;
    var _d = _a.payload, patientId = _d.patientId, callback = _d.callback, forceReload = _d.forceReload;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 6, , 10]);
                return [4 /*yield*/, select(function (state) { return state.profile.avatars; })];
            case 1:
                avatars = _e.sent();
                if (!(avatars[patientId] && callback && !forceReload)) return [3 /*break*/, 3];
                callback(avatars[patientId]);
                return [4 /*yield*/, put(getAvatarSuccess({ patientId: patientId, data: avatars[patientId] }))];
            case 2:
                _e.sent();
                return [2 /*return*/];
            case 3: return [4 /*yield*/, call(profileService.getAvatar, patientId)];
            case 4:
                data = (_e.sent()).data;
                return [4 /*yield*/, put(getAvatarSuccess({ patientId: patientId, data: data }))];
            case 5:
                _e.sent();
                if (callback) {
                    callback(data);
                }
                return [3 /*break*/, 10];
            case 6:
                e_9 = _e.sent();
                error = e_9;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.detail;
                if (!currentError) return [3 /*break*/, 8];
                return [4 /*yield*/, put(setAlert(currentError))];
            case 7:
                _e.sent();
                _e.label = 8;
            case 8: return [4 /*yield*/, put(getAvatarFail())];
            case 9:
                _e.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function uploadAvatarAsync(_a) {
    var data, typedData, formData, key, e_10, error, currentError;
    var _b, _c;
    var _d = _a.payload, patientId = _d.patientId, media = _d.media, file = _d.file;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 6, , 9]);
                return [4 /*yield*/, call(profileService.uploadAvatar, patientId)];
            case 1:
                data = (_e.sent()).data;
                typedData = changeDataKeysCase(data, 'camelCase');
                formData = new FormData();
                for (key in data.fields) {
                    formData.append(key, data.fields[key]);
                }
                formData.append('file', file);
                if (!(typedData.url && typedData.id)) return [3 /*break*/, 5];
                return [4 /*yield*/, call(profileService.uploadMediaData, formData, typedData.url)];
            case 2:
                _e.sent();
                return [4 /*yield*/, call(profileService.confirmAvatar, patientId)];
            case 3:
                _e.sent();
                return [4 /*yield*/, put(uploadAvatarSuccess({
                        patientId: patientId,
                        data: {
                            record_id: typedData.id,
                            url: media.url,
                            confirmed: true,
                        },
                    }))];
            case 4:
                _e.sent();
                _e.label = 5;
            case 5: return [3 /*break*/, 9];
            case 6:
                e_10 = _e.sent();
                error = e_10;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.detail;
                return [4 /*yield*/, put(setAlert(currentError))];
            case 7:
                _e.sent();
                return [4 /*yield*/, put(uploadAvatarFail())];
            case 8:
                _e.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function removeAvatarAsync(_a) {
    var e_11, error, currentError;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 3, , 7]);
                return [4 /*yield*/, call(profileService.removeAvatar, payload)];
            case 1:
                _d.sent();
                return [4 /*yield*/, put(removeAvatarSuccess(payload))];
            case 2:
                _d.sent();
                return [3 /*break*/, 7];
            case 3:
                e_11 = _d.sent();
                error = e_11;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.detail;
                if (!currentError) return [3 /*break*/, 5];
                return [4 /*yield*/, put(setAlert(currentError))];
            case 4:
                _d.sent();
                _d.label = 5;
            case 5: return [4 /*yield*/, put(removeAvatarFail())];
            case 6:
                _d.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function getSubscriptionsAsync(_a) {
    var data, e_12, error, currentError;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 3, , 7]);
                return [4 /*yield*/, call(subscriptionService.getSubscriptionByUserId, payload)];
            case 1:
                data = (_d.sent()).data;
                return [4 /*yield*/, put(getSubscriptionsSuccess(data))];
            case 2:
                _d.sent();
                return [3 /*break*/, 7];
            case 3:
                e_12 = _d.sent();
                error = e_12;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.detail;
                if (!currentError) return [3 /*break*/, 5];
                return [4 /*yield*/, put(setAlert(currentError))];
            case 4:
                _d.sent();
                _d.label = 5;
            case 5: return [4 /*yield*/, put(getSubscriptionFail())];
            case 6:
                _d.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function getRecommendationsAsync(_a) {
    var data, e_13, error, currentError;
    var _b, _c;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 4, , 8]);
                return [4 /*yield*/, call(subscriptionService.getRecommendations, payload.id)];
            case 1:
                data = (_d.sent()).data;
                return [4 /*yield*/, put(getRecommendationsSuccess(data))];
            case 2:
                _d.sent();
                return [4 /*yield*/, call(payload.callback)];
            case 3:
                _d.sent();
                return [3 /*break*/, 8];
            case 4:
                e_13 = _d.sent();
                error = e_13;
                currentError = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.detail;
                if (!currentError) return [3 /*break*/, 6];
                return [4 /*yield*/, put(setAlert(currentError))];
            case 5:
                _d.sent();
                _d.label = 6;
            case 6: return [4 /*yield*/, put(getRecommendationsFail())];
            case 7:
                _d.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function getAlertsCountAsync() {
    var data, e_14, error, currentError;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(componentService.getAlertsCount)];
            case 1:
                data = (_c.sent()).data;
                return [4 /*yield*/, put(getAlertsCountSuccess(data))];
            case 2:
                _c.sent();
                return [3 /*break*/, 5];
            case 3:
                e_14 = _c.sent();
                error = e_14;
                currentError = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.detail;
                if (currentError) {
                    // yield put(setAlert(currentError))
                }
                return [4 /*yield*/, put(getAlertsCountFail())];
            case 4:
                _c.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function profileWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(getInfoAll, handlerErrors(getInfoAllAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(getData().type, handlerErrors(getDataAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(addAccountReq().type, handlerErrors(addAccountAsync, checkAddAccountAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(deleteAccountReq().type, handlerErrors(deleteAccountAsync))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(updatePasswordReq().type, handlerErrors(updatePasswordAsync, checkUpdaatePasswordAsync))];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(getInfo().type, handlerErrors(getInfoAsync))];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateInfo().type, handlerErrors(updateInfoAsync))];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(getFamily, handlerErrors(getFamilyAsync))];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(getFamilyWithoutLoader, handlerErrors(getFamilyAsync))];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(getFamilyByPersonId, handlerErrors(getFamilyByPersonIdAsync))];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeEvery(deletePersonSelf, handlerErrors(deletePersonSelfAsync))];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeEvery(getSelfType, handlerErrors(getSelfTypeAsync))];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeEvery(deletePatient.type, handlerErrors(deletePatientAsync))];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeEvery(updatePatient.type, handlerErrors(updatePatientAsync))];
            case 14:
                _a.sent();
                return [4 /*yield*/, takeEvery(getAvatar.type, handlerErrors(getAvatarAsync))];
            case 15:
                _a.sent();
                return [4 /*yield*/, takeLatest(uploadAvatar.type, handlerErrors(uploadAvatarAsync))];
            case 16:
                _a.sent();
                return [4 /*yield*/, takeLatest(removeAvatar.type, handlerErrors(removeAvatarAsync))];
            case 17:
                _a.sent();
                return [4 /*yield*/, takeLatest(getSubscriptions.type, handlerErrors(getSubscriptionsAsync))];
            case 18:
                _a.sent();
                return [4 /*yield*/, takeLatest(getRecommendations.type, handlerErrors(getRecommendationsAsync))];
            case 19:
                _a.sent();
                return [4 /*yield*/, takeLatest(getAlertsCount.type, handlerErrors(getAlertsCountAsync))];
            case 20:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
