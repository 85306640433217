var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import Bowl from '@root/src/shared/svg/bowl';
import Chat from '@root/src/shared/svg/chat';
import Support from '@root/src/shared/svg/support';
import styles from './index.module.sass';
export var Features = function () {
    return (_jsxs("div", __assign({ className: styles.features }, { children: [_jsxs("div", __assign({ className: styles.featuresItem }, { children: [_jsx("div", __assign({ className: styles.featuresItemLogo }, { children: _jsx(Chat, {}) })), _jsx("div", __assign({ className: styles.featuresItemTitle }, { children: _jsx(Link, __assign({ to: routePaths.CONTACTUS }, { children: _jsx("u", { children: t('label_landing_home_services_features1_title') }) })) })), _jsx("div", __assign({ className: styles.featuresItemText }, { children: t('label_landing_home_services_features1_text') }))] })), _jsxs("div", __assign({ className: styles.featuresItem }, { children: [_jsx("div", __assign({ className: styles.featuresItemLogo }, { children: _jsx(Support, {}) })), _jsx("div", __assign({ className: styles.featuresItemTitle }, { children: t('label_landing_home_services_features2_title') })), _jsx("div", __assign({ className: styles.featuresItemText }, { children: t('label_landing_home_services_features2_text') }))] })), _jsxs("div", __assign({ className: styles.featuresItem }, { children: [_jsx("div", __assign({ className: styles.featuresItemLogo }, { children: _jsx(Bowl, {}) })), _jsx("div", __assign({ className: styles.featuresItemTitle }, { children: t('label_landing_home_services_features3_title') })), _jsx("div", __assign({ className: styles.featuresItemText }, { children: t('label_landing_home_services_features3_text') }))] }))] })));
};
