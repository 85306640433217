var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import ButtonContact from '@core/components/buttons/buttonContacts';
import { showModal } from '@core/components/modals';
import { PaymentMethodCard } from '@core/components/paymentMethodCard';
import Plus from '@core/shared/svg/plus';
import { setAlert } from '@core/store/reducers/alertSlice';
import { deletePaymentMethod, getPaymentMethods, resetAddPaymentStatus, resetDeletePaymentStatus, } from '@core/store/reducers/paymentsSlice';
import { SimpleConfirmPopup } from '@root/../core/components/modals/simpleConfirmPopup';
import { PaymentMethodsLoader } from './loader';
import styles from './index.module.sass';
var mapState = function (state) { return ({
    errorsStore: state.errors,
    profile: state.profile,
    codeStore: state.code,
    payments: state.payments,
}); };
export var PaymentMethod = function () {
    var currentModalClose = useRef(null);
    var dispatch = useDispatch();
    var nav = useNavigate();
    var payments = useSelector(mapState).payments;
    var _a = useState(false), isLoaded = _a[0], setLoaded = _a[1];
    useEffect(function () {
        dispatch(getPaymentMethods(function () {
            setLoaded(true);
        }));
        return function () {
            dispatch(resetAddPaymentStatus());
            dispatch(resetDeletePaymentStatus());
        };
    }, []);
    useEffect(function () {
        if (!isLoaded && !payments.isLoading) {
            setLoaded(true);
        }
    }, [payments.isLoading]);
    useEffect(function () {
        if (payments.deletePaymentSuccess) {
            dispatch(setAlert(t('alert_payment_method_deleted')));
            currentModalClose.current && currentModalClose.current();
        }
    }, [payments.deletePaymentSuccess]);
    var handleDelete = function (id) {
        dispatch(resetDeletePaymentStatus());
        var _a = showModal(SimpleConfirmPopup, {
            title: t('delete_payment_title'),
            primaryButtonLabel: t('btn_delete'),
            secondaryButtonLabel: t('btn_cancel'),
            primaryButtonHandler: function () {
                updateStateUnstable({ key: 'isLoading', value: true });
                dispatch(deletePaymentMethod(id));
            },
            secondaryButtonHandler: function () { return closeModal(); },
            closeAfterConfirm: false,
            isDanger: true,
            isTextCentered: true,
        }), closeModal = _a.closeModal, updateStateUnstable = _a.updateStateUnstable;
        currentModalClose.current = closeModal;
    };
    var handleAddPayment = function () {
        dispatch(resetAddPaymentStatus());
        nav(routePaths.ADD_PAYMENT, { replace: true });
    };
    if (isLoaded) {
        return (_jsx("div", __assign({ className: styles.container }, { children: _jsxs("div", __assign({ className: styles.paymentElements }, { children: [payments.paymentMethods.map(function (pm) { return (_jsx(PaymentMethodCard, { method: pm, onDelete: function () { return handleDelete(pm === null || pm === void 0 ? void 0 : pm._id); }, className: styles.paymentCard }, pm === null || pm === void 0 ? void 0 : pm.stripe_id)); }), _jsxs(ButtonContact, __assign({ className: clsx(styles.contactsElement, styles.btn), handleClick: handleAddPayment, add: true }, { children: [_jsx(Plus, {}), t('label_add_payment_method')] }))] })) })));
    }
    return _jsx(PaymentMethodsLoader, {});
};
