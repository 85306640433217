var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { t } from 'i18next';
import { call, put, takeEvery } from 'redux-saga/effects';
import bookingService from '@core/services/booking';
import { serviceMap } from '@core/services/map';
import serviceOrders from '@core/services/orders';
import profileService from '@core/services/prfofile';
import { setAlert } from '@core/store/reducers/alertSlice';
import { cancelOrder, cancelOrderError, cancelOrderSuccess, deleteOrder, deleteOrderError, deleteOrderSuccess, getEventDetail, getEventDetailError, getEventDetailSuccess, getEvents, getEventsAmount, getEventsAmountSuccess, getEventsError, getEventsSuccess, setEventDetail, setEvents, setEventsAdditional, } from '@core/store/reducers/eventsSlice';
import { handlerErrors } from '@core/store/sagas/errors';
import { addError } from '../reducers/errorsSlice';
export function getEventsAsync(_a) {
    var data, e_1;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 9, , 10]);
                return [4 /*yield*/, call(bookingService.getEvents, payload.limit, payload.skip, payload === null || payload === void 0 ? void 0 : payload.excludeStatuses, payload.number)];
            case 1:
                data = (_b.sent()).data;
                if (!payload.additional) return [3 /*break*/, 3];
                return [4 /*yield*/, put(setEventsAdditional(data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, put(setEvents(data))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5:
                if (!payload.callback) return [3 /*break*/, 7];
                return [4 /*yield*/, call(payload.callback)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [4 /*yield*/, put(getEventsSuccess())];
            case 8:
                _b.sent();
                return [3 /*break*/, 10];
            case 9:
                e_1 = _b.sent();
                getEventsError();
                put(setAlert(t('error_unknown')));
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function getEventsAmountAsync(action) {
    var data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(bookingService.getProceduresTotalInfo, action.payload)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(getEventsAmountSuccess({ total: data.total, new: data.new }))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_1 = _a.sent();
                return [4 /*yield*/, put(getEventsAmountSuccess({ total: 0, new: 0 }))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function getEventDetailAsync(_a) {
    var eventExtended, orderSteps, data, person, officeId, office, e_2;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 11, , 14]);
                return [4 /*yield*/, call(bookingService.getOrderById, payload.id)];
            case 1:
                eventExtended = (_b.sent()).data;
                orderSteps = [];
                if (!(eventExtended.appointment_number.length > 0)) return [3 /*break*/, 3];
                return [4 /*yield*/, call(bookingService.getOrderStepsById, payload.id)];
            case 2:
                data = (_b.sent()).data;
                orderSteps = data;
                _b.label = 3;
            case 3: return [4 /*yield*/, call(profileService.getPersonById, eventExtended === null || eventExtended === void 0 ? void 0 : eventExtended.owner_person_id)];
            case 4:
                person = (_b.sent()).data;
                officeId = eventExtended.links.find(function (elem) { return elem.collection === 'offices'; });
                office = void 0;
                if (!officeId) return [3 /*break*/, 6];
                return [4 /*yield*/, call(serviceMap.getOfficeById, officeId.id)];
            case 5:
                office = _b.sent();
                _b.label = 6;
            case 6: return [4 /*yield*/, put(setEventDetail(__assign({ event: eventExtended, person: person, orderSteps: orderSteps }, (office && { office: office.data }))))];
            case 7:
                _b.sent();
                if (!payload.callback) return [3 /*break*/, 9];
                return [4 /*yield*/, call(payload.callback)];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9: return [4 /*yield*/, put(getEventDetailSuccess())];
            case 10:
                _b.sent();
                return [3 /*break*/, 14];
            case 11:
                e_2 = _b.sent();
                return [4 /*yield*/, put(addError({
                        type: 'getEventDetail',
                        error: 'Failed to load data for selected event',
                    }))];
            case 12:
                _b.sent();
                return [4 /*yield*/, put(getEventDetailError())];
            case 13:
                _b.sent();
                put(setAlert(t('error_unknown')));
                throw e_2;
            case 14: return [2 /*return*/];
        }
    });
}
function cancelOrderAsync(action) {
    var data, error_2, errorMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 10]);
                return [4 /*yield*/, call(serviceOrders.cancelOrder, action.payload.orderId)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, action.payload.updateEventsData &&
                        call(getEventsAsync, {
                            payload: __assign(__assign({}, action.payload.updateEventsData), { additional: false }),
                        })];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(cancelOrderSuccess(data._id))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(setAlert(t('cancel_order_success')))];
            case 4:
                _a.sent();
                return [4 /*yield*/, action.payload.callback && call(action.payload.callback)];
            case 5:
                _a.sent();
                return [3 /*break*/, 10];
            case 6:
                error_2 = _a.sent();
                errorMessage = axios.isAxiosError(error_2) && error_2.response.status === 404
                    ? t('cancel_order_error')
                    : t('error_unknown');
                return [4 /*yield*/, put(setAlert(errorMessage))];
            case 7:
                _a.sent();
                return [4 /*yield*/, put(cancelOrderError())];
            case 8:
                _a.sent();
                return [4 /*yield*/, action.payload.callback && call(action.payload.callback, true)];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function deleteOrderAsync(action) {
    var error_3, errorMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 10]);
                return [4 /*yield*/, call(serviceOrders.deleteOrder, action.payload.orderId)];
            case 1:
                _a.sent();
                return [4 /*yield*/, action.payload.updateEventsData &&
                        call(getEventsAsync, {
                            payload: __assign(__assign({}, action.payload.updateEventsData), { additional: false }),
                        })];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(deleteOrderSuccess(action.payload.orderId))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(setAlert(t('delete_order_success')))];
            case 4:
                _a.sent();
                return [4 /*yield*/, action.payload.callback && call(action.payload.callback)];
            case 5:
                _a.sent();
                return [3 /*break*/, 10];
            case 6:
                error_3 = _a.sent();
                errorMessage = axios.isAxiosError(error_3) && error_3.response.status === 404
                    ? t('delete_order_error')
                    : t('error_unknown');
                return [4 /*yield*/, put(setAlert(errorMessage))];
            case 7:
                _a.sent();
                return [4 /*yield*/, put(deleteOrderError())];
            case 8:
                _a.sent();
                return [4 /*yield*/, action.payload.callback && call(action.payload.callback, true)];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function eventsWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(getEvents.type, handlerErrors(getEventsAsync))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(getEventsAmount.type, handlerErrors(getEventsAmountAsync))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(getEventDetail.type, handlerErrors(getEventDetailAsync))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(cancelOrder.type, handlerErrors(cancelOrderAsync))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(deleteOrder.type, handlerErrors(deleteOrderAsync))];
            case 5:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
