import { useMemo, useRef, useState } from 'react';
export function useStartValue(dynamicValue) {
    var _a = useState(false), exec = _a[0], setExec = _a[1];
    var tempStartVal = useRef(undefined);
    var startValue = useMemo(function () {
        var value = dynamicValue;
        if (value && !exec) {
            tempStartVal.current = value;
            setExec(true);
            return value;
        }
        return tempStartVal.current;
    }, [dynamicValue, exec]);
    return startValue;
}
