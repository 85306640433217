var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { sortBy } from 'lodash';
import moment from 'moment/moment';
import { showModal } from '@core/components/modals';
import { SelectPatient } from '@core/components/modals/selectPatient';
import { handleAddFamily } from '@core/shared/utils/patientUtils';
export var handleOpenSelectPatient = function (navigate, order_id) {
    //Navigate in context necessary
    var closeSelectPatientModal = showModal(SelectPatient, {
        navigate: navigate,
        handleSelectPatient: function (patient) {
            closeSelectPatientModal();
            navigate("/booking/".concat(patient._id), { state: __assign({}, (order_id && { order_id: order_id })) });
        },
    }).closeModal;
};
export var selectPatientFromSupervisor = function (userId, navigate, backRoute) {
    var closeModal = showModal(SelectPatient, {
        navigate: navigate,
        userId: userId,
        handleAddFamily: function () {
            closeModal();
            handleAddFamily(userId, navigate, backRoute);
        },
        handleSelectPatient: function (patient) {
            closeModal();
            navigate('/prebooking', { state: { backRoute: backRoute, patient: patient, userId: userId } });
        },
    }).closeModal;
};
export var getDefaultInsurance = function (data) {
    if (!data.length)
        return null;
    if (data.length === 1)
        return data[0];
    var verificatedInsurances = data.filter(function (item) {
        return item.type === 'insurance' &&
            ((item === null || item === void 0 ? void 0 : item.verification_status) === 'verified' || (item === null || item === void 0 ? void 0 : item.verification_status) === 'accepted');
    });
    if (verificatedInsurances.length > 0) {
        return sortBy(verificatedInsurances, 'verification_date').reverse()[0];
    }
    var bestItem = {};
    var statusPriority = {
        verified: 3,
        accepted: 2,
        invalid: 1,
        not_validated: 0,
    };
    var getLevel = function (status) {
        return status in statusPriority ? statusPriority[status] : 0;
    };
    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
        var item = data_1[_i];
        if (item.type !== 'insurance') {
            continue;
        }
        if (getLevel(bestItem.verification_status) < getLevel(item.verification_status)) {
            bestItem = item;
            continue;
        }
    }
    return bestItem;
};
export var getPersonLetters = function (person) {
    var _a, _b;
    return "".concat((_a = person === null || person === void 0 ? void 0 : person.name) === null || _a === void 0 ? void 0 : _a.given[0][0]).concat((_b = person === null || person === void 0 ? void 0 : person.name) === null || _b === void 0 ? void 0 : _b.family[0]);
};
export var getFullName = function (person) {
    var _a, _b;
    return "".concat(__spreadArray([], (((_a = person === null || person === void 0 ? void 0 : person.name) === null || _a === void 0 ? void 0 : _a.given) ? person.name.given : []), true).join(' '), " ").concat((_b = person === null || person === void 0 ? void 0 : person.name) === null || _b === void 0 ? void 0 : _b.family);
};
export var getLongestBlockedProcedure = function (_a) {
    var procedures = _a.procedures, blockedProcedures = _a.blockedProcedures, patient_id = _a.patient_id;
    //Find blocked procedures from current patient then Filter By Today Date
    var patientBlockedProcedures = blockedProcedures
        .filter(function (procedure) { return procedure.person_id === patient_id; })
        .filter(function (procedure) { return moment() < moment(procedure.blocked_until); });
    //Find selected procedures if it includes in patientBlockedProcedures
    var selectedBlockedProcedures = patientBlockedProcedures
        .map(function (bp) {
        var selectedBlockedProcedure = procedures.find(function (sp) { return (sp === null || sp === void 0 ? void 0 : sp._id) === (bp === null || bp === void 0 ? void 0 : bp.procedure_id); });
        if (selectedBlockedProcedure) {
            return {
                name: selectedBlockedProcedure.name,
                blocked_until: bp.blocked_until,
            };
        }
    })
        .filter(Boolean);
    if (selectedBlockedProcedures.length) {
        //Find and return Longest Blocked Procedure
        return selectedBlockedProcedures.reduce(function (acc, procedure) {
            if (moment(acc.blocked_until).valueOf() < moment(procedure.blocked_until).valueOf()) {
                return procedure;
            }
            else {
                return acc;
            }
        }, selectedBlockedProcedures[0]);
    }
    else {
        return null;
    }
};
export var getBlockedProcedure = function (event, blockedProcedures) {
    var _a;
    if (!(event === null || event === void 0 ? void 0 : event.patient_person_id) || !((_a = event === null || event === void 0 ? void 0 : event.items) === null || _a === void 0 ? void 0 : _a.length)) {
        return null;
    }
    return getLongestBlockedProcedure({
        procedures: event.items.map(function (item) { return item.procedure; }),
        blockedProcedures: blockedProcedures,
        patient_id: event.patient_person_id,
    });
};
var getBlockedTime = function (blockedUntil) {
    if (blockedUntil) {
        var millisecondsInMinute = 60000;
        var dateDifference = new Date(blockedUntil).getTime() - new Date().getTime();
        if (dateDifference <= 0)
            return { m: 0, h: 0, milliseconds: 0 };
        var minutesDifference = dateDifference / millisecondsInMinute;
        var hours = Math.floor(minutesDifference / 60);
        var minutes = Math.round(minutesDifference - hours * 60);
        return { milliseconds: dateDifference, m: minutes, h: hours };
    }
    return { m: 0, h: 0, milliseconds: 0 };
};
export var getOrderMaxBlockedProcedures = function (blockedProcedures, activePatient, procedures) {
    if (!procedures || !procedures.length) {
        return [];
    }
    var currentBlockedProcedures = procedures === null || procedures === void 0 ? void 0 : procedures.map(function (procedure) {
        var currentBlocked = blockedProcedures.find(function (blockedProcedure) {
            return blockedProcedure.procedure_id === procedure.procedure._id &&
                blockedProcedure.person_id === activePatient._id;
        });
        if (currentBlocked && procedure) {
            return {
                name: procedure.procedure.name,
                id: procedure.procedure.id,
                time: getBlockedTime(currentBlocked.blocked_until),
            };
        }
    }).filter(function (el) { return !!el && el.time.milliseconds !== 0; });
    var maxBlockedProcedure = currentBlockedProcedures === null || currentBlockedProcedures === void 0 ? void 0 : currentBlockedProcedures.reduce(function (acc, curr) {
        var _a, _b;
        return Number((_a = acc === null || acc === void 0 ? void 0 : acc.time) === null || _a === void 0 ? void 0 : _a.milliseconds) > Number((_b = curr === null || curr === void 0 ? void 0 : curr.time) === null || _b === void 0 ? void 0 : _b.milliseconds) ? acc : curr;
    }, null);
    return maxBlockedProcedure;
};
