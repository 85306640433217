import { useSelector } from 'react-redux';
import { showModal } from '@core/components/modals';
import { LeaveQSure } from '@core/components/modals/leaveQSure';
import { findActiveSubscription } from '@core/shared/utils/subscriptionUtils';
import useWindowDimensions from '@core/shared/utils/widthHook';
export var useProceedToLabq = function () {
    var self = useSelector(function (state) { return state.profile; }).self;
    var _a = useWindowDimensions(), wideScreen = _a.isDesktop, extraWideScreen = _a.isWideScreen;
    var handleProceedToLabq = function (data) {
        var isDontAskAgainProceedLabq = localStorage.getItem('is_dont_ask_again_proceed_to_labq');
        if (isDontAskAgainProceedLabq) {
            if (data === null || data === void 0 ? void 0 : data.callback) {
                data.callback();
            }
            else {
                window.location.href = data === null || data === void 0 ? void 0 : data.url;
            }
            return;
        }
        showModal(LeaveQSure, {
            isBottomMobile: true,
            showCloseIcon: wideScreen,
            showLeftBackArrow: false,
            isAnimateRight: !extraWideScreen,
            hasSubscription: !!findActiveSubscription(self === null || self === void 0 ? void 0 : self.active_subscriptions),
            onContinue: function () {
                if (data === null || data === void 0 ? void 0 : data.callback) {
                    data === null || data === void 0 ? void 0 : data.callback();
                }
                else {
                    window.location.href = data === null || data === void 0 ? void 0 : data.url;
                }
            },
        });
    };
    return {
        handleProceedToLabq: handleProceedToLabq,
    };
};
