var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import { ButtonsGroup } from '@core/components/buttons/buttonsGroup';
import { showModal } from '@core/components/modals';
import ConfirmValues from '@core/pages/forms/modals/confirmValues';
import ValidateInsurance from '@core/pages/forms/modals/validateInsurance';
import { Question } from '@core/pages/forms/question';
import { COUNTRIES } from '@core/shared/phoneMask';
import { getChildren, getContent, getCurrentSlot, hasTag, prefillFields, } from '@core/shared/utils/surveyUtils';
import { handleCancelQuestionEdit, handleCancelUploadMedia, handleSurveyNextButtonStep, handleSurveyUploadMedia, } from '@core/store/reducers/surveySlice';
import styles from './index.module.sass';
export var Step = function (_a) {
    var self = _a.self, children = _a.children, answer = _a.answer, setWithBackButton = _a.setWithBackButton, withBackButton = _a.withBackButton, showConfirmation = _a.showConfirmation, closeConfirmation = _a.closeConfirmation, confirmLogout = _a.confirmLogout, prevStep = _a.prevStep, setPrevStep = _a.setPrevStep, customPrefill = _a.customPrefill;
    var _b = useSelector(function (state) { return state; }), survey = _b.survey, loading = _b.loading;
    var _c = useState(''), currentButton = _c[0], setCurrentButton = _c[1];
    var _d = useState([]), readDocuments = _d[0], setReadDocuments = _d[1];
    var form = useForm();
    var dispatch = useDispatch();
    var _e = useState({}), prefilledOCRFieldsId = _e[0], setPrefilledOCRFieldsId = _e[1];
    var stepRef = useRef();
    var handleSetReadDocuments = function (id) {
        setReadDocuments(function (prev) { return __spreadArray(__spreadArray([], prev, true), [id], false); });
    };
    var _f = useMemo(function () {
        var currentChildren = getChildren({ id: self._id, data: children });
        var questions = currentChildren.filter(function (child) {
            return child.variation === 'question' &&
                child.parents.indexOf(self._id) === child.parents.length - 1;
        });
        var mustReadDocuments = currentChildren
            .filter(function (child) {
            return child.variation === 'info' &&
                child.subvariation === 'document' &&
                hasTag(child, 'mustRead');
        })
            .map(function (child) { return child._id; });
        var outsideLayoutButtons = [];
        var uploadButton;
        var buttons = currentChildren
            .filter(function (child) {
            return child.variation === 'button' &&
                child.parents.indexOf(self._id) === child.parents.length - 1;
        })
            .filter(function (button) {
            var isAddEntity = hasTag(button, 'addEntity'); // Add entity button display like a slot
            var isCancelEntity = button.action === 'cancel_entity'; // Cancel action should be display outside buttons layout
            var isUploadButton = hasTag(button, 'upload');
            if (isCancelEntity) {
                outsideLayoutButtons.push(button);
            }
            if (isUploadButton) {
                uploadButton = button;
            }
            return !isAddEntity && !isCancelEntity && !isUploadButton;
        });
        return {
            currentChildren: currentChildren,
            buttons: buttons,
            outsideLayoutButtons: outsideLayoutButtons,
            questions: questions,
            mustReadDocuments: mustReadDocuments,
            uploadButton: uploadButton,
        };
    }, [self._id, children]), currentChildren = _f.currentChildren, questions = _f.questions, buttons = _f.buttons, outsideLayoutButtons = _f.outsideLayoutButtons, mustReadDocuments = _f.mustReadDocuments, uploadButton = _f.uploadButton;
    useEffect(function () {
        if (hasTag(self, 'autoAnswer') && buttons.length) {
            onButtonClick(buttons[0]).then();
        }
    }, [self, buttons]);
    useEffect(function () {
        if (survey.validateInsurance === 'loading' || survey.validateInsurance === 'creating') {
            showModal(ValidateInsurance, {
                showCloseIcon: false,
                closeBackDrop: false,
                isBottomMobile: true,
                onButtonClick: onButtonClick,
                buttons: buttons,
                values: form.getValues(),
                type: 'forms',
            });
        }
    }, [survey.validateInsurance]);
    useEffect(function () {
        if (setWithBackButton && withBackButton !== !!outsideLayoutButtons.length) {
            setWithBackButton(!!outsideLayoutButtons.length); // margin for title with back arrow
        }
    }, [outsideLayoutButtons.length, setWithBackButton, withBackButton]);
    var setFirstSelectOptionsValue = useCallback(function () {
        var selects = currentChildren.filter(function (child) {
            return child.subvariation === 'select' &&
                child.parents.indexOf(self._id) === child.parents.length - 2;
        });
        var options = currentChildren.filter(function (child) {
            return child.variation === 'select_option' &&
                child.parents.indexOf(self._id) === child.parents.length - 3;
        });
        selects.forEach(function (select) {
            if (hasTag(select, 'selectedFirst') || hasTag(select, 'optionSlot')) {
                var firstOption = options.find(function (option) { return option.parents.indexOf(select._id) === option.parents.length - 1; });
                form.setValue(select._id, firstOption.value);
            }
        });
    }, [currentChildren, self._id]);
    var setOptionsFirstValue = useCallback(function () {
        if (self.subvariation === 'switcher') {
            var options = currentChildren.filter(function (child) {
                return child.subvariation === 'option' &&
                    child.parents.indexOf(self._id) === child.parents.length - 2;
            });
            options[0] && form.setValue(self._id, options[0]._id);
        }
    }, [self.subvariation, currentChildren]);
    var setValuesFromAnswers = useCallback(function () {
        var _a;
        if (answer && ((_a = answer.values) === null || _a === void 0 ? void 0 : _a.length)) {
            answer.values.forEach(function (value) {
                var currentChild = currentChildren.find(function (child) { return child._id === (value === null || value === void 0 ? void 0 : value.slot_id); });
                var isOption = currentChild.subvariation === 'option';
                var isMedia = (currentChild === null || currentChild === void 0 ? void 0 : currentChild.subvariation) === 'media';
                var isUpperCase = hasTag(currentChild, 'uppercase');
                if (isMedia) {
                    return form.setValue(value.slot_id, value.slot_id);
                }
                if (isOption) {
                    var parentQuestion = currentChildren.find(function (el) {
                        return el.variation === 'question' &&
                            currentChild.parents.indexOf(el._id) === currentChild.parents.length - 1;
                    });
                    if (parentQuestion && parentQuestion.subvariation === 'multi') {
                        var oldValue = form.getValues(parentQuestion._id) || [];
                        return form.setValue(parentQuestion._id, __spreadArray(__spreadArray([], oldValue, true), [value.slot_id], false));
                    }
                    if (parentQuestion && parentQuestion.subvariation === 'single') {
                        return form.setValue(parentQuestion._id, value.slot_id);
                    }
                    return form.setValue(answer.step_id, value.slot_id);
                }
                if (value.value) {
                    var val = isUpperCase ? value.value.toUpperCase() : value.value;
                    return form.setValue(value.slot_id, val);
                }
            });
        }
    }, [survey.answers, self]); //eslint-disable-line
    useEffect(function Prefill() {
        var _a;
        form.reset();
        // setOptionsFirstValue()
        var isUpdate = hasTag(self, 'update');
        if (!isUpdate) {
            var currentSlots_1 = currentChildren.filter(function (child) {
                return (child.variation === 'slot' &&
                    child.parents.indexOf(self._id) === child.parents.length - 2);
            });
            var prefilledSlots = currentSlots_1.filter(function (child) {
                return !!child.prefills.length;
            });
            //CUSTOM_PREFILL
            if (!!customPrefill && !!((_a = Object.keys(customPrefill)) === null || _a === void 0 ? void 0 : _a.length)) {
                Object.keys(customPrefill).forEach(function (prefill) {
                    if (prefill === 'contact_identifier') {
                        Object.keys(customPrefill[prefill]).forEach(function (el) {
                            if (customPrefill[prefill][el]) {
                                var identifierType_1 = customPrefill[prefill][el].type;
                                if (identifierType_1 === 'email' || identifierType_1 === 'phone') {
                                    var identifierSlot = currentSlots_1.find(function (el) { return el.subvariation === 'input' && hasTag(el, identifierType_1); });
                                    if (identifierSlot) {
                                        form.setValue(identifierSlot._id, customPrefill[prefill][el].identifier);
                                    }
                                }
                            }
                        });
                        return;
                    }
                    var prefilledSlot = currentSlots_1.find(function (slot) { return slot.sid === prefill; });
                    if (prefilledSlot) {
                        form.setValue(prefilledSlot._id, customPrefill[prefill]);
                    }
                });
            }
            //BACKEND_PREFILL
            prefillFields({
                survey: survey,
                prefilledSlots: prefilledSlots,
                callback: function (_a) {
                    var slot = _a.slot, prefill = _a.prefill, value = _a.value;
                    setPrefilledOCRFieldsId(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[slot._id] = prefill, _a)));
                    });
                    form.setValue(slot._id, value);
                },
            });
        }
        setValuesFromAnswers();
        setFirstSelectOptionsValue();
    }, [
        currentChildren,
        self._id,
        setValuesFromAnswers,
        setFirstSelectOptionsValue,
        setOptionsFirstValue,
    ]); //eslint-disable-line
    var handleCheckFields = function (_a) {
        var values = _a.values, detail = _a.correctData.detail, buttonId = _a.buttonId;
        var preparedValues = values.map(function (valueItem) {
            var content = getContent({ id: valueItem.slot_id, data: children });
            var value = '';
            value = valueItem.value;
            return {
                slot_id: valueItem.slot_id,
                label: content.label,
                value: value,
            };
        });
        if (detail) {
            for (var i = 0; i < detail.length; i++) {
                var detailIndex = detail[i].loc && detail[i].loc.find(function (detail) { return typeof detail === 'number'; });
                if (detailIndex !== -1) {
                    preparedValues[detailIndex].oldValue = JSON.parse(JSON.stringify(preparedValues[detailIndex].value));
                    preparedValues[detailIndex].value = detail[i]['msg'];
                }
            }
        }
        var showedValues = preparedValues.filter(function (val) {
            var slot = getCurrentSlot({ id: val.slot_id, data: children });
            return !hasTag(slot, 'hide');
        });
        var closeModal = showModal(ConfirmValues, {
            showCloseIcon: false,
            isBottomMobile: true,
            values: showedValues,
            title: 'Insurance Details',
            handleConfirm: function () {
                var currentButton = buttons.find(function (button) { return button._id === buttonId; });
                preparedValues.forEach(function (value) { return form.setValue(value.slot_id, value.value); });
                onButtonClick(currentButton, true).then();
                closeModal();
            },
        }).closeModal;
    };
    var handleCheckAddress = function (_a) {
        var values = _a.values, buttonId = _a.buttonId, correctAddressData = _a.correctAddressData, isApartmentError = _a.isApartmentError;
        if (isApartmentError) {
            var apartmentSlot = currentChildren.find(function (child) { return child.sid === 'address_line_2' && child.variation === 'slot'; });
            form.setError(apartmentSlot === null || apartmentSlot === void 0 ? void 0 : apartmentSlot._id, {
                message: form.getValues(apartmentSlot === null || apartmentSlot === void 0 ? void 0 : apartmentSlot._id)
                    ? t('error_incorrect_apartment')
                    : t('error_required'),
            });
            return;
        }
        var details = correctAddressData.detail;
        var innerValues = JSON.parse(JSON.stringify(values));
        if (details) {
            for (var i = 0; i < details.length; i++) {
                var detailIndex = details[i].loc && details[i].loc.findIndex(function (detail) { return typeof detail === 'number'; });
                if (detailIndex !== -1) {
                    innerValues[details[i]['loc'][detailIndex]]['value'] = details[i]['msg'];
                }
            }
            var rightSidsOrder = [
                'address_line_1',
                'address_line_2',
                '\n',
                'city',
                'state',
                'zip',
                '\n',
                'country',
            ];
            //sortByOrder
            var showedInnerValues = rightSidsOrder
                .map(function (orderSid) {
                if (orderSid === '\n') {
                    return '\n';
                }
                var currentVal = innerValues.find(function (innerValue) { var _a; return ((_a = currentChildren.find(function (child) { return innerValue.slot_id === child._id; })) === null || _a === void 0 ? void 0 : _a.sid) === orderSid; });
                if (orderSid === 'country') {
                    var longCountryName = COUNTRIES.find(function (country) { return country.iso2.toLowerCase() === currentVal.value.toLowerCase(); }).label;
                    return {
                        value: (longCountryName === null || longCountryName === void 0 ? void 0 : longCountryName.toUpperCase()) || currentVal.value,
                    };
                }
                return currentVal;
            })
                .filter(Boolean);
            var preparedConfirmationAddress = showedInnerValues
                .map(function (value) { return (value === '\n' ? value : value === null || value === void 0 ? void 0 : value.value); })
                .join(', ')
                .split(', \n, ');
            preparedConfirmationAddress = preparedConfirmationAddress.map(function (val) { return (_jsx("p", __assign({ className: styles.addressConfirmationText }, { children: val }), val)); });
            showConfirmation({
                title: t('confirmation_popup_label_forms_confirm_address_title'),
                description: preparedConfirmationAddress,
                primaryButtonHandler: function () {
                    innerValues.forEach(function (value) { return form.setValue(value.slot_id, value.value); });
                    var currentButton = buttons.find(function (button) { return button._id === buttonId; });
                    onButtonClick(currentButton).then();
                    closeConfirmation();
                },
                primaryButtonLabel: t('btn_confirm'),
                secondaryButtonHandler: closeConfirmation,
                secondaryButtonLabel: t('btn_change_address'),
            });
        }
    };
    var prepareValuesForPayload = function (button, buttonType, isUploadInsurance, withoutModal) {
        var isUploadButton = hasTag(button, 'upload');
        var isUpdate = hasTag(self, 'update');
        var isInsurance = hasTag(self, 'insuranceDetails');
        var payload = __assign(__assign({ stepId: self._id, buttonId: button._id, values: [], setError: form.setError, handleCheckAddress: handleCheckAddress, handleCheckFields: handleCheckFields, surveyType: 'chat' }, (isUpdate && { isUpdate: isUpdate })), (isInsurance &&
            !withoutModal && { isInsurance: isUploadInsurance ? 'creating' : 'validate' }));
        var formValues = form.getValues();
        switch (buttonType) {
            case 'media': {
                payload.values = Object.keys(formValues).map(function (key) { return ({
                    slot_id: key,
                    media: formValues[key],
                }); });
                break;
            }
            case 'skip':
            case 'back':
                break;
            default: {
                Object.keys(formValues).forEach(function (key) {
                    var value = typeof formValues[key] === 'string' ? formValues[key].trim() : formValues[key];
                    var slot = typeof value === 'string' && getCurrentSlot({ id: value, data: currentChildren });
                    if (!!slot && slot.subvariation === 'option') {
                        payload.values.push({
                            slot_id: value,
                        });
                        return;
                    }
                    if (questions.some(function (q) { return q._id === key; })) {
                        //multi select
                        if (value instanceof Array) {
                            value.forEach(function (el) {
                                payload.values.push({
                                    slot_id: el,
                                });
                            });
                            return;
                        }
                        formValues[key] &&
                            payload.values.push({
                                slot_id: value,
                            });
                        return;
                    }
                    formValues[key] &&
                        payload.values.push({
                            slot_id: key,
                            value: value,
                        });
                });
            }
        }
        if (isUploadButton) {
            payload.values = [];
        }
        return payload;
    };
    var wasEdit = function (values) {
        var prevValues = answer.values;
        var currentValues = values.values;
        if (!prevValues.length)
            return false;
        var equalLength = prevValues.length === currentValues.length;
        var equalFields = prevValues.every(function (prevValue) {
            var compairedPrevValue = __assign({}, prevValue);
            if (prevValue.value === null) {
                compairedPrevValue = { slot_id: prevValue.slot_id };
            }
            else {
                compairedPrevValue = {
                    slot_id: prevValue.slot_id,
                    value: prevValue.value,
                };
            }
            return currentValues.some(function (innerValue) { return JSON.stringify(compairedPrevValue) === JSON.stringify(innerValue); });
        });
        return equalFields && equalLength;
    };
    var handleCancelUpdate = function () {
        dispatch(handleCancelQuestionEdit());
    };
    var handleBack = function (button) {
        var payload = prepareValuesForPayload(button, 'back');
        dispatch(handleSurveyNextButtonStep(payload));
    };
    var onButtonClick = function (button, isUploadInsurance, withoutModal) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, payload, isNext, isUpdate, isUploadButton, isValid, formValues, payload, forSlotValues_1, slots, valuesKeys, isMedia;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (hasTag(button, 'logout')) {
                        return [2 /*return*/, confirmLogout()];
                    }
                    if (hasTag(button, 'close')) {
                        window.location.href = '/';
                        return [2 /*return*/, false];
                    }
                    setCurrentButton(button._id);
                    _a = button.action;
                    switch (_a) {
                        case 'skip': return [3 /*break*/, 1];
                        case 'skip-line': return [3 /*break*/, 1];
                        case 'back_repeat': return [3 /*break*/, 2];
                        case 'cancel_entity': return [3 /*break*/, 3];
                        case 'back': return [3 /*break*/, 3];
                        case 'next_overwrite': return [3 /*break*/, 4];
                        case 'force_next': return [3 /*break*/, 4];
                        case 'next': return [3 /*break*/, 4];
                    }
                    return [3 /*break*/, 7];
                case 1:
                    {
                        payload = prepareValuesForPayload(button);
                        dispatch(handleSurveyNextButtonStep(payload));
                        return [3 /*break*/, 7];
                    }
                    _b.label = 2;
                case 2:
                    {
                        showConfirmation({
                            title: t('confirmation_popup_label_forms_back_title'),
                            description: t('confirmation_popup_label_forms_back_description'),
                            primaryButtonHandler: function () {
                                handleBack(button);
                                closeConfirmation();
                            },
                            primaryButtonLabel: t('btn_go_back'),
                            secondaryButtonHandler: closeConfirmation,
                            secondaryButtonLabel: t('btn_cancel'),
                        });
                        return [3 /*break*/, 7];
                    }
                    _b.label = 3;
                case 3:
                    {
                        handleBack(button);
                        return [3 /*break*/, 7];
                    }
                    _b.label = 4;
                case 4:
                    isNext = button.action === 'next' ||
                        button.action === 'force_next' ||
                        button.action === 'next_overwrite';
                    isUpdate = hasTag(self, 'update');
                    isUploadButton = hasTag(button, 'upload');
                    if (!(isNext && !isUploadButton)) return [3 /*break*/, 6];
                    return [4 /*yield*/, form.trigger()];
                case 5:
                    isValid = _b.sent();
                    if (!isValid)
                        return [2 /*return*/];
                    _b.label = 6;
                case 6:
                    formValues = form.getValues();
                    payload = prepareValuesForPayload(button, null, isUploadInsurance, withoutModal);
                    forSlotValues_1 = Object.values(formValues);
                    slots = currentChildren.filter(function (el) { return forSlotValues_1.some(function (el1) { return el1 === el._id; }); });
                    if (slots.some(function (slot) { return hasTag(slot, 'close'); })) {
                        window.location.href = '/';
                        return [2 /*return*/, false];
                    }
                    valuesKeys = Object.keys(formValues);
                    isMedia = valuesKeys.length &&
                        valuesKeys.every(function (key) { var _a; return ((_a = currentChildren.find(function (child) { return child._id === key; })) === null || _a === void 0 ? void 0 : _a.subvariation) === 'media'; });
                    if (isMedia) {
                        payload = prepareValuesForPayload(button, 'media');
                        return [2 /*return*/, dispatch(handleSurveyUploadMedia(payload))];
                    }
                    if (isUpdate && wasEdit(payload)) {
                        handleCancelUpdate();
                        return [2 /*return*/];
                    }
                    dispatch(handleSurveyNextButtonStep(payload));
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleCancelMediaUpload = function () {
        dispatch(handleCancelUploadMedia());
    };
    var mediaUploadIsNotCanceled = survey.fullSurvey.survey.status === 'uploading';
    useEffect(function () {
        var prevAutoAnswer = hasTag(prevStep, 'autoAnswer');
        if (!prevAutoAnswer) {
            window.scrollTo(0, document.body.scrollHeight);
        }
        setPrevStep(self);
    }, [survey.currentStep]);
    var bigButtonStyle = !currentChildren.some(function (child) { return child.subvariation === 'option'; }) &&
        currentChildren.some(function (child) {
            return child.subvariation === 'date' ||
                child.subvariation === 'select' ||
                child.subvariation === 'input';
        });
    var documentsIsRead = function () {
        var hasDocumentMustRead = mustReadDocuments.length;
        var isEqualReadAndMustRead = JSON.stringify(__spreadArray([], mustReadDocuments, true).sort()) === JSON.stringify(__spreadArray([], readDocuments, true).sort());
        return !hasDocumentMustRead || (hasDocumentMustRead && isEqualReadAndMustRead);
    };
    var readingDocumentsLabel = "".concat(readDocuments === null || readDocuments === void 0 ? void 0 : readDocuments.length, " of ").concat(mustReadDocuments === null || mustReadDocuments === void 0 ? void 0 : mustReadDocuments.length, " Read");
    return (_jsxs("div", __assign({ ref: stepRef, className: clsx(styles.step, styles.stepChat) }, { children: [_jsx("div", { children: questions.map(function (question) {
                    if (question.variation === 'question') {
                        return (_jsx(Question, __assign({ self: question, form: form, stepId: self._id, onButtonClick: onButtonClick, mediaUploadIsNotCanceled: mediaUploadIsNotCanceled, prefilledOCRFieldsId: prefilledOCRFieldsId, handleSetReadDocuments: handleSetReadDocuments, readDocuments: readDocuments, documentsIsRead: documentsIsRead, uploadButton: uploadButton }, { children: currentChildren }), question._id));
                    }
                    return null;
                }) }), _jsxs(_Fragment, { children: [_jsx(ButtonsGroup, { isReadingDocuments: !!mustReadDocuments.length, readingDocumentsLabel: readingDocumentsLabel, documentsIsRead: documentsIsRead, bigButtonStyle: bigButtonStyle, buttons: buttons, onButtonClick: onButtonClick, currentButton: currentButton, mediaUploadIsNotCanceled: mediaUploadIsNotCanceled, handleCancelMediaUpload: handleCancelMediaUpload }), hasTag(self, 'update') && (_jsx(Button, { disabled: loading.isLoading, classes: [
                            styles.button,
                            styles.buttonChat,
                            bigButtonStyle && styles.buttonChatBigStyle,
                        ], label: "Cancel", handler: handleCancelUpdate }))] })] })));
};
