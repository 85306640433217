var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { TextMessage } from '@core/components/surveyMessages/textMessage';
import { Title } from '@core/components/title';
import { ContactItem } from '@core/pages/accountsMerge/steps/contactsList/contactItem';
import styles from './index.module.sass';
export var ContactsList = function (_a) {
    var _b, _c, _d;
    var contacts = _a.contacts, onContactClick = _a.onContactClick, handleClose = _a.handleClose, handleBack = _a.handleBack, isServey = _a.isServey;
    var _e = useState('accounts'), listType = _e[0], setListType = _e[1];
    var _f = useSelector(function (state) { return state; }), accountMerge = _f.accountMerge, loading = _f.loading, code = _f.code;
    if (accountMerge.currentAccount) {
        return (_jsx(TextMessage, __assign({ editable: !!code.verification_id, handleEdit: handleBack, type: "outcome" }, { children: accountMerge.currentAccount })));
    }
    return (_jsxs("div", __assign({ className: styles.contactsList }, { children: [_jsxs("div", __assign({ className: styles.surveyHeader }, { children: [_jsx("div", { className: styles.surveyBack }), _jsxs("div", __assign({ className: styles.surveyCloseWrapper }, { children: [_jsx("div", { className: styles.surveyClose, onClick: handleClose }), isServey && (_jsx("span", __assign({ className: styles.surveySkip, onClick: handleClose }, { children: "Skip" })))] })), _jsx(Title, { classes: [styles.title], title: 'Account & Results Merge', exLink: false }), _jsx("p", __assign({ className: styles.subTitle }, { children: "Please select one record below at a time to receive the code to merge the records." }))] })), _jsxs(_Fragment, { children: [!!(((_b = contacts === null || contacts === void 0 ? void 0 : contacts.accounts) === null || _b === void 0 ? void 0 : _b.length) || ((_c = contacts === null || contacts === void 0 ? void 0 : contacts.fhir) === null || _c === void 0 ? void 0 : _c.length)) && (_jsxs("div", __assign({ className: styles.switcher }, { children: [_jsx("span", __assign({ onClick: function () {
                                    setListType('accounts');
                                }, className: styles.switcherItem }, { children: "Accounts" })), _jsx("span", __assign({ onClick: function () {
                                    setListType('fhir');
                                }, className: styles.switcherItem }, { children: "Lab Results" })), _jsx("div", { className: clsx(styles.switcherItemBg, listType === 'fhir' && styles.switcherItemBgEnd) })] }))), !loading.isLoading &&
                        (!((_d = contacts[listType]) === null || _d === void 0 ? void 0 : _d.length) ? (_jsx("p", __assign({ className: styles.emptyList }, { children: "Nothing to merge" }))) : (contacts[listType].map(function (contact) { return (_jsx("div", __assign({ className: styles.contactsListItem }, { children: _jsx(ContactItem, { item: contact, onClick: onContactClick, listType: listType }) }), contact.identifier)); }))), loading.isLoading &&
                        (loading.type === 'accountMerge/mergeSameAccount' ||
                            loading.type === 'accountMerge/mergeFhirAccount') && (_jsx("div", __assign({ className: styles.loader }, { children: _jsx("div", { className: styles.bubbleLoader }) })))] })] })));
};
