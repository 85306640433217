var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { QSureButton as Button } from '@core/components/buttons/qsureButton';
import { YourRiskCardLoader } from '@core/components/yourRiskCard/loader';
import { AsyncStatus } from '@core/shared/constants/loaderTypes';
import { checkRiskLevel } from '@core/shared/utils/recommendationUtils';
import useWindowDimensions from '@core/shared/utils/widthHook';
import styles from './index.module.sass';
export var YourRiskCard = function (_a) {
    var showRecommendations = _a.showRecommendations, startTest = _a.startTest;
    var isMobile = useWindowDimensions().isMobile;
    var _b = useSelector(function (state) { return state.profile; }), recommendations = _b.recommendations, subscriptions = _b.subscriptions;
    var _c = useSelector(function (state) { return state.profile.statuses; }), getRecommendationsStatus = _c.getRecommendationsStatus, getSubscriptionsStatus = _c.getSubscriptionsStatus;
    var _d = useState(false), isLoaded = _d[0], setLoaded = _d[1];
    var riskLevel = checkRiskLevel(recommendations, 'overall');
    useEffect(function () {
        if (getRecommendationsStatus !== AsyncStatus.LOADING &&
            getSubscriptionsStatus !== AsyncStatus.LOADING) {
            setLoaded(true);
        }
    }, [getRecommendationsStatus, getSubscriptionsStatus]);
    if (isLoaded) {
        return (_jsxs("div", __assign({ className: styles.risk }, { children: [isMobile && (_jsx("div", __assign({ className: styles.riskContentTitle }, { children: recommendations.length > 0
                        ? t('title_health_risk_assessment')
                        : t('title_understand_health_risk') }))), _jsx("div", __assign({ className: styles.riskIcon }, { children: _jsx("img", { srcSet: "".concat(require("@core/shared/images/qsure/risk/".concat(riskLevel, "Risk@2x.png")), " 2x,\n            ").concat(require("@core/shared/images/qsure/risk/".concat(riskLevel, "Risk@3x.png")), " 3x"), src: require("@core/shared/images/qsure/risk/".concat(riskLevel, "Risk.png")), alt: "risk" }) })), _jsxs("div", __assign({ className: styles.riskContent }, { children: [!isMobile && (_jsx("div", __assign({ className: styles.riskContentTitle }, { children: recommendations.length > 0
                                ? t('title_health_risk_assessment')
                                : t('title_understand_health_risk') }))), _jsx("div", __assign({ className: styles.riskContentText }, { children: recommendations.length > 0
                                ? t('label_health_risk_assessment', { riskLevel: t("label_".concat(riskLevel)) })
                                : t('label_health_risk_assessment_unknown') })), !!subscriptions.length &&
                            (recommendations.length > 0 ? (_jsx(Button, { label: t('label_show_recomendations'), classes: [styles.btn], handler: showRecommendations, white: true })) : (_jsx(Button, { label: t('label_get_assessment'), classes: [styles.btn], handler: startTest })))] }))] })));
    }
    return _jsx(YourRiskCardLoader, {});
};
