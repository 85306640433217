var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    currentRoomFull: {
        support: {
            created: '',
            modified: '',
            _id: '',
            version: 0,
            room_id: '',
            user_info: {
                name: '',
                mrn: '',
                status: '',
                user_id: '',
            },
            status: '',
            supporter_info: {
                supporter_id: '',
                name: '',
                role: '',
            },
        },
        room: {
            created: '',
            modified: '',
            _id: '',
            version: 0,
            participants: [],
            name: '',
            invited: [],
            declined: [],
            read_messages: [],
            status: '',
            type: '',
            messages: {
                results: [],
                skip: 0,
                total: 0,
                limit: 0,
            },
        },
    },
    supportsList: {
        results: [],
        skip: 0,
        limit: 0,
        total: 0,
    },
    supportsListLoading: false,
    supportsAmount: 0,
    isLoading: true,
};
export var supportSlice = createSlice({
    name: 'chat',
    initialState: initialState,
    reducers: {
        getSelf: function (state, action) { },
        getCurrentRoomFull: function (state, action) { },
        setCurrentRoomFull: function (state, action) {
            state.currentRoomFull = action.payload;
        },
        getAdminCurrentFullSupport: function (state, action) { },
        getOldMessages: function (state, action) {
            state.isLoading = false;
        },
        getSupportOldMessages: function (state, action) {
            state.isLoading = false;
        },
        getUnReadMessages: function (state, action) { },
        getSupportsList: function (state, action) {
            state.supportsListLoading = true;
        },
        setSupportsList: function (state, action) {
            state.supportsList.results = action.payload;
            state.supportsListLoading = false;
        },
        setSupportsAmount: function (state, action) {
            state.supportsAmount = action.payload;
        },
        createSupport: function (state, action) { },
        getUserSupport: function (state, action) { },
        startSupportChat: function (state, action) { },
        stopSupportChat: function (state, action) { },
        finishSupportChat: function (state, action) { },
        enterToRoom: function (state, action) { },
        exitFromRoom: function (state, action) { },
        takeOverRoom: function (state, action) { },
    },
});
export var getCurrentRoomFull = (_a = supportSlice.actions, _a.getCurrentRoomFull), getSelf = _a.getSelf, getUnReadMessages = _a.getUnReadMessages, setCurrentRoomFull = _a.setCurrentRoomFull, getOldMessages = _a.getOldMessages, getSupportOldMessages = _a.getSupportOldMessages, createSupport = _a.createSupport, getSupportsList = _a.getSupportsList, getUserSupport = _a.getUserSupport, setSupportsList = _a.setSupportsList, setSupportsAmount = _a.setSupportsAmount, startSupportChat = _a.startSupportChat, stopSupportChat = _a.stopSupportChat, getAdminCurrentFullSupport = _a.getAdminCurrentFullSupport, finishSupportChat = _a.finishSupportChat, enterToRoom = _a.enterToRoom, exitFromRoom = _a.exitFromRoom, takeOverRoom = _a.takeOverRoom;
export default supportSlice.reducer;
