export var LABQ_CONTACTS = {
    phone: 7185345227,
    displayPhone: '(718) 534-5227',
    site: 'https://labq.com',
    displaySite: 'LabQ.com',
};
export var QSURE_CONTACTS = {
    phone: 7185345227,
    displayPhone: '(718) 534-5227',
    site: 'https://qsure.com',
    displaySite: 'QSure.com',
};
