import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { showModal } from '@core/components/modals';
import { Policy } from '@pages/statics/policy';
import { Questions } from '@pages/statics/questions';
import { Terms } from '@pages/statics/terms';
export var ElementWrapper = function (_a) {
    var children = _a.children;
    var location = useLocation();
    useEffect(function () {
        var tos = location.hash.includes('#tos');
        var pp = location.hash.includes('#pp');
        var qa = location.hash.includes('#qa');
        var modal = null;
        if (tos)
            modal = Terms;
        if (pp)
            modal = Policy;
        if (qa)
            modal = Questions;
        if (modal !== null) {
            showModal(modal, { type: 'fullPage' });
        }
    }, [location]);
    return _jsx(_Fragment, { children: children });
};
