var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var Burger = function () { return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 8C7 7.44772 7.44772 7 8 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H8C7.44772 9 7 8.55228 7 8ZM7 14C7 13.4477 7.44772 13 8 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H8C7.44772 15 7 14.5523 7 14ZM8 19C7.44772 19 7 19.4477 7 20C7 20.5523 7.44772 21 8 21H20C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19H8Z", fill: "black" }) }))); };
export default Burger;
