var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Amex = function () { return (_jsxs("svg", __assign({ width: "40", height: "28", viewBox: "0 0 40 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 6C0 2.68629 2.68629 0 6 0H34C37.3137 0 40 2.68629 40 6V22C40 25.3137 37.3137 28 34 28H6C2.68629 28 0 25.3137 0 22L0 6Z", fill: "#1F72CD" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.61146 10L4 18.2271H8.32342L8.8594 16.9154H10.0845L10.6205 18.2271H15.3794V17.2259L15.8034 18.2271H18.2651L18.6891 17.2048V18.2271H28.5863L29.7897 16.9494L30.9166 18.2271L36 18.2377L32.3771 14.1365L36 10H30.9954L29.824 11.2541L28.7326 10H17.9658L17.0412 12.1235L16.095 10H11.7806V10.9671L11.3006 10H7.61146ZM22.3908 11.1682H28.0742L29.8125 13.1011L31.6068 11.1682H33.3451L30.704 14.1353L33.3451 17.0682H31.528L29.7897 15.1129L27.9862 17.0682H22.3908V11.1682ZM23.7944 13.4681V12.3905V12.3894H27.3407L28.8881 14.1129L27.2721 15.8458H23.7944V14.6693H26.8949V13.4681H23.7944ZM8.44812 11.1682H10.5556L12.951 16.7471V11.1682H15.2596L17.1099 15.1682L18.8151 11.1682H21.1122V17.0717H19.7144L19.703 12.4458L17.6653 17.0717H16.415L14.3658 12.4458V17.0717H11.4904L10.9453 15.7482H8.00013L7.45613 17.0706H5.91551L8.44812 11.1682ZM8.50281 14.5249L9.47311 12.1672L10.4423 14.5249H8.50281Z", fill: "white" })] }))); };
export default Amex;
