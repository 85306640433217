var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import moment from 'moment';
import ArrowRight from '@core/shared/svg/arrow-right';
import { clearHours } from '@core/shared/utils/dateUtils';
import { CalendarDays } from '../calendarDays';
import { CalendarYears } from '../calendarYears';
import { MonthSwitcher } from '../monthSwitcher';
import styles from './index.module.sass';
export var Calendar = function (_a) {
    var label = _a.label, placeholder = _a.placeholder, defaultFormat = _a.defaultFormat, startValue = _a.startValue, innerRange = _a.innerRange, handleChangeDay = _a.handleChangeDay, innerBlockedDays = _a.innerBlockedDays, blockedWeekDays = _a.blockedWeekDays, closeModal = _a.closeModal, originalValue = _a.originalValue, firstWeekDay = _a.firstWeekDay, markBlockedDates = _a.markBlockedDates;
    var _b = useState(function () {
        if (originalValue) {
            return clearHours(moment(originalValue, defaultFormat));
        }
        if (startValue) {
            return clearHours(moment(startValue, defaultFormat));
        }
        if (clearHours(moment()).unix() > innerRange.current.endDate.unix()) {
            return moment(innerRange.current.endDate, defaultFormat);
        }
        if (clearHours(moment()).unix() < innerRange.current.startDate.unix()) {
            return moment(innerRange.current.startDate, defaultFormat);
        }
        return clearHours(moment());
    }), innerValue = _b[0], setInnerValue = _b[1];
    var _c = useState('days'), calendarType = _c[0], setCalendarType = _c[1];
    var handleChangeMonth = function (type) {
        setInnerValue(function (prevValue) {
            var newMonth = type === 'next' ? prevValue.month() + 1 : prevValue.month() - 1;
            return moment(prevValue.set('month', newMonth));
        });
    };
    var handleChangeYear = function (year) {
        var newValue = moment(innerValue).set('year', year);
        var endDate = innerRange.current.endDate;
        var startDate = innerRange.current.startDate;
        setCalendarType('days');
        if (newValue.unix() > endDate.unix()) {
            return setInnerValue(endDate);
        }
        if (newValue.unix() < startDate.unix()) {
            return setInnerValue(startDate);
        }
        setInnerValue(newValue);
    };
    var onChangeDay = function (date) {
        setInnerValue(date);
        handleChangeDay(date);
        closeModal();
    };
    var calendar = {
        days: (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.MonthSwitcher }, { children: _jsx(MonthSwitcher, { innerValue: innerValue, range: innerRange.current, handleChangeMonth: handleChangeMonth, setCalendarType: setCalendarType, markBlockedDates: markBlockedDates }) })), _jsx(CalendarDays, { blockedDays: innerBlockedDays, blockedWeekDays: blockedWeekDays, markBlockedDates: markBlockedDates, dateFormat: defaultFormat, range: innerRange.current, innerValue: innerValue, onChangeDay: onChangeDay, firstWeekDay: firstWeekDay })] })),
        years: (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.CalendarBack, onClick: function () { return setCalendarType('days'); } }, { children: _jsx(ArrowRight, {}) })), _jsx(CalendarYears, { range: innerRange.current, innerValue: innerValue, handleChangeYear: handleChangeYear })] })),
    };
    return (_jsxs("div", __assign({ className: styles.Calendar }, { children: [_jsx("h4", __assign({ className: styles.CalendarLabel }, { children: placeholder || label || '' })), calendar[calendarType]] })));
};
