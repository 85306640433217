var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { SurveyProgress } from '@core/components/surveyProgress';
import { Title } from '@core/components/title';
import styles from './index.module.sass';
var SurveyHeader = function (_a) {
    var title = _a.title, description = _a.description, progress = _a.progress, handleClose = _a.handleClose, stickyHeader = _a.stickyHeader;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: clsx([styles.surveyHeader, stickyHeader && styles.surveyHeaderSticky]) }, { children: [_jsx("div", { className: styles.surveyBack }), _jsx("div", { className: styles.surveyClose, onClick: handleClose }), _jsx(Title, { classes: [styles.title], title: title, description: description, exLink: false }), _jsx(SurveyProgress, { progress: progress })] })) }));
};
export default SurveyHeader;
