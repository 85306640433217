var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { Button } from '@core/components/buttons';
import { logOut } from '@core/store/reducers/authSlice';
import styles from './index.module.sass';
export var ConfirmLogout = function (_a) {
    var closeModal = _a.closeModal;
    var dispatch = useDispatch();
    return (_jsxs("div", __assign({ className: styles.ConfirmLogout }, { children: [_jsx("h3", __assign({ className: styles.ConfirmLogoutTitle }, { children: t('confirmation_popup_label_exit_title') })), _jsx("p", __assign({ className: styles.ConfirmLogoutDescription }, { children: t('confirmation_popup_label_exit_description') })), _jsx(Button, { classes: [styles.ConfirmLogoutButton], primary: true, label: t('btn_exit'), handler: function () {
                    dispatch(logOut());
                    closeModal();
                } }), _jsx(Button, { classes: [styles.ConfirmLogoutButton], label: t('btn_cancel'), handler: closeModal })] })));
};
