var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import ArrowRight from '@core/shared/svg/arrow-right';
import styles from './index.module.sass';
export var MonthSwitcher = function (_a) {
    var innerValue = _a.innerValue, handleChangeMonth = _a.handleChangeMonth, setCalendarType = _a.setCalendarType, range = _a.range, markBlockedDates = _a.markBlockedDates;
    var nameOfMonth = moment.months(innerValue.month());
    var year = innerValue.year();
    var isDisableArrow = function (type) {
        var valueYear = innerValue.year();
        var valueMonth = innerValue.month();
        var rangeYear = moment(type === 'left' ? range.startDate : range.endDate).year();
        var rangeMonth = moment(type === 'left' ? range.startDate : range.endDate).month();
        if (type === 'left') {
            return valueMonth <= rangeMonth && valueYear <= rangeYear && markBlockedDates;
        }
        if (type === 'right') {
            return valueMonth >= rangeMonth && valueYear >= rangeYear && markBlockedDates;
        }
    };
    return (_jsxs("div", __assign({ className: styles.MonthSwitcher }, { children: [_jsx("div", __assign({ className: clsx(styles.MonthSwitcherLeftArrow, isDisableArrow('left') && styles.disabled), onClick: function () { return handleChangeMonth('prev'); } }, { children: _jsx(ArrowRight, {}) })), _jsxs("span", __assign({ className: styles.MonthSwitcherLabel, onClick: function () { return setCalendarType('years'); } }, { children: [nameOfMonth, " ", year] })), _jsx("div", __assign({ className: clsx(styles.MonthSwitcherRightArrow, isDisableArrow('right') && styles.disabled), onClick: function () { return handleChangeMonth('next'); } }, { children: _jsx(ArrowRight, {}) }))] })));
};
