var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { BaseWrapper } from '@core/components/baseWrapper';
import ButtonContact from '@core/components/buttons/buttonContacts';
import { showModal } from '@core/components/modals';
import { ModalContacts } from '@core/components/modals/contacts';
import { PageTitle } from '@core/components/pageTitle';
import Mail from '@core/shared/svg/mail';
import Phone from '@core/shared/svg/phone';
import Plus from '@core/shared/svg/plus';
import { loaderType } from '@core/store/reducers/loadingSlice';
import { deleteAccountReq, getInfoAll } from '@core/store/reducers/profileSlice';
import { resetCodeInfo } from '@core/store/reducers/verificationSlice';
import styles from './index.module.sass';
var mapState = function (state) { return ({
    profile: state.profile,
    loading: state.loading,
}); };
export var Contacts = function () {
    var nav = useNavigate();
    var dispatch = useDispatch();
    var _a = useSelector(mapState), profile = _a.profile, loading = _a.loading;
    var deleting = function (type, id) {
        dispatch(deleteAccountReq({
            id: id,
            type: type,
            callback: function () { return nav(routePaths.DELETE_CONTACT); },
        }));
    };
    useEffect(function () {
        dispatch(getInfoAll());
        dispatch(resetCodeInfo());
    }, [dispatch]);
    var mainContact = profile.accounts.find(function (elem) { return !elem.deleting; });
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInnerMid: true }, { children: [_jsx(PageTitle, { text: t('label_contacts'), hasBackButton: true }), _jsx("div", __assign({ className: styles.contacts }, { children: _jsxs("div", __assign({ className: styles.contactsElements }, { children: [loading.type === loaderType.GET_DATA && loading.isLoading && (_jsx("div", __assign({ className: styles.contactsLoader }, { children: _jsx("div", { className: clsx(styles.bubbleLoader) }) }))), _jsxs(ButtonContact, __assign({ className: styles.contactsElement, handleDelete: function () { return deleting('phone', mainContact.id); }, isDeleting: mainContact.deleting, handleClick: function () { }, loading: loading.type === mainContact.type && loading.isLoading }, { children: [_jsx(Phone, {}), mainContact.identifier] })), loading.type !== loaderType.GET_DATA &&
                                profile.accounts.map(function (elem) {
                                    switch (elem.type) {
                                        case 'phone':
                                            return (elem.deleting && (_jsxs(ButtonContact, __assign({ className: styles.contactsElement, handleDelete: function () { return deleting('phone', elem.id); }, isDeleting: elem.deleting, handleClick: function () { }, loading: loading.type === 'profile/deleteAccountReq' && loading.isLoading }, { children: [_jsx(Phone, {}), elem.identifier] }), elem.identifier)));
                                        case 'email':
                                            return (elem.deleting && (_jsxs(ButtonContact, __assign({ className: styles.contactsElement, handleDelete: function () { return deleting('email', elem.id); }, handleClick: function () { }, isDeleting: elem.deleting, loading: loading.type === 'profile/deleteAccountReq' && loading.isLoading }, { children: [_jsx(Mail, {}), elem.identifier] }), elem.identifier)));
                                        default:
                                            return null;
                                    }
                                }), _jsxs(ButtonContact, __assign({ className: styles.contactsElement, handleClick: function () {
                                    return showModal(ModalContacts, {
                                        handle: function (type) {
                                            return nav(routePaths.CONTACTS + 'add/' + type, { replace: true });
                                        },
                                        isBottomMobile: true,
                                    });
                                }, add: true }, { children: [_jsx(Plus, {}), t('btn_contact')] }))] })) }))] })) })));
};
