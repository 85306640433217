var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import { BaseWrapper } from '@core/components/baseWrapper';
import { Button } from '@core/components/buttons/button';
import { Input } from '@core/components/formComponents/input';
import { PageTitle } from '@core/components/pageTitle';
import { Timer } from '@core/components/timer';
import { removeError } from '@core/store/reducers/errorsSlice';
import { setPassPosition, updatePasswordReq } from '@core/store/reducers/profileSlice';
import { verificationRequest } from '@core/store/reducers/verificationSlice';
import { passFlow } from './config';
import styles from './index.module.sass';
var mapState = function (state) { return ({
    auth: state.auth,
    errorsStore: state.errors,
    profile: state.profile,
    loading: state.loading,
    codeStore: state.code,
}); };
export var UpdatePassword = function () {
    var _a = useForm(), register = _a.register, unregister = _a.unregister, errors = _a.formState.errors, control = _a.control, setValue = _a.setValue, getValues = _a.getValues, trigger = _a.trigger, clearErrors = _a.clearErrors, setError = _a.setError;
    var _b = useState([]), innerLoading = _b[0], setInnerLoading = _b[1];
    var nav = useNavigate();
    var dispatch = useDispatch();
    var _c = useSelector(mapState), errorsStore = _c.errorsStore, profile = _c.profile, loading = _c.loading, codeStore = _c.codeStore;
    var isPhoneAccount = useMemo(function () { var _a, _b; return ((_b = (_a = profile === null || profile === void 0 ? void 0 : profile.accounts) === null || _a === void 0 ? void 0 : _a.find(function (acc) { return acc.deleting === false; })) === null || _b === void 0 ? void 0 : _b.type) === 'phone'; }, [profile]);
    var actionCallbacks = {
        setPosition: function () { return dispatch(setPassPosition('initial')); },
        sendPassword: function (params) { return __awaiter(void 0, void 0, void 0, function () {
            var newPass;
            return __generator(this, function (_a) {
                newPass = getValues('password');
                dispatch(updatePasswordReq({ newPass: newPass, params: params }));
                return [2 /*return*/];
            });
        }); },
        resetField: function (params) {
            dispatch(removeError(params.field));
            setValue(params.field, '');
        },
        sendCode: function () { return __awaiter(void 0, void 0, void 0, function () {
            var code, verificationCallback;
            return __generator(this, function (_a) {
                code = getValues('code');
                verificationCallback = function () {
                    dispatch(setPassPosition('done'));
                };
                dispatch(verificationRequest({
                    id: codeStore.verification_id,
                    code: code,
                    disableUpdateInfo: true,
                    callback: verificationCallback,
                }));
                return [2 /*return*/];
            });
        }); },
        exit: function () { return nav(-1); },
    };
    var callAction = function (actions) {
        if (actions && actions.length) {
            actions.map(function (action) { return __awaiter(void 0, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = action.field;
                            if (!_a) return [3 /*break*/, 2];
                            return [4 /*yield*/, trigger(action.field)];
                        case 1:
                            _a = !(_b.sent());
                            _b.label = 2;
                        case 2:
                            if (_a) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, actionCallbacks[action.name](action.params)];
                        case 3: return [2 /*return*/, _b.sent()];
                    }
                });
            }); });
        }
    };
    useEffect(function () {
        for (var key in errorsStore) {
            if (Object.prototype.hasOwnProperty.call(errorsStore, key)) {
                var element = errorsStore[key];
                if (element)
                    setError(key, { type: 'pattern', message: element });
            }
        }
    }, [errorsStore, setError]);
    useEffect(function () {
        if (profile.passUpd.position === 'initial') {
            setValue('code', '');
            clearErrors('code');
        }
    }, [profile.passUpd.position, clearErrors, setValue]);
    useEffect(function () {
        return function () {
            dispatch(setPassPosition('initial'));
            clearErrors();
            dispatch(removeError('code'));
            dispatch(removeError('password'));
        };
    }, [clearErrors, dispatch]);
    useEffect(function () {
        if (!loading.isLoading && innerLoading.length) {
            setInnerLoading([]);
        }
    }, [loading.isLoading]);
    return (_jsx(BaseWrapper, __assign({ whiteWrapper: true }, { children: _jsxs(BaseWrapper, __assign({ whiteWrapperInnerMid: true }, { children: [_jsx(PageTitle, { text: t('label_password_update'), hasBackButton: true }), _jsx("div", __assign({ className: styles.Password }, { children: _jsx("div", __assign({ className: styles.PasswordContent }, { children: passFlow[profile.passUpd.position].components.map(function (element) {
                            var _a, _b;
                            if (element.onlyForPhoneAccounts && !isPhoneAccount)
                                return null;
                            switch (element.type) {
                                case 'input':
                                    return (_jsx(Input, __assign({ id: element.label, label: t(element.label), placeholder: t(element.input.placeholder), type: ((_a = element.input) === null || _a === void 0 ? void 0 : _a.type) || element.type, control: control, register: register, clearErrors: clearErrors, unregister: unregister, error: errors[element.label], setEmpty: function () {
                                            setValue(element.label, '');
                                            callAction(element.actions);
                                        }, setValue: setValue }, element.input, { validation: __assign(__assign({}, (_b = element.input) === null || _b === void 0 ? void 0 : _b.validation), { onChange: function () {
                                                clearErrors(element.label);
                                                callAction(element.actions);
                                            } }) }), element.label));
                                case 'button':
                                    return (_jsx(Button, __assign({ label: t(element.label), handler: function () { return callAction(element.actions); }, loading: loading.type === element.loading, classes: [styles.PasswordBtn], isEnterHandle: element.isEnterHandle }, element.button), element.label));
                                case 'timer':
                                    return (_jsx(Timer, { handle: function () { return callAction(element.actions); }, classes: [styles.PasswordTimer], type: element.timerFor }, element.label));
                                case 'text':
                                    return (_jsx("div", __assign({ className: clsx([
                                            styles.PasswordText,
                                            element.label === 'codeText' && styles.PasswordTextCode,
                                        ]) }, { children: t(element.text) }), element.label));
                                case 'changeMethod':
                                    return (_jsx("div", __assign({ className: styles.changeMethod }, { children: innerLoading.some(function (el) { return el === 'changeMethod'; }) &&
                                            loading.type === element.loading &&
                                            loading.isLoading ? (_jsx("div", { className: styles.bubbleLoader })) : (_jsxs("div", __assign({ className: styles.changeMethodInner }, { children: [element.preLabel && _jsxs("span", { children: [t(element.preLabel), "\u00A0"] }), _jsx("span", __assign({ className: clsx(styles.changeMethodBtn), onClick: function () {
                                                        setInnerLoading(['changeMethod']);
                                                        callAction(element.actions);
                                                    } }, { children: t(element.label) }))] }))) }), element.label));
                                default:
                                    return null;
                            }
                        }) })) }))] })) })));
};
