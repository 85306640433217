import { WebViewMessageScreens, WebViewMessageSource, } from '@core/shared/types/webView';
export var isReactNativeWebView = Boolean(window.ReactNativeWebView);
export var changeAppScreenToOrderTest = function () {
    var message = {
        source: WebViewMessageSource.LabqWebViewGoTo,
        payload: {
            screen: WebViewMessageScreens.BookAppointment,
            params: { backRoute: WebViewMessageScreens.QSureScreen },
        },
    };
    webViewPostMessage(message);
};
export var changeAppScreenToMyHealsRecords = function () {
    var message = {
        source: WebViewMessageSource.LabqWebViewGoTo,
        payload: {
            screen: WebViewMessageScreens.MedicalHistoryScreen,
            params: { backRoute: WebViewMessageScreens.QSureScreen },
        },
    };
    webViewPostMessage(message);
};
export var changeAppScreenToEvents = function () {
    var message = {
        source: WebViewMessageSource.LabqWebViewGoTo,
        payload: {
            screen: WebViewMessageScreens.ViewAllEventsScreen,
            params: { backRoute: WebViewMessageScreens.QSureScreen },
        },
    };
    webViewPostMessage(message);
};
export var changeAppScreenToShop = function () {
    var message = {
        source: WebViewMessageSource.LabqWebViewGoTo,
        payload: {
            screen: WebViewMessageScreens.ShopAllTests,
            params: { backRoute: WebViewMessageScreens.QSureScreen },
        },
    };
    webViewPostMessage(message);
};
export var changeAppScreenToMyAlarms = function () {
    var message = {
        source: WebViewMessageSource.LabqWebViewGoTo,
        payload: {
            screen: WebViewMessageScreens.MedicalHistoryScreen,
            params: { isTrendingBiomarkers: true, backRoute: WebViewMessageScreens.QSureScreen },
        },
    };
    webViewPostMessage(message);
};
export var createSeasonTestInApp = function () {
    var message = {
        source: WebViewMessageSource.LabqWebViewGoTo,
        payload: {
            screen: WebViewMessageScreens.HomeScreen,
            params: { clickSeason: true },
        },
    };
    webViewPostMessage(message);
};
export var createOrderByMedicalCodesInApp = function (medicalCodes) {
    var message = {
        source: WebViewMessageSource.LabqWebViewGoTo,
        payload: {
            screen: WebViewMessageScreens.HomeScreen,
            params: { createOrderByMedicalCodes: true, medicalCodes: medicalCodes },
        },
    };
    webViewPostMessage(message);
};
export var changeAppScreenToHome = function () {
    var message = {
        source: WebViewMessageSource.LabqWebViewGoTo,
        payload: {
            screen: WebViewMessageScreens.HomeScreen,
        },
    };
    webViewPostMessage(message);
};
export var changeAppScreenToLocations = function () {
    var message = {
        source: WebViewMessageSource.LabqWebViewGoTo,
        payload: {
            screen: WebViewMessageScreens.OfficesNearbyScreen,
            params: { backRoute: WebViewMessageScreens.QSureScreen },
        },
    };
    webViewPostMessage(message);
};
export var setToAppAsyncStorage = function (_a) {
    var key = _a.key, value = _a.value;
    var message = {
        source: WebViewMessageSource.LabqWebViewSetToAsyncStorage,
        payload: {
            key: key,
            value: value,
        },
    };
    webViewPostMessage(message);
};
export var webViewConsoleLog = function (log) {
    var message = {
        source: WebViewMessageSource.LabqWebViewConsoleLog,
        payload: log,
    };
    webViewPostMessage(message);
};
var webViewPostMessage = function (message) {
    if (!message || !isReactNativeWebView)
        return console.log('WebViewMessage error');
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
};
