var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var DocApproved = function () { return (_jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.7142 8.27054L15.8387 3.42847H4.28564V20.5713H19.7142V8.27054ZM7.10812 11.3108C7.44286 10.976 7.98557 10.976 8.32031 11.3108L11.2912 14.2817L15.8934 9.67952C16.2282 9.34478 16.7709 9.34478 17.1056 9.67952C17.4403 10.0143 17.4403 10.557 17.1056 10.8917L11.2912 16.7061L7.10812 12.523C6.77339 12.1882 6.77339 11.6455 7.10812 11.3108Z", fill: "#4CD964" }) }))); };
export default DocApproved;
