var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Title } from '@core/components/title';
import ArrowRight from '@core/shared/svg/arrow-right';
import styles from './index.module.sass';
export var Questions = function (_a) {
    var closeModal = _a.closeModal;
    var nav = useNavigate();
    var closePage = function () {
        nav('/');
        closeModal();
    };
    return (_jsxs("div", __assign({ className: styles.question }, { children: [_jsxs("div", __assign({ className: styles.questionHeader }, { children: [_jsx("div", __assign({ className: styles.questionHeaderBack, onClick: closePage }, { children: _jsx(ArrowRight, {}) })), _jsx(Title, { classes: [styles.title], title: "Questions and Answers", exLink: false })] })), _jsx("div", __assign({ className: styles.questionContent }, { children: "Question 1" }))] })));
};
