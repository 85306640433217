var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import createSagaMiddleware from 'redux-saga';
import accountMergeSlice from '@core/store/reducers/accountMergeSlice';
import alertSlice from '@core/store/reducers/alertSlice';
import authSlice from '@core/store/reducers/authSlice';
import bookingSlice from '@core/store/reducers/bookingSlice';
import docsSlice from '@core/store/reducers/docsSlice';
import doctorVisitSlice from '@core/store/reducers/doctorVisitSlice';
import errorsSlice from '@core/store/reducers/errorsSlice';
import eventsSlice from '@core/store/reducers/eventsSlice';
import helpersSlice from '@core/store/reducers/helpers';
import loadingSlice from '@core/store/reducers/loadingSlice';
import mapSlice from '@core/store/reducers/mapSlice';
import newOrder from '@core/store/reducers/new-order';
import paymentSlice from '@core/store/reducers/paymentsSlice';
import prescriptionSlice from '@core/store/reducers/prescription';
import profileSlice from '@core/store/reducers/profileSlice';
import reportsSlice from '@core/store/reducers/reportsSlice';
import sessionSlice from '@core/store/reducers/session';
import subscriptionSlice from '@core/store/reducers/subscriptionSlice';
import supportSlice from '@core/store/reducers/support';
import surveySlice from '@core/store/reducers/surveySlice';
import verificationSlice from '@core/store/reducers/verificationSlice';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import bookingNewSlice from './reducers/bookingNewSlice';
import shopSlice from './reducers/shopSlice';
var sagaMiddleware = createSagaMiddleware();
export var coreReducer = {
    auth: authSlice,
    loading: loadingSlice,
    alert: alertSlice,
    shop: shopSlice,
    errors: errorsSlice,
    profile: profileSlice,
    code: verificationSlice,
    map: mapSlice,
    survey: surveySlice,
    booking: bookingSlice,
    bookingNew: bookingNewSlice,
    accountMerge: accountMergeSlice,
    newOrder: newOrder,
    session: sessionSlice,
    support: supportSlice,
    docs: docsSlice,
    events: eventsSlice,
    helpers: helpersSlice,
    prescription: prescriptionSlice,
    reports: reportsSlice,
    payments: paymentSlice,
    subscriptions: subscriptionSlice,
    doctorVisit: doctorVisitSlice,
};
export var coreStore = configureStore({
    reducer: coreReducer,
    middleware: __spreadArray(__spreadArray([], getDefaultMiddleware({ thunk: false, serializableCheck: false }), true), [sagaMiddleware], false),
});
