var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { t } from 'i18next';
import { UserInfoMessage } from '@core/components/surveyMessages/userInfo';
import styles from './index.module.sass';
export var AccountMergeChecking = function () { return (_jsx("div", __assign({ className: styles.accountMergeChecking }, { children: _jsxs("div", { children: [_jsx(UserInfoMessage, {}), _jsx("p", __assign({ className: styles.accountMergeCheckingText }, { children: t('account_merge_checking') }))] }) }))); };
