var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    sameAccounts: false,
    fhirAccounts: false,
    currentAccount: '',
    currentAccountType: '',
    isAddAccount: false,
};
export var accountMergeSlice = createSlice({
    name: 'accountMerge',
    initialState: initialState,
    reducers: {
        getSameAccounts: function (_state, _action) { },
        setSameAccounts: function (state, action) {
            state.sameAccounts = action.payload;
        },
        getFhirAccounts: function (_state, _action) { },
        setFhirAccounts: function (state, action) {
            state.fhirAccounts = action.payload;
        },
        mergeSameAccount: function (_state, _action) { },
        mergeFhirAccount: function (_state, _action) { },
        setCurrentAccount: function (state, action) {
            state.currentAccount = action.payload;
        },
        setCurrentAccountType: function (state, action) {
            state.currentAccountType = action.payload;
        },
        resetCurrentAccount: function (state) {
            state.currentAccount = '';
            state.isAddAccount = false;
            state.currentAccountType = '';
        },
        successAddAccount: function (state, action) {
            state.isAddAccount = action.payload;
        },
        resetAccounts: function () { return initialState; },
    },
});
export var getSameAccounts = (_a = accountMergeSlice.actions, _a.getSameAccounts), setSameAccounts = _a.setSameAccounts, getFhirAccounts = _a.getFhirAccounts, setFhirAccounts = _a.setFhirAccounts, mergeSameAccount = _a.mergeSameAccount, mergeFhirAccount = _a.mergeFhirAccount, setCurrentAccount = _a.setCurrentAccount, setCurrentAccountType = _a.setCurrentAccountType, resetCurrentAccount = _a.resetCurrentAccount, successAddAccount = _a.successAddAccount, resetAccounts = _a.resetAccounts;
export default accountMergeSlice.reducer;
