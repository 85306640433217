var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import _ from 'lodash';
import { config as globalConfig } from '@core/config/config';
import authService from './auth';
import serviceSession from './session';
import TokenService from './tokens';
var REFRESH_TOKEN_URL = "".concat(globalConfig.authService, "/auth/refresh");
var REFRESH_SESSION_TOKEN_URL = "".concat(globalConfig.apiService, "/sessions/refresh");
var TokenRefresher = /** @class */ (function () {
    function TokenRefresher() {
        this.queue = [];
    }
    TokenRefresher.prototype.checkQueue = function () {
        return __awaiter(this, void 0, Promise, function () {
            var tokens, stack;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!TokenService.checkAccessToken()) return [3 /*break*/, 4];
                        if (!(process.env.REACT_APP_TYPE === 'supervisor' &&
                            localStorage.getItem('isSessionToken') === '1')) return [3 /*break*/, 2];
                        return [4 /*yield*/, serviceSession.refreshSession()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, authService.refresh()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        tokens = TokenService.getTokens();
                        stack = this.queue.splice(0, this.queue.length);
                        stack.forEach(function (req) { return req(tokens); });
                        return [2 /*return*/];
                }
            });
        });
    };
    TokenRefresher.prototype.getToken = function () {
        var _this = this;
        var promise = new Promise(function (resolve) {
            var isEmpty = _this.queue.length === 0;
            _this.queue.push(resolve);
            if (isEmpty) {
                _this.checkQueue();
            }
        });
        return promise;
    };
    return TokenRefresher;
}());
var isRefreshing = false;
var failedQueue = [];
var processQueue = function (error, tokenPair) {
    failedQueue.forEach(function (prom) {
        if (error) {
            prom.reject(error);
        }
        else {
            prom.resolve(tokenPair);
        }
    });
    failedQueue = [];
};
var refresherInstance = new TokenRefresher();
export var instance = axios.create({
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    },
});
instance.interceptors.request.use(function (config) { return __awaiter(void 0, void 0, void 0, function () {
    var tokens, tmp;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!TokenService.getAccessToken() ||
                    config.url === REFRESH_TOKEN_URL ||
                    config.url === REFRESH_SESSION_TOKEN_URL) {
                    return [2 /*return*/, config];
                }
                return [4 /*yield*/, refresherInstance.getToken()];
            case 1:
                tokens = _c.sent();
                if ((_a = config === null || config === void 0 ? void 0 : config.headers) === null || _a === void 0 ? void 0 : _a['isAccessToken']) {
                    delete config.headers['isAccessToken'];
                    config.headers['Authorization'] = 'Bearer ' + tokens.accessToken;
                }
                if ((_b = config.data) === null || _b === void 0 ? void 0 : _b.refresh_token) {
                    config.data.refresh_token = tokens.refreshToken;
                }
                else if (_.isString(config.data)) {
                    tmp = JSON.parse(config.data);
                    if (tmp.refresh_token) {
                        config.data = JSON.stringify(__assign(__assign({}, tmp), { refresh_token: tokens.refreshToken }));
                    }
                }
                return [2 /*return*/, config];
        }
    });
}); });
instance.interceptors.response.use(function (response) { return response; }, function (error) { return __awaiter(void 0, void 0, void 0, function () {
    var originalRequest, canRefresh, refresh;
    return __generator(this, function (_a) {
        originalRequest = error.config;
        canRefresh = error.response.status === 401 &&
            error.response.data.error === 'expired_signature_error' &&
            !originalRequest._retry &&
            originalRequest.url !== REFRESH_TOKEN_URL &&
            originalRequest.url !== REFRESH_SESSION_TOKEN_URL;
        if (!canRefresh)
            return [2 /*return*/, Promise.reject(error)];
        if (isRefreshing) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve: resolve, reject: reject });
                })
                    .then(function (tokenPair) {
                    var _a;
                    originalRequest.headers.Authorization = "Bearer ".concat(tokenPair.accessToken);
                    if ((_a = originalRequest.data) === null || _a === void 0 ? void 0 : _a.refresh_token) {
                        originalRequest.data.refresh_token = tokenPair.refreshToken;
                    }
                    else if (_.isString(originalRequest.data)) {
                        var tmp = JSON.parse(originalRequest.data);
                        if (tmp.refresh_token) {
                            originalRequest.data = JSON.stringify(__assign(__assign({}, tmp), { refresh_token: tokenPair.refreshToken }));
                        }
                    }
                    return instance(originalRequest);
                })
                    .catch(function (err) {
                    return Promise.reject(err);
                })];
        }
        originalRequest._retry = true;
        isRefreshing = true;
        refresh = function () {
            return process.env.REACT_APP_TYPE === 'supervisor' &&
                localStorage.getItem('isSessionToken') === '1'
                ? serviceSession.refreshSession
                : authService.refresh;
        };
        return [2 /*return*/, new Promise(function (resolve, reject) {
                refresh()()
                    .then(function () {
                    var _a;
                    var tokens = TokenService.getTokens();
                    instance.defaults.headers.common.Authorization = "Bearer ".concat(tokens.accessToken);
                    originalRequest.headers.Authorization = "Bearer ".concat(tokens.accessToken);
                    if ((_a = originalRequest.data) === null || _a === void 0 ? void 0 : _a.refresh_token) {
                        originalRequest.data.refresh_token = tokens.refreshToken;
                    }
                    else if (_.isString(originalRequest.data)) {
                        var tmp = JSON.parse(originalRequest.data);
                        if (tmp.refresh_token) {
                            originalRequest.data = JSON.stringify(__assign(__assign({}, tmp), { refresh_token: tokens.refreshToken }));
                        }
                    }
                    processQueue(null, {
                        accessToken: tokens.accessToken,
                        refreshToken: tokens.refreshToken,
                    });
                    resolve(instance(originalRequest));
                })
                    .catch(function (err) {
                    processQueue(err, {});
                    TokenService.clearToken();
                    reject(err);
                })
                    .then(function () {
                    isRefreshing = false;
                });
            })];
    });
}); });
