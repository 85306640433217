var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { t } from 'i18next';
import { Button } from '@core/components/buttons/button';
import { Checkbox } from '@core/components/formComponents/checkbox';
import { Input } from '@core/components/formComponents/input';
import { Title } from '@core/components/title';
import { config } from '@core/config/config';
import Common from '@core/services/common';
import { useDebounce } from '@core/shared/hooks/useDebounce';
import ArrowRight from '@core/shared/svg/arrow-right';
import SearchIcon from '@core/shared/svg/search';
import { capitalizeSentence } from '@core/shared/utils/stringUtils';
import { setAlert } from '@core/store/reducers/alertSlice';
import styles from './index.module.sass';
var LIMIT = 15;
var DEBOUNCE_DELAY = 300;
var MAX_SYMBOLS = 160;
export var AutocompleteFullscreenModal = function (_a) {
    var _b, _c;
    var title = _a.title, closeModal = _a.closeModal, _d = _a.handleChange, handleChange = _d === void 0 ? function () { } : _d, autocompleteData = _a.autocompleteData, value = _a.value, useAsIs = _a.useAsIs;
    var dispatch = useDispatch();
    var search = useForm({
        defaultValues: {
            search: (value === null || value === void 0 ? void 0 : value.name) || '',
        },
    });
    var searchValue = search.watch('search');
    var debouncedSearch = useDebounce(searchValue, DEBOUNCE_DELAY);
    var _e = useState(true), loadingTotal = _e[0], setLoadingTotal = _e[1];
    var _f = useState(false), loadingListAdditional = _f[0], setLoadingListAdditional = _f[1];
    var _g = useState(null), searchedData = _g[0], setSearchedData = _g[1];
    var _h = useState(null), innerValue = _h[0], setInnerValue = _h[1];
    var _j = useState(null), useAsIsValue = _j[0], setUseAsIsValue = _j[1];
    var itemsListRef = useRef();
    var fetchSearchedData = function (_a) {
        var value = _a.value, skip = _a.skip, isAdditional = _a.isAdditional;
        return __awaiter(void 0, void 0, void 0, function () {
            var url, method, service, replaceService, replaceUrl, preparedUrl, _b, data, e_1;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        !isAdditional && setLoadingTotal(false);
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 5, 6, 7]);
                        url = autocompleteData.url, method = autocompleteData.method, service = autocompleteData.service;
                        replaceService = (_c = {},
                            _c['ziphy-qapi'] = config.apiService,
                            _c);
                        replaceUrl = url.replace('{:provider_name}', value);
                        preparedUrl = replaceService[service] + replaceUrl + (skip ? "&skip=".concat(skip) : '') + "&limit=".concat(LIMIT);
                        !isAdditional && setLoadingTotal(true);
                        _b = method;
                        switch (_b) {
                            case 'get': return [3 /*break*/, 2];
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, Common.get(preparedUrl)];
                    case 3:
                        data = (_d.sent()).data;
                        return [2 /*return*/, data];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        e_1 = _d.sent();
                        dispatch(setAlert(t('error_unknown')));
                        return [3 /*break*/, 7];
                    case 6:
                        !isAdditional && setLoadingTotal(false);
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    var addAdditionalItems = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newSearchedData_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, , 2, 3]);
                    setLoadingListAdditional(true);
                    return [4 /*yield*/, fetchSearchedData({
                            value: debouncedSearch || '',
                            skip: searchedData.skip + searchedData.limit,
                            isAdditional: true,
                        })];
                case 1:
                    newSearchedData_1 = _a.sent();
                    setSearchedData(function (prev) { return (__assign(__assign({}, newSearchedData_1), { results: __spreadArray(__spreadArray([], prev.results, true), newSearchedData_1.results, true) })); });
                    return [3 /*break*/, 3];
                case 2:
                    setLoadingListAdditional(false);
                    return [7 /*endfinally*/];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var stopScroll = function () {
        if (itemsListRef.current.style) {
            itemsListRef.current.style.overflowY = 'hidden';
            setTimeout(function () {
                itemsListRef.current.style.overflowY = 'scroll';
            });
        }
    };
    var handleItemsScroll = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var scrollTop, scrollHeight, clientHeight, elementsLength, scrollPositionIsBottom, isEnd;
        var _a, _b;
        return __generator(this, function (_c) {
            scrollTop = e.target.scrollTop;
            scrollHeight = e.target.scrollHeight;
            clientHeight = e.target.clientHeight;
            elementsLength = (_a = searchedData === null || searchedData === void 0 ? void 0 : searchedData.results) === null || _a === void 0 ? void 0 : _a.length;
            scrollPositionIsBottom = Math.round(scrollHeight - scrollTop) <= Math.round(clientHeight + 20);
            isEnd = (searchedData === null || searchedData === void 0 ? void 0 : searchedData.total) === ((_b = searchedData === null || searchedData === void 0 ? void 0 : searchedData.results) === null || _b === void 0 ? void 0 : _b.length);
            if (elementsLength && scrollPositionIsBottom && !loadingListAdditional && !isEnd) {
                stopScroll();
                addAdditionalItems();
            }
            return [2 /*return*/];
        });
    }); };
    var setValue = function (value) {
        if (useAsIs && useAsIsValue !== null) {
            setUseAsIsValue(null);
        }
        setInnerValue(value);
    };
    var setUseAsIs = function (value) {
        if (innerValue !== null) {
            setInnerValue(null);
        }
        setUseAsIsValue({
            name: value,
        });
    };
    var handleSelect = function () {
        if (useAsIs && useAsIsValue) {
            handleChange(useAsIsValue);
            return closeModal();
        }
        if (innerValue) {
            handleChange(innerValue);
            return closeModal();
        }
    };
    useEffect(function () {
        if (value) {
            if (useAsIs && !value._id) {
                setUseAsIsValue(value);
            }
            else {
                setValue(value);
            }
        }
        if (!value) {
            setLoadingTotal(false);
        }
    }, [value]);
    useEffect(function () {
        if (useAsIs && useAsIsValue !== null) {
            setUseAsIsValue(null);
        }
    }, [debouncedSearch]);
    var checkSearchLength = function () {
        if ((debouncedSearch === null || debouncedSearch === void 0 ? void 0 : debouncedSearch.length) > MAX_SYMBOLS) {
            search.setError('search', { message: "Max length ".concat(MAX_SYMBOLS, " characters.") });
        }
        else {
            search.clearErrors();
        }
    };
    useEffect(function () {
        var getSearchedData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var searchedData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchSearchedData({
                            value: debouncedSearch || '',
                        })];
                    case 1:
                        searchedData = _a.sent();
                        checkSearchLength();
                        setSearchedData(searchedData);
                        return [2 /*return*/];
                }
            });
        }); };
        getSearchedData();
    }, [debouncedSearch]);
    var totalEqualName = useMemo(function () { var _a; return (_a = searchedData === null || searchedData === void 0 ? void 0 : searchedData.results) === null || _a === void 0 ? void 0 : _a.some(function (item) { return item.name === debouncedSearch; }); }, [searchedData, debouncedSearch]);
    return (_jsx("div", __assign({ className: styles.Select }, { children: _jsxs("div", __assign({ className: styles.container }, { children: [_jsxs("div", __assign({ className: styles.SelectHeader }, { children: [_jsx("div", __assign({ className: styles.SelectHeaderBack, onClick: closeModal }, { children: _jsx(ArrowRight, {}) })), _jsx(Title, { classes: [styles.title], title: capitalizeSentence(title), exLink: false })] })), _jsx(Input, { error: (_c = (_b = search === null || search === void 0 ? void 0 : search.formState) === null || _b === void 0 ? void 0 : _b.errors) === null || _c === void 0 ? void 0 : _c['search'], classes: [styles.SelectSearch], label: 'search', type: "text", icon: _jsx(SearchIcon, {}), setEmpty: function () { return search.setValue('search', ''); }, placeholder: 'Search ' + capitalizeSentence(title), register: search.register, clearErrors: search.clearErrors, control: search.control, unregister: search.unregister }), loadingTotal ? (_jsx("div", __assign({ className: styles.bubbleLoaderWrapper }, { children: _jsx("div", { className: styles.bubbleLoader }) }))) : (_jsxs("div", __assign({ ref: itemsListRef, className: styles.SelectItems, onScroll: handleItemsScroll }, { children: [!debouncedSearch && !loadingTotal && (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: styles.SelectMessage }, { children: t('label_start_enter_to_see_suggestions') })), _jsx("p", __assign({ className: styles.SelectMessagePopular }, { children: t('label_popular_providers') }))] })), searchedData &&
                            (searchedData.results.length ? (_jsxs(_Fragment, { children: [searchedData.results.map(function (dataItem, index) {
                                        return (_jsx(Checkbox, { label: dataItem.name, value: dataItem, onChange: function (_, value) {
                                                setValue(value);
                                            }, checked: (innerValue === null || innerValue === void 0 ? void 0 : innerValue._id) === dataItem._id }, index));
                                    }), (searchedData === null || searchedData === void 0 ? void 0 : searchedData.results.length) < searchedData.total ? (_jsx("div", __assign({ className: clsx(styles.SelectItemsAdditionalLoading, !loadingListAdditional && styles.hide) }, { children: _jsx("div", { className: styles.bubbleLoader }) }))) : (!!useAsIs &&
                                        !!debouncedSearch &&
                                        !totalEqualName && (_jsx(Checkbox, { preLabel: 'Use as is: ', label: debouncedSearch, value: '', onChange: function () { return setUseAsIs(debouncedSearch); }, checked: useAsIsValue })))] })) : (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: styles.SelectMessage }, { children: t('label_not_found') })), !useAsIs || (debouncedSearch === null || debouncedSearch === void 0 ? void 0 : debouncedSearch.length) > MAX_SYMBOLS ? null : (_jsx(Checkbox, { preLabel: 'Use as is: ', label: debouncedSearch, value: '', onChange: function () { return setUseAsIs(debouncedSearch); }, checked: useAsIsValue }))] })))] }))), _jsxs("div", __assign({ className: styles.SelectButtons }, { children: [_jsx(Button, { classes: [styles.SelectButton], disabled: !innerValue && !useAsIsValue, handler: handleSelect, primary: true, label: "Select" }), _jsx(Button, { classes: [styles.SelectButton], handler: function () { return closeModal(); }, label: "Cancel" })] }))] })) })));
};
