var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    inputValue: '',
    markersOptions: [],
    offices: [],
    replace: false,
    address: {
        name: 'New York, NY, USA',
        adr: 'New York, NY, USA',
    },
    currState: 'NY',
};
export var mapSlice = createSlice({
    name: 'map',
    initialState: initialState,
    reducers: {
        setInputValue: function (state, action) {
            state.inputValue = action.payload;
        },
        setMarkersOptions: function (state, action) {
            state.markersOptions = action.payload.options;
            state.replace = action.payload.replace;
        },
        setAddress: function (state, action) {
            state.address = action.payload;
        },
        getOfficesNearPoint: function (_state, _action) { },
        setOffices: function (state, action) {
            state.offices = action.payload;
        },
        getOfficesByProcedures: function (_state, _action) { },
        setOfficesByProcedures: function (state, action) {
            state.offices = action.payload;
        },
        resetOffices: function (state) { return (__assign(__assign({}, state), { offices: initialState.offices })); },
        setCurrState: function (state, action) {
            state.currState = action.payload;
        },
        getDetail: function (_state, _action) { },
        setDetail: function (state, action) {
            state.detail = action.payload;
        },
        resetDetail: function (state) { return (__assign(__assign({}, state), { detail: initialState.detail })); },
    },
});
export var setInputValue = (_a = mapSlice.actions, _a.setInputValue), setMarkersOptions = _a.setMarkersOptions, setAddress = _a.setAddress, setCurrState = _a.setCurrState, getOfficesNearPoint = _a.getOfficesNearPoint, setOffices = _a.setOffices, resetOffices = _a.resetOffices, getOfficesByProcedures = _a.getOfficesByProcedures, setOfficesByProcedures = _a.setOfficesByProcedures, getDetail = _a.getDetail, setDetail = _a.setDetail, resetDetail = _a.resetDetail;
export default mapSlice.reducer;
