export var plansKit = {
    silver: [
        {
            id: 1,
            isPresent: true,
            text: 'Laboratory Discount (15%)',
        },
        {
            id: 2,
            isPresent: true,
            text: 'Health Risk Assessment',
        },
        {
            id: 3,
            isPresent: true,
            text: 'Online Knowledge Base',
        },
        {
            id: 4,
            isPresent: false,
            text: 'Family Telemedicine',
        },
        {
            id: 5,
            isPresent: false,
            text: 'Health Coaching',
        },
        {
            id: 6,
            isPresent: false,
            text: 'Fitness and Nutrition',
        },
        {
            id: 7,
            isPresent: false,
            text: 'Prescription drugs Discount',
        },
        {
            id: 8,
            isPresent: false,
            text: 'Concierge medical at home service',
        },
        {
            id: 9,
            isPresent: false,
            text: 'Behavioral Health',
        },
        {
            id: 10,
            isPresent: false,
            text: 'Annual check-up',
        },
        {
            id: 11,
            isPresent: false,
            text: 'Mental Health',
        },
    ],
    gold: [
        {
            id: 1,
            isPresent: true,
            text: 'Laboratory Discount (20%)',
        },
        {
            id: 2,
            isPresent: true,
            text: 'Health Risk Assessment',
        },
        {
            id: 3,
            isPresent: true,
            text: 'Online Knowledge Base',
        },
        {
            id: 4,
            isPresent: true,
            text: 'Family Telemedicine',
        },
        {
            id: 5,
            isPresent: true,
            text: 'Health Coaching',
        },
        {
            id: 6,
            isPresent: true,
            text: 'Fitness and Nutrition',
        },
        {
            id: 7,
            isPresent: false,
            text: 'Prescription drugs Discount',
        },
        {
            id: 8,
            isPresent: false,
            text: 'Concierge medical at home service',
        },
        {
            id: 9,
            isPresent: false,
            text: 'Behavioral Health',
        },
        {
            id: 10,
            isPresent: false,
            text: 'Annual check-up',
        },
        {
            id: 11,
            isPresent: false,
            text: 'Mental Health',
        },
    ],
    platinum: [
        {
            id: 1,
            isPresent: true,
            text: 'Laboratory Discount (25%)',
        },
        {
            id: 2,
            isPresent: true,
            text: 'Health Risk Assessment',
        },
        {
            id: 3,
            isPresent: true,
            text: 'Online Knowledge Base',
        },
        {
            id: 4,
            isPresent: true,
            text: 'Family Telemedicine',
        },
        {
            id: 5,
            isPresent: true,
            text: 'Health Coaching',
        },
        {
            id: 6,
            isPresent: true,
            text: 'Fitness and Nutrition',
        },
        {
            id: 7,
            isPresent: true,
            text: 'Prescription drugs Discount',
        },
        {
            id: 8,
            isPresent: true,
            text: 'Concierge medical at home service',
        },
        {
            id: 9,
            isPresent: false,
            text: 'Behavioral Health',
        },
        {
            id: 10,
            isPresent: false,
            text: 'Annual check-up',
        },
        {
            id: 11,
            isPresent: false,
            text: 'Mental Health',
        },
    ],
    diamond: [
        {
            id: 1,
            isPresent: true,
            text: 'Laboratory Discount (30%)',
        },
        {
            id: 2,
            isPresent: true,
            text: 'Health Risk Assessment',
        },
        {
            id: 3,
            isPresent: true,
            text: 'Online Knowledge Base',
        },
        {
            id: 4,
            isPresent: true,
            text: 'Family Telemedicine',
        },
        {
            id: 5,
            isPresent: true,
            text: 'Health Coaching',
        },
        {
            id: 6,
            isPresent: true,
            text: 'Fitness and Nutrition',
        },
        {
            id: 7,
            isPresent: true,
            text: 'Prescription drugs Discount',
        },
        {
            id: 8,
            isPresent: true,
            text: 'Concierge medical at home service',
        },
        {
            id: 9,
            isPresent: true,
            text: 'Behavioral Health',
        },
        {
            id: 10,
            isPresent: true,
            text: 'Annual check-up',
        },
        {
            id: 11,
            isPresent: true,
            text: 'Mental Health',
        },
    ],
};
