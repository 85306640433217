var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isLoading: true,
    downloadList: [],
};
export var reportsSlice = createSlice({
    name: 'reports',
    initialState: initialState,
    reducers: {
        getDownloads: function (state) {
            state.isLoading = true;
        },
        getDownloadSuccess: function (state, action) {
            state.downloadList = action.payload;
            state.isLoading = false;
        },
        getDownloadFail: function (state) {
            state.isLoading = false;
        },
    },
});
export var getDownloads = (_a = reportsSlice.actions, _a.getDownloads), getDownloadSuccess = _a.getDownloadSuccess, getDownloadFail = _a.getDownloadFail;
export default reportsSlice.reducer;
