var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Shop = function () { return (_jsxs("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 7V20C8 20.5523 8.44772 21 9 21C9.55228 21 10 20.5523 10 20V7H8ZM7 5C6.44772 5 6 5.44772 6 6V20C6 21.6569 7.34315 23 9 23C10.6569 23 12 21.6569 12 20V6C12 5.44772 11.5523 5 11 5H7Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18 7V20C18 20.5523 18.4477 21 19 21C19.5523 21 20 20.5523 20 20V7H18ZM17 5C16.4477 5 16 5.44772 16 6V20C16 21.6569 17.3431 23 19 23C20.6569 23 22 21.6569 22 20V6C22 5.44772 21.5523 5 21 5H17Z", fill: "black" }), _jsx("rect", { x: "17", y: "9", width: "4", height: "2", fill: "black" }), _jsx("path", { d: "M5 6C5 5.44772 5.44772 5 6 5H12C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7H6C5.44772 7 5 6.55228 5 6Z", fill: "black" }), _jsx("path", { d: "M15 6C15 5.44772 15.4477 5 16 5H22C22.5523 5 23 5.44772 23 6C23 6.55228 22.5523 7 22 7H16C15.4477 7 15 6.55228 15 6Z", fill: "black" }), _jsx("rect", { x: "7", y: "14", width: "4", height: "2", fill: "black" })] }))); };
export default Shop;
