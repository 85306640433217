var _a;
import { DoctorVisitScreens } from '@core/shared/types/sick';
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    currentScreen: DoctorVisitScreens.SelectPatient,
    selectedPatientId: null,
    selectedReason: null,
};
export var doctorVisitSlice = createSlice({
    name: 'doctorVisit',
    initialState: initialState,
    reducers: {
        setCurrentScreen: function (state, action) {
            state.currentScreen = action.payload;
        },
        setSelectedPatientId: function (state, action) {
            state.selectedPatientId = action.payload;
        },
        setSelectedReason: function (state, action) {
            state.selectedReason = action.payload;
        },
    },
});
export var setCurrentScreen = (_a = doctorVisitSlice.actions, _a.setCurrentScreen), setSelectedPatientId = _a.setSelectedPatientId, setSelectedReason = _a.setSelectedReason;
export default doctorVisitSlice.reducer;
