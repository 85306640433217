export var COUNTRIES = [
    {
        iso2: 'ad',
        label: 'Andorra',
        labelNative: 'Andorra',
        languages: ['ca'],
        emergencyNumber: '',
        phone: {
            dialCode: '376',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ae',
        label: 'United Arab Emirates',
        labelNative: 'دولة الإمارات العربية المتحدة',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '971',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'af',
        label: 'Afghanistan',
        labelNative: 'افغانستان',
        languages: ['ps', 'uz', 'tk'],
        emergencyNumber: '',
        phone: {
            dialCode: '93',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ag',
        label: 'Antigua and Barbuda',
        labelNative: 'Antigua and Barbuda',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['268'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ai',
        label: 'Anguilla',
        labelNative: 'Anguilla',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['264'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'al',
        label: 'Albania',
        labelNative: 'Shqipëria',
        languages: ['sq'],
        emergencyNumber: '',
        phone: {
            dialCode: '355',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'am',
        label: 'Armenia',
        labelNative: 'Հայաստան',
        languages: ['hy', 'ru'],
        emergencyNumber: '',
        phone: {
            dialCode: '374',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ao',
        label: 'Angola',
        labelNative: 'Angola',
        languages: ['pt'],
        emergencyNumber: '',
        phone: {
            dialCode: '244',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'aq',
        label: 'Antarctica',
        labelNative: 'Antarctica',
        languages: [],
        emergencyNumber: '',
        phone: {
            dialCode: '672',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ar',
        label: 'Argentina',
        labelNative: 'Argentina',
        languages: ['es', 'gn'],
        emergencyNumber: '',
        phone: {
            dialCode: '54',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'as',
        label: 'American Samoa',
        labelNative: 'American Samoa',
        languages: ['en', 'sm'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['684', '833'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'at',
        label: 'Austria',
        labelNative: 'Österreich',
        languages: ['de'],
        emergencyNumber: '',
        phone: {
            dialCode: '43',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'au',
        label: 'Australia',
        labelNative: 'Australia',
        languages: ['en'],
        emergencyNumber: '112',
        phone: {
            dialCode: '61',
            mask: '... ... ...',
            areaCodes: [],
        },
        defaultPlace: {
            building: '',
            city: 'Yarralumla',
            coords: {
                lat: -35.3064497,
                lng: 149.1162186,
            },
            country: 'AU',
            geocoder_id: 'ChIJU3-6MNhMFmsRdPBfT3F25og',
            state: 'ACT',
            street: 'Moonah Place',
            zip: 'ACT 2600',
        },
    },
    {
        iso2: 'aw',
        label: 'Aruba',
        labelNative: 'Aruba',
        languages: ['nl', 'pa'],
        emergencyNumber: '',
        phone: {
            dialCode: '297',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ax',
        label: 'Åland',
        labelNative: 'Åland',
        languages: ['sv'],
        emergencyNumber: '',
        phone: {
            dialCode: '358',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'az',
        label: 'Azerbaijan',
        labelNative: 'Azərbaycan',
        languages: ['az'],
        emergencyNumber: '',
        phone: {
            dialCode: '994',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ba',
        label: 'Bosnia and Herzegovina',
        labelNative: 'Bosna i Hercegovina',
        languages: ['bs', 'hr', 'sr'],
        emergencyNumber: '',
        phone: {
            dialCode: '387',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bb',
        label: 'Barbados',
        labelNative: 'Barbados',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['246'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bd',
        label: 'Bangladesh',
        labelNative: 'Bangladesh',
        languages: ['bn'],
        emergencyNumber: '',
        phone: {
            dialCode: '880',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'be',
        label: 'Belgium',
        labelNative: 'België',
        languages: ['nl', 'fr', 'de'],
        emergencyNumber: '',
        phone: {
            dialCode: '32',
            mask: '... .. .. ..',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bf',
        label: 'Burkina Faso',
        labelNative: 'Burkina Faso',
        languages: ['fr', 'ff'],
        emergencyNumber: '',
        phone: {
            dialCode: '226',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bg',
        label: 'Bulgaria',
        labelNative: 'България',
        languages: ['bg'],
        emergencyNumber: '',
        phone: {
            dialCode: '359',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bh',
        label: 'Bahrain',
        labelNative: '‏البحرين',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '973',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bi',
        label: 'Burundi',
        labelNative: 'Burundi',
        languages: ['fr', 'rn'],
        emergencyNumber: '',
        phone: {
            dialCode: '257',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bj',
        label: 'Benin',
        labelNative: 'Bénin',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '229',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bl',
        label: 'Saint Barthélemy',
        labelNative: 'Saint-Barthélemy',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '590',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bm',
        label: 'Bermuda',
        labelNative: 'Bermuda',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['441'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bn',
        label: 'Brunei',
        labelNative: 'Negara Brunei Darussalam',
        languages: ['ms'],
        emergencyNumber: '',
        phone: {
            dialCode: '673',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bo',
        label: 'Bolivia',
        labelNative: 'Bolivia',
        languages: ['es', 'ay', 'qu'],
        emergencyNumber: '',
        phone: {
            dialCode: '591',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bq',
        label: 'Bonaire',
        labelNative: 'Bonaire',
        languages: ['nl'],
        emergencyNumber: '',
        phone: {
            dialCode: '599',
            mask: '',
            areaCodes: ['715'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'br',
        label: 'Brazil',
        labelNative: 'Brasil',
        languages: ['pt'],
        emergencyNumber: '',
        phone: {
            dialCode: '55',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bs',
        label: 'Bahamas',
        labelNative: 'Bahamas',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['242'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bt',
        label: 'Bhutan',
        labelNative: 'ʼbrug-yul',
        languages: ['dz'],
        emergencyNumber: '',
        phone: {
            dialCode: '975',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bv',
        label: 'Bouvet Island',
        labelNative: 'Bouvetøya',
        languages: ['no', 'nb', 'nn'],
        emergencyNumber: '',
        phone: {
            dialCode: '47',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bw',
        label: 'Botswana',
        labelNative: 'Botswana',
        languages: ['en', 'tn'],
        emergencyNumber: '',
        phone: {
            dialCode: '267',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'by',
        label: 'Belarus',
        labelNative: 'Белару́сь',
        languages: ['be', 'ru'],
        emergencyNumber: '',
        phone: {
            dialCode: '375',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'bz',
        label: 'Belize',
        labelNative: 'Belize',
        languages: ['en', 'es'],
        emergencyNumber: '',
        phone: {
            dialCode: '501',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        priority: 1,
        iso2: 'ca',
        label: 'Canada',
        labelNative: 'Canada',
        languages: ['en', 'fr'],
        emergencyNumber: '911',
        phone: {
            dialCode: '1',
            mask: '(...) ...-....',
            areaCodes: [
                '204',
                '226',
                '236',
                '249',
                '250',
                '289',
                '306',
                '343',
                '365',
                '387',
                '403',
                '416',
                '418',
                '431',
                '437',
                '438',
                '450',
                '506',
                '514',
                '519',
                '548',
                '579',
                '581',
                '587',
                '604',
                '613',
                '639',
                '647',
                '672',
                '705',
                '709',
                '742',
                '778',
                '780',
                '782',
                '807',
                '819',
                '825',
                '867',
                '873',
                '902',
                '905',
            ],
        },
        defaultPlace: {
            building: '1075',
            city: 'Vancouver',
            coords: {
                lat: 49.2875786,
                lng: -123.119728,
            },
            country: 'CA',
            geocoder_id: 'ChIJh_G8wYNxhlQReeCjC4RO5fc',
            state: 'BC',
            street: 'West Pender Street',
            zip: 'V6E 2M6',
        },
    },
    {
        iso2: 'cc',
        label: 'Cocos [Keeling] Islands',
        labelNative: 'Cocos (Keeling) Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '61',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cd',
        label: 'Democratic Republic of the Congo',
        labelNative: 'République démocratique du Congo',
        languages: ['fr', 'ln', 'kg', 'sw', 'lu'],
        emergencyNumber: '',
        phone: {
            dialCode: '243',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cf',
        label: 'Central African Republic',
        labelNative: 'Ködörösêse tî Bêafrîka',
        languages: ['fr', 'sg'],
        emergencyNumber: '',
        phone: {
            dialCode: '236',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cg',
        label: 'Republic of the Congo',
        labelNative: 'République du Congo',
        languages: ['fr', 'ln'],
        emergencyNumber: '',
        phone: {
            dialCode: '242',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ch',
        label: 'Switzerland',
        labelNative: 'Schweiz',
        languages: ['de', 'fr', 'it'],
        emergencyNumber: '',
        phone: {
            dialCode: '41',
            mask: '.. ... .. ..',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ci',
        label: 'Ivory Coast',
        labelNative: "Côte d'Ivoire",
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '225',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ck',
        label: 'Cook Islands',
        labelNative: 'Cook Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '682',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cl',
        label: 'Chile',
        labelNative: 'Chile',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '56',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cm',
        label: 'Cameroon',
        labelNative: 'Cameroon',
        languages: ['en', 'fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '237',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cn',
        label: 'China',
        labelNative: '中国',
        languages: ['zh'],
        emergencyNumber: '',
        phone: {
            dialCode: '86',
            mask: '..-........',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'co',
        label: 'Colombia',
        labelNative: 'Colombia',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '57',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cr',
        label: 'Costa Rica',
        labelNative: 'Costa Rica',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '506',
            mask: '....-....',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cu',
        label: 'Cuba',
        labelNative: 'Cuba',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '53',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cv',
        label: 'Cape Verde',
        labelNative: 'Cabo Verde',
        languages: ['pt'],
        emergencyNumber: '',
        phone: {
            dialCode: '238',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cw',
        label: 'Curacao',
        labelNative: 'Curaçao',
        languages: ['nl', 'pa', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '599',
            mask: '',
            areaCodes: ['9'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cx',
        label: 'Christmas Island',
        labelNative: 'Christmas Island',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '61',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cy',
        label: 'Cyprus',
        labelNative: 'Κύπρος',
        languages: ['el', 'tr', 'hy'],
        emergencyNumber: '',
        phone: {
            dialCode: '357',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'cz',
        label: 'Czech Republic',
        labelNative: 'Česká republika',
        languages: ['cs', 'sk'],
        emergencyNumber: '',
        phone: {
            dialCode: '420',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'de',
        label: 'Germany',
        labelNative: 'Deutschland',
        languages: ['de'],
        emergencyNumber: '',
        phone: {
            dialCode: '49',
            mask: '... .......',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'dj',
        label: 'Djibouti',
        labelNative: 'Djibouti',
        languages: ['fr', 'ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '253',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'dk',
        label: 'Denmark',
        labelNative: 'Danmark',
        languages: ['da'],
        emergencyNumber: '',
        phone: {
            dialCode: '45',
            mask: '.. .. .. ..',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'dm',
        label: 'Dominica',
        labelNative: 'Dominica',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['767'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'do',
        label: 'Dominican Republic',
        labelNative: 'República Dominicana',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['809', '829', '849'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'dz',
        label: 'Algeria',
        labelNative: 'الجزائر',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '213',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ec',
        label: 'Ecuador',
        labelNative: 'Ecuador',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '593',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ee',
        label: 'Estonia',
        labelNative: 'Eesti',
        languages: ['et'],
        emergencyNumber: '',
        phone: {
            dialCode: '372',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'eg',
        label: 'Egypt',
        labelNative: 'مصر‎',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '20',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'eh',
        label: 'Western Sahara',
        labelNative: 'الصحراء الغربية',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '212',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'er',
        label: 'Eritrea',
        labelNative: 'ኤርትራ',
        languages: ['ti', 'ar', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '291',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'es',
        label: 'Spain',
        labelNative: 'España',
        languages: ['es', 'eu', 'ca', 'gl', 'oc'],
        emergencyNumber: '',
        phone: {
            dialCode: '34',
            mask: '... ... ...',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'et',
        label: 'Ethiopia',
        labelNative: 'ኢትዮጵያ',
        languages: ['am'],
        emergencyNumber: '',
        phone: {
            dialCode: '251',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'fi',
        label: 'Finland',
        labelNative: 'Suomi',
        languages: ['fi', 'sv'],
        emergencyNumber: '',
        phone: {
            dialCode: '358',
            mask: '.. ... .. ..',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'fj',
        label: 'Fiji',
        labelNative: 'Fiji',
        languages: ['en', 'fj', 'hi', 'ur'],
        emergencyNumber: '',
        phone: {
            dialCode: '679',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'fk',
        label: 'Falkland Islands',
        labelNative: 'Falkland Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '500',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'fm',
        label: 'Micronesia',
        labelNative: 'Micronesia',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '691',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'fo',
        label: 'Faroe Islands',
        labelNative: 'Føroyar',
        languages: ['fo'],
        emergencyNumber: '',
        phone: {
            dialCode: '298',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'fr',
        label: 'France',
        labelNative: 'France',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '33',
            mask: '. .. .. .. ..',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ga',
        label: 'Gabon',
        labelNative: 'Gabon',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '241',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gb',
        label: 'United Kingdom',
        labelNative: 'United Kingdom',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '44',
            mask: '.... ......',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gd',
        label: 'Grenada',
        labelNative: 'Grenada',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['473'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ge',
        label: 'Georgia',
        labelNative: 'საქართველო',
        languages: ['ka'],
        emergencyNumber: '',
        phone: {
            dialCode: '995',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gf',
        label: 'French Guiana',
        labelNative: 'Guyane française',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '594',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gg',
        label: 'Guernsey',
        labelNative: 'Guernsey',
        languages: ['en', 'fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '44',
            mask: '',
            areaCodes: ['1481'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gh',
        label: 'Ghana',
        labelNative: 'Ghana',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '233',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gi',
        label: 'Gibraltar',
        labelNative: 'Gibraltar',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '350',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gl',
        label: 'Greenland',
        labelNative: 'Kalaallit Nunaat',
        languages: ['kl'],
        emergencyNumber: '',
        phone: {
            dialCode: '299',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gm',
        label: 'Gambia',
        labelNative: 'Gambia',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '220',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gn',
        label: 'Guinea',
        labelNative: 'Guinée',
        languages: ['fr', 'ff'],
        emergencyNumber: '',
        phone: {
            dialCode: '224',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gp',
        label: 'Guadeloupe',
        labelNative: 'Guadeloupe',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '590',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gq',
        label: 'Equatorial Guinea',
        labelNative: 'Guinea Ecuatorial',
        languages: ['es', 'fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '240',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gr',
        label: 'Greece',
        labelNative: 'Ελλάδα',
        languages: ['el'],
        emergencyNumber: '',
        phone: {
            dialCode: '30',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gs',
        label: 'South Georgia and the South Sandwich Islands',
        labelNative: 'South Georgia',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '500',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gt',
        label: 'Guatemala',
        labelNative: 'Guatemala',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '502',
            mask: '....-....',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gu',
        label: 'Guam',
        labelNative: 'Guam',
        languages: ['en', 'ch', 'es'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['671'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gw',
        label: 'Guinea-Bissau',
        labelNative: 'Guiné-Bissau',
        languages: ['pt'],
        emergencyNumber: '',
        phone: {
            dialCode: '245',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'gy',
        label: 'Guyana',
        labelNative: 'Guyana',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '592',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'hk',
        label: 'Hong Kong',
        labelNative: '香港',
        languages: ['zh', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '852',
            mask: '.... ....',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'hm',
        label: 'Heard Island and McDonald Islands',
        labelNative: 'Heard Island and McDonald Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '61',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'hn',
        label: 'Honduras',
        labelNative: 'Honduras',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '504',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'hr',
        label: 'Croatia',
        labelNative: 'Hrvatska',
        languages: ['hr'],
        emergencyNumber: '',
        phone: {
            dialCode: '385',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ht',
        label: 'Haiti',
        labelNative: 'Haïti',
        languages: ['fr', 'ht'],
        emergencyNumber: '',
        phone: {
            dialCode: '509',
            mask: '....-....',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'hu',
        label: 'Hungary',
        labelNative: 'Magyarország',
        languages: ['hu'],
        emergencyNumber: '',
        phone: {
            dialCode: '36',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'id',
        label: 'Indonesia',
        labelNative: 'Indonesia',
        languages: ['id'],
        emergencyNumber: '',
        phone: {
            dialCode: '62',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ie',
        label: 'Ireland',
        labelNative: 'Éire',
        languages: ['ga', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '353',
            mask: '.. .......',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'il',
        label: 'Israel',
        labelNative: 'יִשְׂרָאֵל',
        languages: ['he', 'ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '972',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'im',
        label: 'Isle of Man',
        labelNative: 'Isle of Man',
        languages: ['en', 'gv'],
        emergencyNumber: '',
        phone: {
            dialCode: '44',
            mask: '',
            areaCodes: ['1624'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'in',
        label: 'India',
        labelNative: 'भारत',
        languages: ['hi', 'en'],
        emergencyNumber: '102',
        phone: {
            dialCode: '91',
            mask: '.....-.....',
            areaCodes: [],
        },
        defaultPlace: {
            building: '',
            city: 'New Delhi',
            coords: {
                lat: 28.597641538216376,
                lng: 77.18797679434556,
            },
            country: 'IN',
            geocoder_id: 'ChIJe7odWGcdDTkRloMlZhcdrDE',
            state: 'DL',
            street: 'Panchsheel Marg',
            zip: '110021',
        },
    },
    {
        iso2: 'io',
        label: 'British Indian Ocean Territory',
        labelNative: 'British Indian Ocean Territory',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '246',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'iq',
        label: 'Iraq',
        labelNative: 'العراق',
        languages: ['ar', 'ku'],
        emergencyNumber: '',
        phone: {
            dialCode: '964',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ir',
        label: 'Iran',
        labelNative: 'ایران',
        languages: ['fa'],
        emergencyNumber: '',
        phone: {
            dialCode: '98',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'is',
        label: 'Iceland',
        labelNative: 'Ísland',
        languages: ['is'],
        emergencyNumber: '',
        phone: {
            dialCode: '354',
            mask: '... ....',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'it',
        label: 'Italy',
        labelNative: 'Italia',
        languages: ['it'],
        emergencyNumber: '',
        phone: {
            dialCode: '39',
            mask: '... ......',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'je',
        label: 'Jersey',
        labelNative: 'Jersey',
        languages: ['en', 'fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '44',
            mask: '',
            areaCodes: ['1534', '7797', '7700', '7829'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'jm',
        label: 'Jamaica',
        labelNative: 'Jamaica',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['876'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'jo',
        label: 'Jordan',
        labelNative: 'الأردن',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '962',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'jp',
        label: 'Japan',
        labelNative: '日本',
        languages: ['ja'],
        emergencyNumber: '119',
        phone: {
            dialCode: '81',
            mask: '... .. ....',
            areaCodes: [],
        },
        defaultPlace: {
            building: '3',
            city: 'Sapporo',
            coords: {
                lat: 43.0562538,
                lng: 141.3133331,
            },
            country: 'JP',
            geocoder_id: 'ChIJWU3UPsIpC18R7vAxyk4kR88',
            state: 'Hokkaido',
            street: 'Chuo Ward',
            zip: '060-0001',
        },
    },
    {
        iso2: 'ke',
        label: 'Kenya',
        labelNative: 'Kenya',
        languages: ['en', 'sw'],
        emergencyNumber: '',
        phone: {
            dialCode: '254',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'kg',
        label: 'Kyrgyzstan',
        labelNative: 'Кыргызстан',
        languages: ['ky', 'ru'],
        emergencyNumber: '',
        phone: {
            dialCode: '996',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'kh',
        label: 'Cambodia',
        labelNative: 'Kâmpŭchéa',
        languages: ['km'],
        emergencyNumber: '',
        phone: {
            dialCode: '855',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ki',
        label: 'Kiribati',
        labelNative: 'Kiribati',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '686',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'km',
        label: 'Comoros',
        labelNative: 'Komori',
        languages: ['ar', 'fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '269',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'kn',
        label: 'Saint Kitts and Nevis',
        labelNative: 'Saint Kitts and Nevis',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['869'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'kp',
        label: 'North Korea',
        labelNative: '북한',
        languages: ['ko'],
        emergencyNumber: '',
        phone: {
            dialCode: '850',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'kr',
        label: 'South Korea',
        labelNative: '대한민국',
        languages: ['ko'],
        emergencyNumber: '',
        phone: {
            dialCode: '82',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'kw',
        label: 'Kuwait',
        labelNative: 'الكويت',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '965',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ky',
        label: 'Cayman Islands',
        labelNative: 'Cayman Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['345'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'kz',
        label: 'Kazakhstan',
        labelNative: 'Қазақстан',
        languages: ['kk', 'ru'],
        emergencyNumber: '',
        phone: {
            dialCode: '7',
            mask: '... ...-..-..',
            areaCodes: [
                '310',
                '311',
                '312',
                '313',
                '315',
                '318',
                '321',
                '324',
                '325',
                '326',
                '327',
                '336',
                '7172',
                '73622',
            ],
        },
        defaultPlace: {},
    },
    {
        iso2: 'la',
        label: 'Laos',
        labelNative: 'ສປປລາວ',
        languages: ['lo'],
        emergencyNumber: '',
        phone: {
            dialCode: '856',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'lb',
        label: 'Lebanon',
        labelNative: 'لبنان',
        languages: ['ar', 'fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '961',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'lc',
        label: 'Saint Lucia',
        labelNative: 'Saint Lucia',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['758'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'li',
        label: 'Liechtenstein',
        labelNative: 'Liechtenstein',
        languages: ['de'],
        emergencyNumber: '',
        phone: {
            dialCode: '423',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'lk',
        label: 'Sri Lanka',
        labelNative: 'śrī laṃkāva',
        languages: ['si', 'ta'],
        emergencyNumber: '',
        phone: {
            dialCode: '94',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'lr',
        label: 'Liberia',
        labelNative: 'Liberia',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '231',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ls',
        label: 'Lesotho',
        labelNative: 'Lesotho',
        languages: ['en', 'st'],
        emergencyNumber: '',
        phone: {
            dialCode: '266',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'lt',
        label: 'Lithuania',
        labelNative: 'Lietuva',
        languages: ['lt'],
        emergencyNumber: '',
        phone: {
            dialCode: '370',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'lu',
        label: 'Luxembourg',
        labelNative: 'Luxembourg',
        languages: ['fr', 'de', 'lb'],
        emergencyNumber: '',
        phone: {
            dialCode: '352',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'lv',
        label: 'Latvia',
        labelNative: 'Latvija',
        languages: ['lv'],
        emergencyNumber: '',
        phone: {
            dialCode: '371',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ly',
        label: 'Libya',
        labelNative: '‏ليبيا',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '218',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ma',
        label: 'Morocco',
        labelNative: 'المغرب',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '212',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mc',
        label: 'Monaco',
        labelNative: 'Monaco',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '377',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'md',
        label: 'Moldova',
        labelNative: 'Moldova',
        languages: ['ro'],
        emergencyNumber: '',
        phone: {
            dialCode: '373',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'me',
        label: 'Montenegro',
        labelNative: 'Црна Гора',
        languages: ['sr', 'bs', 'sq', 'hr'],
        emergencyNumber: '',
        phone: {
            dialCode: '382',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mf',
        label: 'Saint Martin',
        labelNative: 'Saint-Martin',
        languages: ['en', 'fr', 'nl'],
        emergencyNumber: '',
        phone: {
            dialCode: '590',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mg',
        label: 'Madagascar',
        labelNative: 'Madagasikara',
        languages: ['fr', 'mg'],
        emergencyNumber: '',
        phone: {
            dialCode: '261',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mh',
        label: 'Marshall Islands',
        labelNative: 'M̧ajeļ',
        languages: ['en', 'mh'],
        emergencyNumber: '',
        phone: {
            dialCode: '692',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mk',
        label: 'North Macedonia',
        labelNative: 'Северна Македонија',
        languages: ['mk'],
        emergencyNumber: '',
        phone: {
            dialCode: '389',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ml',
        label: 'Mali',
        labelNative: 'Mali',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '223',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mm',
        label: 'Myanmar [Burma]',
        labelNative: 'မြန်မာ',
        languages: ['my'],
        emergencyNumber: '',
        phone: {
            dialCode: '95',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mn',
        label: 'Mongolia',
        labelNative: 'Монгол улс',
        languages: ['mn'],
        emergencyNumber: '',
        phone: {
            dialCode: '976',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mo',
        label: 'Macao',
        labelNative: '澳門',
        languages: ['zh', 'pt'],
        emergencyNumber: '',
        phone: {
            dialCode: '853',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mp',
        label: 'Northern Mariana Islands',
        labelNative: 'Northern Mariana Islands',
        languages: ['en', 'ch'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['670'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mq',
        label: 'Martinique',
        labelNative: 'Martinique',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '596',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mr',
        label: 'Mauritania',
        labelNative: 'موريتانيا',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '222',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ms',
        label: 'Montserrat',
        labelNative: 'Montserrat',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['664'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mt',
        label: 'Malta',
        labelNative: 'Malta',
        languages: ['mt', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '356',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mu',
        label: 'Mauritius',
        labelNative: 'Maurice',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '230',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mv',
        label: 'Maldives',
        labelNative: 'Maldives',
        languages: ['dv'],
        emergencyNumber: '',
        phone: {
            dialCode: '960',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mw',
        label: 'Malawi',
        labelNative: 'Malawi',
        languages: ['en', 'ny'],
        emergencyNumber: '',
        phone: {
            dialCode: '265',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'mx',
        label: 'Mexico',
        labelNative: 'México',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '52',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'my',
        label: 'Malaysia',
        labelNative: 'Malaysia',
        languages: ['ms'],
        emergencyNumber: '999',
        phone: {
            dialCode: '60',
            mask: '..-....-....',
            areaCodes: [],
        },
        defaultPlace: {
            building: '376',
            city: 'Kuala Lumpur',
            coords: {
                lat: 3.154668,
                lng: 101.722633,
            },
            country: 'MY',
            geocoder_id: 'ChIJER7HGcw3zDERanNM3BHH3LE',
            state: 'Wilayah Persekutuan Kuala Lumpur',
            street: 'Jalan Tun Razak',
            zip: '50400',
        },
    },
    {
        iso2: 'mz',
        label: 'Mozambique',
        labelNative: 'Moçambique',
        languages: ['pt'],
        emergencyNumber: '',
        phone: {
            dialCode: '258',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'na',
        label: 'Namibia',
        labelNative: 'Namibia',
        languages: ['en', 'af'],
        emergencyNumber: '',
        phone: {
            dialCode: '264',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'nc',
        label: 'New Caledonia',
        labelNative: 'Nouvelle-Calédonie',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '687',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ne',
        label: 'Niger',
        labelNative: 'Niger',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '227',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'nf',
        label: 'Norfolk Island',
        labelNative: 'Norfolk Island',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '672',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ng',
        label: 'Nigeria',
        labelNative: 'Nigeria',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '234',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ni',
        label: 'Nicaragua',
        labelNative: 'Nicaragua',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '505',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'nl',
        label: 'Netherlands',
        labelNative: 'Nederland',
        languages: ['nl'],
        emergencyNumber: '',
        phone: {
            dialCode: '31',
            mask: '.. ........',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'no',
        label: 'Norway',
        labelNative: 'Norge',
        languages: ['no', 'nb', 'nn'],
        emergencyNumber: '',
        phone: {
            dialCode: '47',
            mask: '... .. ...',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'np',
        label: 'Nepal',
        labelNative: 'नपल',
        languages: ['ne'],
        emergencyNumber: '',
        phone: {
            dialCode: '977',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'nr',
        label: 'Nauru',
        labelNative: 'Nauru',
        languages: ['en', 'na'],
        emergencyNumber: '',
        phone: {
            dialCode: '674',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'nu',
        label: 'Niue',
        labelNative: 'Niuē',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '683',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'nz',
        label: 'New Zealand',
        labelNative: 'New Zealand',
        languages: ['en', 'mi'],
        emergencyNumber: '',
        phone: {
            dialCode: '64',
            mask: '...-...-....',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'om',
        label: 'Oman',
        labelNative: 'عمان',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '968',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'pa',
        label: 'Panama',
        labelNative: 'Panamá',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '507',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'pe',
        label: 'Peru',
        labelNative: 'Perú',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '51',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'pf',
        label: 'French Polynesia',
        labelNative: 'Polynésie française',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '689',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'pg',
        label: 'Papua New Guinea',
        labelNative: 'Papua Niugini',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '675',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ph',
        label: 'Philippines',
        labelNative: 'Pilipinas',
        languages: ['en'],
        emergencyNumber: '166',
        phone: {
            dialCode: '63',
            mask: '... ....',
            areaCodes: [],
        },
        defaultPlace: {
            building: '1201',
            city: 'Manila',
            coords: {
                lat: 14.5771968,
                lng: 120.9782031,
            },
            country: 'PH',
            geocoder_id: 'ChIJyeIsti_KlzMR8i706ymJQdQ',
            state: 'NCR',
            street: 'Roxas Boulevard',
            zip: '1000',
        },
    },
    {
        iso2: 'pk',
        label: 'Pakistan',
        labelNative: 'Pakistan',
        languages: ['en', 'ur'],
        emergencyNumber: '',
        phone: {
            dialCode: '92',
            mask: '...-.......',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'pl',
        label: 'Poland',
        labelNative: 'Polska',
        languages: ['pl'],
        emergencyNumber: '112',
        phone: {
            dialCode: '48',
            mask: '...-...-...',
            areaCodes: [],
        },
        defaultPlace: {
            building: '29/31',
            city: 'Warszawa',
            coords: {
                lat: 52.22450019999999,
                lng: 21.0236106,
            },
            country: 'PL',
            geocoder_id: 'ChIJF4-nzvrMHkcRdseH7La6Wes',
            state: 'Mazowieckie',
            street: 'Aleje Ujazdowskie',
            zip: '00-540',
        },
    },
    {
        iso2: 'pm',
        label: 'Saint Pierre and Miquelon',
        labelNative: 'Saint-Pierre-et-Miquelon',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '508',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'pn',
        label: 'Pitcairn Islands',
        labelNative: 'Pitcairn Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '64',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'pr',
        label: 'Puerto Rico',
        labelNative: 'Puerto Rico',
        languages: ['es', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['787', '939'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ps',
        label: 'Palestine',
        labelNative: 'فلسطين',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '970',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'pt',
        label: 'Portugal',
        labelNative: 'Portugal',
        languages: ['pt'],
        emergencyNumber: '',
        phone: {
            dialCode: '351',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'pw',
        label: 'Palau',
        labelNative: 'Palau',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '680',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'py',
        label: 'Paraguay',
        labelNative: 'Paraguay',
        languages: ['es', 'gn'],
        emergencyNumber: '',
        phone: {
            dialCode: '595',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'qa',
        label: 'Qatar',
        labelNative: 'قطر',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '974',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 're',
        label: 'Réunion',
        labelNative: 'La Réunion',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '262',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ro',
        label: 'Romania',
        labelNative: 'România',
        languages: ['ro'],
        emergencyNumber: '',
        phone: {
            dialCode: '40',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'rs',
        label: 'Serbia',
        labelNative: 'Србија',
        languages: ['sr'],
        emergencyNumber: '',
        phone: {
            dialCode: '381',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ru',
        label: 'Russia',
        labelNative: 'Россия',
        languages: ['ru'],
        emergencyNumber: '112',
        phone: {
            dialCode: '7',
            mask: '... ...-..-..',
            areaCodes: [],
        },
        defaultPlace: {
            building: '8',
            city: 'Moskva',
            coords: {
                lat: 55.7556744,
                lng: 37.5801517,
            },
            country: 'RU',
            geocoder_id: 'ChIJffsIMjVKtUYRyY-BAubvVVQ',
            state: '',
            street: 'Bolshoy Devyatinsky Lane',
            zip: '121099',
        },
    },
    {
        iso2: 'rw',
        label: 'Rwanda',
        labelNative: 'Rwanda',
        languages: ['rw', 'en', 'fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '250',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sa',
        label: 'Saudi Arabia',
        labelNative: 'العربية السعودية',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '966',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sb',
        label: 'Solomon Islands',
        labelNative: 'Solomon Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '677',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sc',
        label: 'Seychelles',
        labelNative: 'Seychelles',
        languages: ['fr', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '248',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sd',
        label: 'Sudan',
        labelNative: 'السودان',
        languages: ['ar', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '249',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'se',
        label: 'Sweden',
        labelNative: 'Sverige',
        languages: ['sv'],
        emergencyNumber: '',
        phone: {
            dialCode: '46',
            mask: '.. ... .. ..',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sg',
        label: 'Singapore',
        labelNative: 'Singapore',
        languages: ['en', 'ms', 'ta', 'zh'],
        emergencyNumber: '995',
        phone: {
            dialCode: '65',
            mask: '....-....',
            areaCodes: [],
        },
        defaultPlace: {
            building: '27',
            city: 'Singapore',
            coords: {
                lat: 1.3052732,
                lng: 103.820494,
            },
            country: 'SG',
            geocoder_id: 'ChIJ_b8IjCAa2jERyW0jQBYO9xM',
            state: '',
            street: 'Napier Road',
            zip: '258508',
        },
    },
    {
        iso2: 'sh',
        label: 'Saint Helena',
        labelNative: 'Saint Helena',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '290',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'si',
        label: 'Slovenia',
        labelNative: 'Slovenija',
        languages: ['sl'],
        emergencyNumber: '',
        phone: {
            dialCode: '386',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sj',
        label: 'Svalbard and Jan Mayen',
        labelNative: 'Svalbard og Jan Mayen',
        languages: ['no'],
        emergencyNumber: '',
        phone: {
            dialCode: '4779',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sk',
        label: 'Slovakia',
        labelNative: 'Slovensko',
        languages: ['sk'],
        emergencyNumber: '',
        phone: {
            dialCode: '421',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sl',
        label: 'Sierra Leone',
        labelNative: 'Sierra Leone',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '232',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sm',
        label: 'San Marino',
        labelNative: 'San Marino',
        languages: ['it'],
        emergencyNumber: '',
        phone: {
            dialCode: '378',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sn',
        label: 'Senegal',
        labelNative: 'Sénégal',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '221',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'so',
        label: 'Somalia',
        labelNative: 'Soomaaliya',
        languages: ['so', 'ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '252',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sr',
        label: 'Suriname',
        labelNative: 'Suriname',
        languages: ['nl'],
        emergencyNumber: '',
        phone: {
            dialCode: '597',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ss',
        label: 'South Sudan',
        labelNative: 'South Sudan',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '211',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'st',
        label: 'São Tomé and Príncipe',
        labelNative: 'São Tomé e Príncipe',
        languages: ['pt'],
        emergencyNumber: '',
        phone: {
            dialCode: '239',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sv',
        label: 'El Salvador',
        labelNative: 'El Salvador',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '503',
            mask: '....-....',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sx',
        label: 'Sint Maarten',
        labelNative: 'Sint Maarten',
        languages: ['nl', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['721'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sy',
        label: 'Syria',
        labelNative: 'سوريا',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '963',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'sz',
        label: 'Swaziland',
        labelNative: 'Swaziland',
        languages: ['en', 'ss'],
        emergencyNumber: '',
        phone: {
            dialCode: '268',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tc',
        label: 'Turks and Caicos Islands',
        labelNative: 'Turks and Caicos Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['649'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'td',
        label: 'Chad',
        labelNative: 'Tchad',
        languages: ['fr', 'ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '235',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tf',
        label: 'French Southern Territories',
        labelNative: 'Territoire des Terres australes et antarctiques fr',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '262',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tg',
        label: 'Togo',
        labelNative: 'Togo',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '228',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'th',
        label: 'Thailand',
        labelNative: 'ประเทศไทย',
        languages: ['th'],
        emergencyNumber: '191',
        phone: {
            dialCode: '66',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {
            building: '95',
            city: 'Bangkok',
            coords: {
                lat: 13.736069,
                lng: 100.547381,
            },
            country: 'TH',
            geocoder_id: 'ChIJ0QNDE9-e4jAR6bvH9Q03694',
            state: 'Krung Thep Maha Nakhon',
            street: 'Wireless Road',
            zip: '10330',
        },
    },
    {
        iso2: 'tj',
        label: 'Tajikistan',
        labelNative: 'Тоҷикистон',
        languages: ['tg', 'ru'],
        emergencyNumber: '',
        phone: {
            dialCode: '992',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tk',
        label: 'Tokelau',
        labelNative: 'Tokelau',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '690',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tl',
        label: 'East Timor',
        labelNative: 'Timor-Leste',
        languages: ['pt'],
        emergencyNumber: '',
        phone: {
            dialCode: '670',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tm',
        label: 'Turkmenistan',
        labelNative: 'Türkmenistan',
        languages: ['tk', 'ru'],
        emergencyNumber: '',
        phone: {
            dialCode: '993',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tn',
        label: 'Tunisia',
        labelNative: 'تونس',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '216',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'to',
        label: 'Tonga',
        labelNative: 'Tonga',
        languages: ['en', 'to'],
        emergencyNumber: '',
        phone: {
            dialCode: '676',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tr',
        label: 'Turkey',
        labelNative: 'Türkiye',
        languages: ['tr'],
        emergencyNumber: '',
        phone: {
            dialCode: '90',
            mask: '... ... .. ..',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tt',
        label: 'Trinidad and Tobago',
        labelNative: 'Trinidad and Tobago',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['868'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tv',
        label: 'Tuvalu',
        labelNative: 'Tuvalu',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '688',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tw',
        label: 'Taiwan',
        labelNative: '臺灣',
        languages: ['zh'],
        emergencyNumber: '',
        phone: {
            dialCode: '886',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'tz',
        label: 'Tanzania',
        labelNative: 'Tanzania',
        languages: ['sw', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '255',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ua',
        label: 'Ukraine',
        labelNative: 'Україна',
        languages: ['uk'],
        emergencyNumber: '118',
        phone: {
            dialCode: '380',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {
            building: '4',
            city: 'Kyiv',
            coords: {
                lat: 50.4648943,
                lng: 30.4321581,
            },
            country: 'UA',
            geocoder_id: 'ChIJ_ZlqrkvM1EARFBeNWYIusBU',
            state: '',
            street: 'Aviakonstructor Igor Sikorsky Street',
            zip: '04112',
        },
    },
    {
        iso2: 'ug',
        label: 'Uganda',
        labelNative: 'Uganda',
        languages: ['en', 'sw'],
        emergencyNumber: '',
        phone: {
            dialCode: '256',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'um',
        label: 'U.S. Minor Outlying Islands',
        labelNative: 'United States Minor Outlying Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        priority: 0,
        iso2: 'us',
        label: 'United States',
        labelNative: 'United States',
        languages: ['en'],
        emergencyNumber: '911',
        phone: {
            dialCode: '1',
            mask: '(...) ...-....',
            areaCodes: [
                '917',
                '907',
                '205',
                '251',
                '256',
                '334',
                '479',
                '501',
                '870',
                '480',
                '520',
                '602',
                '623',
                '928',
                '209',
                '213',
                '310',
                '323',
                '408',
                '415',
                '510',
                '530',
                '559',
                '562',
                '619',
                '626',
                '650',
                '661',
                '707',
                '714',
                '760',
                '805',
                '818',
                '831',
                '858',
                '909',
                '916',
                '925',
                '949',
                '951',
                '303',
                '719',
                '970',
                '203',
                '860',
                '202',
                '302',
                '239',
                '305',
                '321',
                '352',
                '386',
                '407',
                '561',
                '727',
                '772',
                '813',
                '850',
                '863',
                '904',
                '941',
                '954',
                '229',
                '404',
                '478',
                '706',
                '770',
                '912',
                '808',
                '319',
                '515',
                '563',
                '641',
                '712',
                '208',
                '217',
                '309',
                '312',
                '618',
                '630',
                '708',
                '773',
                '815',
                '847',
                '219',
                '260',
                '317',
                '574',
                '765',
                '812',
                '316',
                '620',
                '785',
                '913',
                '270',
                '502',
                '606',
                '859',
                '225',
                '318',
                '337',
                '504',
                '985',
                '413',
                '508',
                '617',
                '781',
                '978',
                '301',
                '410',
                '207',
                '231',
                '248',
                '269',
                '313',
                '517',
                '586',
                '616',
                '734',
                '810',
                '906',
                '989',
                '218',
                '320',
                '507',
                '612',
                '651',
                '763',
                '952',
                '314',
                '417',
                '573',
                '636',
                '660',
                '816',
                '228',
                '601',
                '662',
                '406',
                '252',
                '336',
                '704',
                '828',
                '910',
                '919',
                '701',
                '308',
                '402',
                '603',
                '201',
                '609',
                '732',
                '856',
                '908',
                '973',
                '505',
                '575',
                '702',
                '775',
                '212',
                '315',
                '516',
                '518',
                '585',
                '607',
                '631',
                '716',
                '718',
                '845',
                '914',
                '216',
                '330',
                '419',
                '440',
                '513',
                '614',
                '740',
                '937',
                '405',
                '580',
                '918',
                '503',
                '541',
                '215',
                '412',
                '570',
                '610',
                '717',
                '724',
                '814',
                '401',
                '803',
                '843',
                '864',
                '605',
                '423',
                '615',
                '731',
                '865',
                '901',
                '931',
                '210',
                '214',
                '254',
                '281',
                '325',
                '361',
                '409',
                '432',
                '512',
                '713',
                '806',
                '817',
                '830',
                '903',
                '915',
                '936',
                '940',
                '956',
                '972',
                '979',
                '435',
                '801',
                '276',
                '434',
                '540',
                '703',
                '757',
                '804',
                '802',
                '206',
                '253',
                '360',
                '425',
                '509',
                '262',
                '414',
                '608',
                '715',
                '920',
                '304',
                '307',
            ],
        },
        defaultPlace: {
            building: '500',
            city: 'New York',
            coords: {
                lat: 40.7532218,
                lng: -73.9892771,
            },
            country: 'US',
            geocoder_id: 'ChIJAxmUe6xZwokRPpZCDmpNCss',
            state: 'NY',
            street: '7th Avenue',
            zip: '10018',
        },
    },
    {
        iso2: 'uy',
        label: 'Uruguay',
        labelNative: 'Uruguay',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '598',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'uz',
        label: 'Uzbekistan',
        labelNative: 'O‘zbekiston',
        languages: ['uz', 'ru'],
        emergencyNumber: '',
        phone: {
            dialCode: '998',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'va',
        label: 'Vatican City',
        labelNative: 'Vaticano',
        languages: ['it', 'la'],
        emergencyNumber: '',
        phone: {
            dialCode: '379',
            mask: '.. .... ....',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'vc',
        label: 'Saint Vincent and the Grenadines',
        labelNative: 'Saint Vincent and the Grenadines',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['784'],
        },
        defaultPlace: {},
    },
    {
        iso2: 've',
        label: 'Venezuela',
        labelNative: 'Venezuela',
        languages: ['es'],
        emergencyNumber: '',
        phone: {
            dialCode: '58',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'vg',
        label: 'British Virgin Islands',
        labelNative: 'British Virgin Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['284'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'vi',
        label: 'U.S. Virgin Islands',
        labelNative: 'United States Virgin Islands',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '1',
            mask: '',
            areaCodes: ['340'],
        },
        defaultPlace: {},
    },
    {
        iso2: 'vn',
        label: 'Vietnam',
        labelNative: 'Việt Nam',
        languages: ['vi'],
        emergencyNumber: '',
        phone: {
            dialCode: '84',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'vu',
        label: 'Vanuatu',
        labelNative: 'Vanuatu',
        languages: ['bi', 'en', 'fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '678',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'wf',
        label: 'Wallis and Futuna',
        labelNative: 'Wallis et Futuna',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '681',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ws',
        label: 'Samoa',
        labelNative: 'Samoa',
        languages: ['sm', 'en'],
        emergencyNumber: '',
        phone: {
            dialCode: '685',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'ye',
        label: 'Yemen',
        labelNative: 'اليَمَن',
        languages: ['ar'],
        emergencyNumber: '',
        phone: {
            dialCode: '967',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'yt',
        label: 'Mayotte',
        labelNative: 'Mayotte',
        languages: ['fr'],
        emergencyNumber: '',
        phone: {
            dialCode: '262',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'za',
        label: 'South Africa',
        labelNative: 'South Africa',
        languages: ['af', 'en', 'nr', 'st', 'ss', 'tn', 'ts', 've', 'xh', 'zu'],
        emergencyNumber: '',
        phone: {
            dialCode: '27',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'zm',
        label: 'Zambia',
        labelNative: 'Zambia',
        languages: ['en'],
        emergencyNumber: '',
        phone: {
            dialCode: '260',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
    {
        iso2: 'zw',
        label: 'Zimbabwe',
        labelNative: 'Zimbabwe',
        languages: ['en', 'sn', 'nd'],
        emergencyNumber: '',
        phone: {
            dialCode: '263',
            mask: '',
            areaCodes: [],
        },
        defaultPlace: {},
    },
];
