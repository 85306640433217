var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    procedureCategories: [],
    proceduresData: {},
    procedureDetail: {},
    getProceduresStatus: null,
    getProceduresIsAddedStatus: null,
    getGeoLocationStatus: null,
    getOfficesNearbyStatus: null,
    getSeasonProcedureStatus: null,
    activeCategoryId: null,
    proceduresSearchValue: '',
    showSearchInput: false,
    userGeoLocation: JSON.parse(localStorage.getItem('geolocation')) || null,
    isGeolocationDenied: JSON.parse(localStorage.getItem('geolocationDenied')) || false,
    officesNearby: [],
    searchFilters: null,
    seasonProcedures: [],
};
export var shopSlice = createSlice({
    name: 'shop',
    initialState: initialState,
    reducers: {
        getCategories: function (_, _action) { },
        getCategoriesSuccess: function (state, action) {
            state.procedureCategories = action.payload;
        },
        getCategoriesFail: function (_) { },
        clearCategories: function (state) {
            state.procedureCategories = [];
        },
        setActiveCategoryId: function (state, action) {
            state.activeCategoryId = action.payload;
        },
        getProcedures: function (state, action) {
            state.searchFilters = action.payload.data;
            if (action.payload.isAdded) {
                state.getProceduresIsAddedStatus = 'loading';
            }
            else {
                state.getProceduresStatus = 'loading';
            }
        },
        getProceduresSuccess: function (state, action) {
            if (action.payload.isAdded) {
                state.getProceduresIsAddedStatus = 'success';
                var updProcedures_1 = state.proceduresData.results;
                action.payload.data.results.map(function (e) {
                    updProcedures_1.push(e);
                });
                state.proceduresData = __assign(__assign({}, action.payload.data), { results: updProcedures_1 });
            }
            else {
                state.getProceduresStatus = 'success';
                state.proceduresData = action.payload.data;
            }
        },
        setSearchFilters: function (state, action) {
            state.searchFilters = action.payload;
        },
        getProceduresFail: function (state) {
            state.getProceduresStatus = null;
        },
        getProcedureDetail: function (_state, _action) { },
        getProcedureDetailSuccess: function (state, action) {
            state.procedureDetail = action.payload;
        },
        getProcedureDetailFail: function (_state) { },
        removeProcedure: function (state, action) {
            state.proceduresData.results = state.proceduresData.results.filter(function (procedure) { return procedure._id !== action.payload; });
            state.proceduresData.total -= 1;
        },
        clearProcedures: function (state) {
            state.proceduresData = {};
        },
        setShowSearchInput: function (state, action) {
            state.showSearchInput = action.payload;
        },
        toggleShowSearchInput: function (state) {
            state.showSearchInput = !state.showSearchInput;
        },
        getGeolocation: function (state) {
            state.getGeoLocationStatus = 'loading';
        },
        getGeolocationFail: function (state) {
            state.userGeoLocation = null;
            state.getGeoLocationStatus = 'fail';
        },
        getGeolocationSuccess: function (state, action) {
            state.userGeoLocation = action.payload;
            state.getGeoLocationStatus = 'success';
        },
        setGeolocationDenied: function (state, action) {
            state.isGeolocationDenied = action.payload;
        },
        setProceduresSearchValue: function (state, action) {
            state.proceduresSearchValue = action.payload;
        },
        getOfficesNearby: function (state, _action) {
            state.getOfficesNearbyStatus = 'loading';
        },
        getOfficesNearbySuccess: function (state, action) {
            state.officesNearby = action.payload;
            state.getOfficesNearbyStatus = 'success';
        },
        getOfficesNearbyFail: function (state) {
            state.getOfficesNearbyStatus = 'fail';
        },
        clearSearchFilters: function (state) {
            state.searchFilters = initialState.searchFilters;
        },
        clearLoaders: function (state) {
            state.getProceduresStatus = null;
        },
        getSeasonProcedure: function (state, _action) {
            state.getSeasonProcedureStatus = 'loading';
        },
        getSeasonProcedureSuccess: function (state, action) {
            state.seasonProcedures = action.payload;
            state.getSeasonProcedureStatus = 'success';
        },
        getSeasonProcedureFail: function (state) {
            state.getSeasonProcedureStatus = 'fail';
        },
    },
});
export var getCategories = (_a = shopSlice.actions, _a.getCategories), getCategoriesSuccess = _a.getCategoriesSuccess, getCategoriesFail = _a.getCategoriesFail, clearCategories = _a.clearCategories, getProcedures = _a.getProcedures, getProceduresSuccess = _a.getProceduresSuccess, getProceduresFail = _a.getProceduresFail, setSearchFilters = _a.setSearchFilters, clearSearchFilters = _a.clearSearchFilters, getProcedureDetail = _a.getProcedureDetail, getProcedureDetailSuccess = _a.getProcedureDetailSuccess, getProcedureDetailFail = _a.getProcedureDetailFail, removeProcedure = _a.removeProcedure, clearProcedures = _a.clearProcedures, setActiveCategoryId = _a.setActiveCategoryId, setProceduresSearchValue = _a.setProceduresSearchValue, setShowSearchInput = _a.setShowSearchInput, toggleShowSearchInput = _a.toggleShowSearchInput, getGeolocation = _a.getGeolocation, getGeolocationFail = _a.getGeolocationFail, getGeolocationSuccess = _a.getGeolocationSuccess, setGeolocationDenied = _a.setGeolocationDenied, getOfficesNearby = _a.getOfficesNearby, getOfficesNearbySuccess = _a.getOfficesNearbySuccess, getOfficesNearbyFail = _a.getOfficesNearbyFail, getSeasonProcedure = _a.getSeasonProcedure, getSeasonProcedureSuccess = _a.getSeasonProcedureSuccess, getSeasonProcedureFail = _a.getSeasonProcedureFail, clearLoaders = _a.clearLoaders;
export default shopSlice.reducer;
