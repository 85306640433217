var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Maestro = function () { return (_jsxs("svg", __assign({ width: "23", height: "16", viewBox: "0 0 23 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 2C0 0.895431 0.895431 0 2 0H21C22.1046 0 23 0.895431 23 2V14C23 15.1046 22.1046 16 21 16H2C0.89543 16 0 15.1046 0 14L0 2Z", fill: "white" }), _jsx("ellipse", { cx: "8.28001", cy: "8.00001", rx: "4.83", ry: "4.8", fill: "#ED0006" }), _jsx("ellipse", { cx: "14.72", cy: "8.00001", rx: "4.83", ry: "4.8", fill: "#0099DF" }), _jsx("mask", __assign({ id: "mask0_5781_28693", maskUnits: "userSpaceOnUse", x: "9", y: "3", width: "11", height: "10" }, { children: _jsx("ellipse", { cx: "14.72", cy: "8.00001", rx: "4.83", ry: "4.8", fill: "white" }) })), _jsx("g", __assign({ mask: "url(#mask0_5781_28693)" }, { children: _jsx("ellipse", { cx: "8.28001", cy: "8.00001", rx: "4.83", ry: "4.8", fill: "#EB001A", fillOpacity: "0.1" }) }))] }))); };
export default Maestro;
