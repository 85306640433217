var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Subscription = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" }, { children: [_jsx("rect", { x: "0.686295", y: "12", width: "16", height: "16", rx: "2", transform: "rotate(-45 0.686295 12)", stroke: "black", strokeWidth: "2" }), _jsx("path", { d: "M7.75736 12H16.2426", stroke: "black", strokeWidth: "2", strokeLinecap: "round" }), _jsx("path", { d: "M12 7.75732L12 16.2426", stroke: "black", strokeWidth: "2", strokeLinecap: "round" })] }))); };
export default Subscription;
