var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useWindowDimensions from '@core/shared/utils/widthHook';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './index.module.sass';
export var YourRiskCardLoader = function () {
    var isMobile = useWindowDimensions().isMobile;
    return (_jsx("div", __assign({ className: styles.risk }, { children: _jsxs(SkeletonTheme, __assign({ borderRadius: 16, baseColor: "#e9e7e7", highlightColor: "#f0f0f0" }, { children: [isMobile && _jsx(Skeleton, { height: 32 }), _jsx(Skeleton, { height: 190, className: styles.riskIcon }), _jsxs("div", __assign({ className: styles.riskContent }, { children: [!isMobile && _jsx(Skeleton, { height: 32 }), _jsx(Skeleton, { height: 96, className: styles.riskContentText }), _jsx(Skeleton, { height: 59, className: styles.btn })] }))] })) })));
};
