var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AccessPriority, AccessRole } from '@core/shared/models/access';
export function filterToGetUrl(filters) {
    var filterList = [];
    Object.keys(filters).forEach(function (key) {
        if (typeof filters[key] == 'number' || (!!filters[key].length && filters[key] !== 'empty')) {
            filterList.push("".concat(key, "=").concat(filters[key]));
        }
    });
    return filterList.join('&');
}
export function filterToParams(filters) {
    var sortedFilters = JSON.parse(JSON.stringify(filters));
    Object.keys(filters).forEach(function (key) {
        if (sortedFilters[key] === 'empty') {
            delete sortedFilters[key];
        }
    });
    return sortedFilters;
}
export function constructQueryParams(params, filters) {
    var paramsData = params || { limit: 0 }; // to get all records (instead of default 10)
    var filtersData = filters || {};
    var all = Object.entries(__assign(__assign({}, paramsData), filtersData)).map(function (el) { return [el[0], el[1].toString()]; });
    return new URLSearchParams(all).toString();
}
// Returns array of roles according to AccessPriority
export function getAvailableRoles(userRole) {
    return Object.values(AccessRole)
        .filter(function (role) { return AccessPriority[role] < AccessPriority[userRole]; })
        .filter(function (role) {
        // PRACTICE_SUPERVISOR can't create SUPERVISORS and can't see them
        if (userRole === AccessRole.PRACTICE_SUPERVISOR) {
            return role !== AccessRole.SUPERVISOR;
        }
        return true;
    });
}
