var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c;
import { createSlice } from '@reduxjs/toolkit';
var AUTH_POSITION_KEY = 'auth_position';
var authTypes = ['phoneInitial', 'emailInitial'];
var initialState = {
    id: '',
    identifier: '',
    position: localStorage.getItem(AUTH_POSITION_KEY) || 'phoneInitial',
    tries: {
        password: 0,
        code: 0,
    },
    mergeToDeletedAccountRegStat: null,
};
export var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        checkRequest: function (_state, _action) { },
        checkSuccess: function (state, action) {
            state.identifier = action.payload.identifier;
            state.id = action.payload.userId;
        },
        signUpRequest: function (_state, _action) { },
        logInRequest: function (_state, _action) { },
        verificationPassRequest: function (_state, _action) { },
        logInGoogleConfirm: function (_state, _action) { },
        logInAppleConfirm: function (_state, _action) { },
        logOut: function (_state, _action) { },
        addTry: function (state, action) {
            var _a;
            state.tries = __assign(__assign({}, state.tries), (_a = {}, _a[action.payload] = state.tries[action.payload] + 1, _a));
        },
        resetTries: function (state) {
            state.tries = {
                password: 0,
                code: 0,
            };
        },
        changePostition: function (state, action) {
            state.position = action.payload;
            if (authTypes.includes(action.payload)) {
                localStorage.setItem(AUTH_POSITION_KEY, action.payload);
            }
        },
        mergeToDeletedAccountRequest: function (state, _action) {
            state.mergeToDeletedAccountRegStat = 'loading';
        },
        mergeToDeletedAccountSuccess: function (state) {
            state.mergeToDeletedAccountRegStat = null;
        },
        mergeToDeletedAccountFail: function (state) {
            state.mergeToDeletedAccountRegStat = null;
        },
        cloneAccount: function (_state, _action) { },
        resetStore: function (state) { return (__assign(__assign({}, initialState), { position: localStorage.getItem(AUTH_POSITION_KEY) || state.position })); },
    },
});
export var checkSuccess = (_a = authSlice.actions, _a.checkSuccess), checkRequest = _a.checkRequest;
export var verificationPassRequest = authSlice.actions.verificationPassRequest;
export var logInRequest = (_b = authSlice.actions, _b.logInRequest), signUpRequest = _b.signUpRequest;
export var logInGoogleConfirm = authSlice.actions.logInGoogleConfirm;
export var logInAppleConfirm = authSlice.actions.logInAppleConfirm;
export var logOut = (_c = authSlice.actions, _c.logOut), addTry = _c.addTry, resetTries = _c.resetTries, resetStore = _c.resetStore, changePostition = _c.changePostition, mergeToDeletedAccountRequest = _c.mergeToDeletedAccountRequest, mergeToDeletedAccountFail = _c.mergeToDeletedAccountFail, mergeToDeletedAccountSuccess = _c.mergeToDeletedAccountSuccess, cloneAccount = _c.cloneAccount;
export default authSlice.reducer;
