var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Button } from '@core/components/buttons';
import { Label } from '@core/components/formComponents/admin/inputs';
import styles from './index.module.sass';
export var ButtonSelect = function (_a) {
    var label = _a.label, items = _a.items, active = _a.active, buttonClassName = _a.buttonClassName, className = _a.className, onChange = _a.onChange, error = _a.error, disabled = _a.disabled;
    return (_jsxs("div", __assign({ className: clsx(styles.select, disabled && styles.selectDisabled, className) }, { children: [label && _jsx(Label, { children: label }), _jsx("div", __assign({ className: clsx(styles.buttons, disabled && styles.buttonsDisabled) }, { children: items.map(function (item, index) {
                    return (_jsx(Button, { label: item.textValue ? item.textValue : item.value, primary: active === (item === null || item === void 0 ? void 0 : item.id) || active === (item === null || item === void 0 ? void 0 : item.key), classes: [
                            (active === (item === null || item === void 0 ? void 0 : item.id) || active === (item === null || item === void 0 ? void 0 : item.key)) && styles.active,
                            buttonClassName,
                        ], handler: function () { return onChange(item.id !== undefined ? item.id : item.key); } }, index));
                }) })), error && _jsx("span", __assign({ className: styles.selectError }, { children: error }))] })));
};
