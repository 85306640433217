var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// Displays "100 000" вместо "100000"
export var formatToGroups = function (number, defaultValue) {
    if (defaultValue === void 0) { defaultValue = ''; }
    return number ? number.toLocaleString('ru-Ru') : String(defaultValue);
};
// Displays 2 decimals for numbers
export var formatNumber = function (num, valueIfEmpty) {
    if (valueIfEmpty === void 0) { valueIfEmpty = ''; }
    if (!num && num !== 0) {
        return valueIfEmpty;
    }
    if (typeof num === 'string') {
        return parseFloat(num).toFixed(2);
    }
    return num.toFixed(2);
};
// Rounds up the percents for chart to always result 100
export var countDisplayedPercents = function (chartValues) {
    var values = chartValues === null || chartValues === void 0 ? void 0 : chartValues.map(function (v) { return v.count; });
    var allTestsCount = values.reduce(function (acc, item) { return acc + item; }, 0);
    var percents = values.map(function (v) { return Math.round((v / allTestsCount) * 100); });
    var total = percents.reduce(function (acc, item) { return acc + item; }, 0);
    if (total === 100)
        return percents;
    var isMore100 = total > 100;
    var countEqualsInValues = percents.reduce(function (acc, item) {
        acc[item] = acc[item] + 1 || 1;
        return acc;
    }, {});
    var entry = __spreadArray([], Object.entries(countEqualsInValues), true).sort(function (a, b) {
        return a[1] - b[1];
    })[0];
    var index = percents.findIndex(function (el) { return el === Number(entry[0]); });
    return percents.map(function (p, idx) {
        if (idx === index) {
            return isMore100 ? p - 1 : p + 1;
        }
        return p;
    });
};
