var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var MedCardCircle = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "60", height: "60", viewBox: "0 0 60 60", fill: "none" }, { children: [_jsx("circle", { cx: "30", cy: "30", r: "30", fill: "#FFCC37" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18 22.5C18 20.0147 20.0147 18 22.5 18H25.5C26.3284 18 27 18.6716 27 19.5C27 20.3284 26.3284 21 25.5 21H22.5C21.6716 21 21 21.6716 21 22.5V40.5C21 41.3284 21.6716 42 22.5 42H37.5C38.3284 42 39 41.3284 39 40.5V22.5C39 21.6716 38.3284 21 37.5 21H34.5C33.6716 21 33 20.3284 33 19.5C33 18.6716 33.6716 18 34.5 18H37.5C39.9853 18 42 20.0147 42 22.5V40.5C42 42.9853 39.9853 45 37.5 45H22.5C20.0147 45 18 42.9853 18 40.5V22.5Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24 19.5C24 17.0147 26.0147 15 28.5 15H31.5C33.9853 15 36 17.0147 36 19.5C36 21.9853 33.9853 24 31.5 24H28.5C26.0147 24 24 21.9853 24 19.5ZM28.5 18C27.6716 18 27 18.6716 27 19.5C27 20.3284 27.6716 21 28.5 21H31.5C32.3284 21 33 20.3284 33 19.5C33 18.6716 32.3284 18 31.5 18H28.5Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.5 33C25.5 32.1716 26.1716 31.5 27 31.5H33C33.8284 31.5 34.5 32.1716 34.5 33C34.5 33.8284 33.8284 34.5 33 34.5H27C26.1716 34.5 25.5 33.8284 25.5 33Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M30 28.5C30.8284 28.5 31.5 29.1716 31.5 30V36C31.5 36.8284 30.8284 37.5 30 37.5C29.1716 37.5 28.5 36.8284 28.5 36V30C28.5 29.1716 29.1716 28.5 30 28.5Z", fill: "black" })] }))); };
export default MedCardCircle;
