var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var SilverIcon = function () { return (_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "22", height: "22", viewBox: "0 0 22 22", fill: "none" }, { children: [_jsx("rect", { x: "10.6064", width: "15", height: "15", transform: "rotate(45 10.6064 0)", fill: "#70B4FF" }), _jsx("g", __assign({ style: { mixBlendMode: 'overlay' }, opacity: "0.6" }, { children: _jsx("path", { d: "M10.606 21.2136L-0.000566393 10.607H10.606V21.2136Z", fill: "white" }) })), _jsx("path", { d: "M10.6069 0.000253946L21.2135 10.6069H10.6069V0.000253946Z", fill: "white", fillOpacity: "0.2" })] }))); };
export default SilverIcon;
