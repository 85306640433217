var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { routePaths } from '@app/routes/routes';
import { Button } from '@components/button';
import useWindowDimensions from '@core/shared/utils/widthHook';
import styles from './index.module.sass';
export var Hero = function (_a) {
    var handleScroll = _a.handleScroll;
    var wideScreen = useWindowDimensions().isDesktop;
    return (_jsxs("div", __assign({ className: styles.hero }, { children: [_jsxs("div", __assign({ className: styles.heroContent }, { children: [_jsx("div", __assign({ className: styles.heroContentTitle }, { children: t('label_landing_home_hero_title') })), _jsx("div", __assign({ className: styles.heroContentSubtitle }, { children: t('label_landing_home_hero_subtitle') })), _jsx("div", __assign({ className: styles.heroContentText }, { children: t('label_landing_home_hero_text') })), _jsxs("div", __assign({ className: styles.heroContentBtn }, { children: [_jsx(Button, { label: t('label_landing_home_hero_btn_plans'), classes: [styles.heroContentBtnItem], withoutArrow: !wideScreen, handler: handleScroll }), _jsx(Link, __assign({ to: routePaths.EMPLOYERS, className: styles.heroContentBtnItem }, { children: _jsx(Button, { label: t('label_landing_home_hero_btn_employers'), black: true, withoutArrow: !wideScreen }) }))] }))] })), _jsx("div", __assign({ className: styles.heroLogo }, { children: _jsx("img", { src: require('@root/src/shared/images/qsureCard/qsureCard.png'), srcSet: "".concat(require('@root/src/shared/images/qsureCard/qsureCard@2x.png'), " 2x, \n          ").concat(require('@root/src/shared/images/qsureCard/qsureCard@3x.png'), " 3x"), alt: "Card" }) }))] })));
};
