var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { config } from '@core/config/config';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { PaymentMethodForm } from './paymentMethodsForm';
// Disable advanced fraud detection in non-production environments
//https://github.com/stripe/stripe-js/blob/ef32028d0e1f8381b3b4ecca8bc74bf659e7153e/README.md#disabling-advanced-fraud-detection-signals
if (process.env.NODE_ENV !== 'production') {
    loadStripe.setLoadParameters({ advancedFraudSignals: false });
}
var stripePromise = loadStripe(config.apiKeys.stripe, { locale: 'en' });
export var StripePaymentMethods = function (_a) {
    var onSubmit = _a.onSubmit;
    return (_jsx(Elements, __assign({ stripe: stripePromise }, { children: _jsx(PaymentMethodForm, { onSubmit: onSubmit }) })));
};
