export var DATE_FORMAT;
(function (DATE_FORMAT) {
    DATE_FORMAT["dash"] = "YYYY-MM-DD";
    DATE_FORMAT["dashStartByMonth"] = "MM-DD-YYYY";
    DATE_FORMAT["slash"] = "MM/DD/YYYY";
    DATE_FORMAT["dot"] = "MM.DD.YYYY";
    DATE_FORMAT["short"] = "MMM DD, YYYY";
    DATE_FORMAT["long"] = "LLL";
    DATE_FORMAT["year"] = "YYYY";
    DATE_FORMAT["month"] = "MMMM";
    DATE_FORMAT["dayShort"] = "D";
    DATE_FORMAT["dayNumber"] = "DD";
    DATE_FORMAT["dayName"] = "ddd";
    DATE_FORMAT["monthDay"] = "MMM DD";
    DATE_FORMAT["dotWithTime"] = "MM.DD.YYYY h:mmA";
    DATE_FORMAT["shortWithTime"] = "MMM DD, YYYY h:mmA";
    DATE_FORMAT["shortDay"] = "MMM D YYYY";
    DATE_FORMAT["time"] = "h:mm A";
    DATE_FORMAT["dayFull"] = "dddd, MMMM Do";
})(DATE_FORMAT || (DATE_FORMAT = {}));
